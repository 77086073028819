import { Box, Card, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import { profiles } from "../../../utils/altoRiesgo/profilesAltoRiesgo";
import AltoDataTable from "./AltoDataTable";
import BreadCrumb from "../../general/BreadCrumb";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";

const itemsBreadCrumbs = [
  {
    label: "Alto Riesgo",
    icon: <GppMaybeIcon sx={{ mr: 0.5 }} fontSize="small" />,
    link: "/alto-riesgo/inicio",
  },
  { label: "Alto Riesgo" },
];

const Alto = () => {
  const [nextRender, setNextRender] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!profiles.altoRiesgo.includes(user.role.idProfile)) {
      navigate("/dashboard", { replace: true });
    } else setNextRender(true);
  }, []);

  return (
    <>
      {nextRender && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h4" sx={{ my: 2 }}>
              Alto Riesgo
            </Typography>
            <BreadCrumb items={itemsBreadCrumbs} />
          </Box>

          <motion.div
            initial={{ y: 20 }}
            animate={{ y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Card sx={{ mb: 4 }}>
              <AltoDataTable />
            </Card>
          </motion.div>
        </>
      )}
    </>
  );
};

export default Alto;
