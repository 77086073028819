import axios from "axios";

/**
 * GET
 **/

const getQuotes = async (currentPage) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/cotizaciones/cotizaciones/all/new?page=${currentPage}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const searchQuotes = async (currentPage, search) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/cotizaciones/cotizaciones/all/search?page=${currentPage}&search=${search}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const searchQuotesAdv = async (page, advancedS) => {
  const URL = get_url_af(page, advancedS);
  try {
    const res = await axios(URL);

    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getQuotesDetail = async (id) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/cotizaciones/cotizaciones/detail/cotizaciones?id=${id}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getContactDetail = async (id) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/cotizaciones/cotizaciones/detail/contact?id=${id}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getProductsDetail = async (page, id) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/cotizaciones/cotizaciones/detail/products?id=${id}&page=${page}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const searchProductDetail = async (page, id, search) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/cotizaciones/cotizaciones/detail/products/search?id=${id}&page=${page}&search=${search}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getCompany = async (idRequerimiento) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/cotizaciones/cotizaciones/detail/company?idRequerimiento=${idRequerimiento}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getMessages = async (idRequerimiento) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/cotizaciones/cotizaciones/detail/messages?idRequerimiento=${idRequerimiento}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getEditQuoteInf = async (id) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/cotizaciones/cotizaciones/editquote/geteditquoteinf?id=${id}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getProductEditC = async (page, id) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/cotizaciones/cotizaciones/editquote/products?id=${id}&page=${page}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getEstados = async () => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/cotizaciones/cotizaciones/all/estados`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

function get_url_af(currentPage, advancedS) {
  let aux = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/cotizaciones/cotizaciones/all/searchadv?page=${currentPage}`;

  if (advancedS.cotizacion && advancedS.cotizacion.length > 3)
    aux = aux + `&cotizacion=${advancedS.cotizacion}`;
  if (advancedS.estatus && advancedS.estatus.length > 3)
    aux = aux + `&estatus=${advancedS.estatus}`;
  if (advancedS.orden_venta && advancedS.orden_venta.length > 3)
    aux = aux + `&orden_venta=${advancedS.orden_venta}`;
  if (advancedS.requerimiento && advancedS.requerimiento.length > 3)
    aux = aux + `&requerimiento=${advancedS.requerimiento}`;
  if (advancedS.razon_social && advancedS.razon_social.length > 3)
    aux = aux + `&razon_social=${advancedS.razon_social}`;
  if (advancedS.rfc && advancedS.rfc.length > 3)
    aux = aux + `&rfc=${advancedS.rfc}`;
  if (advancedS.email && advancedS.email.length > 3)
    aux = aux + `&email=${advancedS.email}`;
  if (advancedS.telefono && advancedS.telefono.length > 3)
    aux = aux + `&telefono=${advancedS.telefono}`;
  if (advancedS.pais && advancedS.pais.length > 3)
    aux = aux + `&pais=${advancedS.pais}`;
  if (advancedS.ciudad && advancedS.ciudad.length > 3)
    aux = aux + `&ciudad=${advancedS.ciudad}`;
  if (advancedS.delegacion && advancedS.delegacion.length > 3)
    aux = aux + `&delegacion=${advancedS.delegacion}`;
  if (advancedS.cp && advancedS.cp.length > 3)
    aux = aux + `&cp=${advancedS.cp}`;
  if (advancedS.nombre_producto && advancedS.nombre_producto.length > 3)
    aux = aux + `&nombre_producto=${advancedS.nombre_producto}`;
  if (advancedS.estado && advancedS.estado > 0)
    aux = aux + `&estado=${advancedS.estado}`;

    console.log(aux);

  return aux;
}

/**
 * POST
 **/

const saveMessageC = async (messageD) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/cotizaciones/cotizaciones/add/message`;
  try {
    await axios.post(URL, messageD);
  } catch (error) {
    console.error("saveMessageC", error);
  }
};

const editQuotes = async (data) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/cotizaciones/cotizaciones/editquote/all`;
  try {
    await axios.post(URL, data);
  } catch (error) {
    console.error("editQuotes", error);
  }
};

const cancelQuote = async (data) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/cotizaciones/cotizaciones/cancel/all`;
  try {
    await axios.post(URL, data);
  } catch (error) {
    console.error("cancelQuote", error);
  }
};

const addSalesOrder = async (data) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/cotizaciones/cotizaciones/all/saleorder`;
  try {
    await axios.post(URL, data);
  } catch (error) {
    console.error("addSalesOrder", error);
  }
};

const returnToRequirement = async (data) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/cotizaciones/cotizaciones/all/returnrequirement`;
  try {
    await axios.post(URL, data);
  } catch (error) {
    console.error("returnToRequirement", error);
  }
};

const validateQuote = async (data) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/cotizaciones/cotizaciones/all/validatequote`;
  try {
    await axios.post(URL, data);
  } catch (error) {
    console.error("validateQuote", error);
  }
};

const approveQuote = async (data) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/cotizaciones/cotizaciones/all/approvequote`;
  try {
    await axios.post(URL, data);
  } catch (error) {
    console.error("approveQuote", error);
  }
};

const resendSapOV = async (data) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/cotizaciones/cotizaciones/all/resendsap/ov`;
  try {
    await axios.post(URL, data);
  } catch (error) {
    console.error("approveQuote", error);
  }
};

export {
  getQuotes,
  searchQuotes,
  getQuotesDetail,
  getContactDetail,
  getProductsDetail,
  searchProductDetail,
  getCompany,
  getMessages,
  saveMessageC,
  searchQuotesAdv,
  getEditQuoteInf,
  getProductEditC,
  editQuotes,
  getEstados,
  cancelQuote,
  addSalesOrder,
  returnToRequirement,
  validateQuote,
  approveQuote,
  resendSapOV,
};
