import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Grid,
  Snackbar,
  Alert,
  Typography,
} from "@mui/material";
import { createSeason } from "../../../utils/vales/configuracion/configuracion";

const ModalCreateTemporada = ({
  open,
  onClose,
  onSave,
  creationUsr,
  setLoading,
  setRows,
  setEstado,
}) => {
  const [seasonName, setSeasonName] = useState("");
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [errors, setErrors] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [charCount, setCharCount] = useState(0);

  const validate = () => {
    let newErrors = {};

    if (amount.length < 1) newErrors.amount = "Monto es requerido.";
    if (isNaN(amount)) newErrors.amount = "Ingresa un dato numerico.";
    if (seasonName.length < 3)
      newErrors.name = "Nombre debe tener al menos 3 caracteres.";
    if (description.length < 3)
      newErrors.description = "Descripción debe tener al menos 3 caracteres.";

    if (!startDate) newErrors.startDate = "Fecha de inicio es requerida.";
    if (!endDate) newErrors.endDate = "Fecha final es requerida.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (validate()) {
      const seasonData = {
        name: seasonName,
        amount: amount,
        description: description,
        ref_start_date: startDate,
        ref_finish_date: endDate,
        creationUsr: creationUsr,
      };

      setLoading(true);
      setRows([]);
      onSave();
      await createSeason(seasonData);
      setEstado((prevEstado) => (prevEstado === 0 ? 1 : 0));

      setSeasonName("");
      setAmount("");
      setDescription("");
      setStartDate(null);
      setEndDate(null);
      setCharCount(0);
      setErrors({});
    }
  };

  const handleDateChange = (field, date) => {
    if (field === "startDate") {
      setStartDate(date);

      if (endDate && new Date(date) > new Date(endDate)) {
        handleDateError();
      }
    } else if (field === "endDate") {
      setEndDate(date);

      if (startDate && new Date(date) < new Date(startDate)) {
        handleDateError();
      }
    }
  };

  const handleDateError = () => {
    setSnackbarOpen(true);
    setStartDate(null);
    setEndDate(null);
  };

  const onCloseAndClear = () => {
    onClose();
    setSeasonName("");
    setAmount("");
    setDescription("");
    setStartDate(null);
    setEndDate(null);
    setCharCount(0);
    setErrors({});
  };

  return (
    <>
      <Dialog open={open} onClose={onCloseAndClear} maxWidth="sm" fullWidth>
        <DialogTitle>Agregar Temporada</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Nombre"
            fullWidth
            value={seasonName}
            onChange={(e) => setSeasonName(e.target.value)}
            error={!!errors.name}
            helperText={errors.name}
          />
          <TextField
            margin="dense"
            label="Monto"
            fullWidth
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            error={!!errors.amount}
            helperText={errors.amount}
          />
          <Typography
            variant="subtitle1"
            gutterBottom
            style={{ marginTop: 10 }}
          >
            Periodo propuesto:
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                margin="dense"
                label="Fecha de inicio"
                type="date"
                value={startDate || ""}
                onChange={(e) => handleDateChange("startDate", e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                error={!!errors.startDate}
                helperText={errors.startDate}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                margin="dense"
                label="Fecha final"
                type="date"
                value={endDate || ""}
                onChange={(e) => handleDateChange("endDate", e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: startDate,
                }}
                disabled={!startDate}
                error={!!errors.endDate}
                helperText={errors.endDate}
                required
              />
            </Grid>
          </Grid>
          <TextField
            margin="dense"
            label="Descripción"
            fullWidth
            multiline
            rows={4}
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
              setCharCount(e.target.value.length);
            }}
            error={!!errors.description}
            helperText={errors.description}
            inputProps={{ maxLength: 44 }}
          />
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ marginBottom: 10 }}
          >
            {charCount}/44 caracteres
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseAndClear} color="error" variant="outlined">
            Cancelar
          </Button>
          <Button onClick={handleSubmit} color="success" variant="outlined">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="error"
          variant="filled"
        >
          La fecha final no puede ser menor que la fecha de inicio
        </Alert>
      </Snackbar>
    </>
  );
};

export default ModalCreateTemporada;
