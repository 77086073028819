import {
  Box,
  Grid,
  Button,
  Stack,
  Typography,
  TextField,
  Menu,
  MenuItem,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { useAuth } from "../../../context/AuthContext";
import {
  getSeasons,
  searchSeasons,
} from "../../../utils/vales/configuracion/configuracion";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { LoadingButton } from "@mui/lab";
import ModalDeleteTemporada from "./ModalDeleteTemporada";
import ModalCreateTemporada from "./ModalCreateTemporada";
import ModalUpdateTemporada from "./ModalUpdateTemporada";

const TemporadasDataTable = () => {
  const { user } = useAuth();
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [keyWord, setKeytoSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(null);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [isModalCreateOpen, setIsModalCreateOpen] = useState(false);
  const [isModalUpdateOpen, setIsModalUpdateOpen] = useState(false);
  const [selectedSeasonIdUpdate, setSelectedSeasonIdUpdate] = useState(null);
  const [selectedSeasonId, setSelectedSeasonId] = useState(null);
  const [selectedSeasonName, setSelectedSeasonName] = useState(null);
  const [estado, setEstado] = useState(0);

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setOpen(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(null);
  };

  const handleDeleteClick = (id, name) => {
    setSelectedSeasonId(id);
    setSelectedSeasonName(name);
    setIsModalDeleteOpen(true);
  };

  function formatDate(isoDate) {
    const date = new Date(isoDate);

    const months = [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ];
    let day = date.getUTCDate();
    const month = months[date.getUTCMonth()];
    const year = date.getUTCFullYear();

    if (day < 10) {
      day = "0" + day;
    }

    return `${day}/${month}/${year}`;
  }

  const handleSaveSeason = () => {
    setIsModalCreateOpen(false);
  };

  const handleClickUpdate = (season) => {
    setSelectedSeasonIdUpdate(season);
  };

  const columns = [
    {
      field: "season",
      headerName: "Temporada",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Typography variant="subtitle1" align="center">
          {params.row.name}
        </Typography>
      ),
    },
    {
      field: "seasonReference",
      headerName: "Periodo de Referencia",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Typography
          variant="subtitle1"
          align="center"
          sx={{ lineHeight: "20px" }}
        >
          {formatDate(params.row.ref_start_date)}
          <br />
          {formatDate(params.row.ref_finish_date)}
        </Typography>
      ),
    },
    {
      field: "seasonReal",
      headerName: "Periodo real",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) =>
        params.row.season_start_date && params.row.season_finish_date ? (
          <Typography
            variant="subtitle1"
            align="center"
            sx={{ lineHeight: "20px" }}
          >
            {formatDate(params.row.season_start_date)}
            <br />
            {formatDate(params.row.season_finish_date)}
          </Typography>
        ) : (
          ""
        ),
    },
    {
      field: "description",
      headerName: "Descripción",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Typography variant="subtitle1" align="center">
          {params.row.description}
        </Typography>
      ),
    },
    {
      field: "active",
      headerName: "Activo",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Typography variant="subtitle1" align="center">
          {params.row.active === 1 ? "Si" : "No"}
        </Typography>
      ),
    },
    {
      field: "amount",
      headerName: "Monto",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Typography variant="subtitle1" align="center">
          {`$${params.row.amount}.00`}
        </Typography>
      ),
    },
    {
      field: "accion",
      headerName: "Acción",
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => (
        <>
          <Button
            variant="contained"
            size="small"
            endIcon={<KeyboardArrowDownIcon />}
            disableElevation
            aria-controls={open ? this : undefined}
            aria-haspopup="menu"
            onClick={(event) => handleClick(event, params.row.id_season)}
          >
            Acción
          </Button>
          <Menu
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            anchorEl={anchorEl}
            open={open === params.row.id_season ? true : false}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                setIsModalUpdateOpen(true);
                handleClickUpdate(params.row.id_season);
                handleClose();
              }}
            >
              <Typography>Editar datos</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleDeleteClick(params.row.id_season, params.row.name);
                handleClose();
              }}
            >
              <Typography>Eliminar</Typography>
            </MenuItem>
          </Menu>
        </>
      ),
    },
  ];

  if (
    document.querySelector(
      ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
    )
  )
    document
      .querySelector(
        ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
      )
      .remove();

  useEffect(() => {
    let active = true;

    (async () => {
      let newRows = null;

      if (
        typeof keyWord === "undefined" ||
        keyWord === undefined ||
        keyWord.length === 0
      ) {
        setLoading(true);
        newRows = await getSeasons(page);
      } else if (keyWord.length >= 3) {
        setLoading(true);
        newRows = await searchSeasons(page, keyWord);
      } else active = false;

      if (!active) {
        return;
      }
      setTotal(newRows.total);
      setRows(newRows.data);
      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [page, keyWord, estado]);

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Grid container sx={{ mb: 2, mt: 2 }}>
          <Grid item md={4} sx={{ px: 2 }}>
            <Stack direction="row" spacing={1}>
              <TextField
                size="small"
                variant="outlined"
                fullWidth
                label={`Buscar Temporadas`}
                value={keyWord}
                onChange={(e) => setKeytoSearch(e.target.value)}
              />
            </Stack>
          </Grid>

          <Grid item md sx={{ px: 2, textAlign: "right" }}>
            <LoadingButton
              variant="contained"
              color="info"
              onClick={() => setIsModalCreateOpen(true)}
            >
              Agregar Temporada
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
      <Box style={{ height: 635, width: "100%" }}>
        <DataGridPro
          rows={rows}
          columns={columns}
          pagination
          pageSize={10}
          rowsPerPageOptions={[10]}
          getRowId={(row) => row.id_season}
          rowCount={total}
          paginationMode="server"
          onPageChange={(newPage) => {
            setPage(newPage);
          }}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
          loading={loading}
          disableColumnMenu
          disableSelectionOnClick
          getDetailPanelHeight={() => "auto"}
        />
      </Box>

      <ModalCreateTemporada
        open={isModalCreateOpen}
        onClose={() => setIsModalCreateOpen(false)}
        onSave={handleSaveSeason}
        creationUsr={user.data.awsid}
        setLoading={setLoading}
        setRows={setRows}
        setEstado={setEstado}
      />

      <ModalUpdateTemporada
        open={isModalUpdateOpen}
        onClose={() => setIsModalUpdateOpen(false)}
        seasonId={selectedSeasonIdUpdate}
        updateUsr={user.data.awsid}
        setLoading={setLoading}
        setRows={setRows}
        setEstado={setEstado}
      />

      <ModalDeleteTemporada
        open={isModalDeleteOpen}
        onClose={() => setIsModalDeleteOpen(false)}
        selectedSeasonId={selectedSeasonId}
        selectedSeasonName={selectedSeasonName}
        idSeason={user.data.awsid}
        setLoading={setLoading}
        setRows={setRows}
        setEstado={setEstado}
      />
    </>
  );
};

export default TemporadasDataTable;
