import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  LinearProgress,
  MenuItem,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { forwardRef, useEffect, useState } from "react";
import dayjs from "dayjs";
import { clearPhone } from "../../utils/strings";
import { useResourses } from "../../context/ResourcesContext";
import { useAuth } from "../../context/AuthContext";
import { CheckBox } from "@mui/icons-material";
import { getInteractions } from "../../utils/crm/buscador";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NuevoCasoDialog = ({ open, setOpen, noDialog, clearTopValues, details }) => {
  const { aclarationsTypes } = useResourses();
  // const { aclarationsTypes, categories, themes } = useResourses();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [numeroPedido, setNumeroPedido] = useState("");
  const [pedido, setPedido] = useState(null);
  const [aclaracionValue, setAclaracionValue] = useState("Aclaración de pago");
  const [tipificacionValue, setTipificacionValue] = useState("");
  const [caseAsociations, setCaseAsociations] = useState([]);
  const [interactions, setInteractions] = useState([]);

  const [aclaracion, setAclaracion] = useState(6);
  const [tipificacion, setTipificacion] = useState(1);
  const [aclaraciones, setAclaraciones] = useState(null);
  const [tipificaciones, setTipificaciones] = useState(null);

  const closeDialog = () => {
    setCaseAsociations([]);
    setAclaracionValue("");
    setTipificacionValue("");
    setInteractions([]);
    setAclaracion(6);
    setTipificacion(1);
    setNumeroPedido("");
    setPedido(null);
    if (noDialog) {
      clearTopValues();
    }
    setOpen(false);
  };

  const handleCreateCase = async () => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      // formData.append(
      //   "id_tipificacion_ac",
      //    categories.get(aclaracionValue) === 5 ? 11 :
      //    categories.get(aclaracionValue) === 6 ? 10 :
      //    categories.get(aclaracionValue) === 10 ? 1 :
      //    categories.get(aclaracionValue) === 14 ? 12 :
      //    categories.get(aclaracionValue) === 15 ? 3 :
      //    categories.get(aclaracionValue) === 16 ? 9 :
      //    categories.get(aclaracionValue) === 25 ? 16 :
      //    categories.get(aclaracionValue) === 18 ? 5 :
      //    categories.get(aclaracionValue) === 22 ? 4 :
      //    categories.get(aclaracionValue) === 26 ? 13 :
      //    categories.get(aclaracionValue) === 27 ? 11 :
      //    categories.get(aclaracionValue) === 29 ? 15 :
      //    categories.get(aclaracionValue) === 28 ? 14 :
      //    categories.get(aclaracionValue)
      // );
      formData.append("id_tipificacion_ac", aclaracion);
      formData.append("id_tema", tipificacion);

      formData.append("increment_id", numeroPedido);
      formData.append("user_id", user.data.awsid);
      // formData.append("id_tema", themes.get(tipificacionValue));
      formData.append("idUrl", details.id_crm_main);
      formData.append("canal", details?.channel ?? "Chat");
      formData.append("is_ticket", "0");
      formData.append("contact", details.contact);
      formData.append("id_guia", pedido.guia);
      formData.append("solution", details.description);
      formData.append("id_school", pedido.school_id);
      // formData.append("solution", details?.channel === "Chat" ? details.description : "solucionado");
      const data = {
        // id_tipificacion_ac: categories.get(aclaracionValue),
        id_tipificacion_ac: aclaracion,
        increment_id: numeroPedido,
        user_id: user.data.awsid,
        // id_tema: themes.get(tipificacionValue),
        id_tema: tipificacion,
        idUrl: 1,
        canal: details?.channel ?? "Chat",
        is_ticket: 0,
        contact: pedido.telephone,
        id_guia: pedido.guia,
        solution: details?.channel === "Chat" ? details.description : "solucionado",
        id_school: pedido.school_id,
      };
      console.log(data);
      await axios({
        url: `${process.env.REACT_APP_BASEURL}/crm/cases/save`,
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      });
      if (!noDialog) {
        navigate("../", { replace: true });
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
    setOpen(false);
  };

  const handleCheckboxChange = (id) => {
    const checked = caseAsociations.includes(id);

    if (checked) {
      const newAsociations = caseAsociations.filter((as) => as !== id);
      setCaseAsociations(newAsociations);
    } else {
      const newAsociations = [...caseAsociations];
      newAsociations.push(id);
      setCaseAsociations(newAsociations);
    }
  };

  const columns = [
    {
      field: "date_register",
      headerName: "Fecha Registro",
      minWidth: 200,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            {dayjs(params.row.date_register).format("DD MMM YYYY, hh:mm A")}
          </Typography>
          <Typography variant="caption">{dayjs(params.row.date_register).fromNow()}</Typography>
        </Box>
      ),
    },
    {
      field: "type",
      headerName: "Tipo",
      width: 110,
      renderCell: (params) => (
        <Chip
          label={params.row.type}
          size="small"
          color={params.row.type === "Online" ? "success" : "error"}
          variant="outlined"
        />
      ),
      disableColumnMenu: true,
    },
    {
      field: "title",
      headerName: "Titulo",
      minWidth: 300,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "channel",
      headerName: "Canal",
      width: 120,
      disableColumnMenu: true,
    },
    {
      field: "name_s",
      headerName: "Status",
      width: 120,
      renderCell: (params) => (
        <Chip label={params.row.name_s} size="small" color={params.row.name_s === "Nuevo" ? "success" : "info"} />
      ),
      disableColumnMenu: true,
    },
    {
      field: "action",
      headerName: "Asociar",
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <CheckBox
          checked={caseAsociations.includes(params.row.id_crm_main)}
          onChange={() => handleCheckboxChange(params.row.id_crm_main)}
        />
      ),
      disableColumnMenu: true,
    },
  ];

  useEffect(() => {
    let isCancelled = false;
    if (!pedido) return;
    const getRelatedInteractions = async () => {
      try {
        const newInteractions = await getInteractions(pedido.email);
        if (!isCancelled) setInteractions(newInteractions);
      } catch (error) {
        console.error(error.message);
      }
    };

    getRelatedInteractions();

    return () => {
      isCancelled = true;
    };
  }, [pedido]);

  useEffect(() => {
    let isEffectCanceled = false;
    const getDetallesPedido = async () => {
      if (numeroPedido.length < 9) return;
      setIsLoading(true);
      try {
        const res = await axios.get(`${process.env.REACT_APP_BASEURL}/crm/searcher/orders?id=${numeroPedido}&mail&tel`);
        if (res.status === 200 && res.data.success && !isEffectCanceled) {
          setPedido(res.data.data[0]);
        }
      } catch (error) {
        console.log("getDetallesPedido", error.message);
      }
      setIsLoading(false);
    };

    getDetallesPedido();

    return () => {
      isEffectCanceled = true;
    };
  }, [numeroPedido]);

  useEffect(() => {
    if (aclaracionValue.length === 0) return;
    setTipificacionValue(aclarationsTypes[aclaracionValue][0]);
  }, [aclaracionValue, aclarationsTypes]);

  useEffect(() => {
    const getResourcesForNewCase = async () => {
      const url = `${process.env.REACT_APP_BASEURL}/crm/cases/resources`;
      try {
        const res = await axios.get(url);
        if (res.data.success) {
          const resources = res.data.data;

          const aclarations = resources.reduce((result, item) => {
            if (!result.filter((r) => r.id === item.tipo_id).length)
              result.push({ id: item.tipo_id, name: item.tipo_name });
            return result;
          }, []);
          setAclaraciones(aclarations);

          const typifications = {};
          resources.forEach((res) => {
            if (!typifications[res.tipo_id]) typifications[res.tipo_id] = [{ id: res.tema_id, name: res.tema_name }];
            else typifications[res.tipo_id].push({ id: res.tema_id, name: res.tema_name });
          });
          setTipificaciones(typifications);
        }
      } catch (error) {
        console.error("getResourcesForNewCase", error.message);
      }
    };

    getResourcesForNewCase();
  }, []);

  if (noDialog) {
    return (
      <>
        <Box sx={{ mb: 4 }}>
          {isLoading && <LinearProgress />}
          {!noDialog && <Typography variant="h5">Ingrese numero de pedido</Typography>}
          <Divider sx={{ mb: 2, mt: 1 }} />
          <TextField
            value={numeroPedido}
            onChange={(e) => setNumeroPedido(e.target.value)}
            variant="filled"
            label="No. Pedido"
            sx={{ width: "100%" }}
          />
        </Box>
        {pedido && (
          <>
            <Box sx={{ mb: 4 }}>
              <Typography variant="h5">Informacion del pedido</Typography>
              <Divider sx={{ mb: 2, mt: 1 }} />
              <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 2 }}>
                <Box sx={{ width: "15rem" }}>
                  <Typography variant="h6">Numero de pedido</Typography>
                  <Typography gutterBottom>{pedido.increment_id}</Typography>
                </Box>
                <Box sx={{ width: "15rem" }}>
                  <Typography variant="h6">Nombre del cliente</Typography>
                  <Typography gutterBottom>{pedido.customer_name ?? ""}</Typography>
                </Box>
                <Box sx={{ width: "15rem" }}>
                  <Typography variant="h6">Fecha de creacion</Typography>
                  <Typography gutterBottom>{dayjs(pedido.created_at).format("DD MMM YYYY, hh:mm A")}</Typography>
                </Box>
                <Box sx={{ width: "15rem" }}>
                  <Typography variant="h6">Telefono</Typography>
                  <Typography gutterBottom>{clearPhone(pedido.telephone ?? "")}</Typography>
                </Box>
                <Box sx={{ width: "15rem" }}>
                  <Typography variant="h6">Fecha de pago</Typography>
                  <Typography gutterBottom>{dayjs(pedido.payment_date).format("DD MMM YYYY, hh:mm A")}</Typography>
                </Box>
                <Box sx={{ width: "15rem" }}>
                  <Typography variant="h6">Email</Typography>
                  <Typography gutterBottom>{pedido.email}</Typography>
                </Box>
                {/* */}
                <Box sx={{ width: "15rem" }}>
                  <Typography variant="h6">Escuela</Typography>
                  <Typography gutterBottom>
                    {pedido.school_name ?? "Sin colegio"}
                  </Typography>
                </Box> 
              </Box>
            </Box>
            <Box sx={{ mb: 4 }}>
              <Typography variant="h5">Seleccionar tipo de aclaracion</Typography>
              <Divider sx={{ mb: 2, mt: 1 }} />
              <TextField
                select
                label="Tipo de aclaracion"
                value={aclaracionValue}
                onChange={(e) => setAclaracionValue(e.target.value)}
                helperText="Selecciona el tipo de aclaracion"
                variant="filled"
                sx={{
                  width: "100%",
                }}
                disabled={isLoading}
              >
                {aclarationsTypes &&
                  Object.keys(aclarationsTypes).map((at, i) => (
                    <MenuItem key={i} value={at}>
                      {at}
                    </MenuItem>
                  ))}
              </TextField>
            </Box>
            <Box sx={{ mb: 4 }}>
              <Typography variant="h5">Seleccionar tipificacion</Typography>
              <Divider sx={{ mb: 2, mt: 1 }} />
              <TextField
                select
                label="Tipificacion"
                value={tipificacionValue}
                onChange={(e) => setTipificacionValue(e.target.value)}
                helperText="Selecciona la tipificacion"
                variant="filled"
                sx={{
                  width: "100%",
                }}
                disabled={isLoading}
              >
                {aclarationsTypes &&
                  aclarationsTypes[aclaracionValue].map((tip, i) => (
                    <MenuItem key={i} value={tip}>
                      {tip}
                    </MenuItem>
                  ))}
              </TextField>
            </Box>
            <Box>
              <Typography variant="h5">Seleccionar interacciones a asociar</Typography>
              <Divider sx={{ mb: 2, mt: 1 }} />
              <div style={{ width: "100%" }}>
                {interactions?.length > 0 && (
                  <div style={{ width: "100%" }}>
                    <DataGrid
                      columns={columns}
                      rows={interactions}
                      autoHeight
                      disableSelectionOnClick
                      disableColumnMenu
                      disableColumnFilter
                      sx={{ border: "none" }}
                      getRowId={(row) => row.id_crm_main}
                      hideFooter
                    />
                  </div>
                )}
              </div>
            </Box>
          </>
        )}

        <DialogActions>
          <Button onClick={closeDialog}>Cancelar</Button>
          <Button variant="contained" disableElevation onClick={handleCreateCase} disabled={isLoading}>
            Crear caso
          </Button>
        </DialogActions>
      </>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      TransitionComponent={Transition}
      scroll="body"
      fullWidth={true}
      maxWidth="md"
    >
      {isLoading && <LinearProgress />}
      <DialogTitle>Crear nuevo caso</DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5">Ingrese numero de pedido</Typography>
          <Divider sx={{ mb: 2, mt: 1 }} />
          <TextField
            value={numeroPedido}
            onChange={(e) => setNumeroPedido(e.target.value)}
            variant="filled"
            label="No. Pedido"
            sx={{ width: "100%" }}
          />
        </Box>
        {pedido && (
          <>
            <Box sx={{ mb: 4 }}>
              <Typography variant="h5">Informacion del pedido</Typography>
              <Divider sx={{ mb: 2, mt: 1 }} />
              <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 2 }}>
                <Box sx={{ width: "15rem" }}>
                  <Typography variant="h6">Numero de pedido</Typography>
                  <Typography gutterBottom>{pedido.increment_id}</Typography>
                </Box>
                <Box sx={{ width: "15rem" }}>
                  <Typography variant="h6">Nombre del cliente</Typography>
                  <Typography gutterBottom>{pedido.customer_name ?? ""}</Typography>
                </Box>
                <Box sx={{ width: "15rem" }}>
                  <Typography variant="h6">Fecha de creacion</Typography>
                  <Typography gutterBottom>{dayjs(pedido.created_at).format("DD MMM YYYY, hh:mm A")}</Typography>
                </Box>
                <Box sx={{ width: "15rem" }}>
                  <Typography variant="h6">Telefono</Typography>
                  <Typography gutterBottom>{clearPhone(pedido.telephone ?? "")}</Typography>
                </Box>
                <Box sx={{ width: "15rem" }}>
                  <Typography variant="h6">Fecha de pago</Typography>
                  <Typography gutterBottom>{dayjs(pedido.payment_date).format("DD MMM YYYY, hh:mm A")}</Typography>
                </Box>
                <Box sx={{ width: "15rem" }}>
                  <Typography variant="h6">Email</Typography>
                  <Typography gutterBottom>{pedido.email}</Typography>
                </Box>
                <Box sx={{ width: "15rem" }}>
                  <Typography variant="h6">Escuela</Typography>
                  <Typography gutterBottom>{pedido.school_name}</Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ mb: 4 }}>
              <Typography variant="h5">Seleccionar tipo de aclaracion</Typography>
              <Divider sx={{ mb: 2, mt: 1 }} />
              <Autocomplete
                disablePortal
                isOptionEqualToValue={(e) => true}
                // options={
                //   aclarationsTypes &&
                //   Object.keys(aclarationsTypes).map((tip) => ({
                //     id: tip,
                //     label: tip,
                //   }))
                // }
                options={aclaraciones.map((ac) => ({ id: ac.id, label: ac.name }))}
                sx={{
                  width: "100%",
                  mb: 2,
                }}
                onChange={(e, val) => {
                  setAclaracion(val.id);
                  // setAclaracionValue(val.id);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tipo de aclaracion"
                    helperText="Selecciona el tipo de aclaracion"
                    variant="filled"
                  />
                )}
              />
            </Box>
            <Box sx={{ mb: 4 }}>
              <Typography variant="h5">Seleccionar tipificacion</Typography>
              <Divider sx={{ mb: 2, mt: 1 }} />
              <Autocomplete
                disablePortal
                isOptionEqualToValue={(e) => true}
                // options={
                //   aclarationsTypes &&
                //   aclarationsTypes[aclaracionValue].map((tip) => ({
                //     id: tip,
                //     label: tip,
                //   }))
                // }
                options={tipificaciones[aclaracion].map((tip) => ({ id: tip.id, label: tip.name }))}
                sx={{
                  width: "100%",
                  mb: 2,
                }}
                onChange={(e, val) => {
                  setTipificacion(val.id);
                  // setTipificacionValue(val.id);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tipificacion"
                    helperText="Selecciona la tipificacion"
                    variant="filled"
                  />
                )}
              />
            </Box>
            <Box>
              <Typography variant="h5">Seleccionar interacciones a asociar</Typography>
              <Divider sx={{ mb: 2, mt: 1 }} />
              <div style={{ width: "100%" }}>
                {interactions?.length > 0 && (
                  <div style={{ width: "100%" }}>
                    <DataGrid
                      columns={columns}
                      rows={interactions}
                      autoHeight
                      disableSelectionOnClick
                      disableColumnMenu
                      disableColumnFilter
                      sx={{ border: "none" }}
                      getRowId={(row) => row.id_crm_main}
                      hideFooter
                    />
                  </div>
                )}
              </div>
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Cancelar</Button>
        <Button variant="contained" disableElevation onClick={handleCreateCase} disabled={isLoading}>
          Crear caso
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NuevoCasoDialog;
