import { Box, Card, LinearProgress, Typography, Grid, Stack, TextField, Button,Menu, MenuItem,Dialog, DialogActions, DialogContent, DialogTitle,} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import AssignmentIcon from '@mui/icons-material/Assignment';
import { useEffect, useState } from "react";
import { useAuth } from "../../../context/AuthContext";
import BreadCrumb from "../../general/BreadCrumb";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import { DataGridPro } from '@mui/x-data-grid-pro';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { getBeneficiaries, getChildrenBeneficiaries, getTemporadasChildrenIndex, saveChildren, searchTemporadasChildren } from "../../../utils/vales/temporadas/temporadas";
import { LoadingButton } from "@mui/lab";
import IndexTabs from "./tabs/IndexTabs";
import { profiles } from "../../../utils/vales/profilesVales";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

const itemsBreadCrumbs = [
    {
        label: 'Temporadas',
        icon: <ContentPasteSearchIcon sx={{ mr: 0.5 }} fontSize="small" />,
        link: '/vales/temporadas'
    },
    { label: 'DetailsTemporada' }
];

const TemporadasDetail = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [keyWord, setKeytoSearch] = useState("");
    const [detalle, setDetalle] = useState(null);

    const [alumnos, setAlumnos] = useState(null);

    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(null);
    

    const [beneficiary_name, setBeneficiaryName] = useState("");
    const [id_Beneficiary, setIdBeneficiary] = useState("");
    const [id_Season_Children, setIdSeasonChildren] = useState("");

    
    const handleClick = (event, id) => {
        setAnchorEl(event.currentTarget);
        setOpen(id);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(null)
    };

    const [openModalAgregarB, setOpenModalAgregarB] = useState(false);

    const handleOpenModalAgregarB = () => {
        getBenef();
        setOpenModalAgregarB(true);
    };

    const handleCloseModalAgregarB = () => {
        setOpenModalAgregarB(false);
        setSelectedOption("");
        setAlumnos(null);
    };

    const [openModalDetail, setOpenModalDetail] = useState(false);

    const handleOpenModalDetail = async (idBeneficiary, id_season_children, BeneficiaryName) => {
        setIdBeneficiary(idBeneficiary);
        setIdSeasonChildren(id_season_children);
        setBeneficiaryName(BeneficiaryName);
        setOpenModalDetail(true);
    };

    const handleCloseModalDetail = () => {
        setOpenModalDetail(false);
    };

    const getBenef = async () => { 
        try {
            setIsLoading(true);
            const res = await getBeneficiaries(user.role.idProfile, user.data.awsid);
            setDetalle(res);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const nombreCompleto = (nombre, apellidoP, apellidoM) => {
        const usuario = [];
        if (nombre) {
            usuario.push(nombre + " ");
        }
        if (apellidoP) {
            usuario.push(apellidoP + " ");
        }
        if (apellidoM) {
            usuario.push(apellidoM);
        }
        return (
            usuario
        );
    };


    const params = useParams();
    const id = params.id; //id = id_season

    const [loading, setLoading] = useState(false); //Loading Index
    const [isLoading, setIsLoading] = useState(false);

    const [selectedOption, setSelectedOption] = useState(" ");
    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleOptionChange = async (beneficiarie) => {
        console.log(beneficiarie);
        setSelectedOption(beneficiarie);
        getAlumnos(beneficiarie);
    };

    const getAlumnos = async (beneficiarie) => {
        console.log(beneficiarie);
        try {
            setIsLoading(true);
            const res = await getChildrenBeneficiaries(id, beneficiarie);
            setAlumnos(res);
            console.log(res);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };
    
    
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const handleSubmit = (alumnosDisponibles) => { 
        alumnosDisponibles.map(addStudent())
    }

    const handleCheckboxChanges = (event) => {
        const { value, checked } = event.target;
        if (checked) {
        setSelectedOptions([...selectedOptions, value]);
        } else {
        setSelectedOptions(selectedOptions.filter((option) => option !== value));
        }
    };

    const handleButtonClick = () => {
    handleSubmit(selectedOptions);
    };
    
    const addStudent = async (idChildren) => {
        try {
            setIsLoading(true);
            await saveChildren (
                user.data.awsid,
                idChildren,
                id,
            );
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };



    const columns = [
        {
            field: "beneficiary_name", headerName: "Beneficiario", flex: 2, align: "center", headerAlign: "center"
        },
        {
            field: "children_name", headerName: "Alumno", flex: 2, align: "center", headerAlign: "center"
        },
        {
            field: "status_name", headerName: "Status", flex: 1, align: "center", headerAlign: "center"
        },
        {
            field: "children_mail", headerName: "Mensajes", flex: 1, align: "center", headerAlign: "center"
        },
        {
            field: "code", headerName: "Código", flex: 1, align: "center", headerAlign: "center"
        },
        {
            field: "accion", headerName: "Acción", flex: 1, align: "center", headerAlign: "center",renderCell: (params) => (<>
                <Button variant="contained" size="small" endIcon={<KeyboardArrowDownIcon />} disableElevation aria-controls={open ? this : undefined} aria-haspopup="menu" onClick={(event) => handleClick(event, params.row.id_season_children)}>Acción</Button>
                <Menu anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} transformOrigin={{ vertical: 'top', horizontal: 'right', }} anchorEl={anchorEl} open={open === params.row.id_season_children ? true : false} onClose={handleClose}>
                    <MenuItem sx={{ justifyContent: "space-evenly" }} onClick={ () => handleOpenModalDetail(params.row.id_beneficiary, params.row.id_season_children, params.row.beneficiary_name) }><VisibilityIcon/>Ver detalle</MenuItem>
                </Menu>
            </>)},
    ];

    if (document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"))
        document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']").remove();

    useEffect(() => {
        let active = true;
        (async () => {
            let newRows = null;
            if (typeof keyWord === 'undefined' || keyWord === undefined || keyWord.length === 0) {
                setLoading(true);
                newRows = await getTemporadasChildrenIndex(page, id);
            }
            else if (keyWord.length >= 1) {
                setLoading(true);
                newRows = await searchTemporadasChildren (page, id, keyWord);
            }
            else active = false;
            if (!active) {
                return;
            }
            setTotal(newRows.total);
            setRows(newRows.data);
            setLoading(false);
        })();
        return () => {
            active = false;
        };
    }, [navigate, page, keyWord, user]);

    return (
        <>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography variant="h4" sx={{ my: 2 }}>Temporadas</Typography>
                <Box><BreadCrumb items={itemsBreadCrumbs} /></Box>
            </Box>

            <motion.div initial={{ y: 20 }} animate={{ y: 0 }} transition={{ duration: 0.5 }}>
                <Card>
                    <Box>
                        <Box>
                            <Grid container spacing={2}>
                                <Grid item md={12}>
                                    <Typography variant="h6" sx={{ ml: 2, my: 2, mb: 0 }}><AssignmentIcon sx={{ m: 0 }} /> Beneficiarios</Typography>
                                    {isLoading && <LinearProgress />}
                                </Grid>
                                
                            </Grid>
                        </Box>
                        <Box>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Grid container sx={{ my: 2 }}>
                                    <Grid item md={4} sx={{ px: 2 }}>
                                        <Stack direction="row" spacing={1}>
                                            <TextField size="small" variant="outlined" fullWidth label="Buscar..." value={keyWord} onChange={(e) => setKeytoSearch(e.target.value)} />
                                        </Stack>
                                        
                                    </Grid>
                                    <Grid item md sx={{ px: 2, textAlign: 'right', justifyContent:"space-between" }}>
                                        <LoadingButton variant="contained" color="info" onClick={()=>handleOpenModalAgregarB()}><PersonAddIcon/>Agregar Beneficiario</LoadingButton>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box style={{ height: 635, width: '100%' }}>
                                <DataGridPro
                                    rows={rows}
                                    columns={columns}
                                    pagination
                                    pageSize={10}
                                    rowsPerPageOptions={[10]}
                                    getRowId={((row) => (row.id_season_children + '-' + row.id_children))}
                                    rowCount={total}
                                    paginationMode="server"
                                    onPageChange={(newPage) => {
                                        setPage(newPage);
                                    }}
                                    loading={loading}
                                    disableColumnMenu
                                    disableSelectionOnClick
                                />
                            </Box>
                        </Box>
                    </Box>
                </Card>
            </motion.div>
            <Box>&nbsp;</Box>

            <Dialog open={openModalDetail} onClose={() => handleCloseModalDetail()} fullWidth={true} maxWidth={'md'}>
                <DialogTitle>
                    {beneficiary_name}
                </DialogTitle>
                <DialogContent>
                    <IndexTabs id_beneficiary={id_Beneficiary} id_season={id} id_season_children={ id_Season_Children}/>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={() => handleCloseModalDetail()}>Cerrar</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openModalAgregarB} onClose={() => handleCloseModalAgregarB()} fullWidth={true}>
                <DialogTitle>
                    Agregar beneficiario
                </DialogTitle>
                <DialogContent>
                    <Stack>
                        <strong>Beneficiario:</strong>
                        {detalle && <>
                            <select value={selectedOption} onChange={(e) => handleOptionChange(e.target.value)}>
                                <option value={""}>Seleccionar</option>
                                {detalle.data.map(({id_beneficiary, name, last_name,mothers_name,mail }) => (<>
                                    <option value={id_beneficiary}>{nombreCompleto(name,last_name,mothers_name) }</option>
                                </>))}
                            </select>
                        </>}
                    </Stack>
                    <Stack>
                            {alumnos && 
                                    alumnos.data.map(({ name, last_name, mothes_name, on_season, id_season_children }) => (<>
                                        <Box sx={{ display: "flex", justifyItems:"center"}}>
                                        {(on_season === 1) && <>
                                            <CheckBoxIcon fontSize={'small'} />
                                                {nombreCompleto(name, last_name, mothes_name)}
                                            </>}
                                        </Box>
                                        {(on_season === 0) && <div key={id_season_children}>
                                            <input
                                                type="checkbox"
                                                id={id_season_children}
                                                value={id_season_children}
                                                checked={selectedOptions.includes(id_season_children)}
                                                onChange={handleCheckboxChanges}
                                            />
                                            <label htmlFor={id_season_children}>{nombreCompleto(name, last_name, mothes_name)}</label>
                                        </div>}
                                </>))}
                            <button onClick={handleButtonClick}>Validar</button>
                        
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button type="submit" id="formAdd">Validar</Button>
                    <Button variant="contained" color="error" onClick={() => handleCloseModalAgregarB()}>Cerrar</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default TemporadasDetail;