import { Box, Grid, TextField, Button, IconButton, Stack, Divider, Chip, InputLabel, Select, FormControl, FormControlLabel, FormLabel, MenuItem, CircularProgress, Typography, Alert, Card, CardContent, Radio, RadioGroup, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAuth } from "../../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import UploadIcon from "@mui/icons-material/Upload";
import SaveIcon from '@mui/icons-material/Save';
import Toast from "../../../general/Toast";
import { profilesAclaraciones } from "../../../../utils/trazabilidad/aclaraciones/profilesAclaraciones";
import { getClarificationCarriers, generateClarificationGuide } from "../../../../utils/trazabilidad/aclaraciones/aclaraciones";
import { getGuides } from "../../../../utils/trazabilidad/pedidos/pedidos";

const GuiasTab = ({ aId, detalle }) => {
  const { user } = useAuth();
  const [refresh, setRefresh] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [guias, setGuias] = useState([]);
  const [carriers, setCarriers] = useState([]);
  const [carrier, setCarrier] = useState(null);
  const [services, setServices] = useState([]);
  const [service, setService] = useState(null);
  const [opena, setOpenA] = useState(null);
  const [confalert, setConfAlert] = useState({
    severidad: "success",
    mensaje: "",
  });

  const handleOpenA = (severidad, mensaje) => {
    setConfAlert({ severidad: severidad, mensaje: mensaje });
    setOpenA(true);
  };

  const handleCloseA = () => {
    setOpenA(false);
  };

  const handleChangeCarrier = (event) => {
    let value = event.target.value;
    setCarrier(value);
    setServices(value.services ?? []);
  };

  function isDriveLink(link){
    return link.includes('drive.google.com');
  }

  const handleGenerateGuia = async () => {
    try {
      setIsLoading(true);
      let aux = { numE: detalle.dir_numero_ext, celular: detalle.telephone };
      if(detalle.dir_numero_int)
        aux = { numE: detalle.dir_numero_ext, numI: detalle.dir_numero_int, celular: detalle.telephone };
      let resp = await generateClarificationGuide(aId, user.data.awsid, detalle.id_orden, carrier.id_tz_carriers, aux);
      if(resp){
        handleOpenA("success", "Guía generada con éxito");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
      else handleOpenA("error", "La guía no pudo ser generada, intente de nuevo más tarde");
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        if(detalle.guia)
        {
          setIsLoading(true);
          const resp = await getClarificationCarriers();
          setCarriers(resp);
          const resp_2 = await getGuides(detalle.id_orden, 0);
          setGuias(resp_2);
        }else{
          setIsLoading(true);
          const resp = await getClarificationCarriers();
          setCarriers(resp);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    getData();
  }, [aId, refresh]);

  return (
  <>
    <Box sx={{ mb: 2, ml: 2, mr: 2 }}>

      {(detalle.status_acl === 2 && !detalle.guia && profilesAclaraciones.editarGuias.includes( user.role.idProfile )) && (
        <Grid container sx={{ my: 2 }}>
          <Grid item md={4}></Grid>
          <Grid item md={4}>
            <Alert variant="filled" severity="info" icon={false}>
              <Typography variant="p"><strong>Generar y asignar guía.</strong></Typography>
            </Alert>
            <Card>
              <CardContent>
                <FormControl fullWidth sx={{ mb: 2}}>
                  <InputLabel id="selecteC" htmlFor="carrierselect">Seleccionar Carrier</InputLabel>
                  <Select id="carrierselect" label="Seleccionar Carrier" value={carrier} onChange={handleChangeCarrier}>
                    <MenuItem value=""><em>Seleccionar Carrier</em></MenuItem>
                  {(carriers.length > 0) && carriers.map((row) => (
                    <MenuItem key={row.id_tz_carriers} value={row}>{row.nombre}</MenuItem>
                  ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth sx={{ mb: 2}}>
                  <InputLabel id="selecteS" htmlFor="serviceselect">Seleccionar Servicio</InputLabel>
                  <Select id="serviceselect" label="Seleccionar Servicio" value={service} onChange={(e) => setService(e.target.value)}>
                    <MenuItem value=""><em>Seleccionar Servicio</em></MenuItem>
                  {(services.length > 0) && services.map((row) => (
                    <MenuItem key={row.id_tipo_servicio} value={row.id_tipo_servicio}>{row.servicio}</MenuItem>
                  ))}
                  </Select>
                </FormControl>

                <LoadingButton sx={{ mt: 2 }} loading={isLoading} fullWidth size="medium" variant="contained" color="success" onClick={handleGenerateGuia} disabled={!carrier || !service}>
                  Asignar guía
                </LoadingButton>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={4}></Grid>
        </Grid>
      )}

    </Box>
    <Toast open={opena} message={confalert.mensaje} type={confalert.severidad} onClose={handleCloseA} />
  </>
  );
};

export default GuiasTab;
