import { useState, useEffect } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  FormControl,
  FormHelperText,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import {
  createChildren,
  getGradesSchool,
} from "../../../utils/vales/beneficiarios/beneficiarios";

const TapAddChildren = ({
  onClose,
  creationUsr,
  schools,
  beneficiaryIdUpdate,
  setLoading,
  setRows,
  setEstado,
  setValue,
}) => {
  const [nameC, setNameC] = useState("");
  const [apellidoPC, setApellidoPC] = useState("");
  const [apellidoMC, setApellidoMC] = useState("");
  const [selectedSchoolIdC, setSelectedSchoolIdC] = useState("");
  const [gradeExternalC, setGradeExternalC] = useState([]);
  const [gradeExternalIdC, setGradeExternalIdC] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [errorsC, setErrorsC] = useState({});

  useEffect(() => {
    setGradeExternalC([]);
    const fetchGradesSchoolData = async () => {
      if (selectedSchoolIdC) {
        try {
          const response = await getGradesSchool(selectedSchoolIdC);
          const userData = response.data;
          setGradeExternalC(userData);
        } catch (error) {
          console.error("Error al obtener los datos:", error);
        }
      }
    };

    fetchGradesSchoolData();
  }, [selectedSchoolIdC]);

  const handleAdd = async () => {
    let tempErrors = {};

    if (nameC.length <= 3)
      tempErrors.nameC = "El nombre debe tener más de 3 caracteres";
    if (apellidoPC.length <= 3)
      tempErrors.apellidoPC =
        "El apellido paterno debe tener más de 3 caracteres";
    if (apellidoMC.length <= 3)
      tempErrors.apellidoMC =
        "El apellido materno debe tener más de 3 caracteres";
    if (selectedSchoolIdC === "")
      tempErrors.selectedSchoolIdC = "Selecciona el colegio";
    if (gradeExternalIdC === "")
      tempErrors.gradeExternalIdC = "Selecciona el grado";

    setErrorsC(tempErrors);

    if (Object.keys(tempErrors).length === 0) {
      let data = {
        name: nameC,
        last_name: apellidoPC,
        mothers_name: apellidoMC,
        id_school: selectedSchoolIdC,
        id_grade: gradeExternalIdC,
        id_beneficiary: beneficiaryIdUpdate,
        creationUsr,
      };
      setLoadingForm(true);
      await new Promise(resolve => setTimeout(resolve, 500));
      await createChildren(data);
      setLoadingForm(false);
      setSnackbarOpen(true);
      setNameC("");
      setApellidoMC("");
      setApellidoPC("");
      setSelectedSchoolIdC("");
      setGradeExternalIdC("");
    }
  };

  const handleSchoolChange = (event) => {
    setSelectedSchoolIdC(event.target.value);
    setGradeExternalIdC("");
  };

  const handleGradeChange = (event) => {
    setGradeExternalIdC(event.target.value);
  };

  const onCloseAndClear = () => {
    onClose();
    setLoading(true);
    setRows([]);
    setEstado((prevEstado) => (prevEstado === 0 ? 1 : 0));
    setValue(0);
  };

  return (
    <>
      {loadingForm ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "530px",
            height: "368px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <List sx={{ padding: 0 }}>
          <ListItem>
            <TextField
              variant="outlined"
              required
              fullWidth
              label="Nombre"
              value={nameC}
              onChange={(e) => setNameC(e.target.value)}
              error={!!errorsC.nameC}
              helperText={errorsC.nameC}
              sx={{ width: "500px" }}
            />
          </ListItem>
          <ListItem>
            <TextField
              variant="outlined"
              required
              fullWidth
              label="Primer Apellido"
              value={apellidoPC}
              onChange={(e) => setApellidoPC(e.target.value)}
              error={!!errorsC.apellidoPC}
              helperText={errorsC.apellidoPC}
              sx={{ width: "500px" }}
            />
          </ListItem>
          <ListItem>
            <TextField
              variant="outlined"
              required
              fullWidth
              label="Segundo Apellido"
              value={apellidoMC}
              onChange={(e) => setApellidoMC(e.target.value)}
              error={!!errorsC.apellidoMC}
              helperText={errorsC.apellidoMC}
              sx={{ width: "500px" }}
            />
          </ListItem>
          <ListItem>
            <FormControl
              variant="outlined"
              sx={{ width: "500px" }}
              error={!!errorsC.selectedSchoolIdC}
            >
              <InputLabel id="school-label" required>
                Colegio
              </InputLabel>
              <Select
                labelId="school-label"
                label="Colegio"
                required
                value={selectedSchoolIdC}
                onChange={handleSchoolChange}
              >
                {schools.map((school) => (
                  <MenuItem key={school.school_id} value={school.school_id}>
                    {school.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errorsC.selectedSchoolIdC}</FormHelperText>
            </FormControl>
          </ListItem>
          <ListItem>
            <FormControl
              variant="outlined"
              sx={{ width: "500px" }}
              error={!!errorsC.gradeExternalIdC}
            >
              <InputLabel id="school-label" required>
                Grado
              </InputLabel>
              <Select
                labelId="school-label"
                label="Grado"
                required
                value={gradeExternalIdC}
                onChange={handleGradeChange}
              >
                {gradeExternalC.map((grade) => (
                  <MenuItem key={grade.sltg_id} value={grade.grade_external_id}>
                    {grade.grade_alias_name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errorsC.gradeExternalIdC}</FormHelperText>
            </FormControl>
          </ListItem>
        </List>
      )}

      <DialogActions>
        <Button onClick={onCloseAndClear} color="error" variant="outlined">
          Cerrar
        </Button>
        <Button onClick={handleAdd} color="success" variant="outlined">
          Agregar
        </Button>
      </DialogActions>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="success"
          variant="filled"
        >
          Datos Agregados Correctamente
        </Alert>
      </Snackbar>
    </>
  );
};

export default TapAddChildren;
