import { Box, Grid, Button, Stack, IconButton, Typography, TextField, Accordion, AccordionSummary, AccordionDetails, Card, CardContent, InputLabel,  Select,  FormControl, MenuItem, Radio, RadioGroup, FormControlLabel, FormLabel, Checkbox, CircularProgress } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useCallback, useEffect, useState } from "react";
import DateInTable from "../../general/DateInTable";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import { getLabels, searchLabels, searchDetailLabels, getPdfEEzetera } from "../../../utils/proveedores/etiquetasEzetera/etiquetasEzetera";

function DownloadButtonD({ id }) {
    const [loading, setLoading] = useState(false);
    const [downloadReady, setDownloadReady] = useState(false);
    const [fileData, setFileData] = useState(null);
    const [nameFile, setNameFile] = useState("");

    const downloadPdf = useCallback(async () => {
        setLoading(true);
        try {
            const response = await getPdfEEzetera(id);
            const data = response.data[0];
            if (data && data.label_base64_pdf) {
                setFileData(data.label_base64_pdf);
                let paymentdate = data.payment_date.split('T');
                let hora = paymentdate[1].split(':');
                let tiempo = hora[0] + hora[1] + hora[2].split('.')[0];
                let nameF = `${data.type}_${data.size.split('-')[0]}_${data.increment_id}_${paymentdate[0]}${tiempo}`;
                setNameFile(nameF);
                setDownloadReady(true);
            }
        } catch (error) {
            console.error("Error al descargar el PDF:", error);
        } finally {
            setLoading(false);
        }
    }, [id]);

    const downloadFile = useCallback(() => {
        const link = document.createElement("a");
        link.href = `data:application/pdf;base64,${fileData}`;
        link.download = `${nameFile}.pdf`;
        link.click();
    }, [fileData]);

    const isDriveLink = useCallback((link) => {
        return link.includes('drive.google.com');
    }, []);

    useEffect(() => {
        if (downloadReady) {
            if (isDriveLink(fileData)) {
                window.location.href = fileData;
            } else {
                downloadFile();
            }
            setDownloadReady(false);
        }
    }, [downloadReady, fileData, isDriveLink, downloadFile]);

    let content;
    if (loading) {
        content = <IconButton disabled><CircularProgress size={25} /></IconButton>;
    } else {
        content = <IconButton color="primary" size="small" onClick={downloadPdf}><DownloadIcon /></IconButton>;
    }

    return content;
}

const EtiquetasEzeteraDataTable = () => {
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [keyWord, setKeytoSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isAF, setIsAF] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [advancedS, setAdvancedS] = useState({
        downloaded: 0,
        type: '',
        size: 0,
        desde: '',
        hasta: ''
    });
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);

    const handleChangeAdvancedS = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        setAdvancedS((prevalue) => {
          return {
            ...prevalue,
            [name]: value
          }
        });
    }; 

    const columns = [
        {
            field: 'select',
            headerName: 'Select',
            flex: 0.3, align: "center", headerAlign: "center",
            renderCell: ({ row }) => (
              <Checkbox
                checked={selectedRows.includes(row.id_gea_ord_label)}
                onChange={() => {
                  if (selectedRows.includes(row.id_gea_ord_label)) {
                    setSelectedRows(prev => prev.filter(id_gea_ord_label => id_gea_ord_label !== row.id_gea_ord_label));
                  } else {
                    setSelectedRows(prev => [...prev, row.id_gea_ord_label]);
                  }
                }}
              />
            )
          },
        { field: "is_downloaded", headerName: "Descargado", flex: 0.6, align: "center", headerAlign: "center", 
            renderCell: ({ row }) => 
            (row.is_downloaded ? 
                (<Button variant="contained" size="small" color="success" sx={{lineHeight: 1, fontSize: "0.7rem", minWidth: 0}}>Si</Button>) 
                    : (<Button variant="contained" size="small" color="error" sx={{lineHeight: 1, fontSize: "0.7rem", minWidth: 0}}>No</Button>) ) },
        { field: "increment_id", headerName: "Pedido", flex: 0.75, align: "center", headerAlign: "center" },
        { field: "sku", headerName: "SKU", flex: 1, align: "center", headerAlign: "center" },
        { field: "payment_date", headerName: "Fecha de pago", flex: 1, renderCell: (params) => (
            <Typography><DateInTable dateString={params.row.payment_date} /></Typography>
        )},
        { field: "qty", headerName: "Cantidad", flex: 0.5, align: "center", headerAlign: "center" },
        { field: "category", headerName: "Categoría", flex: 1 , align: "center", headerAlign: "center" },
        { field: "type", headerName: "Tipo", flex: 0.5, align: "center", headerAlign: "center" },
        { field: "size", headerName: "Tamaño", flex: 2, align: "center", headerAlign: "center" },
        { field: "accion", headerName: "PDF", flex: 0.4, align: "center", headerAlign: "center", sortable: false, 
        renderCell: (params) => (params.row.id_gea_ord_label && (<DownloadButtonD id={params.row.id_gea_ord_label} />))},
    ];

    if(document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"))
        document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']").remove();

    useEffect(() => {

        let active = true;

        (async () => {

            let newRows = null;

            if(isAF){
                setLoading(true);
                newRows = await searchDetailLabels(page, advancedS);
            }
            else if(typeof keyWord === 'undefined' || keyWord === undefined || keyWord.length === 0){
                setLoading(true);
                newRows = await getLabels(page);
            }   
            else if(keyWord.length >= 3)
            {
                setLoading(true);
                newRows = await searchLabels(page, keyWord);
            }
            else active = false;

            if (!active) {
                return;
            }
            setTotal(newRows.total);
            setRows(newRows.data);
            const ids = newRows.data.map(row => row.id_gea_ord_label);
            setSelectedIds(ids);
            setLoading(false);
        })();

        return () => {
            active = false;
        };

    }, [page, keyWord, isAF, advancedS]);

    function isDriveLink(link){
        return link.includes('drive.google.com');
      }

    const downloadPdf2 = async (id) => {
        try {
            setLoading(true);
            let response = await getPdfEEzetera(id);
            let data = response.data[0];
            setLoading(false);
            if (data && data.label_base64_pdf) {
                return data;
            }
        } catch (error) {
            console.error("Error al descargar el PDF:", error);
            setLoading(false);
        }
        return null;
    };

    const handleDownloadClick = async () => {
        for (const rowId of selectedRows) {
            if (rowId !== undefined) {
                const fileData = await downloadPdf2(rowId);
                if (fileData.label_base64_pdf) {
                    if (isDriveLink(fileData.label_base64_pdf)) {
                        window.location.href = fileData.label_base64_pdf;
                    } else {
                        let paymentdate = fileData.payment_date.split('T');
                        let hora = paymentdate[1].split(':');
                        let tiempo = hora[0] + hora[1] + hora[2].split('.')[0];
                        let nameF = `${fileData.type}_${fileData.size.split('-')[0]}_${fileData.increment_id}_${paymentdate[0]}${tiempo}`;
                        
                        const link = document.createElement("a");
                        link.href = `data:application/pdf;base64,${fileData.label_base64_pdf}`;
                        link.download = `${nameF}.pdf`;
                        link.click();
                    }
                }
            }
        }
    };
    

    return (
        <Box>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography>Búsqueda avanzada</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Card>
                        <CardContent>
                            <Stack sx={{m: 2}} direction="row" spacing={3} autoComplete="off">
                                <TextField InputLabelProps={{ shrink: true }} fullWidth type={"date"} name="desde" label="Desde" variant="outlined" value={advancedS.desde} onChange={handleChangeAdvancedS} />
                                <TextField InputLabelProps={{ shrink: true }} fullWidth type={"date"} name="hasta" label="Hasta" variant="outlined" value={advancedS.hasta} onChange={handleChangeAdvancedS} />
                                <FormControl fullWidth sx={{ textAlign: 'center', alignItems: 'center' }}>
                                    <FormLabel id="descargado">Opciones</FormLabel>
                                    <RadioGroup row aria-labelledby="descargado" name="downloaded" value={advancedS.downloaded} onChange={handleChangeAdvancedS}>
                                        <FormControlLabel value={0} control={<Radio />} label="No descargado" />
                                        <FormControlLabel value={1} control={<Radio />} label="Descargado" />
                                    </RadioGroup>
                                </FormControl>
                            </Stack>
                            <Stack sx={{m: 2}} direction="row" spacing={3} autoComplete="off">
                                <FormControl fullWidth sx={{mb: 2}}>
                                    <InputLabel id="typeL" htmlFor="type">Tipo</InputLabel>
                                    <Select id="type" name="type" label="Tipo" value={advancedS.type} onChange={handleChangeAdvancedS}>
                                        <MenuItem value={""}>Seleccionar</MenuItem>
                                        <MenuItem value={"Bond"}>Bond</MenuItem>
                                        <MenuItem value={"Transparent"}>Transparent</MenuItem>
                                    </Select>
                                </FormControl>

                                <FormControl fullWidth sx={{mb: 2}}>
                                    <InputLabel id="sizeL" htmlFor="size">Tamaño</InputLabel>
                                    <Select id="size" name="size" label="Tamaño" value={advancedS.size} onChange={handleChangeAdvancedS}>
                                        <MenuItem value={0}>Seleccionar</MenuItem>
                                        <MenuItem value={30}>30</MenuItem>
                                        <MenuItem value={80}>80</MenuItem>
                                    </Select>
                                </FormControl>

                                <FormControl fullWidth sx={{mb: 2}}></FormControl>
                            </Stack>
                            <div style={{ textAlign: 'right', marginTop: '2em' }}>
                                <LoadingButton startIcon={<SearchIcon />} sx={{ mx: 2}} loading={isLoading} size="medium" variant="contained" color="success" onClick={() => setIsAF(true)}>Consultar</LoadingButton>
                            </div>
                        </CardContent>
                    </Card>
                </AccordionDetails>
            </Accordion>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Grid container sx={{ my:2 }}>
                    <Grid item md={4} sx={{ px:2 }}>
                        <Stack direction="row" spacing={1}>
                            <TextField size="small" variant="outlined" fullWidth label={'Buscar Etiqueta'} value={keyWord} onChange={(e) => {setIsAF(false); setKeytoSearch(e.target.value);}} />
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
            <Box style={{ height: 635, width: '100%' }}>
                <DataGridPro
                    rows={rows}
                    columns={columns}
                    pagination
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    getRowId={((row) => (row.id_gea_ord_label))}
                    rowCount={total}
                    paginationMode="server"
                    onPageChange={(newPage) => {
                        setPage(newPage);
                    }}
                    onSelectionModelChange={(newSelectionModel) => {
                        setSelectionModel(newSelectionModel);
                    }}
                    selectionModel={selectionModel}
                    loading={loading}
                    disableColumnMenu
                    disableSelectionOnClick
                />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "start" }}>
                <Grid container>
                    <Grid item sx={{ px: 1, my: 1 }}>
                        <Stack direction="row" spacing={1}>
                            <LoadingButton
                            variant="contained"
                            color="info"
                            onClick={() => setSelectedRows(selectedIds)}
                            >
                            Seleccionar todo
                            </LoadingButton>
                        </Stack>
                    </Grid>
                    <Grid item sx={{ px: 1, my: 1 }}>
                        <Stack direction="row" spacing={1}>
                            <LoadingButton
                            variant="contained"
                            color="info"
                            onClick={() => setSelectedRows([])}
                            >
                            Borrar Seleccion
                            </LoadingButton>
                        </Stack>
                    </Grid>
                    <Grid item sx={{ px: 1, my: 1 }}>
                        <Stack direction="row" spacing={1}>
                            <LoadingButton
                            variant="contained"
                            color="info"
                            onClick={handleDownloadClick}
                            >
                            Descargar seleccionados
                            </LoadingButton>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default EtiquetasEzeteraDataTable;