import { Typography } from "@mui/material";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const DashboardHome = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {

    if (!user) {
      navigate("/login", { replace: true });
    }
    
  }, [user, navigate]);

  return (
    <>
      <Typography variant="h4">
        Bienvenid@ de nuevo, {user.data.displayName}!
      </Typography>
    </>
  );
};

export default DashboardHome;
