import { Box, Grid, TextField, Button, IconButton, Divider, Chip, Input, InputLabel, Select, FormControl, FormHelperText, MenuItem, CircularProgress, Typography, Alert, Card, CardContent, Stack, ListSubheader, List, ListItem, ListItemText, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DescriptionIcon from "@mui/icons-material/Description";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import UploadIcon from "@mui/icons-material/Upload";
import SaveIcon from '@mui/icons-material/Save';
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../context/AuthContext";
import { useEffect, useState } from "react";
import Toast from "../../../general/Toast";
import { profilesAclaraciones } from "../../../../utils/trazabilidad/aclaraciones/profilesAclaraciones";
import { saveClarificationRefactura, getClarificationFiles, uploadClarificationFile } from "../../../../utils/trazabilidad/aclaraciones/aclaraciones";

const RefacturacionTab = ({ aId, detalle }) => {
  const { user } = useAuth();
  const [refresh, setRefresh] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [archivos, setArchivos] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [address, setAddress] = useState({
    razon: detalle.billing_address_razon,
    rfc: detalle.rfc,
    pais: detalle.country,
    estado: detalle.state,
    dele: detalle.delegation,
    calle: detalle.street,
    colonia: detalle.suburb,
    ciudad: detalle.city,
    cp: detalle.postal_code,
    ne: detalle.outside_number,
    ni: detalle.inside_number
  });
  const [opena, setOpenA] = useState(null);
  const [confalert, setConfAlert] = useState({
    severidad: "success",
    mensaje: "",
  });

  const handleOpenA = (severidad, mensaje) => {
    setConfAlert({ severidad: severidad, mensaje: mensaje });
    setOpenA(true);
  };

  const handleCloseA = () => {
    setOpenA(false);
  };

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  const handleChangeAddress = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setAddress((prevalue) => {
      return {
        ...prevalue,
        [name]: value
      }
    })
  };

  function isempt(){
    if(address.razon && address.pais && address.cp && address.estado && address.dele && address.calle && address.ne && address.colonia && address.ciudad && address.rfc)
      return false;
    else 
      return true;
  }

  function isDriveLink(link){
    return link.includes('drive.google.com');
  }

  const handleSaveSD = async () => {
    try {
      setIsLoading(true);
      let resp = await saveClarificationRefactura( detalle.id_orden, aId, user.data.awsid, address.razon, address.ciudad, address.pais, address.delegation, address.ni, address.ne, address.cp, address.rfc, address.estado, address.calle, address.colonia );
      if(resp){
        handleOpenA("success", "Refacturación generada con éxito");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
      else handleOpenA("error", "La refacturación no pudo ser generada, intente de nuevo más tarde");
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseRefacto = async () => {
    try {
      setIsLoading(true);
      //let resp = await saveClarificationRefund(detalle.id_orden, aId, user.data.awsid, ifDebit, ifCredit, noCuen, titular, bank, sucursal );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveFile = async () => {
    try {
      setIsLoading(true);
      let resp = await uploadClarificationFile(aId, selectedFile);
      if(resp){
        handleOpenA("success", "Archivo cargado con éxito");
        setSelectedFile([]);
        setIsFilePicked(false);
        setTimeout(() => {
          let au = refresh;
          setRefresh(au+1);
        }, 3000);
      }
      else handleOpenA("error", "El archivo no pudo ser cargado, intente de nuevo más tarde");
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const getFiles = async () => {
      try {
        if(detalle.status_acl_id === 5 || detalle.status_acl_id === 6)
        {
          setIsLoading(true);
          const resp = await getClarificationFiles(aId);
          setArchivos(resp);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    getFiles();
  }, [aId, refresh]);

  return (
  <>
    <Box sx={{mb: 2, ml: 2, mr: 2}}>
      {(detalle.status_acl_id === 1 && detalle.id_tipificacion_ac === 4 && profilesAclaraciones.editarRefacturacion.includes( user.role.idProfile )) && (
      <Grid container sx={{mt:2, mb:2}}>
        <Grid item md={12}>
          <Alert variant="filled" severity="info"><Typography variant="p"><strong>Datos de facturación</strong></Typography></Alert>
          <Card>
            <CardContent>
              <Stack sx={{m: 2}} direction="row" spacing={3} autoComplete="off">
                <TextField fullWidth name="razon" label="Razón Social" variant="outlined" value={address.razon} onChange={handleChangeAddress} />
                <TextField fullWidth name="rfc" label="RFC" variant="outlined" inputProps={{maxlength: 13}} value={address.rfc} onChange={handleChangeAddress} helperText="Estructura de un RFC: Morales de 12 caractéres y Físicas de 13 caractéres." />                
                <TextField fullWidth name="pais" label="País" variant="outlined" value={address.pais} onChange={handleChangeAddress} />
              </Stack>
              <Stack sx={{m: 2}} direction="row" spacing={3} autoComplete="off">
                <TextField fullWidth name="dele" label="Delegación/Municipio" variant="outlined" value={address.dele} onChange={handleChangeAddress} />
                <TextField fullWidth name="calle" label="Calle" variant="outlined" value={address.calle} onChange={handleChangeAddress} />
                <TextField fullWidth name="colonia" label="Colonia" variant="outlined" value={address.colonia} onChange={handleChangeAddress} />
              </Stack>
              <Stack sx={{mx: 2, my: 4}} direction="row" spacing={3} autoComplete="off">
                <TextField fullWidth name="estado" label="Estado" variant="outlined" value={address.estado} onChange={handleChangeAddress} />
                <TextField fullWidth name="ciudad" label="Ciudad" variant="outlined" value={address.ciudad} onChange={handleChangeAddress} />
                <TextField fullWidth name="cp" label="Código Postal" variant="outlined" value={address.cp} onChange={handleChangeAddress} />
              </Stack>
              <Stack sx={{m: 2}} direction="row" spacing={3} autoComplete="off" justifyItems={'center'}>
                <TextField name="ne" label="Número exterior" variant="outlined" value={address.ne} onChange={handleChangeAddress} />
                <TextField name="ni" label="Número interior" variant="outlined" value={address.ni} onChange={handleChangeAddress} />
              </Stack>
              <div style={{ textAlign: 'right', marginTop: '2em' }}>
                <LoadingButton sx={{ mx: 2}} loading={isLoading} size="medium" variant="contained" color="success" onClick={handleSaveSD} disabled={(isempt())}>Generar Refacturación</LoadingButton>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      )}

      {(detalle.status_acl_id === 5 || detalle.status_acl_id === 6) && (
        <Grid container sx={{ my: 2 }}>
          <Grid item md={12}><Alert variant="filled" severity="info"><Typography variant="p"><strong>Datos de refacturación</strong></Typography></Alert></Grid>
          <Grid item md={6}>
            <List>
              <ListItem>
                <ListItemText primary="Razón Social" secondary={detalle.billing_address_razon}/>
              </ListItem>
              <ListItem>
                <ListItemText primary="RFC" secondary={detalle.rfc}/>
              </ListItem>
              <ListItem>
                <ListItemText primary="Estado" secondary={detalle.state}/>
              </ListItem>
              <ListItem>
                <ListItemText primary="Calle" secondary={detalle.street}/>
              </ListItem>
              <ListItem>
                <ListItemText primary="Ciudad" secondary={detalle.city}/>
              </ListItem>
              <ListItem>
                <ListItemText primary="Código Postal" secondary={detalle.postal_code}/>
              </ListItem>
            </List>
          </Grid>
          <Grid item md={6}>
            <List>
              <ListItem>
                <ListItemText primary="País" secondary={detalle.country}/>
              </ListItem>
              <ListItem>
                <ListItemText primary="Deleg. / Munic" secondary={detalle.delegation}/>
              </ListItem>
              <ListItem>
                <ListItemText primary="Colonia" secondary={detalle.suburb}/>
              </ListItem>
              <ListItem>
                <ListItemText primary="Número Exterior" secondary={detalle.outside_number} />
              </ListItem>
              <ListItem>
                <ListItemText primary="Número Interior" secondary={detalle.inside_number ?? 'S/N'}/>
              </ListItem>
            </List>
          </Grid>

          {(detalle.status_acl === 5 || detalle.status_acl === 6) && (
            <>
              <Grid item md></Grid>            
              <Grid item md={5}>
                <Grid container sx={{ my : 2}}>
                  <Grid item md={12}>
                    <Card variant="outlined">
                      <CardContent>
                        <Typography variant="h6" component="div"><DescriptionIcon /> Archivos</Typography>
                        <Grid container sx={{ alignItems: "center" }}>
                          <Grid item md={12}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell sx={{ width: '80%'}}>Nombre</TableCell>
                                  <TableCell sx={{ width: '20%'}} align="right">Descargable</TableCell>
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                {archivos.length > 0 && archivos.map( (row) => (
                                  <TableRow key={row.id_ac_expedientes}>
                                    <TableCell component="th" scope="row">{row.nombre}</TableCell>
                                    <TableCell align="right">
                                      <IconButton loading={isLoading} color="primary" size="small">
                                        <a download href={isDriveLink(row.pdf) ? row.pdf : `data:application/${row.extension};base64,${row.pdf}` } style={{ textDecoration: "none", color: "#041C32" }} >
                                          <FileDownloadIcon />
                                        </a>
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md></Grid>
            </>
          )}

          {(detalle.status_acl === 6 && profilesAclaraciones.subirArchivosReembolso.includes( user.role.idProfile )) && (
          <>
            <Grid item md></Grid>
            <Grid item md={5}>
              <Alert variant="filled" severity="info">
                <Typography variant="p"><strong>Subir archivos</strong></Typography>
              </Alert>
              <Card>
                <CardContent>
                  <Typography align="center" sx={{ mb: 2 }}>
                    {isFilePicked ? selectedFile?.name : "Ningún archivo seleccionado"}
                  </Typography>
                  <Button align="center" variant="contained" component="label" sx={{ mb: 4 }} endIcon={<UploadIcon />} >
                    {isFilePicked ? "Elegir otro archivo" : "Elegir archivo"}
                    <input hidden accept="image/*,.pdf,.xls,.xlsx" type="file" onChange={changeHandler} />
                  </Button>

                  <LoadingButton sx={{ mt: 2 }} loading={isLoading} fullWidth size="medium" variant="contained"
                    color="info" onClick={handleSaveFile} disabled={!isFilePicked} endIcon={<SaveIcon />}>
                    Guardar archivo
                  </LoadingButton>

                  <Divider sx={{ my: 4 }}>
                    <Chip label="o" />
                  </Divider>

                  <LoadingButton loading={isLoading} fullWidth size="medium" variant="contained" color="success" onClick={handleCloseRefacto}>
                    Finalizar Refacturación
                  </LoadingButton>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md></Grid>
          </>
          )}
        </Grid>
      )}

    </Box>
    <Toast open={opena} message={confalert.mensaje} type={confalert.severidad} onClose={handleCloseA} />
  </>
  );

};

export default RefacturacionTab;
