import { profiles } from "./profilesProveedores";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
const dicon = <RadioButtonUncheckedIcon fontSize='small' />;

export const proveedoresModules = [
    {
        label: "Ventas",
        icon: dicon,
        slug: "ventas",
        profiles: profiles.ventas
    },
    {
        label: "Etiquetas Ezetera",
        icon: dicon,
        slug: "etiquetas-ezetera",
        profiles: profiles.etiquetasE
    },
    {
        label: "Etiquetas Proveedor",
        icon: dicon,
        slug: "etiquetas-proveedor",
        profiles: profiles.etiquetasP
    },
    {
        label: "Asignar OC",
        icon: dicon,
        slug: "asignar-oc",
        profiles: profiles.asignarOC
    },
    {
        label: "Pedidos Recibidos",
        icon: dicon,
        slug: "pedidos-recibidos",
        profiles: profiles.pedidosRec
    },
    {
        label: "Stock",
        icon: dicon,
        slug: "stock",
        profiles: profiles.stock
    },
    {
        label: "Movimientos Inventario",
        icon: dicon,
        slug: "movimientos",
        profiles: profiles.movimientosInv
    },
];