import { Box, LinearProgress, CircularProgress, Typography, Table, TableCell, TableContainer, TableRow, Grid } from "@mui/material";
import { useEffect, useState, Fragment } from "react";
import { getTabObservaciones } from "../../../../utils/cancelaciones/cancelaciones/cancelaciones";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TimelineOppositeContent, { timelineOppositeContentClasses} from '@mui/lab/TimelineOppositeContent';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import local from "dayjs/plugin/localizedFormat";
import relative from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";

dayjs.locale("es-mx");
dayjs.extend(local);
dayjs.extend(relative);
dayjs.extend(utc);

const ObservationsTab = ({ id }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [detalle, setDetalle] = useState(null);

    const getObservations = async () => {
        try {
            setIsLoading(true);
            const res = await getTabObservaciones(id);
            setDetalle(res);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const nombreCompleto = (nombre, apellidoP, apellidoM) => {
        const usuario = [];
        if (nombre) {
            usuario.push(nombre + " ");
        }
        if (apellidoP) {
            usuario.push(apellidoP + " ");
        }
        if (apellidoM) {
            usuario.push(apellidoM);
        }
        return (
            usuario
        );
    };

    useEffect(() => {
        getObservations();
    }, [id]);

    return (<>
        {isLoading && <LinearProgress />}
        {detalle && (<>
            <Timeline sx={{ [`& .${timelineOppositeContentClasses.root}`]: { flex: 0.3, } }}>
                {detalle.data.map(({nombre,apellidoP,apellidoM,created_atl, log}) => (
                    <TimelineItem>
                        <TimelineOppositeContent sx={{ m: 'auto 0' }} align="right" variant="body2" color="text.secondary">
                            <ListItemText>
                                <Typography><strong>{nombreCompleto(nombre, apellidoP, apellidoM)}</strong></Typography>
                            </ListItemText>
                            <ListItemText>
                                <Typography variant="caption">{dayjs.utc(created_atl).format("DD MMM YYYY, hh:mm A")}</Typography>
                            </ListItemText>
                            <ListItemText>
                                <Typography variant="caption">{dayjs.utc(created_atl).from(dayjs().utc(true))}</Typography>
                            </ListItemText>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot />
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <ListItemText primary={log} />
                        </TimelineContent>
                    </TimelineItem>
                ))}
            </Timeline>

        </>)}
    </>);
};

export default ObservationsTab;