import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import { useEffect } from "react";
import {
  getUserRHId,
  updateUserRH,
} from "../../../utils/vales/configuracion/configuracion";

const ModalUpdateUserRH = ({
  open,
  onClose,
  userId,
  updateUsr,
  setLoading,
  setRows,
  setEstado,
}) => {
  const [name, setName] = useState("");
  const [apellidoP, setApellidoP] = useState("");
  const [apellidoM, setApellidoM] = useState("");
  const [mail, setMail] = useState("");
  const [pass, setPass] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchUserData = async () => {
      if (open && userId) {
        try {
          const response = await getUserRHId(userId);
          const userData = response.data[0];

          setName(userData.nombre || "");
          setApellidoP(userData.apellidoP || "");
          setApellidoM(userData.apellidoM || "");
          setMail(userData.mail || "");
          setPass(userData.pass || "");
        } catch (error) {
          console.error("Error al obtener los datos del usuario:", error);
        }
      }
    };

    fetchUserData();
  }, [open, userId]);

  const validate = () => {
    let newErrors = {};

    if (name.length < 3)
      newErrors.name = "El nombre debe tener al menos 3 caracteres.";
    if (apellidoP.length < 3)
      newErrors.apellidoP =
        "El apellido paterno debe tener al menos 3 caracteres.";
    if (apellidoM.length < 3)
      newErrors.apellidoM =
        "El apellido materno debe tener al menos 3 caracteres.";
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(mail))
      newErrors.mail = "El formato del correo es inválido.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (validate()) {
      const userData = {
        idUser: userId,
        nombre: name,
        apellidoP,
        apellidoM,
        mail,
        updateUsr,
      };

      if (pass.length > 1) {
        userData.pass = pass;
      }

      setLoading(true);
      setRows([]);
      onClose();
      await updateUserRH(userData);
      setEstado((prevEstado) => (prevEstado === 0 ? 1 : 0));
    }
  };

  const onCloseAndClear = () => {
    onClose();
    setName("");
    setApellidoP("");
    setApellidoM("");
    setMail("");
    setPass("");
    setErrors({});
  };

  return (
    <Dialog open={open} onClose={onCloseAndClear} maxWidth="sm" fullWidth>
      <DialogTitle>Editar Usuario</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          margin="dense"
          label="Nombre"
          value={name}
          onChange={(e) => setName(e.target.value)}
          error={!!errors.name}
          helperText={errors.name}
          required
        />
        <TextField
          fullWidth
          margin="dense"
          label="Apellido Paterno"
          value={apellidoP}
          onChange={(e) => setApellidoP(e.target.value)}
          error={!!errors.apellidoP}
          helperText={errors.apellidoP}
          required
        />
        <TextField
          fullWidth
          margin="dense"
          label="Apellido Materno"
          value={apellidoM}
          onChange={(e) => setApellidoM(e.target.value)}
          error={!!errors.apellidoM}
          helperText={errors.apellidoM}
          required
        />
        <TextField
          fullWidth
          margin="dense"
          label="Correo Electrónico"
          value={mail}
          onChange={(e) => setMail(e.target.value)}
          error={!!errors.mail}
          helperText={errors.mail}
          required
        />
        <TextField
          fullWidth
          margin="dense"
          label="Contraseña"
          value={pass}
          onChange={(e) => setPass(e.target.value)}
          type="password"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseAndClear} color="error" variant="outlined">
          Cancelar
        </Button>
        <Button onClick={handleSubmit} color="success" variant="outlined">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalUpdateUserRH;
