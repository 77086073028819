import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { deleteSeason } from "../../../utils/vales/configuracion/configuracion";

const ModalDeleteTemporada = ({
  open,
  onClose,
  selectedSeasonName,
  selectedSeasonId,
  idSeason,
  setLoading,
  setRows,
  setEstado,
}) => {
  const handleConfirmDelete = async () => {
    let data = {
      idUser: idSeason,
      idSeason: selectedSeasonId,
    };
    setLoading(true);
    setRows([]);
    onClose();
    await deleteSeason(data);
    setEstado((prevEstado) => (prevEstado === 0 ? 1 : 0));
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{`Eliminar ${selectedSeasonName}`}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          ¿Estás seguro de eliminar esta temporada?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error" variant="outlined">
          Cancelar
        </Button>
        <Button
          onClick={() => {
            handleConfirmDelete();
            onClose();
          }}
          color="success"
          variant="outlined"
          autoFocus
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalDeleteTemporada;
