import { Box, CircularProgress, Typography, Chip, Table, TableHead, TableContainer, TableBody, TableCell, TableRow } from "@mui/material";
import { useEffect, useState } from "react";

const chipColors = {
    "1": "#dd4b39",
    "2": "#f39c12",
    "3": "#007aa6",
    "4": "#000000",
    "5": "#000000",
    "6": "#00a65a",
    "7": "#000000",
    "8": "#b73384",
    "9": "#000000",
    "10": "#000000",
    "11": "#616161",
    "12": "#000000"
};
  
const chipLabels = {
    "1": "Nuevo",
    "2": "Pedido Recibido, en espera de resolución",
    "3": "En espera de datos de envio",
    "4": "En espera de datos de reembolso",
    "5": "En espera de datos",
    "6": "Liberado",
    "7": "En espera de Store Credit",
    "8": "En espera de reembolso",
    "9": "En espera de reembolsos",
    "10": "Cerrado",
    "11": "No aplica",
    "12": "En espera de envio"
};

const RmaStatus = ({ rmaId, status }) => {
    const [isLoading, setIsLoading] = useState(false);

    return (
        <Box sx={{ mt: 2, mb: 2 }}>
            <TableContainer>
                {isLoading && <CircularProgress />}
                {status && (<Table size="small">
                    <TableHead>
                        <TableCell>Store Credit</TableCell>
                        <TableCell>Reembolso</TableCell>
                        <TableCell>Cambio</TableCell>
                    </TableHead>
                    <TableBody>
                        <TableRow >
                        <TableCell sx={{ textAlign: 'center' }} colSpan={(status.id_status_storeCredit === 2 && status.id_status_reembolso === 2 && status.id_status_cambio === 2) ? 3 : 1}>
                            <Chip label={chipLabels[status.id_status_storeCredit]} size="small" 
                                sx={{ backgroundColor: chipColors[status.id_status_storeCredit], color: "#fff" }}
                            />
                        </TableCell>
                        <TableCell sx={{ textAlign: 'center', display: (status.id_status_storeCredit === 2 && status.id_status_reembolso === 2 && status.id_status_cambio === 2) ? 'none' : '' }}>
                            <Chip label={chipLabels[status.id_status_reembolso]} size="small"
                            sx={{ backgroundColor: chipColors[status.id_status_reembolso], color: "#fff" }}
                            />
                        </TableCell>
                        <TableCell sx={{ textAlign: 'center', display: (status.id_status_storeCredit === 2 && status.id_status_reembolso === 2 && status.id_status_cambio === 2) ? 'none' : '' }}>
                            <Chip label={chipLabels[status.id_status_cambio]} size="small"
                            sx={{ backgroundColor: chipColors[status.id_status_cambio], color: "#fff" }}
                            />
                        </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                )}
            </TableContainer>
        </Box>
    );
}

export default RmaStatus;