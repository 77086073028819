import { useState, useEffect } from "react";
import { Box, Grid, Stack, TextField, styled } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useAuth } from "../../../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import {
  getProducts,
  searchProducts,
} from "../../../../../utils/ventaMayoreo/validaciones/validaciones";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export const ProductosDataTable = ({
  idRequerimiento,
  idRequerimientoProducto,
}) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [keyWord, setKeytoSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);

  const columns = [
    {
      field: "nombre",
      headerName: "Producto",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "sku",
      headerName: "SKU",
      flex: 0.8,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "cantidad",
      headerName: "Cantidad",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "validacion",
      headerName: "Validación",
      flex: 0.8,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => (
        <Box
          sx={{
            backgroundColor: "#00c0ef",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          {row.id_status === 6 ? (
            <CloseIcon sx={{ color: "white" }} />
          ) : (
            <CheckCircleIcon sx={{ color: "white" }} />
          )}
        </Box>
      ),
    },
    {
      field: "proveedor",
      headerName: "Proveedor",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: ({ row }) =>
        row.razon_social ? row.razon_social : "S/Proveedor",
    },
    {
      field: "tipoValidacion",
      headerName: "Tipo validación",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: ({ row }) =>
        row.validacion_status ? row.validacion_status : "S/Validación",
    },
  ];

  if (
    document.querySelector(
      ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
    )
  )
    document
      .querySelector(
        ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
      )
      .remove();

  useEffect(() => {
    let active = true;

    (async () => {
      let newRows = null;

      if (
        typeof keyWord === "undefined" ||
        keyWord === undefined ||
        keyWord.length === 0
      ) {
        setLoading(true);
        newRows = await getProducts(
          page,
          idRequerimiento,
          idRequerimientoProducto
        );
      } else if (keyWord.length >= 3) {
        setLoading(true);
        newRows = await searchProducts(
          page,
          idRequerimiento,
          idRequerimientoProducto,
          keyWord
        );
      } else active = false;

      if (!active) {
        return;
      }
      setTotal(newRows.total);
      setRows(newRows.data);
      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [page, keyWord]);

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Grid container sx={{ mb: 2, mt: 2 }}>
          <Grid item md={4} sx={{ px: 2 }}>
            <Stack direction="row" spacing={1}>
              <TextField
                size="small"
                variant="outlined"
                fullWidth
                label={`Buscar`}
                value={keyWord}
                onChange={(e) => setKeytoSearch(e.target.value)}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box style={{ height: 635, width: "100%" }}>
        <DataGridPro
          rows={rows}
          columns={columns}
          pagination
          pageSize={10}
          rowsPerPageOptions={[10]}
          getRowId={(row) => row.id_requerimiento_producto}
          rowCount={total}
          paginationMode="server"
          onPageChange={(newPage) => {
            setPage(newPage);
          }}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
          loading={loading}
          disableColumnMenu
          disableSelectionOnClick
          getDetailPanelHeight={() => "auto"}
        />
      </Box>
    </>
  );
};
