const profiles = {
    accesos: [1,],
    solicitudes: [1,],
    administracion: [1,],
    reportes: [1,],
    matrices: [1,],
    adminProv: [1,],
};

const belongsControlAccesos = ( profile ) => {
    let aux = profiles;
    return  aux.accesos.includes( profile ) ? true : aux.solicitudes.includes( profile )
            ? true : aux.administracion.includes( profile ) ? true : aux.reportes.includes( profile )
            ? true : aux.matrices.includes( profile ) ? true : aux.adminProv.includes( profile );
}

export{
    profiles, belongsControlAccesos
};