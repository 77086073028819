import {
  Box,
  Grid,
  Button,
  Stack,
  IconButton,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  InputLabel,
  Select,
  FormControl,
  Menu,
  MenuItem,
  Chip,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Paper,
  Icon,
  CircularProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import DateInTable from "../../general/DateInTable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/Download";
import InboxIcon from "@mui/icons-material/Inbox";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { EventNote, Search } from "@mui/icons-material";
import {
  getLevel,
  getSalesIndex,
  getVentasNetas,
} from "../../../utils/proveedores/ventas/ventas";
import LevelList from "./LevelList";
import ExcelFile from "../../general/ExcelFile";

const VentasDataTable = () => {
  const { user } = useAuth();
  const [isELoading, setIsELoading] = useState(false);
  const [excelData, setExcelData] = useState(null);
  const [loadingRows, setloadingRows] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [errors, setErrors] = useState({});
  const [level, setLevel] = useState([]);

  const getAllExcelData = async () => {
    try {
      setIsELoading(true);
      const data = await getVentasNetas(344, startDate, endDate);
      setExcelData(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsELoading(false);
    }
  };

    if (
      document.querySelector(
        ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
      )
    )
      document
        .querySelector(
          ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
        )
        .remove();

  const validate = () => {
    let newErrors = {};
    if (!startDate) newErrors.startDate = "Fecha de inicio es requerida.";
    if (!endDate) newErrors.endDate = "Fecha final es requerida.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (validate()) {
      //   setStartDateF(startDate);
      //   setEndDateF(endDate);
      setloadingRows(true);
      let level = await getLevel(33, startDate, endDate);
      setLevel(level.data);
      setloadingRows(false);
    }
  };

  const handleDateChange = (field, date) => {
    if (field === "startDate") {
      setStartDate(date);
      setExcelData(null);

      if (endDate && new Date(date) > new Date(endDate)) {
        handleDateError();
      }
    } else if (field === "endDate") {
      setEndDate(date);
      setExcelData(null);

      if (startDate && new Date(date) < new Date(startDate)) {
        handleDateError();
      }
    }
  };

  const handleDateError = () => {
    setStartDate(null);
    setEndDate(null);
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Grid container sx={{ mb: 2, mt: 2 }}>
          <Grid item md={6} sx={{ px: 2 }}>
            <Stack direction="row" spacing={1}>
              <Grid container alignItems="center">
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    margin="dense"
                    label="Fecha de inicio"
                    type="date"
                    value={startDate || ""}
                    onChange={(e) =>
                      handleDateChange("startDate", e.target.value)
                    }
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.startDate}
                    helperText={errors.startDate}
                    size="small"
                    required
                  />
                </Grid>
                <Grid item xs={4} sx={{ marginLeft: "1px" }}>
                  <TextField
                    fullWidth
                    margin="dense"
                    label="Fecha final"
                    type="date"
                    value={endDate || ""}
                    onChange={(e) =>
                      handleDateChange("endDate", e.target.value)
                    }
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ min: startDate }}
                    disabled={!startDate}
                    error={!!errors.endDate}
                    helperText={errors.endDate}
                    size="small"
                    required
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleSubmit}
                    sx={{
                      minWidth: "auto",
                      padding: "6px",
                      marginTop: "3.1px",
                      marginLeft: "2px",
                    }}
                    disabled={!startDate || !endDate}
                  >
                    <SearchIcon />
                  </Button>
                </Grid>
              </Grid>
            </Stack>
          </Grid>
          <Grid item md sx={{ px: 2, textAlign: "right" }}>
            {!excelData && (
              <LoadingButton
                loading={isELoading}
                variant="contained"
                color="info"
                onClick={getAllExcelData}
                disabled={!startDate || !endDate}
              >
                Generar excel
              </LoadingButton>
            )}
            {excelData && (
              <ExcelFile excelData={excelData} fileName={"reporteVentas_"} />
            )}
          </Grid>
        </Grid>
      </Box>
      <Box style={{ minHeight: 635, width: "100%" }}>
        {loadingRows && (
          <Paper
            elevation={3}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              minHeight: 635,
              padding: "20px",
            }}
          >
            <CircularProgress color="primary" />
          </Paper>
        )}
        {level.length !== 0 && !loadingRows ? (
          <box>
            {level.map((item) => (
              <LevelList
                key={item.id_grid}
                Nivel={item.Nivel}
                idNivel={item.Id_nivel}
                SumaValorStock={item.SumaValorStock}
                startDate={startDate}
                endDate={endDate}
              />
            ))}
          </box>
        ) : (
          !loadingRows && (
            <Paper
              elevation={3}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                minHeight: 635,
                padding: "20px",
              }}
            >
              <Icon color="primary">
                <EventNote />
              </Icon>
              <Typography variant="h5" style={{ marginTop: 10 }}>
                No hay ninguna información con la fecha indicada.
              </Typography>
              <Box></Box>
              <Typography variant="subtitle1" sx={{ marginTop: 1 }}>
                Seleccionar otro rango de fechas.
              </Typography>
            </Paper>
          )
        )}
      </Box>
    </>
  );
};

export default VentasDataTable;
