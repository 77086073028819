import { Breadcrumbs, Typography, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';

const BreadCrumb = ({ items }) => {
    const navigate = useNavigate();

    return (
        <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} color="inherit" onClick={() => navigate("/dashboard", { replace: true })} >
                <HomeIcon sx={{ mr: 0.5 }} fontSize="small" /> Dashboard
            </Link>
            {(items && items.length > 0) && items.map((row) => (
                <>
                {(row?.link) && (
                    <Link underline="hover" sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} color="inherit" onClick={() => navigate(row.link, { replace: true })} >
                        {row.icon ?? ''} {row.label}
                    </Link>
                )}

                {(! row?.link) && (
                    <Typography sx={{ display: 'flex', alignItems: 'center' }} color="text.primary" >
                        {row.icon ?? ''} {row.label}
                    </Typography>
                )}
                </>
            ))}
        </Breadcrumbs>
    );
};

export default BreadCrumb;