import {
  Box,
  Button,
  Card,
  CardContent,
  LinearProgress,
  Typography,
  Tabs,
  Tab,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Container,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useContext, useEffect, useRef, useState } from "react";
import { useAuth } from "../../../../context/AuthContext";
import { profiles } from "../../../../utils/trazabilidad/profilesTrazabilidad";
import BreadCrumb from "../../../general/BreadCrumb";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import { addSalesOrder } from "../../../../utils/ventaMayoreo/cotizaciones/cotizaciones";

const itemsBreadCrumbs = [
  {
    label: "Venta mayoreo",
    icon: <ContentPasteSearchIcon sx={{ mr: 0.5 }} fontSize="small" />,
    link: "/venta-mayoreo",
  },
  { label: "Cotizaciones", link: "/venta-mayoreo/cotizaciones" },
  { label: "Orden De Venta" },
];

const OrdenDeVenta = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;
  const [isLoading, setIsLoading] = useState(false);
  const [ordenVenta, setOrdenVenta] = useState("");

  useEffect(() => {
    if (!profiles.rma.includes(auth.user.role.idProfile)) {
      navigate("/dashboard", { replace: true });
    } else {
      const getDetallePedido = async () => {
        try {
          setIsLoading(true);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      };
      getDetallePedido();
    }
  }, [id, auth.user.data.awsid]);

  const handleChangeOrdenVenta = (event) => {
    setOrdenVenta(event.target.value);
  };

  const handleAgregarOrdenVenta = async () => {
    let data = {
      idCotizacion: parseInt(id),
      ordenVenta,
    };
    await addSalesOrder(data);
    navigate(`/venta-mayoreo/cotizaciones`);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" sx={{ my: 2 }}>
          Orden De Venta
        </Typography>
        <Box>
          <BreadCrumb items={itemsBreadCrumbs} />
        </Box>
      </Box>

      <motion.div
        initial={{ y: 20 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Card>
          <Box>
            <Box>
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <Typography variant="h6" sx={{ ml: 2, my: 2, mb: 0 }}>
                    Orden De Venta
                  </Typography>
                </Grid>
              </Grid>
              <Container maxWidth="md" sx={{ marginY: "40px" }}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  mt={2}
                >
                  <TextField
                    label="Ingrese la orden de venta"
                    variant="outlined"
                    value={ordenVenta}
                    onChange={handleChangeOrdenVenta}
                    sx={{ width: "100%" }}
                  />
                  <Box mt={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleAgregarOrdenVenta}
                    >
                      Agregar orden de venta
                    </Button>
                  </Box>
                </Box>
              </Container>
            </Box>
          </Box>
        </Card>
      </motion.div>
    </>
  );
};

export default OrdenDeVenta;
