import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const ModalEliminar = ({
  openDeleteDialog,
  handleCloseDeleteDialog,
  handleConfirmDelete,
}) => {
  return (
    <Dialog
      open={openDeleteDialog}
      onClose={handleCloseDeleteDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"¿Estás seguro de que deseas eliminar este coordinador?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Eliminar Coordinador.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="error"
          onClick={handleCloseDeleteDialog}
        >
          Cancelar
        </Button>
        <Button
          variant="outlined"
          color="success"
          autoFocus
          onClick={handleConfirmDelete}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalEliminar;
