import { LoadingButton } from "@mui/lab";
import { Chip, TableBody, TableCell, TableRow, Button, Menu, MenuItem, Typography, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import DateInTable from "../../../general/DateInTable";
import FileDownloadIcon from '@mui/icons-material/FileDownload';

function isJSON(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

const CargasTableBody = ( cargas, loading ) => {
    const navigate = useNavigate();

    return (
        <TableBody>
        {cargas.cargas.map((row) => (
            <TableRow key={row.id}>
                <TableCell>
                    <a download href={"data:text/csv;charset=utf-8;base64,"+row.content} style={{ textDecoration: 'none', color: '#fff'}}>
                        <IconButton aria-label="descargar">
                            <FileDownloadIcon />
                        </IconButton>
                    </a>
                </TableCell>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.file}</TableCell>
                <TableCell><DateInTable dateString={row.upload_date} /></TableCell>                
                {isJSON(row.logs) && (
                    <>
                    <TableCell>Creadas: {JSON.parse(row.logs).creadas}, Resueltas: {JSON.parse(row.logs).resueltas}, Errores: {JSON.parse(row.logs).error}</TableCell>
                    <TableCell>
                        {JSON.parse(row.logs).errores.length > 0 && (
                            JSON.parse(row.logs).errores.map((row) => (
                                <p key={row}>{row}</p>
                            ))
                        )}
                    </TableCell>
                    </>
                )}
                {!isJSON(row.logs) && (
                    <>
                    <TableCell>{row.logs}</TableCell>
                    <TableCell> </TableCell>
                    </>
                )}
            </TableRow>
        ))}
        </TableBody>
    );
};

export default CargasTableBody;