import useSWR from "swr";
import {
  Box,
  Chip,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { useLocation } from "react-router-dom";
import DateInTable from "../general/DateInTable";
import { searchTicket } from "../../utils/crm/tickets";
import TicketsTableBody from "./TicketsTableBody";

const chipColors = {
  Cerrado: "#f44336",
  Canalizado: "#673ab7",
  Tomado: "#1e88e5",
  Nuevo: "#43a047",
};

const useTickets = (currentPage) => {
  const fetcher = (...args) => fetch(...args).then((res) => res.json());
  const url = `${process.env.REACT_APP_BASEURL}/crm/tickets/new?page=${currentPage}`;
  const { data } = useSWR(url, fetcher, {
    refreshInterval: 2000,
    revalidateOnFocus: true,
  });

  return {
    page: currentPage,
    rowsPerPage: data?.data?.length ?? 0,
    rows: data?.data ?? [],
    total: data?.total ?? 0,
  };
};

const TicketsTable = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState({});
  const tickets = useTickets(currentPage);
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [ticketABuscar, setTicketABuscar] = useState("");
  const [ticketsFiltrados, setTicketsFiltrados] = useState(null);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const validateTicket = async (ticket) => {
    setLoading({ ...loading, [ticket]: true });
    try {
      const email = user.data.email;
      const row = tickets.rows.find((r) => r.id_crm_main === ticket);
      if (row.status !== "Tomado") {
        const res = await axios.put(`${process.env.REACT_APP_BASEURL}/crm/tickets/assign?id=${ticket}&mail=${email}`);
        if (!res?.data?.success) {
          throw new Error("User assign failed from server");
        }
      }
      setLoading({ ...loading, [ticket]: false });
      if (location.pathname.lastIndexOf("/") === location.pathname.length - 1) {
        navigate(`ticket/${ticket}`);
      } else {
        navigate(`ticket/${ticket}`);
      }
    } catch (error) {
      console.log("validateTicket", error);
    }

    setLoading({ ...loading, [ticket]: false });
  };

  useEffect(() => {
    const handleSearchTicket = async () => {
      if (ticketABuscar.length < 5) return;
      try {
        setIsLoading(true);
        const ticketsF = await searchTicket(ticketABuscar);
        setTicketsFiltrados(ticketsF);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    handleSearchTicket();
  }, [ticketABuscar]);

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <TextField
          size="small"
          variant="filled"
          label="Buscar ticket"
          sx={{ m: 1 }}
          value={ticketABuscar}
          onChange={(e) => setTicketABuscar(e.target.value)}
        />
        {isLoading && <CircularProgress size={30} />}
      </Box>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Fecha de registro</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Descripcion</TableCell>
              <TableCell>Canal</TableCell>
              <TableCell>Contacto</TableCell>
              <TableCell>Responsable</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TicketsTableBody
            tickets={ticketsFiltrados ?? tickets.rows}
            validateTicket={validateTicket}
            chipColors={chipColors}
            loading={loading}
          />
          {/* <TableBody>
            {tickets.rows.map((row) => (
              <TableRow key={row.id_crm_main}>
                <TableCell>
                  <DateInTable dateString={row.date_register} />
                </TableCell>
                <TableCell>
                  <Chip
                    label={row.type}
                    size="small"
                    color={row.type === "Online" ? "success" : "error"}
                    variant="outlined"
                  />
                </TableCell>
                <TableCell>{row.title}</TableCell>
                <TableCell>{row.channel}</TableCell>
                <TableCell>{row.contact}</TableCell>
                <TableCell>{row.responsable}</TableCell>
                <TableCell>
                  <Chip
                    label={row.status}
                    size="small"
                    sx={{
                      backgroundColor: chipColors[row.status],
                      color: "#fff",
                    }}
                  />
                </TableCell>
                <TableCell>
                  <LoadingButton
                    variant="contained"
                    size="small"
                    disableElevation
                    startIcon={<VisibilityIcon />}
                    onClick={() => validateTicket(row.id_crm_main)}
                    loading={loading[row.id_crm_main]}
                  >
                    Detalle
                  </LoadingButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody> */}
          <TableFooter>
            {ticketABuscar.length < 5 && (
              <TableRow>
                <TablePagination
                  rowsPerPage={tickets.rowsPerPage}
                  rowsPerPageOptions={[tickets.rowsPerPage]}
                  count={tickets.total}
                  page={tickets.page}
                  onPageChange={handleChangePage}
                />
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TicketsTable;
