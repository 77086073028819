import { Box, LinearProgress, Grid, List, ListItem, ListItemText,Button, Typography, Table, TableCell, TableContainer, TableRow, Stack,Dialog, DialogActions, DialogContent, DialogTitle, } from "@mui/material";
import { useEffect, useState } from "react";
import { useAuth } from "../../../../context/AuthContext";
import local from "dayjs/plugin/localizedFormat";
import relative from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import TableHead from '@mui/material/TableHead';
import { deleteChildren, getChildrenBeneficiaries, getStatus, saveChildren, updateChildren } from "../../../../utils/vales/temporadas/temporadas";
import { LoadingButton } from "@mui/lab";
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGridPro } from '@mui/x-data-grid-pro';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

dayjs.locale("es-mx");
dayjs.extend(local);
dayjs.extend(relative);
dayjs.extend(utc);

const DTab = ({ id_beneficiary, id_season, id_season_children }) => {
    const { user } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdateLoading, setIsUpdateLoading] = useState(false);
    const [alumnos, setAlumnos] = useState(null);
    const [estatus, setEstatus] = useState(null);
    const [detalle, setDetalle] = useState(null);
    const [auxNombre, setAuxNombre] = useState("");
    const [auxIdSeasonChildren, setAuxIdSeasonChildren] = useState("");

    const [openModalDelete, setOpenModalDelete] = useState(null);

    const handleOpenModalDelete = (name, last_name, mothes_name, id_season_children) => {
        clearAuxEstatus();
        setAuxNombre(nombreCompleto(name, last_name, mothes_name))
        console.log(id_season_children);
        setAuxIdSeasonChildren(id_season_children);
        setOpenModalDelete(true);
    };

    const handleCloseModalDelete = () => {
        setAuxNombre("");
        setAuxIdSeasonChildren("");
        setOpenModalDelete(false);
    };

    const [openModalAdd, setOpenModalAdd] = useState(null);

    const handleOpenModalAdd = (name, last_name, mothes_name, id_children) => {
        clearAuxEstatus();
        setAuxNombre(nombreCompleto(name, last_name, mothes_name))
        console.log(id_children);
        setAuxIdSeasonChildren(id_children);
        setOpenModalAdd(true);
    };

    const handleCloseModalAdd = () => {
        setAuxNombre("");
        setAuxIdSeasonChildren("");
        setOpenModalAdd(false);
    };

    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false); //Loading Index

    const [selectedOption, setSelectedOption] = useState("");

    const [auxEstatus, setAuxEstatus] = useState([]);

    const handleOptionChange = (idStatus, idSeasonChildren) => {
        clearAuxEstatus();
        setSelectedOption(idStatus);
        updateStatusStudent(idSeasonChildren, idStatus);
    };

    const clearAuxEstatus = () => {
        setAuxEstatus([]);
    };

    const getAlumnos = async () => {
        clearAuxEstatus();
        try {
            setIsLoading(true);
            const res = await getChildrenBeneficiaries(id_season, id_beneficiary);
            setAlumnos(res);
            res.data.map(({ id_season_children }) => (
                validStatus(id_season_children)
            ));
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const validStatus = (idSeasonChildren) => {
        if (idSeasonChildren) {
            getStatusAlumno(idSeasonChildren);
        }
    };

    const getStatusAlumno = async (idSeasonChildren) => {
        try {
            setIsLoading(true);
            const res = await getStatus(user.role.idProfile, idSeasonChildren);
            setAuxEstatus([...auxEstatus, res]);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const updateStatusStudent = async (idSeasonChildren, idStatus) => {
        try {
            setIsUpdateLoading(true);
            await updateChildren(user.data.awsid, idSeasonChildren, idStatus, user.role.idProfile);
        } catch (error) {
            console.error(error);
        } finally {
            setIsUpdateLoading(false);
        }
    };

    const deleteStudent = async (idSeasonChildren) => {
        clearAuxEstatus();
        try {
            setIsLoading(true);
            await deleteChildren(user.role.idProfile, idSeasonChildren);
        } catch (error) {
            console.error(error);
        } finally {
            handleCloseModalDelete();
            clearAuxEstatus();
            setIsLoading(false);
        }
    };

    const addStudent = async (idChildren) => {
        try {
            setIsLoading(true);
            await saveChildren(
                user.data.awsid,
                idChildren,
                id_season,
            );
        } catch (error) {
            console.error(error);
        } finally {
            handleCloseModalAdd();
            clearAuxEstatus();
            setIsLoading(false);
        }
    };

    const nombreCompleto = (nombres, apellidoP, apellidoM, idSeasonChildren) => {
        const usuario = [];
        if (nombres) {
            usuario.push(nombres + " ");
        }
        if (apellidoP) {
            usuario.push(apellidoP + " ");
        }
        if (apellidoM) {
            usuario.push(apellidoM + " ");
        }
        return (
            usuario
        );
    };

    useEffect(() => {
        getAlumnos();
    }, [id_beneficiary, id_season, id_season_children, isUpdateLoading, openModalDelete,openModalAdd]);

    return (
        <>
            <Box sx={{ mb: 2, ml: 2, mr: 2, display: 'flex', justifyContent: 'space-between' }}>
                {isLoading && <LinearProgress />}
                <Stack>
                    <Typography variant="h5"> Asignados</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between',width: 'auto',
                        height: 'auto', mb: 2, ml: 2, mr: 2, }}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography> <strong>Alumno</strong></Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography> <strong>Estatus</strong> </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography> <strong>Acción</strong> </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {alumnos && <>
                                    {alumnos.data.map(({ name, last_name, mothes_name, status, on_season, id_season_children }) => (<>
                                        {on_season === 1 && <>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography> {nombreCompleto(name, last_name, mothes_name)} </Typography>
                                                </TableCell>
                                                {auxEstatus && <>
                                                    {auxEstatus.map(({ data }) => (<>
                                                        <TableCell>
                                                            <select value={status} onChange={(e) => handleOptionChange(e.target.value, id_season_children)}>
                                                                {data.map(({ id_status, name, active, is_season_status }) => (<>
                                                                    <option value={id_status}>{name}</option>
                                                                </>))}
                                                            </select>
                                                        </TableCell>
                                                        <TableCell>
                                                            <LoadingButton variant="contained" color="error" startIcon={<DeleteIcon />} onClick={() => handleOpenModalDelete(name, last_name, mothes_name,id_season_children)}></LoadingButton>
                                                        </TableCell>
                                                    </>
                                                    ))}
                                                </>}
                                            </TableRow>
                                        </>}
                                    </>))}
                                </>}
                            </Table>
                        </TableContainer>
                    </Box>
                </Stack>
                <Stack>
                    <Typography variant="h5"> Disponibles</Typography>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: 'auto',
                        height: 'auto',
                        mb: 2, ml: 2, mr: 2,
                    }}>
                        <TableContainer>
                            <Table>
                                {alumnos && <>
                                    {alumnos.data.map(({ name, last_name, mothes_name, status, on_season, id_children }) => (<>
                                        {on_season === 0 && <>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography> {nombreCompleto(name, last_name, mothes_name)} </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <LoadingButton variant="contained" color="success" startIcon={<PersonAddIcon />} onClick={() => handleOpenModalAdd(name, last_name, mothes_name,id_children)}></LoadingButton>
                                                </TableCell>
                                            </TableRow>
                                        </>}
                                    </>))}
                                </>}
                            </Table>
                        </TableContainer>
                    </Box>
                </Stack>
            </Box>

            <Dialog open={openModalDelete} onClose={() => handleCloseModalDelete()}>
                <DialogTitle></DialogTitle>
                <DialogContent>
                    <Typography>¿Está seguro que desea quitar a {auxNombre} de esta lista?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="success" onClick={() => deleteStudent(auxIdSeasonChildren)}>Aceptar</Button>
                    <Button variant="contained" color="error" onClick={() => handleCloseModalDelete()}>Cerrar</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openModalAdd} onClose={() => handleCloseModalAdd()}>
                <DialogTitle></DialogTitle>
                <DialogContent>
                    <Typography>¿Está seguro que desea asignar a {auxNombre}?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="success" onClick={() => addStudent(auxIdSeasonChildren)}>Aceptar</Button>
                    <Button variant="contained" color="error" onClick={() => handleCloseModalAdd()}>Cerrar</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DTab;