import { Box, Grid, TextField, Button, IconButton, Divider, Chip, Input, InputLabel, Select, FormControl, FormHelperText, MenuItem, CircularProgress, Typography, Alert, Card, CardContent, Stack, ListSubheader, List, ListItem, ListItemText, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DescriptionIcon from "@mui/icons-material/Description";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import UploadIcon from "@mui/icons-material/Upload";
import SaveIcon from '@mui/icons-material/Save';
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../context/AuthContext";
import { useEffect, useState } from "react";
import Toast from "../../../general/Toast";
import { profilesAclaraciones } from "../../../../utils/trazabilidad/aclaraciones/profilesAclaraciones";
import { saveClarificationRefactura, getClarificationFiles, uploadClarificationFile } from "../../../../utils/trazabilidad/aclaraciones/aclaraciones";

const EvidenciasTab = ({ aId, detalle }) => {
  const { user } = useAuth();
  const [refresh, setRefresh] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [archivos, setArchivos] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [opena, setOpenA] = useState(null);
  const [confalert, setConfAlert] = useState({
    severidad: "success",
    mensaje: "",
  });

  const handleOpenA = (severidad, mensaje) => {
    setConfAlert({ severidad: severidad, mensaje: mensaje });
    setOpenA(true);
  };

  const handleCloseA = () => {
    setOpenA(false);
  };

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  function isDriveLink(link){
    return link.includes('drive.google.com');
  }

  const handleSaveFile = async () => {
    try {
      setIsLoading(true);
      let resp = await uploadClarificationFile(aId, selectedFile);
      if(resp){
        handleOpenA("success", "Archivo cargado con éxito");
        setSelectedFile([]);
        setIsFilePicked(false);
        setTimeout(() => {
          let au = refresh;
          setRefresh(au+1);
        }, 3000);
      }
      else handleOpenA("error", "El archivo no pudo ser cargado, intente de nuevo más tarde");
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const getFiles = async () => {
      try {
        if(detalle.id_tipificacion_ac === 6 || detalle.id_tipificacion_ac === 8 || detalle.id_tipificacion_ac === 9 || detalle.id_tipificacion_ac === 12 || detalle.id_tipificacion_ac === 13)
        {
          setIsLoading(true);
          const resp = await getClarificationFiles(aId);
          setArchivos(resp);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    getFiles();
  }, [aId, refresh]);

  return (
  <>
    {(detalle.id_tipificacion_ac === 6 || detalle.id_tipificacion_ac === 8 || detalle.id_tipificacion_ac === 9 || detalle.id_tipificacion_ac === 12 || detalle.id_tipificacion_ac === 13) && (
      <Grid container sx={{ my: 2 }}>
        {(detalle.status_acl !== 0) && (
          <>
            <Grid item md></Grid>            
            <Grid item md={5}>
              <Grid container sx={{ my : 2}}>
                <Grid item md={12}>
                  <Card variant="outlined">
                    <CardContent>
                      <Typography variant="h6" component="div"><DescriptionIcon /> Archivos</Typography>
                      <Grid container sx={{ alignItems: "center" }}>
                        <Grid item md={12}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell sx={{ width: '80%'}}>Nombre</TableCell>
                                <TableCell sx={{ width: '20%'}} align="right">Descargable</TableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {archivos.length > 0 && archivos.map( (row) => (
                                <TableRow key={row.id_ac_expedientes}>
                                  <TableCell component="th" scope="row">{row.nombre}</TableCell>
                                  <TableCell align="right">
                                    <IconButton loading={isLoading} color="primary" size="small">
                                      <a download href={isDriveLink(row.pdf) ? row.pdf : `data:application/${row.extension};base64,${row.pdf}` } style={{ textDecoration: "none", color: "#041C32" }} >
                                        <FileDownloadIcon />
                                      </a>
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md></Grid>
          </>
        )}

        {(detalle.status_acl === 1 || detalle.status_acl === 2) && (
          <>
            <Grid item md></Grid>
            <Grid item md={5}>
              <Alert variant="filled" severity="info">
                <Typography variant="p"><strong>Subir archivos</strong></Typography>
              </Alert>
              <Card>
                <CardContent>
                  <Typography align="center" sx={{ mb: 2 }}>
                    {isFilePicked ? selectedFile?.name : "Ningún archivo seleccionado"}
                  </Typography>
                  <Button align="center" variant="contained" component="label" sx={{ mb: 4 }} endIcon={<UploadIcon />} >
                    {isFilePicked ? "Elegir otro archivo" : "Elegir archivo"}
                    <input hidden accept="image/*,.pdf,.xls,.xlsx" type="file" onChange={changeHandler} />
                  </Button>

                  <LoadingButton sx={{ mt: 2 }} loading={isLoading} fullWidth size="medium" variant="contained"
                    color="info" onClick={handleSaveFile} disabled={!isFilePicked} endIcon={<SaveIcon />}>
                    Guardar archivo
                  </LoadingButton>

                  <Divider sx={{ my: 4 }}>
                    <Chip label="o" />
                  </Divider>

                  <LoadingButton loading={isLoading} fullWidth size="medium" variant="contained" color="success" >
                    Finalizar Refacturación
                  </LoadingButton>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md></Grid>
          </>
        )}
      </Grid>
    )}
  </>
  );
};

export default EvidenciasTab;