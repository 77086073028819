import { Box, CircularProgress, Typography, Alert, Card, CardContent, Grid, TextField, Table, TableHead, TableCell, TableRow, TableBody } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAuth } from "../../../../context/AuthContext";
import { useEffect, useState } from "react";
import DateInTable from "../../../general/DateInTable";
import Toast from "../../../general/Toast";
import { getClarificationMessages, saveClarificationMessage } from "../../../../utils/trazabilidad/aclaraciones/aclaraciones";
import AllInboxIcon from '@mui/icons-material/AllInbox';

const MensajesTab = ({ aId, detalle }) => {
  const { user } = useAuth();
  const [refresh, setRefresh] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState(null);
  const [message, setMessage] = useState("");
  const [opena, setOpenA] = useState(null);
  const [confalert, setConfAlert] = useState({
    severidad: "success",
    mensaje: "Mensaje registrado con éxito",
  });

  const handleChangeM = (event) => {
    setMessage(event.target.value);
  };

  const handleOpenA = (severidad, mensaje) => {
    setConfAlert({ severidad: severidad, mensaje: mensaje });
    setOpenA(true);
  };

  const handleCloseA = () => {
    setOpenA(false);
  };

  const handleSaveMS = async () => {
    try {
      setIsLoading(true);
      if(message){
        let resp = await saveClarificationMessage(detalle.id_orden, aId, user.data.awsid, message);
        if(resp){
          handleOpenA("success", "Mensaje registrado con éxito");
          handleClose();
          setTimeout(() => {
            let au = refresh;
            setRefresh(au+1);
          }, 3000);
        }
        else handleOpenA("error", "El mensaje no pudo ser registrado, intente de nuevo más tarde");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setMessage("");
  };

  useEffect(() => {
    const getMessages = async () => {
      try {
        setIsLoading(true);
        const resp = await getClarificationMessages(aId);
        setMessages(resp);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    
    getMessages();
  }, [aId, refresh]);

  return (
    <>
      <Box sx={{mb: 2, ml: 2, mr: 2}}>
        {isLoading && <CircularProgress />}
        
        {messages && (<Grid container sx={{mt:2, mb:2}}>
            <Grid item md={3}></Grid>
            <Grid item md={6}>
                <Card>
                    <CardContent>
                        <Grid container>
                            <Grid item md={2}></Grid>
                            <Grid item md={8} sx={{mt:2, mb:2}}>
                                <TextField type="text" multiline rows={4} fullWidth sx={{mb:2}} id="message" label="Escribe tu mensaje" value={message} onChange={handleChangeM} />
                                <LoadingButton loading={isLoading} fullWidth size="medium" variant="contained" color="success" onClick={handleSaveMS} disabled={(!message || message.length < 4)}>Enviar</LoadingButton>
                            </Grid>
                            <Grid item md={2}></Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item md={3}></Grid>
        </Grid>
        )}

        {(messages && messages.length > 0) && (<>
          <Alert variant="filled" severity="info" icon={<AllInboxIcon />}><Typography variant="p"><strong>MENSAJES ANTERIORES</strong></Typography></Alert>
          <div style={{ display: "flex", height: "100%" }}>                
              <div style={{ flexGrow: 1 }}>
                  <Table size="small">
                      <TableHead>
                          <TableRow>
                              <TableCell>Fecha</TableCell>
                              <TableCell sx={{ textAlign: 'center' }}>Usuario</TableCell>
                              <TableCell sx={{ textAlign: 'center' }}>Mensaje</TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                      {messages.map(( row ) => (
                          <TableRow sx={{ border: 0 }} >
                              <TableCell sx={{ width: '15%' }}><DateInTable dateString={row.fecha_reg} /></TableCell>
                              <TableCell sx={{ width: '25%', textAlign: 'center' }}>{row.nombreRecup}</TableCell>
                              <TableCell sx={{ width: '60%', textAlign: 'center' }}>{row.comentario}</TableCell>
                          </TableRow>
                      ))}
                      </TableBody>
                  </Table>
              </div>
          </div></>
        )}

        {(messages && messages.length === 0) && (
          <Grid container sx={{mb:2}}>
              <Grid item md={3}></Grid>
              <Grid item md={6}>
                  <Alert variant="filled" severity="info"><Typography variant="p">No se encontraron mensajes</Typography></Alert>
              </Grid>
              <Grid item md={3}></Grid>
          </Grid>                
        )}
      </Box>
      <Toast open={opena} message={confalert.mensaje} type={confalert.severidad} onClose={handleCloseA} />
    </>
  );
};

export default MensajesTab;