import { Box, Grid, Button, Stack, Typography, TextField, Chip, Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { useAuth } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import DateInTable from "../../general/DateInTable";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { getClarifications, searchClarifications } from "../../../utils/trazabilidad/aclaraciones/aclaraciones";

function DetailPanelContent({ row: rowProp }) {
  return (
    <Stack sx={{ py: 2, height: "100%", boxSizing: "border-box" }} direction="column">
      <Paper sx={{ flex: 1, mx: "auto", width: "90%", p: 1 }}>
        <Stack direction="column" spacing={1} sx={{ height: 1 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ textAlign: "center" }}>Última modificación por</TableCell>
                <TableCell sx={{ textAlign: "center" }}>Reembolso</TableCell>
                <TableCell sx={{ textAlign: "center" }}>Store Credit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ border: 0 }}>
                <TableCell sx={{ width: "40%", textAlign: "center" }}>
                  {rowProp.UltimaModif ?? '-'}
                </TableCell>
                <TableCell sx={{ width: "30%", textAlign: "center" }}>
                  <Chip size="small" label={rowProp.if_reembolso ? "Sí aplica" : "No aplica"} sx={{ backgroundColor: rowProp.if_reembolso ? "#c8e6c9" : "#f5f5f5", }} />
                </TableCell>
                <TableCell sx={{ width: "30%", textAlign: "center" }}>
                  <Chip size="small" label={rowProp.if_storecredit ? "Sí aplica" : "No aplica"} sx={{ backgroundColor: rowProp.if_storecredit ? "#c8e6c9" : "#f5f5f5", }} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Stack>
      </Paper>
    </Stack>
  );
}

const AclaracionesDataTable = ({ typeP, chipColors, chipLabels }) => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [keyWord, setKeytoSearch] = useState("");
    const [loading, setLoading] = useState(false);

    const columns = [
        { field: "creation_date", headerName: "Fecha", flex: 1, renderCell: (params) => (
            <Typography><DateInTable dateString={params.row.creation_date} /></Typography>
        )},
        { field: "is_crm", headerName: "CRM", flex: 0.5, align: "center", headerAlign: "center", valueGetter: (params) => `${params.row.is_crm ?? "-"}` },
        { field: "diasTranscurridos", headerName: "Días transcurridos", flex: 0.5, align: "center", headerAlign: "center", renderCell: (params) =>
            parseInt( (new Date() - new Date(params.row.creation_date)) / (1000 * 60 * 60 * 24), 10)
        },
        { field: "id_orden", headerName: "Orden", flex: 0.6, align: "center", headerAlign: "center" },
        { field: "createdBy", headerName: "Creado por", flex: 1, align: "center", headerAlign: "center", valueGetter: (params) => `${params.row.user_nombre ?? '-'} ${params.row.user_apellido ?? ''}` },
        { field: "tipificacion", headerName: "Tipificación", flex: 1, align: "center", headerAlign: "center" },
        { field: "status_acl_id", headerName: "Estatus", flex: 1, align: "center", headerAlign: "center", renderCell: (params) => (
            <Chip size="small" label={chipLabels[params.row.status_acl_id]} sx={{ backgroundColor: chipColors[params.row.status_acl_id], color: "#fff" }} />
        )},
        { field: "fechaModif", headerName: "Última modificación", flex: 1, renderCell: (params) => (
            <Typography>{params.row.fechaModif && <DateInTable dateString={params.row.fechaModif} />}</Typography>
        )},
        { field: "accion", headerName: "Acción", flex: 1, align: "center", headerAlign: "center", sortable: false, renderCell: (params) => (
            <Button variant="contained" size="small" disableElevation startIcon={<VisibilityIcon />} onClick={() => navigate(`detail/${params.row.id_aclaracion}`)}>Detalle</Button>
        )},
    ];

    if(document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"))
        document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']").remove();

    useEffect(() => {

        let active = true;

        (async () => {
          let tb = typeP === 1 ? "new" : "closed";
          let newRows = null;

          if(typeof keyWord === 'undefined' || keyWord === undefined || keyWord.length === 0){
              setLoading(true);
              newRows = await getClarifications(tb, page, user.role.idProfile);
          }   
          else if(keyWord.length >= 5)
          {
              setLoading(true);
              newRows = await searchClarifications(page, user.role.idProfile, keyWord);
          }
          else active = false;

          if (!active) {
              return;
          }
          setTotal(newRows.total);
          setRows(newRows.data);
          setLoading(false);
        })();

        return () => {
            active = false;
        };

    }, [page, keyWord]);

    return (
        <>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Grid container sx={{ mb: 2 }}>
              <Grid item md={4} sx={{ px: 2 }}>
                <Stack direction="row" spacing={1}>
                  <TextField size="small" variant="outlined" fullWidth
                    label="Buscar Aclaración..." value={keyWord}
                    onChange={(e) => setKeytoSearch(e.target.value)}
                  />
                </Stack>
              </Grid>
              <Grid item md sx={{ px: 2, textAlign: "right" }}></Grid>
            </Grid>
          </Box>
          <Box style={{ height: 635, width: "100%" }}>
            <DataGridPro
              rows={rows}
              columns={columns}
              pagination
              pageSize={10}
              rowsPerPageOptions={[10]}
              getRowId={(row) => row.id_aclaracion}
              rowCount={total}
              paginationMode="server"
              onPageChange={(newPage) => {
                setPage(newPage);
              }}
              loading={loading}
              disableColumnMenu
              disableSelectionOnClick
              getDetailPanelHeight={() => "auto"}
              getDetailPanelContent={({ row }) => <DetailPanelContent row={row} />}
              style={{
                border: "none",
              }}
            />
          </Box>
        </>
    );
};

export default AclaracionesDataTable;