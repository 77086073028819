const profiles = {
    cancelaciones: [1,7,9,10,30,45],
};

const belongsCancelaciones = ( profile ) => {
    let aux = profiles;
    return  aux.cancelaciones.includes( profile );
}

export{
    profiles, belongsCancelaciones
};