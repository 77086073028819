import axios from "axios";
import { getDocs, query, where } from "firebase/firestore";
import { filesCollection } from "../../services/firebase/collections";
// import {
//   uploadFileAndGetUrl,
//   uploadMultipleFilesAndGetUrls,
// } from "../../services/firebase/storage";

// const getBase64FromFile = (file) =>
//   new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = (error) => reject(error);
//   });

const getDataDownloadFromBase64 = (base64, att_name, att_type) => {
  let href = `data:${att_type};base64,${base64}`;
  let download = att_name;

  return { href, download };
};

// const uploadFilesToFirestore = async (files, ticket, path) => {
//   try {
//     for (let file of files) {
//       const url = await uploadFileAndGetUrl(file, "files");
//       const fileInstance = {
//         url,
//         name: file.name,
//         ticket,
//         date: Timestamp.fromDate(new Date()),
//       };

//       await addDoc(filesCollection, fileInstance);
//     }
//   } catch (error) {
//     console.error(error);
//   }
// };

const uploadFiles = async (files, ticket, userId) => {
  try {
    // await uploadFilesToFirestore(files, ticket, "files");
    const formData = new FormData();
    formData.append("idMain", ticket);
    formData.append("idUser", userId);

    for (let file of files) {
      formData.append("files", file);

      const res = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASEURL}/crm/files/upload`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data;",
        },
      });

      if (!res?.data?.success) {
        throw new Error("uploadFiles:", "Error al enviar los archivos");
      }
    }
  } catch (error) {
    console.log(error);
  }
};

const getFilesByTicketId = async (ticketid) => {
  try {
    const q = query(filesCollection, where("ticket", "==", ticketid));
    const docs = [];

    const snap = await getDocs(q);
    snap.forEach((d) => {
      docs.push(d.data());
    });

    return docs;
  } catch (error) {
    console.error(error);
  }

  return [];
};

function base64ToFile(url, filename, mimeType) {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
}

export {
  uploadFiles,
  getFilesByTicketId,
  base64ToFile,
  getDataDownloadFromBase64,
};
