import { Box, Button, Chip, Typography} from "@mui/material";
import { useEffect, useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { DataGridPro } from '@mui/x-data-grid-pro';
import Paper from '@mui/material/Paper';
import {  getBackOrders, } from "../../../utils/trazabilidad/pedidos/pedidos";
import VisibilityIcon from '@mui/icons-material/Visibility';
import local from "dayjs/plugin/localizedFormat";
import relative from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";

dayjs.locale("es-mx");
dayjs.extend(local);
dayjs.extend(relative);
dayjs.extend(utc);

const chipColors = {
    "Nuevo": "#e53935",
    "En Espera de Reembolso": "#ffa000",
    "En Proceso": "#ffa000",
    "Sin Stock": "#ffa000",
    "Liberado": "#43a047",
};

const BackOrdersTab = ({ id }) => {
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [keyWord, setKeytoSearch] = useState("");
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const columns = [

        {
            field: "wms_id", headerName: "BackOrder", flex: 1, align: "center", headerAlign: "center",
        },
        {
            field: "status_acl", headerName: "Estatus", flex: 1, align: "center", headerAlign: "center",
            renderCell: (params) => (<>
                {(params.row.status_acl === "Nuevo" || "En Espera de Reembolso" || "En Proceso" || "Sin Stock" || "Liberado"  ) ? <Chip label={params.row.status_acl} size="small" /> : params.row.status_acl }
            </>)
        },
        {
            field: "Cdoc", headerName: "Crossdoc", flex: 1, align: "center", headerAlign: "center",
            renderCell: (params) => (<>
                {params.row.is_crossdoc === 1 ? "SI" : "NO"}
            </>)
        },
        {
            field: "fecha", headerName: "Fecha", flex: 1, align: "center", headerAlign: "center",
            renderCell: (params) => (<>
                {dayjs.utc(params.row.created_at).format("DD MMM YYYY, hh:mm:ss A")}
            </>)
        },
        {
            field: "accion", headerName: "Accion", flex: 1, align: "center", headerAlign: "center",
            renderCell: (params) => (<>
                <Button variant="contained" size="small" disableElevation onClick={() => navigate(`/trazabilidad/backorders/detail/${params.row.id_backorder}`)}><VisibilityIcon /></Button>
            </>)
        },
    ];

    if (document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"))
        document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']").remove();

    useEffect(() => {
        let active = true;
        (async () => {
            let newRows = null;
            if (typeof keyWord === 'undefined' || keyWord === undefined || keyWord.length === 0) {
                setLoading(true);
                newRows = await getBackOrders(id, page);
                console.log(newRows);
            }
            else if (keyWord.length >= 1) {
                setLoading(true);
                //newRows = await searchOrders (page, keyWord);
            }
            else active = false;
            if (!active) {
                return;
            }
            setTotal(newRows.total);
            setRows(newRows.data);
            setLoading(false);
        })();
        return () => {
            active = false;
        };
    }, [id]);

    return (<>
        <Box sx={{ p:2 }} style={{ height: 300 }} component={Paper}>
            <DataGridPro
                rows={rows}
                columns={columns}
                pagination
                pageSize={5}
                rowsPerPageOptions={[5]}
                getRowId={((row) => (row.id_backorder))}
                rowCount={total}
                paginationMode="server"
                onPageChange={(newPage) => {
                    setPage(newPage);
                }}
                loading={loading}
                disableColumnMenu
                disableSelectionOnClick
            />
        </Box>
    </>);
};

export default BackOrdersTab;