import { Box, CircularProgress, Typography, Tabs, Tab } from "@mui/material";
import { useState } from "react";
import { useTabs } from "../../../../context/TabsContext";
import DOTab from "./DOTab";
import HistoryTab from "./HistoryTab";
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import HistoryIcon from '@mui/icons-material/History';
import ListIcon from '@mui/icons-material/List';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DescriptionIcon from '@mui/icons-material/Description';
import ChatIcon from '@mui/icons-material/Chat';
import ObservationsTab from "./ObservationsTab";
import InternalLogsTab from "./InternalLogsTab";
import EvidencesTab from "./EvidencesTab";
import MessagesTab from "./MessagesTab";

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
        </div>
    );
};

const IndexTabs = ({ idOrder, id }) => {
    const { crmTab, setCrmTab } = useTabs();
    const [isLoading, setIsLoading] = useState(false);

    const handleTabChange = (event, value) => {
        setCrmTab(value);
    };

    return (
        <>            
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={crmTab} onChange={handleTabChange} >
                    <Tab icon={<TrackChangesIcon />} iconPosition="start" label="Observaciones" />
                    <Tab icon={<HistoryIcon />} iconPosition="start" label="Historial" />
                    <Tab icon={<ListIcon />} iconPosition="start" label="Log Internos" />
                    <Tab icon={<LocalShippingIcon />} iconPosition="start" label="Detalle de Orden" />
                    <Tab icon={<DescriptionIcon />} iconPosition="start" label="Evidencias" />
                    <Tab icon={<ChatIcon />} iconPosition="start" label="Mensajes" />
                </Tabs>
            </Box>
            {isLoading && (<Box sx={{ml:2, mt:2, mr:2, mb:2}}> <Typography variant="p"> Cargando ...</Typography> <CircularProgress /> </Box>)}
            <TabPanel value={crmTab} index={0}><ObservationsTab id={id}/></TabPanel>
            <TabPanel value={crmTab} index={1}><HistoryTab id={id}/></TabPanel>
            <TabPanel value={crmTab} index={2}><InternalLogsTab id={id}/></TabPanel>
            <TabPanel value={crmTab} index={3}><DOTab idOrder={idOrder}/></TabPanel>
            <TabPanel value={crmTab} index={4}><EvidencesTab id={id}/></TabPanel>
            <TabPanel value={crmTab} index={5}><MessagesTab id={id} idOrder={idOrder}/></TabPanel>
        </>
    );
};

export default IndexTabs;