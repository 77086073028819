import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import React, { useEffect } from "react";
import { useState } from "react";
import { getWarehouses } from "../../../../../utils/ventaMayoreo/ordenesVenta/ordenesVenta";
import { Check, CheckCircle, CheckCircleOutline } from "@mui/icons-material";

export const AlamacenTab = ({ ordenVenta }) => {
  const [datos, setDatos] = useState(null);
  const [productosAlmacenes, setProductosAlmacenes] = useState([]);
  const [seguimientoSurtido, setSeguimientoSurtido] = useState(null);
  const [almacenes, setAlmacenes] = useState([]);
  const [almacenesSurtido, setAlmacenesSurtido] = useState([]);

  const renderAlmacenHeaders = () => {
    return almacenes
      .filter((alm) => ![4, 5, 2].includes(alm.id_almacen))
      .map((alm) => (
        <React.Fragment key={alm.id_almacen}>
          <TableCell>{alm.nombre_almacen.toUpperCase()}</TableCell>
          <TableCell>QTY</TableCell>
        </React.Fragment>
      ));
  };

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        const response = await getWarehouses(ordenVenta);
        const { data } = response;
        setProductosAlmacenes(data.productosAlmacenesD);
        setSeguimientoSurtido(data.seguimientoSurtidoD);
        setAlmacenes(data.almacenesD);
        setAlmacenesSurtido(data.almacenesSurtidoD);
      } catch (error) {
        console.error("Error al realizar la petición:", error);
      }
    };
    cargarDatos();
  }, []);

  const validarCantidadAlmacenes = (cantidad, idProveedorProducto) => {
  };

  const calcularCantidadSolicitada = (almacen, cantidadProducto) => {
    return almacen.cantidad_disponible >= cantidadProducto ? cantidadProducto : almacen.cantidad_disponible;
  };


  return (
    <TableContainer component={Paper}>
      <Table aria-label="almacenes table">
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>Producto</strong>
            </TableCell>
            <TableCell style={{ width: 112 }}>
              <strong>SKU</strong>
            </TableCell>
            <TableCell>
              <strong>Cantidad</strong>
            </TableCell>
            {!seguimientoSurtido && renderAlmacenHeaders()}
          </TableRow>
        </TableHead>
        <TableBody>
          {productosAlmacenes.map((p, index) => {
            const almacenesProducto = almacenesSurtido[index];
            let cantidadAlm = p.cantidad;
            let cantidadesListas = false;

            return (
              <TableRow key={p.sku}>
                <TableCell>{p.nombre}</TableCell>
                <TableCell style={{ width: "112px" }}>{p.sku}</TableCell>
                <TableCell>{p.cantidad}</TableCell>
                {almacenesProducto.map((ap, idx) => {
                  if (![2, 4, 5].includes(ap.id_almacen)) {
                    let cantidadSolicitada = 0;
                    if (ap.cantidad_disponible === 0) {
                      return (
                        <React.Fragment key={`${ap.id_almacen}-${p.sku}`}>
                          <TableCell align="right">
                            <strong>0</strong>
                          </TableCell>
                          <TableCell>
                            <TextField
                              size="small"
                              variant="outlined"
                              value="0"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <Check />
                                  </InputAdornment>
                                ),
                              }}
                              onChange={(e) =>
                                validarCantidadAlmacenes(
                                  p.cantidad,
                                  p.idProveedorProducto
                                )
                              }
                            />
                          </TableCell>
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <React.Fragment key={`${ap.id_almacen}-${p.sku}`}>
                          <TableCell align="right">
                            <strong>{ap.cantidad_disponible}</strong>
                          </TableCell>
                          <TableCell>
                            <TextField
                              size="small"
                              variant="outlined"
                              value={cantidadSolicitada}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <Check />
                                  </InputAdornment>
                                ),
                              }}
                              onChange={(e) =>
                                validarCantidadAlmacenes(
                                  p.cantidad,
                                  p.idProveedorProducto
                                )
                              }
                            />
                          </TableCell>
                        </React.Fragment>
                      );
                    }
                  }
                  return null;
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
