import { useState, useEffect } from "react";
import {
  Button,
  DialogActions,
  FormControl,
  FormHelperText,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  getChildren,
  getGradesSchool,
  updateChildren,
} from "../../../utils/vales/beneficiarios/beneficiarios";

const TapUpdateChildren = ({
  open,
  onClose,
  schools,
  child,
  updateUsr,
  setLoadingChildrens,
  setChildrens,
  setReloadTable,
}) => {
  const [nameC, setNameC] = useState("");
  const [apellidoPC, setApellidoPC] = useState("");
  const [apellidoMC, setApellidoMC] = useState("");
  const [selectedSchoolIdC, setSelectedSchoolIdC] = useState("");
  const [gradeExternalC, setGradeExternalC] = useState([]);
  const [gradeExternalIdC, setGradeExternalIdC] = useState("");
  const [errorsC, setErrorsC] = useState({});

  useEffect(() => {
    setGradeExternalC([]);
    const fetchGradesSchoolData = async () => {
      if (selectedSchoolIdC) {
        try {
          const response = await getGradesSchool(selectedSchoolIdC);
          const userData = response.data;
          setGradeExternalC(userData);
        } catch (error) {
          console.error("Error al obtener los datos:", error);
        }
      }
    };

    fetchGradesSchoolData();
  }, [selectedSchoolIdC]);

  useEffect(() => {
    const fetchChildData = async () => {
      if (open && child) {
        try {
          const response = await getChildren(child);
          const userData = response.data[0];

          setNameC(userData.name || "");
          setApellidoPC(userData.last_name || "");
          setApellidoMC(userData.mothes_name || "");
          setSelectedSchoolIdC(userData.id_school || 0);
          setGradeExternalIdC(userData.id_grade || 0);
        } catch (error) {
          console.error("Error al obtener los datos:", error);
        }
      }
    };

    fetchChildData();
  }, [open, child]);

  const handleAdd = async () => {
    let tempErrors = {};

    if (nameC.length <= 3)
      tempErrors.nameC = "El nombre debe tener más de 3 caracteres";
    if (apellidoPC.length <= 3)
      tempErrors.apellidoPC =
        "El apellido paterno debe tener más de 3 caracteres";
    if (apellidoMC.length <= 3)
      tempErrors.apellidoMC =
        "El apellido materno debe tener más de 3 caracteres";
    if (selectedSchoolIdC === "")
      tempErrors.selectedSchoolIdC = "Selecciona el colegio";
    if (gradeExternalIdC === "")
      tempErrors.gradeExternalIdC = "Selecciona el grado";

    setErrorsC(tempErrors);

    if (Object.keys(tempErrors).length === 0) {
      let data = {
        idChild: child,
        name: nameC,
        last_name: apellidoPC,
        mothers_name: apellidoMC,
        id_school: selectedSchoolIdC,
        id_grade: gradeExternalIdC,
        updateUsr,
      };
      onClose();
      setLoadingChildrens(true);
      setChildrens([]);
      await updateChildren(data);
      setReloadTable((prevValue) => (prevValue === 0 ? 1 : 0));
      setNameC("");
      setApellidoMC("");
      setApellidoPC("");
      setSelectedSchoolIdC("");
      setGradeExternalIdC("");
    }
  };

  const handleSchoolChange = (event) => {
    setSelectedSchoolIdC(event.target.value);
    setGradeExternalIdC("");
  };

  const handleGradeChange = (event) => {
    setGradeExternalIdC(event.target.value);
  };

  return (
    <>
      <List sx={{ padding: 0 }}>
        <ListItem>
          <TextField
            variant="outlined"
            required
            fullWidth
            label="Nombre"
            value={nameC}
            onChange={(e) => setNameC(e.target.value)}
            error={!!errorsC.nameC}
            helperText={errorsC.nameC}
            sx={{ width: "500px" }}
          />
        </ListItem>
        <ListItem>
          <TextField
            variant="outlined"
            required
            fullWidth
            label="Primer Apellido"
            value={apellidoPC}
            onChange={(e) => setApellidoPC(e.target.value)}
            error={!!errorsC.apellidoPC}
            helperText={errorsC.apellidoPC}
            sx={{ width: "500px" }}
          />
        </ListItem>
        <ListItem>
          <TextField
            variant="outlined"
            required
            fullWidth
            label="Segundo Apellido"
            value={apellidoMC}
            onChange={(e) => setApellidoMC(e.target.value)}
            error={!!errorsC.apellidoMC}
            helperText={errorsC.apellidoMC}
            sx={{ width: "500px" }}
          />
        </ListItem>
        <ListItem>
          <FormControl
            variant="outlined"
            sx={{ width: "500px" }}
            error={!!errorsC.selectedSchoolIdC}
          >
            <InputLabel id="school-label" required>
              Colegio
            </InputLabel>
            <Select
              labelId="school-label"
              label="Colegio"
              required
              value={selectedSchoolIdC}
              onChange={handleSchoolChange}
            >
              {schools.map((school) => (
                <MenuItem key={school.school_id} value={school.school_id}>
                  {school.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errorsC.selectedSchoolIdC}</FormHelperText>
          </FormControl>
        </ListItem>
        <ListItem>
          <FormControl
            variant="outlined"
            sx={{ width: "500px" }}
            error={!!errorsC.gradeExternalIdC}
          >
            <InputLabel id="school-label" required>
              Grado
            </InputLabel>
            <Select
              labelId="school-label"
              label="Grado"
              required
              value={gradeExternalIdC}
              onChange={handleGradeChange}
            >
              {gradeExternalC.map((grade) => (
                <MenuItem key={grade.sltg_id} value={grade.grade_external_id}>
                  {grade.grade_alias_name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errorsC.gradeExternalIdC}</FormHelperText>
          </FormControl>
        </ListItem>
      </List>

      <DialogActions>
        <Button onClick={onClose} color="error" variant="outlined">
          Cerrar
        </Button>
        <Button onClick={handleAdd} color="success" variant="outlined">
          Actualizar
        </Button>
      </DialogActions>
    </>
  );
};

export default TapUpdateChildren;
