import { Box, Grid, Stack, TextField } from "@mui/material";
import { DataGridPro, GridToolbarContainer } from "@mui/x-data-grid-pro";
import { DetailPanelContent } from "./DetailPanelContent";
import { DetailPanelContentNoDate } from "./DetailPanelContentNoDate";
import { DetailPanelContentId } from "./DetailPanelContentId";
import { useState, useEffect } from "react";
import {
  Description as DescriptionIcon,
  Web as WebIcon,
} from "@mui/icons-material";
import { useAuth } from "../../../context/AuthContext";

const columns = [
  {
    field: "report",
    headerName: "Reporte",
    flex: 1,
    align: "center",
    headerAlign: "center",
    renderHeader: (params) => (
      <GridToolbarContainer>
        <DescriptionIcon sx={{ paddingRight: "4px" }} />
        {params.colDef.headerName}
      </GridToolbarContainer>
    ),
  },
  {
    field: "platform",
    headerName: "Plataforma",
    flex: 1,
    align: "center",
    headerAlign: "center",
    renderHeader: (params) => (
      <GridToolbarContainer>
        <WebIcon sx={{ paddingRight: "4px" }} />
        {params.colDef.headerName}
      </GridToolbarContainer>
    ),
  },
];

const profiles = {
  permiso_m_team: [1, 10, 21, 23, 29, 32, 35, 37, 49, 52, 53, 54, 55, 57],
  permiso_montos_web: [1, 10, 21, 23, 29, 32, 35, 37, 49, 52, 53, 54, 55, 57],
  permiso_montos_web_s: [1, 10, 21, 23, 29, 32, 35, 37, 49, 52, 53, 54, 55, 57],
  permiso_nps: [1, 15, 21, 23, 29, 34, 49, 52, 53, 54, 55, 57, 63],
  permiso_store_credit: [1, 10, 21, 23, 29, 32, 35, 37, 49, 52, 53, 54, 55, 57],
  permiso_stock: [1, 15, 16, 21, 23, 29, 34, 49, 52, 53, 54, 55, 57, 63],
  permiso_repor_gast_envios: [
    1, 9, 15, 20, 21, 23, 29, 34, 45, 46, 48, 49, 52, 53, 54, 55, 57, 63,
  ],
  permiso_G_E: [1, 15, 21, 23, 29, 34, 49, 52, 53, 54, 55, 57, 63],
  permisoCupones: [1, 15, 21, 23, 29, 34, 49, 52, 53, 54, 55, 57, 63],
  permiso_pro_pub_img: [1, 15, 21, 23, 29, 34, 49, 52, 53, 54, 55, 57, 63],
  permis_rep_vent: [
    1, 10, 15, 16, 19, 21, 23, 29, 30, 32, 34, 35, 37, 49, 52, 53, 54, 55, 57,
    63,
  ],
  permis_rep_inv_venta: [1, 16, 19, 21, 23, 29, 30, 49, 52, 53, 54, 55, 57],
  permis_rep_total_inv_venta: [
    1, 10, 15, 16, 19, 21, 23, 29, 30, 34, 35, 37, 49, 52, 53, 54, 55, 57, 63,
  ],
  perm_list_publ_colegios: [1, 15, 21, 23, 29, 34, 49, 52, 53, 54, 55, 57, 63],
  perm_carritos_abandonados: [
    1, 15, 21, 23, 29, 34, 49, 52, 53, 54, 55, 57, 63,
  ],
  perm_lista_poblacion_colegios: [
    1, 15, 21, 23, 29, 34, 49, 52, 53, 54, 55, 57, 63,
  ],
  permiso_stock_accesos: [
    1, 10, 15, 20, 21, 23, 29, 32, 34, 35, 37, 49, 52, 53, 54, 55, 57, 63,
  ],
  permis_repor_mail_sent: [1, 15, 21, 23, 29, 34, 49, 52, 53, 54, 55, 57, 63],
  permitproductdetail: [1, 15, 21, 23, 29, 34, 49, 52, 53, 54, 55, 57, 63],
  permision_nps_tiendas: [
    1, 15, 21, 23, 29, 30, 34, 49, 52, 53, 54, 55, 57, 63,
  ],
  permit_mag_user: [1, 15, 21, 23, 29, 34, 49, 52, 53, 54, 55, 57, 63],
  permit_venta_neta: [
    1, 7, 10, 15, 16, 20, 21, 23, 24, 29, 30, 32, 34, 35, 37, 40, 49, 52, 53,
    54, 55, 57, 58, 61, 63,
  ],
  permit_transito_venta: [1, 16, 19, 21, 23, 29, 49, 52, 53, 54, 55, 57],
  permit_EntradasDevoluciones: [
    1, 7, 10, 21, 23, 29, 30, 32, 34, 35, 37, 49, 52, 53, 54, 55, 57, 58,
  ],
  permit_Mailing: [1, 15, 21, 23, 29, 34, 49, 52, 53, 54, 55, 57, 63],
  permit_rma_finanzas: [1, 10, 21, 23, 32, 35, 37, 49, 53, 55, 57],
  permit_aclar_finanzas: [1, 10, 21, 23, 32, 35, 37, 49, 53, 55, 57],
  permiso_Modelo_Resurtido_Tiendas: [
    1, 15, 16, 20, 21, 23, 29, 34, 49, 52, 53, 54, 55, 57, 63,
  ],
  reporteImportacionConfigurables: [1, 15, 21, 23, 32, 34, 49, 53, 55, 57, 63],
  calzadoActualizacionInformacion: [1, 15, 21, 23, 32, 34, 49, 53, 55, 57, 63],
  importaciondeListas: [1, 15, 21, 23, 32, 34, 49, 53, 55, 57, 63],
  permiso_Modelo_Utiles_Sap: [
    1, 4, 9, 15, 16, 20, 21, 23, 29, 34, 45, 48, 49, 52, 53, 54, 55, 57, 63,
  ],
  permisoPedidosLM: [
    1, 9, 10, 15, 20, 21, 23, 29, 34, 35, 37, 45, 48, 49, 52, 53, 54, 55, 57,
    63,
  ],
  permisoPedidosEscuelas: [1, 7, 21, 23, 30, 33, 34, 35, 49, 53, 55, 57, 58],
  permisoPreciosArticulosMag: [1, 10, 21, 23, 35, 37, 49, 53, 55, 57],
  permisoVentaMayoreo: [1, 9, 21, 23, 29, 30, 45, 48, 49, 52, 53, 54, 55, 57],
  permisoAclaracionesSku: [1, 7, 21, 23, 49, 53, 55, 57, 58],
  permisoReporteRmaSku: [
    1, 7, 9, 10, 15, 21, 23, 29, 30, 34, 45, 48, 49, 52, 53, 54, 55, 57, 58, 63,
  ],
  permisoReporteInventarios: [
    1, 9, 15, 19, 20, 21, 23, 29, 34, 44, 45, 48, 49, 52, 53, 54, 55, 57, 63,
  ],
  permisoReporteNpsKlipp: [1, 20, 21, 23, 32, 38, 49, 53, 55, 57],
  permisoReporteCasos: [1, 7, 21, 23, 30, 34, 49, 53, 55, 57, 58],
  permisoCRM: [1, 7, 21, 23, 30, 49, 53, 57, 58],
  permisoCRMNumeros: [1, 7, 15, 21, 30, 34, 53, 57, 58, 63],
  Outstocksubscribers: [1, 15, 16, 21, 34, 53, 57, 63],
  aclarComments: [1, 9, 15, 21, 34, 45, 53, 57, 63],
  permisoRTickets: [1, 15, 34, 63],
  permisoPedidosPorFecha: [1, 9, 15, 34, 45, 46, 63],
  permisoErrorTimbrado: [1, 10, 15, 63],
  permisoSkusConfigurables: [1, 15],
  permisoBundles: [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
    26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44,
    45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63,
    77, 79, 308,
  ],
};

export const ReporteDataTable = () => {
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [keyWord, setKeytoSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [sortModel, setSortModel] = useState([
    { field: "report", sort: "asc" },
  ]);

  const { user } = useAuth();
  const { idProfile } = user.role;

  const r01 = searchProfiles(profiles.permisoPedidosEscuelas, idProfile)
  const r02 = searchProfiles(profiles.permision_nps_tiendas, idProfile)
  const r03 = searchProfiles(profiles.permisoVentaMayoreo, idProfile)
  const r04 = searchProfiles(profiles.permisoReporteRmaSku, idProfile)
  const r05 = searchProfiles(profiles.permisoAclaracionesSku, idProfile)
  const r06 = searchProfiles(profiles.permisoReporteNpsKlipp, idProfile)
  const r07 = searchProfiles(profiles.permisoReporteCasos, idProfile)
  const r08 = searchProfiles(profiles.permisoCRMNumeros, idProfile)
  const r09 = searchProfiles(profiles.permisoReporteInventarios, idProfile)
  const r10 = searchProfiles(profiles.aclarComments, idProfile)
  const r11 = searchProfiles(profiles.permisoRTickets, idProfile)
  const r12 = searchProfiles(profiles.permisoPedidosPorFecha, idProfile)
  const r13 = searchProfiles(profiles.permisoErrorTimbrado, idProfile)
  const r14 = searchProfiles(profiles.permisoCupones, idProfile)
  const r15 = searchProfiles(profiles.perm_carritos_abandonados, idProfile)
  const r16 = searchProfiles(profiles.permitproductdetail, idProfile)
  const r17 = searchProfiles(profiles.perm_lista_poblacion_colegios, idProfile)
  const r18 = searchProfiles(profiles.perm_list_publ_colegios, idProfile)
  const r19 = searchProfiles(profiles.Outstocksubscribers, idProfile)
  const r20 = searchProfiles(profiles.permiso_m_team, idProfile)
  const r21 = searchProfiles(profiles.permisoBundles, idProfile)
  const r22 = searchProfiles(profiles.permiso_montos_web, idProfile)
  const r23 = searchProfiles(profiles.permiso_nps, idProfile)
  const r24 = searchProfiles(profiles.permiso_store_credit, idProfile)
  const r25 = searchProfiles(profiles.permiso_repor_gast_envios, idProfile)
  const r26 = searchProfiles(profiles.permit_mag_user, idProfile)
  const r27 = searchProfiles(profiles.permisoPreciosArticulosMag, idProfile)
  const r28 = searchProfiles(profiles.reporteImportacionConfigurables, idProfile)
  const r29 = searchProfiles(profiles.calzadoActualizacionInformacion, idProfile)
  const r30 = searchProfiles(profiles.importaciondeListas, idProfile)
  const r31 = searchProfiles(profiles.permiso_montos_web_s, idProfile)
  const r32 = searchProfiles(profiles.permiso_Modelo_Resurtido_Tiendas, idProfile)
  const r33 = searchProfiles(profiles.permiso_Modelo_Utiles_Sap, idProfile)
  const r34 = searchProfiles(profiles.permisoPedidosLM, idProfile)
  const r35 = searchProfiles(profiles.permisoSkusConfigurables, idProfile)
  const r36 = searchProfiles(profiles.permit_rma_finanzas, idProfile)
  const r37 = searchProfiles(profiles.permit_aclar_finanzas, idProfile)
  const r38 = searchProfiles(profiles.permit_EntradasDevoluciones, idProfile)
  const r39 = searchProfiles(profiles.permit_Mailing, idProfile)
  const r40 = searchProfiles(profiles.permit_venta_neta, idProfile)
  const r41 = searchProfiles(profiles.permiso_stock, idProfile)
  const r42 = searchProfiles(profiles.permiso_stock_accesos, idProfile)
  const r43 = searchProfiles(profiles.permis_rep_vent, idProfile)
  const r44 = searchProfiles(profiles.permis_rep_inv_venta, idProfile)
  const r45 = searchProfiles(profiles.permis_rep_total_inv_venta, idProfile)
  const r46 = searchProfiles(profiles.permit_transito_venta, idProfile)

  const report = [
    {
      report: "Pedidos y Escuelas",
      platform: "Klaar",
      visible: r01,
    },
    {
      report: "NPS Tiendas",
      platform: "Klaar",
      visible: r02,
    },
    {
      report: "Venta Mayoreo General",
      platform: "Klaar",
      visible: r03,
    },
    {
      report: "RMA - Skus",
      platform: "Klaar",
      visible: r04,
    },
    {
      report: "Aclaraciones - Skus",
      platform: "Klaar",
      visible: r05,
    },
    {
      report: "NPS-Klipps",
      platform: "Klaar",
      visible: r06,
    },
    {
      report: "Casos",
      platform: "Klaar",
      visible: r07,
    },
    {
      report: "Casos y Asociados",
      platform: "Klaar",
      visible: r08,
    },
    {
      report: "Inventarios",
      platform: "Klaar",
      visible: r09,
    },
    {
      report: "Comentarios - Aclaraciones",
      platform: "Klaar",
      visible: r10,
    },
    {
      report: "Tickets",
      platform: "Klaar",
      visible: r11,
    },
    {
      report: "Pedidos por fecha",
      platform: "Klaar",
      visible: r12,
    },
    {
      report: "Errores de timbrado",
      platform: "Klaar",
      visible: r13,
    },
    {
      report: "Cupones Usados",
      platform: "Ezetera",
      visible: r14,
    },
    {
      report: "Carritos Abandonados",
      platform: "Ezetera",
      visible: r15,
    },
    {
      report: "Detalle de Producto",
      platform: "Ezetera",
      visible: r16,
    },
    {
      report: "Poblacion de Colegios",
      platform: "Ezetera",
      visible: r17,
    },
    {
      report: "Listas publicadas en colegios",
      platform: "Ezetera",
      visible: r18,
    },
    {
      report: "Out of Stock Subscribers",
      platform: "Ezetera",
      visible: r19,
    },
    {
      report: "M Team",
      platform: "Magento",
      visible: r20,
    },
    {
      report: "Bundle's",
      platform: "Klaar",
      visible: r21,
    },
    {
      report: "Montos Web M",
      platform: "Magento",
      visible: r22,
    },
    {
      report: "NPS",
      platform: "Magento",
      visible: r23,
    },
    {
      report: "Store Credit",
      platform: "Magento",
      visible: r24,
    },
    {
      report: "Gastos de Envio",
      platform: "Magento",
      visible: r25,
    },
    {
      report: "Clientes Magento",
      platform: "Magento",
      visible: r26,
    },
    {
      report: "Precios de Artículos en Magento",
      platform: "Magento - Ezetera",
      visible: r27,
    },
    {
      report: "Calzado - Importación configurables",
      platform: "SAP",
      visible: r28,
    },
    {
      report: "Calzado - Actualización de Información",
      platform: "SAP",
      visible: r29,
    },
    {
      report: "Calzado - Importación de listas",
      platform: "SAP",
      visible: r30,
    },
    {
      report: "Montos WEB S",
      platform: "SAP",
      visible: r31,
    },
    {
      report: "Modelo de Resurtido de Tiendas",
      platform: "SAP",
      visible: r32,
    },
    {
      report: "Paquete Útiles",
      platform: "SAP - Klaar",
      visible: r33,
    },
    {
      report: "Pedidos que no están en SAP - Lista de Materiales",
      platform: "SAP - Klaar",
      visible: r34,
    },
    {
      report: "Skus Configurables y Simples",
      platform: "Ezetera",
      visible: r35,
    },
    {
      report: "RMA - Finanzas",
      platform: "RMA - Finanzas",
      visible: r36,
    },
    {
      report: "Aclaraciones - Finanzas",
      platform: "Aclaraciones - Finanzas",
      visible: r37,
    },
    {
      report: "Entradas de Mercancía y Devoluciones",
      platform: "Entradas de Mercancía y Devoluciones",
      visible: r38,
    },
    {
      report: "Suscripciones Mailing",
      platform: "Suscripciones Mailing",
      visible: r39,
    },
    {
      report: "Ventas Netas",
      platform: "Multiplataforma",
      visible: r40,
    },
    {
      report: "Stock",
      platform: "Multi - Plataforma",
      visible: r41,
    },
    {
      report: "Stock de Accesos",
      platform: "Multi - Plataforma",
      visible: r42,
    },
    {
      report: "Ventas",
      platform: "Terminal punto de venta",
      visible: r43,
    },
    {
      report: "Movimientos",
      platform: "Terminal punto de venta",
      visible: r44,
    },
    {
      report: "Total inventario",
      platform: "Terminal punto de venta",
      visible: r45,
    },
    {
      report: "Traspasos en transito",
      platform: "Terminal punto de venta",
      visible: r46,
    },
  ];

  function searchProfiles(authorizedProfiles, id) {
    return authorizedProfiles.includes(id);
  }

  useEffect(() => {
    setLoading(true);
    const timeout = setTimeout(() => {
      setRows(report);
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  const visibleRows = rows.filter((row) => row.visible);

  const handleSearch = (e) => {
    const keyword = e.target.value;
    setKeytoSearch(keyword);

    const filteredRows = visibleRows.filter((row) => {
      const report = row.report && row.report.toLowerCase();
      const platform = row.platform && row.platform.toLowerCase();
      const reportMatches = report && report.includes(keyword.toLowerCase());
      const platformMatches =
        platform && platform.includes(keyword.toLowerCase());
      return reportMatches || platformMatches;
    });

    setSearchResults(filteredRows);
  };

  const handleSortModelChange = (newSortModel) => {
    setSortModel(newSortModel);
  };

  if (
    document.querySelector(
      ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
    )
  )
    document
      .querySelector(
        ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
      )
      .remove();

  function SelecPanelContent({ row }) {
    switch (row.report) {
      case "Errores de timbrado":
        return <DetailPanelContentNoDate row={row} />;
      case "Detalle de Producto":
        return <DetailPanelContentNoDate row={row} />;
      case "Store Credit":
        return <DetailPanelContentNoDate row={row} />;
      case "Clientes Magento":
        return <DetailPanelContentNoDate row={row} />;
      case "Precios de Artículos en Magento":
        return <DetailPanelContentNoDate row={row} />;
      case "Calzado - Importación configurables":
        return <DetailPanelContentNoDate row={row} />;
      case "Calzado - Actualización de Información":
        return <DetailPanelContentNoDate row={row} />;
      case "Calzado - Importación de listas":
        return <DetailPanelContentNoDate row={row} />;
      case "Skus Configurables y Simples":
        return <DetailPanelContentNoDate row={row} />;
      case "Paquete Útiles":
        return <DetailPanelContentNoDate row={row} />;
      case "Suscripciones Mailing":
        return <DetailPanelContentNoDate row={row} />;
      case "Stock":
        return <DetailPanelContentNoDate row={row} />;
      case "Stock de Accesos":
        return <DetailPanelContentNoDate row={row} />;
      case "Ventas":
        return <DetailPanelContentNoDate row={row} />;
      case "Movimientos":
        return <DetailPanelContentNoDate row={row} />;
      case "Total inventario":
        return <DetailPanelContentNoDate row={row} />;
      case "Traspasos en transito":
        return <DetailPanelContentNoDate row={row} />;
      case "Pedidos que no están en SAP - Lista de Materiales":
        return <DetailPanelContentNoDate row={row} />;
      case "Listas publicadas en colegios":
        return <DetailPanelContentId row={row} />;
      default:
        return <DetailPanelContent row={row} />;
    }
  }

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Grid container>
          <Grid item md={4} sx={{ px: 2 }}>
            <Stack direction="row" spacing={1} sx={{ marginY: "1rem" }}>
              <TextField
                size="small"
                variant="outlined"
                fullWidth
                label="Buscar reporte"
                value={keyWord}
                onChange={handleSearch}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box
        style={{
          height: 635,
          width: "100%",
        }}
      >
        <DataGridPro
          rows={searchResults.length > 0 ? searchResults : visibleRows}
          columns={columns}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          sx={{
            "& .MuiDataGrid-columnHeader": {
              backgroundColor: "rgb(4 28 50)",
              color: "white",
            },
            "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus .MuiDataGrid-sortIcon":
              {
                color: "white",
              },
            "& .MuiDataGrid-sortIcon": {
              color: "white",
            },
            "& .MuiDataGrid-row:nth-of-type(odd)": {
              backgroundColor: "rgb(245 245 245)",
            },
            "& .MuiDataGrid-row:nth-of-type(even)": {
              backgroundColor: "rgb(255 255 255)",
            },
          }}
          pagination
          pageSize={10}
          rowsPerPageOptions={[10]}
          getRowId={(row) => row.report}
          disableColumnMenu
          disableSelectionOnClick
          getDetailPanelHeight={() => "auto"}
          getDetailPanelContent={({ row }) => <SelecPanelContent row={row} />}
          loading={loading}
        />
      </Box>
    </>
  );
};
