import axios from "axios";

const getRefacturacion = async (idAcl, idUser) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/clarifications/clarifications/details?idAcl=${idAcl}&idUser=${idUser}`;
  try {
    const refacturacion = await axios(URL);

    if (refacturacion?.data?.success) {
      const ref = refacturacion.data.data[0];
      const keys = Object.keys(ref);
      const keysFiltered = keys.filter((key) => key.startsWith("join_"));
      const result = {};
      keysFiltered.forEach((key) => {
        result[key] = ref[key];
      });
      result["uso_cfdi"] = ref["uso_cfdi"];
      result["regimen_fiscal"] = ref["regimen_fiscal"];
      return result;
    }
    return null;
  } catch (error) {
    console.error(error);
  }
};

const getRFValues = async (idAcl, idUser) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/clarifications/clarifications/details?idAcl=${idAcl}&idUser=${idUser}`;
  try {
    const refacturacion = await axios(URL);

    if (refacturacion?.data?.success) {
      const ref = refacturacion.data.data[0];
      const keys = Object.keys(ref);
      const keysFiltered = keys.filter((key) => key.startsWith("rf_"));
      const result = {};
      keysFiltered.forEach((key) => {
        result[key] = ref[key] ?? "data";
      });
      return result;
    }
    return null;
  } catch (error) {
    console.error(error);
  }
};

const generateRefacturacion = async (idAcl, idUser, increment_id, rf) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/clarifications/rebilling/generate`;
  const data = {
    idAcl,
    idUser,
    increment_id,
    ...rf,
  };
  try {
    await axios.post(URL, data);
  } catch (error) {
    console.error(error);
  }
};

export { getRefacturacion, generateRefacturacion, getRFValues };
