import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Skeleton,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import ColegioList from "./ColegioList";
import { getColegio } from "../../../utils/proveedores/ventas/ventas";
import { useEffect } from "react";
import { useState } from "react";

const GradeList = ({
  Grade,
  idGrade,
  SumaValorStockG,
  startDate,
  endDate,
  idNivel,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataColegio, setDataColegio] = useState([]);

  useEffect(() => {
    if (open) {
      (async () => {
        setLoading(true);
        let colegio = await getColegio(
          23,
          startDate,
          endDate,
          idNivel,
          idGrade
        );
        setDataColegio(colegio.data);
        setLoading(false);
      })();
    }
  }, [open]);

  function formatNumber(num) {
    const roundedNum = Number(num).toFixed(2);
    return new Intl.NumberFormat("en-US").format(roundedNum);
  }

  return (
    <Accordion
      expanded={open}
      onChange={() => setOpen(!open)}
      sx={{
        borderStyle: "solid",
        borderColor: "rgba(0, 0, 0, 0.1)",
        borderWidth: "1px",
        marginTop: "15px",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        style={{
          flexDirection: "row-reverse",
          justifyContent: "space-between",
          backgroundColor: "#dfdfdf",
        }}
      >
        <Box display="flex" justifyContent="space-between" width="100%">
          <Typography style={{ order: 2 }} sx={{ fontSize: "1.09rem" }}>
            {Grade}
          </Typography>
          <Box
            style={{
              order: 3,
              marginLeft: "auto",
              width: "100px",
              textAlign: "left",
            }}
          >
            <Typography>
              <strong>${formatNumber(SumaValorStockG)}</strong>
            </Typography>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {open && loading && (
          <>
            <Skeleton sx={{ height: "50px" }} />
            <Skeleton sx={{ height: "50px" }} />
          </>
        )}
        {open &&
          !loading &&
          dataColegio.map((item, index) => (
            <ColegioList
              key={index}
              Colegio={item.Colegio}
              SumaValorStockC={item.SumaValorStock}
              startDate={startDate}
              endDate={endDate}
              idNivel={idNivel}
              idGrade={idGrade}
              idColegio={item.Id_school}
            />
          ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default GradeList;
