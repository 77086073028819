import { useState } from "react";
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  getReporteErroresDeTimbrado,
  getReporteDetalleDeProducto,
  getReporteStoreCredit,
  getReporteClientesMagento,
  getReportePreciosArticulosMagento,
  getReporteImportacionConfigurablesCalzado,
  getReporteActualizacionDeInformacionCalzado,
  getReporteImportacionDeListasCalzado,
  getReporteSkusConfigurablesSimples,
  getReportePaqueteUtiles,
  getReporteSuscripcionesMailing,
  getReporteStock,
  getReporteStockAccesos,
  getReporteVentas,
  getReporteMovimientos,
  getReporteTotalInventario,
  getReporteTraspasosEnTransito,
  getReportePedidosNotSAPListaMateriales
} from "../../../utils/reportes/reportes";
import ExcelFile from "../../general/ExcelFile";
import { LoadingButton } from "@mui/lab";
import { HistoricoLinks } from "./HistoricoLinks";

export const DetailPanelContentNoDate = ({ row: rowProp }) => {
  const [excelData, setExcelData] = useState(null);
  const [isELoading, setIsELoading] = useState(false);

  const handleButtonClick = (row) => {
    buscarReporte(row);
  };

  const buscarReporte = async (row) => {
    var horaActual = new Date().toTimeString().slice(0, 8).replace(/:/g, "");

    var fechaActual = new Date();
    var dia = fechaActual.getDate().toString().padStart(2, "0");
    var mes = (fechaActual.getMonth() + 1).toString().padStart(2, "0");
    var anio = fechaActual.getFullYear().toString();
    var hora = fechaActual.getHours().toString().padStart(2, "0");
    var minutos = fechaActual.getMinutes().toString().padStart(2, "0");
    var segundos = fechaActual.getSeconds().toString().padStart(2, "0");
    var fechaFormateada = `${anio}-${mes}-${dia}`;
    var horaFormateada = `${hora}_${minutos}_${segundos}`;
    var horaFormateada2 = `${hora}${minutos}${segundos}`;

    switch (row.report) {
      case "Errores de timbrado":
        try {
          setIsELoading(true);
          const response = await getReporteErroresDeTimbrado();
          setExcelData({
            data: response,
            name: `Reporte_ErroresTimbrado ${horaActual}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      case "Detalle de Producto":
        let initDate = `${new Date().getFullYear()}-01-01`;
        let endDate = new Date().toISOString().split("T")[0];
        try {
          setIsELoading(true);
          const response = await getReporteDetalleDeProducto(initDate, endDate);
          setExcelData({
            data: response,
            name: `detalleproducto ${initDate} a ${endDate} - ${horaFormateada}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      case "Store Credit":
        try {
          setIsELoading(true);
          const response = await getReporteStoreCredit();
          setExcelData({
            data: response,
            name: `reportecreditbalance ${fechaFormateada} - ${horaFormateada2}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      case "Clientes Magento":
        try {
          setIsELoading(true);
          const response = await getReporteClientesMagento();
          setExcelData({
            data: response,
            name: `Clientes de Magento ${fechaFormateada} - ${horaFormateada2}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      case "Precios de Artículos en Magento":
        try {
          setIsELoading(true);
          const response = await getReportePreciosArticulosMagento();
          setExcelData({
            data: response,
            name: `reporte_precios_articulos_magento ${fechaFormateada} - ${horaFormateada2}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      case "Calzado - Importación configurables":
        try {
          setIsELoading(true);
          const response = await getReporteImportacionConfigurablesCalzado();
          setExcelData({
            data: response,
            name: `reporte_importacion_configurables ${fechaFormateada} - ${horaFormateada2}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      case "Calzado - Actualización de Información":
        try {
          setIsELoading(true);
          const response = await getReporteActualizacionDeInformacionCalzado();
          setExcelData({
            data: response,
            name: `reporte_actualizacion_configurables ${fechaFormateada} - ${horaFormateada2}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      case "Calzado - Importación de listas":
        try {
          setIsELoading(true);
          const response = await getReporteImportacionDeListasCalzado();
          setExcelData({
            data: response,
            name: `reporte_actualizacion_configurables ${fechaFormateada} - ${horaFormateada2}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      case "Skus Configurables y Simples":
        try {
          setIsELoading(true);
          const response = await getReporteSkusConfigurablesSimples();
          setExcelData({
            data: response,
            name: `Reporte_SkusConfigurables ${fechaFormateada} - ${horaFormateada2}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      case "Paquete Útiles":
        try {
          setIsELoading(true);
          const response = await getReportePaqueteUtiles();
          setExcelData({
            data: response,
            name: `reporte_Modelo_utiles_sap_${fechaFormateada} - ${horaFormateada2}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      case "Suscripciones Mailing":
        try {
          setIsELoading(true);
          const response = await getReporteSuscripcionesMailing();
          setExcelData({
            data: response,
            name: `reporte_mailing_ ${fechaFormateada} - ${horaFormateada2}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      case "Stock":
        try {
          setIsELoading(true);
          const response = await getReporteStock();
          setExcelData({
            data: response,
            name: `reportestock_ ${fechaFormateada} - ${horaFormateada2}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      case "Stock de Accesos":
        try {
          setIsELoading(true);
          const response = await getReporteStockAccesos();
          setExcelData({
            data: response,
            name: `reportestcock ${fechaFormateada} - ${horaFormateada2}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      case "Ventas":
        try {
          setIsELoading(true);
          const response = await getReporteVentas();
          setExcelData({
            data: response,
            name: `reporteVentas_${fechaFormateada} - ${horaFormateada2}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      case "Movimientos":
        try {
          setIsELoading(true);
          const response = await getReporteMovimientos();
          setExcelData({
            data: response,
            name: `reporteinventarioventas ${fechaFormateada} - ${horaFormateada2}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      case "Total inventario":
        try {
          setIsELoading(true);
          const response = await getReporteTotalInventario();
          setExcelData({
            data: response,
            name: `reporteinventarioventas_${fechaFormateada} - ${horaFormateada2}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      case "Traspasos en transito":
        try {
          setIsELoading(true);
          const response = await getReporteTraspasosEnTransito();
          setExcelData({
            data: response,
            name: `reporteTransitoVentas ${fechaFormateada} - ${horaFormateada2}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      case "Pedidos que no están en SAP - Lista de Materiales":
        try {
          setIsELoading(true);
          const response = await getReportePedidosNotSAPListaMateriales();
          setExcelData({
            data: response,
            name: `reporte_pedidos_lm_m ${fechaFormateada} - ${horaFormateada2}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      default:
        console.log("No se econtro la opcion");
    }
  };

  return (
    <Stack
      sx={{ py: 2, height: "100%", boxSizing: "border-box" }}
      direction="column"
    >
      <Paper
        sx={{
          flex: 1,
          mx: "auto",
          width: "90%",
          p: 1,
          minWidth: "530px",
          backgroundColor: "rgb(245 245 245 / 30%)",
        }}
      >
        <Stack direction="column" spacing={1} sx={{ height: 1 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ textAlign: "left" }}>Accion</TableCell>
                <TableCell sx={{ textAlign: "right" }}>
                  {!excelData && (
                    <LoadingButton
                      variant="contained"
                      loading={isELoading}
                      onClick={() => handleButtonClick(rowProp)}
                      sx={{ mt: 1 }}
                    >
                      Buscar
                    </LoadingButton>
                  )}
                  {excelData && (
                    <ExcelFile
                      excelData={excelData.data}
                      fileName={excelData.name}
                    />
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <HistoricoLinks row={rowProp} />
            </TableBody>
          </Table>
        </Stack>
      </Paper>
    </Stack>
  );
};
