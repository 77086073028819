import axios from "axios";

/**
 * GET Excep
 */

function get_url_ase(currentPage, idUser, advancedS, typeE)
{
  let aux = `${process.env.REACT_APP_BASEURL}/trazabilidad/exceptions/exceptions/${typeE}/searchD?page=${currentPage}&idUser=${idUser}`;
  
  if(advancedS.orden && advancedS.orden.length > 5)
    aux = aux+`&idOrder=${advancedS.orden}`;
  if(advancedS.guia && advancedS.guia.length > 5)
    aux = aux+`&guia=${advancedS.guia}`;
  if(advancedS.reporte && advancedS.reporte.length > 2)
    aux = aux+`&reporte=${advancedS.reporte}`;
  if(advancedS.desde && advancedS.desde.length > 5)
    aux = aux+`&srtDate=${advancedS.desde}`;
  if(advancedS.hasta && advancedS.hasta.length > 5)
    aux = aux+`&endDate=${advancedS.hasta}`;
  if(advancedS.groupedselect > 0)
    aux = aux+`&idCarrier=${advancedS.groupedselect}`;

  return aux;
}

const getExceptionsList = async ( page, idUser ) => 
{
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/exceptions/exceptions/all/new?page=${page}&idUser=${idUser}`;
  try {
    const res = await axios(URL);

    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const searchExceptionsList = async ( page, search, idUser ) => 
{
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/exceptions/exceptions/all/search?page=${page}&search=${search}&idUser=${idUser}`;
  try {
    const res = await axios(URL);

    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getExceptionsIntList = async ( page, idUser ) => 
{
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/exceptions/exceptions/internal/new?page=${page}&idUser=${idUser}`;
  try {
    const res = await axios(URL);

    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const searchExceptionsIntList = async ( page, search, idUser ) => 
{
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/exceptions/exceptions/internal/search?page=${page}&search=${search}&idUser=${idUser}`;
  try {
    const res = await axios(URL);

    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const searchDetailExceptions = async ( page, idUser, advancedS, typeE ) => 
{
  const URL = get_url_ase(page, idUser, advancedS, typeE);
  try {
    const res = await axios(URL);

    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getAllExceptionsList = async ( page, idUser, profile ) => 
{
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/exceptions/exceptions/all/new?page=${page}&idUser=${idUser}&profile=${profile}`;
  try {
    const res = await axios(URL);

    return res.data?.success ? res.data.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getExcepById = async ( idExc ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/exceptions/exceptions/details?idExc=${idExc}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data.data[0] : null;
  } catch (error) {
    console.error(error);
  }
};

const getHistorialExcep = async ( idExc ) => 
{
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/exceptions/exceptions/historial?idExc=${idExc}`;
  try {
    const res = await axios(URL);

    return res.data?.success ? res.data.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getMessagesExcep = async ( idExc ) => 
{
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/exceptions/exceptions/messages?idExc=${idExc}`;
  try {
    const res = await axios(URL);

    return res.data?.success ? res.data.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getSDExcep = async ( idExc, increment_id ) => 
{
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/exceptions/exceptions/shipdata?idExc=${idExc}&increment_id=${increment_id}`;
  try {
    const res = await axios(URL);

    return res.data?.success ? res.data.data[0] : [];
  } catch (error) {
    console.error(error);
  }
};

const getAExcep = async ( idExc ) => 
{
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/exceptions/exceptions/addhist?idExc=${idExc}`;
  try {
    const res = await axios(URL);

    return res.data?.success ? res.data.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getAllExceptionsIntList = async ( page, idUser ) => 
{
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/exceptions/exceptions/internal/new?page=${page}&idUser=${idUser}`;
  try {
    const res = await axios(URL);

    return res.data?.success ? res.data.data : [];
  } catch (error) {
    console.error(error);
  }
};

/**
 * POST Excep
 */

const saveMessage = async ( increment_id, idExc, msj, idUser, option ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/exceptions/exceptions/messages/save`;
  try {
    await axios.post(URL, { increment_id, idExc, msj, idUser, option });
  } catch (error) {
    console.error("saveMessage", error);
  }
};

const saveSDExcep = async ( idExc, idUser, name, street, oNumber, iNumber, betStr, sub, del, cp, city, state, ref, tel ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/exceptions/exceptions/shipdata/save`;
  try {
    await axios.post(URL, { idExc, idUser, name, street, oNumber, iNumber, betStr, sub, del, cp, city, state, ref, tel });
  } catch (error) {
    console.error("saveSDExcep", error);
  }
};

const uploadFiles = async (idUser, carrier, files) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/exceptions/loads/files/upload`;
  try {
    const formData = new FormData();
    formData.append("idUser", idUser);
    formData.append("carrier", carrier);
    formData.append("files", files);

    const res = await axios({
      method: "POST",
      url: URL,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data;",
      },
    });

    if (!res?.data?.success) {
      throw new Error("uploadFiles:", "Error al enviar los archivos");
    }
  } catch (error) {
    console.error("uploadFiles", error);
  }
};

const closeExcep = async ( idExc ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/exceptions/exceptions/close`;
  try {
    await axios.post(URL, { idExc });
  } catch (error) {
    console.error("closeExcep", error);
  }
};

/**
 * GET Excep Tipi
 */

const getDetailTipification = async ( idTip ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/exceptions/tipifications/details?idTip=${idTip}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data.data[0] : [];
  } catch (error) {
    console.error(error);
  }
};

/**
 * POST Excep Tipi
 */

const addTipifications = async ( idUser, type, action, idCarrier, clave, internal ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/exceptions/tipifications/add`;
  try {
    await axios.post(URL, { idUser, type, action, idCarrier, clave, internal });
  } catch (error) {
    console.error("addTipifications", error);
  }
};

const saveTipifications = async ( idTip, idUser, type, action, idCarrier, clave, internal ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/exceptions/tipifications/save`;
  try {
    await axios.post(URL, { idTip, idUser, type, action, idCarrier, clave, internal });
  } catch (error) {
    console.error("saveTipifications", error);
  }
};

const DeleteTipification = async ( idTip, idUser ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/exceptions/tipifications/delete`;
  try {
    await axios.post(URL, { idTip, idUser });
  } catch (error) {
    console.error("DeleteTipification", error);
  }
};


/**
 * EXCEL EXPORT
**/

const getExcelExcepciones = async ( idUser, typeE ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/exceptions/exceptions/${typeE}/new?page=All&idUser=${idUser}`;
  try {
    let res = await axios(URL);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach(element => {
      let aux2 = {
        "No. de excepción": element.id,
        "Fecha de registro": new Date(element.fecha_reg).toLocaleDateString('es-mx', {timeZone: 'UTC'}),
        "Guia": element.guia_tx,
        "Estatus guía": element.guia_st,
        "Orden": element.id_order,
        "Fecha de embarque": new Date(element.fecha_embarque).toLocaleDateString('es-mx', {timeZone: 'UTC'}),
        "Carrier": element.carrier,
        "Tipificación": element.tipo,
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

export 
{
  getExceptionsList, searchExceptionsList, getExceptionsIntList, searchExceptionsIntList, searchDetailExceptions,
  getAllExceptionsList, getExcepById, getHistorialExcep, getMessagesExcep, saveMessage, getSDExcep, saveSDExcep, getAExcep,
  getAllExceptionsIntList, uploadFiles, addTipifications, saveTipifications, getDetailTipification, DeleteTipification, closeExcep,
  getExcelExcepciones
};