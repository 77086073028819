import {
  Box,
  Button,
  Card,
  CardContent,
  LinearProgress,
  TextField,
  Typography,
  Tabs,
  Tab,
  Grid,
  styled,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListSubheader,
  ListItemAvatar,
  Avatar,
  AppBar,
  Toolbar,
  IconButton,
  Paper,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  getMessagesByRMA,
  saveMessage,
} from "../../../utils/trazabilidad/rma/rma";
import { useEffect, useState, Fragment } from "react";
import { useAuth } from "../../../context/AuthContext";
import { deepOrange, deepPurple } from "@mui/material/colors";

import local from "dayjs/plugin/localizedFormat";
import relative from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";

dayjs.locale("es-mx");
dayjs.extend(local);
dayjs.extend(relative);
dayjs.extend(utc);

const RmaMessages = ({ rmaId, incrementId }) => {
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [detalle, setDetalle] = useState(null);
  const [mensaje, setMensaje] = useState("");

  const getMensajes = async () => {
    try {
      setIsLoading(true);
      const res = await getMessagesByRMA(rmaId);
      if (res && res.length > 0) setDetalle(res);
      else setDetalle(null);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveMessage = async () => {
    try {
      setIsLoading(true);
      await saveMessage(incrementId, rmaId, mensaje, auth.user.data.awsid);
      getMensajes();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      handleClose();
    }
  };

  const handleClose = () => {
    setMensaje("");
  };

  useEffect(() => {
    getMensajes();
  }, [rmaId]);

  return (
    <Box>
      {isLoading && <LinearProgress />}
      {!detalle && (
        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            overflow: "auto",
            maxHeight: 400,
            minHeight: 400,
          }}
        >
          <Fragment>
            <ListItem button>
              <ListItemText primary="Aún no hay mensajes" />
            </ListItem>
          </Fragment>
        </List>
      )}

      <Card elevation={0} variant="outlined" square>
        {detalle && (
          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              overflow: "auto",
              maxHeight: 400,
              minHeight: 400,
            }}
          >
            {detalle.map(
              ({
                id_mensaje,
                comentario,
                fecha_reg,
                nombreRecup,
                fotoUser,
              }) => (
                <Fragment key={id_mensaje}>
                  {auth.user.data.displayName == nombreRecup && (
                    <ListItem button>
                      <ListItemText
                        align="right"
                        primary={nombreRecup}
                        secondary={
                          <Fragment>
                            <Typography>{comentario}</Typography>
                            <Typography variant="caption">
                              {dayjs
                                .utc(fecha_reg)
                                .format("DD MMM YYYY, hh:mm A")}{" "}
                              - {dayjs.utc(fecha_reg).from(dayjs().utc(true))}
                            </Typography>
                          </Fragment>
                        }
                      />
                      <ListItemAvatar sx={{ ml: 2, mr: 0 }}>
                        {fotoUser && (
                          <Avatar alt="Profile Picture">
                            <img src={fotoUser} style={{ width: "100%" }} />
                          </Avatar>
                        )}
                        {!fotoUser && (
                          <Avatar
                            alt="Profile Picture"
                            sx={{ bgcolor: deepOrange[500] }}
                          >
                            {nombreRecup.charAt(0)}
                          </Avatar>
                        )}
                      </ListItemAvatar>
                    </ListItem>
                  )}

                  {auth.user.data.displayName != nombreRecup && (
                    <ListItem button>
                      <ListItemAvatar>
                        {fotoUser && (
                          <Avatar alt="Profile Picture">
                            <img src={fotoUser} style={{ width: "100%" }} />
                          </Avatar>
                        )}
                        {!fotoUser && (
                          <Avatar
                            alt="Profile Picture"
                            sx={{ bgcolor: deepPurple[500] }}
                          >
                            {nombreRecup.charAt(0)}
                          </Avatar>
                        )}
                      </ListItemAvatar>
                      <ListItemText
                        primary={nombreRecup}
                        secondary={
                          <Fragment>
                            <Typography>{comentario}</Typography>
                            <Typography variant="caption">
                              {dayjs
                                .utc(fecha_reg)
                                .format("DD MMM YYYY, hh:mm A")}{" "}
                              - {dayjs.utc(fecha_reg).from(dayjs().utc(true))}
                            </Typography>
                          </Fragment>
                        }
                      />
                    </ListItem>
                  )}
                </Fragment>
              )
            )}
          </List>
        )}
      </Card>
      <AppBar position="sticky" color="transparent" sx={{ top: "auto" }}>
        <Toolbar>
          <TextField
            id="outlined-textarea"
            label="Escribir mensaje..."
            size="small"
            value={mensaje}
            fullWidth
            onChange={(e) => setMensaje(e.target.value)}
            onKeyUp={async (e) => {
              if (e.key === "Enter") {
                await handleSaveMessage();
              }
            }}
          />
          <LoadingButton
            loading={isLoading}
            variant="contained"
            color="success"
            size="medium"
            sx={{ ml: 1 }}
            onClick={handleSaveMessage}
            disabled={!mensaje}
          >
            Enviar
          </LoadingButton>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default RmaMessages;
