import axios from "axios";

/**
 * GET
 **/

const getAssortedItems = async (currentPage) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/surtidos/surtidos/all/new?page=${currentPage}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const searchAssortedItems = async (currentPage, search) => {
    const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/surtidos/surtidos/all/search?page=${currentPage}&search=${search}`;
    try {
      const res = await axios(URL);
      return res.data?.success ? res.data : [];
    } catch (error) {
      console.error(error);
    }
  };

const getCarriers = async () => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/surtidos/surtidos/all/carriers`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const genGuideNumber = async (data) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/surtidos/surtidos/all/genguidenumber`;
  try {
    await axios.post(URL, data);
  } catch (error) {
    console.error("genGuideNumber", error);
  }
};

export { getAssortedItems, getCarriers, genGuideNumber, searchAssortedItems };
