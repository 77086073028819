import { Box, Button, Card, CardContent, CardHeader, IconButton, Typography, Grid, Table, TableBody, TableCell, TableContainer, CircularProgress, TableRow, Chip, Divider, Alert  } from "@mui/material";
import { useEffect, useState } from "react";
import DateInTable from "../../general/DateInTable";
import { getHRMarks, getHRFiles } from "../../../utils/altoRiesgo/alto/alto";

import AssignmentIcon from '@mui/icons-material/Assignment';
import ForumIcon from '@mui/icons-material/Forum';
import DownloadIcon from '@mui/icons-material/Download';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';
import CloseIcon from '@mui/icons-material/Close';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import DescriptionIcon from '@mui/icons-material/Description';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

import AltoProductos from "./AltoProductos";

const chipLColors = {
    "S/Estatus": "#f39c12",
    "Entregado": "#00a65a",
    "Enterado Logistica": "#00c0ef",
    "Detenido": "#dd4b39",
    "Confirmado cancelacion": "#dd4b39",
    "En ruta": "#00c0ef",
    "Confirmado liberacion": "#00a65a"
};

const chipACColors = {
    "S/Estatus": "#f39c12",
    "En Prorroga": "#00a65a",
    "Con evidencia": "#00a65a",
    "Enterado Atencion a Clientes": "#00a65a"
};

const chipFColors = {
    "S/Estatus": "#f39c12",
    "En espera de validacion": "#00c0ef",
    "Cerrada": "#dd4b39",
    "Rechazadas": "#dd4b39",
    "Nuevas": "#00c0ef",
    "Aprobadas": "#00a65a"
};

const AltoDSDetail = ({ altoId, detalle, orderd }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [marks, setMarks] = useState(null);
    const [files, setFiles] = useState(null);

    useEffect(() => {
        const getMarksHR = async () => {
            try {
                setIsLoading(true);
                const res = await getHRMarks(altoId);
                setMarks(res);
                const resp = await getHRFiles(altoId);
                setFiles(resp);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };
        getMarksHR();
    }, []);

    return (
        <Grid container spacing={2} sx={{ mt: 1}}>
                <Grid item md={5}>
                    <Card sx={{ mb: 2 }}>
                        <CardContent>
                            <Typography variant="h6" sx={{ ml: 2, my: 2 }}><AssignmentIcon sx={{ m: 0 }} /> Detalles de operación</Typography>
                            <TableContainer>
                                <Table size="small">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell><strong>Tipificación:</strong></TableCell>
                                            <TableCell>{detalle.tipnombre}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><strong>Fecha creación:</strong></TableCell>
                                            <TableCell><DateInTable dateString={detalle.fecha_alta} /></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><strong>Fecha de vencimiento:</strong></TableCell>
                                            <TableCell><DateInTable dateString={detalle.fecha_vencimiento} /></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><strong>Titular:</strong></TableCell>
                                            <TableCell>{detalle.titular_tarjeta}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><strong>Banco emisor:</strong></TableCell>
                                            <TableCell>{detalle.banco_nombre}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><strong>Nota de crédito:</strong></TableCell>
                                            <TableCell>{detalle.nota_credito ? detalle.nota_credito : 'S/Nota de crédito'}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><strong>Evidencia reembolso:</strong></TableCell>
                                            <TableCell>{detalle.archivo_rembolso ? (
                                                <a download href={"data:application/pdf;base64,"+detalle.archivo_rembolso} style={{ textDecoration: 'none', color: '#fff'}}>
                                                    <Button variant="outlined" size="small" startIcon={<DownloadIcon />} color="primary">Descargar</Button>
                                                </a>) : 'S/Evidencia'}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><strong>Estatus flujo:</strong></TableCell>
                                            <TableCell><Chip label={(detalle.sfnombre ? detalle.sfnombre : 'S/Estatus')} size="small" sx={{ backgroundColor: chipFColors[(detalle.sfnombre ? detalle.sfnombre : 'S/Estatus')], color: "#fff" }} /></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><strong>Estatus logística:</strong></TableCell>
                                            <TableCell><Chip label={(detalle.lonombre ? detalle.lonombre : 'S/Estatus')} size="small" sx={{ backgroundColor: chipLColors[(detalle.lonombre ? detalle.lonombre : 'S/Estatus')], color: "#fff" }} /></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><strong>Estatus atención:</strong></TableCell>
                                            <TableCell><Chip label={(detalle.acnombre ? detalle.acnombre : 'S/Estatus')} size="small" sx={{ backgroundColor: chipACColors[(detalle.acnombre ? detalle.acnombre : 'S/Estatus')], color: "#fff" }} /></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>

                    <Card sx={{ mb: 2 }}>
                        <CardContent>
                            <Typography variant="h6" sx={{ ml: 2, my: 2 }}><MarkunreadMailboxIcon sx={{ m: 0 }} /> Marcas</Typography>
                            {marks && (marks.map((row) => (
                                <Alert sx={{ mb: 2 }} variant="filled" severity={(row.tipo == "A" ? "info" : (row.tipo == "B" ? "warning" : "error"))}>{row.descripcion}</Alert>
                            )))}
                        </CardContent>
                    </Card>

                    <Card sx={{ mb: 2 }}>
                        <CardContent>
                            <Typography variant="h6" sx={{ ml: 2, my: 2 }}><FilePresentIcon sx={{ m: 0 }} /> Evidencias</Typography>
                            <Divider variant="middle" />
                            {isLoading && (<Box sx={{ml:2, mt:2, mr:2, mb:2}}> <Typography variant="p"> Cargando ...</Typography> <CircularProgress /> </Box>)}
                            {files && (files.map((row) => (
                                <>
                                    <Card key={row.id_expediente} sx={{ maxWidth: '100%', boxShadow: 'none' }}>
                                        <CardHeader avatar={ <DescriptionIcon /> } title={row.nombre} subheader={<DateInTable dateString={row.fecha_alta} />} 
                                        action={
                                        <a download href={"data:"+row.extension+";base64,"+row.base64} style={{ textDecoration: 'none', color: '#fff'}}>
                                            <IconButton aria-label="download" size="large" color="primary">
                                                <DownloadForOfflineIcon />
                                            </IconButton>
                                        </a>
                                        }/>
                                    </Card>
                                    <Divider variant="middle" />
                                </>
                            )))}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={7}>
                    <Card sx={{ mb: 2 }}>
                        <CardContent>
                            <Typography variant="h6" sx={{ ml: 2, my: 2 }}><ForumIcon sx={{ m: 0 }} /> Orden #{orderd.increment_id}</Typography>
                            <TableContainer>
                                <Table size="small">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell><strong>Fecha del pedido:</strong></TableCell>
                                            <TableCell><DateInTable dateString={orderd.created_at} /></TableCell>
                                            <TableCell><strong>Método de pago:</strong></TableCell>
                                            <TableCell>{orderd.method_name}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><strong>Email:</strong></TableCell>
                                            <TableCell>{orderd.email}</TableCell>
                                            <TableCell><strong>Fecha de registro:</strong></TableCell>
                                            <TableCell><DateInTable dateString={orderd.payment_date} /></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><strong>Teléfono:</strong></TableCell>
                                            <TableCell>{orderd.telephone}</TableCell>
                                            <TableCell><strong>Ciudad:</strong></TableCell>
                                            <TableCell>{orderd.city}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><strong>Estado:</strong></TableCell>
                                            <TableCell>{orderd.state}</TableCell>
                                            <TableCell><strong>Código postal:</strong></TableCell>
                                            <TableCell>{orderd.postal_code}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>

                    <Card sx={{ mb: 2 }}>
                        <CardContent>
                            <AltoProductos altoId={altoId} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
    );
};

export default AltoDSDetail;