import { CircularProgress, Tab, Tabs, Box, Button, Card, Typography, Grid, IconButton, Stack, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, InputLabel, Select, FormControl, MenuItem, List, ListItem, ListItemText } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTabs } from "../../../context/TabsContext";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import SearchIcon from "@mui/icons-material/Search";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import { profiles } from "../../../utils/trazabilidad/profilesTrazabilidad";
import { getOrderData } from "../../../utils/trazabilidad/verificaciones/verificaciones";
import { getExcelClarifications, getClarificationsTypes, saveClarification } from "../../../utils/trazabilidad/aclaraciones/aclaraciones";
import DateInTable from "../../general/DateInTable";
import Toast from "../../general/Toast";
import AclaracionesDataTable from "./AclaracionesDataTable";
import BreadCrumb from "../../general/BreadCrumb";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import ExcelFile from "../../general/ExcelFile";

const itemsBreadCrumbs = [
    {
      label: "Trazabilidad",
      icon: <ContentPasteSearchIcon sx={{ mr: 0.5 }} fontSize="small" />,
      link: "/trazabilidad/inicio",
    },
    { label: "Aclaraciones" },
];

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other} >
        {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
      </div>
    );
};

const chipColors = {
    "1": "#dd4b39",
    "2": "#f39c12",
    "3": "#007aa6",
    "4": "#b73384",
    "5": "#00a65a",
    "6": "#118B87",
    "7": "#6d6d6d",
    "8": "#1c108e",
    "9": "#118B87",
    "10": "#1c108e",
    "11": "#1c108e"
};
  
const chipLabels = {
    "1": "Nuevo",
    "2": "En Proceso",
    "3": "Sin Stock",
    "4": "En Espera de Reembolso",
    "5": "Liberado",
    "6": "En Espera de Refacturación",
    "7": "Cerrada",
    "8": "En espera de autorización",
    "9": "En espera de liberar clave",
    "10": "En espera de resolucion",
    "11": "En proceso de autorización"
};

const Aclaraciones = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const { crmTab, setCrmTab } = useTabs();
    const [open, setOpen] = useState(false);
    const [order, setOrder] = useState("");
    const [motivo, setMotivo] = useState("");
    const [type, setType] = useState("");
    const [data, setData] = useState(null);
    const [types, setTypes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [nextRender, setNextRender] = useState(false);
    const [isELoading, setIsELoading] = useState(false);
    const [opena, setOpenA] = useState(null);
    const [excelData, setExcelData] = useState([]);
    const [confalert, setConfAlert] = useState({
        severidad: "success",
        mensaje: "Aclaración registrada con éxito",
    });

    const handleTabChange = (event, value) => {
        setCrmTab(value);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenA = (severidad, mensaje) => {
        setConfAlert({ severidad: severidad, mensaje: mensaje });
        setOpenA(true);
    };

    const handleCloseA = () => {
        setOpenA(false);
    };

    const searchData = async () => {
        try {
          setIsLoading(true);
          setData(null);
          setType("");
          setTypes([]);
          const resp = await getOrderData(order);
          setData(resp);
          const respo = await getClarificationsTypes();
          setTypes(respo);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
    };

    const handleSaveSC = async () => {
        try {
          setIsLoading(true);
          if (data && data.increment_id && type && motivo) {
            let resp = await saveClarification(data.increment_id, type, user.data.awsid, motivo);
            if(resp){
                handleOpenA("success", "Aclaración registrada con éxito");
                handleClose();
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            }
            else handleOpenA("error", "La aclaración no pudo ser registrada, intente de nuevo más tarde");
          }
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
    };

    const getAllExcelData = async () => {
        try {
            setIsELoading(true);
            const data = await getExcelClarifications(user.role.idProfile, chipLabels);
            setExcelData(data);
        } catch (error) {
            console.error(error);
        } finally {
            setIsELoading(false);
        }
    };

    useEffect(() => {
        if (!profiles.aclaraciones.includes(user.role.idProfile)) {
          navigate("/dashboard", { replace: true });
        } else setNextRender(true);
    }, []);

    return (
    <>
        {nextRender && (
        <>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", }} >
                <Typography variant="h4" sx={{ my: 2 }}>Aclaraciones</Typography>
                <Box><BreadCrumb items={itemsBreadCrumbs} /></Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "end", mb: 2 }}>
                { excelData.length > 0 ? (<ExcelFile excelData={excelData} fileName={"Aclaraciones"} />) : (<LoadingButton loading={isELoading} variant="contained" color="info" onClick={getAllExcelData}>Generar excel</LoadingButton>) }
                <Button variant="contained" color="success" startIcon={<AddIcon />} onClick={handleClickOpen} sx={{ ml: 2 }} >
                    Crear aclaración
                </Button>
            </Box>

            <motion.div initial={{ y: 20 }} animate={{ y: 0 }} transition={{ duration: 0.5 }} >
                <Card>
                    <Box>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={crmTab} onChange={handleTabChange} >
                            <Tab label="Aclaraciones" />
                            <Tab label="Aclaraciones cerradas" />
                        </Tabs>
                        </Box>
                        <TabPanel value={crmTab} index={0}><AclaracionesDataTable typeP={1} chipColors={chipColors} chipLabels={chipLabels} /></TabPanel>
                        <TabPanel value={crmTab} index={1}><AclaracionesDataTable typeP={2} chipColors={chipColors} chipLabels={chipLabels} /></TabPanel>
                    </Box>
                </Card>
            </motion.div>

            <Dialog open={open} fullWidth={true} maxWidth="md" onClose={handleClose}>
                <DialogTitle>Nueva aclaración</DialogTitle>
                <DialogContent>
                    <Stack sx={{ mt: 2, mb: 2 }} direction="row" spacing={1}>
                        <TextField fullWidth label="Número de pedido" variant="outlined" value={order} onChange={ (e) => setOrder(e.target.value) } />
                        <Button variant="contained" color="info" startIcon={<SearchIcon />} disabled={!order || order.length < 9} onClick={searchData} >
                            Buscar
                        </Button>
                    </Stack>

                    {isLoading && (!data || types.length <= 0) && (
                        <>
                        <Typography variant="p"> Cargando ...</Typography>{" "}
                        <CircularProgress />
                        </>
                    )}

                    {data && (
                        <Grid container>
                            <Grid item md={6}>
                                <List>
                                <ListItem>
                                    <ListItemText primary="Número de pedido" secondary={data.increment_id} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Fecha de creación" secondary={ <DateInTable dateString={data.created_at} /> } />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Fecha de pago" secondary={ <DateInTable dateString={data.payment_date} /> } />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Escuela(s)" secondary={data.school} />
                                </ListItem>
                                </List>
                            </Grid>
                            <Grid item md={6}>
                                <List>
                                <ListItem>
                                    <ListItemText primary="Nombre del cliente" secondary={
                                        data.customer_name +
                                        " " +
                                        data.customer_lastname +
                                        " " +
                                        data.customer_mothersname
                                    }
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Teléfono" secondary={data.telephone} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Email" secondary={data.email} />
                                </ListItem>
                                </List>
                            </Grid>
                        </Grid>
                    )}

                    {types.length > 0 && (
                        <>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel id="selecte" htmlFor="groupedselect">Tipificación</InputLabel>
                            <Select id="groupedselect" name="groupedselect" label="Tipificación" value={type} onChange={ (e) => setType(e.target.value) }>

                                {types.map((row) => (
                                <MenuItem key={row.id_ac_tipo} value={row.id_ac_tipo}>
                                    {row.nombre_tipo_ac}
                                </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <TextField fullWidth label="Motivo" variant="outlined" multiline rows={3} value={motivo} onChange={ (e) => setMotivo(e.target.value) } />
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <LoadingButton loading={isLoading} variant="contained" color="info" startIcon={<SaveIcon />} disabled={!order || !type || !motivo} onClick={handleSaveSC}>
                        Guardar aclaración
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Toast open={opena} message={confalert.mensaje} type={confalert.severidad} onClose={handleCloseA} />
        </>
        )}
    </>
    );
}

export default Aclaraciones;