import { Typography } from "@mui/material";
import local from "dayjs/plugin/localizedFormat";
import relative from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";

dayjs.locale("es-mx");
dayjs.extend(local);
dayjs.extend(relative);
dayjs.extend(utc);

const DateInTable = ({ dateString }) => {
  return (
    <>
      <Typography variant="subtitle2" noWrap>
        {dayjs.utc(dateString).format("DD MMM YYYY, hh:mm A")}
      </Typography>
      <Typography variant="caption">
        {dayjs.utc(dateString).from(dayjs().utc(true))}
      </Typography>
    </>
  );
};

export default DateInTable;
