import { Box, Button, Card, Tab, Tabs, Typography } from "@mui/material";
import AsesoriasCasosTable from "./AsesoriasCasosTable";
import NuevoTicketDialog from "./NuevoTicketDialog";
import TicketsCerradosTable from "./TicketsCerradosTable";
import TicketsTable from "./TicketsTable";
import { useDialogs } from "../../context/DialogsContext";
import { motion } from "framer-motion";
import { useTabs } from "../../context/TabsContext";
import NuevoCasoOnCrmDialog from "./NuevoCasoOnCrmDialog";
import TabSearcher from "./TabSearcher";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  );
};

const CrmHome = () => {
  const { setOpenNuevoCasoOnCrmDialog } = useDialogs();
  const { crmTab, setCrmTab } = useTabs();
  const handleTabChange = (event, value) => {
    setCrmTab(value);
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" sx={{ my: 2 }}>
          Tickets
        </Typography>
        <Box>
          <Button variant="contained" onClick={() => setOpenNuevoCasoOnCrmDialog(true)}>
            Registrar nuevo ticket
          </Button>
          {/*<Button
            variant="contained"
            color="inherit"
            sx={{ bgcolor: "#fff", mr: 1 }}
            onClick={() => setOpenNuevoTicketDialog(true)}
          >
            Nuevo ticket
          </Button>*/}
        </Box>
      </Box>

      <motion.div initial={{ y: 20 }} animate={{ y: 0 }} transition={{ duration: 0.5 }}>
        <Card>
          <Box>
            <Tabs value={crmTab} onChange={handleTabChange}>
              <Tab label="Tickets" />
              <Tab label="Asesorias y casos" />
              <Tab label="Tickets cerrados" />
              <Tab label="Buscador" />
            </Tabs>
          </Box>

          <TabPanel value={crmTab} index={0}>
            <TicketsTable />
          </TabPanel>
          <TabPanel value={crmTab} index={1}>
            <AsesoriasCasosTable />
          </TabPanel>
          <TabPanel value={crmTab} index={2}>
            <TicketsCerradosTable />
          </TabPanel>
          <TabPanel value={crmTab} index={3}>
            <TabSearcher />
            {/* <BuscadorTickets isTab={false} /> */}
          </TabPanel>
        </Card>
      </motion.div>
      <NuevoTicketDialog />
      <NuevoCasoOnCrmDialog />
    </>
  );
};

export default CrmHome;
