import axios from "axios";

/**
 * GET
 */

const getOrderLabel = async (idGroup) => {
  const URL = `${process.env.REACT_APP_BASEURL}/proveedores/asignaroc/order/label/get?idGroup=${idGroup}`;
  try {
    const res = await axios(URL);

    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getLabelsList = async (page) => {
  const URL = `${process.env.REACT_APP_BASEURL}/proveedores/asignaroc/labels/all/new?page=${page}`;
  try {
    const res = await axios(URL);

    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const searchLabelsList = async (page, search) => {
  const URL = `${process.env.REACT_APP_BASEURL}/proveedores/asignaroc/labels/all/search?page=${page}&search=${search}`;
  try {
    const res = await axios(URL);

    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

/**
 * POST
 */

const updateOCLabel = async (details) => {
    const URL = `${process.env.REACT_APP_BASEURL}/proveedores/asignaroc/labels/payord/save`;
    console.log(details);
    try {
      const res = await axios.post(URL, details);
      return res.data?.success ? true : false;
    } catch (error) {
      console.error("updateOCLabel", error);
    }
  };

export { getOrderLabel, getLabelsList, searchLabelsList, updateOCLabel };
