import axios from "axios";
import {
  query,
  collection,
  where,
  getDocs,
  setDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../services/firebase/config";

const findUserByUid = async (uid) => {
  const q = query(collection(db, "users"), where("uid", "==", uid));
  try {
    let data = null;
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      data = doc.data();
    });

    return data;
  } catch (error) {
    console.log("findUserByUid", error);
  }
};

const createNewUser = async (data) => {
  try {
    const { uid, email, displayName, photoURL } = data;
    const currentUser = await axios.get(
      `${process.env.REACT_APP_BASEURL}/login/permissions?mail=${email}`
    );
    if (!currentUser?.data?.success) {
      throw new Error("usuario no encontrado en aws");
    }
    const { user, job, profile, permission, idProfile, phone_number } =
      currentUser.data.data;
    const newUser = {
      uid,
      data: {
        awsid: user,
        email,
        displayName,
        photoURL,
        phone_number,
      },
      role: {
        job,
        profile,
        idProfile,
        permission,
      },
    };

    await setDoc(doc(db, "users", email), { ...newUser });
    const generatedUser = await findUserByUid(uid);
    return generatedUser;
  } catch (error) {
    console.log("createNewUser", error);
  }
};

const updatePUser = async (data) => {
  try {
    const { uid, email, displayName, photoURL } = data;
    const currentUser = await axios.get(
      `${process.env.REACT_APP_BASEURL}/login/permissions?mail=${email}`
    );
    if (!currentUser?.data?.success) {
      throw new Error("usuario no encontrado en aws");
    }
    const { user, job, profile, permission, idProfile, phone_number } =
      currentUser.data.data;
    const newUser = {
      uid,
      data: {
        awsid: user,
        email,
        displayName,
        photoURL,
        phone_number,
      },
      role: {
        job,
        profile,
        idProfile,
        permission,
      },
    };

    await updateDoc(doc(db, "users", email), { ...newUser });
    const generatedUser = await findUserByUid(uid);
    return generatedUser;
  } catch (error) {
    console.log("createNewUser", error);
  }
};

const getUserByUid = async (data) => {
  try {
    const user = await findUserByUid(data.uid);

    if (user) {
      const updUser = await updatePUser(data);
      return updUser;
    }

    const newUser = await createNewUser(data);
    return newUser;
  } catch (error) {
    console.log("getUserByUid", error);
  }
};

const verifyLoginExter = async (details) => {
  const URL = `${process.env.REACT_APP_BASEURL}/login/loginexternal`;
  try {
    const res = await axios.post(URL, details);
    return res.data;
  } catch (error) {
    console.error("verifyLoginExter", error);
  }
};

const getUserExt = async (mail, id) => {
  const URL = `${process.env.REACT_APP_BASEURL}/login/loginextverify?mail=${mail}&id=${id}`;
  try {
    const res = await axios(URL);
    return res.data;
  } catch (error) {
    console.error(error);
  }
};

export { getUserByUid, verifyLoginExter, getUserExt };
