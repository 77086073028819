import { Box, Grid, Button, Stack, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { useAuth } from "../../../context/AuthContext";
import { getSupplier, searchSupplier, toRegisterSupplier, updateSupplier } from "../../../utils/pagos/proveedores/proveedores";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import Toast from "../../general/Toast";
import FormHelperText from '@mui/material/FormHelperText';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';

const ProveedoresDataTable = () => {
    const { user } = useAuth();
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [keyWord, setKeytoSearch] = useState("");
    const [loading, setLoading] = useState(false); //Loading index
    const [isRLoading, setIsRLoading] = useState(false); //Loading Button Register
    const [isULoading, setIsULoading] = useState(false); //Loading Button Update
    const [confalert, setConfAlert] = useState({ severidad: 'success', mensaje: '' });

    const [supplier, setSupplier] = useState("");
    const [rfc, setRfc] = useState("");
    const isRFCValid = (rfc) => rfc.length > 0 && rfc.length < 2
    const [codbanco, setCodBanco] = useState("");
    const isCodBancoValid = (codbanco) => codbanco.length > 0 && codbanco.length < 2
    const [banco, setBanco] = useState("");
    const isBancoValid = (banco) => banco.length > 0 && banco.length < 2
    const [ncuenta, setNoCuenta] = useState("");
    const isNCuentaValid = (ncuenta) => ncuenta.length > 0 && ncuenta.length < 2
    const [clabe, setClabe] = useState("");
    const isClabeValid = (clabe) => clabe.length > 0 && clabe.length < 2
    const [sucursal, setSucursal] = useState("");
    const [referencia, setReferencia] = useState("");

    //-------------------Register---------------------------------------------
    const [openr, setOpenR] = useState(false); //Dialog Register

    const handleOpenR = () => {
        setOpenR(true);
    };
    const handleCloseR = () => {
        setOpenR(false);
    };
    const handleClickOpenR = () => {
        handleOpenR();
        setIsRLoading(true);
    };
    const handleClickCloseR = () => {
        setSupplier("");
        setRfc("");
        setCodBanco("");
        setBanco("");
        setNoCuenta("");
        setClabe("");
        setSucursal("");
        setReferencia("");
        handleCloseR();
        setIsRLoading(false);
    };
    //--------------------------Update--------------------------------------

    const [openu, setOpenU] = useState(false); //Dialog Update

    const handleOpenU = () => {
        setOpenU(true);
    };
    const handleCloseU = () => {
        setOpenU(false);
    };

    const handleClickOpenU = () => {
        handleOpenU();
        setIsULoading(true);
    };

    const handleClickCloseU = () => {
        setSupplier("");
        setRfc("");
        setCodBanco("");
        setBanco("");
        setNoCuenta("");
        setClabe("");
        setSucursal("");
        setReferencia("");
        handleCloseU();
        setIsULoading(false);
    };

    //--------------------------Alert--------------------------------------

    const [openAlert, setOpenAlert] = useState(false);

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };
    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handleRegisterSupplier = async () => {
        try {
            setIsRLoading(true);
            if (!isRFCValid(rfc) && !isCodBancoValid(codbanco) && !isBancoValid(banco) && !isNCuentaValid(ncuenta) && !isClabeValid(clabe)) {
                let response = await toRegisterSupplier(user.data.awsid, rfc, codbanco, banco, ncuenta, clabe, sucursal, referencia);
                handleCloseAlert();
                if (response)
                    setConfAlert({ severidad: 'success', mensaje: 'Proveedor dado de alta con éxito' });
                else
                    setConfAlert({ severidad: 'error', mensaje: 'No pudo darse de alta el proveedor, intente más tarde' });
                handleOpenAlert();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsRLoading(false);
            handleClickCloseR();
        }
    };

    const handleUpdateSupplier = async () => {
        try {
            if (!isRFCValid(rfc) && !isCodBancoValid(codbanco) && !isBancoValid(banco) && !isNCuentaValid(ncuenta) && !isClabeValid(clabe)) {
                let response = await updateSupplier(user.data.awsid, rfc, codbanco, banco, ncuenta, clabe, sucursal, referencia, supplier);
                handleCloseAlert();
                if (response)
                    setConfAlert({ severidad: 'success', mensaje: 'Datos del proveedor actualizados con éxito' });
                else
                    setConfAlert({ severidad: 'error', mensaje: 'No pudo actualizarse los datos del proveedor, intente más tarde' });
                handleOpenAlert();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsULoading(false);
            handleClickCloseU();
        }
    };

    const handleGetSupplier = ( RFC, codigo_banco, sucursal, banco, no_cuenta, CLABE, referencia, IdSupplier ) => {
        setRfc(RFC);
        setCodBanco(codigo_banco);
        setSucursal(sucursal);
        setBanco(banco);
        setNoCuenta(no_cuenta);
        setClabe(CLABE);
        setReferencia(referencia);
        setSupplier(IdSupplier);
        handleClickOpenU();
    };

    const columns = [
        { field: "RFC", headerName: "RFC", flex: 1.2, align: "center", headerAlign: "center"},
        { field: "codigo_banco", headerName: "Codigo del Banco", flex: 1.1, align: "center", headerAlign: "center" },
        { field: "sucursal", headerName: "Sucursal", flex: 0.6, align: "center", headerAlign: "center" },
        { field: "banco", headerName: "N. del Banco", flex: 1.1, align: "center", headerAlign: "center" },
        { field: "no_cuenta", headerName: "Núm. de Cuenta", flex: 1.1, align: "center", headerAlign: "center" },
        { field: "CLABE", headerName: "Clabe", flex: 1.4, align: "center", headerAlign: "center" },
        { field: "referencia", headerName: "Referencia", flex: 0.8, align: "center", headerAlign: "center" },
        {
            field: "acciones", headerName: "Acciones", flex: 0.7, align: "center", headerAlign: "center", sortable: false,
            renderCell: (params) => (
                <LoadingButton loading={isULoading} variant="contained" size="small" startIcon={<EditIcon />} onClick={()=>handleGetSupplier( params.row.RFC, params.row.codigo_banco, params.row.sucursal, params.row.banco, params.row.no_cuenta, params.row.CLABE, params.row.referencia, params.row.IdSupplier)}></LoadingButton>
            ),
        },
    ];

    if (document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"))
        document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']").remove();

    useEffect(() => {
        let active = true;
        (async () => {
            let newRows = null;
            if (typeof keyWord === "undefined" || keyWord === undefined || keyWord.length === 0) {
                setLoading(true);
                newRows = await getSupplier(page);
            } else if (keyWord.length >= 5) {
                setLoading(true);
                newRows = await searchSupplier(page, keyWord);
            } else active = false;
            if (!active) {
                return;
            }
            setTotal(newRows.total);
            setRows(newRows.data);
            setLoading(false);
        })();
        return () => {
            active = false;
        };
    }, [page, keyWord, openr, openu]);

    return (
        <>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Grid container sx={{ my: 2 }}>
                    <Grid item md={4} sx={{ px: 2 }}>
                        <Stack direction="row" spacing={1}>
                            <TextField size="small" variant="outlined" fullWidth label="Buscar Proveedor..." value={keyWord} onChange={(e) => setKeytoSearch(e.target.value)}/>
                        </Stack>
                    </Grid>
                    <Grid item md sx={{ px: 2, textAlign: "right" }}>
                        {<LoadingButton  loading={isRLoading} variant="contained" color="info" onClick={handleClickOpenR} startIcon={<AddIcon />}>
                            Dar de Alta Proveedor
                        </LoadingButton>}
                    </Grid>
                </Grid>
            </Box>
            <Box style={{ height: 635, width: "100%" }}>
                <DataGridPro
                    rows={rows}
                    columns={columns}
                    pagination
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    getRowId={(row) => row.IdSupplier}
                    rowCount={total}
                    paginationMode="server"
                    onPageChange={(newPage) => {
                        setPage(newPage);
                    }}
                    loading={loading}
                    disableColumnMenu
                    disableSelectionOnClick
                />
            </Box>

            <Dialog open={openr} onClose={handleClickCloseR} maxWidth="sm" fullWidth>
                <DialogTitle>
                    Dar de alta
                </DialogTitle>    
                <DialogContent>
                    <Box component="form" sx={{ '& .MuiTextField-root': { m: 0.8, width: '100%' }, }} noValidate autoComplete="off">
                        <TextField label="RFC" fullWidth variant="outlined" required onChange={(a) => setRfc(a.target.value)} error={isRFCValid(rfc)}/>
                        {isRFCValid(rfc) && <FormHelperText error={isRFCValid(rfc)}>Datos no validos</FormHelperText>}
                        <TextField label="Codigo del Banco" fullWidth variant="outlined" required type="number" onChange={(e) => setCodBanco(e.target.value)} error={isCodBancoValid(codbanco)}/>
                        {isCodBancoValid(codbanco) && <FormHelperText error={isCodBancoValid(codbanco)}>Datos no validos</FormHelperText>}
                        <TextField label="Sucursal" fullWidth variant="outlined" type="number" onChange={(i) => setSucursal(i.target.value)} />
                        <TextField label="Nombre del Banco" fullWidth variant="outlined" required onChange={(o) => setBanco(o.target.value)} error={isBancoValid(banco)}/>
                        {isBancoValid(banco) && <FormHelperText error={isBancoValid(banco)}>Datos no validos</FormHelperText>}
                        <TextField label="Numero de Cuenta" fullWidth variant="outlined" required type="number" onChange={(u) => setNoCuenta(u.target.value)} error={isNCuentaValid(ncuenta)}/>
                        {isNCuentaValid(ncuenta) && <FormHelperText error={isNCuentaValid(ncuenta)}>Datos no validos</FormHelperText>}
                        <TextField label="Clabe" fullWidth variant="outlined" required type="number" onChange={(aa) => setClabe(aa.target.value)} error={isClabeValid(clabe)} />
                        {isClabeValid(clabe) && <FormHelperText error={isClabeValid(clabe)}>Datos no validos</FormHelperText>}
                        <TextField label="Referencia" fullWidth variant="outlined" onChange={(ee) => setReferencia(ee.target.value)} />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClickCloseR} variant="contained" color="error" startIcon={<CloseIcon/>} >
                        Cancelar
                    </Button>
                    <Button startIcon={<DoneIcon/>} variant="contained" color="success" disabled={isRFCValid(rfc) || rfc === "" || isCodBancoValid(codbanco) || codbanco === "" || isBancoValid(banco) || banco === "" || isNCuentaValid(ncuenta) || ncuenta === "" || isClabeValid(clabe) || clabe === ""} onClick={handleRegisterSupplier}>
                        Enviar
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openu} onClose={handleClickCloseU} maxWidth="sm" fullWidth>
                <DialogTitle>
                    Actualizar datos del Proveedor
                </DialogTitle>    
                <DialogContent>
                    <Box component="form" sx={{ '& .MuiTextField-root': { m: 0.8, width: '100%' }, }} noValidate autoComplete="off">
                        <TextField label="RFC" fullWidth variant="outlined" value={rfc} required onChange={(a) => setRfc(a.target.value)} error={isRFCValid(rfc)}/>
                        {isRFCValid(rfc) && <FormHelperText error={isRFCValid(rfc)}>Datos no validos</FormHelperText>}
                        <TextField label="Codigo del Banco" fullWidth variant="outlined" value={codbanco} required type="number" onChange={(e) => setCodBanco(e.target.value)} error={isCodBancoValid(codbanco)}/>
                        {isCodBancoValid(codbanco) && <FormHelperText error={isCodBancoValid(codbanco)}>Datos no validos</FormHelperText>}
                        <TextField label="Sucursal" fullWidth variant="outlined" value={sucursal} type="number" onChange={(i) => setSucursal(i.target.value)} />
                        <TextField label="Nombre del Banco" fullWidth variant="outlined" value={banco} required onChange={(o) => setBanco(o.target.value)} error={isBancoValid(banco)}/>
                        {isBancoValid(banco) && <FormHelperText error={isBancoValid(banco)}>Datos no validos</FormHelperText>}
                        <TextField label="Numero de Cuenta" fullWidth variant="outlined" value={ncuenta} required type="number" onChange={(u) => setNoCuenta(u.target.value)} error={isNCuentaValid(ncuenta)}/>
                        {isNCuentaValid(ncuenta) && <FormHelperText error={isNCuentaValid(ncuenta)}>Datos no validos</FormHelperText>}
                        <TextField label="Clabe" fullWidth variant="outlined" value={clabe} required type="number" onChange={(aa) => setClabe(aa.target.value)} error={isClabeValid(clabe)} />
                        {isClabeValid(clabe) && <FormHelperText error={isClabeValid(clabe)}>Datos no validos</FormHelperText>}
                        <TextField label="Referencia" fullWidth variant="outlined" value={referencia} onChange={(ee) => setReferencia(ee.target.value)} />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClickCloseU} variant="contained" color="error" startIcon={<CloseIcon/>} >
                        Cancelar
                    </Button>
                    <LoadingButton startIcon={<SaveIcon/>} variant="contained" color="success" disabled={isRFCValid(rfc) || rfc === "" || isCodBancoValid(codbanco) || codbanco === "" || isBancoValid(banco) || banco === "" || isNCuentaValid(ncuenta) || ncuenta === "" || isClabeValid(clabe) || clabe === ""} onClick={handleUpdateSupplier}>
                        Guardar
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Toast open={openAlert} message={confalert.mensaje} type={confalert.severidad} onClose={handleCloseAlert}/>
        </>
    );
};

export default ProveedoresDataTable;
