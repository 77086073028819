import {
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { useEffect } from "react";
import { createContext, useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { auth } from "../services/firebase/config";
import { getUserByUid } from "../utils/login";

const AuthContext = createContext({
  user: null,
  login: async () => {},
  logout: async () => {},
});

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const location = useLocation();

  const login = async () => {
    try {
      await signInWithPopup(auth, new GoogleAuthProvider());
      //await setPersistence(auth, browserSessionPersistence);
    } catch (error) {
      console.log("login", error);
    }
  };

  const logout = async () => {
    // if (JSON.parse(localStorage.getItem("user")).uid) {
      try {
        await signOut(auth);
        localStorage.removeItem("user");
      } catch (error) {
        console.log("logout", error);
      }
    // } else {
    //   setUser(null);
    //   localStorage.removeItem("user");
    // }
  };

  useEffect(() => {
    // let uInit = localStorage.getItem("user");
    // if (uInit === "undefined") {
    //   localStorage.removeItem("user");
    //   window.location.reload();
    // }
    // if (!JSON.parse(uInit) ||  JSON.parse(uInit).uid) {
      const checkIfUserExist = async () => {
        const u = localStorage.getItem("user");
        if (u) {
          setUser(JSON.parse(u));
        }
      };

      const unsub = onAuthStateChanged(auth, (current) => {
        if (current) {
          getUserByUid(current).then((u) => {
            // u.role.idProfile = 7;
            console.log("user", u);
            setUser(u);
            localStorage.setItem("user", JSON.stringify(u));
          });
        } else {
          setUser(null);
          localStorage.removeItem("user");
        }
      });

      checkIfUserExist();
      return unsub;
    // } else {
    //   const checkIfUserExtExist = async () => {
    //     const u = localStorage.getItem("user");
    //     if (u) {
    //       try {
    //         setUser(JSON.parse(u));
    //         let mail = JSON.parse(u).data.email;
    //         let id = JSON.parse(u).data.awsid;
    //         let userFetch = await getUserExt(mail, id);
    //         console.log("user", userFetch.data);
    //         setUser(userFetch.data);
    //         localStorage.setItem("user", JSON.stringify(userFetch.data));
    //       } catch (error) {
    //         setUser(null);
    //         localStorage.removeItem("user");
    //         window.location.reload();
    //       }
    //     }
    //   };

    //   checkIfUserExtExist();
    // }
  }, []);

  useEffect(() => {
    if (location.pathname !== "/login") {
      console.log("last location", location.pathname);
      localStorage.setItem(
        "lastPath",
        location.pathname === "/" ? "/dashboard" : location.pathname
      );
    }
  }, [location.pathname]);

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
