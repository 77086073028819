import { Box, Typography, InputLabel, Select, FormControl, FormControlLabel, Menu, MenuItem, Grid, TextField, Alert, Card, CardContent, Checkbox } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../../../context/AuthContext";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import DateInTable from "../../../general/DateInTable";
import Toast from "../../../general/Toast";
import { saveSC, closeStore, updateResolution } from "../../../../utils/trazabilidad/backorders/backorders";

const SCTab = ({ boId, detalle, articulos }) => {
    const { user } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [cnote, setCNote] = useState(null);
    const [quanti, setQuanti] = useState(null);
    const [checked, setChecked] = useState(false);
    const [opena, setOpenA] = useState(false);
    const [confalert, setConfAlert] = useState({
        severidad: "success",
        mensaje: "",
    });

    const handleClose = () => {
        setCNote(null);
        setQuanti(null);
        setChecked(false);
    };

    const handleOpenA = () => {
        setOpenA(true);
    };
    
    const handleCloseA = () => {
        setOpenA(false);
    };

    const columns = [
        { field: "joinSku", headerName: "SKU", flex: 1, align: "center", headerAlign: "center", },
        { field: "joinProducto", headerName: "Nombre Producto", flex: 2 },
        { field: "joinCant_pedida", headerName: "Cant. pedida", align: "center", headerAlign: "center", flex: 0.6,  },
        { field: "joinCant_surtida", headerName: "Cant. surtida", align: "center", headerAlign: "center", flex: 0.6,  },
        { field: "joinCant_faltante", headerName: "Cant. faltante", align: "center", headerAlign: "center", flex: 0.6,  },
        { field: "price", headerName: "Precio", align: "center", headerAlign: "center", flex: 1, valueGetter: ({ row }) => ('$ '+row.price) },
        { field: "id_item_status", headerName: "Status", align: "center", headerAlign: "center", flex: 1, valueGetter: ({ row }) => ('Store Credit') },
    ];

    function get_info() {
        let price = 0;
        let skus = [];
        let ids = [];
        articulos.forEach(element => {
            price = price + (element.price*element.joinCant_pedida);
            skus.push(element.joinSku);
            ids.push(element.id_item_bo);
        });

        let aux = {
            total: price,
            askus: skus,
            ids: ids
        };

        return aux;
    }

    const handleSaveData = async () => {
        try {

            setIsLoading(true);

            let aux = get_info();
            const resp = await saveSC(boId, user.data.awsid);
            const resp2 = await updateResolution(boId, user.data.awsid, 8, detalle.increment_id, detalle.customer_id, "", "", "", "", "", aux.total, aux.askus);

            if(resp && resp2) {
                setConfAlert({
                    severidad: "success",
                    mensaje: "Solicitud de Store Credit enviada",
                });
                handleOpenA(true);
            }else {
                setConfAlert({
                    severidad: "warning",
                    mensaje: "Hubo un problema, intenta de nuevo más tarde",
                });
                handleOpenA(true);
            }

        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);          
        }
    };

    const handleSaveSC = async () => {
        try {
            if(cnote && quanti){
                setIsLoading(true);
                let aux = get_info();
                const resp = await closeStore(detalle.id_storecredit, user.data.awsid, cnote, boId, detalle.customer_id, detalle.increment_id, quanti, (checked ? 1 : 0), user.data.displayName, aux.ids, aux.askus );
                
                if(resp) {
                    setConfAlert({
                        severidad: "success",
                        mensaje: "Store Credit aplicado",
                    });
                    handleOpenA(true);
                    handleClose();
                }else {
                    setConfAlert({
                        severidad: "warning",
                        mensaje: "Hubo un problema, intenta de nuevo más tarde",
                    });
                    handleOpenA(true);
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
    <>
        <Box sx={{mb: 2, mx: 2}}>
            <div style={{ display: "flex", height: "100%" }}>
                <div style={{ flexGrow: 1 }}>
                    <DataGrid
                        autoHeight
                        rows={articulos}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        getRowId={(row) => row.id_item_bo}
                        loading={isLoading}
                        disableColumnMenu
                        disableSelectionOnClick
                    />
                </div>
            </div>
        </Box>

        {!detalle.uss_date && (<Grid container sx={{mt:2, mb:2}}>
            <Grid item md={4}></Grid>
            <Grid item md={4}>
                <LoadingButton loading={isLoading} fullWidth size="medium" variant="contained" color="success" onClick={handleSaveData}>Solicitar Store Credit</LoadingButton>
            </Grid>
            <Grid item md={4}></Grid>
        </Grid>
        )}

        {(detalle.uss_date && !detalle.uas_date) && (
        
        <Grid container sx={{mt:2, mb:2}}>
            <Grid item md={3}></Grid>
            <Grid item md={6}>
                <Alert sx={{ mb:2 }} variant="filled" severity="primary">
                    <Typography variant="p" component="div">Store Credit solicitado por <strong>{detalle.uss_name} {detalle.uss_lastname}</strong> <DateInTable dateString={detalle.uss_date} /> </Typography>
                </Alert>
                <Card>
                    <CardContent>
                        <Grid container>
                            <Grid item md={3}></Grid>
                            <Grid item md={6} sx={{mt:2, mb:2}}>
                                <TextField fullWidth sx={{mb:2}} id="cnote" label="Nota de crédito" value={cnote} onChange={(e) => setCNote(e.target.value)}/>
                                <TextField fullWidth sx={{mb:2}} id="quanti" label="Cantidad" value={quanti} onChange={(e) => setQuanti(e.target.value)} type="number"/>
                                <FormControlLabel control={
                                    <Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)} />
                                } label="Considerar gastos de envío" />
                                <Typography sx={{mb:2}} variant="h4" align="center" component="div">${quanti}</Typography>
                                <LoadingButton loading={isLoading} fullWidth size="medium" variant="contained" color="success" onClick={handleSaveSC} disabled={(!cnote || !quanti)}>Asignar Store Credit</LoadingButton>
                            </Grid>
                            <Grid item md={3}></Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item md={3}></Grid>
        </Grid>
        )}

        {(detalle.uss_date && detalle.uas_date) && (
            <Grid container sx={{mt:2, mb:2}}>
                <Grid item md={4}></Grid>
                <Grid item md={4}>
                    <Alert variant="filled" severity="success"><Typography variant="p"><strong>¡Crédito en tienda asignado!</strong></Typography></Alert>
                    <Card>
                        <CardContent>
                            <Typography variant="p" component="div">Nota de crédito: <strong></strong></Typography>
                            <Typography variant="p" component="div">Cantidad del reembolso en Store Credit:</Typography>
                            <Typography variant="h4" align="center" component="div">$</Typography>
                        </CardContent>
                    </Card>                        
                </Grid>
                <Grid item md={4}></Grid>
            </Grid>
        )}


        <Toast open={opena} message={confalert.mensaje} type={confalert.severidad} onClose={handleCloseA} />
    </>
    );
};

export default SCTab;