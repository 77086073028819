import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  LinearProgress,
  Link,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAuth } from "../../../context/AuthContext";
import { asociarCaso } from "../../../utils/crm/asociaciones";

const CasosTab = ({ casos, details }) => {
    const auth = useAuth();
    const navigate = useNavigate();
    const [asociados, setAsociados] = useState([]);

    const handleAsociar = async ({ id }) => {
        if (asociados.includes(id)) {
          return;
        }
    
        try {
          await asociarCaso({
            idMainFather: details.id_crm_main,
            idMainChild: id,
            idUrl: details.id_crm_main,
            idUser: auth.user.data.awsid,
          });
          setAsociados([...asociados, id]);
          navigate("..", { replace: true });
        } catch (error) {
          console.error("handleAsociar", error);
        }
    };

    const handleViewCase = (id_crm_case) => {
        navigate(`../case/${id_crm_case}`);
    };

    return (
        <>
            <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Asociar</TableCell>
                    <TableCell>Caso</TableCell>
                    <TableCell>Fecha</TableCell>
                    <TableCell>Tipo</TableCell>
                    <TableCell>Tipificacion</TableCell>
                    <TableCell>Descripcion</TableCell>
                    <TableCell>Contacto</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {casos &&
                    casos.map((caso, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Checkbox
                            checked={asociados.includes(caso.id_crm_case)}
                            onClick={() =>
                              handleAsociar({ id: caso.id_crm_case })
                            }
                          />
                        </TableCell>
                        <TableCell>{caso.id_crm_case}</TableCell>
                        <TableCell>
                          <Typography variant="subtitle2" noWrap>
                            {dayjs(caso.lastmove).format(
                              "DD MMM YYYY, hh:mm A"
                            )}
                          </Typography>
                          <Typography variant="caption">
                            {dayjs(caso.lastmove).fromNow()}
                          </Typography>
                        </TableCell>
                        <TableCell>{caso.case_type}</TableCell>
                        <TableCell>{caso.tipification}</TableCell>
                        <TableCell>
                          {caso.description?.slice(0, 100) ?? ""}
                        </TableCell>
                        <TableCell>{caso.contact}</TableCell>
                        <TableCell>{caso.name_s}</TableCell>
                        <TableCell>
                          <Button
                            variant="outlined"
                            size="small"
                            disableElevation
                            onClick={() => handleViewCase(caso.id_crm_case)}
                          >
                            Detalle
                          </Button>
                        </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </>
    )
};

export default CasosTab;