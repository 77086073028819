import axios from "axios";

const getReembolsos = async (id_aclaracion) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/clarifications/clarifications/details/tab/reembolso?id_aclaracion=${id_aclaracion}`;
  try {
    const reembolsos = await axios(URL);

    return reembolsos.data?.success ? reembolsos.data.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getBCAValues = async (idAcl, idUser) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/clarifications/clarifications/details?idAcl=${idAcl}&idUser=${idUser}`;
  try {
    const refacturacion = await axios(URL);

    if (refacturacion?.data?.success) {
      const ref = refacturacion.data.data[0];
      const keys = Object.keys(ref);
      const keysFiltered = keys.filter((key) => key.startsWith("bca_"));
      const result = {};
      keysFiltered.forEach((key) => {
        result[key] = ref[key] ?? 1;
      });
      return result;
    }
    return null;
  } catch (error) {
    console.error(error);
  }
};

const generateReembolso = async (idAcl, idUser, increment_id, bca) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/clarifications/rebilling/generate`;
  const data = {
    idAcl,
    idUser,
    increment_id,
    ...bca,
  };
  try {
    await axios.post(URL, data);
  } catch (error) {
    console.error(error);
  }
};

export { getReembolsos, getBCAValues, generateReembolso };
