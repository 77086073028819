import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  LinearProgress,
  Link,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { useTabs } from "../../context/TabsContext";
import { asociarCaso } from "../../utils/crm/asociaciones";
import {
  getCases,
  getFiles,
  getInteractions,
  getOrders,
  getProcesses,
} from "../../utils/crm/buscador";
import { getDataDownloadFromBase64, uploadFiles } from "../../utils/crm/files";
import DateInTable from "../general/DateInTable";
import { motion } from "framer-motion";
import TabPanel from "../general/TabPanel";
import CasosTab from "./tabs/CasosTab";
import InterTab from "./tabs/InteraTab";
import ProcesTab from "./tabs/ProcesTab";
import PedidosTab from "./tabs/PedidosTab";
import ArchivosTab from "./tabs/ArchivosTab";
import EmailsTab from "./tabs/EmailsTab";

const DetailSearcher = ({ details }) => {
  const auth = useAuth();
  const navigate = useNavigate();

  const [pedido, setPedido] = useState("");
  const [telefono, setTelefono] = useState("");
  const [correo, setCorreo] = useState(details.contact);
  const [rma, setRma] = useState("");

  const [casos, setCasos] = useState(null);  

  const [tab, setTab] = useState(0);
  const { crmTab, setCrmTab } = useTabs();
  const [isLoading, setIsLoading] = useState(false);

  const handleTabChange = (event, value) => {
    setCrmTab(value);
  };

  const handleSearch = useCallback(async () => {
    if (!pedido && !telefono && !correo && !rma) {
      return;
    }
    setIsLoading(true);
    try {
      const newCasos = await getCases(pedido, telefono, correo);
      /*const newInteracciones = await getInteractions(correo);
      const newProcesos = await getProcesses(pedido, telefono, correo, rma);
      const newPedidos = await getOrders(pedido, telefono, correo);
      const newArchivos = await getFiles(details.id_crm_main);*/

      setCasos(newCasos);
      /*setInteracciones(newInteracciones);
      setProcesos(newProcesos);
      setPedidos(newPedidos);
      setArchivos(newArchivos);*/
    } catch (error) {
      console.error("handleSearch", error);
    }
    setIsLoading(false);
  }, [correo, details.id_crm_main, pedido, rma, telefono]);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  return (
    <motion.div
      initial={{ y: 20 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Card sx={{ mb: 4 }}>
        {isLoading && <LinearProgress />}
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Informacion relacionada
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "3fr 3fr 3fr 3fr 1fr",
              gap: 2,
              mb: 2,
            }}
          >
            <TextField
              value={pedido}
              onChange={(e) => setPedido(e.target.value)}
              variant="filled"
              label="Pedido"
            />
            <TextField
              value={telefono}
              onChange={(e) => setTelefono(e.target.value)}
              variant="filled"
              label="Telefono"
            />
            <TextField
              value={correo}
              onChange={(e) => setCorreo(e.target.value)}
              variant="filled"
              label="Correo"
            />
            <TextField
              value={rma}
              onChange={(e) => setRma(e.target.value)}
              variant="filled"
              label="RMA"
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                disableElevation
                onClick={handleSearch}
              >
                Buscar
              </Button>
            </Box>
          </Box>
          <Box>
            <Tabs value={crmTab} onChange={handleTabChange}>
              <Tab label="Casos" />
              <Tab label="Interacciones" />
              <Tab label="Procesos" />
              <Tab label="Pedidos" />
              <Tab label="Archivos" />
              <Tab label="Emails" />
            </Tabs>
            <TabPanel value={crmTab} index={0}>{casos && (<CasosTab casos={casos} details={details} />)}</TabPanel>
            <TabPanel value={crmTab} index={1}>{correo && (<InterTab correo={correo} />)}</TabPanel>
            <TabPanel value={crmTab} index={2}>{(pedido && telefono && correo && rma) && (<ProcesTab pedido={pedido} telefono={telefono} correo={correo} rma={rma} />)}</TabPanel>
            <TabPanel value={crmTab} index={3}>{(pedido && telefono && correo && rma) && (<PedidosTab pedido={pedido} telefono={telefono} correo={correo} rma={rma} />)}</TabPanel>
            <TabPanel value={crmTab} index={4}><ArchivosTab details={details} pedido={pedido} telefono={telefono} correo={correo} rma={rma} /></TabPanel>
            <TabPanel value={crmTab} index={5}><EmailsTab details={details} /></TabPanel>
          </Box>
        </CardContent>
      </Card>
    </motion.div>
  );
};

export default DetailSearcher;
