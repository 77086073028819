import useSWR from "swr";
import {
  Box,
  Chip,
  CircularProgress,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Accordion, 
  AccordionSummary, 
  AccordionDetails,
  Card,
  CardContent,
  Stack,
  InputLabel, 
  Select, 
  FormControl, 
  MenuItem
} from "@mui/material";
import { useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import { useAuth } from "../../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { useLocation } from "react-router-dom";
import TipificacionesTableBody from "./TipificacionesTableBody";

const useExceps = (keyWord, currentPage, user) => {
    const fetcher = (...args) => fetch(...args).then((res) => res.json());
    let opt = "";
    
    if(typeof keyWord === 'undefined' || typeof keyWord === undefined || keyWord.length < 2)
      opt = `${process.env.REACT_APP_BASEURL}/trazabilidad/exceptions/tipifications/all?page=${currentPage}&idUser=${user.data.awsid}`;  
    else
      opt = `${process.env.REACT_APP_BASEURL}/trazabilidad/exceptions/tipifications/search?page=${currentPage}&search=${keyWord}&idUser=${user.data.awsid}`;
    
    const url = opt;
    const { data } = useSWR(url, fetcher, {
      //refreshInterval: 2000,
      revalidateOnFocus: true,
    });
  
  
    return {
      page: currentPage,
      rowsPerPage: data?.data?.length ?? 0,
      rows: data?.data ?? [],
      total: data?.total ?? 0,
    };
};

const TipificacionesTable = () => {
  const [keyWord, setExcepABuscar] = useState("");
  const [currentPage, setCurrentPage] = useState(0);  
  const [loading, setLoading] = useState({});
  const { user } = useAuth();  
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [excepFiltrados, setExcepFiltrados] = useState(null);
  const excep = useExceps(keyWord, currentPage, user);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  return (    
    <Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <TextField
          size="small"
          variant="filled"
          label="Buscar"
          sx={{ mx: 1, my: 2 }}
          value={keyWord}
          onChange={(e) => setExcepABuscar(e.target.value)}
        />
        {isLoading && <CircularProgress size={30} />}
      </Box>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Clave</TableCell>
              <TableCell>Acción</TableCell>
              <TableCell>Carrier</TableCell>
              <TableCell>Opciones</TableCell>
            </TableRow>
          </TableHead>
          <TipificacionesTableBody 
            exceps={excepFiltrados ?? excep.rows}
            loading={loading} />
          <TableFooter>
            {excep.total > 10 && (
              <TableRow>
                <TablePagination
                  rowsPerPage={excep.rowsPerPage}
                  rowsPerPageOptions={[excep.rowsPerPage]}
                  count={excep.total}
                  page={excep.page}
                  onPageChange={handleChangePage}
                />
              </TableRow>
            )}
            </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TipificacionesTable;