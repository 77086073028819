import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import {
  genGuideNumber,
  getAssortedItems,
  getCarriers,
  searchAssortedItems,
} from "../../../utils/ventaMayoreo/surtidos/surtidos";
import { getCarrieService } from "../../../utils/ventaMayoreo/ordenesVenta/ordenesVenta";

const SurtidosDataTable = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [keyWord, setKeytoSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [carriers, setcarriers] = useState([]);
  const [carrier, setCarrier] = useState(1);
  const [servicesCarrier, setServicesCarrier] = useState([]);
  const [selectedService, setSelectedService] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [reloadPage, setReloadPage] = useState(0);
  const [carrierError, setCarrierError] = useState(false);
  const [selectedServiceError, setSelectedServiceError] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [estado, setEstado] = useState(0);

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleSelectionModelChange = (newSelectionModel) => {
    setSelectedRows(newSelectionModel);
  };

  const columns = [
    {
      field: "ordenVenta",
      headerName: "Orden venta",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: ({ row }) => row.orden_venta,
    },
    {
      field: "producto",
      headerName: "Producto",
      flex: 2,
      align: "center",
      headerAlign: "center",
      valueGetter: ({ row }) => row.nombre,
    },
    {
      field: "sku",
      headerName: "SKU",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: ({ row }) => row.sku,
    },
    {
      field: "cantidad",
      headerName: "Cantidad",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: ({ row }) => row.cantidad_disponible,
    },
  ];

  if (
    document.querySelector(
      ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
    )
  )
    document
      .querySelector(
        ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
      )
      .remove();

  useEffect(() => {
    let active = true;

    (async () => {
      let carriersD = await getCarriers();
      setcarriers(carriersD.data);

      let newRows = null;

      if (
        typeof keyWord === "undefined" ||
        keyWord === undefined ||
        keyWord.length === 0
      ) {
        setLoading(true);
        newRows = await getAssortedItems(page);
      } else if (keyWord.length >= 3) {
        setLoading(true);
        newRows = await searchAssortedItems(page, keyWord);
      } else active = false;

      if (!active) {
        return;
      }
      setTotal(newRows.total);
      setRows(newRows.data);
      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [page, keyWord, estado, reloadPage]);

  useEffect(() => {
    (async () => {
      let servicesC = await getCarrieService(carrier || 1);
      setServicesCarrier(servicesC.data);
      setSelectedService("");
    })();
  }, [carrier]);

  const handleChange = (event) => {
    setCarrier(event.target.value);
    if (carrierError) setCarrierError(false);
  };

  const handleChangeService = (event) => {
    setSelectedService(event.target.value);
    if (selectedServiceError) setSelectedServiceError(false);
  };

  const toggleReloadPage = () => {
    setReloadPage((prev) => (prev === 0 ? 1 : 0));
  };

  const saveGuide = async () => {
    let isValid = true;

    if (typeof carrier !== "number" || carrier <= 0) {
      setCarrierError(true);
      isValid = false;
    }

    if (typeof selectedService !== "number" || selectedService <= 0) {
      setSelectedServiceError(true);
      isValid = false;
      return;
    }

    if (selectedRows.length === 0) {
      setDialogOpen(true);
      return;
    }

    if (isValid) {
      let data = {
        productos: selectedRows,
        idCarrier: carrier,
        idServiceTipe: selectedService,
        idUser: user.data.awsid,
      };
      console.log(data);
      await genGuideNumber(data);
      setSelectedRows([]);
      toggleReloadPage();
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Grid container sx={{ mt: 2 }}>
          <Grid item md={6} sx={{ px: 2 }}>
            <Stack direction="row" spacing={1}>
              <FormControl fullWidth size="small" error={carrierError}>
                <InputLabel id="carrier-select-label">Carrier</InputLabel>
                <Select
                  labelId="carrier-select-label"
                  id="carrier-select"
                  value={carrier}
                  label="Carrier"
                  onChange={handleChange}
                  size="small"
                >
                  {carriers.map((carrier) => (
                    <MenuItem
                      key={carrier.id_tz_carriers}
                      value={carrier.id_tz_carriers}
                    >
                      {carrier.nombre}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth size="small" error={selectedServiceError}>
                <InputLabel id="service-select-label">Service</InputLabel>
                <Select
                  labelId="service-select-label"
                  id="service-select"
                  value={selectedService}
                  label="Service"
                  onChange={handleChangeService}
                  size="small"
                >
                  {servicesCarrier.map((service) => (
                    <MenuItem
                      key={service.id_tipo_servicio}
                      value={service.id_tipo_servicio}
                    >
                      {service.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Grid container sx={{ mb: 2, mt: 2, justifyContent: "end" }}>
          <Grid item md={4} sx={{ px: 2 }}>
            <Stack direction="row" spacing={1}>
              <TextField
                size="small"
                variant="outlined"
                fullWidth
                label={`Buscar`}
                value={keyWord}
                onChange={(e) => setKeytoSearch(e.target.value)}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box style={{ height: 635, width: "100%" }}>
        <DataGridPro
          rows={rows}
          columns={columns}
          pagination
          pageSize={10}
          rowsPerPageOptions={[10]}
          getRowId={(row) => row.id_requerimiento_producto}
          rowCount={total}
          paginationMode="server"
          checkboxSelection
          onPageChange={(newPage) => {
            setPage(newPage);
          }}
          onSelectionModelChange={handleSelectionModelChange}
          selectionModel={selectedRows}
          loading={loading}
          disableColumnMenu
          disableSelectionOnClick
          getDetailPanelHeight={() => "auto"}
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          variant="contained"
          color="info"
          sx={{ marginY: "15px", marginRight: "5px" }}
          onClick={() => saveGuide()}
        >
          Generar número de guía
        </Button>
      </Box>
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>{"Advertencia"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Selecciona al menos un producto antes de continuar.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            color="error"
            autoFocus
            variant="outlined"
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SurtidosDataTable;
