import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getSuppliers,
  getTypeValidations,
  getValidations,
  searchNewsProducts,
  validateProduct,
} from "../../../utils/ventaMayoreo/validaciones/validaciones";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  styled as styledMUI,
  tooltipClasses,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useEffect } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import styled from "styled-components";
import { useAuth } from "../../../context/AuthContext";

const dCantidadProveedor = [1, 3, 4, 5];
const dPrecio = [3, 4];
const dTiempoEntrega = [3, 4];
const dPorcentajeDevolucion = [3, 4];
const dDescuentoProveedor = [3, 4];

const BootstrapTooltip = styledMUI(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const StyledDataGrid = styled(DataGridPro)`
  & .MuiDataGrid-cell {
    padding: 0 !important;
    margin: 0 !important;
  }
`;

const RenderAsignarProveedorCell = (
  row,
  typeValidations,
  suppliers,
  reloadPageFn,
  user
) => {
  const navigate = useNavigate();

  const [errors, setErrors] = useState({});
  const [validacionValues, setValidacionValues] = useState({
    tipoValidacion: 1,
    precio: "",
    tiempoEntrega: "",
    porcentajeDevolucion: "",
    idSupplier: 0,
    descuentoProveedor: "",
    cantidadProveedor: row.cantidad || "",
  });
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [idProducto, setIdProducto] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    validateAllFields();
  }, [validacionValues.tipoValidacion]);

  const validateAllFields = () => {
    validateField("precio", validacionValues.precio);
    validateField("tiempoEntrega", validacionValues.tiempoEntrega);
    validateField(
      "porcentajeDevolucion",
      validacionValues.porcentajeDevolucion
    );
    validateField("descuentoProveedor", validacionValues.descuentoProveedor);
    validateField("idSupplier", validacionValues.idSupplier);
  };

  useEffect(() => {
    setValuesTypeV();
  }, [validacionValues.tipoValidacion]);

  useEffect(() => {
    let active = true;

    if (inputValue.length >= 4) {
      fetchOptions(inputValue);
    }

    return () => {
      active = false;
    };
  }, [inputValue]);

  useEffect(() => {
    console.log(errors);
    const areAllErrorsEmpty = Object.values(errors).every((error) => error === "");
    setIsButtonDisabled(!areAllErrorsEmpty);
  }, [errors]);

  const fetchOptions = async (inputValue) => {
    try {
      const newsProductsD = await searchNewsProducts(inputValue);
      setOptions(newsProductsD.data);
    } catch (error) {
      console.error("Error al buscar opciones", error);
      setOptions([]);
    }
  };

  const setValuesTypeV = () => {
    if (
      validacionValues.tipoValidacion == 3 ||
      validacionValues.tipoValidacion == 4
    ) {
      setValidacionValues((prevValues) => ({
        ...prevValues,
        precio: 0,
        tiempoEntrega: 0,
        porcentajeDevolucion: 0,
        cantidadProveedor: 0,
        descuentoProveedor: 0,
      }));
    }
    if (validacionValues.tipoValidacion == 1) {
      setValidacionValues((prevValues) => ({
        ...prevValues,
        precio: "",
        tiempoEntrega: "",
        porcentajeDevolucion: "",
        descuentoProveedor: "",
        cantidadProveedor: row.cantidad || "",
      }));
    }
  };

  const validateField = (name, value) => {
    if (
      validacionValues.tipoValidacion == 3 ||
      validacionValues.tipoValidacion == 4
    ) {
      setErrors({});
    }
    let errorMessage = "";
    if (
      validacionValues.tipoValidacion == 1 ||
      validacionValues.tipoValidacion == 2 ||
      validacionValues.tipoValidacion == 5
    ) {
      switch (name) {
        case "precio":
          if (!value || isNaN(value) || value <= 0) {
            errorMessage =
              "El precio no debe de estar vacío, debe ser un número y ser mayor a 0";
          }
          break;

        case "tiempoEntrega":
          if (!value || isNaN(value) || value <= 0) {
            errorMessage =
              "El tiempo de entrega no debe de estar vacío, debe ser un número y ser mayor a 0";
          }
          break;

        case "porcentajeDevolucion":
          if (!value || isNaN(value) || value < 0 || value > 100) {
            errorMessage =
              "El porcentaje de devolución no debe de estar vacío, debe ser un número, ser mayor o igual a 0 y menor o igual a 100!";
          }
          break;

        case "descuentoProveedor":
          if (!value || isNaN(value) || value < 0 || value > 100) {
            errorMessage =
              "El descuento del proveedor no debe de estar vacío, debe ser un número, ser mayor o igual a 0 y menor o igual a 100!";
          }
          break;

        case "cantidadProveedor":
          if (!value || isNaN(value)) {
            errorMessage = "Esate campo debe ser un número";
          }
          break;

        case "idSupplier":
          if (!value || isNaN(value) || value <= 0) {
            errorMessage = "Debes seleccionar un proveedor";
          }
          break;

        default:
          break;
      }

      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: errorMessage,
      }));
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValidacionValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    validateField(name, value);
  };

  const validarProducto = async (idRequerimiento, idRequerimientoProducto) => {
    let dataSend = {};
    if (validacionValues.tipoValidacion == 5) {
      dataSend = {
        idRequerimiento,
        idRequerimientoProducto,
        idProveedor: validacionValues.idSupplier,
        idCatValidacion: validacionValues.tipoValidacion,
        idProducto: idProducto,
        tiempoEntrega: parseInt(validacionValues.tiempoEntrega),
        precio: parseInt(validacionValues.precio),
        porcentajeDevolucion: parseInt(validacionValues.porcentajeDevolucion),
        cantidadDisponible: validacionValues.cantidadProveedor,
        descuento: validacionValues.descuentoProveedor,
        idUsuario: user.data.awsid,
      };
    } else {
      dataSend = {
        idRequerimiento,
        idRequerimientoProducto,
        idProveedor: validacionValues.idSupplier,
        idCatValidacion: validacionValues.tipoValidacion,
        idProducto: 0,
        tiempoEntrega: parseInt(validacionValues.tiempoEntrega),
        precio: parseInt(validacionValues.precio),
        porcentajeDevolucion: parseInt(validacionValues.porcentajeDevolucion),
        cantidadDisponible: validacionValues.cantidadProveedor,
        descuento: validacionValues.descuentoProveedor,
        idUsuario: user.data.awsid,
      };
    }
    await validateProduct(dataSend);
    reloadPageFn();
  };

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: "#3c8dbc",
          paddingY: "5px",
          h6: { color: "white" },
        }}
      >
        <Typography variant="h6" sx={{ paddingX: "4px" }}>
          Requerimiento: {row.numero_requerimiento}
        </Typography>
      </Box>
      <Grid container>
        <Grid item xs={6} sx={{ paddingX: "5px" }}>
          <FormControl fullWidth variant="outlined" size="small" margin="dense">
            <InputLabel id="demo-simple-select-outlined-label">
              Tipo Validacion
            </InputLabel>
            <Select
              name="tipoValidacion"
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={validacionValues.tipoValidacion}
              onChange={handleChange}
              label="Tipo Validacion"
            >
              {typeValidations.map((validation) => (
                <MenuItem
                  key={validation.id_cat_validacion}
                  value={validation.id_cat_validacion}
                >
                  {validation.nombre}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <BootstrapTooltip title={errors.precio || ""} placement="top" arrow>
            <TextField
              label="Precio"
              fullWidth
              margin="dense"
              size="small"
              name="precio"
              value={validacionValues.precio}
              onChange={handleChange}
              error={!!errors.precio}
              disabled={dPrecio.includes(validacionValues.tipoValidacion)}
            />
          </BootstrapTooltip>
          <BootstrapTooltip
            title={errors.tiempoEntrega || ""}
            placement="top"
            arrow
          >
            <TextField
              label="Tiempo de entrega"
              fullWidth
              margin="dense"
              size="small"
              name="tiempoEntrega"
              value={validacionValues.tiempoEntrega}
              onChange={handleChange}
              error={!!errors.tiempoEntrega}
              disabled={dTiempoEntrega.includes(
                validacionValues.tipoValidacion
              )}
            />
          </BootstrapTooltip>
          <BootstrapTooltip
            title={errors.porcentajeDevolucion || ""}
            placement="top"
            arrow
          >
            <TextField
              label="Porcentaje de devolución"
              fullWidth
              margin="dense"
              size="small"
              name="porcentajeDevolucion"
              value={validacionValues.porcentajeDevolucion}
              onChange={handleChange}
              error={!!errors.porcentajeDevolucion}
              disabled={dPorcentajeDevolucion.includes(
                validacionValues.tipoValidacion
              )}
            />
          </BootstrapTooltip>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginY: "10px",
              marginBottom: "20px",
            }}
          >
            <Box>
              <Button
                color="info"
                variant="contained"
                size="small"
                onClick={() =>
                  validarProducto(
                    row.id_requerimiento,
                    row.id_requerimiento_producto
                  )
                }
                disabled={isButtonDisabled}
              >
                Asignar proveedor
              </Button>
            </Box>
            <Box>
              <IconButton
                size="small"
                sx={{
                  backgroundColor: "#5cb85c",
                  borderRadius: 1,
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#4ca74a",
                  },
                }}
                onClick={() =>
                  navigate(
                    `detail/${row.id_requerimiento}/${row.id_requerimiento_producto}`
                  )
                }
              >
                <VisibilityIcon />
              </IconButton>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={6} sx={{ paddingX: "5px" }}>
          <FormControl fullWidth size="small" margin="dense">
            <Autocomplete
              id="supplier-autocomplete"
              options={suppliers}
              getOptionLabel={(option) => option.value}
              value={suppliers.find(
                (supplier) => supplier.id === validacionValues.idSupplier
              )}
              onChange={(event, newValue) => {
                handleChange({
                  target: {
                    name: "idSupplier",
                    value: newValue ? newValue.id : "",
                  },
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Proveedor"
                  variant="outlined"
                  error={!!errors.idSupplier}
                  helperText={errors.idSupplier}
                  size="small"
                />
              )}
            />
          </FormControl>
          <BootstrapTooltip
            title={errors.descuentoProveedor || ""}
            placement="top"
            arrow
          >
            <TextField
              label="Descuento del proveedor"
              fullWidth
              margin="dense"
              size="small"
              name="descuentoProveedor"
              value={validacionValues.descuentoProveedor}
              onChange={handleChange}
              error={!!errors.descuentoProveedor}
              disabled={dDescuentoProveedor.includes(
                validacionValues.tipoValidacion
              )}
            />
          </BootstrapTooltip>
          <BootstrapTooltip
            title={errors.cantidadProveedor || ""}
            placement="top"
            arrow
          >
            <TextField
              label="Cantidad del proveedor"
              fullWidth
              margin="dense"
              size="small"
              name="cantidadProveedor"
              value={validacionValues.cantidadProveedor}
              onChange={handleChange}
              error={!!errors.cantidadProveedor}
              disabled={dCantidadProveedor.includes(
                validacionValues.tipoValidacion
              )}
            />
          </BootstrapTooltip>
          {validacionValues.tipoValidacion == 5 ? (
            <Autocomplete
              id="productos-select"
              options={options}
              getOptionLabel={(option) => option.text}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              onChange={(event, newValue) => {
                setIdProducto(newValue ? newValue.id : "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Seleccione un producto"
                  variant="outlined"
                  fullWidth
                  size="small"
                />
              )}
            />
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
};

const renderInnerTable = (row) => {
  return (
    <Box
      sx={{
        alignItems: "flex-start !important",
        display: "flex",
        height: "100%",
        backgroundColor: "#3c8dbc",
        td: { color: "white" },
        width: "100%",
        paddingTop: "42px",
      }}
    >
      <Table
        size="small"
        sx={{ borderTop: "1px solid rgba(224, 224, 224, 1)" }}
      >
        <TableBody>
          <TableRow>
            <TableCell>Producto:</TableCell>
            <TableCell>{row.nombre}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>SKU:</TableCell>
            <TableCell>{row.sku}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Editorial:</TableCell>
            <TableCell>{row.editorial}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Cantidad solicitada:</TableCell>
            <TableCell>{row.cantidad}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

export const ValidacionesDataTable = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [keyWord, setKeytoSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [validations, setValidations] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [reloadPage, setReloadPage] = useState(0);

  const columns = [
    {
      field: "asignarProveedor",
      headerName: "Asignar proveedor",
      flex: 1.2,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) =>
        RenderAsignarProveedorCell(
          row,
          validations,
          suppliers,
          reloadPageFn,
          user
        ),
    },
    {
      field: "informacion",
      headerName: "Información",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => renderInnerTable(row),
    },
  ];

  if (
    document.querySelector(
      ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
    )
  )
    document
      .querySelector(
        ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
      )
      .remove();

  useEffect(() => {
    setLoading(true);
    let active = true;

    (async () => {
      let newRows = null;
      let tValitionsD = await getTypeValidations();
      setValidations(tValitionsD.data);
      let suppliersD = await getSuppliers();
      setSuppliers(suppliersD.data);

      if (
        typeof keyWord === "undefined" ||
        keyWord === undefined ||
        keyWord.length === 0
      ) {
        setLoading(true);
        newRows = await getValidations(page);
      } else if (keyWord.length >= 3) {
        setLoading(true);
        // newRows = await searchQuotes(page, keyWord);
      } else active = false;

      if (!active) {
        return;
      }
      setTotal(newRows.total);
      setRows(newRows.data);
      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [page, keyWord, reloadPage]);

  const reloadPageFn = () => {
    setReloadPage((prevState) => (prevState === 0 ? 1 : 0));
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Grid container sx={{ mb: 2, mt: 2 }}>
          <Grid item md={4} sx={{ px: 2 }}>
            <Stack direction="row" spacing={1}>
              <TextField
                size="small"
                variant="outlined"
                fullWidth
                label={`Buscar`}
                // value={keyWord}
                // onChange={(e) => setKeytoSearch(e.target.value)}
              />
            </Stack>
          </Grid>

          <Grid item md sx={{ px: 2, textAlign: "right" }}>
            <LoadingButton
              variant="contained"
              color="info"
              //   onClick={handleOpenModalCreate}
            >
              Excel
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
      <Box style={{ height: 1235, width: "100%" }}>
        <StyledDataGrid
          rows={rows}
          columns={columns}
          pagination
          pageSize={10}
          rowsPerPageOptions={[10]}
          getRowId={(row) => row.id_requerimiento_producto}
          rowCount={total}
          paginationMode="server"
          onPageChange={(newPage) => {
            setPage(newPage);
          }}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
          loading={loading}
          disableColumnMenu
          disableSelectionOnClick
          getRowHeight={() => "auto"}
          getDetailPanelHeight={() => "auto"}
        />
      </Box>
    </>
  );
};
