import { Button, Card, CardContent, LinearProgress, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import NuevoCasoDialog from "./NuevoCasoDialog";
import dayjs from "dayjs";
import "dayjs/locale/es";
import local from "dayjs/plugin/localizedFormat";
import relative from "dayjs/plugin/relativeTime";
import { motion } from "framer-motion";
import CerrarCasoDialog from "./CerrarCasoDialog";
import CrearAsesoriaDialog from "./CrearAsesoriaDialog";
import DetailSearcher from "./DetailSearcher";

dayjs.locale("es");
dayjs.extend(local);
dayjs.extend(relative);

const TicketById = () => {
  const params = useParams();
  const [details, setDetails] = useState(null);
  const [openCaseDialog, setOpenCaseDialog] = useState(false);
  const [openCloseDialog, setOpenCloseDialog] = useState(false);
  const [openAsesoriaDialog, setOpenAsesoriaDialog] = useState(false);

  const id = params.id;
  useEffect(() => {
    let isEffectCancelled = false;
    const getTicketDetails = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_BASEURL}/crm/tickets/details`, {
          params: {
            id,
          },
        });
        if (res.status === 200 && !isEffectCancelled) {
          setDetails(res.data[0]);
        }
      } catch (error) {
        console.error(error.message);
      }
    };

    getTicketDetails();

    return () => {
      isEffectCancelled = true;
    };
  }, [id]);

  return (
    <>
      <motion.div initial={{ y: 20 }} animate={{ y: 0 }} transition={{ duration: 0.5 }}>
        <Card sx={{ mb: 2 }}>
          {!details && <LinearProgress />}
          <CardContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h4" gutterBottom>
                Ticket #{id}
              </Typography>
              {details && (
                <Box>
                  <Button
                    variant="contained"
                    disableElevation
                    sx={{ ml: 1 }}
                    color="success"
                    onClick={() => setOpenCaseDialog(true)}
                  >
                    Crear caso
                  </Button>
                  <Button
                    variant="contained"
                    disableElevation
                    sx={{ ml: 1 }}
                    color="warning"
                    onClick={() => setOpenAsesoriaDialog(true)}
                  >
                    Asesoria
                  </Button>
                  <Button
                    variant="contained"
                    disableElevation
                    sx={{ ml: 1 }}
                    color="error"
                    onClick={() => setOpenCloseDialog(true)}
                  >
                    Cerrar
                  </Button>
                </Box>
              )}
            </Box>
            {details && (
              <Box sx={{ display: "flex", mt: 4 }}>
                <Box sx={{ flex: 1 }}>
                  <Typography variant="h6">Titulo</Typography>
                  <Typography gutterBottom>{details.title}</Typography>
                  <Typography variant="h6">Canal</Typography>
                  <Typography gutterBottom>{details.channel}</Typography>
                  <Typography variant="h6">Fecha</Typography>
                  <Typography gutterBottom>
                    {dayjs(details.date_register).format("DD MMM YYYY, hh:mm A")} (
                    {dayjs(details.date_register).fromNow()})
                  </Typography>
                  <Typography variant="h6">Estatus</Typography>
                  <Typography gutterBottom>{details.estatus}</Typography>
                  <Typography variant="h6">Contacto</Typography>
                  <Typography gutterBottom>{details.contact}</Typography>
                  <Typography variant="h6">Tomado por</Typography>
                  <Typography gutterBottom>{`${details.nombre} ${details.apellidoP}`}</Typography>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Typography variant="h6">Mensaje</Typography>
                  <TextField
                    multiline
                    variant="filled"
                    rows={14}
                    sx={{
                      width: "100%",
                    }}
                    value={details.description}
                    fullWidth
                  />
                </Box>
              </Box>
            )}
          </CardContent>
        </Card>
      </motion.div>

      {details && (
        <>
          <DetailSearcher details={details} />
          <NuevoCasoDialog details={details} open={openCaseDialog} setOpen={setOpenCaseDialog} />
          <CerrarCasoDialog details={details} open={openCloseDialog} setOpen={setOpenCloseDialog} />
          <CrearAsesoriaDialog details={details} open={openAsesoriaDialog} setOpen={setOpenAsesoriaDialog} />
        </>
      )}
    </>
  );
};

export default TicketById;
