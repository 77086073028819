import { Box, LinearProgress, Grid, List, ListItem, ListItemText } from "@mui/material";
import { useEffect, useState } from "react";
import { getTabOrderDetail } from "../../../../utils/cancelaciones/cancelaciones/cancelaciones";
import local from "dayjs/plugin/localizedFormat";
import relative from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";

dayjs.locale("es-mx");
dayjs.extend(local);
dayjs.extend(relative);
dayjs.extend(utc);

const DOTab = ({ idOrder }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [detalle, setDetalle] = useState(null);

    const getDetails = async () => {
        try {
            setIsLoading(true);
            const res = await getTabOrderDetail(idOrder);
            setDetalle(res);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getDetails();
    }, [idOrder]);

    return (
        <>
            <Box sx={{ mb: 2, ml: 2, mr: 2,display: 'flex', justifyContent: 'space-around' }}>
                {isLoading && <LinearProgress />}
                {detalle && (<>      
                    <Grid item md={3} >
                        <List>
                            <ListItem>
                                <ListItemText primary="Orden #:" secondary={detalle.data.increment_id}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Email:" secondary={detalle.data.email}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Teléfono:" secondary={detalle.data.telephone}/>
                            </ListItem>
                        </List>
                    </Grid>

                    <Grid item md={3}>
                        <List>
                            <ListItem>
                                <ListItemText primary="Método de pago:" secondary={detalle.data.method_name}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Fecha de registro:" secondary={dayjs.utc(detalle.data.created_at).format("DD MMM YYYY, hh:mm A")}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Fecha del pedido:" secondary={dayjs.utc(detalle.data.payment_date).format("DD MMM YYYY, hh:mm A")}/>
                            </ListItem>
                        </List>
                    </Grid>

                    <Grid item md={3}>
                        <List>
                            <ListItem>
                                <ListItemText primary="Estado:" secondary={detalle.data.state}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Ciudad:" secondary={detalle.data.city}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Código postal:" secondary={detalle.data.postal_code}/>
                            </ListItem>
                        </List>
                    </Grid>
                </> )}
            </Box>
        </>
    );
};

export default DOTab;