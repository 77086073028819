import { useParams } from "react-router-dom";
import DetallePedido from "./DetallePedido";
import DetalleTabs from "./DetalleTabs";

const Detalle = () => {
  const params = useParams();

  if (params.id) {
    return (
      <>
        <DetallePedido aclaracionId={params.id} />
        <DetalleTabs aclaracionId={params.id} />
      </>
    );
  }

  return null;
};

export default Detalle;
