import { Box, Grid, Button, Chip } from "@mui/material";
import { useEffect, useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { getDetailsException, } from "../../../utils/trazabilidad/pedidos/pedidos";

import local from "dayjs/plugin/localizedFormat";
import relative from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";

dayjs.locale("es-mx");
dayjs.extend(local);
dayjs.extend(relative);
dayjs.extend(utc);

const chipColors = {
    "Nueva": "#3c8dbc",
    "Resuelta": "#00a65a",
    "Cerrada": "#dd4b39",
    "Abierta": "#f39c12",
    "En proceso": "#777777",
    "Info": "#00c0ef"
};

const ExcepcionesTab = ({ id }) => {
    const [exceptions, setExceptions] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const getExceptions = async () => {
        try {
            setIsLoading(true);
            const res = await getDetailsException(id);
            setExceptions(res);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getExceptions();
    }, [id]);

    return (<>
        {exceptions &&
            <Box sx={{ p:2 }} component={Paper}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Tipificacion</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Fecha</TableCell>
                                <TableCell>Acción</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            <TableRow>
                                <TableCell>{exceptions.data.tipificacion ? exceptions.data.tipificacion : "Sin definir"}</TableCell>
                                <TableCell>{exceptions.data.status ? <Chip label={exceptions.data.status} size="small" sx={{ backgroundColor: chipColors[exceptions.data.status], color: "#fff", }} /> : "Sin Estatus"}</TableCell>
                                <TableCell>{exceptions.data.fecha ? dayjs(exceptions.data.fecha).format("DD MMM YYYY, hh:mm:ss A") : "Sin definir"}</TableCell>
                                <TableCell>
                                    <Button variant="contained" color="primary" disableElevation onClick={() => navigate(`/trazabilidad/excepciones/detail/${exceptions.data.id}`)}>
                                        <VisibilityIcon />
                                    </Button>
                                </TableCell>
                            </TableRow>

                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        }
    </>);
};

export default ExcepcionesTab;