import React from "react";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  styled,
} from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export const ContactTab = ({ contactDetail }) => {
  const { cotizacion, empresa, requerimiento } = contactDetail;

  return (
    <Box
      sx={{
        border: "1px solid #dedede",
        borderTop: "3px solid #3c8dbc",
        marginBottom: "25px",
        borderRadius: "5px",
      }}
    >
      <Box sx={{ borderBottom: "1px solid #dedede", paddingLeft: "10px", paddingY: "5px" }}>
        <Typography variant="h6" sx={{ fontSize: "1.2rem" }}>
          Información de contacto
        </Typography>
      </Box>
      <Box
        sx={{
          border: "1px solid #dedede",
          borderTop: "3px solid #00a65a",
          margin: "10px",
          marginBottom: "25px",
          borderRadius: "5px",
        }}
      >
        <Box sx={{ borderBottom: "1px solid #dedede" }}>
          <Typography
            variant="h6"
            sx={{ fontSize: "1.18rem", margin: "7px", marginLeft: "12px" }}
          >
            Datos
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <List sx={{ "& .MuiListItem-root": { paddingTop: 0 } }}>
              <ListItem>
                <ListItemText
                  primary="Contacto:"
                  secondary={
                    requerimiento.nombre_completo
                      ? requerimiento.nombre_completo
                      : "S/Nombre"
                  }
                  sx={{ span: { fontWeight: "500" } }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Correo electrónico:"
                  secondary={
                    requerimiento.email
                      ? requerimiento.email
                      : "S/Correo electrónico"
                  }
                  sx={{ span: { fontWeight: "500" } }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Teléfono:"
                  secondary={
                    requerimiento.telefono
                      ? requerimiento.telefono
                      : "S/Teléfono"
                  }
                  sx={{ span: { fontWeight: "500" } }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Celular:"
                  secondary={
                    requerimiento.celular ? requerimiento.celular : "S/Celular"
                  }
                  sx={{ span: { fontWeight: "500" } }}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={4}>
            <List sx={{ "& .MuiListItem-root": { paddingTop: 0 } }}>
              <ListItem>
                <ListItemText
                  primary="Razon social:"
                  secondary={
                    empresa.razon_social
                      ? empresa.razon_social
                      : "S/Razón social"
                  }
                  sx={{ span: { fontWeight: "500" } }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Rfc:"
                  secondary={empresa.rfc ? empresa.rfc : "S/Rfc"}
                  sx={{ span: { fontWeight: "500" } }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Calle:"
                  secondary={empresa.calle ? empresa.calle : "S/Calle"}
                  sx={{ span: { fontWeight: "500" } }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Numero Exterior:"
                  secondary={empresa.numeroExt ? empresa.numeroExt : "S/Número"}
                  sx={{ span: { fontWeight: "500" } }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Numero Interior:"
                  secondary={empresa.numeroInt ? empresa.numeroInt : "S/Número"}
                  sx={{ span: { fontWeight: "500" } }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Colonia:"
                  secondary={empresa.colonia ? empresa.colonia : "S/Colonia"}
                  sx={{ span: { fontWeight: "500" } }}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={4}>
            <List sx={{ "& .MuiListItem-root": { paddingTop: 0 } }}>
              <ListItem>
                <ListItemText
                  primary="Ciudad:"
                  secondary={empresa.ciudad ? empresa.ciudad : "S/Ciudad"}
                  sx={{ span: { fontWeight: "500" } }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Delegación:"
                  secondary={
                    empresa.delegacion ? empresa.delegacion : "S/Delegación"
                  }
                  sx={{ span: { fontWeight: "500" } }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Código postal:"
                  secondary={empresa.cp ? empresa.cp : "S/Código postal"}
                  sx={{ span: { fontWeight: "500" } }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Estado:"
                  secondary={empresa.estado ? empresa.estado : "S/Estado"}
                  sx={{ span: { fontWeight: "500" } }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="País:"
                  secondary={empresa.pais ? empresa.pais : "S/País"}
                  sx={{ span: { fontWeight: "500" } }}
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          border: "1px solid #dedede",
          borderTop: "3px solid #f39c12",
          margin: "10px",
          marginBottom: "25px",
          borderRadius: "5px",
        }}
      >
        <Box sx={{ borderBottom: "1px solid #dedede" }}>
          <Typography
            variant="h6"
            sx={{ fontSize: "1.18rem", margin: "7px", marginLeft: "12px" }}
          >
            Información de envío
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <List sx={{ "& .MuiListItem-root": { paddingTop: 0 } }}>
              <ListItem>
                <ListItemText
                  primary="Calle:"
                  secondary={
                    cotizacion.calle_envio ? cotizacion.calle_envio : "S/Calle"
                  }
                  sx={{ span: { fontWeight: "500" } }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Colonia:"
                  secondary={
                    cotizacion.colonia_envio
                      ? cotizacion.colonia_envio
                      : "S/Colonia"
                  }
                  sx={{ span: { fontWeight: "500" } }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Código postal:"
                  secondary={
                    cotizacion.cp_envio
                      ? cotizacion.cp_envio
                      : "S/Código postal"
                  }
                  sx={{ span: { fontWeight: "500" } }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Entre Calles:"
                  secondary={
                    cotizacion.entreCalles_envio
                      ? cotizacion.entreCalles_envio
                      : "S/Entre calles"
                  }
                  sx={{ span: { fontWeight: "500" } }}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={4}>
            <List sx={{ "& .MuiListItem-root": { paddingTop: 0 } }}>
              <ListItem>
                <ListItemText
                  primary="Número Exterior:"
                  secondary={
                    cotizacion.numeroExt_envio
                      ? cotizacion.numeroExt_envio
                      : "S/Número"
                  }
                  sx={{ span: { fontWeight: "500" } }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Delegación:"
                  secondary={
                    cotizacion.delegacion_envio
                      ? cotizacion.delegacion_envio
                      : "S/Delegación"
                  }
                  sx={{ span: { fontWeight: "500" } }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Estado:"
                  secondary={
                    cotizacion.estado_envio
                      ? cotizacion.estado_envio
                      : "S/Estado"
                  }
                  sx={{ span: { fontWeight: "500" } }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Fachada:"
                  secondary={
                    cotizacion.fachada_envio
                      ? cotizacion.fachada_envio
                      : "S/fachada"
                  }
                  sx={{ span: { fontWeight: "500" } }}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={4}>
            <List sx={{ "& .MuiListItem-root": { paddingTop: 0 } }}>
              <ListItem>
                <ListItemText
                  primary="Número Interior:"
                  secondary={
                    cotizacion.numeroInt_envio
                      ? cotizacion.numeroInt_envio
                      : "S/Número"
                  }
                  sx={{ span: { fontWeight: "500" } }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Ciudad:"
                  secondary={
                    cotizacion.ciudad_envio
                      ? cotizacion.ciudad_envio
                      : "S/Ciudad"
                  }
                  sx={{ span: { fontWeight: "500" } }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="País:"
                  secondary={
                    cotizacion.pais_envio ? cotizacion.pais_envio : "S/País"
                  }
                  sx={{ span: { fontWeight: "500" } }}
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          border: "1px solid #dedede",
          borderTop: "3px solid #00c0ef",
          margin: "10px",
          borderRadius: "5px",
        }}
      >
        <Box sx={{ borderBottom: "1px solid #dedede" }}>
          <Typography
            variant="h6"
            sx={{ fontSize: "1.18rem", margin: "7px", marginLeft: "12px" }}
          >
            Información de facturación
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <List sx={{ "& .MuiListItem-root": { paddingTop: 0 } }}>
              <ListItem>
                <ListItemText
                  primary="Razón social:"
                  secondary={
                    cotizacion.razon_social_facturacion
                      ? cotizacion.razon_social_facturacion
                      : "S/Razón social"
                  }
                  sx={{ span: { fontWeight: "500" } }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Calle:"
                  secondary={
                    cotizacion.calle_facturacion
                      ? cotizacion.calle_facturacion
                      : "S/Calle"
                  }
                  sx={{ span: { fontWeight: "500" } }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Colonia:"
                  secondary={
                    cotizacion.colonia_facturacion
                      ? cotizacion.colonia_facturacion
                      : "S/Colonia"
                  }
                  sx={{ span: { fontWeight: "500" } }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Código postal:"
                  secondary={
                    cotizacion.cp_facturacion
                      ? cotizacion.cp_facturacion
                      : "S/Código postal"
                  }
                  sx={{ span: { fontWeight: "500" } }}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={4}>
            <List sx={{ "& .MuiListItem-root": { paddingTop: 0 } }}>
              <ListItem>
                <ListItemText
                  primary="RFC:"
                  secondary={
                    cotizacion.rfc_facturacion
                      ? cotizacion.rfc_facturacion
                      : "S/RFC"
                  }
                  sx={{ span: { fontWeight: "500" } }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Número Exterior:"
                  secondary={
                    cotizacion.numeroExt_facturacion
                      ? cotizacion.numeroExt_facturacion
                      : "S/Número"
                  }
                  sx={{ span: { fontWeight: "500" } }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Delegación:"
                  secondary={
                    cotizacion.delegacion_facturacion
                      ? cotizacion.delegacion_facturacion
                      : "S/Delegación"
                  }
                  sx={{ span: { fontWeight: "500" } }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Estado:"
                  secondary={
                    cotizacion.estado_facturacion
                      ? cotizacion.estado_facturacion
                      : "S/Estado"
                  }
                  sx={{ span: { fontWeight: "500" } }}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={4}>
            <List sx={{ "& .MuiListItem-root": { paddingTop: 0 } }}>
              <ListItem>
                <ListItemText
                  primary="Número Interior:"
                  secondary={
                    cotizacion.numeroInt_facturacion
                      ? cotizacion.numeroInt_facturacion
                      : "S/Número"
                  }
                  sx={{ span: { fontWeight: "500" } }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Ciudad:"
                  secondary={
                    cotizacion.ciudad_facturacion
                      ? cotizacion.ciudad_facturacion
                      : "S/Ciudad"
                  }
                  sx={{ span: { fontWeight: "500" } }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="País:"
                  secondary={
                    cotizacion.pais_facturacion
                      ? cotizacion.pais_facturacion
                      : "S/País"
                  }
                  sx={{ span: { fontWeight: "500" } }}
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
