import { Box, Card, Tab, Tabs, Typography, } from "@mui/material";
import { useTabs } from "../../../context/TabsContext";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import { profiles } from "../../../utils/trazabilidad/profilesTrazabilidad";
import BackordersDataTable from "./BackordersDataTable";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import DescriptionIcon from '@mui/icons-material/Description';
import HistoryIcon from '@mui/icons-material/History';
import BreadCrumb from "../../general/BreadCrumb";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";

const itemsBreadCrumbs = [
  {
    label: 'Trazabilidad',
    icon: <ContentPasteSearchIcon sx={{ mr: 0.5 }} fontSize="small"/>,
    link: '/trazabilidad/inicio'
  },
  { label: 'Backorders', }
];

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other} >
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  );
};

const Backorders = () => {
  const { user } = useAuth();
  const { crmTab, setCrmTab } = useTabs();
  const navigate = useNavigate();
  const handleTabChange = (event, value) => {
    setCrmTab(value);
  };

  useEffect(() => {

    if(!profiles.backorders.includes(user.role.idProfile)){
      navigate("/dashboard", { replace: true });
    }
    
  }, [user, navigate]);

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", }} >
        <Typography variant="h4" sx={{ my: 2 }}>Backorders</Typography>
        <Box><BreadCrumb items={itemsBreadCrumbs} /></Box>
      </Box>

      <motion.div initial={{ y: 20 }} animate={{ y: 0 }} transition={{ duration: 0.5 }}>        
        <Card sx={{mb:4}}>          
          <Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={crmTab} onChange={handleTabChange} >
                <Tab icon={<FormatListBulletedIcon />} iconPosition="start" label="Pendientes por liberar" />
                <Tab icon={<DescriptionIcon />} iconPosition="start" label="Cross Doc" />
                <Tab icon={<HistoryIcon />} iconPosition="start" label="Histórico" />
              </Tabs>
            </Box>
            <TabPanel value={crmTab} index={0}><BackordersDataTable typeP={0} /></TabPanel>
            <TabPanel value={crmTab} index={1}><BackordersDataTable typeP={1} /></TabPanel>
            <TabPanel value={crmTab} index={2}><BackordersDataTable typeP={2} /></TabPanel>
          </Box>
        </Card>
      </motion.div>
    </>
  )
}

export default Backorders;