import { Box, Tabs, Tab, Select, FormControl, MenuItem } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useTabs } from "../../../../context/TabsContext";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import HomeIcon from "@mui/icons-material/Home";
import EmailIcon from "@mui/icons-material/Email";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PaymentIcon from "@mui/icons-material/Payment";
import GuiasTabs from "./GuiasTab";
import DETab from "./DETab";
import MensajesTab from "./MensajesTab";
import ReembolsoTab from "./ReembolsoTab";
import SCTab from "./SCTab";
import { getBackOrderItems } from "../../../../utils/trazabilidad/backorders/backorders";

const statusLabel = {
  1: "Nuevo",
  2: "En Proceso",
  3: "Sin Stock",
  4: "En Espera de Reembolso",
  5: "Liberado",
  6: "Sin Stock",
  7: "Reembolso",
  8: "Store Credit",
  9: "Agotado",
  10: "En espera de datos",
  11: "Finalizado",
  12: "En espera de Store Credit",
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  );
};

const IndexTabs = ({ boId, detalle }) => {
  const { crmTab, setCrmTab } = useTabs();
  const [articulos, setArticulos] = useState([]);
  const [statusArt, setStatusArt] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isRem, setIsRem] = useState(false);
  const [isSC, setIsSC] = useState(false);
  const [arRem, setArRem] = useState([]);
  const [arSC, setArSC] = useState([]);

  const handleTabChange = (event, value) => {
    setCrmTab(value);
  };

  function get_rem_items(arre) {
    return arre.filter((elem) => elem.id_item_status === 7);
  }

  function get_sc_items(arre) {
    return arre.filter((elem) => elem.id_item_status === 8);
  }

  const handleChangeStatus = (event) => {
    let item = event.target.name;
    let value = event.target.value;
    let aux = {
      rem: [],
      sc: [],
    };
    if (value === 7) {
      let a = arSC.filter((elem) => elem !== item);
      setArSC(a);
      setArRem([...arRem, item]);
      aux.sc = a;
      aux.rem = [...arRem, item];
    } else if (value === 8) {
      let b = arRem.filter((elem) => elem !== item);
      setArRem(b);
      setArSC([...arSC, item]);
      aux.rem = b;
      aux.sc = [...arSC, item];
    } else {
      let a = arRem.filter((elem) => elem !== item);
      let b = arSC.filter((elem) => elem !== item);
      setArRem(a);
      setArSC(b);
      aux.rem = a;
      aux.sc = b;
    }
    let saux = statusArt.filter((element) => element.id !== item.id_item_bo);
    saux.push({
      id: item.id_item_bo,
      value: value,
    });
    setStatusArt(saux);
    handleCallback(aux);
  };

  const handleCallback = (childData) => {
    if (childData.rem.length > 0) setIsRem(true);
    else setIsRem(false);
    if (childData.sc.length > 0) setIsSC(true);
    else setIsSC(false);
  };

  function isSelect(row) {
    if (row.TRR_id_refund !== null && row.TRR_id_refund > 0) return false;
    else if (row.TRSC_id_storecredit !== null && row.TRSC_id_storecredit > 0)
      return false;
    else return true;
  }

  function getActualValues(row) {
    let aux = statusArt.find((element) => element.id === row.id_item_bo);
    if (aux) {
      return aux.value;
    }
    return 6;
  }

  const columns = [
    {
      field: "joinSku",
      headerName: "SKU",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    { field: "joinProducto", headerName: "Nombre Producto", flex: 2 },
    {
      field: "joinCant_pedida",
      headerName: "Cant. pedida",
      align: "center",
      headerAlign: "center",
      flex: 0.6,
    },
    {
      field: "joinCant_surtida",
      headerName: "Cant. surtida",
      align: "center",
      headerAlign: "center",
      flex: 0.6,
    },
    {
      field: "joinCant_faltante",
      headerName: "Cant. faltante",
      align: "center",
      headerAlign: "center",
      flex: 0.6,
    },
    {
      field: "price",
      headerName: "Precio",
      align: "center",
      headerAlign: "center",
      flex: 1,
      valueGetter: ({ row }) => "$ " + row.price,
    },
    {
      field: "id_item_status",
      headerName: "Status",
      align: "center",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => (
        <>
          {isSelect(params.row) && (
            <FormControl variant="standard" fullWidth>
              <Select
                name={params.row}
                defaultValue={getActualValues(params.row)}
                onChange={handleChangeStatus}
              >
                <MenuItem value={6}>Sin Stock</MenuItem>
                <MenuItem value={7}>Reembolso</MenuItem>
                <MenuItem value={8}>Store Credit</MenuItem>
              </Select>
            </FormControl>
          )}

          {!isSelect(params.row) ? statusLabel[params.row.id_item_status] : ""}
        </>
      ),
    },
    {
      field: "joinStock",
      headerName: "Disponibilidad",
      align: "center",
      headerAlign: "center",
      flex: 1,
      valueGetter: ({ row }) => (row.joinStock ? "" : "Descontinuado"),
    },
  ];

  useEffect(() => {
    const getArticulosBO = async () => {
      try {
        setIsLoading(true);
        let res = await getBackOrderItems(boId);
        if (!Array.isArray(res)) res = [res];
        setArticulos(res);
        let aux = [];
        res.forEach((element) => {
          let aux2 = {
            id: element.id_item_bo,
            value: 6,
          };
          aux.push(aux2);
        });
        setStatusArt(aux);
        let rem_i = get_rem_items(res);
        if(rem_i.length > 0){
          setArRem(rem_i);
          setIsRem(true);
        }
        let sc_i = get_sc_items(res);
        if(sc_i.length > 0){
          setArSC(sc_i);
          setIsSC(true);
        }

      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    getArticulosBO();
  }, [boId]);
  console.log(detalle);
  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={crmTab} onChange={handleTabChange}>
          <Tab
            icon={<ShoppingCartIcon />}
            iconPosition="start"
            label="Artículos"
          />
          <Tab
            icon={<HomeIcon />}
            iconPosition="start"
            label="Datos de envío"
          />
          <Tab icon={<EmailIcon />} iconPosition="start" label="Mensajes" />
          <Tab
            icon={<LocalShippingIcon />}
            iconPosition="start"
            label="Guías"
            disabled={detalle.guia === null}
          />
          <Tab
            icon={<AttachMoneyIcon />}
            iconPosition="start"
            label="Reembolso"
            disabled={!isRem}
          />
          <Tab
            icon={<PaymentIcon />}
            iconPosition="start"
            label="Store Credit"
            disabled={!isSC}
          />
        </Tabs>
      </Box>
      <TabPanel value={crmTab} index={0}>
        <Box sx={{ mb: 2, mx: 2 }}>
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                autoHeight
                rows={articulos}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                getRowId={(row) => row.id_item_bo}
                loading={isLoading}
                disableColumnMenu
                disableSelectionOnClick
              />
            </div>
          </div>
        </Box>
      </TabPanel>
      <TabPanel value={crmTab} index={1}>
        <DETab boId={boId} detalle={detalle} />
      </TabPanel>
      <TabPanel value={crmTab} index={2}>
        <MensajesTab boId={boId} detalle={detalle} />
      </TabPanel>
      <TabPanel value={crmTab} index={3}>
        <GuiasTabs boId={boId} detalle={detalle} />
      </TabPanel>
      <TabPanel value={crmTab} index={4}>
        <ReembolsoTab boId={boId} detalle={detalle} articulos={arRem} />
      </TabPanel>
      <TabPanel value={crmTab} index={5}>
        <SCTab boId={boId} detalle={detalle} articulos={arSC} />
      </TabPanel>
    </>
  );
};

export default IndexTabs;
