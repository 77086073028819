
import Routess from "./routes/routes";

const App = () => {

    return (
        <>
            <Routess />
        </>
    )
};

export default App;