import { Box, Grid, Table, TableBody, TableCell, TableContainer, Typography , TableRow, CircularProgress, IconButton } from '@mui/material';
import { useEffect, useState, Fragment } from "react";
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import DoneIcon from '@mui/icons-material/Done';
import DateInTable from '../../../general/DateInTable';

import { getVerifMovements } from '../../../../utils/trazabilidad/verificaciones/verificaciones';

const chipIcons = {
    "44": <AddIcon />,
    "45": <DoneIcon />,
    "46": <SaveIcon />,
    "47": <SaveIcon />,
};

const chipColors = {
    "44": "#0073b7",
    "45": "#0073b7",
    "46": "#0073b7",
    "47": "#0073b7",
};

const MovimientosTab = ({ verifId }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [detalle, setDetalle] = useState(null);

    useEffect(() => {
        const getMovements = async () => {
            try {
                setIsLoading(true);
                const res = await getVerifMovements(verifId);
                setDetalle(res);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };
        getMovements();
    }, [verifId]);

    return (
        <>            
            <Box sx={{mb: 2, ml: 2, mr: 2}}>
                {isLoading && <CircularProgress />}
                <Grid container sx={{mb: 2}}>
                    {detalle && (
                    <Grid item md={12}>
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    {detalle.map((row) => (
                                    <TableRow key={row.id_ver_moves}>
                                        <TableCell sx={{ width: '5%'}}>
                                            <IconButton disableFocusRipple disableRipple sx={{backgroundColor: chipColors[row.id_move], borderRadius: 0, color: '#fff'}} >{chipIcons[row.id_move]}</IconButton>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="p" sx={{fontWeight: 700}}>{row.user_nombre} </Typography>
                                            <Typography variant="p">{row.descripcion}</Typography>
                                        </TableCell>
                                        <TableCell><DateInTable dateString={row.date} /></TableCell>
                                    </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    )}
                </Grid>
            </Box>
        </>
    );
};

export default MovimientosTab;