import { Box, Button, List, ListItemText, Typography, Grid, LinearProgress } from "@mui/material";
import { useEffect, useState, Fragment } from "react";
import { getTabEvidences } from "../../../../utils/cancelaciones/cancelaciones/cancelaciones";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import local from "dayjs/plugin/localizedFormat";
import relative from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";

dayjs.locale("es-mx");
dayjs.extend(local);
dayjs.extend(relative);
dayjs.extend(utc);

const EvidencesTab = ({ id }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [detalle, setDetalle] = useState(null);
    
    const nombreCompleto = (nombres, apellidos) => {
        const usuario = [];
        if (nombres) {
            usuario.push(nombres + " ");
        }
        if (apellidos) {
            usuario.push(apellidos + " ");
        }
        return (
            usuario
        );
    };

    const getEvidences = async () => {
        try {
            setIsLoading(true);
            const res = await getTabEvidences(id);
            setDetalle(res);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDownload = (base64, name) => {
        console.log(base64.substring(0, 4));
        if (base64.substring(0, 4) === 'http') {
            const fileUrl = base64;
            const link = document.createElement('a');
            link.href = fileUrl;
            link.download = name;
            link.click();
        } else {
            const linkSource = `data:application/pdf;base64,${base64}`;
            const downloadLink = document.createElement("a");
            downloadLink.href = linkSource;
            downloadLink.download = name;
            downloadLink.click();
        }
    };

    useEffect(() => {
        getEvidences();
    }, [id]);

    return (<>
        <Box sx={{ mb: 2, ml: 2, mr: 2 }}>
            {isLoading && <LinearProgress />}
            <Grid container sx={{ mt: 2, mb: 2 }}>
                {detalle && (<>
                    {detalle.data.map(({ name, firstname_user, lastname_user, size, creation_date, base64 }) => (<>
                        <Box sx={{ width: 'auto', height: 'auto', backgroundColor: "#ffff", boxShadow: 2, border: 1, borderRadius: 2, p: 2 }}>
                            <List >
                                <ListItemText primary={name} />
                                <ListItemText primary="Usuario:" secondary={nombreCompleto(firstname_user, lastname_user)} />
                                <ListItemText primary="Peso:" secondary={size} />
                                <Typography variant="caption">
                                    {dayjs.utc(creation_date).format("DD MMM YYYY, hh:mm A")}
                                </Typography>
                            </List>
                            <Button variant="contained" startIcon={<FileDownloadIcon />} onClick={() => handleDownload(base64, name)}>Descargar PDF</Button>
                        </Box>
                    </>
                    ))}
                </>)}
            </Grid>
        </Box>
    </>);
};

export default EvidencesTab;