import { Box, Grid, TextField, InputLabel, Select, FormControl, MenuItem, CircularProgress, Typography, Alert, Card, CardContent, Stack, ListSubheader, List, ListItem, ListItemText } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../context/AuthContext";
import Toast from "../../../general/Toast";
import { useEffect, useState } from "react";
import { profilesAclaraciones } from "../../../../utils/trazabilidad/aclaraciones/profilesAclaraciones";
import { saveClarificationCC, updateClarificationCC, saveClarificationKey } from "../../../../utils/trazabilidad/aclaraciones/aclaraciones";

const ClavesCuotasTab = ({ aId, detalle, tipo }) => {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [clave, setClave] = useState("");
  const [comentario, setComentario] = useState("");
  const [opena, setOpenA] = useState(null);
  const [confalert, setConfAlert] = useState({
    severidad: "success",
    mensaje: "",
  });

  const handleOpenA = (severidad, mensaje) => {
    setConfAlert({ severidad: severidad, mensaje: mensaje });
    setOpenA(true);
  };

  const handleCloseA = () => {
    setOpenA(false);
  };

  const handleGenerateCCs = async () => {
    try {
      setIsLoading(true);
      let resp = await saveClarificationCC(aId, user.data.awsid, clave, comentario);
      if(resp){
        let aux_resp = await saveClarificationKey(aId, user.data.awsid, 10);
        handleOpenA("success", "Información guardada con éxito");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
      else handleOpenA("error", "La información no pudo ser guardada, intente de nuevo más tarde");
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGenerateResolution = async () => {
    try {
      setIsLoading(true);
      let resp = await updateClarificationCC(aId, user.data.awsid, comentario);
      if(resp){
        let aux_resp = await saveClarificationKey(aId, user.data.awsid, 5);
        handleOpenA("success", "Información guardada con éxito");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
      else handleOpenA("error", "La información no pudo ser guardada, intente de nuevo más tarde");
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
  <>
    <Box sx={{ mb: 2, ml: 2, mr: 2 }}>

    {( (detalle.status_acl === 1 && detalle.id_tipificacion_ac === 10 && profilesAclaraciones.editarClave.includes( user.role.idProfile )) || (detalle.status_acl === 1 && detalle.id_tipificacion_ac === 11) ) && (
      <Grid container sx={{ my: 2 }}>
        <Grid item md={4}></Grid>
        <Grid item md={4}>
          <Alert variant="filled" severity="info" icon={false}>
            <Typography variant="p"><strong>Información.</strong></Typography>
          </Alert>
          <Card>
            <CardContent>
              <TextField fullWidth sx={{ mb: 2 }} id="clave" label="Ingresar clave" value={clave} onChange={(e) => setClave(e.target.value)} />
              <TextField type="text" multiline rows={4} fullWidth sx={{mb:2}} id="comentario" label="Ingresar comentario" value={comentario} onChange={(e) => setComentario(e.target.value)} />
              <LoadingButton sx={{ mt: 2 }} startIcon={<SaveIcon />} loading={isLoading} fullWidth size="medium" variant="contained" color="success" onClick={handleGenerateCCs} disabled={!clave || !comentario} >
                Guardar
              </LoadingButton>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={4}></Grid>
      </Grid>
    )}

    {( (detalle.status_acl === 10 && detalle.id_tipificacion_ac === 10 && profilesAclaraciones.cerraResolucion.includes( user.role.idProfile )) ) && (
      <Grid container sx={{ my: 2 }}>
        <Grid item md={4}></Grid>
        <Grid item md={4}>
          <Alert variant="filled" severity="info" icon={false}>
            <Typography variant="p"><strong>Resolución.</strong></Typography>
          </Alert>
          <Card>
            <CardContent>
              <TextField type="text" multiline rows={4} fullWidth sx={{mb:2}} id="comentario" label="Ingresar resolución" value={comentario} onChange={(e) => setComentario(e.target.value)} />
              <LoadingButton sx={{ mt: 2 }} startIcon={<SaveIcon />} loading={isLoading} fullWidth size="medium" variant="contained" color="success" onClick={handleGenerateResolution} disabled={!comentario} >
                Guardar
              </LoadingButton>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={4}></Grid>
      </Grid>
    )}

    {(detalle.status_acl === 5 || detalle.status_acl === 10 || detalle.status_acl === 11 || detalle.status_acl === 4 || detalle.status_acl === 9) && (
      <Grid container sx={{ my: 2 }}>
        <Grid item md={5}>
          <Alert variant="filled" severity="info" icon={false}>
            <Typography variant="p">
              <strong>Información registrada</strong>
            </Typography>
          </Alert>
          <Card>
            <CardContent>
              <Typography variant="p" component="div">
                Clave: <strong>{detalle.vclave}</strong>
              </Typography>
              <Typography variant="p" component="div">
                Comentarios: <strong>{detalle.vincidence}</strong>
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {(profilesAclaraciones.cerraResolucion.includes( user.role.idProfile ) && detalle.id_tipificacion_ac === 11 && (detalle.status_acl === 4 || detalle.status_acl === 5 || detalle.status_acl === 9)) && (
          <Grid item md>
            {detalle.vis_authorized && (
              <Alert variant="filled" severity="success">
                <Typography variant="p" color="white"><strong>Autorizado</strong></Typography>
              </Alert>
            )}

            {detalle.vis_liberated && (
              <Alert variant="filled" severity="success">
                <Typography variant="p" color="white"><strong>Liberado</strong></Typography>
              </Alert>
            )}
          </Grid>
        )}
      </Grid>
    )}

    </Box>
    <Toast open={opena} message={confalert.mensaje} type={confalert.severidad} onClose={handleCloseA} />
  </>
  );
};

export default ClavesCuotasTab;