import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { extension } from "mime-types";
import { nanoid } from "nanoid";
import { storage } from "./config";

export const uploadFileAndGetUrl = async (file, path) => {
  try {
    const fid = nanoid();
    const fref = ref(storage, `${path}/${fid}.${extension(file.type)}`);

    await uploadBytes(fref, file);
    const url = await getDownloadURL(fref);
    return url;
  } catch (error) {
    console.error(error);
  }

  return null;
};

export const uploadMultipleFilesAndGetUrls = async (files, path) => {
  const urls = [];
  try {
    for (let file of files) {
      const url = await uploadFileAndGetUrl(file, path);
      urls.push(url);
    }
  } catch (error) {
    console.error(error);
  }

  return urls;
};
