import { Box, CircularProgress, Typography, Tab } from "@mui/material";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import RepeatIcon from '@mui/icons-material/Repeat';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import PasswordIcon from '@mui/icons-material/Password';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CancelIcon from '@mui/icons-material/Cancel';
import { useAuth } from "../../../../context/AuthContext";
import { useEffect, useState } from "react";
import { profilesAclaraciones, isETabDatosEnvio, isETabAclarPago, isETabGuias, isETabReembolso, isETabRefacturacion, isETabClavesCuotas, isETabCancelClaves } from "../../../../utils/trazabilidad/aclaraciones/profilesAclaraciones";

import ArticulosTab from "./ArticulosTab";
import MovimientosTab from "./MovimentosTab";
import MensajesTab from "./MensajesTab";
import DatosEnvio from "./DatosEnvioTab";
import ReembolsoTab from "./ReembolsoTab";
import RefacturacionTab from "./RefacturacionTab";
import EvidenciasTab from "./EvidenciasTab";
import ClavesCuotasTab from "./ClavesCuotasTab";
import GuiasTab from "./GuiasTab";

const IndexTabs = ({ aId, detalle }) => {
    const { user } = useAuth();
    const [tabValue, setTabValue] = useState('1');
    const [isLoading, setIsLoading] = useState(false);
    const [isVisible, setIsVisible] = useState({
        articulos: true,
        mensajes: true,
        movimientos: true,
        archivos: true,
        datos: false,
        guias: false,
        reembolso: false,
        refacturacion: false,
        evidencias: false,
        claves: false,
        cancelacion: false
    });

    const handleTabChange = (event, value) => {
        setTabValue(value);
    };

    useEffect(() => {
        const checkTabs = async () => {
            try {
                setIsLoading(true);
                setIsVisible((prevalue) => {
                    return {
                        ...prevalue,
                        "datos": isETabDatosEnvio(detalle.id_tipificacion_ac, user.role.idProfile, detalle.status_acl_id, detalle),
                        "guias": isETabGuias(detalle.id_tipificacion_ac, user.role.idProfile, detalle.status_acl_id, detalle),
                        "reembolso": isETabReembolso(detalle.id_tipificacion_ac, user.role.idProfile, detalle.status_acl_id, detalle),
                        "refacturacion": isETabRefacturacion(detalle.id_tipificacion_ac, user.role.idProfile, detalle.status_acl_id, detalle),
                        "evidencias": isETabAclarPago(detalle.id_tipificacion_ac, user.role.idProfile, detalle.status_acl_id, detalle),
                        "claves": isETabClavesCuotas(detalle.id_tipificacion_ac, user.role.idProfile, detalle.status_acl_id, detalle),
                        "cancelacion": isETabCancelClaves(detalle.id_tipificacion_ac, user.role.idProfile, detalle.status_acl_id, detalle)
                    }
                });
                             
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };
    
        checkTabs();
    }, [aId, detalle]);

    return (
        <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleTabChange}>
                    {isVisible.articulos && <Tab icon={<ShoppingCartIcon />} iconPosition="start" label="Artículos" value="1" />}
                    {isVisible.mensajes && <Tab icon={<EmailIcon />} iconPosition="start" label="Mensajes" value="2" />}
                    {isVisible.datos && <Tab icon={<HomeIcon />} iconPosition="start" label="Datos de envío" value="3" />}
                    {isVisible.guias && <Tab icon={<LocalShippingIcon />} iconPosition="start" label="Guías" value="4" />}
                    {isVisible.reembolso && <Tab icon={<AttachMoneyIcon />} iconPosition="start" label="Reembolso" value="5" />}
                    {isVisible.refacturacion && <Tab icon={<RepeatIcon />} iconPosition="start" label="Refacturación" value="6" />}
                    {isVisible.evidencias && <Tab icon={<FolderOpenIcon />} iconPosition="start" label="Evidencias" value="7" />}
                    {isVisible.claves && <Tab icon={<PasswordIcon />} iconPosition="start" label="Claves y Cuotas" value="8" />}
                    {isVisible.cancelacion && <Tab icon={<CancelIcon />} iconPosition="start" label="Cancelaciones de Claves" value="9" />}
                    {isVisible.movimientos && <Tab icon={<FormatListBulletedIcon />} iconPosition="start" label="Movimientos" value="10" />}
                    {isVisible.archivos && <Tab icon={<AttachFileIcon />} iconPosition="start" label="Archivos" value="11" />}
                </TabList>
            </Box>
            {isLoading && (<Box sx={{ml:2, mt:2, mr:2, mb:2}}> <Typography variant="p"> Cargando ...</Typography> <CircularProgress /> </Box>)}

            {isVisible.articulos && <TabPanel value="1"><ArticulosTab aId={aId} detalle={detalle} /></TabPanel>}
            {isVisible.mensajes && <TabPanel value="2"><MensajesTab aId={aId} detalle={detalle} /></TabPanel>}
            {isVisible.datos && <TabPanel value="3"><DatosEnvio aId={aId} detalle={detalle} /></TabPanel>}
            {isVisible.guias && <TabPanel value="4"><GuiasTab aId={aId} detalle={detalle} /></TabPanel>}
            {isVisible.reembolso && <TabPanel value="5"><ReembolsoTab aId={aId} detalle={detalle} /></TabPanel>}
            {isVisible.refacturacion && <TabPanel value="6"><RefacturacionTab aId={aId} detalle={detalle} /></TabPanel>}
            {isVisible.evidencias && <TabPanel value="7"><EvidenciasTab aId={aId} detalle={detalle} /></TabPanel>}
            {isVisible.claves && <TabPanel value="8"><ClavesCuotasTab aId={aId} detalle={detalle} tipo={1} /></TabPanel>}
            {isVisible.cancelacion && <TabPanel value="9"><ClavesCuotasTab aId={aId} detalle={detalle} tipo={2} /></TabPanel>}
            {isVisible.movimientos && <TabPanel value="10"><MovimientosTab aId={aId} /></TabPanel>}
            {isVisible.archivos && <TabPanel value="11">No se encontrarón archivos para esta aclaración</TabPanel>}

        </TabContext>
    );
};

export default IndexTabs;