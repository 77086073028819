import { Card, Typography, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import img from "../assets/dreamer.svg";

const Reload = () => {
  return (
    <Card sx={{ py: 8, display: "flex", justifyContent: "space-evenly" }}>
      <img
        src={img}
        alt="404 Page not found"
        width="500px"
      />
      <Box
        sx={{
          color: "#041C32",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box textAlign="center">          
          <CircularProgress size={100} sx={{mb: 2}} />
          <Typography variant="h1" sx={{ fontWeight: "bold" }}>
            Cargando...
          </Typography>          
        </Box>
      </Box>
    </Card>
  );
};

export default Reload;
