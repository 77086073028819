import axios from "axios";

/**
 * GET
**/
const getCoordinatorsIndex = async () => {
  const URL = `${process.env.REACT_APP_BASEURL}/vales/coordinadores/all/new?page=All`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getSchools = async () => {
  const URL = `${process.env.REACT_APP_BASEURL}/vales/coordinadores/all/schools`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getSchoolsList = async (idUser) => {
  const URL = `${process.env.REACT_APP_BASEURL}/vales/coordinadores/all/listadocolegio?idUser=${idUser}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getCoordinator = async (idUser) => {
  const URL = `${process.env.REACT_APP_BASEURL}/vales/coordinadores/all/getcoordinador?idUser=${idUser}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

/**
 * POST
**/

const addCoordinator = async ( details ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/vales/coordinadores/all/createuser`;
  try {
      const res = await axios.post(URL, details );
      return res.data?.success ? true : false;
  } catch (error) {
      console.error("addCoordinator", error);
  }
};

const deleteCoordinator = async ( details ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/vales/coordinadores/all/deleteuser`;
  try {
      const res = await axios.post(URL, details );
      return res.data?.success ? true : false;
  } catch (error) {
      console.error("deleteCoordinator", error);
  }
};

const updateStatusCoord = async ( details ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/vales/coordinadores/all/updatestatuscoord`;
  try {
      const res = await axios.post(URL, details );
      return res.data?.success ? true : false;
  } catch (error) {
      console.error("updateStatusCoord", error);
  }
};

const addSchool = async ( details ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/vales/coordinadores/all/addschol`;
  try {
      const res = await axios.post(URL, details );
      return res.data?.success ? true : false;
  } catch (error) {
      console.error("addSchool", error);
  }
};

const deleteSchool = async ( details ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/vales/coordinadores/all/deleteschool`;
  try {
      const res = await axios.post(URL, details );
      return res.data?.success ? true : false;
  } catch (error) {
      console.error("deleteSchool", error);
  }
};

const updateCoordinator = async ( details ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/vales/coordinadores/all/updatecoordinador`;
  try {
      const res = await axios.post(URL, details );
      return res.data?.success ? true : false;
  } catch (error) {
      console.error("updateCoordinator", error);
  }
};

export {
    getCoordinatorsIndex,
    addCoordinator,
    deleteCoordinator,
    updateStatusCoord,
    getSchools,
    getSchoolsList,
    addSchool,
    deleteSchool,
    getCoordinator,
    updateCoordinator
};