import { Box, CircularProgress, Typography, Alert, Card, CardContent } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAuth } from "../../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import DateInTable from "../../../general/DateInTable";

const HistorialTab = ({ historial, excepId }) => {
    const auth = useAuth();
    const [isLoading, setIsLoading] = useState(false);

    const columns = [
        {
            field: "fecha",
            headerName: "Fecha de actualización",
            flex: 1,
            renderCell: (params) => (
                <Typography><DateInTable dateString={params.row.fecha} /></Typography>
            ),
        },
        { field: "datos_solicitados", headerName: "Datos solicitados", flex: 4 },
        { field: "tipo", headerName: "Tipo excepción", flex: 2 },
        { field: "status", headerName: "Status", flex: 0.55 }
    ];

    return (
        <Box sx={{mb: 2, ml: 2, mr: 2}}>
            {isLoading && <CircularProgress />}
            {historial && (<div style={{ display: "flex", height: "100%" }}>
                <div style={{ flexGrow: 1 }}>
                    <DataGrid
                        autoHeight
                        rows={historial}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        getRowId={(row) => row.id}
                        disableColumnFilter
                        disableColumnMenu
                        disableColumnSelector
                        disableDensitySelector
                        disableExtendRowFullWidth
                        disableSelectionOnClick

                        initialState={{
                            sorting: {
                            sortModel: [{ field: 'fecha', sort: 'desc' }],
                            },
                        }}
                    />
                </div>
            </div>
            )}
        </Box>
    );
};

export default HistorialTab;