import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import { useState } from "react";
import SalesFinal from "./SalesFinal";

const ColegioList = ({
  Colegio,
  SumaValorStockC,
  startDate,
  endDate,
  idNivel,
  idGrade,
  idColegio,
}) => {
  const [open, setOpen] = useState(false);

  if (
    document.querySelector(
      ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
    )
  )
    document
      .querySelector(
        ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
      )
      .remove();

  function formatNumber(num) {
    const roundedNum = Number(num).toFixed(2);
    return new Intl.NumberFormat("en-US").format(roundedNum);
  }

  return (
    <Accordion
      expanded={open}
      onChange={() => setOpen(!open)}
      sx={{
        marginTop: "5px",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        style={{
          flexDirection: "row-reverse",
          justifyContent: "space-between",
          backgroundColor: "#dfdfdf",
        }}
      >
        <Box display="flex" justifyContent="space-between" width="100%">
          <Typography style={{ order: 2 }} sx={{ fontSize: "0.97rem" }}>
            {Colegio}
          </Typography>
          <Box
            style={{
              order: 3,
              marginLeft: "auto",
              width: "100px",
              textAlign: "left",
            }}
          >
            <Typography>
              <strong>${formatNumber(SumaValorStockC)}</strong>
            </Typography>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <SalesFinal
          open={open}
          startDate={startDate}
          endDate={endDate}
          idNivel={idNivel}
          idGrade={idGrade}
          idColegio={idColegio}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default ColegioList;
