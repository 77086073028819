import axios from "axios";

/**
 * GET
**/

const getSupplier = async ( currentPage ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/pagos/rsupplier/suppliers/all/new?page=${currentPage}`;
    try {
        const res = await axios(URL);
        return res.data?.success ? res.data : [];
    } catch (error) {
        console.error(error);
    }
};

const searchSupplier = async ( currentPage, search ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/pagos/rsupplier/suppliers/all/search?page=${currentPage}&search=${search}`;
    try {
        const res = await axios(URL);
        return res.data?.success ? res.data : [];
    } catch (error) {
        console.error(error);
    }
};

/**
 * POST
**/

const toRegisterSupplier = async ( idUser, RFC, codigo_banco, banco, no_cuenta, CLABE, sucursal, referencia ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/pagos/rsupplier/suppliers/save`;
    try {
        const res = await axios.post(URL, { idUser, RFC, codigo_banco, banco, no_cuenta, CLABE, sucursal, referencia });
        return res.data?.success ? true : false;
    } catch (error) {
        console.error("toRegisterSupplier", error);
    }
};

const updateSupplier = async ( idUser, RFC, codigo_banco, banco, no_cuenta, CLABE, sucursal, referencia, idSupp ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/pagos/rsupplier/suppliers/update`;
    try {
        const res = await axios.post(URL, { idUser, RFC, codigo_banco, banco, no_cuenta, CLABE, sucursal, referencia, idSupp });
        return res.data?.success ? true : false;
    } catch (error) {
        console.error("updateSupplier", error);
    }
};

export {
    getSupplier,searchSupplier,toRegisterSupplier,updateSupplier
};