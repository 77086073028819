import {
  Box,
  Button,
  Card,
  CardContent,
  LinearProgress,
  Typography,
  Tabs,
  Tab,
  Grid,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useEffect, useState } from "react";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
//   import CotizacionDetailItem from "./CotizacionDetailItem";
import { IndexTabs } from "./tabs/IndexTabs";
import { useAuth } from "../../../../context/AuthContext";
import { profiles } from "../../../../utils/ventaMayoreo/profilesVentaMayoreo";
import BreadCrumb from "../../../general/BreadCrumb";
import { OrdenVentaDetailItem } from "./OrdenVentaDetailItem";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { getSaleOrdenDetail } from "../../../../utils/ventaMayoreo/ordenesVenta/ordenesVenta";

const itemsBreadCrumbs = [
  {
    label: "Venta mayoreo",
    icon: <ContentPasteSearchIcon sx={{ mr: 0.5 }} fontSize="small" />,
    link: "/venta-mayoreo",
  },
  { label: "Órdenes de venta", link: "/venta-mayoreo/ordenes-venta" },
  { label: "Detalle" },
];

export const OrdenVentaDetalle = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;
  const [isLoading, setIsLoading] = useState(false);
  const [detalle, setDetalle] = useState(null);
  const [contactDetail, setContactDetail] = useState(null);
  const [productsDetail, setProductsDetail] = useState(null);
  const [status, setStatus] = useState(null);
  const [ordenVenta, setOrdenVenta] = useState(null);
  const [ordCotizacion, setOrdCotizacion] = useState(null);
  const [numCotizaacion, setNumCotizaacion] = useState(null);
  const [estado, setEstado] = useState(0);

  useEffect(() => {
    if (!profiles.ordenesV.includes(auth.user.role.idProfile)) {
      navigate("/dashboard", { replace: true });
    } else {
      const getDetallePedido = async () => {
        try {
          setDetalle(null);
          setIsLoading(true);
          const resDetail = await getSaleOrdenDetail(id);
          setDetalle(resDetail.data);
          setOrdCotizacion(resDetail.data.cotizacion.ord_cotizacion);
          setOrdenVenta(resDetail.data.cotizacion.orden_venta);
          setNumCotizaacion(resDetail.data.cotizacion.numero_cotizacion);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      };
      getDetallePedido();
    }
  }, [id, auth.user.data.awsid, estado]);

  const toggleEstado = () => {
    setEstado((prevEstado) => (prevEstado === 0 ? 1 : 0));
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" sx={{ my: 2 }}>
          Detalle Cotizaciones
        </Typography>
        <Box>
          <BreadCrumb items={itemsBreadCrumbs} />
        </Box>
      </Box>

      <motion.div
        initial={{ y: 20 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Card>
          <Box>
            <Box>
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <Typography variant="h6" sx={{ ml: 2, my: 2, mb: 0 }}>
                    <LocalShippingIcon sx={{ m: 0 }} /> Órdenes de venta
                  </Typography>
                  {isLoading && <LinearProgress />}
                  {detalle && (
                    <OrdenVentaDetailItem ordenId={id} detalle={detalle} />
                  )}
                </Grid>
              </Grid>
            </Box>
          </Box>
          <IndexTabs
            id={id}
            ordCotizacion={ordCotizacion}
            ordenVenta={ordenVenta}
            numCotizaacion={numCotizaacion}
            idRequerimiento={detalle?.cotizacion?.id_requerimiento}
            toggleEstado={toggleEstado}
          />
        </Card>
      </motion.div>

      <Box>&nbsp;</Box>
    </>
  );
};
