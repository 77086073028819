import { Grid, List, ListItem, ListItemText } from "@mui/material";

const CancelacionesDSDetail = ({ detalle }) => {

    const creadoPor = [];
        if (detalle.data.nombre) {
        creadoPor.push(detalle.data.nombre + " ");
        }
        if (detalle.data.apellidoP) {
        creadoPor.push(detalle.data.apellidoP + " ");
        }
        if (detalle.data.apellidoM) {
        creadoPor.push(detalle.data.apellidoM);
    }

    return (
        <>
            <Grid container>
                {detalle && (
                <Grid item md={4}>
                    <List>
                        <ListItem>
                            <ListItemText primary="Cancelación #:" secondary={detalle.data.id_cancelacion}/>                                        
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Orden#" secondary={detalle.data.id_orden}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Caso CRM#" secondary={detalle.data.id_caso_crm}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Nota de Credito #:" secondary={detalle.data.no_credito}/>
                        </ListItem>
                    </List>
                </Grid>)}

                {detalle && (
                <Grid item md={4}>
                    <List>
                        <ListItem>
                                <ListItemText primary="Creado por:" secondary={creadoPor}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Fecha de registro:" secondary={detalle.data.cretaed_at}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Tipificacion:" secondary={detalle.data.channel}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Canal:" secondary={detalle.data.type}/>
                        </ListItem>
                    </List>
                </Grid>)}

                {detalle && (
                <Grid item md={4}>
                    <List>
                        <ListItem>
                            <ListItemText primary="Estatus:" secondary={detalle.data.estatus}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Estatus de atencion al cliente:" secondary={detalle.data.id_status_acc}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Estatus Logistica" secondary={detalle.data.id_status_logistica}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Estatus Finanzas" secondary={detalle.data.id_status_finanza}/>
                        </ListItem>
                    </List>
                </Grid>)}
            </Grid>
        </>
    );
};

export default CancelacionesDSDetail;