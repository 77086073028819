import axios from "axios";

const searchTicket = async (ticketId) => {
  const URL = `${process.env.REACT_APP_BASEURL}/crm/tickets/searchnew?id_crm_main=${ticketId}`;
  try {
    const response = await axios(URL);

    return response?.data?.success ? response.data.data : [];
  } catch (error) {
    console.log(error);
  }
};
const searchClosedTicket = async (ticketId) => {
  const URL = `${process.env.REACT_APP_BASEURL}/crm/tickets/searchclosed?id_crm_main=${ticketId}`;
  try {
    const response = await axios(URL);

    return response?.data?.success ? response.data.data : [];
  } catch (error) {
    console.log(error);
  }
};
const searchCaseTicket = async (ticketId) => {
  const URL = `${process.env.REACT_APP_BASEURL}/crm/cases/search?id_crm_case=${ticketId}`;
  try {
    const response = await axios(URL);

    return response?.data?.success ? response.data.data : [];
  } catch (error) {
    console.log(error);
  }
};

export { searchTicket, searchClosedTicket, searchCaseTicket };
