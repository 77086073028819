import { Box, CircularProgress, Typography, Alert, Card, CardContent, Grid, TextField, InputLabel, Select, FormControl, MenuItem, Table, TableHead, TableCell, TableRow, TableBody } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAuth } from "../../../../context/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import DateInTable from "../../../general/DateInTable";
import { getMessagesExcep, saveMessage } from "../../../../utils/trazabilidad/excepciones/excepciones";
import AllInboxIcon from '@mui/icons-material/AllInbox';

const MessagesTab = ({ excepId, excepDl }) => {
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [messages, setMessages] = useState(null);
    const [action, setAction] = useState(0);
    const [message, setMessage] = useState("");

    const handleChangeM = (event) => {
        setMessage(event.target.value);
    };

    const handleChangeA = (event) => {
        setAction(event.target.value);
    };

    const handleSaveMS = async () => {
        try {
          setIsLoading(true);
          await saveMessage(excepDl.id_order, excepId, message, auth.user.data.awsid, action);
          refreshPage();
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
          handleClose();
        }
    };

    const handleClose = () => {
        setAction(0);
        setMessage(null);
    };

    const refreshPage = () => {
        const current = location.pathname;
        navigate(`/reload`, { replace: true });
        setTimeout(() => {
          navigate(current, { replace: true });
        });
    };

    const getMessages = async () => {
        try {
            setIsLoading(true);
            const resp = await getMessagesExcep(excepId);
            setMessages(resp);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getMessages();
    }, []);

    return (
        <Box sx={{mb: 2, ml: 2, mr: 2}}>
            {isLoading && <CircularProgress />}
            
            {messages && (<Grid container sx={{mt:2, mb:2}}>
                <Grid item md={3}></Grid>
                <Grid item md={6}>
                    <Card>
                        <CardContent>
                            <Grid container>
                                <Grid item md={2}></Grid>
                                <Grid item md={8} sx={{mt:2, mb:2}}>
                                    <FormControl fullWidth sx={{mb: 2}}>
                                        <InputLabel id="selecte" htmlFor="groupedselect">Selecciona una opción</InputLabel>
                                        <Select id="groupedselect" name="groupedselect" label="Selecciona una opción" value={action} onChange={handleChangeA}>
                                            <MenuItem value={0}>Enviar mensaje</MenuItem>
                                            {excepDl.estado === 2 && (<>
                                            <MenuItem value={1}>Reabrir</MenuItem>
                                            <MenuItem value={2}>Agregar número de reporte</MenuItem></>
                                            )}
                                            <MenuItem value={3}>Cerrar</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <TextField multiline rows={4} fullWidth sx={{mb:2}} id="message" label="Escribe tu mensaje" value={message} onChange={handleChangeM} />
                                    <LoadingButton loading={isLoading} fullWidth size="medium" variant="contained" color="success" onClick={handleSaveMS} disabled={(!message || message.length < 4)}>Enviar</LoadingButton>
                                </Grid>
                                <Grid item md={2}></Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={3}></Grid>
            </Grid>
            )}

            {(messages && messages.length > 0) && (<>
                <Alert variant="filled" severity="info" icon={<AllInboxIcon />}><Typography variant="p"><strong>MENSAJES ANTERIORES</strong></Typography></Alert>
                <div style={{ display: "flex", height: "100%" }}>                
                    <div style={{ flexGrow: 1 }}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Fecha</TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}>Usuario</TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}>Mensaje</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {messages.map(( row ) => (
                                <TableRow sx={{ border: 0 }} >
                                    <TableCell sx={{ width: '15%' }}><DateInTable dateString={row.fecha_reg} /></TableCell>
                                    <TableCell sx={{ width: '25%', textAlign: 'center' }}>{row.nombre} {row.apellidoP}</TableCell>
                                    <TableCell sx={{ width: '60%', textAlign: 'center' }}>{row.comentario}</TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </div>
                </div></>
            )}

            {(messages && messages.length === 0) && (
                <Grid container sx={{mb:2}}>
                    <Grid item md={3}></Grid>
                    <Grid item md={6}>
                        <Alert variant="filled" severity="info"><Typography variant="p">No se encontraron mensajes</Typography></Alert>
                    </Grid>
                    <Grid item md={3}></Grid>
                </Grid>                
            )}
        </Box>
    );
};

export default MessagesTab;