import axios from "axios";

/**
 * GET
**/

const getCancelations = async ( currentPage , idUser ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/cancelaciones/cancelations/all/new?page=${currentPage}`;
    try {
        const res = await axios(URL);
        return res.data?.success ? res.data : [];
    } catch (error) {
        console.error(error);
    }
};

const searchCancelation = async ( currentPage ,search ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/cancelaciones/cancelations/all/search?page=${currentPage}&search=${search}`;
    try {
        const res = await axios(URL);
        return res.data?.success ? res.data : [];
    } catch (error) {
        console.error(error);
    }
};

const getCancelationByID = async ( cancelationId ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/cancelaciones/cancelations/details?cancelationId=${cancelationId}`;
    try {
        const res = await axios(URL);
        return res.data?.success ? res.data : [];
    } catch (error) {
        console.error("getCancelationByID", error);
    }
};

const getTabInternalLogs = async ( cancelationId ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/cancelaciones/cancelations/tabs/logs?cancelationId=${cancelationId}`;
    try {
        const res = await axios(URL);
        return res.data?.success ? res.data : [];
    } catch (error) {
        console.error("getTabInternalLogs", error);
    }
};

const getTabHistory = async ( cancelationId ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/cancelaciones/cancelations/tabs/history?cancelationId=${cancelationId}`;
    try {
        const res = await axios(URL);
        return res.data?.success ? res.data : [];
    } catch (error) {
        console.error("getTabHistory", error);
    }
};

const getTabObservaciones = async ( cancelationId ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/cancelaciones/cancelations/tabs/history?cancelationId=${cancelationId}`;
    try {
        const res = await axios(URL);
        return res.data?.success ? res.data : [];
    } catch (error) {
        console.error("getTabObservaciones", error);
    }
};

const getTabMessages = async ( cancelationId ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/cancelaciones/cancelations/tabs/messages?cancelationId=${cancelationId}`;
    try {
        const res = await axios(URL);
        return res.data?.success ? res.data : [];
    } catch (error) {
        console.error("getTabMessages", error);
    }
};

const getTabEvidences = async ( cancelationId ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/cancelaciones/cancelations/tabs/evidences?cancelationId=${cancelationId}`;
    try {
        const res = await axios(URL);
        return res.data?.success ? res.data : [];
    } catch (error) {
        console.error("getTabEvidences", error);
    }
};

const getTabOrderDetail = async ( orderId ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/cancelaciones/cancelations/tabs/order?orderId=${orderId}`;
    try {
        const res = await axios(URL);
        return res.data?.success ? res.data : [];
    } catch (error) {
        console.error("getTabOrderDetail", error);
    }
};

const getOrderDetails = async ( idCancel ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/cancelaciones/orders/detail?idOrder=${idCancel}`;
    try {
        const res = await axios(URL);
        return res.data?.success ? res.data : [];
    } catch (error) {
        console.error(error);
    }
};

/**
 * POST
**/

const saveCancelation = async ( orderId, userId) => {
    const URL = `${process.env.REACT_APP_BASEURL}/cancelaciones/cancelations/save`;
    try {
        const res = await axios.post(URL, { orderId, userId });
        return res.data?.success ? true : false;
    } catch (error) {
        console.error("saveCancelation", error);
    }
};

const confirmCancelation = async ( id_cancelacion, area, opcion, userId ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/cancelaciones/cancelations/confirm`;
    try {
        const res = await axios.post(URL, { id_cancelacion, area, opcion, userId });
        return res.data?.success ? true : false;
    } catch (error) {
        console.error("confirmCancelation", error);
    }
};

const saveCreditNote = async ( id_cancelacion, no_credito ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/cancelaciones/credit_note/save`;
    try {
        const res = await axios.post(URL, { id_cancelacion, no_credito });
        return res.data?.success ? true : false;
    } catch (error) {
        console.error("saveCreditNote", error);
    }
};

const uploadFiles = async (  files, cancelationId, userId) => {
    const URL = `${process.env.REACT_APP_BASEURL}/cancelaciones/files/upload`;
    try {
        const formData = new FormData();
        formData.append("files", files);
        formData.append("cancelationId", cancelationId);
        formData.append("userId", userId);

        const res = await axios({
            method: "POST",
            url: URL,
            data: formData,
            headers: {
            "Content-Type": "multipart/form-data;",
        },
        
    });
        if (!res?.data?.success) {
            throw new Error("uploadFiles:", "Error al enviar los archivos");
        }
    } catch (error) {
        console.error("uploadFiles", error);
    }
};

const deleteSingleFile = async ( fileId, userId ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/cancelaciones/files/delete`;
    try {
        const res = await axios.post(URL, { fileId, userId });
        return res.data?.success ? true : false;
    } catch (error) {
        console.error("deleteSingleFile", error);
    }
};

const saveMessage = async ( comentario, foraneoId, orderId, userId ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/cancelaciones/cancelations/tabs/messages/save`;
    try {
        const res = await axios.post(URL, { comentario, foraneoId, orderId, userId });
        return res.data?.success ? true : false;
    } catch (error) {
        console.error("saveMessage", error);
    }
};

const getCancelationExcel = async () => {
    const URL = `${process.env.REACT_APP_BASEURL}/cancelaciones/cancelations/all/new?page=All`;
    try {
        let res = await axios(URL);
        res = res.data?.success ? res.data.data : [];
        let aux = [];
        res.forEach((element) => {
            let aux2 = {
                "ID Cancelación": element.id_cancelacion,
                "Orden": element.order_increment_id,
                "ID Caso CRM": element.id_caso_crm ?? '-',
                "Fecha de alta": new Date(element.cretaed_at).toLocaleDateString("es-mx", { timeZone: "UTC" }),
                "Canal": element.channel,
                "Tipo": element.type,
                "Estatus Flujo": element.estatus,
                "Estatus Logística": element.id_status_logistica,
                "Estatus AC": element.id_status_acc,
                "Estatus Finanzas": element.id_status_finanza,
            };
            aux.push(aux2);
        });

        return aux;
    } catch (error) {
        console.error(error);
    }

    return [];
};

export {
    getCancelations, searchCancelation, getOrderDetails, saveCancelation, saveCreditNote, confirmCancelation, getCancelationByID,
    getTabInternalLogs, getTabHistory, getTabObservaciones, getTabMessages, getTabEvidences, getTabOrderDetail, uploadFiles,
    deleteSingleFile, saveMessage, getCancelationExcel
};