import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";

const AsignarOrdenCompra = () => {
  const [ordenCompra, setOrdenCompra] = useState("");
  const [error, setError] = useState("");

  const handleClickGenerarOrden = () => {
    if (ordenCompra.length === 0) {
      setError("El campo no puede estar vacío");
      return;
    }

    setError("");

    console.log("Orden de compra:", ordenCompra);
  };

  return (
    <Box
      sx={{
        border: "1px solid #dedede",
        borderTop: "3px solid #00a65a",
        margin: "10px",
        marginBottom: "25px",
        borderRadius: "5px",
      }}
    >
      <Box sx={{ borderBottom: "1px solid #dedede" }}>
        <Typography
          variant="h6"
          sx={{
            fontSize: "1.18rem",
            margin: "7px",
            marginLeft: "12px",
          }}
        >
          Asignar orden de compra
        </Typography>
      </Box>
      <Box>
        <List
          sx={{
            "& .MuiListItem-root": { paddingTop: 0 },
          }}
        >
          <ListItem>
            <ListItemText
              primary="Número de orden de compra"
              secondary={
                <TextField
                  label="Orden de compra"
                  variant="outlined"
                  size="small"
                  sx={{ marginTop: "5px" }}
                  fullWidth
                  value={ordenCompra}
                  onChange={(e) => setOrdenCompra(e.target.value)}
                  error={Boolean(error)}
                  helperText={error}
                />
              }
              sx={{ span: { fontWeight: "500" } }}
            />
          </ListItem>
        </List>

        <Grid container justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleClickGenerarOrden}
            sx={{
              backgroundColor: "#337ab7",
              margin: "10px",
              marginTop: "0px",
              "&:hover": {
                bgcolor: "#3d698f",
              },
            }}
          >
            Generar orden
          </Button>
        </Grid>
      </Box>
    </Box>
  );
};

export default AsignarOrdenCompra;
