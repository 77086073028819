import axios from "axios";

const getProcesses = async (pedido, telefono, correo, rma) => {
  try {
    const url = `${process.env.REACT_APP_BASEURL}/crm/searcher/processes`;
    const res = await axios.get(url, {
      params: {
        id: pedido,
        tel: telefono,
        mail: correo,
        rma,
      },
    });
    console.log("Respuesta procesos", res);
    return res?.data?.success ? res.data.data : [];
  } catch (error) {
    console.log("getProcesses", error.message);
  }
};

const getCases = async (pedido, telefono, correo) => {
  try {
    const url = `${process.env.REACT_APP_BASEURL}/crm/searcher/cases`;
    const res = await axios.get(url, {
      params: {
        id: pedido,
        tel: telefono,
        mail: correo,
      },
    });
    console.log("Respuesta casos", res);
    return res?.data?.success ? res.data.data : [];
  } catch (error) {
    console.log("getCases", error.message);
  }
};

const getOrders = async (pedido, telefono, correo) => {
  try {
    const url = `${process.env.REACT_APP_BASEURL}/crm/searcher/orders`;
    const res = await axios.get(url, {
      params: {
        id: pedido,
        tel: telefono,
        mail: correo,
      },
    });
    console.log("Respuesta pedidos", res);
    const result = res.data.data;
    const unique = [...new Set(result.map((r) => r))];
    return res?.data?.success ? unique : [];
  } catch (error) {
    console.error("getOrders", error.message);
  }
};

const getInteractions = async (correo) => {
  try {
    const url = `${process.env.REACT_APP_BASEURL}/crm/searcher/interactions`;
    const res = await axios.get(url, {
      params: {
        mail: correo,
      },
    });
    console.log("Respuesta Interacciones", res);
    return res?.data?.success ? res.data.data : [];
  } catch (error) {
    console.log("getInteractions", error.message);
  }
};

const getFiles = async (id) => {
  try {
    const url = `${process.env.REACT_APP_BASEURL}/crm/searcher/files`;
    const res = await axios.get(url, {
      params: {
        idMain: id,
      },
    });
    console.log("Respuesta archivos", res);
    return res?.data?.success ? res.data.data : [];
  } catch (error) {
    console.log("getFiles", error.message);
  }
};

export { getProcesses, getCases, getOrders, getInteractions, getFiles };
