import { useState } from "react";
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { getReporteListasPublicadasEnColegios } from "../../../utils/reportes/reportes";
import ExcelFile from "../../general/ExcelFile";
import { LoadingButton } from "@mui/lab";
import { HistoricoLinks } from "./HistoricoLinks";

export const DetailPanelContentId = ({ row: rowProp }) => {
  const [idPeriodo, setIdPeriodo] = useState(null);
  const [excelData, setExcelData] = useState(null);
  const [isELoading, setIsELoading] = useState(false);

  const handleButtonClick = (row) => {
    buscarReporte(idPeriodo, row);
  };

  const buscarReporte = async (idPeriodo, row) => {
    var fechaActual = new Date();
    var año = fechaActual.getFullYear();
    var mes = (fechaActual.getMonth() + 1).toString().padStart(2, "0");
    var dia = fechaActual.getDate().toString().padStart(2, "0");
    var hora = fechaActual.getHours().toString().padStart(2, "0");
    var minutos = fechaActual.getMinutes().toString().padStart(2, "0");
    var segundos = fechaActual.getSeconds().toString().padStart(2, "0");
    var fechaFormateada = `${año}-${mes}-${dia} ${hora}${minutos}${segundos}`;

    switch (row.report) {
      case "Listas publicadas en colegios":
        try {
          setIsELoading(true);
          const response = await getReporteListasPublicadasEnColegios(
            idPeriodo
          );
          setExcelData({
            data: response,
            name: `listaspublicadasencolegios ${fechaFormateada}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      default:
        console.log("No se econtro la opcion");
    }
  };

  return (
    <Stack
      sx={{ py: 2, height: "100%", boxSizing: "border-box" }}
      direction="column"
    >
      <Paper
        sx={{
          flex: 1,
          mx: "auto",
          width: "90%",
          p: 1,
          minWidth: "530px",
          backgroundColor: "rgb(245 245 245 / 30%)",
        }}
      >
        <Stack direction="column" spacing={1} sx={{ height: 1 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ textAlign: "center" }}>Id Periodo</TableCell>
                <TableCell sx={{ textAlign: "center" }}>Accion</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ border: 0 }}>
                <TableCell sx={{ width: "15%", textAlign: "center" }}>
                  <TextField
                    onChange={(e) => {
                      setIdPeriodo(e.target.value);
                      setExcelData(null);
                    }}
                    inputProps={{ type: "number" }}
                    value={idPeriodo || ""}
                  />
                </TableCell>
                <TableCell sx={{ width: "10%", textAlign: "center" }}>
                  {!excelData && (
                    <LoadingButton
                      variant="contained"
                      loading={isELoading}
                      onClick={() => handleButtonClick(rowProp)}
                      disabled={!idPeriodo}
                      sx={{ mt: 1 }}
                    >
                      Buscar
                    </LoadingButton>
                  )}
                  {excelData && (
                    <ExcelFile
                      excelData={excelData.data}
                      fileName={excelData.name}
                    />
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <HistoricoLinks row={rowProp} />
        </Stack>
      </Paper>
    </Stack>
  );
};
