import { createContext, useContext, useState } from "react";

const DialogsContext = createContext();

const DialogsProvider = ({ children }) => {
  const [openNuevoTicketDialog, setOpenNuevoTicketDialog] = useState(false);
  const [openNuevoCasoOnCrmDialog, setOpenNuevoCasoOnCrmDialog] =useState(false);
  const [openManifiestoDialog, setOpenManifiestoDialog] = useState(false);
  const [openRemisionDialog, setOpenRemisionDialog] = useState(false);

  return (
    <DialogsContext.Provider
      value={{
        openNuevoTicketDialog,
        setOpenNuevoTicketDialog,
        openNuevoCasoOnCrmDialog,
        setOpenNuevoCasoOnCrmDialog,
        openManifiestoDialog,
        setOpenManifiestoDialog,
        openRemisionDialog,
        setOpenRemisionDialog
      }}
    >
      {children}
    </DialogsContext.Provider>
  );
};

const useDialogs = () => useContext(DialogsContext);

export { DialogsProvider, useDialogs };
