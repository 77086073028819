import { Button,Box,CircularProgress,Chip,Typography,Link,Paper } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAuth } from "../../../context/AuthContext";
import dayjs from "dayjs";
import { getFiles } from "../../../utils/crm/buscador";
import { getDataDownloadFromBase64, uploadFiles } from "../../../utils/crm/files";

const ArchivosTab = ({ details, pedido, telefono, correo, rma }) => {
    const auth = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [archivos, setArchivos] = useState(null);
    const filesRef = useRef(null);

    const loadArchivos = async () => {
        if (!pedido && !telefono && !correo && !rma) {
          return;
        }
        setIsLoading(true);
        try {
          const newArchivos = await getFiles(details.id_crm_main);
          setArchivos(newArchivos);
        } catch (error) {
          console.error("handleSearch", error);
        }
        setIsLoading(false);
    };
    
    const handleUploadFiles = async () => {
        if (!filesRef?.current?.files?.length) {
          return;
        }
        setIsLoading(true);
        try {
          await uploadFiles(
            filesRef.current.files,
            details.id_crm_main,
            auth.user.data.awsid
          );
          const newArchivos = await getFiles(details.id_crm_main);
          setArchivos(newArchivos);
          filesRef.current.value = "";
        } catch (error) {
          console.error("handleUploadFiles", error);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        loadArchivos();
    }, []);

    return (
        <>
            <Box>
                <Typography variant="h6" gutterBottom>
                  Subir archivos
                </Typography>
                <input type="file" multiple ref={filesRef} />
                <Button
                  disableElevation
                  variant="contained"
                  size="small"
                  onClick={handleUploadFiles}
                  disabled={isLoading}
                >
                  Subir archivos
                </Button>
            </Box>

            {isLoading && <CircularProgress />}
            {archivos && (
            <Box>
                <Typography variant="h6" sx={{ my: 2 }}>
                  Lista de archivos
                </Typography>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    gap: 2,
                  }}
                >
                  {archivos &&
                    archivos.map((archivo) => {
                      const { href, download } = getDataDownloadFromBase64(
                        archivo.base64,
                        archivo.name,
                        archivo.type
                      );
                      return (
                        <Link
                          key={archivo.id_file}
                          href={href}
                          download={download}
                          color="inherit"
                          underline="none"
                        >
                          <Paper variant="outlined" sx={{ p: 1 }}>
                            <Chip label={archivo.id_file} size="small" />
                            <Typography
                              variant="button"
                              display="block"
                              sx={{ mt: 2 }}
                            >
                              {archivo.name}
                            </Typography>
                            <Typography variant="caption">
                              {dayjs(archivo.creation_date).format(
                                "DD/MM/YYYY"
                              )}
                            </Typography>
                          </Paper>
                        </Link>
                      );
                    })}
                </Box>
            </Box>
            )}
        </>
    )
};

export default ArchivosTab;