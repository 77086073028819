import axios from "axios";

/**
 * GET
 */

const getRmaList = async (currentPage) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/rma/rmas/new?page=${currentPage}`;
  try {
    const res = await axios(URL);

    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const searchRmaList = async (currentPage, search) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/rma/rmas/search?page=${currentPage}&search=${search}`;
  try {
    const res = await axios(URL);

    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getRMAById = async (idRMA, idUser) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/rma/rmas/details?idRMA=${idRMA}&idUser=${idUser}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data.data[0] : null;
  } catch (error) {
    console.error(error);
  }
};

const getMovByRMA = async (idRMA) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/rma/rmas/details/tab/movements?idRMA=${idRMA}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data.data : null;
  } catch (error) {
    console.error(error);
  }
};

const getMessagesByRMA = async (idRMA) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/rma/rmas/details/tab/messages?idRMA=${idRMA}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data.data : null;
  } catch (error) {
    console.error(error);
  }
};

const getItems = async (idRMA, idOrder, increment_id) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/rma/rmas/details/tab/items?idRMA=${idRMA}&idOrder=${idOrder}&increment_id=${increment_id}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data.data : null;
  } catch (error) {
    console.error(error);
  }
};

const getStatusRma = async (idRMA) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/rma/rmas/status?idRMA=${idRMA}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data.data[0] : null;
  } catch (error) {
    console.error(error);
  }
};

const getFileRem = async (idRMA) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/rma/rmas/files?idRMA=${idRMA}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data.data[0] : null;
  } catch (error) {
    console.error(error);
  }
};

const getSCRma = async (idOrder, increment_id) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/rma/rmas/details/credit?idOrder=${idOrder}&increment_id=${increment_id}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data.data[0] : null;
  } catch (error) {
    console.error(error);
  }
};

const getAddresses = async (email) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/rma/rmas/addresses?email=${email}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data.data : null;
  } catch (error) {
    console.error(error);
  }
};

const getShippingData = async (idOrder, idNota) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/rma/rmas/details/data?idOrder=${idOrder}&idNota=${idNota}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data.data[0] : null;
  } catch (error) {
    console.error(error);
  }
};

const getGuide = async (idRMA) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/rma/rmas/details/guide?idRMA=${idRMA}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data.data[0] : null;
  } catch (error) {
    console.error(error);
  }
};

/**
 * POST
 */

const saveMessage = async (increment_id, idRMA, msj, idUser) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/rma/messages/save`;
  try {
    await axios.post(URL, { increment_id, idRMA, msj, idUser });
  } catch (error) {
    console.error("saveMessage", error);
  }
};

const changeSolution = async (
  increment_id,
  idRMA,
  name,
  idUser,
  sku,
  idOrder,
  idEze,
  solution
) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/rma/items/solution/change`;
  try {
    await axios.post(URL, {
      increment_id,
      idRMA,
      name,
      idUser,
      sku,
      idOrder,
      idEze,
      solution,
    });
  } catch (error) {
    console.error("changeSolution", error);
  }
};

const saveItemQuantity = async (
  idRMA,
  idUser,
  increment_id,
  idOrder,
  if_itemRMA,
  quantity,
  idEze,
  sku
) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/rma/rmas/items/save`;
  try {
    await axios.post(URL, {
      idRMA,
      idUser,
      increment_id,
      idOrder,
      if_itemRMA,
      quantity,
      idEze,
      sku,
    });
  } catch (error) {
    console.error("saveItemQuantity", error);
  }
};

const saveStoreCredit = async (
  increment_id,
  idRMA,
  email,
  clientName,
  idUser,
  idOrder,
  userName,
  cantFinal,
  numNota
) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/rma/rmas/storecredit/refund/save`;
  try {
    await axios.post(URL, {
      increment_id,
      idRMA,
      email,
      clientName,
      idUser,
      idOrder,
      userName,
      cantFinal,
      numNota,
    });
  } catch (error) {
    console.error("saveStoreCredit", error);
  }
};

const saveRefundData = async (
  idRMA,
  idUser,
  idOrder,
  idNote,
  ifDebit,
  ifCredit,
  numCuenta,
  holder,
  bank,
  office
) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/rma/rmas/refund/data/save`;
  try {
    await axios.post(URL, {
      idRMA,
      idUser,
      idOrder,
      idNote,
      ifDebit,
      ifCredit,
      numCuenta,
      holder,
      bank,
      office,
    });
  } catch (error) {
    console.error("saveRefundData", error);
  }
};

const uploadFiles = async (idRMA, files) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/rma/rmas/details/files/upload`;
  try {
    const formData = new FormData();
    formData.append("idRMA", idRMA);
    formData.append("files", files);

    const res = await axios({
      method: "POST",
      url: URL,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data;",
      },
    });

    if (!res?.data?.success) {
      throw new Error("uploadFiles:", "Error al enviar los archivos");
    }
  } catch (error) {
    console.error("uploadFiles", error);
  }
};

const saveSCRefundData = async (
  increment_id,
  idRMA,
  email,
  clientName,
  idUser,
  idOrder,
  idNote,
  cantReembolso,
  numNota
) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/rma/rmas/creditnote/refund/save`;
  try {
    await axios.post(URL, {
      increment_id,
      idRMA,
      email,
      clientName,
      idUser,
      idOrder,
      idNote,
      cantReembolso,
      numNota,
    });
  } catch (error) {
    console.error("saveSCRefundData", error);
  }
};

const saveShippingData = async (
  idRMA,
  idUser,
  idOrder,
  idNote,
  dirName,
  dirCountry,
  dirCP,
  dirState,
  dirMun,
  dirStreet,
  dirNumExt,
  dirNumInt,
  dirCol,
  dirCity,
  dirBetSt,
  dirFac,
  dirTel
) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/rma/rmas/shippdata/save`;
  try {
    await axios.post(URL, {
      idRMA,
      idUser,
      idOrder,
      idNote,
      dirName,
      dirCountry,
      dirCP,
      dirState,
      dirMun,
      dirStreet,
      dirNumExt,
      dirNumInt,
      dirCol,
      dirCity,
      dirBetSt,
      dirFac,
      dirTel,
    });
  } catch (error) {
    console.error("saveShippingData", error);
  }
};

const assignGuide = async (
  idRMA,
  idUser,
  idOrder,
  idNote,
  dirName,
  dirCountry,
  dirCP,
  dirState,
  dirMun,
  dirStreet,
  dirNumExt,
  dirNumInt,
  dirCol,
  dirCity,
  dirBetSt,
  dirFac,
  dirTel,
  dirCel,
  dirJustif,
  name,
  lastname,
  mothersname,
  email
) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/rma/rmas/labels/save`;
  try {
    await axios.post(URL, {
      idRMA,
      idUser,
      idOrder,
      idNote,
      dirName,
      dirCountry,
      dirCP,
      dirState,
      dirMun,
      dirStreet,
      dirNumExt,
      dirNumInt,
      dirCol,
      dirCity,
      dirBetSt,
      dirFac,
      dirTel,
      dirCel,
      dirJustif,
      name,
      lastname,
      mothersname,
      email,
    });
  } catch (error) {
    console.error("assignGuide", error);
  }
};

const updateShippingData = async (
  idRMA,
  idUser,
  idOrder,
  idNote,
  dirName,
  dirCountry,
  dirCP,
  dirState,
  dirMun,
  dirStreet,
  dirNumExt,
  dirNumInt,
  dirCol,
  dirCity,
  dirBetSt,
  dirFac,
  dirTel
) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/rma/rmas/shippdata/update`;
  try {
    await axios.post(URL, {
      idRMA,
      idUser,
      idOrder,
      idNote,
      dirName,
      dirCountry,
      dirCP,
      dirState,
      dirMun,
      dirStreet,
      dirNumExt,
      dirNumInt,
      dirCol,
      dirCity,
      dirBetSt,
      dirFac,
      dirTel,
    });
  } catch (error) {
    console.error("updateShippingData", error);
  }
};

const takeRMA = async (idRMA, idUser, increment_id, comment) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/rma/rmas/status/take`;
  try {
    await axios.post(URL, { idRMA, idUser, increment_id, comment });
  } catch (error) {
    console.error("takeRMA", error);
  }
};

const changeStatusRMA = async (
  idRMA,
  idUser,
  idOrder,
  increment_id,
  email,
  sap_id_method,
  diasTranscurridos,
  if_EnvioEmail,
  clientName
) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/rma/rmas/status/change`;
  try {
    await axios.post(URL, {
      idRMA,
      idUser,
      idOrder,
      increment_id,
      email,
      sap_id_method,
      diasTranscurridos,
      if_EnvioEmail,
      clientName,
    });
  } catch (error) {
    console.error("changeStatusRMA", error);
  }
};

const saveCNandQ = async (id_orden_fk, id_nota_credito, id_rma_nc, numero_notaCreditoReembolso, cantidadReembolso) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/rma/rmas/refund/noteqty/save`;
  try {
    await axios.post(URL, { id_orden_fk, id_nota_credito, id_rma_nc, numero_notaCreditoReembolso, cantidadReembolso });
  } catch (error) {
    console.error("saveCNandQ", error);
  }
};

/**
 * EXCEL EXPORT
 **/

const getExcelRma = async (chipLabels) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/rma/rmas/new?page=All`;
  try {
    let res = await axios(URL);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        "Fecha de entrega": new Date(element.fechaEntrega).toLocaleDateString(
          "es-mx",
          { timeZone: "UTC" }
        ),
        "Días Hábiles Transcurridos": element.diasTranscurridos,
        "Número de Guia": element.guia,
        Orden: element.order_increment_id,
        RMA: element.increment_id,
        "Fecha de última modificación": new Date(
          element.fecha_ult
        ).toLocaleDateString("es-mx", { timeZone: "UTC" }),
        "Store Credit": chipLabels[element.id_status_storeCredit],
        Reembolso: chipLabels[element.id_status_reembolso],
        Cambio: chipLabels[element.id_status_cambio],
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const toggleItemStatus = async (itemId, rmaId, userId, sku) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/rma/rmas/details/tab/items/togglestatus?itemId=${itemId}&rmaId=${rmaId}&itemSKU=${sku}&userId=${userId}`;
  try {
    await axios.put(URL);
  } catch (error) {
    console.error("toggleItemStatus", error);
  }
};

export {
  takeRMA,
  getRMAById,
  getMovByRMA,
  getMessagesByRMA,
  saveMessage,
  getItems,
  getStatusRma,
  getFileRem,
  getSCRma,
  saveStoreCredit,
  saveRefundData,
  uploadFiles,
  saveSCRefundData,
  getAddresses,
  saveShippingData,
  updateShippingData,
  getShippingData,
  assignGuide,
  getGuide,
  changeSolution,
  changeStatusRMA,
  saveItemQuantity,
  getRmaList,
  searchRmaList,
  getExcelRma,
  toggleItemStatus,
  saveCNandQ,
};
