import { Box, CircularProgress, Typography, Alert, Card, CardContent, Grid, TextField, Collapse, IconButton, Table, TableHead, TableCell, TableRow, TableBody } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAuth } from "../../../../context/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import DateInTable from "../../../general/DateInTable";
import AllInboxIcon from '@mui/icons-material/AllInbox';
import CloseIcon from '@mui/icons-material/Close';
import { getBackOrderMessages, sendMessage } from "../../../../utils/trazabilidad/backorders/backorders";

const MensajesTab = ({ boId, detalle }) => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState("");
    const [open, setOpen] = useState(false);

    const handleSaveMS = async () => {
        try {
          setIsLoading(true);
          if(message)
          {
            let response = await sendMessage(message, user.data.awsid, boId, detalle.increment_id);
            if(response){
                setOpen(true);
            }
          }
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
          handleClose();
        }
    };

    const handleClose = () => {
        setMessage("");
    };

    useEffect(() => {

        const getMessages = async () => {
            try {
                setIsLoading(true);
                const resp = await getBackOrderMessages(boId);
                setMessages(resp);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };
        getMessages();

    }, [boId, open]);

    return (
        <Box sx={{mb: 2, ml: 2, mr: 2}}>
            {isLoading && <CircularProgress />}
            <Collapse in={open}>
                <Alert severity="success" action={ <IconButton aria-label="close" color="inherit" size="small" onClick={() => { setOpen(false); }} >
                    <CloseIcon fontSize="inherit" />
                    </IconButton>
                } sx={{ mb: 2 }} > Mensaje enviado
                </Alert>
            </Collapse>
            
            {(messages && !isLoading) && (<Grid container sx={{mt:2, mb:2}}>
                <Grid item md={3}></Grid>
                <Grid item md={6}>
                    <Card>
                        <CardContent>
                            <Grid container>
                                <Grid item md={2}></Grid>
                                <Grid item md={8} sx={{mt:2, mb:2}}>
                                    <TextField multiline rows={4} fullWidth sx={{mb:2}} id="message" label="Escribe tu mensaje" value={message} onChange={(e) => setMessage(e.target.value)} />
                                    <LoadingButton loading={isLoading} fullWidth size="medium" variant="contained" color="success" onClick={handleSaveMS} disabled={(!message || message.length < 4)}>Enviar</LoadingButton>
                                </Grid>
                                <Grid item md={2}></Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={3}></Grid>
            </Grid>
            )}

            {(messages && messages.length > 0 && !isLoading) && (<>
                <Alert variant="filled" severity="info" icon={<AllInboxIcon />}><Typography variant="p"><strong>Historial de mensajes</strong></Typography></Alert>
                <div style={{ display: "flex", height: "100%" }}>                
                    <div style={{ flexGrow: 1 }}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Fecha</TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}>Usuario</TableCell>
                                    <TableCell sx={{ textAlign: 'center' }}>Mensaje</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {messages.map(( row ) => (
                                <TableRow sx={{ border: 0 }} >
                                    <TableCell sx={{ width: '15%' }}><DateInTable dateString={row.fecha_reg} /></TableCell>
                                    <TableCell sx={{ width: '25%', textAlign: 'center' }}>{row.nombreRecup}</TableCell>
                                    <TableCell sx={{ width: '60%', textAlign: 'center' }}>{row.comentario}</TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </div>
                </div></>
            )}

            {(messages && messages.length === 0 && !isLoading) && (
                <Grid container sx={{mb:2}}>
                    <Grid item md={3}></Grid>
                    <Grid item md={6}>
                        <Alert variant="filled" severity="info"><Typography variant="p">No se encontraron mensajes</Typography></Alert>
                    </Grid>
                    <Grid item md={3}></Grid>
                </Grid>                
            )}
        </Box>
    );
};

export default MensajesTab;