import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import {
  getCoordinator,
  updateCoordinator,
} from "../../../utils/vales/coordinadores/coordinadores";

const ModalEditCoordinator = ({
  openModalED,
  handleCloseModalED,
  updateUsr,
  coordinator,
  setRows,
  setReload
}) => {
  const [nombre, setNombre] = useState("");
  const [nombreError, setNombreError] = useState(false);
  const [primerApellido, setPrimerApellido] = useState("");
  const [primerApellidoError, setPrimerApellidoError] = useState(false);
  const [segundoApellido, setSegundoApellido] = useState("");
  const [segundoApellidoError, setSegundoApellidoError] = useState(false);
  const [mail, setMail] = useState("");
  const [mailEmptyError, setMailEmptyError] = useState(false);
  const [mailFormatError, setMailFormatError] = useState(false);
  const [contrasena, setContrasena] = useState("");
  const [idUser, setidUser] = useState(null);

  useEffect(() => {
    (async () => {
      console.log();
      if (coordinator.length > 0) {
        setNombre(coordinator[0].nombre);
        setPrimerApellido(coordinator[0].apellidoP);
        setSegundoApellido(coordinator[0].apellidoM);
        setMail(coordinator[0].mail);
        setidUser(coordinator[0].id_user);
      }
    })();
  }, [coordinator]);

  const validateFields = () => {
    let isValid = true;

    if (!nombre) {
      setNombreError(true);
      isValid = false;
    } else {
      setNombreError(false);
    }

    if (!primerApellido) {
      setPrimerApellidoError(true);
      isValid = false;
    } else {
      setPrimerApellidoError(false);
    }

    if (!segundoApellido) {
      setSegundoApellidoError(true);
      isValid = false;
    } else {
      setSegundoApellidoError(false);
    }

    if (!mail) {
      setMailEmptyError(true);
      isValid = false;
    } else {
      setMailEmptyError(false);
    }

    if (mail && !isValidEmail(mail)) {
      setMailFormatError(true);
      isValid = false;
    } else {
      setMailFormatError(false);
    }

    return isValid;
  };

  const isValidEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return regex.test(email);
  };

  const handleGuardarClick = async () => {
    if (validateFields()) {
      let usuario = {
        nombre: nombre,
        apellidoP: primerApellido,
        apellidoM: segundoApellido,
        mail: mail,
        updateUsr: updateUsr,
        id_user: idUser,
      };

      if (contrasena.length > 0) {
        usuario.pass = contrasena;
      }

      handleCloseModalED();
      await updateCoordinator(usuario);
      handleClearModal();
        setRows([]);
        setReload((prevReload) => (prevReload === 0 ? 1 : 0));
    }
  };

  const handleClearModal = () => {
    setNombre("");
    setPrimerApellido("");
    setSegundoApellido("");
    setMail("");
    setContrasena("");
  };

  return (
    <Dialog open={openModalED} onClose={handleCloseModalED}>
      <DialogTitle>
        <Typography>
          <strong>Agregar Coordinador</strong>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <List>
          <ListItem>
            <TextField
              label="Nombre"
              variant="outlined"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
              sx={{ width: "500px" }}
              error={nombreError}
              helperText={nombreError ? "Este campo es obligatorio" : ""}
            />
          </ListItem>
          <ListItem>
            <TextField
              label="Primer Apellido"
              variant="outlined"
              value={primerApellido}
              onChange={(e) => setPrimerApellido(e.target.value)}
              sx={{ width: "500px" }}
              error={primerApellidoError}
              helperText={
                primerApellidoError ? "Este campo es obligatorio" : ""
              }
            />
          </ListItem>
          <ListItem>
            <TextField
              label="Segundo Apellido"
              variant="outlined"
              value={segundoApellido}
              onChange={(e) => setSegundoApellido(e.target.value)}
              sx={{ width: "500px" }}
              error={segundoApellidoError}
              helperText={
                segundoApellidoError ? "Este campo es obligatorio" : ""
              }
            />
          </ListItem>
          <ListItem>
            <TextField
              label="Mail"
              variant="outlined"
              value={mail}
              onChange={(e) => setMail(e.target.value)}
              sx={{ width: "500px" }}
              error={mailEmptyError || mailFormatError}
              helperText={
                mailEmptyError
                  ? "Este campo es obligatorio"
                  : mailFormatError
                  ? "Ingrese un email válido"
                  : ""
              }
            />
          </ListItem>
          <ListItem>
            <TextField
              label="Contraseña"
              type="password"
              variant="outlined"
              value={contrasena}
              onChange={(e) => setContrasena(e.target.value)}
              sx={{ width: "500px" }}
            />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="error" onClick={handleCloseModalED}>
          Cerrar
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={handleGuardarClick}
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalEditCoordinator;
