import { Box, InputLabel,  Select,  FormControl, Menu, MenuItem } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useAuth } from "../../../../context/AuthContext";
import { getClarificationItems } from "../../../../utils/trazabilidad/aclaraciones/aclaraciones";

const ArticulosTab = ({ aId, detalle }) => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [articulos, setArticulos] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleChangeValProd = async (event) => {
        try {
          setIsLoading(true);
          let item = event.target.name;
          let value = event.target.value;
          console.log(item);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
    };

    function getValProd(row) {
        if (row.if_correcto) return 0;
        else if (row.if_faltante) return 1;
        else if (row.if_erroneo) return 2;
        else if (row.if_defectuoso) return 3;
        else return 0;
    }

    const columns = [
        { field: "product_sku", headerName: "SKU", flex: 1, align: "center", headerAlign: "center", },
        { field: "product_name", headerName: "Nombre Producto", flex: 2 },
        { field: "attributes", headerName: "Categoría", align: "center", headerAlign: "center", flex: 0.6,  },
        { field: "grupo_articulos", headerName: "Grupo de Artículo", align: "center", headerAlign: "center", flex: 0.6, valueGetter: ({ row }) => (row.grupo_articulos ?? 'S/Grupo') },
        { field: "quantity", headerName: "Cantidad enviada", align: "center", headerAlign: "center", flex: 0.6,  },
        { field: "price", headerName: "Precio", align: "center", headerAlign: "center", flex: 1, valueGetter: ({ row }) => ('$ '+ row.price.toFixed(4)) },
        { field: "total", headerName: "Total", align: "center", headerAlign: "center", flex: 1, valueGetter: ({ row }) => ('$ '+ row.total.toFixed(4)) },
        { field: "accion", headerName: "Acción", align: "center", headerAlign: "center", flex: 1, sortable: false, renderCell: ({ row }) => (
            <FormControl variant="standard" fullWidth>
                <Select name={row} defaultValue={getValProd(row)} onChange={handleChangeValProd}>
                    <MenuItem value={0}>Producto correcto</MenuItem>
                    <MenuItem value={1}>Producto faltante</MenuItem>
                    <MenuItem value={2}>Producto erróneo</MenuItem>
                    <MenuItem value={3}>Producto defectuoso</MenuItem>
                </Select>
            </FormControl>
        )},
    ];

    if(document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"))
        document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']").remove();

    useEffect(() => {
        const getItems = async () => {
            try {
              setIsLoading(true);
              const resp = await getClarificationItems(detalle.id_orden, aId);
              setArticulos(resp);
            } catch (error) {
              console.error(error);
            } finally {
              setIsLoading(false);
            }
        };
        
        getItems();
    }, [aId]);

    return (
    <>
        <Box sx={{ mb: 2 }}>
            <div style={{ display: "flex", height: "100%" }}>
                <div style={{ flexGrow: 1 }}>
                    <DataGridPro
                        autoHeight
                        rows={articulos}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        getRowId={(row) => row.eze3_item_id}
                        loading={isLoading}
                        disableColumnMenu
                        disableSelectionOnClick
                        initialState={{
                            sorting: {
                              sortModel: [
                                { field: "product_sku", sort: "desc" },
                              ],
                            },
                        }}
                        pagination
                    />
                </div>
            </div>
        </Box>
    </>
    );
};

export default ArticulosTab;