import axios from "axios";

/**
 * GET
 **/

const getClarifications = async (type, currentPage, idProfile) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/clarifications/clarifications/all/${type}?page=${currentPage}&idProfile=${idProfile}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const searchClarifications = async (currentPage, idProfile, search) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/clarifications/clarifications/all/search?page=${currentPage}&idProfile=${idProfile}&search=${search}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getClarificationsTypes = async () => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/clarifications/types/all/new`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getClarificationDetail = async (idAcl, idUser) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/clarifications/clarifications/details/get?idAcl=${idAcl}&idUser=${idUser}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getClarificationMoves = async (idAcl) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/clarifications/clarifications/moves/get?idAcl=${idAcl}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getClarificationMessages = async (idAcl) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/clarifications/clarifications/messages/get?idAcl=${idAcl}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getClarificationAddresses = async (email) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/clarifications/clarifications/addresses/get?email=${email}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getClarificationFiles = async (idAcl) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/clarifications/clarifications/files/get?idAcl=${idAcl}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getClarificationItems = async (increment_id, idAcl) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/clarifications/clarifications/items/get?increment_id=${increment_id}&idAcl=${idAcl}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getClarificationCarriers = async () => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/clarifications/carriers/services/get`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data.data : [];
  } catch (error) {
    console.error(error);
  }
};

/**
 * POST
 **/

const saveClarification = async ( increment_id, idType, idUser, motivo ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/clarifications/clarifications/save`;
  try {
    const res = await axios.post(URL, { increment_id, idType, idUser, motivo });
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("saveClarification", error);
  }
};

const saveClarificationMessage = async ( increment_id, idAcl, idUser, comment ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/clarifications/clarifications/messages/save`;
  try {
    const res = await axios.post(URL, { increment_id, idAcl, idUser, comment });
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("saveClarificationMessage", error);
  }
};

const saveClarificationDetails = async ( idAcl, idUser, idStatus, increment_id, dName, dCty, dCP, dState, dMun, dStr, dNumE, dNumI, dCol, dCity, dRef, dFac, dTel ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/clarifications/clarifications/details/save`;
  try {
    const res = await axios.post(URL, { idAcl, idUser, idStatus, increment_id, dName, dCty, dCP, dState, dMun, dStr, dNumE, dNumI, dCol, dCity, dRef, dFac, dTel });
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("saveClarificationDetails", error);
  }
};

const saveClarificationRefund = async ( increment_id, idAcl, idUser, ifDebit, ifCredit, bNumAcc, bTitu, bBank, bSucu ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/clarifications/clarifications/refund/save`;
  try {
    const res = await axios.post(URL, { increment_id, idAcl, idUser, ifDebit, ifCredit, bNumAcc, bTitu, bBank, bSucu });
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("saveClarificationRefund", error);
  }
};

const deleteMissingItem = async ( idEzeItem, idAcl, sku ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/clarifications/clarifications/missitem/delete`;
  try {
    const res = await axios.post(URL, { idEzeItem, idAcl, sku });
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("deleteMissingItem", error);
  }
};

const saveMissingItem = async ( idEzeItem, idAcl, sku, idUser, qtyC, qtyE, price, iva, discount, name ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/clarifications/clarifications/missitem/save`;
  try {
    const res = await axios.post(URL, { idEzeItem, idAcl, sku, idUser, qtyC, qtyE, price, iva, discount, name });
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("saveMissingItem", error);
  }
};

const updateMissingItem = async ( idEzeItem, idAcl, sku, idUser, qtyC ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/clarifications/clarifications/missitem/save`;
  try {
    const res = await axios.post(URL, { idEzeItem, idAcl, sku, idUser, qtyC });
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("updateMissingItem", error);
  }
};

const saveWrongOrDefecItem = async ( idEzeItem, idAcl, sku, idUser, qtyE, price, iva, discount, name, action ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/clarifications/clarifications/wrongitem/save`;
  try {
    const res = await axios.post(URL, { idEzeItem, idAcl, sku, idUser, qtyE, price, iva, discount, name, action });
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("saveWrongOrDefecItem", error);
  }
};

const updateWrongOrDefecItem = async ( idEzeItem, idAcl, sku, idUser, action ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/clarifications/clarifications/wrongitem/save`;
  try {
    const res = await axios.post(URL, { idEzeItem, idAcl, sku, idUser, action });
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("updateWrongOrDefecItem", error);
  }
};

const saveClarificationSC = async ( increment_id, idAcl, idUser ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/clarifications/clarifications/stocre/save`;
  try {
    const res = await axios.post(URL, { increment_id, idAcl, idUser });
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("saveClarificationSC", error);
  }
};

const saveClarificationRefactura = async ( increment_id, idAcl, idUser, dBillA, dCity, dCty, dDel, dNumI, dNumO, dCP, dRfc, dSta, dStr, dSub ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/clarifications/clarifications/refact/save`;
  try {
    const res = await axios.post(URL, { increment_id, idAcl, idUser, dBillA, dCity, dCty, dDel, dNumI, dNumO, dCP, dRfc, dSta, dStr, dSub });
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("saveClarificationRefactura", error);
  }
};

const saveClarificationCC = async ( idAcl, idUser, key, incidence ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/clarifications/clarifications/datacc/save`;
  try {
    const res = await axios.post(URL, { idAcl, idUser, key, incidence });
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("saveClarificationCC", error);
  }
};

const updateClarificationCC = async ( idAcl, idUser, resolution ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/clarifications/clarifications/datacc/update`;
  try {
    const res = await axios.post(URL, { idAcl, idUser, resolution });
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("updateClarificationCC", error);
  }
};

const saveClarificationKey = async ( idAcl, idUser, idStatus ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/clarifications/clarifications/key/save`;
  try {
    const res = await axios.post(URL, { idAcl, idUser, idStatus });
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("saveClarificationKey", error);
  }
};

const generateClarificationGuide = async ( idAcl, idUser, increment_id, idCarrier, addData ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/clarifications/clarifications/guide/gen`;
  try {
    const res = await axios.post(URL, { idAcl, idUser, increment_id, idCarrier, addData });
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("generateClarificationGuide", error);
  }
};

const uploadClarificationFile = async (idAcl, files) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/clarifications/clarifications/files/upload`;
  try {
    const formData = new FormData();
    formData.append("idAcl", idAcl);
    formData.append("files", files);

    const res = await axios({
      method: "POST",
      url: URL,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data;",
      },
    });

    if (!res?.data?.success) {
      throw new Error("uploadClarificationFile:", "Error al enviar los archivos");
    }

    return res.data?.success ? true : false;
  } catch (error) {
    console.error("uploadClarificationFile", error);
  }
};

/**
 * EXCEL EXPORT
 **/

const getExcelClarifications = async (idProfile, chipLabels) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/clarifications/clarifications/all/new?page=All&idProfile=${idProfile}`;
  try {
    let res = await axios(URL);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        "Fecha": new Date(element.creation_date).toLocaleDateString(
          "es-mx",
          {
            timeZone: "UTC",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
          }
        ),
        "CRM": element.is_crm,
        "Días Transcurridos": parseInt( (new Date() - new Date(element.creation_date)) / (1000 * 60 * 60 * 24), 10),
        "Orden": element.id_orden,
        "Creado por": `${element.user_nombre} ${element.user_apellido}`,
        "Tipificación": element.tipificacion,
        "Estatus": chipLabels[element.status_acl_id],
        "Última Modificación": element.UltimaModif,
        "Fecha de última modificación": element.fechaModif ? new Date(element.fechaModif).toLocaleDateString(
          "es-mx",
          {
            timeZone: "UTC",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
          }
        ) : "",
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getAclaracionById = () => {}

export {
  getAclaracionById,
  getClarifications, searchClarifications, getClarificationsTypes, getExcelClarifications,
  getClarificationDetail, getClarificationMoves, getClarificationMessages, getClarificationFiles,
  getClarificationAddresses, getClarificationItems, getClarificationCarriers,
  saveClarification, saveClarificationMessage, saveClarificationDetails,
  saveClarificationRefund, deleteMissingItem, saveMissingItem, updateMissingItem, saveWrongOrDefecItem, 
  updateWrongOrDefecItem, uploadClarificationFile, saveClarificationSC, saveClarificationRefactura,
  saveClarificationCC, updateClarificationCC, saveClarificationKey, generateClarificationGuide, 
};
