import { Box, Grid, Button, Stack, Paper, Typography, TextField, Table, TableBody, TableCell, TableHead, TableRow, Accordion, AccordionSummary, AccordionDetails, Card, CardContent, InputLabel,  Select,  FormControl, Menu, MenuItem, Chip } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import DateInTable from "../../general/DateInTable";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ExcelFile from "../../general/ExcelFile";
import { getExceptionsIntList, searchExceptionsIntList, searchDetailExceptions, getExcelExcepciones, closeExcep } from "../../../utils/trazabilidad/excepciones/excepciones";

function DetailPanelContent({ row: rowProp }) {
    return (
        <Stack sx={{ py: 2, height: '100%', boxSizing: 'border-box' }} direction="column" >
            <Paper sx={{ flex: 1, mx: 'auto', width: '90%', p: 1 }}>
                <Stack direction="column" spacing={1} sx={{ height: 1 }}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ textAlign: 'center' }}>Tipificación</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}># de reporte</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>Días transcurridos</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{ border: 0 }} >
                                <TableCell sx={{ width: '40%', textAlign: 'center' }}>{rowProp.tipo}</TableCell>
                                <TableCell sx={{ width: '40%', textAlign: 'center' }}>{rowProp.num_reporte}</TableCell>
                                <TableCell sx={{ width: '20%', textAlign: 'center' }}>{rowProp.dias_transcurridos}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Stack>
            </Paper>
        </Stack>
    );
}

const chipColors = {
    "1": "#3c8dbc",
    "2": "#00a65a",
    "3": "#dd4b39",
    "4": "#f39c12",
    "5": "#777777",
    "6": "#00c0ef"
};

const chipLabels = {
    "1": "Nueva",
    "2": "Resuelta",
    "3": "Cerrada",
    "4": "Abierta",
    "5": "En proceso",
    "6": "Info"
};

const ExcepcionesIDataTable = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [keyWord, setKeytoSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isELoading, setIsELoading] = useState(false);
    const [excelData, setExcelData] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(null);
    const [isAF, setIsAF] = useState(false);
    const [advancedS, setAdvancedS] = useState({
        orden: '',
        guia: '',
        reporte: '',
        desde: '',
        hasta: '',
        groupedselect: 0
    });

    const handleChangeAdvancedS = (event) => {    
        let name = event.target.name;
        let value = event.target.value;
        setAdvancedS((prevalue) => {
          return {
            ...prevalue,
            [name]: value
          }
        });
    };

    const handleClick = (event, id) => {
        setAnchorEl(event.currentTarget);
        setOpen(id);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(null)
    };

    const handleCloseExcep = async ( id ) => {
        try {
          setIsLoading(true);
          await closeExcep(id);
          setKeytoSearch("1");
          setKeytoSearch("");
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
    };

    const getAllExcelData = async () => {
        try {
            setIsELoading(true);
            const data = await getExcelExcepciones(user.data.awsid, 'internal');
            setExcelData(data);
        } catch (error) {
            console.error(error);
        } finally {
            setIsELoading(false);
        }
    };

    const columns = [
        { field: "id", headerName: "No. de excepción", flex: 1, align: "center", headerAlign: "center", renderCell: (params) => (
            <Chip label={params.row.id} variant="outlined" size="small" />
        )},
        { field: "fecha_reg", headerName: "Fecha de registro", flex: 1, renderCell: (params) => (
            <Typography><DateInTable dateString={params.row.fecha_reg} /></Typography>
        )},
        { field: "guia_tx", headerName: "Guía", flex: 1, align: "center", headerAlign: "center" },
        { field: "guia_st", headerName: "Estatus Guía", flex: 1, align: "center", headerAlign: "center" },
        { field: "id_order", headerName: "Orden", flex: 1, align: "center", headerAlign: "center" },
        { field: "fecha_embarque", headerName: "Fecha de embarque", flex: 1, renderCell: (params) => (
            <Typography><DateInTable dateString={params.row.fecha_embarque} /></Typography>
        )},
        { field: "carrier", headerName: "Carrier", flex: 1, align: "center", headerAlign: "center" },
        { field: "tipo", headerName: "Tipificación", flex: 1, hide: true },
        { field: "num_reporte", headerName: "# de reporte", flex: 1, hide: true },
        { field: "estado", headerName: "Estatus", flex: 1, align: "center", headerAlign: "center", renderCell: (params) => (
            <Chip label={chipLabels[params.row.estado]} size="small" sx={{ backgroundColor: chipColors[params.row.estado], color: "#fff", }} />
        )},
        { field: "dias_transcurridos", headerName: "Días transcurridos", flex: 1, hide: true },
        { field: "accion", headerName: "Acciones", flex: 1, align: "center", headerAlign: "center", sortable: false, renderCell: (params) => (
        <>
            <Button variant="contained" size="small" endIcon={<KeyboardArrowDownIcon />} disableElevation aria-controls={open ? this : undefined} aria-haspopup="menu" onClick={(event) => handleClick(event, params.row.id)}>Acción</Button>
            <Menu anchorEl={anchorEl} open={open === params.row.id ? true : false} onClose={handleClose}>
                <MenuItem onClick={() => navigate(`detail/${params.row.id}`)}>Ver detalle</MenuItem>
                {params.row.estado === 2 && (<MenuItem onClick={() => handleCloseExcep(params.row.id)}>Cerrar</MenuItem>)}
            </Menu>
        </>
        )},
    ];

    if(document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"))
        document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']").remove();

    useEffect(() => {

        let active = true;

        (async () => {

            let newRows = null;

            if(isAF){
                setLoading(true);
                newRows = await searchDetailExceptions(page, user.data.awsid, advancedS, 'internal');
            }
            else if(typeof keyWord === 'undefined' || keyWord === undefined || keyWord.length === 0){
                setLoading(true);
                newRows = await getExceptionsIntList(page, user.data.awsid);
            }   
            else if(keyWord.length >= 3)
            {
                setLoading(true);
                newRows = await searchExceptionsIntList(page, keyWord, user.data.awsid);
            }
            else active = false;

            if (!active) {
                return;
            }
            setTotal(newRows.total);
            setRows(newRows.data);
            setLoading(false);
        })();

        return () => {
            active = false;
        };

    }, [page, keyWord, isAF, advancedS]);

    return (
        <Box>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography>Búsqueda avanzada</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Card>
                        <CardContent>
                            <Stack sx={{m: 2}} direction="row" spacing={3} autoComplete="off">
                                <TextField fullWidth name="orden" label="No. de Orden" variant="outlined" value={advancedS.orden} onChange={handleChangeAdvancedS} />
                                <TextField fullWidth name="guia" label="Guía" variant="outlined" value={advancedS.guia} onChange={handleChangeAdvancedS} />
                                <TextField fullWidth name="reporte" label="Número de reporte" variant="outlined" value={advancedS.reporte} onChange={handleChangeAdvancedS} />
                            </Stack>
                            <Stack sx={{m: 2}} direction="row" spacing={3} autoComplete="off">
                                <TextField InputLabelProps={{ shrink: true }} fullWidth type={"date"} name="desde" label="Desde" variant="outlined" value={advancedS.desde} onChange={handleChangeAdvancedS} />
                                <TextField InputLabelProps={{ shrink: true }} fullWidth type={"date"} name="hasta" label="Hasta" variant="outlined" value={advancedS.hasta} onChange={handleChangeAdvancedS} />
                                <FormControl fullWidth sx={{mb: 2}}>
                                <InputLabel id="selecte" htmlFor="groupedselect">Carrier</InputLabel>
                                <Select id="groupedselect" name="groupedselect" label="Carrier" value={advancedS.groupedselect} onChange={handleChangeAdvancedS}>
                                    <MenuItem value={0}>Todos</MenuItem>
                                    <MenuItem value={1}>Estafeta</MenuItem>
                                    <MenuItem value={2}>Fedex</MenuItem>
                                    <MenuItem value={3}>DHL</MenuItem>
                                </Select>
                                </FormControl>
                            </Stack>
                            <div style={{ textAlign: 'right', marginTop: '2em' }}>
                                <LoadingButton startIcon={<SearchIcon />} sx={{ mx: 2}} loading={isLoading} size="medium" variant="contained" color="success" onClick={() => setIsAF(true)}>Consultar</LoadingButton>
                            </div>
                        </CardContent>
                    </Card>
                </AccordionDetails>
            </Accordion>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Grid container sx={{ my:2 }}>
                    <Grid item md={4} sx={{ px:2 }}>
                        <Stack direction="row" spacing={1}>
                            <TextField size="small" variant="outlined" fullWidth label={'Buscar Excepción'} value={keyWord} onChange={(e) => {setIsAF(false); setKeytoSearch(e.target.value);}} />
                        </Stack>
                    </Grid>
                    <Grid item md sx={{ px:2, textAlign: 'right' }}>
                        {!excelData && <LoadingButton loading={isELoading} variant="contained" color="info" onClick={getAllExcelData}>Generar excel</LoadingButton>}
                        {excelData && (<ExcelFile excelData={excelData} fileName={'ExcepcionesInternas'} />)}
                    </Grid>
                </Grid>
            </Box>
            <Box style={{ height: 635, width: '100%' }}>
                <DataGridPro
                    rows={rows}
                    columns={columns}
                    pagination
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    getRowId={((row) => (row.id))}
                    rowCount={total}
                    paginationMode="server"
                    onPageChange={(newPage) => {
                    setPage(newPage);
                    }}
                    loading={loading}
                    disableColumnMenu
                    disableSelectionOnClick
                    getDetailPanelHeight={() => 'auto'}
                    getDetailPanelContent={({ row }) => (<DetailPanelContent row={row} />)}
                />
            </Box>
        </Box>
    );
};

export default ExcepcionesIDataTable;