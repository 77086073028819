import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../../context/AuthContext";
import {
  Box,
  Button,
  Card,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import BreadCrumb from "../../../general/BreadCrumb";
import { motion } from "framer-motion";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import { useState } from "react";
import { useEffect } from "react";
import { profiles } from "../../../../utils/ventaMayoreo/profilesVentaMayoreo";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  getProducts,
  getTransfersById,
  searchProducts,
} from "../../../../utils/ventaMayoreo/traspasos/traspasos";
import { InfoTraspaso } from "./InfoTraspaso";

const itemsBreadCrumbs = [
  {
    label: "Venta mayoreo",
    icon: <ContentPasteSearchIcon sx={{ mr: 0.5 }} fontSize="small" />,
    link: "/venta-mayoreo",
  },
  { label: "Traspasos", link: "/venta-mayoreo/traspasos" },
  { label: "Ver" },
];

export const DetalleTraspaso = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;
  const [isLoading, setIsLoading] = useState(false);
  const [inforRCE, setInforRCE] = useState(null);
  const [estados, setEstados] = useState([]);
  const [openM, setOpenM] = useState(false);

  const [traspaso, setTraspaso] = useState(null);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [keyWord, setKeytoSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [reloadPage, setReloadPage] = useState(0);

  useEffect(() => {
    if (!profiles.traspasos.includes(auth.user.role.idProfile)) {
      navigate("/dashboard", { replace: true });
    }
  }, [id, auth.user.data.awsid]);

  const columns = [
    {
      field: "ordenVenta",
      headerName: "Orden de venta",
      flex: 0.7,
      align: "center",
      headerAlign: "center",
      valueGetter: ({ row }) => encodeUtf8(row.orden_venta),
    },
    {
      field: "producto",
      headerName: "Producto",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: ({ row }) => encodeUtf8(row.nombre_producto),
    },
    {
      field: "sku",
      headerName: "SKU",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: ({ row }) => encodeUtf8(row.sku),
    },
    {
      field: "almacen",
      headerName: "Almacen",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: ({ row }) =>
        row.nombre_almacen ? encodeUtf8(row.nombre_almacen) : "S/almacen",
    },
  ];

  if (
    document.querySelector(
      ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
    )
  )
    document
      .querySelector(
        ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
      )
      .remove();

  useEffect(() => {
    let active = true;
    setLoading(true);
    setRows([]);
    setInforRCE(null);

    (async () => {
      let newRows = null;
      const traspasoD = await getTransfersById(id);
      setTraspaso(traspasoD.data);

      if (
        typeof keyWord === "undefined" ||
        keyWord === undefined ||
        keyWord.length === 0
      ) {
        setLoading(true);
        newRows = await getProducts(id, page);
      } else if (keyWord.length >= 3) {
        setLoading(true);
        newRows = await searchProducts(id, page, keyWord);
      } else active = false;

      if (!active) {
        return;
      }
      setTotal(newRows.total);
      setRows(newRows.data);
      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [page, keyWord, reloadPage]);

  const handleCloseM = () => {
    setOpenM(false);
  };

  const reloadPageFn = () => {
    setReloadPage((prevState) => (prevState === 0 ? 1 : 0));
  };

  function encodeUtf8(val) {
    try {
      return decodeURIComponent(escape(val));
    } catch (err) {
      return val;
    }
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" sx={{ my: 2 }}>
          Detalle
        </Typography>
        <Box>
          <BreadCrumb items={itemsBreadCrumbs} />
        </Box>
      </Box>

      <motion.div
        initial={{ y: 20 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Card>
          <Box>
            <Box sx={{ margin: "8px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={3.5}>
                  <Box>
                    <InfoTraspaso traspaso={traspaso} id={id} reloadPageFn={reloadPageFn} />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={8.5}>
                  <Card>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Grid container sx={{ mb: 2, mt: 2 }}>
                        <Grid item md={4} sx={{ px: 2 }}>
                          <Stack direction="row" spacing={1}>
                            <TextField
                              size="small"
                              variant="outlined"
                              fullWidth
                              label={`Buscar`}
                              value={keyWord}
                              onChange={(e) => setKeytoSearch(e.target.value)}
                            />
                          </Stack>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box style={{ height: 635, width: "100%" }}>
                      <DataGridPro
                        rows={rows}
                        columns={columns}
                        pagination
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        // getRowHeight={() => "auto"}
                        getRowId={(row) => row.id_seguimiento_producto}
                        rowCount={total}
                        paginationMode="server"
                        onPageChange={(newPage) => {
                          setPage(newPage);
                        }}
                        loading={loading}
                        disableColumnMenu
                        disableSelectionOnClick
                        getDetailPanelHeight={() => "auto"}
                      />
                    </Box>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Card>
      </motion.div>

      <Box>&nbsp;</Box>
    </>
  );
};
