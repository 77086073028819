import axios from "axios";

/**
 * GET
 **/

const getSeasons = async (currentPage) => {
  const URL = `${process.env.REACT_APP_BASEURL}/vales/configuracion/all/new?page=${currentPage}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const searchSeasons = async (currentPage, search) => {
  const URL = `${process.env.REACT_APP_BASEURL}/vales/configuracion/seasons/search?page=${currentPage}&search=${search}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getSeasonId = async (idSeason) => {
  const URL = `${process.env.REACT_APP_BASEURL}/vales/configuracion/season/search?idSeason=${idSeason}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getUserRH = async (currentPage) => {
  const URL = `${process.env.REACT_APP_BASEURL}/vales/configuracion/all/rh?page=${currentPage}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const searchUserRH = async (currentPage, search) => {
  const URL = `${process.env.REACT_APP_BASEURL}/vales/configuracion/usersrh/search?page=${currentPage}&search=${search}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getUserRHId = async (idUser) => {
  const URL = `${process.env.REACT_APP_BASEURL}/vales/configuracion/userrh/search?idUser=${idUser}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const validateMailRH = async (mailUser) => {
  const URL = `${process.env.REACT_APP_BASEURL}/vales/configuracion/userrh/validate?mailUser=${mailUser}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

/**
 * POST
 **/

const createSeason = async (details) => {
  const URL = `${process.env.REACT_APP_BASEURL}/vales/configuracion/season/create`;
  try {
    const res = await axios.post(URL, details);
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("createSeason", error);
  }
};

const updateSeason = async (details) => {
  const URL = `${process.env.REACT_APP_BASEURL}/vales/configuracion/season/update`;
  try {
    const res = await axios.post(URL, details);
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("updateSeason", error);
  }
};

const deleteSeason = async (details) => {
  const URL = `${process.env.REACT_APP_BASEURL}/vales/configuracion/season/delete`;
  try {
    const res = await axios.post(URL, details);
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("deleteSeason", error);
  }
};

const createUserRH = async (details) => {
  const URL = `${process.env.REACT_APP_BASEURL}/vales/configuracion/userrh/create`;
  try {
    const res = await axios.post(URL, details);
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("createUserRH", error);
  }
};

const updateUserRH = async (details) => {
  const URL = `${process.env.REACT_APP_BASEURL}/vales/configuracion/userrh/update`;
  try {
    const res = await axios.post(URL, details);
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("updateUserRH", error);
  }
};

const updateSatatusUserRH = async (details) => {
  const URL = `${process.env.REACT_APP_BASEURL}/vales/configuracion/userrh/updatestatus`;
  try {
    const res = await axios.post(URL, details);
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("updateSatatusUserRH", error);
  }
};

const deleteSatatusUserRH = async (details) => {
  const URL = `${process.env.REACT_APP_BASEURL}/vales/configuracion/userrh/delete`;
  try {
    const res = await axios.post(URL, details);
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("deleteSatatusUserRH", error);
  }
};

export {
  getSeasons,
  searchSeasons,
  getUserRH,
  searchUserRH,
  updateSatatusUserRH,
  createUserRH,
  deleteSatatusUserRH,
  getUserRHId,
  updateUserRH,
  createSeason,
  getSeasonId,
  updateSeason,
  deleteSeason,
  validateMailRH
};
