import {
  Box,
  Grid,
  Button,
  Stack,
  TextField,
  Menu,
  MenuItem,
  Typography,
  List,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { useAuth } from "../../../context/AuthContext";
import ModalEliminar from "./ModalEliminar";
import {
  addSchool,
  deleteCoordinator,
  deleteSchool,
  getCoordinator,
  getCoordinatorsIndex,
  getSchools,
  getSchoolsList,
  updateStatusCoord,
} from "../../../utils/vales/coordinadores/coordinadores";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ListItemText from "@mui/material/ListItemText";
import Switch from "@mui/material/Switch";
import ModalAddCoordinators from "./ModalAddCoordinators";
import ModalEditSchools from "./ModalEditSchools";
import ModalEditCoordinator from "./ModalEditCoordinator";

const label = { inputProps: { "aria-label": "Switch demo" } };

const CoordinadoresDataTable = () => {
  const { user } = useAuth();
  const [rows, setRows] = useState([]);
  const [keyWord, setKeytoSearch] = useState("");
  const [searchResults, setSearchResults] = useState(rows);
  const [reload, setReload] = useState(0);
  const [schools, setSchools] = useState([]);
  const [selectedSchoolId, setSelectedSchoolId] = useState("");
  const [coordinators, setCoordinators] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const [idUser, setIdUser] = useState(null);
  const [coordinator, setCoordinator] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(null);

  const [loading, setLoading] = useState(false);

  const [openModalAC, setOpenModalAC] = useState(null);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);

  const handleOpenModalAC = () => {
    setOpenModalAC(true);
  };

  const handleCloseModalAC = () => {
    setOpenModalAC(false);
  };

  const [openModalED, setOpenModalED] = useState(null);

  const handleOpenModalED = () => {
    setOpenModalED(true);
  };

  const handleCloseModalED = () => {
    setOpenModalED(false);
  };

  const [openModalMC, setOpenModalMC] = useState(null);

  const handleOpenModalMC = () => {
    setOpenModalMC(true);
  };

  const handleCloseModalMC = () => {
    setOpenModalMC(false);
  };

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setOpen(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(null);
  };

  //Schools
  const handleSchoolChange = (event) => {
    setSelectedSchoolId(event.target.value);
  };

  const handleSave = () => {
    if (!selectedSchoolId) {
      console.log("No se ha seleccionado un colegio válido");
      return;
    }

    const selectedSchool = schools.find(
      (school) => school.school_id === selectedSchoolId
    );
    const schoolName = selectedSchool ? selectedSchool.name : "";

    const newCoordinatorObj = {
      name: schoolName,
      school_id: selectedSchoolId,
    };

    if (
      !coordinators.some(
        (coordinator) => coordinator.school_id === selectedSchoolId
      )
    ) {
      setCoordinators([...coordinators, newCoordinatorObj]);
    }
  };

  //Status
  const handleStatusChange = async (id, newStatus) => {
    let data = {
      idUser: id,
      status: newStatus,
      updateUsr: user.data.awsid,
    };

    await updateStatusCoord(data);
    setReload((prevReload) => (prevReload === 0 ? 1 : 0));
    setRows([]);
  };

  //Modal Delete
  const handleOpenDeleteDialog = (id) => {
    setUserIdToDelete(id);
    setOpenDeleteDialog(true);
  };

  const handleConfirmDelete = async () => {
    let data = {
      id_user: userIdToDelete,
      updateUsr: user.data.awsid,
    };

    await deleteCoordinator(data);

    setOpenDeleteDialog(false);
    setRows([]);
    setReload((prevReload) => (prevReload === 0 ? 1 : 0));
  };

  const handleCloseDeleteDialog = () => {
    setUserIdToDelete(null);
    setOpenDeleteDialog(false);
  };

  //Modal EditCord
  const handleEditarCoordinatorClick = async (id_user) => {
    const coordinator = await getCoordinator(id_user);
    setCoordinator(coordinator.data);
    handleOpenModalED();
  };

  //Modal Schools
  const handleEditarColegiosClick = async (id_user) => {
    setIdUser(id_user);
    handleOpenModalMC(true);
    const schoolsList = await getSchoolsList(id_user);
    setSchoolList(schoolsList.data);
  };

  const handleSchoolChangeA = async () => {
    if (!selectedSchoolId) {
      console.log("No se ha seleccionado un colegio válido");
      return;
    }

    const isAlreadyInList = schoolList.some(
      (school) => school.school_id === selectedSchoolId
    );

    if (isAlreadyInList) {
      console.log("El colegio ya está en la lista");
      return;
    }

    let data = {
      idUser: idUser,
      idSchool: selectedSchoolId,
    };

    await addSchool(data);
    setSchoolList([]);
    const schoolsList = await getSchoolsList(idUser);
    setSchoolList(schoolsList.data);
  };

  const handleDeleteUser = async (idSchool) => {
    let data = {
      idUser: idUser,
      idSchool: idSchool,
    };

    await deleteSchool(data);
    setSchoolList([]);
    const schoolsList = await getSchoolsList(idUser);
    setSchoolList(schoolsList.data);
  };

  const columns = [
    {
      field: "name",
      headerName: "Nombre",
      flex: 1.8,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
          <Typography variant="subtitle1" align="center">
            {params.row.nombre +
              " " +
              params.row.apellidoP +
              " " +
              params.row.apellidoM}
          </Typography>
      ),
    },
    {
      field: "colegios",
      headerName: "Colegio(s)",
      flex: 2,
      align: "left",
      headerAlign: "center",
      renderCell: (params) => (
        <List>
          {params.row.schools.map(({ name }) => (
            <ListItemText key={name}>
              <Typography variant="subtitle1">- {name}</Typography>
            </ListItemText>
          ))}
        </List>
      ),
    },
    {
      field: "mail",
      headerName: "Mail",
      flex: 2,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "estatus",
      headerName: "Estatus",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          {params.row.status === 1 && (
            <>
              <Switch
                {...label}
                defaultChecked
                onChange={(e) =>
                  handleStatusChange(
                    params.row.id_user,
                    e.target.checked ? 1 : 0
                  )
                }
              />
            </>
          )}
          {params.row.status === 0 && (
            <>
              <Switch
                {...label}
                onChange={(e) =>
                  handleStatusChange(
                    params.row.id_user,
                    e.target.checked ? 1 : 0
                  )
                }
              />
            </>
          )}
        </>
      ),
    },
    {
      field: "accion",
      headerName: "Acción",
      flex: 0.8,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          <Button
            variant="contained"
            size="small"
            endIcon={<KeyboardArrowDownIcon />}
            disableElevation
            aria-controls={open ? this : undefined}
            aria-haspopup="menu"
            onClick={(event) => handleClick(event, params.row.id_user)}
          >
            Acción
          </Button>
          <Menu
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            anchorEl={anchorEl}
            open={open === params.row.id_user ? true : false}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                handleEditarCoordinatorClick(params.row.id_user);
                handleClose();
              }}
            >
              <Typography>Editar datos</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleEditarColegiosClick(params.row.id_user);
                handleClose();
              }}
            >
              <Typography>Editar colegios</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleOpenDeleteDialog(params.row.id_user);
                handleClose();
              }}
            >
              <Typography>Eliminar</Typography>
            </MenuItem>
          </Menu>
        </>
      ),
    },
  ];

  if (
    document.querySelector(
      ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
    )
  )
    document
      .querySelector(
        ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
      )
      .remove();

  useEffect(() => {
    (async () => {
      setLoading(true);
      let schools = await getSchools();
      setSchools(schools.data);
      const coordinadores = await getCoordinatorsIndex();
      setRows(coordinadores.data);
      setLoading(false);
    })();
  }, [user, reload]);

  const handleSearch = (event) => {
    const searchValue = event.target.value;
    setKeytoSearch(searchValue);

    if (!searchValue) {
      setSearchResults(rows);
      return;
    }

    const newFilteredRows = rows.filter((row) => {
      const fullName = `${row.nombre || ""} ${row.apellidoM || ""} ${
        row.apellidoP || ""
      }`.toLowerCase();
      const nameMatch = fullName.includes(searchValue);
      const mailMatch = row.mail
        ? row.mail.toLowerCase().includes(searchValue.toLowerCase())
        : false;
      const schoolsMatch = row.schools
        ? row.schools.some(
            (school) =>
              school.name &&
              school.name.toLowerCase().includes(searchValue.toLowerCase())
          )
        : false;

      return nameMatch || mailMatch || schoolsMatch;
    });

    setSearchResults(newFilteredRows);
  };

  return (
    <>
      <Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Grid container sx={{ my: 2 }}>
            <Grid item md={4} sx={{ px: 2 }}>
              <Grid>
                <Stack direction="row" spacing={1}>
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    label="Buscar..."
                    value={keyWord}
                    onChange={handleSearch}
                    disabled={loading}
                  />

                  <Typography
                    variant="h6"
                    sx={{ paddingLeft: 1, lineHeight: "2" }}
                  >
                    {searchResults.length > 0 && keyWord.length >= 1
                      ? searchResults.length
                      : null}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>

            <Grid item md sx={{ px: 2, textAlign: "right" }}>
              <LoadingButton
                variant="contained"
                color="info"
                onClick={handleOpenModalAC}
              >
                Agregar Coordinador
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
        <Box style={{ height: 635, width: "100%" }}>
          <DataGridPro
            rows={searchResults.length > 0 ? searchResults : rows}
            columns={columns}
            getRowHeight={() => "auto"}
            pagination
            pageSize={10}
            rowsPerPageOptions={[10]}
            getRowId={(row) => row.id_user}
            loading={loading}
            disableColumnMenu
            disableSelectionOnClick
          />
        </Box>
      </Box>

      <ModalAddCoordinators
        schools={schools}
        coordinators={coordinators}
        openModalAC={openModalAC}
        handleCloseModalAC={handleCloseModalAC}
        selectedSchoolId={selectedSchoolId}
        handleSchoolChange={handleSchoolChange}
        handleSave={handleSave}
        setCoordinators={setCoordinators}
        updateUsr={user.data.awsid}
        setRows={setRows}
        setReload={setReload}
      />

      <ModalEditCoordinator
        openModalED={openModalED}
        handleCloseModalED={handleCloseModalED}
        updateUsr={user.data.awsid}
        coordinator={coordinator}
        setRows={setRows}
        setReload={setReload}
      />

      <ModalEditSchools
        openModalMC={openModalMC}
        handleCloseModalMC={handleCloseModalMC}
        selectedSchoolId={selectedSchoolId}
        handleSchoolChange={handleSchoolChange}
        schools={schools}
        schoolList={schoolList}
        handleSchoolChangeA={handleSchoolChangeA}
        setReload={setReload}
        setRows={setRows}
        handleDeleteUser={handleDeleteUser}
      />

      <ModalEliminar
        openDeleteDialog={openDeleteDialog}
        handleCloseDeleteDialog={handleCloseDeleteDialog}
        handleConfirmDelete={handleConfirmDelete}
      />
    </>
  );
};

export default CoordinadoresDataTable;
