import {
  Box,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { getOrder } from "../../../../utils/ventaMayoreo/ordenesCompra/ordenesCompra";
import { useState } from "react";
import DateInTable from "../../../general/DateInTable";
import { CategoriaButton } from "./CategoriaButton";

const InfoOrdenCompra = ({ order }) => {

  function encodeUtf8(val) {
    try {
      return decodeURIComponent(escape(val));
    } catch (err) {
      return val;
    }
  }

  return (
    <Box
      sx={{
        border: "1px solid #dedede",
        borderTop: "3px solid #00a65a",
        margin: "10px",
        marginBottom: "25px",
        borderRadius: "5px",
      }}
    >
      <Box sx={{ borderBottom: "1px solid #dedede" }}>
        <Typography
          variant="h6"
          sx={{
            fontSize: "1.18rem",
            margin: "7px",
            marginLeft: "12px",
          }}
        >
          Orden de compra
        </Typography>
      </Box>
      <Box>
        {order ? (
          <Table size="small">
            <TableBody>
              <TableRow sx={{ backgroundColor: "#0000000a" }}>
                <TableCell align="left">
                  <Typography variant="subtitle2">Orden:</Typography>
                </TableCell>
                <TableCell align="right">
                  {order.orden_compra ? encodeUtf8(order.orden_compra) : ""}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  <Typography variant="subtitle2">Fecha de alta:</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography
                    component="div"
                    sx={{ h6: { fontWeight: "normal" } }}
                  >
                    <DateInTable dateString={order.fecha_orden} />
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow sx={{ backgroundColor: "#0000000a" }}>
                <TableCell align="left">
                  <Typography variant="subtitle2">Estatus:</Typography>
                </TableCell>
                <TableCell align="right">
                  <CategoriaButton categoria={order.nombre} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ) : (
          <Box sx={{ width: "100%", padding: "5px" }}>
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton animation={false} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default InfoOrdenCompra;
