import { Delete } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { addCoordinator } from "../../../utils/vales/coordinadores/coordinadores";

const ModalAddCoordinators = ({
  schools,
  coordinators,
  openModalAC,
  handleCloseModalAC,
  selectedSchoolId,
  handleSchoolChange,
  handleSave,
  setCoordinators,
  updateUsr,
  setRows,
  setReload,
}) => {
  const [nombre, setNombre] = useState("");
  const [nombreError, setNombreError] = useState(false);
  const [primerApellido, setPrimerApellido] = useState("");
  const [primerApellidoError, setPrimerApellidoError] = useState(false);
  const [segundoApellido, setSegundoApellido] = useState("");
  const [segundoApellidoError, setSegundoApellidoError] = useState(false);
  const [mail, setMail] = useState("");
  const [mailEmptyError, setMailEmptyError] = useState(false);
  const [mailFormatError, setMailFormatError] = useState(false);
  const [contrasena, setContrasena] = useState("");
  const [contrasenaError, setContrasenaError] = useState(false);

  const validateFields = () => {
    let isValid = true;

    if (!nombre) {
      setNombreError(true);
      isValid = false;
    } else {
      setNombreError(false);
    }

    if (!primerApellido) {
      setPrimerApellidoError(true);
      isValid = false;
    } else {
      setPrimerApellidoError(false);
    }

    if (!segundoApellido) {
      setSegundoApellidoError(true);
      isValid = false;
    } else {
      setSegundoApellidoError(false);
    }

    if (!mail) {
      setMailEmptyError(true);
      isValid = false;
    } else {
      setMailEmptyError(false);
    }

    if (mail && !isValidEmail(mail)) {
      setMailFormatError(true);
      isValid = false;
    } else {
      setMailFormatError(false);
    }

    if (!contrasena) {
      setContrasenaError(true);
      isValid = false;
    } else {
      setContrasenaError(false);
    }

    if (coordinators.length < 1) {
      isValid = false;
    }

    return isValid;
  };

  const isValidEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return regex.test(email);
  };

  const handleGuardarClick = async () => {
    if (validateFields()) {
      let schoolsF = coordinators.map((coordinator) => coordinator.school_id);
      let usuario = {
        nombre: nombre,
        apellidoP: primerApellido,
        apellidoM: segundoApellido,
        mail: mail,
        pass: contrasena,
        schools: schoolsF,
        updateUsr: updateUsr,
      };

      handleCloseModalAC();
      await addCoordinator(usuario);
      handleClearModal();
      setRows([]);
      setReload((prevReload) => (prevReload === 0 ? 1 : 0));
    }
  };

  const handleClearModal = () => {
    setNombre("");
    setPrimerApellido("");
    setSegundoApellido("");
    setMail("");
    setContrasena("");
    setCoordinators([]);
  };

  return (
    <Dialog
      open={openModalAC}
      onClose={() => {
        handleCloseModalAC();
        handleClearModal();
      }}
    >
      <DialogTitle>
        <Typography>
          <strong>Agregar Coordinador</strong>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <List>
          <ListItem>
            <TextField
              label="Nombre"
              variant="outlined"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
              sx={{ width: "500px" }}
              error={nombreError}
              helperText={nombreError ? "Este campo es obligatorio" : ""}
            />
          </ListItem>
          <ListItem>
            <TextField
              label="Primer Apellido"
              variant="outlined"
              value={primerApellido}
              onChange={(e) => setPrimerApellido(e.target.value)}
              sx={{ width: "500px" }}
              error={primerApellidoError}
              helperText={
                primerApellidoError ? "Este campo es obligatorio" : ""
              }
            />
          </ListItem>
          <ListItem>
            <TextField
              label="Segundo Apellido"
              variant="outlined"
              value={segundoApellido}
              onChange={(e) => setSegundoApellido(e.target.value)}
              sx={{ width: "500px" }}
              error={segundoApellidoError}
              helperText={
                segundoApellidoError ? "Este campo es obligatorio" : ""
              }
            />
          </ListItem>
          <ListItem>
            <TextField
              label="Mail"
              variant="outlined"
              value={mail}
              onChange={(e) => setMail(e.target.value)}
              sx={{ width: "500px" }}
              error={mailEmptyError || mailFormatError}
              helperText={
                mailEmptyError
                  ? "Este campo es obligatorio"
                  : mailFormatError
                  ? "Ingrese un email válido"
                  : ""
              }
            />
          </ListItem>
          <ListItem>
            <TextField
              label="Contraseña"
              type="password"
              variant="outlined"
              value={contrasena}
              onChange={(e) => setContrasena(e.target.value)}
              sx={{ width: "500px" }}
              error={contrasenaError}
              helperText={contrasenaError ? "Este campo es obligatorio" : ""}
            />
          </ListItem>

          <ListItem>
            <Select
              label="Escuela"
              variant="outlined"
              value={selectedSchoolId}
              onChange={handleSchoolChange}
              sx={{ width: "500px" }}
            >
              {schools.map((school) => (
                <MenuItem key={school.school_id} value={school.school_id}>
                  {school.name}
                </MenuItem>
              ))}
            </Select>
          </ListItem>

          <ListItem>
            <Button variant="contained" onClick={handleSave}>
              Agregar Colegio
            </Button>
          </ListItem>

          <List>
            {coordinators.map((coordinator) => (
              <ListItem
                key={coordinator.school_id}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={(e) => {
                      const updatedCoordinators = coordinators.filter(
                        (objeto) => objeto.school_id !== coordinator.school_id
                      );
                      setCoordinators(updatedCoordinators);
                    }}
                  >
                    <Delete />
                  </IconButton>
                }
                disablePadding
              >
                <ListItemButton dense>
                  <ListItemText primary={coordinator.name} />
                </ListItemButton>
              </ListItem>
            ))}
            {coordinators.length < 1 && (
              <FormHelperText error>
                Es necesario agregar al menos un colegio
              </FormHelperText>
            )}
          </List>
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            handleCloseModalAC();
            handleClearModal();
          }}
        >
          Cerrar
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={handleGuardarClick}
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalAddCoordinators;
