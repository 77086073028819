import { createContext, useContext, useState } from "react";

const TabsContext = createContext();

const TabsProvider = ({ children }) => {
  const [crmTab, setCrmTab] = useState(0);

  return (
    <TabsContext.Provider value={{ crmTab, setCrmTab }}>
      {children}
    </TabsContext.Provider>
  );
};

const useTabs = () => useContext(TabsContext);

export { TabsProvider, useTabs };
