import {
  CircularProgress,
  Box,
  Button,
  Card,
  Typography,
  Grid,
  IconButton,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import SearchIcon from "@mui/icons-material/Search";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import { profiles } from "../../../utils/trazabilidad/profilesTrazabilidad";
import {
  getOrderData,
  getOrderGuides,
  addVerif,
  getVerifications,
  getVerificationsExcel,
} from "../../../utils/trazabilidad/verificaciones/verificaciones";
import DateInTable from "../../general/DateInTable";
import VerificacionesDataTable from "./VerificacionesDataTable";
import BreadCrumb from "../../general/BreadCrumb";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import ExcelFile from "../../general/ExcelFile";

const itemsBreadCrumbs = [
  {
    label: "Trazabilidad",
    icon: <ContentPasteSearchIcon sx={{ mr: 0.5 }} fontSize="small" />,
    link: "/trazabilidad/inicio",
  },
  { label: "Verificaciones" },
];

const Verificaciones = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [nextRender, setNextRender] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [order, setOrder] = useState("");
  const [guia, setGuia] = useState("");
  const [data, setData] = useState(null);
  const [guias, setGuias] = useState([]);
  const [isGeneratingExcel, setIsGeneratingExcel] = useState(false);
  const [verifications, setVerifications] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeOrder = (event) => {
    setOrder(event.target.value);
  };

  const handleChangeGuia = (event) => {
    setGuia(event.target.value);
  };

  const handleSaveSC = async () => {
    try {
      setIsLoading(true);
      if (data && data.increment_id && guia) {
        await addVerif(data.increment_id, user.data.awsid, guia);
        refreshPage();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const searchData = async () => {
    try {
      setIsLoading(true);
      setData(null);
      setGuia("");
      setGuias([]);
      const resp = await getOrderData(order);
      setData(resp);
      const respo = await getOrderGuides(order);
      setGuia(respo[0].id_tz_guias);
      setGuias(respo);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const refreshPage = () => {
    const current = location.pathname;
    navigate(`/reload`, { replace: true });
    setTimeout(() => {
      navigate(current, { replace: true });
    }, 1000);
  };

  const handleGenerateExcelWithAllVerifications = async () => {
    try {
      setIsGeneratingExcel(true);
      const vs = await getVerificationsExcel();
      setVerifications(vs);
      console.log(vs);
    } catch (error) {
      console.error(error);
    } finally {
      setIsGeneratingExcel(false);
    }
  };

  useEffect(() => {
    if (!profiles.verificaciones.includes(user.role.idProfile)) {
      navigate("/dashboard", { replace: true });
    } else setNextRender(true);
  }, []);

  return (
    <>
      {nextRender && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h4" sx={{ my: 2 }}>
              Verificaciones
            </Typography>
            <Box>
              <BreadCrumb items={itemsBreadCrumbs} />
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "end", mb: 2 }}>
            {verifications.length > 0 ? (
              <ExcelFile
                excelData={verifications}
                fileName={`verificaciones-${new Date().toLocaleDateString()}`}
              />
            ) : (
              <LoadingButton
                variant="contained"
                color="info"
                onClick={handleGenerateExcelWithAllVerifications}
                loading={isGeneratingExcel}
              >
                Excel
              </LoadingButton>
            )}
            <Button
              variant="contained"
              color="success"
              startIcon={<AddIcon />}
              onClick={handleClickOpen}
              sx={{ ml: 2 }}
            >
              Crear verificación
            </Button>
          </Box>

          <motion.div
            initial={{ y: 20 }}
            animate={{ y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Card>
              <Box>
                <VerificacionesDataTable />
              </Box>
            </Card>
          </motion.div>

          <Dialog
            open={open}
            fullWidth={true}
            maxWidth="md"
            onClose={handleClose}
          >
            <DialogTitle>Nueva verificación</DialogTitle>
            <DialogContent>
              <Stack sx={{ mt: 2, mb: 2 }} direction="row" spacing={1}>
                <TextField
                  fullWidth
                  label="Número de pedido"
                  variant="outlined"
                  value={order}
                  onChange={handleChangeOrder}
                />
                <Button
                  variant="contained"
                  color="info"
                  startIcon={<SearchIcon />}
                  disabled={!order || order.length < 9}
                  onClick={searchData}
                >
                  Buscar
                </Button>
              </Stack>
              {isLoading && (!data || guias.length <= 0) && (
                <>
                  <Typography variant="p"> Cargando ...</Typography>{" "}
                  <CircularProgress />
                </>
              )}

              {data && (
                <Grid container>
                  <Grid item md={6}>
                    <List>
                      <ListItem>
                        <ListItemText
                          primary="Número de pedido"
                          secondary={data.increment_id}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary="Fecha de creación"
                          secondary={
                            <DateInTable dateString={data.created_at} />
                          }
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary="Fecha de pago"
                          secondary={
                            <DateInTable dateString={data.payment_date} />
                          }
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary="Escuela(s)"
                          secondary={data.school}
                        />
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid item md={6}>
                    <List>
                      <ListItem>
                        <ListItemText
                          primary="Nombre del cliente"
                          secondary={
                            data.customer_name +
                            " " +
                            data.customer_lastname +
                            " " +
                            data.customer_mothersname
                          }
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary="Teléfono"
                          secondary={data.telephone}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="Email" secondary={data.email} />
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
              )}

              {guias.length > 0 && (
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel id="selecte" htmlFor="groupedselect">
                    Guía
                  </InputLabel>
                  <Select
                    id="groupedselect"
                    name="groupedselect"
                    label="Guía"
                    value={guia}
                    onChange={handleChangeGuia}
                  >
                    {guias.map((row) => (
                      <MenuItem key={row.id_tz_guias} value={row.id_tz_guias}>
                        {row.guia} - {row.name_carrier} - {row.name_servicio}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancelar</Button>
              <LoadingButton
                loading={isLoading}
                variant="contained"
                color="info"
                startIcon={<SaveIcon />}
                disabled={!order || !guia}
                onClick={handleSaveSC}
              >
                Crear verificación
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
};

export default Verificaciones;
