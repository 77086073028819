import { Box, Grid, TextField, Button, IconButton, Stack, Divider, Chip, InputLabel, Select, FormControl, FormControlLabel, FormLabel, MenuItem, CircularProgress, Typography, Alert, Card, CardContent, Radio, RadioGroup, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAuth } from "../../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import UploadIcon from "@mui/icons-material/Upload";
import SaveIcon from '@mui/icons-material/Save';
import Toast from "../../../general/Toast";
import { saveClarificationRefund, uploadClarificationFile, getClarificationFiles, saveClarificationSC } from "../../../../utils/trazabilidad/aclaraciones/aclaraciones";
import { profilesAclaraciones } from "../../../../utils/trazabilidad/aclaraciones/profilesAclaraciones";

const ReembolsoTab = ({ aId, detalle }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [archivos, setArchivos] = useState([]);
  const [archivo, setArchivo] = useState(null);
  const [noCuen, setNoCuen] = useState("");
  const [bank, setBank] = useState("");
  const [titular, setTitular] = useState("");
  const [sucursal, setSucursal] = useState("");
  const [cuenta, setCuenta] = useState("");
  const [cnote, setCNote] = useState("");
  const [quanti, setQuanti] = useState(0);
  const [referenc, setReferenc] = useState(0);
  const [selectedFile, setSelectedFile] = useState([]);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [option, setOption] = useState(0);
  const [opena, setOpenA] = useState(null);
  const [confalert, setConfAlert] = useState({
    severidad: "success",
    mensaje: "",
  });

  const handleOpenA = (severidad, mensaje) => {
    setConfAlert({ severidad: severidad, mensaje: mensaje });
    setOpenA(true);
  };

  const handleCloseA = () => {
    setOpenA(false);
  };

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  const handleClose = () => {
    setNoCuen("");
    setBank("");
    setTitular("");
    setSucursal("");
    setCuenta("");
  };

  function isDriveLink(link){
    return link.includes('drive.google.com');
  }

  const handleSaveData = async () => {
    try {
      setIsLoading(true);
      let ifDebit,
        ifCredit = 0;
      if (cuenta === "debito") {
        ifDebit = 1;
        ifCredit = 0;
      } else if (cuenta === "credito") {
        ifDebit = 0;
        ifCredit = 1;
      }

      let resp = await saveClarificationRefund(detalle.id_orden, aId, user.data.awsid, ifDebit, ifCredit, noCuen, titular, bank, sucursal );
      if(resp){
        handleOpenA("success", "Reembolso generado");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
      else handleOpenA("error", "El reembolso no pudo ser generado, intente de nuevo más tarde");
      handleClose();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleCloseRefund = async () => {
    try {
      setIsLoading(true);
      //let resp = await saveClarificationRefund(detalle.id_orden, aId, user.data.awsid, ifDebit, ifCredit, noCuen, titular, bank, sucursal );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGenerateSC = async () => {
    try {
      setIsLoading(true);
      let resp = await saveClarificationSC(detalle.id_orden, aId, user.data.awsid);
      if(resp){
        handleOpenA("success", "Store Credit generado");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
      else handleOpenA("error", "El Store Credit no pudo ser generado, intente de nuevo más tarde");
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveSC = async () => {
    try {
      setIsLoading(true);
      //let resp = await saveClarificationRefund(detalle.id_orden, aId, user.data.awsid, ifDebit, ifCredit, noCuen, titular, bank, sucursal );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveFile = async () => {
    try {
      setIsLoading(true);
      let resp = await uploadClarificationFile(aId, selectedFile);
      if(resp){
        handleOpenA("success", "Archivo cargado con éxito");
        setSelectedFile([]);
        setIsFilePicked(false);
        setTimeout(() => {
          let au = refresh;
          setRefresh(au+1);
        }, 3000);
      }
      else handleOpenA("error", "El archivo no pudo ser cargado, intente de nuevo más tarde");
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const getFiles = async () => {
      try {
        if(detalle.if_storecredit === 0 && (detalle.status_acl === 4 || detalle.status_acl === 5 || detalle.status_acl === 6 || detalle.status_acl === 9))
        {
          setIsLoading(true);
          const resp = await getClarificationFiles(aId);
          setArchivos(resp);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    getFiles();
  }, [aId, refresh]);

  return (
  <>
    <Box sx={{ mb: 2, ml: 2, mr: 2 }}>
      {((detalle.status_acl === 1 || detalle.status_acl === 3) && profilesAclaraciones.editarReembolso.includes( user.role.idProfile )) && (
      <>
        <Grid container sx={{ my: 2 }}>
          <Grid item md={4}></Grid>
          <Grid item md={4}>
            <Alert variant="filled" severity="info" icon={false}>
              <Typography variant="p"><strong>Selecciona tipo de reembolso.</strong></Typography>
            </Alert>
            <Card>
              <CardContent>
                <FormControl fullWidth>
                  <InputLabel id="selecte" htmlFor="groupedselect">Seleccionar</InputLabel>
                  <Select id="groupedselect" label="Seleccionar" value={option} onChange={(e) => setOption(e.target.value)}>
                    <MenuItem value={0}>Reembolso</MenuItem>
                    <MenuItem value={1}>Store Credit</MenuItem>
                  </Select>
                </FormControl>

                {option === 1 && ( 
                  <LoadingButton sx={{ mt: 2 }} loading={isLoading} fullWidth size="medium" variant="contained" color="success" onClick={handleGenerateSC} >
                    Generar Store Credit
                  </LoadingButton>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={4}></Grid>
        </Grid>
      
        {option === 0 && (
          <Grid container sx={{ my: 2 }}>
            <Grid item md={3}></Grid>
            <Grid item md={6}>
              <Alert variant="filled" severity="info">
                <Typography variant="p">
                  <strong>Datos de reembolso.</strong>
                </Typography>
              </Alert>
              <Card>
                <CardContent>
                  <Grid container>
                    <Grid item md={2}></Grid>
                    <Grid item md={8} sx={{ mt: 2, mb: 2 }}>
                      <TextField fullWidth sx={{ mb: 2 }} id="noCuen" label="Número de cuenta" value={noCuen} onChange={(e) => setNoCuen(e.target.value)} type="number" />
                      <TextField fullWidth sx={{ mb: 2 }} id="bank" label="Banco" value={bank} onChange={(e) => setBank(e.target.value)} />
                      <TextField fullWidth sx={{ mb: 2 }} id="titular" label="Titular" value={titular} onChange={(e) => setTitular(e.target.value)} />
                      <TextField fullWidth sx={{ mb: 2 }} id="sucursal" label="Sucursal" value={sucursal} onChange={(e) => setSucursal(e.target.value)} />
                      <FormControl sx={{ mb: 2 }}>
                        <FormLabel id="tipo-cuenta">Tipo de cuenta</FormLabel>
                        <RadioGroup row aria-labelledby="tipo-cuenta" name="row-radio-buttons-group" value={cuenta} onChange={(e) => setCuenta(e.target.value)} >
                          <FormControlLabel value="debito" control={<Radio />} label="Tarjeta de débito" />
                          <FormControlLabel value="credito" control={<Radio />} label="Tarjeta de crédito" />
                        </RadioGroup>
                      </FormControl>
                      <LoadingButton loading={isLoading} fullWidth size="medium" variant="contained" color="success" onClick={handleSaveData}
                        disabled={ !noCuen || !bank || !titular || !sucursal || !cuenta } >
                        Generar reembolso
                      </LoadingButton>
                    </Grid>
                    <Grid item md={2}></Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={3}></Grid>
          </Grid>
        )}
      </>
      )}

      {(detalle.if_reembolso === 1 && detalle.status_acl !== 1 && detalle.status_acl !== 3 && profilesAclaraciones.editarReembolso.includes( user.role.idProfile )) && (
        <Grid container sx={{ my: 2 }}>
          <Grid item md={5}>
            <Alert variant="filled" severity="info" icon={false}>
              <Typography variant="p">
                <strong>Datos para reembolso</strong>
              </Typography>
            </Alert>
            <Card>
              <CardContent>
                <Typography variant="p" component="div">
                  Número de cuenta:{" "}
                  <strong>{detalle.bca_numero_cuenta}</strong>
                </Typography>
                <Typography variant="p" component="div">
                  Banco: <strong>{detalle.bca_banco}</strong>
                </Typography>
                <Typography variant="p" component="div">
                  Titular: <strong>{detalle.bca_titular}</strong>
                </Typography>
                <Typography variant="p" component="div">
                  Sucursal: <strong>{detalle.bca_sucursal}</strong>
                </Typography>
                <Typography variant="p" component="div">
                  Tipo de cuenta: <strong>{detalle.bca_if_debito ? "Tarjeta de débito" : detalle.bca_if_credito ? "Tarjeta de crédito": ""}</strong>
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {(detalle.if_storecredit === 0 && detalle.status_acl === 4 && profilesAclaraciones.subirArchivosReembolso.includes( user.role.idProfile )) && (
          <>
            <Grid item md></Grid>
            <Grid item md={5}>
              <Alert variant="filled" severity="info">
                <Typography variant="p"><strong>Subir archivos</strong></Typography>
              </Alert>
              <Card>
                <CardContent>
                  <Typography align="center" sx={{ mb: 2 }}>
                    {isFilePicked ? selectedFile?.name : "Ningún archivo seleccionado"}
                  </Typography>
                  <Button align="center" variant="contained" component="label" sx={{ mb: 4 }} endIcon={<UploadIcon />} >
                    {isFilePicked ? "Elegir otro archivo" : "Elegir archivo"}
                    <input hidden accept="image/*,.pdf,.xls,.xlsx" type="file" onChange={changeHandler} />
                  </Button>

                  <LoadingButton sx={{ mt: 2 }} loading={isLoading} fullWidth size="medium" variant="contained"
                    color="info" onClick={handleSaveFile} disabled={!isFilePicked} endIcon={<SaveIcon />}>
                    Guardar archivo
                  </LoadingButton>

                  <Divider sx={{ my: 4 }}>
                    <Chip label="o" />
                  </Divider>

                  <LoadingButton loading={isLoading} fullWidth size="medium" variant="contained" color="success" onClick={handleCloseRefund}>
                    Finalizar reembolso
                  </LoadingButton>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md></Grid>
          </>
          )}

          {(detalle.if_storecredit === 0 && (detalle.status_acl === 4 || detalle.status_acl === 5 || detalle.status_acl === 6 || detalle.status_acl === 9)) && (
            <>
              <Grid item md></Grid>            
              <Grid item md={5}>
                <Grid container sx={{ my : 2}}>
                  <Grid item md={12}>
                    <Card variant="outlined">
                      <CardContent>
                        <Typography variant="h6" component="div"><DescriptionIcon /> Archivos</Typography>
                        <Grid container sx={{ alignItems: "center" }}>
                          <Grid item md={12}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell sx={{ width: '80%'}}>Nombre</TableCell>
                                  <TableCell sx={{ width: '20%'}} align="right">Descargable</TableCell>
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                {archivos.length > 0 && archivos.map( (row) => (
                                  <TableRow key={row.id_ac_expedientes}>
                                    <TableCell component="th" scope="row">{row.nombre}</TableCell>
                                    <TableCell align="right">
                                      <IconButton loading={isLoading} color="primary" size="small">
                                        <a download href={isDriveLink(row.pdf) ? row.pdf : `data:application/${row.extension};base64,${row.pdf}` } style={{ textDecoration: "none", color: "#041C32" }} >
                                          <FileDownloadIcon />
                                        </a>
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md></Grid>
            </>
          )}
          
        </Grid>
      )}

      {(detalle.if_storecredit === 1 && detalle.status_acl === 4 && profilesAclaraciones.editarNumeroStoreCredit.includes( user.role.idProfile )) && (
        <Grid container sx={{ my: 2 }}>
          <Grid item md={3}></Grid>
          <Grid item md={6}>
            <Alert variant="filled" severity="info">
              <Typography variant="p"><strong>Store Credit</strong></Typography>
            </Alert>
            <Card>
              <CardContent>
                <Grid container>
                  <Grid item md={3}></Grid>
                  <Grid item md={6} sx={{ mt: 2, mb: 2 }}>
                    <TextField fullWidth sx={{ mb: 2 }} id="cnote" label="Nota de crédito" value={cnote} onChange={(e) => setCNote(e.target.value)} />
                    <TextField fullWidth sx={{ mb: 2 }} id="quanti" label="Cantidad" value={quanti} onChange={(e) => setQuanti(e.target.value)} type="number" />
                    <Typography sx={{ mb: 2 }} variant="h4" align="center" component="div">
                      ${quanti}
                    </Typography>
                    <LoadingButton loading={isLoading} fullWidth size="medium" variant="contained" color="success" onClick={handleSaveSC}
                      disabled={!cnote || !quanti || quanti < referenc} >
                      Finalizar Store Credit
                    </LoadingButton>
                  </Grid>
                  <Grid item md={3}></Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={3}></Grid>
        </Grid>
      )}
    </Box>

    <Toast open={opena} message={confalert.mensaje} type={confalert.severidad} onClose={handleCloseA} />
  </>
  );
};

export default ReembolsoTab;