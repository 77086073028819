import { Box, Button, Grid, Stack, TextField } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import React from "react";
import { useState } from "react";
import { getPurchaseOrders, searchPurchaseOrders } from "../../../../../utils/ventaMayoreo/ordenesVenta/ordenesVenta";
import { useEffect } from "react";

export const OrdenDeCompraTab = ({ ordenVenta }) => {
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [keyWord, setKeytoSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);

  const columns = [
    {
      field: "producto",
      headerName: "Producto",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: ({ row }) => row.nombre,
    },
    {
      field: "sku",
      headerName: "SKU",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: ({ row }) => row.sku,
    },
    {
      field: "cantidad",
      headerName: "Cantidad",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: ({ row }) => row.cantidad_disponible,
    },
    {
      field: "numeroDeOC",
      headerName: "Número de OC",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: ({ row }) =>
        row.orden_compra ? row.orden_compra : "S/Orden de compra",
    },
    {
      field: "statusOC",
      headerName: "Status OC",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => {
        const labelStatus =
          row.so_nombre === "Entrega completada" ? "success" : "warning";
        const displayText = row.so_nombre || "En espera de OC";

        return (
          <Button
            variant="contained"
            color={labelStatus}
            size="small"
            sx={{
              textTransform: "none",
              lineHeight: 1,
              fontSize: "0.7rem",
              minWidth: 0,
            }}
          >
            {displayText}
          </Button>
        );
      },
    },
    {
      field: "entregado",
      headerName: "Entregado",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => {
        const labelStatus = row.entregado === 1 ? "success" : "error";
        const displayText = row.entregado === 1 ? "Si" : "No";

        return (
          <Button
            variant="contained"
            color={labelStatus}
            size="small"
            sx={{
              textTransform: "none",
              lineHeight: 1,
              fontSize: "0.7rem",
              minWidth: 0,
            }}
          >
            {displayText}
          </Button>
        );
      },
    },
    {
      field: "entrada",
      headerName: "Entrada",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: ({ row }) =>
        row.num_entrada ? encodeUtf8(row.num_entrada) : "",
    },
  ];

  if (
    document.querySelector(
      ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
    )
  )
    document
      .querySelector(
        ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
      )
      .remove();

  useEffect(() => {
    let active = true;

    (async () => {
      let newRows = null;
      let schoolsD = [];

      if (
        typeof keyWord === "undefined" ||
        keyWord === undefined ||
        keyWord.length === 0
      ) {
        setLoading(true);
        newRows = await getPurchaseOrders(page, ordenVenta);
      } else if (keyWord.length >= 3) {
        setLoading(true);
        newRows = await searchPurchaseOrders(page, ordenVenta, keyWord);
      } else active = false;

      if (!active) {
        return;
      }
      setTotal(newRows.total);
      setRows(newRows.data);
      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [page, keyWord]);

  function encodeUtf8(val) {
    try {
      return decodeURIComponent(escape(val));
    } catch (err) {
      return val;
    }
  }

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Grid container sx={{ mb: 2, mt: 2 }}>
          <Grid item md={4}>
            <Stack direction="row" spacing={1}>
              <TextField
                size="small"
                variant="outlined"
                fullWidth
                label={`Buscar`}
                value={keyWord}
                onChange={(e) => setKeytoSearch(e.target.value)}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box style={{ height: 635, width: "100%" }}>
        <DataGridPro
          rows={rows}
          columns={columns}
          pagination
          pageSize={10}
          rowsPerPageOptions={[10]}
          getRowId={(row) => row.id_requerimiento_producto}
          rowCount={total}
          paginationMode="server"
          onPageChange={(newPage) => {
            setPage(newPage);
          }}
          //   onSelectionModelChange={(newSelectionModel) => {
          //     setSelectionModel(newSelectionModel);
          //   }}
          selectionModel={selectionModel}
          loading={loading}
          disableColumnMenu
          disableSelectionOnClick
          getDetailPanelHeight={() => "auto"}
        />
      </Box>
    </>
  );
};
