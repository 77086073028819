import { Box, Grid, Table, TableBody, TableCell, TableContainer, Typography , TableRow, CircularProgress, IconButton } from '@mui/material';
import { useEffect, useState, Fragment } from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import WarningIcon from '@mui/icons-material/Warning';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import EditIcon from '@mui/icons-material/Edit';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import CommentIcon from '@mui/icons-material/Comment';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import LockIcon from '@mui/icons-material/Lock';
import BuildIcon from '@mui/icons-material/Build';
import DateInTable from '../../../general/DateInTable';

import { getMovByRMA } from '../../../../utils/trazabilidad/rma/rma';

const chipIcons = {
    "1": <VisibilityIcon />,
    "2": <AttachMoneyIcon />,
    "3": <MailOutlineIcon />,
    "4": <MailOutlineIcon />,
    "5": <EditIcon />,
    "6": <CloudUploadIcon />,
    "7": <AttachMoneyIcon />,
    "8": <CreditCardIcon />,
    "9": <PersonAddAltIcon />,
    "10": <PersonAddAltIcon />,
    "11": <LocalShippingIcon />,
    "12": <CommentIcon />,
    "13": <AutorenewIcon />,
    "14": <LockIcon />,
    "15": <WarningIcon />,
    "16": <LocalShippingIcon />,
    "18": <WarningIcon />,
    "35": <EditIcon />,
    "63": <BuildIcon />,
    "64": <BuildIcon />,
};

const chipColors = {
    "1": "#605ca8",
    "2": "#00a65a",
    "3": "#0073b7",
    "4": "#0073b7",
    "5": "#f39c12",
    "6": "#d81b60",
    "7": "#00a65a",
    "8": "#39cccc",
    "9": "#f012be",
    "10": "#f012be",
    "11": "#00a65a",
    "12": "#605ca8",
    "13": "#d81b60",
    "14": "#dd4b39",
    "15": "#dd4b39",
    "16": "#00a65a",
    "18": "#dd4b39",
    "35": "#f39c12",
    "63": "#0097a7",
    "64": "#0097a7",
};

const MovimientosTab = ({ rmaId }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [detalle, setDetalle] = useState(null);

    useEffect(() => {
        const getDetallePedido = async () => {
            try {
                setIsLoading(true);
                const res = await getMovByRMA(rmaId);
                setDetalle(res);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };
        getDetallePedido();
    }, [rmaId]);

    return (
        <>            
            <Box sx={{mb: 2, ml: 2, mr: 2}}>
                {isLoading && <CircularProgress />}
                <Grid container sx={{mb: 2}}>
                    {detalle && (
                    <Grid item md={12}>
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }}>
                                <TableBody>
                                    {detalle.map((row) => (
                                    <TableRow key={row.id_rma_move}>
                                        <TableCell>
                                            <IconButton disableFocusRipple disableRipple sx={{backgroundColor: chipColors[row.id_move], borderRadius: 1, color: '#fff'}} >{chipIcons[row.id_move]}</IconButton>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="p" sx={{fontWeight: 700}}>{row.user_nombre + " " + row.user_apellido} </Typography>
                                            <Typography variant="p" dangerouslySetInnerHTML={{ __html: row.descripcion}}></Typography>
                                        </TableCell>
                                        <TableCell><DateInTable dateString={row.date} /></TableCell>
                                    </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    )}
                </Grid>
            </Box>
        </>
    );
};

export default MovimientosTab;