import {
  Box,
  Grid,
  TextField,
  Button,
  Stack,
  InputLabel,
  Select,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  CircularProgress,
  Typography,
  Alert,
  Card,
  CardContent,
  Radio,
  RadioGroup,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAuth } from "../../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import UploadIcon from "@mui/icons-material/Upload";
import SaveIcon from '@mui/icons-material/Save';
import {
  getSCRma,
  getFileRem,
  saveRefundData,
  uploadFiles,
  saveSCRefundData,
  saveCNandQ,
} from "../../../../utils/trazabilidad/rma/rma";

const ReembolsoTab = ({
  lista,
  rmaId,
  orderId,
  incrementId,
  status,
  clientEmail,
  clientName
}) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [articulos, setArticulos] = useState(null);
  const [detalle, setDetalle] = useState(null);
  const [archivo, setArchivo] = useState(null);
  const [noCuen, setNoCuen] = useState(null);
  const [bank, setBank] = useState(null);
  const [titular, setTitular] = useState(null);
  const [sucursal, setSucursal] = useState(null);
  const [cuenta, setCuenta] = useState(null);
  const [cnote, setCNote] = useState("");
  const [quanti, setQuanti] = useState(null);
  const [referenc, setReferenc] = useState(null);
  const [selectedFile, setSelectedFile] = useState([]);
  const [isFilePicked, setIsFilePicked] = useState(false);

  const handleChangeNC = (event) => {
    setNoCuen(event.target.value);
  };
  const handleChangeBk = (event) => {
    setBank(event.target.value);
  };
  const handleChangeTi = (event) => {
    setTitular(event.target.value);
  };
  const handleChangeSu = (event) => {
    setSucursal(event.target.value);
  };
  const handleChangeCu = (event) => {
    setCuenta(event.target.value);
  };
  const handleChangeC = (event) => {
    setCNote(event.target.value);
  };
  const handleChangeQ = (event) => {
    setQuanti(event.target.value);
  };
  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  const handleClose = () => {
    setNoCuen(null);
    setBank(null);
    setTitular(null);
    setSucursal(null);
    setCuenta(null);
  };

  const handleSaveData = async () => {
    try {
      setIsLoading(true);
      let ifDebit,
        ifCredit = 0;
      if (cuenta === "debito") {
        ifDebit = 1;
        ifCredit = 0;
      } else if (cuenta === "credito") {
        ifDebit = 0;
        ifCredit = 1;
      }
      await saveRefundData(
        rmaId,
        auth.user.data.awsid,
        orderId,
        incrementId,
        ifDebit,
        ifCredit,
        noCuen,
        titular,
        bank,
        sucursal
      );
      handleClose();
      getArticulos();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveSC = async () => {
    try {
      setIsLoading(true);
      await uploadFiles(rmaId, selectedFile);
      await saveSCRefundData(
        incrementId,
        rmaId,
        clientEmail,
        clientName,
        auth.user.data.awsid,
        orderId,
        incrementId,
        quanti,
        cnote
      );
      refreshPage();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveDataSC = async () => {
    try {
      setIsLoading(true);
      let aux = await saveCNandQ(detalle.id_orden_fk, detalle.id_nota_credito, rmaId, cnote, quanti);
      refreshPage();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  function refreshPage() {
    navigate(`${rmaId}`);
  }

  function getSCitems(items) {
    return items.filter((element) => element.if_reembolso_log > 0);
  }

  function getCantidadR(params) {
    if (params.row.cantidad_devuelta_log)
      return params.row.cantidad_devuelta_log;
    else return "0";
  }

  function getPTotal(arti) {
    let total = 0.0;
    arti.map((row) => (total = total + parseFloat(row.total.slice(1))));
    setQuanti(total);
    setReferenc(total);
  }

  const columns = [
    { field: "product_sku", headerName: "SKU", flex: 1 },
    { field: "product_name", headerName: "Nombre", flex: 3 },
    { field: "attributes", headerName: "Categoría", flex: 1 },
    { field: "category_name", headerName: "Grupo de Artículos", flex: 1 },
    { field: "price", headerName: "Precio", flex: 1 },
    {
      field: "cantidad_devuelta_log",
      headerName: "Cantidad Recibida",
      flex: 1,
      valueGetter: getCantidadR,
    },
    { field: "total", headerName: "Total", flex: 1 },
    {
      field: "acciones",
      headerName: "Mover a",
      flex: 1,
      renderCell: (params) => "No disponible",
    },
  ];

  const getArticulos = async () => {
    try {
      setIsLoading(true);
      const res = getSCitems(lista);
      setArticulos(res);
      const resp = await getSCRma(orderId, incrementId);
      setDetalle(resp);
      (resp.numero_notaCreditoReembolso ? setCNote(resp.numero_notaCreditoReembolso) : setCNote(""));
      if(resp.cantidadReembolso){
        setQuanti(resp.cantidadReembolso);
        setReferenc(resp.cantidadReembolso);
      }
      else getPTotal(res);
      const respo = await getFileRem(rmaId);
      setArchivo(respo);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getArticulos();
  }, []);

  return (
    <Box sx={{ mb: 2, ml: 2, mr: 2 }}>
      {isLoading && <CircularProgress />}
      {articulos && (
        <div style={{ display: "flex", height: "100%" }}>
          <div style={{ flexGrow: 1 }}>
            <DataGrid
              autoHeight
              rows={articulos}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              getRowId={(row) => row.eze3_item_id}
              disableColumnFilter
              disableColumnMenu
              disableColumnSelector
              disableDensitySelector
              disableExtendRowFullWidth
              disableSelectionOnClick
              initialState={{
                sorting: {
                  sortModel: [{ field: "product_sku", sort: "asc" }],
                },
              }}
            />
          </div>
        </div>
      )}

      {status.id_status_reembolso === 4 && !archivo && (
        <Grid container sx={{ mt: 2, mb: 2 }}>
          <Grid item md={3}></Grid>
          <Grid item md={6}>
            <Alert variant="filled" severity="info">
              <Typography variant="p">
                <strong>Aún sin datos de reembolso.</strong>
              </Typography>
            </Alert>
            <Card>
              <CardContent>
                <Grid container>
                  <Grid item md={2}></Grid>
                  <Grid item md={8} sx={{ mt: 2, mb: 2 }}>
                    <TextField
                      fullWidth
                      sx={{ mb: 2 }}
                      id="noCuen"
                      label="Número de cuenta"
                      value={noCuen}
                      onChange={handleChangeNC}
                      type="number"
                    />
                    <TextField
                      fullWidth
                      sx={{ mb: 2 }}
                      id="bank"
                      label="Banco"
                      value={bank}
                      onChange={handleChangeBk}
                    />
                    <TextField
                      fullWidth
                      sx={{ mb: 2 }}
                      id="titular"
                      label="Titular"
                      value={titular}
                      onChange={handleChangeTi}
                    />
                    <TextField
                      fullWidth
                      sx={{ mb: 2 }}
                      id="sucursal"
                      label="Sucursal"
                      value={sucursal}
                      onChange={handleChangeSu}
                    />
                    <FormControl sx={{ mb: 2 }}>
                      <FormLabel id="tipo-cuenta">Tipo de cuenta</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="tipo-cuenta"
                        name="row-radio-buttons-group"
                        value={cuenta}
                        onChange={handleChangeCu}
                      >
                        <FormControlLabel
                          value="debito"
                          control={<Radio />}
                          label="Tarjeta de débito"
                        />
                        <FormControlLabel
                          value="credito"
                          control={<Radio />}
                          label="Tarjeta de crédito"
                        />
                      </RadioGroup>
                    </FormControl>
                    <LoadingButton
                      loading={isLoading}
                      fullWidth
                      size="medium"
                      variant="contained"
                      color="success"
                      onClick={handleSaveData}
                      disabled={
                        !noCuen || !bank || !titular || !sucursal || !cuenta
                      }
                    >
                      Almacenar datos para reembolso
                    </LoadingButton>
                  </Grid>
                  <Grid item md={2}></Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={3}></Grid>
        </Grid>
      )}

      {status.id_status_reembolso === 8 && (
        <Grid container sx={{ mt: 2, mb: 2 }}>
          <Grid item md={3}></Grid>
          <Grid item md={6}>
            <Card>
              <CardContent>
                <Grid container>
                  <Grid item md={3}></Grid>
                  <Grid item md={6} sx={{ mt: 2, mb: 2 }}>
                    <Typography align="center" sx={{ mb: 2 }}>
                      {isFilePicked
                        ? selectedFile.name
                        : "Ningún archivo seleccionado"}
                    </Typography>
                    <Button
                      align="center"
                      variant="contained"
                      component="label"
                      sx={{ mb: 4 }}
                      endIcon={<UploadIcon />}
                    >
                      {isFilePicked ? "Elegir otro archivo" : "Elegir archivo"}
                      <input
                        hidden
                        accept="image/*,.pdf"
                        type="file"
                        onChange={changeHandler}
                      />
                    </Button>
                    <TextField
                      fullWidth
                      sx={{ mb: 2 }}
                      id="cnote"
                      label="Nota de crédito"
                      value={cnote}
                      onChange={handleChangeC}
                      type="number"
                    />
                    <TextField
                      fullWidth
                      sx={{ mb: 2 }}
                      id="quanti"
                      label="Cantidad"
                      value={quanti}
                      onChange={handleChangeQ}
                      type="number"
                    />
                    <Typography
                      sx={{ mb: 2 }}
                      variant="h4"
                      align="center"
                      component="div"
                    >
                      ${quanti}
                    </Typography>
                    <LoadingButton
                      loading={isLoading}
                      fullWidth
                      size="medium"
                      variant="contained"
                      color="success"
                      onClick={handleSaveSC}
                      disabled={!cnote || !quanti || quanti < referenc}
                    >
                      Asignar reembolso
                    </LoadingButton>

                    <LoadingButton sx={{ mt: 2 }} loading={isLoading} fullWidth size="medium" variant="contained"
                      color="info" onClick={handleSaveDataSC} disabled={!cnote || !quanti || quanti < referenc} endIcon={<SaveIcon />}>
                      Guardar datos
                    </LoadingButton>
                  </Grid>
                  <Grid item md={3}></Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={3}></Grid>
        </Grid>
      )}

      {status.id_status_reembolso === 6 && detalle && archivo && (
        <Grid container sx={{ mt: 2, mb: 2 }} alignItems="center">
          <Grid item md={5}>
            <Grid container sx={{ mt: 2, mb: 2 }}>
              <Grid item md={10}>
                <Alert variant="filled" severity="success">
                  <Typography variant="p">
                    <strong>¡Reembolso aprobado!</strong>
                  </Typography>
                </Alert>
                <Card>
                  <CardContent>
                    <Typography variant="p" component="div">
                      Nota de crédito:{" "}
                      <strong>{detalle.numero_notaCreditoReembolso}</strong>
                    </Typography>
                    <Typography variant="p" component="div">
                      Cantidad del reembolso:
                    </Typography>
                    <Typography variant="h4" align="center" component="div">
                      ${detalle.cantidadReembolso}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={7}>
            <Grid container>
              <Grid item md={10}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="h6" component="div">
                      <DescriptionIcon /> Archivos
                    </Typography>
                    <Grid container sx={{ alignItems: "center" }}>
                      <Grid item md={9}>
                        <Typography variant="p" component="div">
                          <strong>Nombre</strong>
                        </Typography>
                        <Typography variant="p" component="div">
                          {archivo.nombre}
                        </Typography>
                      </Grid>
                      <Grid item md={3}>
                        <Box sx={{ textAlign: "right" }}>
                          <LoadingButton
                            loading={isLoading}
                            variant="contained"
                            color="primary"
                            startIcon={<FileDownloadIcon />}
                          >
                            <a
                              download
                              href={
                                "data:application/pdf;base64," + archivo.pdf
                              }
                              style={{ textDecoration: "none", color: "#fff" }}
                            >
                              Descargar
                            </a>
                          </LoadingButton>
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={5}>
            <Grid container sx={{ mt: 2, mb: 2 }}>
              <Grid item md={10}>
                <Alert variant="filled" severity="info">
                  <Typography variant="p">
                    <strong>Datos para reembolso</strong>
                  </Typography>
                </Alert>
                <Card>
                  <CardContent>
                    <Typography variant="p" component="div">
                      Número de cuenta:{" "}
                      <strong>{detalle.bca_numero_cuenta}</strong>
                    </Typography>
                    <Typography variant="p" component="div">
                      Banco: <strong>{detalle.bca_banco}</strong>
                    </Typography>
                    <Typography variant="p" component="div">
                      Titular: <strong>{detalle.bca_titular}</strong>
                    </Typography>
                    <Typography variant="p" component="div">
                      Sucursal: <strong>{detalle.bca_sucursal}</strong>
                    </Typography>
                    <Typography variant="p" component="div">
                      Tipo de cuenta:{" "}
                      <strong>
                        {detalle.bca_if_debito
                          ? "Tarjeta de débito"
                          : "Tarjeta de crédito"}
                      </strong>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={5}>
            {/* <Grid container sx={{ mt: 2, mb: 2 }}>
              <Grid item md={10}>
                <Alert variant="filled" severity="info">
                  <Typography variant="p">
                    <strong>Costo de envio</strong>
                  </Typography>
                </Alert>
                <Card>
                  <CardContent>
                    <Typography variant="h4" align="center" component="div">
                      $243.99
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid> */}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default ReembolsoTab;
