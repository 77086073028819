import {
  Box,
  Button,
  Card,
  CardContent,
  LinearProgress,
  Typography,
  Tabs,
  Tab,
  Grid,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useEffect, useState } from "react";
import { useAuth } from "../../../context/AuthContext";
import { profiles } from "../../../utils/ventaMayoreo/profilesVentaMayoreo";
import BreadCrumb from "../../general/BreadCrumb";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import {
  getCompany,
  getContactDetail,
  getQuotesDetail,
} from "../../../utils/ventaMayoreo/cotizaciones/cotizaciones";
import CotizacionDetailItem from "./CotizacionDetailItem";
import { IndexTabs } from "./tabs/IndexTabs";

const itemsBreadCrumbs = [
  {
    label: "Venta mayoreo",
    icon: <ContentPasteSearchIcon sx={{ mr: 0.5 }} fontSize="small" />,
    link: "/venta-mayoreo",
  },
  { label: "Cotizaciones", link: "/venta-mayoreo/cotizaciones" },
  { label: "Detalle" },
];

const CotizacionesDetail = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;
  const [isLoading, setIsLoading] = useState(false);
  const [detalle, setDetalle] = useState(null);
  const [contactDetail, setContactDetail] = useState(null);
  const [productsDetail, setProductsDetail] = useState(null);
  const [status, setStatus] = useState(null);

  useEffect(() => {
    if (!profiles.cotizaciones.includes(auth.user.role.idProfile)) {
      navigate("/dashboard", { replace: true });
    } else {
      const getDetallePedido = async () => {
        try {
          setIsLoading(true);
          const resDetail = await getQuotesDetail(id);
          setDetalle(resDetail.data);
          const resContactD = await getContactDetail(id);
          setContactDetail(resContactD.data);
          const resProductsD = await getCompany(
            resDetail.data.cotizacion.id_requerimiento
          );
          setProductsDetail(resProductsD);
          // const resp = await getStatusRma(id);
          // setStatus(resp);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      };
      getDetallePedido();
    }
  }, [id, auth.user.data.awsid]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" sx={{ my: 2 }}>
          Detalle Cotizaciones
        </Typography>
        <Box>
          <BreadCrumb items={itemsBreadCrumbs} />
        </Box>
      </Box>

      <motion.div
        initial={{ y: 20 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Card>
          <Box>
            <Box>
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <Typography variant="h6" sx={{ ml: 2, my: 2, mb: 0 }}>
                    <AssignmentIcon sx={{ m: 0 }} /> Detalles de Cotización
                  </Typography>
                  {isLoading && <LinearProgress />}
                  {detalle && (
                    <CotizacionDetailItem cotizacionId={id} detalle={detalle} />
                  )}
                </Grid>
              </Grid>
            </Box>
          </Box>
          <IndexTabs
            cotizacionId={id}
            contactDetail={contactDetail}
            productsDetail={productsDetail}
            idRequerimiento={detalle?.cotizacion?.id_requerimiento}
            detalle={detalle}
          />
        </Card>
      </motion.div>

      <Box>&nbsp;</Box>
    </>
  );
};

export default CotizacionesDetail;
