import { Box, InputLabel,  Select, Button, FormControl, Menu, MenuItem, Grid, TextField, Alert, Card, CardContent, Radio, RadioGroup, FormControlLabel, FormLabel, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../../../context/AuthContext";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Toast from "../../../general/Toast";
import DateInTable from "../../../general/DateInTable";
import DescriptionIcon from '@mui/icons-material/Description';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import UploadIcon from '@mui/icons-material/Upload';
import { getBackOrderRefund, saveRefund, updateResolution, uploadFiles, closeRefund, getBackOrderEvidences } from "../../../../utils/trazabilidad/backorders/backorders";

const ReembolsoTab = ({ boId, detalle, articulos }) => {
    const { user } = useAuth();
    const [reemb, setReemb] = useState(null);
    const [noCuen, setNoCuen] = useState("");
    const [bank, setBank] = useState("");
    const [titular, setTitular] = useState("");
    const [sucursal, setSucursal] = useState("");
    const [cuenta, setCuenta] = useState("");
    const [cnote, setCNote] = useState("");
    const [selectedFile, setSelectedFile] = useState([]);
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [opena, setOpenA] = useState(false);
    const [evidences, setEvidences] = useState([]);
    const [confalert, setConfAlert] = useState({
        severidad: "success",
        mensaje: "",
    });

    const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
		setIsFilePicked(true);
	};

    const handleOpenA = () => {
        setOpenA(true);
    };
    
    const handleCloseA = () => {
        setOpenA(false);
    };

    const columns = [
        { field: "joinSku", headerName: "SKU", flex: 1, align: "center", headerAlign: "center", },
        { field: "joinProducto", headerName: "Nombre Producto", flex: 2 },
        { field: "joinCant_pedida", headerName: "Cant. pedida", align: "center", headerAlign: "center", flex: 0.6,  },
        { field: "joinCant_surtida", headerName: "Cant. surtida", align: "center", headerAlign: "center", flex: 0.6,  },
        { field: "joinCant_faltante", headerName: "Cant. faltante", align: "center", headerAlign: "center", flex: 0.6,  },
        { field: "price", headerName: "Precio", align: "center", headerAlign: "center", flex: 1, valueGetter: ({ row }) => ('$ '+row.price) },
        { field: "id_item_status", headerName: "Status", align: "center", headerAlign: "center", flex: 1, valueGetter: ({ row }) => ('Reembolso') },
    ];

    const handleClose = () => {
        setNoCuen("");
        setBank("");
        setTitular("");
        setSucursal("");
        setCuenta("");
    };

    function get_info() {
        let price = 0;
        let skus = [];
        let ids = [];
        articulos.forEach(element => {
            price = price + (element.price*element.joinCant_pedida);
            skus.push(element.joinSku);
            ids.push(element.id_item_bo);
        });

        let aux = {
            total: price,
            askus: skus,
            ids: ids
        };

        return aux;
    }

    const handleSaveData = async () => {
        try {

            setIsLoading(true);
            let ifDebit, ifCredit = 0;
            if(cuenta === 'debito'){
                ifDebit = 1;
                ifCredit = 0;
            }else if(cuenta === 'credito'){
                ifDebit = 0;
                ifCredit = 1;
            }

            let aux = get_info();
            const resp = await saveRefund(boId, user.data.awsid, detalle.increment_id, ifDebit, ifCredit, noCuen, bank, titular, sucursal);
            const resp2 = await updateResolution(boId, user.data.awsid, 7, detalle.increment_id, detalle.customer_id, noCuen, bank, titular, "", cuenta, aux.total, aux.askus);

            if(resp && resp2) {
                setConfAlert({
                    severidad: "success",
                    mensaje: "Solicitud de reembolso enviada",
                });
                handleOpenA(true);
                handleClose();
            }else {
                setConfAlert({
                    severidad: "warning",
                    mensaje: "Hubo un problema, intenta de nuevo más tarde",
                });
                handleOpenA(true);
            }

        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);          
        }
    };

    const handleSaveSC = async () => {
        try {
            if(isFilePicked && cnote)
            {
                setIsLoading(true);
                let aux = get_info();
                const resp = await uploadFiles(boId, selectedFile, user.data.awsid, 7, 2);
                const resp2 = await closeRefund(boId, user.data.awsid, reemb.id_refund, cnote, aux.askus, aux.ids);
                
                if(resp && resp2) {
                    setConfAlert({
                        severidad: "success",
                        mensaje: "Reembolso aplicado",
                    });
                    handleOpenA(true);
                    handleClose();
                }else {
                    setConfAlert({
                        severidad: "warning",
                        mensaje: "Hubo un problema, intenta de nuevo más tarde",
                    });
                    handleOpenA(true);
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {

        const getRembBO = async () => {
            try {
                setIsLoading(true);
                const res = await getBackOrderRefund(boId);
                setReemb(res);

                if(detalle.uar_date){
                    const resp = await getBackOrderEvidences(boId);
                    setEvidences(resp);
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };
        getRembBO();

    }, [boId]);

    return (
    <>
        <Box sx={{mb: 2, mx: 2}}>
            <div style={{ display: "flex", height: "100%" }}>
                <div style={{ flexGrow: 1 }}>
                    <DataGrid
                        autoHeight
                        rows={articulos}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        getRowId={(row) => row.id_item_bo}
                        loading={isLoading}
                        disableColumnMenu
                        disableSelectionOnClick
                    />
                </div>
            </div>
        </Box>

        {(!detalle.if_reembolso && !reemb) && (
            <Grid container sx={{mt:2, mb:2}}>
                <Grid item md={3}></Grid>
                <Grid item md={6}>
                    <Alert variant="filled" severity="info"><Typography variant="p"><strong>Aún sin datos de reembolso.</strong></Typography></Alert>
                    <Card>
                        <CardContent>
                            <Grid container>
                                <Grid item md={2}></Grid>
                                <Grid item md={8} sx={{mt:2, mb:2}}>
                                    <TextField fullWidth sx={{mb:2}} id="noCuen" label="Número de cuenta" value={noCuen} onChange={(e) => setNoCuen(e.target.value)} type="number"/>
                                    <TextField fullWidth sx={{mb:2}} id="bank" label="Banco" value={bank} onChange={(e) => setBank(e.target.value)}/>
                                    <TextField fullWidth sx={{mb:2}} id="titular" label="Titular" value={titular} onChange={(e) => setTitular(e.target.value)}/>
                                    <TextField fullWidth sx={{mb:2}} id="sucursal" label="Sucursal" value={sucursal} onChange={(e) => setSucursal(e.target.value)}/>
                                    <FormControl sx={{mb:2}}>
                                        <FormLabel id="tipo-cuenta">Tipo de cuenta</FormLabel>
                                        <RadioGroup row aria-labelledby="tipo-cuenta" name="row-radio-buttons-group" value={cuenta} onChange={(e) => setCuenta(e.target.value)}>
                                            <FormControlLabel value="debito" control={<Radio />} label="Tarjeta de débito" />
                                            <FormControlLabel value="credito" control={<Radio />} label="Tarjeta de crédito" />
                                        </RadioGroup>
                                    </FormControl>
                                    <LoadingButton loading={isLoading} fullWidth size="medium" variant="contained" color="success" onClick={handleSaveData} 
                                        disabled={(!noCuen || !bank || !titular || !sucursal || !cuenta)}>Solicitar reembolso</LoadingButton>
                                </Grid>
                                <Grid item md={2}></Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={3}></Grid>
            </Grid>
        )}

        {(detalle.if_reembolso || reemb) && (
        <>
            {!detalle.uar_date && (
            <Grid container sx={{mt:2, mb:2, alignItems: 'center'}}>
                <Grid item md={5}>
                    <Grid container sx={{mt:2, mb:2}}>
                        <Grid item md={1}></Grid>
                        <Grid item md={10}>
                            <Alert sx={{ mb:2 }} variant="filled" severity="primary">
                                <Typography variant="p" component="div">Solicitado por <strong>{detalle.urr_name} {detalle.urr_lastname}</strong> <DateInTable dateString={detalle.urr_date} /> </Typography>
                            </Alert>
                            <Alert variant="filled" severity="info"><Typography variant="p"><strong>Datos para reembolso</strong></Typography></Alert>
                            <Card>
                                <CardContent>
                                    <Typography variant="p" component="div">Número de cuenta: <strong>{detalle.bca_numero_cuenta ?? reemb.bank_number_account}</strong></Typography>
                                    <Typography variant="p" component="div">Banco: <strong>{detalle.bca_banco ?? reemb.bank_name}</strong></Typography>
                                    <Typography variant="p" component="div">Titular: <strong>{detalle.bca_titular ?? reemb.bank_owner_name}</strong></Typography>
                                    <Typography variant="p" component="div">Sucursal: <strong>{detalle.bca_sucursal ?? reemb.bank_branch}</strong></Typography>
                                    <Typography variant="p" component="div">Tipo de cuenta: <strong>{detalle.bca_if_credito ? 'Tarjeta de crédito' : 'Tarjeta de débito' }</strong></Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item md={1}></Grid>
                    </Grid>
                </Grid>
                <Grid item md={6}>
                    <Card>
                        <CardContent>
                            <Grid container>
                                <Grid item md={3}></Grid>
                                <Grid item md={6} sx={{mt:2, mb:2}}>
                                    <Typography align="center" sx={{mb:2}}>{isFilePicked ? selectedFile.name : 'Ningún archivo seleccionado'}</Typography>
                                    <Button align="center" variant="contained" component="label" sx={{mb:4}} endIcon={<UploadIcon />}>
                                        {isFilePicked ? 'Elegir otro archivo' : 'Elegir archivo'}
                                        <input hidden accept="image/*,.pdf" type="file" onChange={changeHandler} />
                                    </Button>
                                    <TextField fullWidth sx={{mb:2}} id="cnote" label="Nota de crédito" value={cnote} onChange={(e) => setCNote(e.target.value)}/>
                                    <LoadingButton loading={isLoading} fullWidth size="medium" variant="contained" color="success" onClick={handleSaveSC} disabled={(!cnote || !isFilePicked)}>Aplicar reembolso</LoadingButton>
                                </Grid>
                                <Grid item md={3}></Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            )}

            {detalle.uar_date && (
            <Grid container sx={{mt:2, mb:2, alignItems: 'center'}}>
                <Grid item md={5}>
                    <Grid container sx={{mt:2, mb:2}}>
                        <Grid item md={1}></Grid>
                        <Grid item md={10}>
                            <Alert sx={{ mb:2 }} variant="filled" severity="primary">
                                <Typography variant="p" component="div">Reembolso solicitado por <strong>{detalle.urr_name} {detalle.urr_lastname}</strong> <DateInTable dateString={detalle.urr_date} /> </Typography>
                            </Alert>
                            <Alert sx={{ mb:2 }} variant="filled" severity="primary">
                                <Typography variant="p" component="div">Reembolso aplicado por <strong>{detalle.uar_name} {detalle.uar_lastname}</strong> <DateInTable dateString={detalle.uar_date} /> </Typography>
                            </Alert>
                            <Alert variant="filled" severity="info"><Typography variant="p"><strong>Datos de reembolso</strong></Typography></Alert>
                            <Card>
                                <CardContent>
                                    <Typography variant="p" component="div">Número de cuenta: <strong>{detalle.bca_numero_cuenta ?? reemb.bank_number_account}</strong></Typography>
                                    <Typography variant="p" component="div">Banco: <strong>{detalle.bca_banco ?? reemb.bank_name}</strong></Typography>
                                    <Typography variant="p" component="div">Titular: <strong>{detalle.bca_titular ?? reemb.bank_owner_name}</strong></Typography>
                                    <Typography variant="p" component="div">Sucursal: <strong>{detalle.bca_sucursal ?? reemb.bank_branch}</strong></Typography>
                                    <Typography variant="p" component="div">Tipo de cuenta: <strong>{detalle.bca_if_credito ? 'Tarjeta de crédito' : 'Tarjeta de débito' }</strong></Typography>
                                    <Typography variant="p" component="div">Nota de crédito: <strong>{reemb.credit_note}</strong></Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item md={1}></Grid>
                    </Grid>
                </Grid>
                <Grid item md={6}>
                    <Alert variant="filled" severity="info"><Typography variant="p"><strong>Archivos</strong></Typography></Alert>
                    <Card>
                        {(evidences && evidences.length > 0) && evidences.map((row) => (
                        <CardContent>
                            <Typography variant="p" component="div">Nombre del archivo: <strong>{row.nombre}</strong></Typography>
                            <Box sx={{ textAlign: 'center', my: 2 }}>
                                <LoadingButton loading={isLoading} variant="contained" color="primary" startIcon={<FileDownloadIcon />}>
                                    <a download href={`data:application/${row.extension};base64,${row.base64}`} style={{ textDecoration: 'none', color: '#fff'}}>Descargar</a>
                                </LoadingButton>
                            </Box>
                        </CardContent>
                        ))}
                    </Card>
                </Grid>
            </Grid>
            )}
        </>
        )}
        <Toast open={opena} message={confalert.mensaje} type={confalert.severidad} onClose={handleCloseA} />
    </>
    );
};

export default ReembolsoTab;