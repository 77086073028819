import { Button, Tooltip } from "@mui/material";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const ExcelFile = ({ excelData, fileName }) => {
    
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToExcel = async () => {
        const ws = XLSX.utils.json_to_sheet(excelData);
        const excelBuffer = XLSX.write(
            {
                Sheets: { data: ws },
                SheetNames: ["data"],
            },
            { 
                bookType: "xlsx", 
                type: "array" 
            }
        );
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return (
        <>
            <Tooltip title="Descargar Excel">
                <Button variant="contained" color="success" startIcon={<FileDownloadIcon />} onClick={(e) => exportToExcel(fileName)}>
                    Descargar
                </Button>
            </Tooltip>
        </>
    );
};

export default ExcelFile;