import { Box, CircularProgress, Typography, Alert, Card, CardContent, Grid, TextField, InputLabel, Select, FormControlLabel, FormControl, FormLabel, Radio, RadioGroup, MenuItem, Stack, Snackbar, List, ListItem, ListItemText } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { LoadingButton } from "@mui/lab";
import { useAuth } from "../../../../context/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState, forwardRef, ChangeEvent } from "react";
import SaveIcon from '@mui/icons-material/Save';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PrintIcon from '@mui/icons-material/Print';
import { saveVSD, getCarriers, getCarriersServices, newGuide, saveTipification, getVerifGuides } from "../../../../utils/trazabilidad/verificaciones/verificaciones";

const Alerta = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const DETab = ({ verifId, verifDl }) => {
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [openn, setOpenn] = useState(false);
    const [vertical, setVertical] = useState("top");
    const [horizontal, setHorizontal] = useState("right");
    const [tipificacion, setTipificacion] = useState(1);
    const [carriers, setCarriers] = useState(null);
    const [carrier, setCarrier] = useState(1);
    const [services, setServices] = useState(null);
    const [service, setService] = useState(1);
    const [guias, setGuias] = useState(null);
    const [address, setAddress] = useState({
        nombre: '',
        calle: '',
        ne: '',
        ni: '',
        entre: '',
        colonia: '',
        dele: '',
        cp: '',
        ciudad: '',
        estado: '',
        fachada: '',
        tel: ''
    });

    const handleClickN = () => {
        setOpenn(true);
    };

    const handleCloseN = () => {
        setOpenn(false);
    };

    const handleChangeAddress = (event) => {    
        let name = event.target.name;
        let value = event.target.value;
        setAddress((prevalue) => {
          return {
            ...prevalue,
            [name]: value
          }
        });
    };

    function isempt(){
        if(address.nombre && address.calle && address.ne && address.entre && address.colonia && address.dele && address.cp && address.ciudad && address.estado && address.fachada && address.tel)
            return false;
        else
            return true;
    }

    const handleChangeAF = async () => {
        try {
            setIsLoading(true);
            if(!isempt())
            {
                await saveVSD(
                    verifId, auth.user.data.awsid, address.nombre, 'México', address.calle, 
                    address.ne, address.ni, address.entre, address.colonia, address.dele, 
                    address.cp, address.ciudad, address.estado, address.fachada, address.tel
                );
                handleClickN();
                refreshPage();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSaveTip = async () => {
        try {
            setIsLoading(true);
            await saveTipification(verifId, auth.user.data.awsid, tipificacion, verifDl.increment_id);
            handleClickN();
            refreshPage();
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSaveGuide = async () => {
        try {
            setIsLoading(true);
            await newGuide(verifId, auth.user.data.awsid, carrier, service);
            await saveTipification(verifId, auth.user.data.awsid, tipificacion, verifDl.increment_id);
            handleClickN();
            refreshPage();
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleChangeTip = async (event) => {
        try {
            let value = event.target.value;
            setTipificacion(value);
            if(value == 3)
            {
                const resp = await getCarriers();
                setCarriers(resp);
                const res = await getCarriersServices(carrier);
                setServices(res);
            }

        } catch (error) {
            console.error(error);
        } finally {}
    };

    const handleChangeCarrier = async (event) => {
        try {
            let value = event.target.value;
            setCarrier(value);
            const res = await getCarriersServices(value);
            setServices(res);
            setService(res[0].id_tipo_servicio);
        } catch (error) {
            console.error(error);
        } finally {}
    };

    const handleChangeService = (event) => {
        let value = event.target.value;
        setService(value);
    };

    const refreshPage = () => {
        const current = location.pathname;
        navigate(`/reload`, { replace: true });
        setTimeout(() => {
          navigate(current, { replace: true });
        });
    };

    useEffect(() => {
        const getAddress = async () => {
            try {
                setIsLoading(true);
                if(verifDl.id_status === 1)
                {
                    setAddress({
                        nombre: (verifDl.customer_name + " " + verifDl.customer_lastname),
                        calle: (verifDl.shipping_street),
                        ne: '',
                        ni: '',
                        entre: '',
                        colonia: (verifDl.shipping_suburb),
                        dele: (verifDl.shipping_delegation),
                        cp: '',
                        ciudad: (verifDl.shipping_city),
                        estado: (verifDl.shipping_state),
                        fachada: '',
                        tel: (verifDl.telephone)
                    });
                }

                if(verifDl.id_status === 3 && verifDl.id_tipo === 3)
                {
                    const resp = await getVerifGuides(verifId);
                    setGuias(resp);
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };
        getAddress();
    }, []);

    return (
        <>
            <Box sx={{mb: 2, ml: 2, mr: 2}}>
            {isLoading && <CircularProgress />}
            
            {(verifDl.id_status === 1 && address) && (<Grid container sx={{mt:2, mb:2}}>
                <Grid item md={12}>
                    <Card>
                        <CardContent>
                            <Stack sx={{m: 2}} direction="row" spacing={3} autoComplete="off">
                                <TextField fullWidth name="nombre" label="Nombre" variant="outlined" value={address.nombre} onChange={handleChangeAddress} />
                                <TextField fullWidth name="calle" label="Calle" variant="outlined" value={address.calle} onChange={handleChangeAddress} />
                                <TextField fullWidth name="ne" label="Número exterior" variant="outlined" value={address.ne} onChange={handleChangeAddress} />
                            </Stack>
                            <Stack sx={{m: 2}} direction="row" spacing={3} autoComplete="off">
                                <TextField fullWidth name="ni" label="Número interior" variant="outlined" value={address.ni} onChange={handleChangeAddress} />
                                <TextField fullWidth name="entre" label="Entre calles" variant="outlined" value={address.entre} onChange={handleChangeAddress} />
                                <TextField fullWidth name="colonia" label="Colonia" variant="outlined" value={address.colonia} onChange={handleChangeAddress} />
                            </Stack>
                            <Stack sx={{m: 2}} direction="row" spacing={3} autoComplete="off">
                                <TextField fullWidth name="dele" label="Delegación" variant="outlined" value={address.dele} onChange={handleChangeAddress} />
                                <TextField fullWidth name="cp" label="C.P." variant="outlined" value={address.cp} onChange={handleChangeAddress} />
                                <TextField fullWidth name="ciudad" label="Ciudad" variant="outlined" value={address.ciudad} onChange={handleChangeAddress} />
                            </Stack>
                            <Stack sx={{m: 2}} direction="row" spacing={3} autoComplete="off">
                                <TextField fullWidth name="estado" label="Estado" variant="outlined" value={address.estado} onChange={handleChangeAddress} />
                                <TextField fullWidth name="fachada" label="Fachada" variant="outlined" value={address.fachada} onChange={handleChangeAddress} />
                                <TextField fullWidth name="tel" label="Teléfono" variant="outlined" value={address.tel} onChange={handleChangeAddress} />
                            </Stack>
                            <div style={{ textAlign: 'right', marginTop: '2em' }}>
                                <LoadingButton startIcon={<SaveIcon />} sx={{ mx: 2}} loading={isLoading} size="medium" variant="contained" color="success" onClick={handleChangeAF} disabled={isempt()}>Guardar datos</LoadingButton>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            )}
            
            {(verifDl.id_status !== 1) && (
            <>
            <Alert variant="filled" severity="info"><Typography variant="p"><strong>Domicilio guardado</strong></Typography></Alert>
            <Grid container sx={{mt:2, mb:2}}>                
                <Grid item md={4}>
                    <List>
                        <ListItem>
                            <ListItemText primary="Nombre" secondary={verifDl.dir_nombre}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Estado" secondary={verifDl.dir_estado}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Núm. Ext" secondary={verifDl.dir_numero_ext}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Ciudad" secondary={verifDl.dir_ciudad}/>
                        </ListItem>
                    </List>
                </Grid>

                <Grid item md={4}>
                    <List>
                        <ListItem>
                            <ListItemText primary="Municipio" secondary={verifDl.dir_municipio}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Calle" secondary={verifDl.dir_calle}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Núm. Int" secondary={verifDl.dir_numero_int}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Entre Calles" secondary={verifDl.dir_entre_calles}/>
                        </ListItem>
                    </List>
                </Grid>

                <Grid item md={4}>
                    <List>
                        <ListItem>
                            <ListItemText primary="Código Postal" secondary={verifDl.dir_cp}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Colonia" secondary={verifDl.dir_colonia}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Teléfono" secondary={verifDl.dir_telefono}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Fachada" secondary={verifDl.dir_fachada}/>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
            </>
            )}

            {(verifDl.id_status === 2) && (
            <Grid container sx={{ m:2 }}>
                <Grid item md={2}></Grid>
                <Grid item md={8}>
                    <Card>
                        <CardContent>
                            <div>
                                <FormControl>
                                    <FormLabel id="tipi">Tipificación</FormLabel>
                                    <RadioGroup row aria-labelledby="tipi" name="tipi" value={tipificacion} onChange={handleChangeTip}>
                                        <FormControlLabel value={1} control={<Radio />} label="Entrega correcto" />
                                        <FormControlLabel value={2} control={<Radio />} label="Pedido recuperado y entregado" />
                                        <FormControlLabel value={3} control={<Radio />} label="No recuperado. Enviado nuevamente" />
                                    </RadioGroup>
                                </FormControl>
                                {(tipificacion != 3) && (<LoadingButton sx={{ m: 2 }} startIcon={<SaveIcon />} loading={isLoading} size="medium" variant="contained" color="success" onClick={handleSaveTip}>Guardar</LoadingButton>)}
                            </div>                            
                            {(tipificacion == 3) && (
                            <div style={{ textAlign: 'center' }}>
                                <Stack sx={{ mt: 2, mb: 2, textAlign: 'left' }} direction="row" spacing={2} autoComplete="off">
                                    <FormControl fullWidth sx={{ mb: 2 }}>
                                        <InputLabel id="selectec" htmlFor="groupedselectcarriers">Seleccionar Carrier</InputLabel>
                                        <Select id="groupedselectcarriers" name="groupedselectcarriers" label="Seleccionar Carrier" value={carrier} onChange={handleChangeCarrier}>
                                            {carriers && carriers.map((row) => (
                                                <MenuItem key={row.id_tz_carriers} value={row.id_tz_carriers}>{row.nombre}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <FormControl fullWidth sx={{ mb: 2 }}>
                                        <InputLabel id="selectes" htmlFor="groupedselectservices">Seleccionar Servicio</InputLabel>
                                        <Select id="groupedselectservices" name="groupedselectservices" label="Seleccionar Servicio" value={service} onChange={handleChangeService}>
                                            {services && services.map((row) => (
                                                <MenuItem key={row.id_tipo_servicio} value={row.id_tipo_servicio}>{row.servicio}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>                                        
                                </Stack>
                                <LoadingButton startIcon={<LocalShippingIcon />} loading={isLoading} size="medium" variant="contained" color="warning" onClick={handleSaveGuide}>Generar Guía</LoadingButton>
                            </div>
                            )}                            
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={2}></Grid>
            </Grid>
            )}

            {(verifDl.id_status === 3 && verifDl.id_tipo === 3) && (
            <Grid container sx={{ m:2 }}>
                <Grid item md={12}>                    
                    <Box sx={{ textAlign: 'center' }}>
                        <LoadingButton loading={isLoading} variant="contained" color="info" startIcon={<PrintIcon />}>
                            {guias && <a href={"data:application/pdf;base64,"+guias.at(-1).pdf} download target={"_blank"} style={{ textDecoration: 'none', color: '#fff'}}>Descargar guía</a>}
                        </LoadingButton>
                    </Box>
                </Grid>
            </Grid>
            )}

            </Box>

            <Snackbar anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal} open={openn} onClose={handleCloseN}>
                <Alerta onClose={handleCloseN} severity="success" sx={{ width: '100%' }}>Información actualizada</Alerta>
            </Snackbar>
        </>
    );
};

export default DETab;