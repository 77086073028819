import axios from "axios";

const getReportePedidosEscuelas = async (initDate, endDate) => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/pedidosyescuelas?initDate=${initDate}&endDate=${endDate}`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        Pedido: element.Pedido,
        Escuela: element.school,
        "Fecha de Pago": element.FechaPago
          ? `${new Date(element.FechaPago)
              .toLocaleString("es-mx", {
                timeZone: "UTC",
              })
              .replace(",", "")}`
          : "",
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReporteNpsTiendas = async (initDate, endDate) => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/npstiendas?initDate=${initDate}&endDate=${endDate}`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        Encuesta: element.nps,
        "Fecha de respuesta": element.creation_date
          ? `${new Date(element.creation_date).toLocaleDateString("es-mx", {
              timeZone: "UTC",
            })}`
          : "",
        Hora: element.creation_date
          ? `${new Date(element.creation_date).toLocaleTimeString("es-mx", {
              timeZone: "UTC",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
            })}`
          : "",
        Tienda: element.store,
        Pregunta: element.id_question,
        Respuesta: element.answer,
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReporteVentaMayoreoGeneral = async (initDate, endDate) => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/ventamayoreo?initDate=${initDate}&endDate=${endDate}`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        "Numero Requerimiento": element.numero_requerimiento,
        "Fecha de alta": element.fecha_alta
          ? `${new Date(element.fecha_alta)
              .toLocaleString("es-mx", {
                timeZone: "UTC",
              })
              .replace(",", "")}`
          : "",
        Estatus: element.estatus,
        Creador: `${element.nombre} ${element.apellidoP}`,
        Contacto: element.contacto,
        Empresa: element.empresa,
        "Fecha en validacion": element.fecha_en_validacion
          ? `${new Date(element.fecha_en_validacion)
              .toLocaleString("es-mx", {
                timeZone: "UTC",
              })
              .replace(",", "")}`
          : "",
        "Fecha de Validacion": element.fecha_validacion
          ? `${new Date(element.fecha_validacion)
              .toLocaleString("es-mx", {
                timeZone: "UTC",
              })
              .replace(",", "")}`
          : "",
        "No. de Skus": element.skus_requerimiento,
        "Numero Cotizacion": element.numero_cotizacion,
        "Estatus de Cotizacion": element.estatus_cotizacion,
        "Fecha de Cotizacion": element.fecha_cotizacion
          ? `${new Date(element.fecha_cotizacion)
              .toLocaleString("es-mx", {
                timeZone: "UTC",
              })
              .replace(",", "")}`
          : "",
        "Fecha de aprobacion cotizacion": element.fecha_aprobacion
          ? `${new Date(element.fecha_aprobacion)
              .toLocaleString("es-mx", {
                timeZone: "UTC",
              })
              .replace(",", "")}`
          : "",
        "Numero orden de venta": element.orden_venta,
        Total: element.total,
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReporteRMASkus = async (initDate, endDate) => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/rmaskus?initDate=${initDate}&endDate=${endDate}`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        "Numero Requerimiento": element.Pedido,
        RMA: element.RMA,
        FechaCreacion: element.FechaEntregaGEA
          ? `${new Date(element.FechaEntregaGEA)
              .toLocaleString("es-mx", {
                timeZone: "UTC",
              })
              .replace(",", "")}`
          : "",
        NombreProducto: element.product_name,
        Sku: element.product_sku,
        EstatusReembolso: element.EstatusReembolso,
        EstatusStoreCredit: element.EstatusStoreCredit,
        EstatusCambio: element.EstatusCambio,
        Categoria: element.grupo_articulos,
        Escuela: element.school,
        CantidadDevuelta: element.cantidad_devuelta_log,
        "Fecha Solicitud A Finanzas": element.fecha_solicitudAFinanzas
          ? `${new Date(element.fecha_solicitudAFinanzas)
              .toLocaleString("es-mx", {
                timeZone: "UTC",
              })
              .replace(",", "")}`
          : "",
        "Fecha Liberacion Finanzas": element.fecha_liberacionFinanzas
          ? `${new Date(element.fecha_liberacionFinanzas)
              .toLocaleString("es-mx", {
                timeZone: "UTC",
              })
              .replace(",", "")}`
          : "",
        "Dias Transcurridos": element.diasTranscurridos,
        Comentarios: element.Comentarios,
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReporteAclaracionesSku = async (initDate, endDate) => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/aclaracionessku?initDate=${initDate}&endDate=${endDate}`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        Aclaracion: element.id_aclaracion,
        Orden: element.id_orden,
        Tipificacion: element.Tipificacion,
        Estatus: element.Estatus,
        Fecha: element.creation_date
          ? `${new Date(element.creation_date)
              .toLocaleString("es-mx", {
                timeZone: "UTC",
              })
              .replace(",", "")}`
          : "",
        Sku: element.sku,
        "Cantidad Correcta": element.cantidad_correcta,
        "Cantidad Erronea": element.cantidad_erronea,
        Nombre: element.nombre,
        Faltante: element.if_faltante,
        Defectuoso: element.if_defectuoso,
        Erroneo: element.if_erroneo,
        "Item ID": element.eze3_item_id,
        Escuela: element.school,
        "Fecha de Liberacion": element.fecha_liberacion
          ? `${new Date(element.fecha_liberacion)
              .toLocaleString("es-mx", {
                timeZone: "UTC",
              })
              .replace(",", "")}`
          : "",
        Dias: element.dias,
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReporteNPSKlipp = async (initDate, endDate, email) => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/npsklipp?initDate=${initDate}&endDate=${endDate}&mail=${email}`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        "Id Reporte": element.id_klipp,
        Titulo: element.title,
        Solicitante: element.Solicita,
        "En tiempo": element["En tiempo"] === 1 ? "Si" : "No",
        "¿Te atendimos bien?": element["¿Te atendimos bien?"],
        "¿Te dimos respuesta en el tiempo que lo necesitabas?":
          element["¿Te dimos respuesta en el tiempo que lo necesitabas?"],
        "¿Te dimos una solución satisfactoria?":
          element["¿Te dimos una solución satisfactoria?"],
        Comentarios: element.Comentarios,
        Estatus: element.Estatus,
        "Fecha de solicitud": element.start_date
          ? `${new Date(element.start_date)
              .toLocaleString("es-mx", {
                timeZone: "UTC",
              })
              .replace(",", "")}`
          : "",
        "Fecha de asignación": element.creation_date
          ? `${new Date(element.creation_date)
              .toLocaleString("es-mx", {
                timeZone: "UTC",
              })
              .replace(",", "")}`
          : "",
        "Fecha Resuelto": element["Fecha Resuelto"]
          ? `${new Date(element["Fecha Resuelto"])
              .toLocaleString("es-mx", {
                timeZone: "UTC",
              })
              .replace(",", "")}`
          : "",
        VoBo: element.VoBo,
        Responsable: element.Responsable,
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReporteCasos = async (initDate, endDate) => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/casos?initDate=${initDate}&endDate=${endDate}`;
  const URL2 = `${process.env.REACT_APP_BASEURL}/reportes/intervaldays`;

  let res2 = await axios(URL2);
  res2 = res2.data?.success ? res2.data.data : [];

  let diasArray = {};

  res2.forEach((item) => {
    diasArray[item.id_caso] = { dias: item.Dias };
  });

  console.log(diasArray);

  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        "Tipo de Registro": element.tipo_registro,
        Tema: element.tema,
        Colegio: element.colegio,
        "Email del Padre": element.email_padre,
        Teléfono: element.telefono,
        "Tipo de Producto": element.tipo_producto,
        Estado: element.estado,
        "Origen del Caso": element.origen_caso,
        Descripción: element.descripcion,
        Solución: element.solucion,
        "Fecha de Creación": element.date_creation
          ? `${new Date(element.date_creation)
              .toLocaleString("es-mx", {
                timeZone: "UTC",
              })
              .replace(",", "")}`
          : "",
        "Fecha de Cierre": element.update_date
          ? `${new Date(element.update_date)
              .toLocaleString("es-mx", {
                timeZone: "UTC",
              })
              .replace(",", "")}`
          : "",
        "Días Transcurridos":
          element.estado === "Abierto" ? diasArray[element.id_caso].dias : "-",
        "Usuario Alta Caso": `${element.name_user} ${element.apellido_user}`,
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReporteCasosyAsociados = async (initDate, endDate) => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/casosyasociados?initDate=${initDate}&endDate=${endDate}`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        "#Caso": element.Caso,
        Asociados: element.Asociados,
        Fecha: element.user_date_creation
          ? `${new Date(element.user_date_creation)
              .toLocaleString("es-mx", {
                timeZone: "UTC",
              })
              .replace(",", "")}`
          : "",
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReporteInventarios = async (initDate, endDate) => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/inventarios?initDate=${initDate}&endDate=${endDate}`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        Almacen: element.nombre_almacen,
        Rack: element.Rack,
        Sid: element.Sid,
        Sku: `'${element.Sku}`,
        Descripcion: element.Descripcion,
        Cantidad: element.Cantidad,
        Fecha: element.Fecha
          ? `${new Date(element.Fecha)
              .toLocaleString("es-mx", {
                timeZone: "UTC",
              })
              .replace(",", "")}`
          : "",
        Usuario: `${element.nombre} ${element.ap_paterno}`,
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReporteComentariosAclaraciones = async (initDate, endDate) => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/aclaracionescomentarios?initDate=${initDate}&endDate=${endDate}`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        id: element.id_aclaracion,
        Pedido: `'${element.increment_id}`,
        Estatus: element.estatus,
        Tipificación: element.tipificacion,
        "Fecha de Creación": element.fechacreacion
          ? `${new Date(element.fechacreacion)
              .toLocaleString("es-mx", {
                timeZone: "UTC",
              })
              .replace(",", "")}`
          : "",
        Comentario: element.comentario,
        Usuario: element.usuario,
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReporteTickets = async (initDate, endDate) => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/tickets?initDate=${initDate}&endDate=${endDate}`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        id: element.id_klipp,
        Nombre: element.title,
        Descripción: element.description,
        "Solicitado por": `${element.name_user} ${element.apellido_user}`,
        "Fecha de Solicitud": element.start_date
          ? `${new Date(element.start_date)
              .toLocaleString("es-mx", {
                timeZone: "UTC",
              })
              .replace(",", "")}`
          : "",
        "Fecha de cierre": element.finish_date
          ? `${new Date(element.finish_date)
              .toLocaleString("es-mx", {
                timeZone: "UTC",
              })
              .replace(",", "")}`
          : "",
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReportePedidosPorFecha = async (initDate, endDate) => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/pedidosfecha?initDate=${initDate}&endDate=${endDate}`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        "Numero de Pedido": element.numero_pedido,
        "Fecha de Pago": element.fecha_pago
          ? `${new Date(element.fecha_pago)
              .toLocaleString("es-mx", {
                timeZone: "UTC",
              })
              .replace(",", "")}`
          : "",
        "Fecha de Facturacion SAP": element.fecha_facturacion_sap
          ? `${new Date(element.fecha_facturacion_sap)
              .toLocaleString("es-mx", {
                timeZone: "UTC",
              })
              .replace(",", "")}`
          : "",
        "Fecha de Envio a Logistica": element.fecha_envio_logistica
          ? `${new Date(element.fecha_envio_logistica)
              .toLocaleString("es-mx", {
                timeZone: "UTC",
              })
              .replace(",", "")}`
          : "",
        "Fecha Surtido": element.fecha_surtido
          ? `${new Date(element.fecha_surtido)
              .toLocaleString("es-mx", {
                timeZone: "UTC",
              })
              .replace(",", "")}`
          : "",
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReporteErroresDeTimbrado = async () => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/erroresdetimbrado`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        "Id Log": element.id_log,
        DocNum: element.docnum,
        "Tipo Doc": element.tipo_doc,
        NumCard: element.numcard,
        UUID: element.uuid,
        "Is Solved": element.is_solved,
        "Estatus Timbrado": element.estatus_timbrado,
        "Type Error": element.Type_error,
        "Response Ekomercio": sanitizeString(element.response_ekomercio),
        "Texto A Timbrar": sanitizeString(element.text_to_timbrar),
        "Fecha de Creacion": element.created_at
          ? `${new Date(element.created_at)
              .toLocaleString("es-mx", {
                timeZone: "UTC",
              })
              .replace(",", "")}`
          : "",
        "Registro SP": element.reg_SP
          ? `${new Date(element.reg_SP)
              .toLocaleString("es-mx", {
                timeZone: "UTC",
              })
              .replace(",", "")}`
          : "",
      };
      aux.push(aux2);
    });

    function sanitizeString(value) {
      let cleanString = value.replace(/<\/?[^>]+(>|$)/g, "");
      // eslint-disable-next-line
      let convertedString = cleanString.replace(/[\x00-\x1F]/g, "");

      return convertedString;
    }

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReporteCuponesUsados = async (initDate, endDate) => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/cuponesusados?initDate=${initDate}&endDate=${endDate}`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        Pedido: element.increment_id,
        Correo: element.email,
        "cupon utilizado": element.coupon_code,
        "Monto del pedido": element.total
          ? parseFloat(element.total).toString()
          : "",
        "Monto descuento": element.discount_amount
          ? parseFloat(element.discount_amount).toString()
          : "",
        Total: element.grand_total
          ? parseFloat(element.grand_total).toString()
          : "",
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReporteCarritosAbandonados = async (initDate, endDate) => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/carritosabandonados?initDate=${initDate}&endDate=${endDate}`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        "id cliente": element.customer_id,
        Email: element.customer_email,
        Nombre: element.customer_firstname,
        "Apellido Paterno": element.customer_lastname,
        "Apellido Materno": element.customer_middlename,
        "Actualizado en": element.updated_at_mexico
          ? `${new Date(element.updated_at_mexico)
              .toLocaleString("es-mx", {
                timeZone: "UTC",
              })
              .replace(",", "")}`
          : "",
        Virtual: element.is_virtual === 0 ? "" : element.is_virtual,
        Productos: element.products,
        "Cantidad Q": element.quantity
          ? parseFloat(element.quantity).toString()
          : "",
        Total: element.grand_total
          ? parseFloat(element.grand_total).toString()
          : "",
        Orden: element.order_,
        Estatus: element.status,
        SKU: `'${element.sku}`,
        "Nombre Producto": element.name,
        "Cantidad I": element.qty ? parseFloat(element.qty).toString() : "",
        Precio: element.price ? parseFloat(element.price).toString() : "",
        "Total de la fila": element.row_total
          ? parseFloat(element.row_total).toString()
          : "",
        "Nombre Estudiante": element.student_name,
        Nivel: element.level,
        Grado: element.grade,
        "Id Escuela": element.school_id,
        Escuela: element.school,
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReporteDetalleDeProducto = async (initDate, endDate) => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/detalledeproducto?initDate=${initDate}&endDate=${endDate}`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        "Tipo producto": element.product_type,
        SKU: `${element.sku}'`,
        Atributo: element.attribute,
        "Nombre del producto": element.product_name,
        Decripcion: element.description
          ? cleanDescription(element.description)
          : "",
        "Descripcion corta": element.short_description
          ? cleanDescription(element.short_description)
          : "",
        Imagenes: element.images,
        "En stock": element.stock ? parseFloat(element.stock).toString() : "",
        Precio: element.price ? parseFloat(element.price).toString() : "",
        "Administrar stock": element.manage_stock,
        "Uso de configuracion para administrar stock":
          element.use_config_manage_stock,
        Genero: element.gender,
        "Tipo de impuesto": element.tax_type,
        "Articulo Inventariable": element.articulo_inventariable,
        Tienda: element.store,
        Dress_type: element.dresstype,
      };
      aux.push(aux2);
    });

    function cleanDescription(description) {
      var stripped = description.replace(/<[^>]+>/g, "");
      var cleaned = stripped.replace(/\s+/g, " ");
      return cleaned;
    }

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReporteListasPublicadasEnColegios = async (idPeriodo) => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/listaspublicadasencolegios?periodo=${idPeriodo}`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        "id colegio": element.school_external_id,
        Colegio: element.name,
        Grado: element.area,
        Temas: element.subject,
        Categoria: element.category_name,
        Subcategoria: element.subcategory_name,
        SKU: `'${element.sku}`,
        Producto: element.product,
        Grupo: element.grupo,
        Precio: element.precio ? parseFloat(element.precio).toString() : "",
        Alias: element.grade_alias_name,
        Requerido: element.is_required,
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReportePoblacionDeColegios = async (initDate, endDate) => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/poblaciondecolegios?initDate=${initDate}&endDate=${endDate}`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        "id colegio": element["ID Escuela"],
        Colegio: element.Escuela,
        Nivel: element.Nivel,
        Turno: element.Turno,
        Grado: element.Grado,
        Masculino: element.Masculino,
        Femenino: element.Femenino,
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReporteOutOfStockSubscribers = async (initDate, endDate) => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/outofstocksubscribers?initDate=${initDate}&endDate=${endDate}`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        id: element.id,
        SKU: element.sku,
        "Suscrito en": element.subscribed_at
          ? `${new Date(element.subscribed_at)
              .toLocaleString("es-mx", {
                timeZone: "UTC",
              })
              .replace(",", "")}`
          : "",
        Email: element.email,
        Pedido: element.order_increment_id,
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReporteMTeam = async (initDate, endDate) => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/mteam?initDate=${initDate}&endDate=${endDate}`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let status = element.status;
      status = status === "paid" ? "Pagado" : status;
      status = status === "canceled" ? "Cancelado" : status;
      status = status === "pending" ? "Pendiente" : status;
      let aux2 = {
        Pedido: element.increment_id,
        Estatus: status,
        customer_id: element.customer_id,
        email: element.email,
        "Gasto de envio": element.shipping_amount
          ? parseFloat(element.shipping_amount).toString()
          : "",
        Impuesto: element.tax_amount
          ? parseFloat(element.tax_amount).toString()
          : "",
        grand_total: element.grand_total
          ? parseFloat(element.grand_total).toString()
          : "",
        "Metodo Pago SAP": element.sap_method_name,
        "Fecha de creacion": element.created_at
          ? `${new Date(element.created_at)
              .toLocaleString("es-mx", {
                timeZone: "UTC",
              })
              .replace(",", "")}`
          : "",
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReporteBundles = async (initDate, endDate) => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/bundles?initDate=${initDate}&endDate=${endDate}`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        "Fecha de Pago": element.payment_date
          ? `${new Date(element.payment_date)
              .toLocaleString("es-mx", {
                timeZone: "UTC",
              })
              .replace(",", "")}`
          : "",
        Pedido: element.increment_id,
        "ID bundle": element.id_bundle,
        "Nombre Bundle": element.bundle_name,
        Sku: `'${element.product_sku}`,
        "Nombre Producto": element.product_name,
        Cantidad: element.quantity,
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReporteMontosWebM = async (initDate, endDate) => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/montoswebm?initDate=${initDate}&endDate=${endDate}`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        Pedido: element.increment_id,
        Estatus: element.status,
        "Enviado a SAP": element.sendsap,
        "Fecha de Pago": element.payment_date
          ? `${new Date(element.payment_date)
              .toLocaleString("es-mx", {
                timeZone: "UTC",
              })
              .replace(",", "")}`
          : "",
        "Credito de tienda": element.store_credit,
        Comision: element.comission,
        Tarifa: element.tax_amount,
        "Grand Total": element.grand_total,
        "Descuento Total": element.total_discount,
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReporteNPS = async (initDate, endDate) => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/nps?initDate=${initDate}&endDate=${endDate}`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        Correo: element.email,
        "Numero de orden": element.increment_id,
        Rating: element.rating,
        "Fecha de encuesta": element.creation_time
          ? `${new Date(element.creation_time)
              .toLocaleString("es-mx", {
                timeZone: "UTC",
              })
              .replace(",", "")}`
          : "",
        Colegio: element.colegio,
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReporteStoreCredit = async () => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/storecredit`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        Correo: element.email,
        Escuela: element.name,
        Monto: `$${element.amount_per_school}`,
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReporteGastosEnvios = async (initDate, endDate) => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/gastosenvios?initDate=${initDate}&endDate=${endDate}`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        Pedido: element.increment_id,
        "Codigo Postal": `'${element.shipping_postal_code}'`,
        "Costo de envio": `$${element.shipping_amount}`,
        peso: element.total_weight,
        "tipo de envio": element.shipping_mode,
        "fecha WMS": element.date_sended_logistic
          ? `${new Date(element.date_sended_logistic)
              .toLocaleString("es-mx", {
                timeZone: "UTC",
              })
              .replace(",", "")}`
          : "",
        "Fecha de pago": element.payment_date
          ? `${new Date(element.payment_date)
              .toLocaleString("es-mx", {
                timeZone: "UTC",
              })
              .replace(",", "")}`
          : "",
        guia: `'${element.guia}`,
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReporteClientesMagento = async () => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/magentoclients`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        "Id Customer": element.customer_id,
        Nombre: element.name,
        "Apellido Paterno": element.lastname,
        "Apellido Materno": element.mothersname,
        Email: element.email,
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReportePreciosArticulosMagento = async () => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/preciosarticulosmagento`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        SKU: element.SKU,
        Producto: element.product,
        Precio: element.precio,
        Grupo: element.Grupo,
        Promocion: element.Promocion,
        "Importe de Descuento": element.ImporteDescuento,
        Estatus: element.Estatus,
        "Venta Neta": element.VentaNeta,
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReporteImportacionConfigurablesCalzado = async () => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/importacionconfigurables`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        sku_configurable: element.value,
        name: element.itemname,
        description: element.description,
        short_description: element.grados,
        type_of_tax: element.a3,
        categories: element.coords,
        set_of_attributes: element.category,
        sku_simples: element.skus,
        filter_attributes: element.talla,
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReporteActualizacionDeInformacionCalzado = async () => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/calzadoactualizacioninformacion`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        sku: element[0],
        status: element[1],
        meta_description: element[2],
        description: element[3],
        short_descripcion: element[4],
        gender: element[5],
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReporteImportacionDeListasCalzado = async () => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/importaciondelistas`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        category_id: element.value1,
        category: element.value2,
        alias: element.value3,
        order_for_categories: element.value4,
        subcategory_id: element.value5,
        subcategory: element.value6,
        subcategory_alias: element.value7,
        order_for_subcategories: element.value8,
        college_id: element.value9,
        school: element.value10,
        school_comment: element.value11,
        period_id: element.value12,
        period: element.value13,
        school_level_id: element.value14,
        school_level: element.value15,
        school_level_alias: element.value16,
        turn_id: element.value17,
        turn: element.value18,
        grade_id: element.value19,
        grade: element.value20,
        grade_alias: element.value21,
        grade_comment: element.value22,
        grade_order: element.value23,
        subject_id: element.value24,
        subject: element.value25,
        area_id: element.value26,
        area: element.value27,
        sku: element.value28,
        suggested_quantity: element.value29,
        product_comment: element.value30,
        layout: element.value31,
        modify: element.value32,
        opening_date: element.value33,
        closing_date: element.value34,
        is_required: element.value35,
        dress_type_name: element.value36,
        order_for_products: element.value37,
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReporteMontosWebS = async (initDate, endDate) => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/montoswebs?initDate=${initDate}&endDate=${endDate}`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        "Numero Factura": element.NoFacturaSAP,
        Pedido: element.Pedido,
        "Fecha de la factura": element.FechaFactura
          ? `${new Date(element.FechaFactura)
              .toLocaleString("en-US", {
                timeZone: "UTC",
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                millisecond: "numeric",
                hour12: true,
              })
              .replace(/,/g, "")}`
          : "",
        "Total de la factura": element.TotalFactura,
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReporteModeloResurtidoTiendas = async (initDate, endDate) => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/modeloresurtidotiendas?initDate=${initDate}&endDate=${endDate}`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        SKU: element.Articulo,
        Nombre: element.Descripcion,
        Acumulado: element.Acumulado,
        Almacen: element.Almacen,
        Categoria: element.Categoria,
        Semana: element.Semana,
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReporteSkusConfigurablesSimples = async () => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/skusconfigurables`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        "Sku Configurable": element.sku_configurable,
        "Descripcion Sku Configurable": element.descripcion,
        "Sku Simple": `'${element.sku_simple}`,
        "Descripcion Sku Simple": element.descripcion_sku_simple,
        "Talla Sku Simple": element.talla_sku_simple,
        "Precio Sku Simple": element.precio_sku_simple
          ? parseFloat(element.precio_sku_simple).toString()
          : "",
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReportePaqueteUtiles = async () => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/paqueteutilessap`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let key = Object.keys(element)[0];
      let data = element[key];
      let aux2 = {
        SKU: `'${key}`,
        Descripcion: data.Nombre,
        Stock: data.stock,
        OC: data.OC,
        Pagados: data.pagados,
        Surtiendose: data.pagadosSurtidos,
        Pendientes: data.pendientes,
        Faltantes: data.faltantes,
        "Faltantes Reales": data.faltantesReales,
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReporteRMAFinanzas = async (initDate, endDate) => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/rmafinanzas?initDate=${initDate}&endDate=${endDate}`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        Pedido: element.Pedido,
        RMA: element.RMA,
        FechaCreacion: element.FechaCreacion
          ? `${new Date(element.FechaCreacion)
              .toLocaleString("es-mx", {
                timeZone: "UTC",
              })
              .replace(",", "")}`
          : "",
        Colegio: element.Colegio,
        EstatusStoreCredit: element.EstatusStoreCredit,
        EstatusReembolso: element.EstatusReembolso,
        EstatusCambio: element.EstatusCambio,
        Fecha_Solicitud_A_Finanzas: element.fecha_solicitudAFinanzas
          ? `${new Date(element.fecha_solicitudAFinanzas)
              .toLocaleString("es-mx", {
                timeZone: "UTC",
              })
              .replace(",", "")}`
          : "",
        Fecha_Liberacion_Finanzas: element.fecha_liberacionFinanzas
          ? `${new Date(element.fecha_liberacionFinanzas)
              .toLocaleString("es-mx", {
                timeZone: "UTC",
              })
              .replace(",", "")}`
          : "",
        Dias_Transcurridos: element.diasTranscurridos,
        "Nota de Credito": element.nota_credito,
        "Importe Store Credit": element.importe_store_credit,
        Reembolso: element.cantidad_reembolso,
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReporteAclaracionesFinanzas = async (initDate, endDate) => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/aclarfinanzas?initDate=${initDate}&endDate=${endDate}`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        Pedido: element.id_orden,
        TipoAclaracion: element.tipo_aclaracion,
        FechaCreacion: element.creation_date
          ? `${new Date(element.creation_date)
              .toLocaleString("es-mx", {
                timeZone: "UTC",
              })
              .replace(",", "")}`
          : "",
        FechaSolicitud: element.fecha_solicitud
          ? `${new Date(element.fecha_solicitud)
              .toLocaleString("es-mx", {
                timeZone: "UTC",
              })
              .replace(",", "")}`
          : "",
        FechaLiberacion: element.fecha_liberacion
          ? `${new Date(element.fecha_liberacion)
              .toLocaleString("es-mx", {
                timeZone: "UTC",
              })
              .replace(",", "")}`
          : "",
        Dias: element.dias,
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReporteEntradasMercanciaDevoluciones = async (initDate, endDate) => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/entradasdevoluciones?initDate=${initDate}&endDate=${endDate}`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        "Numero de Entrada de Mercancía": element.NumeroDocumento,
        "Fecha Contabilización": element.FechaConta
          ? new Date(element.FechaConta).toLocaleDateString("es-MX", {
              timeZone: "UTC",
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })
          : "",
        "Codigo Proveedor": element.CodigoProveedor,
        "Nombre Proveedor": element.NombreProveedor,
        SKU: element.SKU,
        "Nombre Producto": element.NombreProducto,
        Cantidad: element.CantidadEMI,
        "Precio Unitario": element.PrecioUnitario,
        "Precio Costo": element.PrecioCosto,
        "Descuento Linea": element.DescuentoLinea,
        TotalLinea: element.TotalLinea,
        Almacen: element.Almacen,
        "Pendiente Por Recibir": element.PendientePorRecibir,
        Status: element.Status,
        "Numero de devolucion": element.NumDocumento,
        "Fecha de contabilización": element.FechaDoc
          ? new Date(element.FechaDoc).toLocaleDateString("es-MX", {
              timeZone: "UTC",
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })
          : "",
        "Codigo de barras": element.Sku,
        Descripcion: element.Producto,
        "Cantidad ": element.Cantidad,
        "Precio por unidad": element.PrecioUnidad,
        "% de descuento": element.PorcentajeDescuento,
        "Indicador de impuestos": element.IndicadorImpuesto,
        "Precio bruto": element.PrecioUnidadTotal,
        Total: element.TotalDoc,
        "Almacen ": element.WHS,
        "Cuenta de Mayor": element.ACC,
        "Centro de Costos": element.OCR,
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReporteSuscripcionesMailing = async () => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/suscripcionesmailing`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        Cliente_Id: element.customer_id,
        Email: element.email,
        Escuelas: element.escuelas,
        Noticias: element.noticias,
        Promociones: element.promociones,
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReporteVentasNetas = async (initDate, endDate) => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/ventasnetas?initDate=${initDate}&endDate=${endDate}`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        NumeroDocto: element.DocNum,
        FechaDocumentoSAP: element.FechaSap
          ? new Date(element.FechaSap).toLocaleDateString("es-MX", {
              timeZone: "UTC",
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })
          : "",
        FechaMagento: element.FechaMagento
          ? new Date(element.FechaMagento).toLocaleDateString("es-MX", {
              timeZone: "UTC",
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })
          : "",
        TipoDocto: element.CardCode,
        Pedido: element.Pedido,
        LineNum: element.LineaNumero,
        SKU: element.Sku,
        Producto: element.Producto,
        Cantidad: element.Cantidad,
        "Precio Venta": element.Precio,
        IVA: element.Impuestos,
        CostoGEA: element.ValorStock,
        TotalLinea: element.LineTotal,
        MetodoPago: element.MetodoPago,
        Almacen: element.Almacen,
        Inventariable: element.Inventariable,
        "Id Categoria": element.IDCategoria,
        Categoria: element.Categoria,
        "Alumno ": element.Alumno,
        "Grupo Colegios": element.GrupoColegios,
        "Grupo Articulos": element.GrupoArticulos,
        Id_Colegio: element.Id_school,
        Colegio: element.Colegio,
        "ID Nivel": element.Id_nivel,
        "Nivel ": element.Nivel,
        Id_Grado: element.Id_Grado,
        Grado: element.Grade,
        Cliente: element.Cliente,
        Email: element.Email,
        "ID Proveedor": element.Proveedor,
        Proveedor: element.ProveedorNombre,
        Editorial: element.Editorial,
        Activo: element.is_active,
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReporteStock = async () => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/stock`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        SKU: element.ItemCode,
        "Nombre del producto": element.ItemName,
        "Nombre de grupo": element.Grupo,
        Categoria: element.Categoria,
        "Nombre de la editorial": element.U_Editorial,
        "Nombre del proveedor": element.CardName,
        "Stock magento": element.stock_magento,
        "Stock SAP": element.stock,
        "Pendientes Factura (CRON)": element.pend_sap,
        "Pendientes Factura (Inventario)": element.b,
        "Pendientes Pago": element.suma_pend_pagos,
        "Pagados pendientes por asignar": element.pagosPendientePorAsignar,
        "Minimo 5%": element.min,
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReporteStockAccesos = async () => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/stockaccesos`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        SKU: element[1],
        "Nombre del producto": element[2],
        "Stock magento": element[3],
        "Stock magento (formula)": element[4],
        "DIF Stock magento - Stock magento (formula)": element[5],
        "Stock SAP": element[6],
        "DIF Stock SAP - Pendientes Factura - Pendientes Pago - Minimo 5% - Stock magento (formula)":
          element[7],
        "Accesos Disponibles": element[8],
        "DIF Accesos Disponibles + Pendientes Factura + Pendientes Pago - Stock SAP - Pagados pendientes por asignar":
          element[9],
        "Pendientes Factura": element[10],
        "Pagados pendientes por asignar": element[11],
        "Pendientes Pago": element[12],
        "Minimo 5%": element[13],
        Tipo: element[14],
        Inventariable: element[15],
        "Id Proveedor": element[16],
        Proveedor: element[17],
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReporteVentas = async () => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/ventas`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        Distribuidora: element.distribuidora,
        "Fecha de creacion": element.fecha_creacion,
        "Fecha de creacion ": element.fecha,
        "Numero ticket": element.numero_Ticket,
        SKU: `'${element.sku}'`,
        Descripcion: element.descripcion,
        Cantidad: element.cantidad,
        "Precio unitario": element.precio_unitario,
        "Total linea": element.total_linea,
        sesion: element.sesion,
        status: element.status,
        usuario: element.usuario,
        Correo: element.email,
        Categoria: element.categoria,
        Categoria_Nueva: element.cat,
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReporteMovimientos = async () => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/movimientos`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        "id distribuidora": element.id,
        Distribuidora: element.distribuidora,
        Movimiento: element.movimiento,
        categoria: element.categoria,
        Producto: element.producto,
        SKU: element.sku,
        Precio: element.precio,
        Cantidad: element.cantidad_mov,
        Inventario: element.c_total,
        "Total de linea": element.totalLinea,
        "Tipo de movimiento": element.tipo_de_movimiento,
        "Fecha Movimiento": element.fecha_movimiento,
        "Fecha Movimiento ": element.fecha_movimiento_sinhora,
        usuario: element.usuario,
        Correo: element.correo,
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReporteTotalInventario = async () => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/totalinventario`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        iddistribuidora: element.id,
        Distribuidora: element.distribuidora,
        Nombre: element.producto,
        SKU: element.sku,
        Total: element.c_total,
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReporteTraspasosEnTransito = async () => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/traspasosentransito`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        "id distribuidora": element.id,
        Origen: element.distribuidora,
        Movimiento: element.movimiento,
        categoria: element.categoria,
        Producto: element.producto,
        SKU: element.sku,
        Precio: element.precio,
        Cantidad: element.cantidad,
        "Total de linea": element.totalDeLinea,
        "Tipo de movimiento": element.tipo_de_movimiento,
        Destino: element.id_dist_move,
        "Distribuidora M": element.distribuidora_movimiento,
        "Fecha Movimiento": element.fecha_movimiento,
        "Fecha Movimiento ": element.fecha_movimiento_sinhora,
        usuario: element.usuario,
        Correo: element.correo,
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

const getReportePedidosNotSAPListaMateriales = async () => {
  const URL = `${process.env.REACT_APP_BASEURL}/reportes/pedidoslistamateriales`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        Pedido: element.increment_id,
        "Fecha de Pago": element.payment_date
          ? `${new Date(element.payment_date)
              .toLocaleString("es-mx", {
                timeZone: "UTC",
              })
              .replace(",", "")}`
          : "",
        SKU_LM: element.sku_father,
        SKU_LM_Item: element.sku_son,
        Descripcion: element.description,
        "Stock SAP": element.stock,
        "Cantidad Pedido": element.qty,
        Restante: element.minus,
        "Fecha Actualizacion": element.created_at
          ? `${new Date(element.created_at)
              .toLocaleString("es-mx", {
                timeZone: "UTC",
              })
              .replace(",", "")}`
          : "",
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

export {
  getReportePedidosEscuelas,
  getReporteNpsTiendas,
  getReporteVentaMayoreoGeneral,
  getReporteRMASkus,
  getReporteAclaracionesSku,
  getReporteNPSKlipp,
  getReporteCasos,
  getReporteCasosyAsociados,
  getReporteInventarios,
  getReporteComentariosAclaraciones,
  getReporteTickets,
  getReportePedidosPorFecha,
  getReporteErroresDeTimbrado,
  getReporteCuponesUsados,
  getReporteCarritosAbandonados,
  getReporteDetalleDeProducto,
  getReporteListasPublicadasEnColegios,
  getReportePoblacionDeColegios,
  getReporteOutOfStockSubscribers,
  getReporteMTeam,
  getReporteBundles,
  getReporteMontosWebM,
  getReporteNPS,
  getReporteStoreCredit,
  getReporteGastosEnvios,
  getReporteClientesMagento,
  getReportePreciosArticulosMagento,
  getReporteImportacionConfigurablesCalzado,
  getReporteActualizacionDeInformacionCalzado,
  getReporteImportacionDeListasCalzado,
  getReporteMontosWebS,
  getReporteModeloResurtidoTiendas,
  getReporteSkusConfigurablesSimples,
  getReportePaqueteUtiles,
  getReporteRMAFinanzas,
  getReporteAclaracionesFinanzas,
  getReporteEntradasMercanciaDevoluciones,
  getReporteSuscripcionesMailing,
  getReporteVentasNetas,
  getReporteStock,
  getReporteStockAccesos,
  getReporteVentas,
  getReporteMovimientos,
  getReporteTotalInventario,
  getReporteTraspasosEnTransito,
  getReportePedidosNotSAPListaMateriales,
};
