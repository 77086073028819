import { useState } from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Forum as ForumIcon,
  LocalGroceryStore as LocalGroceryStoreIcon,
  ContactPage as ContactPageIcon,
  Receipt as ReceiptIcon,
  LocalShipping as LocalShippingIcon,
} from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";
import { Warning as WarningIcon } from "@mui/icons-material";
import { MessageTab } from "./MessageTab";
import { ProductosTab } from "./ProductosTab";
import { OrdenDeCompraTab } from "./OrdenDeCompraTab";
import { AlamacenTab } from "./AlamacenTab";
import { TranspasosTab } from "./TranspasosTab";
import { GuiasTap } from "./GuiasTap";

export const IndexTabs = ({
  id,
  ordCotizacion,
  ordenVenta,
  numCotizaacion,
  idRequerimiento,
  toggleEstado
}) => {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab
              icon={<LocalGroceryStoreIcon />}
              iconPosition="start"
              label="Productos"
              value="1"
            />
            <Tab
              icon={<ForumIcon />}
              iconPosition="start"
              label="Mensajes"
              value="2"
            />

            <Tab
              icon={<ReceiptIcon />}
              iconPosition="start"
              label="Ordenes de compra"
              value="3"
            />

            <Tab
              icon={<LocalShippingIcon />}
              iconPosition="start"
              label="Almacenes"
              value="4"
            />
            <Tab
              icon={<LocalShippingIcon />}
              iconPosition="start"
              label="Traspasos"
              value="5"
            />
            <Tab
              icon={<LocalShippingIcon />}
              iconPosition="start"
              label="Guías"
              value="6"
            />
          </TabList>
        </Box>
        <TabPanel value="1">
          {idRequerimiento && ordCotizacion && ordenVenta ? (
            <ProductosTab
              idRequerimiento={idRequerimiento}
              ordCotizacion={ordCotizacion}
              ordenVenta={ordenVenta}
              id={id}
            />
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </TabPanel>
        <TabPanel value="2">
          {idRequerimiento ? (
            <MessageTab idRequerimiento={idRequerimiento} />
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </TabPanel>
        <TabPanel value="3">
          {ordenVenta ? (
            <OrdenDeCompraTab ordenVenta={ordenVenta} />
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </TabPanel>
        <TabPanel value="4">
          {true ? (
            <AlamacenTab ordenVenta={ordenVenta} />
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </TabPanel>
        <TabPanel value="5">
          {ordenVenta ? (
            <TranspasosTab ordenVenta={ordenVenta} />
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </TabPanel>
        <TabPanel value="6">
          {idRequerimiento && numCotizaacion ? (
            <GuiasTap
              idRequerimiento={idRequerimiento}
              numCotizaacion={numCotizaacion}
              id={id}
              toggleEstado={toggleEstado}
            />
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </TabPanel>
      </TabContext>
    </Box>
  );
};
