import {
  Box,
  Button,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import DateInTable from "../../../general/DateInTable";
import { addTransaction } from "../../../../utils/ventaMayoreo/traspasos/traspasos";

export const InfoTraspaso = ({ traspaso, id, reloadPageFn }) => {
  const [numeroTransaccion, setNumeroTransaccion] = useState("");
  const [errorTransaccion, setErrorTransaccion] = useState("");

  const handleInputChange = (event) => {
    setNumeroTransaccion(event.target.value);
    if (event.target.value.length >= 2) {
      setErrorTransaccion("");
    }
  };

  const validarTransaccion = () => {
    if (numeroTransaccion.length < 2) {
      setErrorTransaccion(
        "El número de transacción debe tener al menos dos caracteres"
      );
      return false;
    }
    return true;
  };

  const generarOrden = async () => {
    if (validarTransaccion()) {
      let data = {
        id: parseInt(id),
        numeroTransaccion: numeroTransaccion,
      };
      console.log(data);
      await addTransaction(data);
      setNumeroTransaccion("");
      reloadPageFn();
    }
  };

  function encodeUtf8(val) {
    try {
      return decodeURIComponent(escape(val));
    } catch (err) {
      return val;
    }
  }

  return (
    <>
      <Box
        sx={{
          border: "1px solid #dedede",
          borderTop: "3px solid #00a65a",
          margin: "10px",
          marginBottom: "25px",
          borderRadius: "5px",
        }}
      >
        <Box sx={{ borderBottom: "1px solid #dedede" }}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "1.18rem",
              margin: "7px",
              marginLeft: "12px",
            }}
          >
            Orden de compra
          </Typography>
        </Box>
        <Box>
          {traspaso ? (
            <Table size="small">
              <TableBody>
                <TableRow sx={{ backgroundColor: "#0000000a" }}>
                  <TableCell align="left">
                    <Typography variant="subtitle2">Traspaso:</Typography>
                  </TableCell>
                  <TableCell align="right">
                    {traspaso.numero_transaccion
                      ? encodeUtf8(traspaso.numero_transaccion)
                      : ""}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">
                    <Typography variant="subtitle2">Fecha de alta:</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      component="div"
                      sx={{ h6: { fontWeight: "normal" } }}
                    >
                      <DateInTable dateString={traspaso.created_date} />
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow sx={{ backgroundColor: "#0000000a" }}>
                  <TableCell align="left">
                    <Typography variant="subtitle2">Estatus:</Typography>
                  </TableCell>
                  <TableCell align="right">
                    {traspaso.id_cat_status === undefined ||
                    traspaso.id_cat_status === null ? (
                      <Button
                        variant="contained"
                        color="warning"
                        size="small"
                        sx={{
                          textTransform: "none",
                          lineHeight: 1,
                          fontSize: "0.7rem",
                          minWidth: 0,
                        }}
                      >
                        S/Estatus
                      </Button>
                    ) : traspaso.id_cat_status === 33 ? (
                      <Button
                        variant="contained"
                        color="warning"
                        size="small"
                        sx={{
                          textTransform: "none",
                          lineHeight: 1,
                          fontSize: "0.7rem",
                          minWidth: 0,
                        }}
                      >
                        {traspaso.cat_nombre}
                      </Button>
                    ) : traspaso.id_cat_status === 34 ? (
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        sx={{
                          textTransform: "none",
                          lineHeight: 1,
                          fontSize: "0.7rem",
                          minWidth: 0,
                        }}
                      >
                        {traspaso.cat_nombre}
                      </Button>
                    ) : null}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          ) : (
            <Box sx={{ width: "100%", padding: "5px" }}>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
            </Box>
          )}
        </Box>
      </Box>

      {traspaso && !traspaso.numero_transaccion && (
        <Box
          sx={{
            border: "1px solid #dedede",
            borderTop: "3px solid #00a65a",
            margin: "10px",
            marginBottom: "25px",
            borderRadius: "5px",
          }}
        >
          <Box sx={{ borderBottom: "1px solid #dedede" }}>
            <Typography
              variant="h6"
              sx={{
                fontSize: "1.18rem",
                margin: "7px",
                marginLeft: "12px",
              }}
            >
              Asignar número transacción
            </Typography>
          </Box>
          <Box
            sx={{
              padding: "10px",
            }}
          >
            <TextField
              fullWidth
              label="Número de transacción"
              variant="outlined"
              size="small"
              value={numeroTransaccion}
              onChange={handleInputChange}
              error={!!errorTransaccion}
              helperText={errorTransaccion}
              sx={{
                marginRight: "10px",
                marginY: "10px",
              }}
            />
            <Box
              sx={{ display: "flex", justifyContent: "end", marginY: "5px" }}
            >
              <Button variant="contained" color="info" onClick={generarOrden}>
                Generar Orden
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
