import { profiles } from "./profilesCapitalHumano";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
const dicon = <RadioButtonUncheckedIcon fontSize='small' />;

export const capitalHumanoModules = [
    {
        label: "Aprobación de vacaciones",
        icon: dicon,
        slug: "aprobacion-vacaciones",
        profiles: profiles.aprobarVac
    },
    {
        label: "Administrar vacaciones",
        icon: dicon,
        slug: "administrar-vacaciones",
        profiles: profiles.administrarVac
    },
    {
        label: "Solicitar vacaciones",
        icon: dicon,
        slug: "solicitar-vacaciones",
        profiles: profiles.solicitarVac
    },
    {
        label: "Préstamos",
        icon: dicon,
        slug: "prestamos",
        profiles: profiles.prestamos
    },
];