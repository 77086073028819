import {
  AppBar,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
} from "@mui/material";
import { useState } from "react";
import TabUpdateBeneficiary from "./TabUpdateBeneficiary";
import TabChildrensList from "./TabChildrensList";
import TapAddChildren from "./TapAddChildren";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const DialogUpdateBeneficiario = ({
  open,
  onClose,
  schools,
  setLoading,
  setRows,
  setEstado,
  updateUsr,
  beneficiaryIdUpdate,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Dialog open={open} maxWidth="lg">
      <AppBar position="static">
        <DialogTitle sx={{ paddingX: "24px", paddingY: "8px" }}>
          Modificar Beneficiario
        </DialogTitle>
      </AppBar>

      <Tabs value={value} onChange={handleChange} sx={{ minHeight: 0 }}>
        <Tab label="Editar Beneficiario" />
        <Tab label="Agregar Hijo" />
        <Tab label="Editar Hijos" />
      </Tabs>
      <DialogContent sx={{ padding: 0 }}>
        <TabPanel value={value} index={0}>
          <TabUpdateBeneficiary
            onClose={onClose}
            schools={schools}
            setLoading={setLoading}
            setRows={setRows}
            setEstado={setEstado}
            updateUsr={updateUsr}
            open={open}
            beneficiaryIdUpdate={beneficiaryIdUpdate}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TapAddChildren
            onClose={onClose}
            schools={schools}
            creationUsr={updateUsr}
            beneficiaryIdUpdate={beneficiaryIdUpdate}
            setLoading={setLoading}
            setRows={setRows}
            setEstado={setEstado}
            setValue={setValue}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <TabChildrensList
            onClose={onClose}
            schools={schools}
            open={open}
            beneficiaryIdUpdate={beneficiaryIdUpdate}
            updateUsr={updateUsr}
            setLoading={setLoading}
            setRows={setRows}
            setEstado={setEstado}
            setValue={setValue}
          />
        </TabPanel>
      </DialogContent>
    </Dialog>
  );
};

export default DialogUpdateBeneficiario;
