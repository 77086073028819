import {
  Avatar,
  Box,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";
import { styled } from "@mui/material/styles";
import { urled } from "../utils/strings";
import { useTheme } from "@mui/material";
import luisimage from "../assets/49c16a38805735.57701dcdd452c.gif";

import MenuIcon from "@mui/icons-material/Menu";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
// import PushPinIcon from "@mui/icons-material/PushPin";
import GroupIcon from "@mui/icons-material/Group";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
// import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
// import RequestPageIcon from "@mui/icons-material/RequestPage";
// import ConstructionIcon from "@mui/icons-material/Construction";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import BadgeIcon from "@mui/icons-material/Badge";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import CancelIcon from "@mui/icons-material/Cancel";

import { controlAccesosModules } from "../utils/controlAccesos/modules";
import { belongsControlAccesos } from "../utils/controlAccesos/profilesControlAccesos";
import { trazabilidadModules } from "../utils/trazabilidad/modules";
import { belongsTrazabilidad } from "../utils/trazabilidad/profilesTrazabilidad";
import { altoRiesgoModules } from "../utils/altoRiesgo/modules";
import { belongsAltoRiesgo } from "../utils/altoRiesgo/profilesAltoRiesgo";
import { ventaMayoreoModules } from "../utils/ventaMayoreo/modules";
import { belongsVentaMayoreo } from "../utils/ventaMayoreo/profilesVentaMayoreo";
import { proveedoresModules } from "../utils/proveedores/modules";
import { belongsProveedores } from "../utils/proveedores/profilesProveedores";
import { valesModules } from "../utils/vales/modules";
import { belongsVales } from "../utils/vales/profilesVales";
import { belongsReportes } from "../utils/reportes/profilesReportes";
import { capitalHumanoModules } from "../utils/capitalHumano/modules";
import { belongsCapitalHumano } from "../utils/capitalHumano/profilesCapitalHumano";
import { pagosModules } from "../utils/pagos/modules";
import { belongsPagos } from "../utils/pagos/profilesPagos";
import { belongsCRM } from "../utils/crm/profilesCRM";
import { belongsCancelaciones } from "../utils/cancelaciones/profilesCancelaciones";

const drawerWidth = 280;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: `${drawerWidth}px`,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1),
}));

// const modulesRestantes = [
//   {
//     name: "Klipp",
//     icon: <PushPinIcon />,
//   },
//   {
//     name: "Herramientas",
//     icon: <ConstructionIcon />,
//   },
//   {
//     name: "Atencion a clientes",
//     icon: <HeadsetMicIcon />,
//   },
//   {
//     name: "Presupuestos",
//     icon: <RequestPageIcon />,
//   },
// ];

const Dashboard = ({ children }) => {
  const theme = useTheme();
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [nextRender, setNextRender] = useState(false);
  const [openMenu, setOpenMenu] = useState(true);
  const [openMenuDropdown, setOpenMenuDropdown] = useState({
    altoRiesgo: false,
    capitalH: false,
    controlA: false,
    pagos: false,
    proveedores: false,
    trazabilidad: false,
    vales: false,
    ventaM: false,
  });

  const handleLogOut = async () => {
    await logout();
    window.location.href = "/";
  };

  const handleChangeOpenMenu = (name, actual) => {
    setOpenMenuDropdown((prevalue) => {
      return {
        ...prevalue,
        [name]: !actual,
      };
    });
  };

  function isProfileInclude(perfiles, perfil) {
    return perfiles.includes(perfil);
  }

  useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true });
    } else setNextRender(true);
  }, [user, navigate]);

  return (
    <>
      {nextRender && (
        <Box sx={{ display: "flex" }}>
          <AppBar position="fixed" open={openMenu}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => setOpenMenu((com) => !com)}
                edge="start"
                sx={{ marginRight: 3 }}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap component="div">
                Klaar <small>v2.0.0</small>
              </Typography>
            </Toolbar>
          </AppBar>

          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
            variant="persistent"
            anchor="left"
            open={openMenu}
          >
            <DrawerHeader>
              {user && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    my: 3,
                    color: theme.palette.primary.main,
                  }}
                >
                  <Avatar
                    src={ user.data.photoURL }
                    sx={{ width: 64, height: 64 }}
                    alt={user.data.displayName}
                  />
                  <Typography variant="h6" align="center" lineHeight={ 1.3 }>{user.data.displayName}</Typography>
                  <Typography variant="caption" align="center">{user.data.email}</Typography>
                </Box>
              )}
            </DrawerHeader>
            <Divider />
            <List>
              {belongsControlAccesos(user.role.idProfile) && (
                <>
                  <ListItemButton
                    onClick={() =>
                      handleChangeOpenMenu(
                        "controlA",
                        openMenuDropdown.controlA
                      )
                    }
                  >
                    <ListItemIcon
                      sx={{ ml: 1, mr: -1, color: theme.palette.primary.main }}
                    >
                      <ManageAccountsIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Control de accesos"} />
                    {openMenuDropdown.controlA ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItemButton>
                  <Collapse
                    in={openMenuDropdown.controlA}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List disablePadding>
                      {controlAccesosModules.map(
                        (tm) =>
                          isProfileInclude(
                            tm.profiles,
                            user.role.idProfile
                          ) && (
                            <Link
                              key={tm.label}
                              to={`/control-accesos/${urled(tm.slug)}`}
                              style={{
                                textDecoration: "none",
                                color: theme.palette.primary.main,
                              }}
                            >
                              <ListItemButton key={urled(tm.slug)}>
                                <ListItemText inset primary={tm.label} />
                              </ListItemButton>
                            </Link>
                          )
                      )}
                    </List>
                    <Divider />
                  </Collapse>
                </>
              )}

              {belongsTrazabilidad(user.role.idProfile) && (
                <>
                  <ListItemButton
                    onClick={() =>
                      handleChangeOpenMenu(
                        "trazabilidad",
                        openMenuDropdown.trazabilidad
                      )
                    }
                  >
                    <ListItemIcon
                      sx={{ ml: 1, mr: -1, color: theme.palette.primary.main }}
                    >
                      <ContentPasteSearchIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Trazabilidad"} />
                    {openMenuDropdown.trazabilidad ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItemButton>
                  <Collapse
                    in={openMenuDropdown.trazabilidad}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List disablePadding>
                      {trazabilidadModules.map(
                        (tm) =>
                          isProfileInclude(
                            tm.profiles,
                            user.role.idProfile
                          ) && (
                            <Link
                              key={tm.label}
                              to={`/trazabilidad/${urled(tm.slug)}`}
                              style={{
                                textDecoration: "none",
                                color: theme.palette.primary.main,
                              }}
                            >
                              <ListItemButton key={urled(tm.slug)}>
                                <ListItemText inset primary={tm.label} />
                              </ListItemButton>
                            </Link>
                          )
                      )}
                    </List>
                    <Divider />
                  </Collapse>
                </>
              )}

              {belongsAltoRiesgo(user.role.idProfile) && (
                <>
                  <ListItemButton
                    onClick={() =>
                      handleChangeOpenMenu(
                        "altoRiesgo",
                        openMenuDropdown.altoRiesgo
                      )
                    }
                  >
                    <ListItemIcon
                      sx={{ ml: 1, mr: -1, color: theme.palette.primary.main }}
                    >
                      <GppMaybeIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Alto riesgo"} />
                    {openMenuDropdown.altoRiesgo ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItemButton>
                  <Collapse
                    in={openMenuDropdown.altoRiesgo}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List disablePadding>
                      {altoRiesgoModules.map(
                        (tm) =>
                          isProfileInclude(
                            tm.profiles,
                            user.role.idProfile
                          ) && (
                            <Link
                              key={tm.label}
                              to={`/alto-riesgo/${urled(tm.slug)}`}
                              style={{
                                textDecoration: "none",
                                color: theme.palette.primary.main,
                              }}
                            >
                              <ListItemButton key={urled(tm.slug)}>
                                <ListItemText inset primary={tm.label} />
                              </ListItemButton>
                            </Link>
                          )
                      )}
                    </List>
                    <Divider />
                  </Collapse>
                </>
              )}

              {belongsVentaMayoreo(user.role.idProfile) && (
                <>
                  <ListItemButton
                    onClick={() =>
                      handleChangeOpenMenu("ventaM", openMenuDropdown.ventaM)
                    }
                  >
                    <ListItemIcon
                      sx={{ ml: 1, mr: -1, color: theme.palette.primary.main }}
                    >
                      <AutoGraphIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Venta mayoreo"} />
                    {openMenuDropdown.ventaM ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse
                    in={openMenuDropdown.ventaM}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List disablePadding>
                      {ventaMayoreoModules.map(
                        (tm) =>
                          isProfileInclude(
                            tm.profiles,
                            user.role.idProfile
                          ) && (
                            <Link
                              key={tm.label}
                              to={`/venta-mayoreo/${urled(tm.slug)}`}
                              style={{
                                textDecoration: "none",
                                color: theme.palette.primary.main,
                              }}
                            >
                              <ListItemButton key={urled(tm.slug)}>
                                <ListItemText inset primary={tm.label} />
                              </ListItemButton>
                            </Link>
                          )
                      )}
                    </List>
                    <Divider />
                  </Collapse>
                </>
              )}

              {belongsProveedores(user.role.idProfile) && (
                <>
                  <ListItemButton
                    onClick={() =>
                      handleChangeOpenMenu(
                        "proveedores",
                        openMenuDropdown.proveedores
                      )
                    }
                  >
                    <ListItemIcon
                      sx={{ ml: 1, mr: -1, color: theme.palette.primary.main }}
                    >
                      <BadgeIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Proveedores"} />
                    {openMenuDropdown.proveedores ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItemButton>
                  <Collapse
                    in={openMenuDropdown.proveedores}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List disablePadding>
                      {proveedoresModules.map(
                        (tm) =>
                          isProfileInclude(
                            tm.profiles,
                            user.role.idProfile
                          ) && (
                            <Link
                              key={tm.label}
                              to={`/proveedores/${urled(tm.slug)}`}
                              style={{
                                textDecoration: "none",
                                color: theme.palette.primary.main,
                              }}
                            >
                              <ListItemButton key={urled(tm.slug)}>
                                <ListItemText inset primary={tm.label} />
                              </ListItemButton>
                            </Link>
                          )
                      )}
                    </List>
                    <Divider />
                  </Collapse>
                </>
              )}

              {belongsVales(user.role.idProfile) && (
                <>
                  <ListItemButton
                    onClick={() =>
                      handleChangeOpenMenu("vales", openMenuDropdown.vales)
                    }
                  >
                    <ListItemIcon
                      sx={{ ml: 1, mr: -1, color: theme.palette.primary.main }}
                    >
                      <CreditCardIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Vales"} />
                    {openMenuDropdown.vales ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse
                    in={openMenuDropdown.vales}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List disablePadding>
                      {valesModules.map(
                        (tm) =>
                          isProfileInclude(
                            tm.profiles,
                            user.role.idProfile
                          ) && (
                            <Link
                              key={tm.label}
                              to={`/vales/${urled(tm.slug)}`}
                              style={{
                                textDecoration: "none",
                                color: theme.palette.primary.main,
                              }}
                            >
                              <ListItemButton key={urled(tm.slug)}>
                                <ListItemText inset primary={tm.label} />
                              </ListItemButton>
                            </Link>
                          )
                      )}
                    </List>
                    <Divider />
                  </Collapse>
                </>
              )}

              {belongsReportes(user.role.idProfile) && (
                <>
                  <Link
                    to={`/reportes`}
                    style={{
                      textDecoration: "none",
                      color: theme.palette.primary.main,
                    }}
                  >
                    <ListItemButton key={urled(`/reportes`)}>
                      <ListItemIcon
                        sx={{
                          ml: 1,
                          mr: -1,
                          color: theme.palette.primary.main,
                        }}
                      >
                        <FactCheckIcon />
                      </ListItemIcon>
                      <ListItemText primary={"Reportes"} />
                    </ListItemButton>
                  </Link>
                </>
              )}

              {belongsCapitalHumano(user.role.idProfile) && (
                <>
                  <ListItemButton
                    onClick={() =>
                      handleChangeOpenMenu(
                        "capitalH",
                        openMenuDropdown.capitalH
                      )
                    }
                  >
                    <ListItemIcon
                      sx={{ ml: 1, mr: -1, color: theme.palette.primary.main }}
                    >
                      <GroupIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Capital Humano"} />
                    {openMenuDropdown.capitalH ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItemButton>
                  <Collapse
                    in={openMenuDropdown.capitalH}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List disablePadding>
                      {capitalHumanoModules.map(
                        (tm) =>
                          isProfileInclude(
                            tm.profiles,
                            user.role.idProfile
                          ) && (
                            <Link
                              key={tm.label}
                              to={`/capital-humano/${urled(tm.slug)}`}
                              style={{
                                textDecoration: "none",
                                color: theme.palette.primary.main,
                              }}
                            >
                              <ListItemButton key={urled(tm.slug)}>
                                <ListItemText inset primary={tm.label} />
                              </ListItemButton>
                            </Link>
                          )
                      )}
                    </List>
                    <Divider />
                  </Collapse>
                </>
              )}

              {belongsPagos(user.role.idProfile) && (
                <>
                  <ListItemButton
                    onClick={() =>
                      handleChangeOpenMenu("pagos", openMenuDropdown.pagos)
                    }
                  >
                    <ListItemIcon
                      sx={{ ml: 1, mr: -1, color: theme.palette.primary.main }}
                    >
                      <AttachMoneyIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Pagos"} />
                    {openMenuDropdown.pagos ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse
                    in={openMenuDropdown.pagos}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List disablePadding>
                      {pagosModules.map(
                        (tm) =>
                          isProfileInclude(
                            tm.profiles,
                            user.role.idProfile
                          ) && (
                            <Link
                              key={tm.label}
                              to={`/pagos/${urled(tm.slug)}`}
                              style={{
                                textDecoration: "none",
                                color: theme.palette.primary.main,
                              }}
                            >
                              <ListItemButton key={urled(tm.slug)}>
                                <ListItemText inset primary={tm.label} />
                              </ListItemButton>
                            </Link>
                          )
                      )}
                    </List>
                    <Divider />
                  </Collapse>
                </>
              )}

              {belongsCancelaciones(user.role.idProfile) && (
                <>
                  <Link
                    to={`/cancelaciones`}
                    style={{
                      textDecoration: "none",
                      color: theme.palette.primary.main,
                    }}
                  >
                    <ListItemButton key={urled(`/cancelaciones`)}>
                      <ListItemIcon
                        sx={{
                          ml: 1,
                          mr: -1,
                          color: theme.palette.primary.main,
                        }}
                      >
                        <CancelIcon />
                      </ListItemIcon>
                      <ListItemText primary={"Cancelaciones"} />
                    </ListItemButton>
                  </Link>
                </>
              )}
            </List>

            <Divider />
            <List>
              <ListItemButton key={"Salir"} onClick={handleLogOut}>
                <ListItemIcon
                  sx={{ ml: 1, mr: -1, color: theme.palette.primary.main }}
                >
                  <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText
                  primary={"Salir"}
                  sx={{ color: theme.palette.primary.main }}
                />
              </ListItemButton>
            </List>
          </Drawer>

          <Main
            open={openMenu}
            sx={{
              flexGrow: 1,
              px: 3,
              pt: 10,
              minHeight: "100vh",
              backgroundColor: "#f5f5f5",
            }}
          >
            <DrawerHeader />
            <Box>{children}</Box>
          </Main>
        </Box>
      )}
    </>
  );
};

export default Dashboard;
