import { Typography, Grid, List, ListItem, ListItemText, } from "@mui/material";
import { Fragment } from "react";
import DateInTable from "../../general/DateInTable";

const BackordersDSDetail = ({ boId, detalle }) => {

    return (
        <>
            <Grid container>
                {detalle && (
                <>
                    <Grid item md={4}>
                        <List>
                            <ListItem>
                                <ListItemText primary="Número de pedido" secondary={detalle.increment_id}/>                                        
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Dirección de envío" secondary={
                                    <Fragment>
                                        <Typography variant="body2" >{detalle.shipping_name} {detalle.shipping_lastname} {detalle.shipping_mothersname}</Typography>
                                        <Typography variant="body2" >{detalle.shipping_street} #{detalle.shipping_outside_number} {(detalle.shipping_inside_number ? 'Int. '+detalle.shipping_inside_number : '')}</Typography>
                                        <Typography variant="body2" >{detalle.between_streets}</Typography>
                                        <Typography variant="body2" >{detalle.shipping_suburb}, {detalle.shipping_delegation}</Typography>
                                        <Typography variant="body2" >{detalle.shipping_city}, {detalle.shipping_state}</Typography>
                                        <Typography variant="body2" >C.P. {detalle.shipping_postal_code}</Typography>
                                    </Fragment>
                                } />
                            </ListItem>
                        </List>
                    </Grid>
                    
                    <Grid item md={4}>
                        <List>
                            <ListItem>
                                <ListItemText primary="Nombre de cliente" secondary={detalle.cname}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Teléfono" secondary={detalle.telf}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Email" secondary={detalle.emailAux}/>
                            </ListItem>
                        </List>
                    </Grid>

                    <Grid item md={4}>
                        <List>
                            <ListItem>
                                <ListItemText primary="Fecha de pago" secondary={<DateInTable dateString={detalle.pdate} />}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Carrier" secondary={detalle.carrier}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Método de pago" secondary={detalle.paymethod}/>
                            </ListItem>
                        </List>
                    </Grid>
                </>
                )}
            </Grid>
        </>
    );
};

export default BackordersDSDetail;