import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Skeleton,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import GradeList from "./GradeList";
import { getGrade } from "../../../utils/proveedores/ventas/ventas";

const LevelList = ({ Nivel, idNivel, SumaValorStock, startDate, endDate }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataGrade, setDataGrade] = useState([]);

  useEffect(() => {
    if (open) {
      (async () => {
        setLoading(true);
        let grade = await getGrade(23, startDate, endDate, idNivel);
        setDataGrade(grade.data);
        setLoading(false);
      })();
    }
  }, [open]);

  function formatNumber(num) {
    const roundedNum = Number(num).toFixed(2);
    return new Intl.NumberFormat("en-US").format(roundedNum);
  }

  const styles = {
    rowHover: {
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        cursor: "pointer",
      },
      "&:active": {
        backgroundColor: "rgba(0, 0, 0, 0.1)",
      },
      transition: "background-color 0.3s",
    },
  };

  return (
    <Accordion
      expanded={open}
      onChange={() => setOpen(!open)}
      sx={{
        marginTop: "25px",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          flexDirection: "row-reverse",
          justifyContent: "space-between",
          backgroundColor: "#dfdfdf",
          marginX: "1px"
        }}
      >
        <Box display="flex" justifyContent="space-between" width="100%">
          <Typography
            style={{ order: 2 }}
            variant="h6"
            sx={{ fontSize: "1.1rem" }}
          >
            {Nivel}
          </Typography>
          <Box
            style={{
              order: 3,
              marginLeft: "auto",
              width: "100px",
              textAlign: "left",
            }}
          >
            <Typography>
              <strong>${formatNumber(SumaValorStock)}</strong>
            </Typography>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {open && loading && (
          <>
            <Skeleton sx={{ height: "50px" }} />
            <Skeleton sx={{ height: "50px" }} />
          </>
        )}
        {open &&
          !loading &&
          dataGrade.map((item, index) => (
            <GradeList
              key={index}
              idNivel={idNivel}
              Grade={item.Grade}
              idGrade={item.Id_Grado}
              SumaValorStockG={item.SumaValorStock}
              startDate={startDate}
              endDate={endDate}
            />
          ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default LevelList;
