import { Box, CircularProgress, Typography, Tabs, Tab } from "@mui/material";
import { useEffect, useState } from "react";
import { useTabs } from "../../../../context/TabsContext";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import RefreshIcon from "@mui/icons-material/Refresh";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

import ArticulosTab from "./ArticulosTab";
import ReembolsoTab from "./ReembolsoTab";
import MovimientosTab from "./MovimientosTab";
import SCTab from "./SCTab";
import CambiosTab from "./CambiosTab";

import { getItems } from "../../../../utils/trazabilidad/rma/rma";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  );
};

const IndexTabs = ({ rmaId, rmaDl, status }) => {
  const { crmTab, setCrmTab } = useTabs();
  const [isLoading, setIsLoading] = useState(false);
  const [articulos, setArticulos] = useState(null);
  const [indexSc, setIndexSc] = useState(1);
  const [indexRem, setIndexRem] = useState(1);
  const [indexCam, setIndexCam] = useState(1);
  const [indexMov, setIndexMov] = useState(1);
  const [sc, setSc] = useState(false);
  const [rem, setRem] = useState(false);
  const [cam, setCam] = useState(false);
  const [mov, setMov] = useState(false);

  const handleTabChange = (event, value) => {
    setCrmTab(value);
  };

  function check_states(res) {
    let sc = res.id_status_storeCredit;
    let re = res.id_status_reembolso;
    let ca = res.id_status_cambio;

    let is_sc,
      is_rem,
      is_cam = false;

    if (sc === 5 || sc === 6 || sc === 7) {
      setSc(true);
      is_sc = true;
      setIndexSc(1);
    }

    if (re === 4 || re === 8 || re === 6 || re === 9) {
      setRem(true);
      is_rem = true;
      is_sc === true ? setIndexRem(2) : setIndexRem(1);
    }

    if (ca === 3 || ca === 6 || ca === 12) {
      setCam(true);
      is_cam = true;
      if (is_sc === true) is_rem === true ? setIndexCam(3) : setIndexCam(2);
      else is_rem === true ? setIndexCam(2) : setIndexCam(1);
    }

    if (is_sc === true) {
      if (is_rem === true) is_cam === true ? setIndexMov(4) : setIndexMov(3);
      else is_cam === true ? setIndexMov(3) : setIndexMov(2);
    } else {
      if (is_rem === true) is_cam === true ? setIndexMov(3) : setIndexMov(2);
      else is_cam === true ? setIndexMov(2) : setIndexMov(1);
    }

    setMov(true);
  }

  const getArticulos = async () => {
    try {
      setIsLoading(true);
      check_states(status);
      const items = await getItems(
        rmaId,
        rmaDl.order_increment_id,
        rmaDl.increment_id
      );
      setArticulos(items);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    

    getArticulos();
  }, []);

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={crmTab} onChange={handleTabChange}>
          <Tab
            icon={<ShoppingCartIcon />}
            iconPosition="start"
            label="Artículos"
          />
          {sc && (
            <Tab
              icon={<CreditCardIcon />}
              iconPosition="start"
              label="Store Credit"
            />
          )}
          {rem && (
            <Tab
              icon={<AttachMoneyIcon />}
              iconPosition="start"
              label="Reembolso"
            />
          )}
          {cam && (
            <Tab icon={<RefreshIcon />} iconPosition="start" label="Cambios" />
          )}
          {mov && (
            <Tab
              icon={<FormatListBulletedIcon />}
              iconPosition="start"
              label="Movimientos"
            />
          )}
        </Tabs>
      </Box>
      {isLoading && (
        <Box sx={{ ml: 2, mt: 2, mr: 2, mb: 2 }}>
          {" "}
          <Typography variant="p"> Cargando ...</Typography>{" "}
          <CircularProgress />{" "}
        </Box>
      )}
      <TabPanel value={crmTab} index={0}>
        <ArticulosTab
          articulos={articulos}
          status={status}
          rmaId={rmaId}
          incrementId={rmaDl.increment_id}
          rmaDl={rmaDl}
          getArticulos={getArticulos}
        />
      </TabPanel>
      {sc && indexSc && articulos && rmaId && rmaDl && (
        <TabPanel value={crmTab} index={indexSc}>
          <SCTab
            lista={articulos}
            rmaId={rmaId}
            orderId={rmaDl.order_increment_id}
            incrementId={rmaDl.increment_id}
            clientName={
              rmaDl.customer_name +
              " " +
              rmaDl.customer_lastname +
              " " +
              rmaDl.customer_mothersname
            }
          />
        </TabPanel>
      )}
      {rem && indexRem && articulos && rmaId && rmaDl && status && (
        <TabPanel value={crmTab} index={indexRem}>
          <ReembolsoTab
            lista={articulos}
            rmaId={rmaId}
            orderId={rmaDl.order_increment_id}
            incrementId={rmaDl.increment_id}
            status={status}
            clientEmail={rmaDl.email}
            clientName={
              rmaDl.customer_name +
              " " +
              rmaDl.customer_lastname +
              " " +
              rmaDl.customer_mothersname
            }
          />
        </TabPanel>
      )}
      {cam && indexCam && (
        <TabPanel value={crmTab} index={indexCam}>
          <CambiosTab
            lista={articulos}
            rmaId={rmaId}
            orderId={rmaDl.order_increment_id}
            incrementId={rmaDl.increment_id}
            clientEmail={rmaDl.email}
            clientName={
              rmaDl.customer_name +
              " " +
              rmaDl.customer_lastname +
              " " +
              rmaDl.customer_mothersname
            }
            status={status}
            rmaDl={rmaDl}
          />
        </TabPanel>
      )}
      {mov && indexMov && (
        <TabPanel value={crmTab} index={indexMov}>
          <MovimientosTab rmaId={rmaId} />
        </TabPanel>
      )}
    </>
  );
};

export default IndexTabs;
