import { Box, Card, LinearProgress, Typography, Grid } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import AssignmentIcon from '@mui/icons-material/Assignment';
import { useEffect, useState } from "react";
import { useAuth } from "../../../context/AuthContext";
import { profiles } from "../../../utils/cancelaciones/profilesCancelaciones";
import CancelacionesDSDetail from "./CancelacionesDSDetail";
import IndexTabs from "./tabs/IndexTabs";
import { getCancelationByID } from "../../../utils/cancelaciones/cancelaciones/cancelaciones";
import BreadCrumb from "../../general/BreadCrumb";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";

const itemsBreadCrumbs = [
    {
        label: 'Cancelaciones',
        icon: <ContentPasteSearchIcon sx={{ mr: 0.5 }} fontSize="small"/>,
        link: '/cancelaciones'
    },
    { label: 'Detalle' }
];

const CancelacionesDetail = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const params = useParams();
    const id = params.id;
    const [isLoading, setIsLoading] = useState(false);

    const [detalle, setDetalle] = useState(null);
    const [tabOrderDetail, settabOrderDetail] = useState(null);

    const getDetalleCancelacion = async () => {
        try {
            setIsLoading(true);
            const res = await getCancelationByID (id);
            setDetalle(res);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if(!profiles.cancelaciones.includes(user.role.idProfile)){
            navigate("/dashboard", { replace: true });
        }
        else{
            getDetalleCancelacion();
        }
    }, [id, user, navigate]);

    return (
        <>
            <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <Typography variant="h4" sx={{ my: 2 }}>Cancelaciones</Typography>
                <Box><BreadCrumb items={itemsBreadCrumbs} /></Box>
            </Box>

            <motion.div initial={{ y: 20 }} animate={{ y: 0 }} transition={{ duration: 0.5 }}>
                <Card>
                    <Box>
                        <Box>                        
                            <Grid container spacing={2}>
                                <Grid item md={12}>
                                    <Typography variant="h6" sx={{ ml: 2, my: 2, mb: 0 }}><AssignmentIcon sx={{ m: 0 }} /> Detalles de la cancelación</Typography>
                                    {isLoading && <LinearProgress />}
                                    {detalle && (<CancelacionesDSDetail detalle={detalle}/>)}
                                </Grid>
                            </Grid>
                        </Box>
                        {detalle && (<IndexTabs idOrder={detalle.data.id_orden} id={id} />)}
                    </Box>
                </Card>
            </motion.div>
            <Box>&nbsp;</Box>
        </>
    );
};

export default CancelacionesDetail;