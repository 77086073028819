import {
  Box,
  Grid,
  Button,
  Stack,
  Typography,
  TextField,
  Menu,
  MenuItem,
  Switch,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { useAuth } from "../../../context/AuthContext";
import {
  getUserRH,
  searchUserRH,
  updateSatatusUserRH,
} from "../../../utils/vales/configuracion/configuracion";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { LoadingButton } from "@mui/lab";
import ModalStatusUser from "./ModalStatusUser";
import ModalUserRHCreate from "./ModalUserRHCreate";
import ModalDeleteUser from "./ModalDeleteUser";
import ModalUpdateUserRH from "./ModalUpdateUserRH";

const UserRHDataTable = () => {
  const { user } = useAuth();
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [keyWord, setKeytoSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(null);

  const [isModalStatusOpen, setIsModalStatusOpen] = useState(false);
  const [isModalCreateOpen, setIsModalCreateOpen] = useState(false);
  const [isModalUpdateOpen, setIsModalUpdateOpen] = useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUserName, setSelectedUserName] = useState(null);
  const [selectedUserUpdate, setSelectedUserUpdate] = useState(null);

  const [currentRow, setCurrentRow] = useState(null);
  const [estado, setEstado] = useState(0);

  const handleSwitchChange = (newStatus, idUser) => {
    setCurrentRow({ newStatus, idUser });
    setIsModalStatusOpen(true);
  };

  const handleAccept = async () => {
    setLoading(true);
    setIsModalStatusOpen(false);
    setRows([]);
    if (currentRow) {
      let data = {
        idUser: currentRow.idUser,
        status: currentRow.newStatus,
        updateUsr: user.data.awsid,
      };
      await updateSatatusUserRH(data);
    }

    setEstado((prevEstado) => (prevEstado === 0 ? 1 : 0));
  };

  const handleDeny = () => {
    setIsModalStatusOpen(false);
  };

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setOpen(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(null);
  };

  function handleCloseModalDel() {
    setIsModalDeleteOpen(false);
    setSelectedUserId(null);
    setSelectedUserName(null);
  }

  const handleClickUpdate = (user) => {
    setSelectedUserUpdate(user);
  };

  const columns = [
    {
      field: "name",
      headerName: "Nombre",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Typography variant="subtitle1" align="center">
          {params.row.nombre +
            " " +
            params.row.apellidoP +
            " " +
            params.row.apellidoM}
        </Typography>
      ),
    },
    {
      field: "mail",
      headerName: "Correo",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Typography variant="subtitle1" align="center">
          {params.row.mail}
        </Typography>
      ),
    },
    {
      field: "active",
      headerName: "Activo",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          <Switch
            checked={params.row.status === 1}
            onChange={() =>
              handleSwitchChange(
                params.row.status === 1 ? 0 : 1,
                params.row.id_user
              )
            }
          />
        </>
      ),
    },
    {
      field: "accion",
      headerName: "Acción",
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => (
        <>
          <Button
            variant="contained"
            size="small"
            endIcon={<KeyboardArrowDownIcon />}
            disableElevation
            aria-controls={open ? this : undefined}
            aria-haspopup="menu"
            onClick={(event) => handleClick(event, params.row.id_user)}
          >
            Acción
          </Button>
          <Menu
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            anchorEl={anchorEl}
            open={open === params.row.id_user ? true : false}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                setIsModalUpdateOpen(true);
                handleClickUpdate(params.row.id_user);
                handleClose();
              }}
            >
              <Typography>Editar datos</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setIsModalDeleteOpen(true);
                setSelectedUserId(params.row.id_user);
                setSelectedUserName(
                  `${params.row.nombre} ${params.row.apellidoP}`
                );
                handleClose();
              }}
            >
              <Typography>Eliminar</Typography>
            </MenuItem>
          </Menu>
        </>
      ),
    },
  ];

  if (
    document.querySelector(
      ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
    )
  )
    document
      .querySelector(
        ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
      )
      .remove();

  useEffect(() => {
    let active = true;

    (async () => {
      let newRows = null;

      if (
        typeof keyWord === "undefined" ||
        keyWord === undefined ||
        keyWord.length === 0
      ) {
        setLoading(true);
        newRows = await getUserRH(page);
      } else if (keyWord.length >= 3) {
        setLoading(true);
        newRows = await searchUserRH(page, keyWord);
      } else active = false;

      if (!active) {
        return;
      }
      setTotal(newRows.total);
      setRows(newRows.data);
      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [page, keyWord, estado]);

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Grid container sx={{ mb: 2, mt: 2 }}>
          <Grid item md={4} sx={{ px: 2 }}>
            <Stack direction="row" spacing={1}>
              <TextField
                size="small"
                variant="outlined"
                fullWidth
                label={`Buscar Temporadas`}
                value={keyWord}
                onChange={(e) => setKeytoSearch(e.target.value)}
              />
            </Stack>
          </Grid>
          <Grid item md sx={{ px: 2, textAlign: "right" }}>
            <LoadingButton
              variant="contained"
              color="info"
              onClick={() => setIsModalCreateOpen(true)}
            >
              Agregar Usuario
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
      <Box style={{ height: 635, width: "100%" }}>
        <DataGridPro
          rows={rows}
          columns={columns}
          pagination
          pageSize={10}
          rowsPerPageOptions={[10]}
          getRowId={(row) => row.id_user}
          rowCount={total}
          paginationMode="server"
          onPageChange={(newPage) => {
            setPage(newPage);
          }}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
          loading={loading}
          disableColumnMenu
          disableSelectionOnClick
          getDetailPanelHeight={() => "auto"}
        />
      </Box>

      <ModalUserRHCreate
        isOpen={isModalCreateOpen}
        onClose={() => setIsModalCreateOpen(false)}
        setLoading={setLoading}
        setRows={setRows}
        setEstado={setEstado}
        updateUsr={user.data.awsid}
      />

      <ModalUpdateUserRH
        open={isModalUpdateOpen}
        onClose={() => setIsModalUpdateOpen(false)}
        userId={selectedUserUpdate}
        updateUsr={user.data.awsid}
        setLoading={setLoading}
        setRows={setRows}
        setEstado={setEstado}
      />

      <ModalStatusUser
        isModalOpen={isModalStatusOpen}
        handleDeny={handleDeny}
        handleAccept={handleAccept}
      />

      <ModalDeleteUser
        isOpen={isModalDeleteOpen}
        onClose={handleCloseModalDel}
        userId={selectedUserId}
        selectedUserName={selectedUserName}
        setLoading={setLoading}
        setRows={setRows}
        setEstado={setEstado}
        updateUsr={user.data.awsid}
      />
    </>
  );
};

export default UserRHDataTable;
