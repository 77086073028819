import { Button } from "@mui/material";
import React from "react";

export const CategoriaButton = ({ categoria }) => {
  let label = "";
  let color = "";

  if (!categoria) {
    label = "S/Estatus";
    color = "warning";
  } else if (categoria === "Creada") {
    label = categoria;
    color = "info";
  } else if (categoria === "Entregada") {
    label = categoria;
    color = "success";
  }

  return (
    <Button
      variant="contained"
      color={color}
      size="small"
      sx={{
        textTransform: "none",
        lineHeight: 1,
        fontSize: "0.7rem",
        minWidth: 0,
      }}
    >
      {label}
    </Button>
  );
};
