import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useAuth } from "../../../context/AuthContext";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  approveQuote,
  cancelQuote,
  getEstados,
  getQuotes,
  resendSapOV,
  returnToRequirement,
  searchQuotes,
  searchQuotesAdv,
  validateQuote,
} from "../../../utils/ventaMayoreo/cotizaciones/cotizaciones";
import DateInTable from "../../general/DateInTable";
import { Warning as WarningIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";

const CotizacionesDataTable = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [keyWord, setKeytoSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(null);
  const [reloadPage, setReloadPage] = useState(0);

  const [estado, setEstado] = useState(0);
  const [states, setStates] = useState([]);

  const [isAF, setIsAF] = useState(false);
  const [advancedS, setAdvancedS] = useState({
    cotizacion: "",
    estatus: "",
    orden_venta: "",
    requerimiento: "",
    razon_social: "",
    rfc: "",
    email: "",
    telefono: "",
    pais: "",
    estado: "",
    ciudad: "",
    delegacion: "",
    cp: "",
    nombre_producto: "",
    sku: "",
    estado: "",
  });

  console.log(advancedS.estado);
  const handleChangeAdvancedS = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setAdvancedS((prevValues) => {
      const newState = { ...prevValues, [name]: value };

      if (name === "estado") {
        const estadoSeleccionado = states.find(
          (state) => state.id_estado === parseInt(value)
        );
        newState.id_estado = estadoSeleccionado
          ? estadoSeleccionado.id_estado
          : "";
      }

      return newState;
    });
  };

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setOpen(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(null);
  };

  const columns = [
    {
      field: "mCotizacion",
      headerName: " ",
      flex: 0.1,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) =>
        row.marcaCotizacion ? <WarningIcon color="warning" /> : "",
    },
    {
      field: "cotizacion",
      headerName: "Cotizacion",
      flex: 0.8,
      align: "center",
      headerAlign: "center",
      valueGetter: ({ row }) => row.numero_cotizacion,
    },
    {
      field: "requerimiento",
      headerName: "Requerimiento",
      flex: 0.8,
      align: "center",
      headerAlign: "center",
      valueGetter: ({ row }) => row.numero_requerimiento,
    },
    {
      field: "estatus",
      headerName: "Estatus",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        let nombreCategoria = params.row.nombre_categoria;

        let variant = "contained";
        let color = "default";
        let labelText = nombreCategoria;

        if (
          !nombreCategoria ||
          nombreCategoria === "En proceso" ||
          nombreCategoria === "En espera de oferta de compra" ||
          nombreCategoria === "Cotización modificada" ||
          nombreCategoria === "En espera de orden de compra"
        ) {
          color = "warning";
          labelText = nombreCategoria || "S/Estatus";
        } else if (nombreCategoria === "Creado") {
          color = "info";
        } else if (
          nombreCategoria === "Aprobada" ||
          nombreCategoria === "Cotización validada" ||
          nombreCategoria === "Marcas validadas"
        ) {
          color = "success";
        } else {
          color = "error";
        }

        return (
          <Button
            variant={variant}
            color={color}
            size="small"
            sx={{
              textTransform: "none",
              lineHeight: 1,
              fontSize: "0.7rem",
              minWidth: 0,
            }}
          >
            {labelText}
          </Button>
        );
      },
    },
    {
      field: "fecha",
      headerName: "Fecha",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) =>
        row.fecha_cotizacion ? (
          <Typography>
            <DateInTable dateString={row.fecha_cotizacion} />
          </Typography>
        ) : (
          "S/Fecha"
        ),
    },
    {
      field: "total",
      headerName: "Total",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => (
        <Typography variant="subtitle2">${row.total}</Typography>
      ),
    },
    {
      field: "fechaCorreo",
      headerName: "Fecha Correo",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) =>
        row.fecha_correo ? (
          <Typography>
            <DateInTable dateString={row.fecha_correo} />
          </Typography>
        ) : (
          "S/Fecha"
        ),
    },
    {
      field: "fechaAprobacion",
      headerName: "Fecha Aprobacion",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) =>
        row.fecha_aprobacion ? (
          <Typography>
            <DateInTable dateString={row.fecha_aprobacion} />
          </Typography>
        ) : (
          "S/Fecha"
        ),
    },
    {
      field: "accion",
      headerName: "Acción",
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => (
        <>
          <Button
            variant="contained"
            size="small"
            endIcon={<KeyboardArrowDownIcon />}
            disableElevation
            aria-controls={open ? this : undefined}
            aria-haspopup="menu"
            onClick={(event) => handleClick(event, params.row.id_cotizacion)}
          >
            Acción
          </Button>
          <Menu
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            anchorEl={anchorEl}
            open={open === params.row.id_cotizacion ? true : false}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                navigate(`detail/${params.row.id_cotizacion}`);
                handleClose();
              }}
            >
              <Typography>Ver</Typography>
            </MenuItem>
            {params.row.id_cat_status === 9 && (
              <>
                {params.row.marcaCotizacion ? (
                  <>
                    {(user.data.email == "adriana.angeles@gea.mx" ||
                      "permisoBoton") && (
                      <MenuItem
                        onClick={() => {
                          handleValidateQuote(params.row.id_cotizacion);
                          handleClose();
                          reloadPageFn();
                        }}
                      >
                        <Typography>Validar cotización</Typography>
                      </MenuItem>
                    )}
                    <MenuItem
                      onClick={() => {
                        regresarRequerimiento(params.row.id_cotizacion);
                        handleClose();
                        reloadPageFn();
                      }}
                    >
                      <Typography>Regresar a requerimiento</Typography>
                    </MenuItem>
                  </>
                ) : (
                  <>
                    <MenuItem
                      onClick={() => {
                        handleApproveQuote(params.row.id_cotizacion);
                        handleClose();
                        reloadPageFn();
                      }}
                    >
                      <Typography>Aprobar</Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        regresarRequerimiento(params.row.id_cotizacion);
                        handleClose();
                        reloadPageFn();
                      }}
                    >
                      <Typography>Regresar a requerimiento</Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        cancelarCotizacion(params.row.id_cotizacion);
                        handleClose();
                        reloadPageFn();
                      }}
                    >
                      <Typography>Cancelar</Typography>
                    </MenuItem>
                  </>
                )}
                <MenuItem
                  onClick={() =>
                    navigate(`editarcotizacion/${params.row.id_cotizacion}`)
                  }
                >
                  <Typography>Editar cotización</Typography>
                </MenuItem>
              </>
            )}

            {params.row.id_cat_status === 10 && (
              <>
                <MenuItem
                  onClick={() => {
                    handleApproveQuote(params.row.id_cotizacion);
                    handleClose();
                    reloadPageFn();
                  }}
                >
                  <Typography>Aprobar</Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    regresarRequerimiento(params.row.id_cotizacion);
                    handleClose();
                    reloadPageFn();
                  }}
                >
                  <Typography>Regresar a requerimiento</Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    cancelarCotizacion(params.row.id_cotizacion);
                    handleClose();
                    reloadPageFn();
                  }}
                >
                  <Typography>Cancelar</Typography>
                </MenuItem>
              </>
            )}

            {params.row.id_cat_status === 12 && (
              <></>
              // <MenuItem
              //   onClick={() =>
              //     navigate(`/ofertaCompra/${params.row.id_cotizacion}`)
              //   }
              // >
              //   <Typography>Agregar oferta</Typography>
              // </MenuItem>
            )}

            {params.row.id_cat_status === 14 && (
              <></>
              // <MenuItem
              //   onClick={() =>
              //     navigate(`/ordenCompra/${params.row.id_cotizacion}`)
              //   }
              // >
              //   <Typography>Agregar orden</Typography>
              // </MenuItem>
            )}

            {params.row.id_cat_status === 15 && (
              <>
                <MenuItem
                  onClick={() =>
                    navigate(`ordendeventa/${params.row.id_cotizacion}`)
                  }
                >
                  <Typography>Orden de venta</Typography>
                </MenuItem>
                {params.row.orden_venta == null && (
                  <MenuItem
                    onClick={() => {
                      handleResendSapOV(params.row.id_cotizacion);
                      handleClose();
                      reloadPageFn();
                    }}
                  >
                    <Typography>Reenviar petición a SAP</Typography>
                  </MenuItem>
                )}
              </>
            )}

            {params.row.id_cat_status === 35 && (
              <>
                {/* <MenuItem
                  onClick={() =>
                    navigate(`/correoCotizacion/${params.row.id_cotizacion}`)
                  }
                >
                  <Typography>Enviar correo</Typography>
                </MenuItem> */}
                <MenuItem
                  onClick={() => {
                    cancelarCotizacion(params.row.id_cotizacion);
                    handleClose();
                    reloadPageFn();
                  }}
                >
                  <Typography>Cancelar</Typography>
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    navigate(`editarcotizacion/${params.row.id_cotizacion}`)
                  }
                >
                  <Typography>Editar cotización</Typography>
                </MenuItem>
              </>
            )}

            {params.row.id_cat_status === 38 && (
              <>
                <MenuItem
                  onClick={() => {
                    regresarRequerimiento(params.row.id_cotizacion);
                    handleClose();
                    reloadPageFn();
                  }}
                >
                  <Typography>Regresar a requerimiento</Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    cancelarCotizacion(params.row.id_cotizacion);
                    handleClose();
                    reloadPageFn();
                  }}
                >
                  <Typography>Cancelar</Typography>
                </MenuItem>
              </>
            )}

            {params.row.id_cat_status === 43 && (
              <MenuItem
                onClick={() => {
                  handleApproveQuote(params.row.id_cotizacion);
                  handleClose();
                  reloadPageFn();
                }}
              >
                <Typography>Aprobar</Typography>
              </MenuItem>
            )}
          </Menu>
        </>
      ),
    },
  ];

  if (
    document.querySelector(
      ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
    )
  )
    document
      .querySelector(
        ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
      )
      .remove();

  useEffect(() => {
    let active = true;

    (async () => {
      let newRows = null;
      let stateD = await getEstados();
      setStates(stateD.data);

      if (isAF) {
        console.log("isAF");
        setLoading(true);
        newRows = await searchQuotesAdv(page, advancedS);
        console.log(newRows.data);
      } else if (
        typeof keyWord === "undefined" ||
        keyWord === undefined ||
        keyWord.length === 0
      ) {
        setLoading(true);
        newRows = await getQuotes(page);
        // schoolsD = await getSchools();
      } else if (keyWord.length >= 3) {
        setLoading(true);
        newRows = await searchQuotes(page, keyWord);
        // schoolsD = await getSchools();
      } else active = false;

      if (!active) {
        return;
      }
      setTotal(newRows.total);
      setRows(newRows.data);
      //   setSchools(schoolsD.data);
      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [page, keyWord, estado, isAF, advancedS, reloadPage]);

  const cancelarCotizacion = async (idCotizacion) => {
    await cancelQuote({ idCotizacion });
  };

  const reloadPageFn = () => {
    setKeytoSearch("");
    setRows([]);
    setReloadPage((prevState) => (prevState === 0 ? 1 : 0));
  };

  const regresarRequerimiento = async (idCotizacion) => {
    let data = {
      idCotizacion,
      idUser: user.data.awsid,
    };
    await returnToRequirement(data);
  };

  const handleValidateQuote = async (idCotizacion) => {
    let data = {
      idCotizacion,
    };
    await validateQuote(data);
  };

  const handleApproveQuote = async (idCotizacion) => {
    let data = {
      idCotizacion,
    };
    await approveQuote(data);
  };

  const handleResendSapOV = async (idCotizacion) => {
    let data = {
      idCotizacion,
    };
    await resendSapOV(data);
  };

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Busqueda avanzada</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {/* <Grid item xs={4}>
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                type={"date"}
                name="dateDisch"
                label="Fecha de alta"
                variant="outlined"
                value={advancedS.dateDisch}
                onChange={handleChangeAdvancedS}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                type={"date"}
                name="dateMail"
                label="Fecha correo"
                variant="outlined"
                value={advancedS.dateMail}
                onChange={handleChangeAdvancedS}
              />
            </Grid> */}
            {/* <Grid item xs={4}>
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                type={"date"}
                name="dateApro"
                label="Fecha aprobacion"
                variant="outlined"
                value={advancedS.dateApro}
                onChange={handleChangeAdvancedS}
              />
            </Grid> */}
            <Grid item xs={4}>
              <TextField
                fullWidth
                name="cotizacion"
                label="Cotizacion"
                variant="outlined"
                value={advancedS.cotizacion}
                onChange={handleChangeAdvancedS}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                name="requerimiento"
                label="Requerimiento"
                variant="outlined"
                value={advancedS.requerimiento}
                onChange={handleChangeAdvancedS}
              />
            </Grid>

            {/* <Grid item xs={4}>
              <FormControl fullWidth variant="filled">
                <InputLabel id="er">Estatus Requerimiento</InputLabel>
                <Select
                  labelId="er"
                  // value={}
                  // onChange={}
                >
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Razon social"
                name="razon_social"
                variant="outlined"
                value={advancedS.razon_social}
                onChange={handleChangeAdvancedS}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                name="rfc"
                label="RFC"
                variant="outlined"
                value={advancedS.rfc}
                onChange={handleChangeAdvancedS}
              />
            </Grid>
            <Grid item xs={4}>
              {/* <TextField
                fullWidth
                label="Correo electronico"
                variant="filled"
              /> */}
              <TextField
                fullWidth
                name="email"
                label="Correo electronico"
                variant="outlined"
                value={advancedS.email}
                onChange={handleChangeAdvancedS}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Numero de telefono"
                name="telefono"
                variant="outlined"
                value={advancedS.telefono}
                onChange={handleChangeAdvancedS}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                name="pais"
                label="Pais"
                variant="outlined"
                value={advancedS.pais}
                onChange={handleChangeAdvancedS}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="id_estado">Estado</InputLabel>
                <Select
                  value={advancedS.estado}
                  onChange={handleChangeAdvancedS}
                  label="Estado"
                  input={
                    <OutlinedInput
                      label="Estado"
                      id="id_estado"
                      name="estado"
                    />
                  }
                >
                  {states.map((state) => (
                    <MenuItem key={state.id_estado} value={state.id_estado}>
                      {state.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                name="ciudad"
                label="Ciudad"
                variant="outlined"
                value={advancedS.ciudad}
                onChange={handleChangeAdvancedS}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                name="delegacion"
                label="Delegacion"
                variant="outlined"
                value={advancedS.delegacion}
                onChange={handleChangeAdvancedS}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                name="cp"
                label="Codigo postal"
                variant="outlined"
                value={advancedS.cp}
                onChange={handleChangeAdvancedS}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                fullWidth
                name="nombre_producto"
                label="Producto"
                variant="outlined"
                value={advancedS.nombre_producto}
                onChange={handleChangeAdvancedS}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                name="sku"
                label="SKU"
                variant="outlined"
                value={advancedS.sku}
                onChange={handleChangeAdvancedS}
              />
            </Grid>
            <Grid item xs={4}>
              {/* <TextField fullWidth label="Orden de venta" variant="filled" />salesOrder */}
              <TextField
                fullWidth
                name="orden_venta"
                label="Orden de venta"
                variant="outlined"
                value={advancedS.orden_venta}
                onChange={handleChangeAdvancedS}
              />
            </Grid>
            <Grid item xs={12}>
              <div style={{ textAlign: "right", marginTop: "2em" }}>
                <LoadingButton
                  startIcon={<SearchIcon />}
                  sx={{ mx: 2 }}
                  // loading={isLoading}
                  size="medium"
                  variant="contained"
                  color="success"
                  onClick={() => setIsAF(true)}
                  disableElevation
                >
                  Consultar
                </LoadingButton>
              </div>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Grid container sx={{ mb: 2, mt: 2 }}>
          <Grid item md={4} sx={{ px: 2 }}>
            <Stack direction="row" spacing={1}>
              <TextField
                size="small"
                variant="outlined"
                fullWidth
                label={`Buscar`}
                value={keyWord}
                onChange={(e) => setKeytoSearch(e.target.value)}
              />
            </Stack>
          </Grid>

          <Grid item md sx={{ px: 2, textAlign: "right" }}>
            <LoadingButton
              variant="contained"
              color="info"
              //   onClick={handleOpenModalCreate}
            >
              Excel
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
      <Box style={{ height: 635, width: "100%" }}>
        <DataGridPro
          rows={rows}
          columns={columns}
          pagination
          pageSize={10}
          rowsPerPageOptions={[10]}
          getRowId={(row) => row.id_cotizacion}
          rowCount={total}
          paginationMode="server"
          onPageChange={(newPage) => {
            setPage(newPage);
          }}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
          loading={loading}
          disableColumnMenu
          disableSelectionOnClick
          getDetailPanelHeight={() => "auto"}
        />
      </Box>
    </>
  );
};

export default CotizacionesDataTable;
