import axios from "axios";

const getDatosEnvio = async (id_aclaracion) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/clarifications/clarifications/details/tab/datosenvio?id_aclaracion=${id_aclaracion}`;

  try {
    const datos = await axios(URL);

    return datos.data?.success ? datos.data.data : null;
  } catch (error) {
    console.error(error);
  }
};

export { getDatosEnvio };
