import { Avatar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import { getClarificationMoves } from "../../../../utils/trazabilidad/aclaraciones/aclaraciones";
import DateInTable from "../../../general/DateInTable";
import { moveTypes } from "../../../../utils/general/moves";

const MovimientosTab = ({ aId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [movimientos, setMovimientos] = useState(null);

  useEffect(() => {
    const getDetallePedido = async () => {
        try {
          setIsLoading(true);
          const res = await getClarificationMoves(aId);
          setMovimientos(res);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
    };
    getDetallePedido();
  }, [aId]);

  return (
    <>
      <TableContainer>
        <Table size="small">
          <TableBody>
            {movimientos &&
              movimientos.map((movimiento) => (
                <TableRow key={movimiento.id_move}>
                  <TableCell>
                    <Avatar variant="rounded" sx={{ bgcolor: moveTypes[movimiento.id_move].color }}>
                      {moveTypes[movimiento.id_move].icon}
                    </Avatar>
                  </TableCell>
                  <TableCell><strong>{`${movimiento.descripcion.nombre}`}</strong> {movimiento.descripcion.descripcion}</TableCell>
                  <TableCell>
                    <DateInTable dateString={movimiento.date} />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default MovimientosTab;
