import axios from "axios";

/**
 * GET
 **/

const getOrders = async (currentPage) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/ordenesventa/ordenesventa/all/new?page=${currentPage}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const searchSaleOrders = async (currentPage, search) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/ordenesventa/ordenesventa/all/search?page=${currentPage}&search=${search}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getSaleOrdenDetail = async (id) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/ordenesventa/ordenesventa/details?id=${id}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getMessages = async (idRequerimiento) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/ordenesventa/ordenesventa/detail/messages?idRequerimiento=${idRequerimiento}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getProductsReq = async (idRequerimiento, ordCotizacion, ordenVenta) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/ordenesventa/ordenesventa/detail/products?idRequerimiento=${idRequerimiento}&ordCotizacion=${ordCotizacion}&ordenVenta=${ordenVenta}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getPurchaseOrders = async (page, ordenVenta) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/ordenesventa/ordenesventa/detail/purchaseorders?page=${page}&ordenVenta=${ordenVenta}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const searchPurchaseOrders = async (page, ordenVenta, search) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/ordenesventa/ordenesventa/detail/search/purchaseorders?page=${page}&ordenVenta=${ordenVenta}&search=${search}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

//Traspasos
const getTransfers = async (page, ordenVenta) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/ordenesventa/ordenesventa/detail/transfers?page=${page}&ordenVenta=${ordenVenta}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const searchTransfers = async (page, ordenVenta, search) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/ordenesventa/ordenesventa/detail/search/transfers?page=${page}&ordenVenta=${ordenVenta}&search=${search}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

//Guias
const getGuides = async (page, idRequerimiento, numCotizaacion) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/ordenesventa/ordenesventa/detail/guides?page=${page}&idRequerimiento=${idRequerimiento}&numCotizaacion=${numCotizaacion}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const searchGuides = async (page, idRequerimiento, numCotizaacion, search) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/ordenesventa/ordenesventa/detail/search/guides?page=${page}&idRequerimiento=${idRequerimiento}&numCotizaacion=${numCotizaacion}&search=${search}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getProductListGuide = async (page, guideNum) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/ordenesventa/ordenesventa/detail/guides/productlist?page=${page}&guideNum=${guideNum}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getPdfGuide = async (id) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/ordenesventa/ordenesventa/detail/guides/pdfguide?id=${id}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getNmberOfGuides = async (idRequerimiento) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/ordenesventa/ordenesventa/detail/guides/total?idRequerimiento=${idRequerimiento}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getCarrieService = async (idCarrier) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/ordenesventa/ordenesventa/detail/guides/carrier/services?idCarrier=${idCarrier}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getCotizacion = async (idCarrier) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/ordenesventa/ordenesventa/detail/cotizacion?id=${idCarrier}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getListProductsTracking = async (ordVenta, idRequerimientoProducto) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/ordenesventa/ordenesventa/detail/products/list/productstracking?idRequerimientoProducto=${idRequerimientoProducto}&ordVenta=${ordVenta}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getWarehouses = async (ordenVenta) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/ordenesventa/ordenesventa/detail/warehouses?ordenVenta=${ordenVenta}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const insertGuide = async ( details ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/ordenesventa/ordenesventa/detail/guides/insert`;
  try {
      const res = await axios.post(URL, details );
      return res.data?.success ? true : false;
  } catch (error) {
      console.error("insertGuide", error);
  }
};

const reqShippingRequirement = async ( details ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/ordenesventa/ordenesventa/detail/shipping/requirement`;
  try {
      const res = await axios.post(URL, details );
      return res.data?.success ? true : false;
  } catch (error) {
      console.error("reqShippingRequirement", error);
  }
};

const deleteGuideOV = async ( details ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/ordenesventa/ordenesventa/detail/guides/delete`;
  try {
      const res = await axios.post(URL, details );
      return res.data?.success ? true : false;
  } catch (error) {
      console.error("deleteGuideOV", error);
  }
};

export {
  getOrders,
  searchSaleOrders,
  getSaleOrdenDetail,
  getMessages,
  getProductsReq,
  getPurchaseOrders,
  searchPurchaseOrders,
  getTransfers,
  searchTransfers,
  getGuides,
  searchGuides,
  getProductListGuide,
  getPdfGuide,
  getNmberOfGuides,
  getCarrieService,
  getCotizacion,
  insertGuide,
  getListProductsTracking,
  reqShippingRequirement,
  getWarehouses,
  deleteGuideOV
};
