import pdf from "@react-pdf/renderer";

export const StyleSheet = pdf.StyleSheet;
export const Document = pdf.Document;
export const Page = pdf.Page;
export const Image = pdf.Image;
export const View = pdf.View;
export const Text = pdf.Text;
export const Link = pdf.Link;
export const Note = pdf.Note;

const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  boxTitle: {
    width: "100%",
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    backgroundColor: "#bdbdbd",
    paddingVertical: 15,
    border: "1px solid black",
  },
  boxTable: {
    width: "100%",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    width: "270px",
    margin: 0,
  },
  tableCellName: {
    width: "50%",
    fontWeight: "bold",
    fontSize: 10,
    border: "1px solid black",
    padding: 3,
    margin: 0,
  },
  tableCellValue: {
    width: "50%",
    fontSize: 10,
    border: "1px solid black",
    padding: 3,
    margin: 0,
  },
  tableContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  itemTable: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    margin: 0,
    marginTop: 40,
  },
  itemRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    fontSize: 10,
  },
  itemCellNum: {
    width: "10%",
    border: "1px solid black",
    padding: 3,
  },
  itemCellSKU: {
    width: "20%",
    border: "1px solid black",
    padding: 3,
  },
  itemCellName: {
    width: "70%",
    border: "1px solid black",
    padding: 3,
  },
  headerItemCellNum: {
    width: "10%",
    border: "1px solid black",
    padding: 3,
    backgroundColor: "#bdbdbd",
  },
  headerItemCellSKU: {
    width: "20%",
    border: "1px solid black",
    padding: 3,
    backgroundColor: "#bdbdbd",
  },
  headerItemCellName: {
    width: "70%",
    border: "1px solid black",
    padding: 3,
    backgroundColor: "#bdbdbd",
  },
  itemsTitle: {
    width: "100%",
    fontSize: 16,
    marginBottom: 10,
  },
});

const PedidoDetailsDocument = ({ details, domicilio, items }) => {
  // console.log("details", details);
  // console.log("domicilio", domicilio);
  // console.log("items", items);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.boxTitle}>
          <Text>Numero de pedido: {details.increment_id}</Text>
        </View>
        <View style={styles.tableContainer}>
          <View>
            <View style={styles.tableRow}>
              <Text style={styles.tableCellName}>Pedido</Text>
              <Text style={styles.tableCellValue}>{details.increment_id}</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={styles.tableCellName}>Status Pedido</Text>
              <Text style={styles.tableCellValue}>{details.status}</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={styles.tableCellName}>Colegio</Text>
              <Text style={styles.tableCellValue}>{items[0].school}</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={styles.tableCellName}>Nombre</Text>
              <Text style={styles.tableCellValue}>{`${domicilio.customer_name ?? ""} ${
                domicilio.customer_lastname ?? ""
              } ${domicilio.customer_mothersname ?? ""}`}</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={styles.tableCellName}>Tel</Text>
              <Text style={styles.tableCellValue}>{domicilio.telephone ?? ""}</Text>
            </View>
          </View>
          <View>
            <View style={styles.tableRow}>
              <Text style={styles.tableCellName}>Calle</Text>
              <Text style={styles.tableCellValue}>{domicilio.shipping_street ?? ""}</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={styles.tableCellName}>Colonia</Text>
              <Text style={styles.tableCellValue}>{domicilio.shipping_suburb ?? ""}</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={styles.tableCellName}>Ciudad</Text>
              <Text style={styles.tableCellValue}>{domicilio.shipping_city ?? ""}</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={styles.tableCellName}>C. P.</Text>
              <Text style={styles.tableCellValue}>{domicilio.shipping_postal_code ?? ""}</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={styles.tableCellName}>Municipio</Text>
              <Text style={styles.tableCellValue}>{domicilio.shipping_delegation ?? ""}</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={styles.tableCellName}>Estado</Text>
              <Text style={styles.tableCellValue}>{domicilio.shipping_state ?? ""}</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={styles.tableCellName}>Entre calles</Text>
              <Text style={styles.tableCellValue}>{domicilio.between_streets ?? ""}</Text>
            </View>
          </View>
        </View>
        <View style={styles.itemTable}>
          <Text style={styles.itemsTitle}>{items[0].school}</Text>
          <View style={styles.itemRow}>
            <Text style={styles.headerItemCellNum}>Cant.</Text>
            <Text style={styles.headerItemCellSKU}>SKU</Text>
            <Text style={styles.headerItemCellName}>Nombre</Text>
          </View>
          {items &&
            items.map((item, index) => (
              <View style={styles.itemRow} key={index}>
                <Text style={styles.itemCellNum}>{item.quantity}</Text>
                <Text style={styles.itemCellSKU}>{item.product_sku}</Text>
                <Text style={styles.itemCellName}>{item.product_name}</Text>
              </View>
            ))}
        </View>
      </Page>
    </Document>
  );
};

export default PedidoDetailsDocument;
