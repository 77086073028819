import React, { useContext, useState, useEffect, useRef } from 'react';

export const SizeContext = React.createContext();

export function SizeProvider({ children }) {
  const [maxSize, setMaxSize] = useState(0);

  return (
    <SizeContext.Provider value={{ maxSize, setMaxSize }}>
      {children}
    </SizeContext.Provider>
  );
}
