import { useState } from "react";
import {
  Box,
  Button,
  Container,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";
import { useEffect } from "react";
import { useAuth } from "../../../../../context/AuthContext";
import DateInTable from "../../../../general/DateInTable";
import { getMessages } from "../../../../../utils/ventaMayoreo/cotizaciones/cotizaciones";
import { addMessage } from "../../../../../utils/ventaMayoreo/validaciones/validaciones";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },

  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const MensajesTab = ({ idRequerimiento, requerimiento }) => {
  const { user } = useAuth();
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [reload, setReload] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const { numero_requerimiento } = requerimiento;

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      const resMessages = await getMessages(idRequerimiento);
      setMessages(resMessages.data);
    })();
    setIsLoading(false);
  }, [idRequerimiento, reload]);

  const handleSendClick = async () => {
    if (message.trim().length < 3) {
      setError("El mensaje debe tener al menos tres caracteres.");
      return;
    }
    setError("");
    const messageSave = {
      idUser: user.data.awsid,
      idRequerimiento: parseInt(idRequerimiento),
      numeroRequerimiento: numero_requerimiento,
      mensaje: message,
    };

    await addMessage(messageSave);
    setMessage("");
    reloadF();
  };

  const reloadF = () => {
    setReload(reload === 0 ? 1 : 0);
  };

  function encodeUtf8(val) {
    try {
      return decodeURIComponent(escape(val));
    } catch (err) {
      return val;
    }
  }

  return (
    <>
      <Container maxWidth="sm">
        <Box>
          <TextField
            fullWidth
            variant="outlined"
            label="Escribe tu mensaje"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            multiline
            rows={4}
            error={!!error}
            helperText={error}
          />
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
          <Button variant="contained" color="primary" onClick={handleSendClick}>
            Enviar
          </Button>
        </Box>
      </Container>

      <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
        {isLoading ? (
          <Box sx={{ width: "100%" }}>
            <Skeleton variant="h1" sx={{ marginY: "10px", marginX: "3px" }} />
            <Skeleton
              animation="wave"
              variant="h1"
              sx={{ marginY: "10px", marginX: "3px" }}
            />
            <Skeleton
              animation={false}
              variant="h1"
              sx={{ marginY: "10px", marginX: "3px" }}
            />
          </Box>
        ) : (
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow sx={{ th: { padding: "8px" } }}>
                <StyledTableCell align="center">Fecha</StyledTableCell>
                <StyledTableCell align="center">Usuario</StyledTableCell>
                <StyledTableCell align="center">Mensaje</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {messages.map((message) => (
                <StyledTableRow
                  key={message.id_message}
                  sx={{ td: { padding: "10px" } }}
                >
                  <StyledTableCell align="center">
                    <Typography
                      component="div"
                      sx={{ h6: { fontWeight: "normal" } }}
                    >
                      <DateInTable dateString={message.fecha} />
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {encodeUtf8(message.nombre)} {encodeUtf8(message.apellidoP)}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {encodeUtf8(message.mensaje)}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </>
  );
};
