import { Box, Button, Card, Tab, Tabs, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, InputLabel, Select, FormControl, MenuItem } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../../../context/AuthContext";
import TipificacionesTable from "./TipificacionesTable";
import { addTipifications } from "../../../../utils/trazabilidad/excepciones/excepciones";
import BreadCrumb from "../../../general/BreadCrumb";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";

const itemsBreadCrumbs = [
  {
    label: 'Trazabilidad',
    icon: <ContentPasteSearchIcon sx={{ mr: 0.5 }} fontSize="small"/>,
    link: '/trazabilidad/inicio'
  },
  { label: 'Excepciones', link: '/trazabilidad/excepciones' },
  { label: 'Tipificaciones', }
];

const Tipificaciones = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { user } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [tipi, setTipi] = useState({
        tipo: '',
        clave: '',
        accion: 'sf',
        carrier: 1,
        interna: 0,
    });

    function isTipi(){
        if(tipi.tipo && tipi.clave && tipi.accion)
            return true;
        else
            return false;
    }

    const handleChangeTipi = (event) => {    
        let name = event.target.name;
        let value = event.target.value;
        setTipi((prevalue) => {
          return {
            ...prevalue,
            [name]: value
          }
        });
    };

    const handleClickOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    const checkP = (permit) => {
        return (permit == 0);
    }

    const handleSaveTipi = async () => {
        try {
          setIsLoading(true);
          await addTipifications(user.data.awsid, tipi.tipo, tipi.accion, tipi.carrier, tipi.clave, tipi.interna);
          refreshPage();
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
    };
    
    const refreshPage = () => {
        const current = location.pathname;
        navigate(`/reload`, { replace: true });
        setTimeout(() => {
          navigate(current, { replace: true });
        });
    };
    
    useEffect(() => {
        if((user.role.permission.find(checkP))){
            navigate("/dashboard", { replace: true });
        }
    }, [navigate]);

    return (
        <>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", }} >
                <Typography variant="h4" sx={{ my: 2 }}>Tipificaciones</Typography>
                <BreadCrumb items={itemsBreadCrumbs} />
            </Box>
            <Box sx={{ display: "flex", justifyContent: 'end', mb: 2 }}>
                <Button variant="contained" color="success" startIcon={<AddIcon />} onClick={handleClickOpen}>Agregar</Button>          
            </Box>

            <motion.div initial={{ y: 20 }} animate={{ y: 0 }} transition={{ duration: 0.5 }}>        
                <Card sx={{mb:4}}>          
                    <Box>
                        <TipificacionesTable />
                    </Box>
                </Card>
            </motion.div>

            <Dialog open={open} fullWidth={true} maxWidth="sm" onClose={handleClose}>
                <DialogTitle>Agregar tipificación</DialogTitle>
                <DialogContent>
                    <TextField fullWidth sx={{mt: 2, mb: 2}} id="tipo" name="tipo" label="Tipo" variant="outlined" value={tipi.tipo} onChange={handleChangeTipi} />
                    <TextField fullWidth sx={{mb: 2}} id="clave" name="clave" label="Clave" variant="outlined" value={tipi.clave} onChange={handleChangeTipi} />
                    <FormControl fullWidth sx={{mb: 2}}>
                        <InputLabel htmlFor="accion">Acción</InputLabel>
                        <Select id="accion" name="accion" label="Acción" value={tipi.accion} onChange={handleChangeTipi}>
                            <MenuItem value="sf">Sales Force</MenuItem>
                            <MenuItem value="magento">Magento</MenuItem>
                            <MenuItem value="info">Info</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{mb: 2}}>
                        <InputLabel htmlFor="carrier">Carrier</InputLabel>
                        <Select id="carrier" name="carrier" label="Carrier" value={tipi.carrier} onChange={handleChangeTipi}>
                            <MenuItem value={1}>Estafeta</MenuItem>
                            <MenuItem value={2}>Fedex</MenuItem>
                            <MenuItem value={3}>DHL</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{mb: 2}}>
                        <InputLabel htmlFor="interna">Interna</InputLabel>
                        <Select id="interna" name="interna" label="Interna" value={tipi.interna} onChange={handleChangeTipi}>
                            <MenuItem value={0}>No</MenuItem>
                            <MenuItem value={1}>Logística-AC</MenuItem>
                            <MenuItem value={2}>TI</MenuItem>
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <LoadingButton loading={isLoading} variant="contained" color="info" startIcon={<SaveIcon />} disabled={!isTipi()} onClick={handleSaveTipi}>Guardar</LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
};

export default Tipificaciones;