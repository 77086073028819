const profiles = {
    asignarOC: [1,],
    etiquetasE: [1,],
    etiquetasP: [1,],
    movimientosInv: [1,],
    pedidosRec: [1,],
    stock: [1,],
    ventas: [1,],
};

const belongsProveedores = ( profile ) => {
    let aux = profiles;
    return  aux.asignarOC.includes( profile ) ? true : aux.etiquetasE.includes( profile )
            ? true : aux.etiquetasP.includes( profile ) ? true : aux.movimientosInv.includes( profile )
            ? true : aux.pedidosRec.includes( profile ) ? true : aux.stock.includes( profile )
            ? true : aux.ventas.includes( profile );
}

export{
    profiles, belongsProveedores
};