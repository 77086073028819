import axios from "axios";

/**
 * GET
 **/

const getBeneficiaries = async (currentPage) => {
  const URL = `${process.env.REACT_APP_BASEURL}/vales/beneficiarios/all/new?page=${currentPage}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const searchBeneficiaries = async (currentPage, search) => {
  const URL = `${process.env.REACT_APP_BASEURL}/vales/beneficiarios/all/search?page=${currentPage}&search=${search}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getSchools = async () => {
  const URL = `${process.env.REACT_APP_BASEURL}/vales/beneficiarios/all/schools`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getBeneficiary = async (idBeneficiary) => {
  const URL = `${process.env.REACT_APP_BASEURL}/vales/beneficiarios/beneficiary?idBeneficiary=${idBeneficiary}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getChildrens = async (idBeneficiary) => {
  const URL = `${process.env.REACT_APP_BASEURL}/vales/beneficiarios/childrens?idBeneficiary=${idBeneficiary}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getGradesSchool = async (idSchool) => {
  const URL = `${process.env.REACT_APP_BASEURL}/vales/beneficiarios/school/grades?idSchool=${idSchool}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getChildren = async (idChildren) => {
  const URL = `${process.env.REACT_APP_BASEURL}/vales/beneficiarios/children?idChildren=${idChildren}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

/**
 * POST
 **/

const createBeneficiary = async (details) => {
  const URL = `${process.env.REACT_APP_BASEURL}/vales/beneficiarios/all/create`;
  try {
    const res = await axios.post(URL, details);
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("createBeneficiary", error);
  }
};

const deleteBeneficiary = async (details) => {
  const URL = `${process.env.REACT_APP_BASEURL}/vales/beneficiarios/beneficiary/delete`;
  try {
    const res = await axios.post(URL, details);
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("deleteBeneficiary", error);
  }
};

const createChildren = async (details) => {
  const URL = `${process.env.REACT_APP_BASEURL}/vales/beneficiarios/children/create`;
  try {
    const res = await axios.post(URL, details);
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("createChildren", error);
  }
};

const updateBeneficiary = async (details) => {
  const URL = `${process.env.REACT_APP_BASEURL}/vales/beneficiarios/beneficiary/update`;
  try {
    const res = await axios.post(URL, details);
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("updateBeneficiary", error);
  }
};

const deleteChildren = async (details) => {
  const URL = `${process.env.REACT_APP_BASEURL}/vales/beneficiarios/children/delete`;
  try {
    const res = await axios.post(URL, details);
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("deleteChildren", error);
  }
};

const updateChildren = async (details) => {
  const URL = `${process.env.REACT_APP_BASEURL}/vales/beneficiarios/children/update`;
  try {
    const res = await axios.post(URL, details);
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("updateChildren", error);
  }
};

export {
  getBeneficiaries,
  searchBeneficiaries,
  getSchools,
  createBeneficiary,
  deleteBeneficiary,
  getBeneficiary,
  getChildrens,
  getGradesSchool,
  createChildren,
  updateBeneficiary,
  deleteChildren,
  getChildren,
  updateChildren
};
