const profiles = {
    requerimientos: [1,],
    validaciones: [1,9,15,22,29,30,44,46,50,52,53,54,58,63],
    cotizaciones: [1,7,9,15,21,22,29,30,34,44,45,46,50,52,53,54,58,63],
    traspasos: [1,9,15,22,29,30,44,45,46,50,52,53,54,58,63],
    surtidos: [1,9,29,30,44,45,46,50,52,53,54,58],
    ordenesC: [1,7,8,9,15,21,22,29,30,34,44,45,46,50,52,53,54,58,63],
    ordenesV: [1,7,9,15,21,22,29,30,34,44,45,46,50,52,53,54,58,63],
    proveedores: [1,],
    clientes: [1,],
    distribuidores: [1,],
    resellers: [1,],
    uniformes: [1,],
    productos: [1,],
    configuraciones: [1,],
    bitacora: [1,],
};

const belongsVentaMayoreo = ( profile ) => {
    let aux = profiles;
    return  aux.requerimientos.includes( profile ) ? true : aux.validaciones.includes( profile )
            ? true : aux.cotizaciones.includes( profile ) ? true : aux.traspasos.includes( profile )
            ? true : aux.surtidos.includes( profile ) ? true : aux.ordenesC.includes( profile )
            ? true : aux.ordenesV.includes( profile ) ? true : aux.proveedores.includes( profile )
            ? true : aux.clientes.includes( profile ) ? true : aux.distribuidores.includes( profile )
            ? true : aux.resellers.includes( profile ) ? true : aux.uniformes.includes( profile )
            ? true : aux.productos.includes( profile ) ? true : aux.configuraciones.includes( profile )
            ? true : aux.bitacora.includes( profile );
}

export{
    profiles, belongsVentaMayoreo
};