import { Box, Grid, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAuth } from "../../../../context/AuthContext";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import DateInTable from "../../../general/DateInTable";

import PanToolIcon from '@mui/icons-material/PanTool';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import GroupIcon from '@mui/icons-material/Group';
import CloseIcon from '@mui/icons-material/Close';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import EmailIcon from '@mui/icons-material/Email';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';

import { getHRObservations } from "../../../../utils/altoRiesgo/alto/alto";

const chipColors = {
    "fa fa-square-o bg-blue": "#0073b7",
    "fa fa-times bg-red": "#dd4b39",
    "fa fa-users  bg-green": "#00a65a",
    "fa fa-truck bg-red": "#dd4b39",
    "fa fa-hand-paper-o bg-red": "#dd4b39",
    "fa fa-truck bg-green": "#00a65a",
    "fa fa-envelope bg-green": "#00a65a",
    "fa fa-folder-open bg-red": "#dd4b39",
};

const chipIcons = {
    "fa fa-square-o bg-blue": <CropSquareIcon fontSize="small" />,
    "fa fa-times bg-red": <CloseIcon fontSize="small" />,
    "fa fa-users  bg-green": <GroupIcon fontSize="small" />,
    "fa fa-truck bg-red": <LocalShippingIcon fontSize="small" />,
    "fa fa-hand-paper-o bg-red": <PanToolIcon fontSize="small" />,
    "fa fa-truck bg-green": <LocalShippingIcon fontSize="small" />,
    "fa fa-envelope bg-green": <EmailIcon fontSize="small" />,
    "fa fa-folder-open bg-red": <FolderOpenIcon fontSize="small" />,
};

const ObservacionesTab = ({ altoId }) => {
    const auth = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [items, setItems] = useState(null);

    function get_string(val){
        try{
            return decodeURIComponent(escape(val));
        }catch(err){
            return val;
        }        
    }

    useEffect(() => {
        const getItemsHR = async () => {
            try {
                setIsLoading(true);
                const res = await getHRObservations(altoId);
                setItems(res);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };
        getItemsHR();
    }, []);

    return (
        <Box>
            <Grid container>
                <Grid item md={12}>
                {isLoading && (<Box sx={{ml:2, mt:2, mr:2, mb:2}}> <Typography variant="p"> Cargando ...</Typography> <CircularProgress /> </Box>)}
                    <Timeline sx={{[`& .${timelineOppositeContentClasses.root}`]: {flex: 0.2,},}}>
                    {items && (items.map((row) => (
                        <TimelineItem key={row.id_observacion}>
                            <TimelineOppositeContent sx={{ m: 'auto 0' }} align="right" variant="body2" color="text.secondary">
                                <Typography><strong>{row.nombre} {row.apellidoP}</strong></Typography>
                                <DateInTable dateString={row.fecha_registro} />
                            </TimelineOppositeContent>                            
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot sx={{ backgroundColor: chipColors[row.icono], color: "#fff" }}>
                                    {chipIcons[row.icono]}
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: 3.5, px: 2 }}>
                                <Typography>{get_string(row.observacion)}</Typography>
                            </TimelineContent>
                        </TimelineItem>
                    )))}
                    </Timeline>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ObservacionesTab;