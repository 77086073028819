import React from "react";
import { Routes, Route } from "react-router-dom";
import { Outlet } from "react-router";
import Dashboard from "../modules/Dashboard";
import DashboardHome from "../modules/DashboardHome";
import NotFound from "../modules/NotFound";
import Home from "../modules/Home";
import Login from "../modules/Login";
import Reload from "../modules/Reload";

/**
 *
 * Control de accesos
 */
import Accesos from "../components/controlAccesos/accesos/Accesos";
import Solicitudes from "../components/controlAccesos/solicitudes/Solicitudes";
import Administracion from "../components/controlAccesos/administracion/Administracion";
import ReportesAccesos from "../components/controlAccesos/reportes/Reportes";
import Matrices from "../components/controlAccesos/matrices/Matrices";
import AdminProveedores from "../components/controlAccesos/adminProveedores/AdminProveedores";

/**
 *
 * Trazabilidad
 */
import Aclaraciones from "../components/trazabilidad/aclaraciones/Aclaraciones";
import AclaracionesDetail from "../components/trazabilidad/aclaraciones/AclaracionesDetail";
import Backorders from "../components/trazabilidad/backorders/Backorders";
import BackordersDetail from "../components/trazabilidad/backorders/BackordersDetail";
import Carriers from "../components/trazabilidad/carriers/Carriers";
import Excepciones from "../components/trazabilidad/excepciones/Excepciones";
import ExcepcionesI from "../components/trazabilidad/excepciones-internas/ExcepcionesI";
import ExcepcionesDetail from "../components/trazabilidad/excepciones/ExcepcionesDetail";
import ExcepcionesIDetail from "../components/trazabilidad/excepciones-internas/ExcepcionesIDetail";
import Cargas from "../components/trazabilidad/excepciones/cargas/Cargas";
import Incidencias from "../components/trazabilidad/incidencias/Incidencias";
import IncidenciasDetail from "../components/trazabilidad/incidencias/IncidenciasDetail";
import Pedidos from "../components/trazabilidad/pedidos/Pedidos";
import PedidosDetail from "../components/trazabilidad/pedidos/PedidosDetail";

import Rma from "../components/trazabilidad/rma/Rma";
import RmaDetail from "../components/trazabilidad/rma/RmaDetail";
import Tipificaciones from "../components/trazabilidad/excepciones/tipificaciones/Tipificaciones";
import TrazabilidadInicio from "../components/trazabilidad/TrazabilidadInicio";
import Verificaciones from "../components/trazabilidad/verificaciones/Verificaciones";
import VerificacionesDetail from "../components/trazabilidad/verificaciones/VerificacionesDetail";

/**
 *
 * Alto Riesgo
 */
import AltoRiesgoInicio from "../components/altoRiesgo/AltoRiesgoInicio";
import Alto from "../components/altoRiesgo/alto/Alto";
import AltoDetail from "../components/altoRiesgo/alto/AltoDetail";
import AtencionClientes from "../components/altoRiesgo/atencionClientes/AtencionClientes";
import Blacklist from "../components/altoRiesgo/blacklist/Blacklist";
import BlacklistNombres from "../components/altoRiesgo/blacklistNombres/BlacklistNombres";
import Complementos from "../components/altoRiesgo/complementos/Complementos";
import Contracargos from "../components/altoRiesgo/contracargos/Contracargos";
import LogisticaAlto from "../components/altoRiesgo/logistica/Logistica";
import NotificacionesAlto from "../components/altoRiesgo/notificaciones/Notificaciones";
import OrdenesAlto from "../components/altoRiesgo/ordenes/Ordenes";

/**
 *
 * Venta Mayoreo
 */
import Requerimientos from "../components/ventaMayoreo/requerimientos/Requerimientos";
import Validaciones from "../components/ventaMayoreo/validaciones/Validaciones";
import Cotizaciones from "../components/ventaMayoreo/cotizaciones/Cotizaciones";
import Traspasos from "../components/ventaMayoreo/traspasos/Traspasos";
import { DetalleTraspaso } from "../components/ventaMayoreo/traspasos/detalle/DetalleTraspaso";
import Surtidos from "../components/ventaMayoreo/surtidos/Surtidos";
import OrdenesCompra from "../components/ventaMayoreo/ordenesCompra/OrdenesCompra";
import OrdenesVenta from "../components/ventaMayoreo/ordenesVenta/OrdenesVenta";
import VMProveedores from "../components/ventaMayoreo/proveedores/Proveedores";
import Clientes from "../components/ventaMayoreo/clientes/Clientes";
import Distribuidores from "../components/ventaMayoreo/distribuidores/Distribuidores";
import Resellers from "../components/ventaMayoreo/resellers/Resellers";
import Uniformes from "../components/ventaMayoreo/uniformes/Uniformes";
import Productos from "../components/ventaMayoreo/productos/Productos";
import Configuraciones from "../components/ventaMayoreo/configuraciones/Configuraciones";
import BitacoraEnvio from "../components/ventaMayoreo/bitacoraEnvio/BitacoraEnvio";

/**
 *
 * Proveedores
 */
import Ventas from "../components/proveedores/ventas/Ventas";
import EtiquetasEzetera from "../components/proveedores/etiquetasEzetera/EtiquetasEzetera";
import EtiquetasProveedor from "../components/proveedores/etiquetasProveedor/EtiquetasProveedor";
import AsignarOC from "../components/proveedores/asignarOC/AsignarOC";
import PedidosRecibidos from "../components/proveedores/pedidosRecibidos/PedidosRecibidos";
import Stock from "../components/proveedores/stock/Stock";
import MovimientosInventario from "../components/proveedores/movimientosInventario/MovimientosInventario";

/**
 *
 * Vales
 */
import Temporadas from "../components/vales/temporadas/Temporadas";
import TemporadasDetail from './../components/vales/temporadas/TemporadasDetail';
import Coordinadores from "../components/vales/coordinadores/Coordinadores";
import Configuracion from "../components/vales/configuracion/Configuracion";
import Beneficiarios from "../components/vales/beneficiarios/Beneficiarios";

/**
 *
 * Reportes
 */
import Reportes from "../components/reportes/reportes/Reportes";

/**
 *
 * Capital Humano
 */
import AprobarVacaciones from "../components/capitalHumano/aprobarVacaciones/AprobarVacaciones";
import AdministrarVacaciones from "../components/capitalHumano/administrarVacaciones/AdministrarVacaciones";
import SolicitarVacaciones from "../components/capitalHumano/solicitarVacaciones/SolicitarVacaciones";
import Prestamos from "../components/capitalHumano/prestamos/Prestamos";

/**
 *
 * Pagos
 */
import SolicitudPagos from "../components/pagos/solicitud/Solicitud";
import PagosAprobaciones from "../components/pagos/aprobaciones/Aprobaciones";
import AltaProveedores from "../components/pagos/proveedores/Proveedores";

/**
 *
 * CRM
 */
import AsesoriaCasoById from "../components/crm/AsesoriaCasoById";
import CrmHome from "../components/crm/CrmHome";
import TicketById from "../components/crm/TicketById";
import TicketCerradoById from "../components/crm/TicketCerradoById";

/**
 *
 * Cancelaciones
 */
import Cancelaciones from '../components/cancelaciones/cancelaciones/Cancelaciones';
import CancelacionesDetail from '../components/cancelaciones/cancelaciones/CancelacionesDetail';
import Detalle from "../components/trazabilidad/aclaraciones/Detalle";
import CotizacionesDetail from "../components/ventaMayoreo/cotizaciones/CotizacionesDetail";
import EditarCotizacion from "../components/ventaMayoreo/cotizaciones/EditarCotizacion/EditarCotizacion";
import OrdenDeVenta from "../components/ventaMayoreo/cotizaciones/ordenDeVenta/OrdenDeVenta";
import { DetalleOCompra } from "../components/ventaMayoreo/ordenesCompra/detalle/DetalleOCompra";
import { GenerarOrdenCompra } from "../components/ventaMayoreo/ordenesCompra/generarOrden/GenerarOrdenCompra";
import { OrdenVentaDetalle } from "../components/ventaMayoreo/ordenesVenta/detalle/OrdenVentaDetalle";
import { ValidacionesDetail } from "../components/ventaMayoreo/validaciones/detalle/ValidacionesDetail";
import { GenerarTraspasos } from "../components/ventaMayoreo/traspasos/detalle/GenerarTraspasos";


const Routess = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="login" element={<Login />} />
        <Route element={<Dashboard children={<Outlet />} />}>
          <Route path="dashboard" element={<DashboardHome />} />
          <Route path="reload" element={<Reload />} />

          <Route path="control-accesos">
            <Route index element={<Accesos />} />
            <Route path="accesos" element={<Accesos />} />
            <Route path="solicitudes" element={<Solicitudes />} />
            <Route path="administracion" element={<Administracion />} />
            <Route path="reportes" element={<ReportesAccesos />} />
            <Route path="matrices" element={<Matrices />} />
            <Route
              path="administracion-proveedores"
              element={<AdminProveedores />}
            />
          </Route>

          <Route path="trazabilidad">
            <Route path="inicio" element={<TrazabilidadInicio />} />
            <Route path="pedidos/*" element={<Pedidos />} />
            <Route path="pedidos/detail/:id" element={<PedidosDetail />} />
            
            <Route path="rma/*" element={<Rma />} />
            <Route path="rma/detail/:id" element={<RmaDetail />} />
            <Route path="aclaraciones/*" element={<Aclaraciones />} />
            <Route path="aclaraciones/detail/:id" element={<AclaracionesDetail />} />
            <Route path="aclaraciones/:id" element={<Detalle />} />
            <Route path="backorders/*" element={<Backorders />} />
            <Route
              path="backorders/detail/:id"
              element={<BackordersDetail />}
            />
            <Route path="excepciones/*" element={<Excepciones />} />
            <Route path="excepciones/cargas/*" element={<Cargas />} />
            <Route
              path="excepciones/tipificaciones/*"
              element={<Tipificaciones />}
            />
            <Route
              path="excepciones/detail/:id"
              element={<ExcepcionesDetail />}
            />
            <Route path="excepciones-internas/*" element={<ExcepcionesI />} />
            <Route
              path="excepciones-internas/detail/:id"
              element={<ExcepcionesIDetail />}
            />
            <Route path="carriers/*" element={<Carriers />} />
            <Route path="incidencias/*" element={<Incidencias />} />
            <Route
              path="incidencias/detail/:id"
              element={<IncidenciasDetail />}
            />
            <Route path="verificaciones/*" element={<Verificaciones />} />
            <Route
              path="verificaciones/detail/:id"
              element={<VerificacionesDetail />}
            />
          </Route>

          <Route path="alto-riesgo">
            <Route path="inicio" element={<AltoRiesgoInicio />} />
            <Route path="alto/*" element={<Alto />} />
            <Route path="alto/detail/:id" element={<AltoDetail />} />
            <Route path="atencion-clientes/*" element={<AtencionClientes />} />
            <Route path="blacklist/*" element={<Blacklist />} />
            <Route path="blacklist-nombres/*" element={<BlacklistNombres />} />
            <Route path="complementos/*" element={<Complementos />} />
            <Route path="contracargos/*" element={<Contracargos />} />
            <Route path="logistica/*" element={<LogisticaAlto />} />
            <Route path="notificaciones/*" element={<NotificacionesAlto />} />
            <Route path="ordenes/*" element={<OrdenesAlto />} />
          </Route>

          <Route path="venta-mayoreo">
            <Route index element={<Requerimientos />} />
            <Route path="requerimientos" element={<Requerimientos />} />
            <Route path="validaciones" element={<Validaciones />} />
            <Route path="validaciones/detail/:id1/:id2" element={<ValidacionesDetail />} />
            <Route path="cotizaciones" element={<Cotizaciones />} />
            <Route path="cotizaciones/detail/:id" element={<CotizacionesDetail />} />
            <Route path="cotizaciones/editarcotizacion/:id" element={<EditarCotizacion />} />
            <Route path="cotizaciones/ordendeventa/:id" element={<OrdenDeVenta />} />

            <Route path="traspasos" element={<Traspasos />} />
            <Route path="traspasos/detail/:id" element={<DetalleTraspaso />} />
            <Route path="traspasos/generartraspaso" element={<GenerarTraspasos />} />
            <Route path="surtidos" element={<Surtidos />} />
            <Route path="ordenes-compra" element={<OrdenesCompra />} />
            <Route path="ordenes-compra/detail/:id" element={<DetalleOCompra />} />
            <Route path="ordenes-compra/generarordencompra" element={<GenerarOrdenCompra />} />

            <Route path="ordenes-venta" element={<OrdenesVenta />} />
            <Route path="ordenes-venta/detail/:id" element={<OrdenVentaDetalle />} />
            <Route path="proveedores" element={<VMProveedores />} />
            <Route path="clientes" element={<Clientes />} />
            <Route path="distribuidores" element={<Distribuidores />} />
            <Route path="resellers" element={<Resellers />} />
            <Route path="uniformes" element={<Uniformes />} />
            <Route path="productos" element={<Productos />} />
            <Route path="configuraciones" element={<Configuraciones />} />
            <Route path="bitacora-envios" element={<BitacoraEnvio />} />
          </Route>

          <Route path="crm">
            <Route index element={<CrmHome />} />
            <Route path="ticket/:id" element={<TicketById />} />
            <Route path="closed/:id" element={<TicketCerradoById />} />
            <Route path="case/:id" element={<AsesoriaCasoById />} />
          </Route>

          <Route path="proveedores">
            <Route index element={<Ventas />} />
            <Route path="ventas/*" element={<Ventas />} />
            <Route path="etiquetas-ezetera/*" element={<EtiquetasEzetera />} />
            <Route path="etiquetas-proveedor/*" element={<EtiquetasProveedor />} />
            <Route path="asignar-oc/*" element={<AsignarOC />} />
            <Route path="pedidos-recibidos/*" element={<PedidosRecibidos />} />
            <Route path="stock/*" element={<Stock />} />
            <Route path="movimientos/*" element={<MovimientosInventario />} />
          </Route>

          <Route path="vales">
            <Route index element={<Temporadas />} />
            <Route path="temporadas/*" element={<Temporadas />} />
            <Route path="temporadas/detail/:id" element={<TemporadasDetail/>}/> 
            <Route path="coordinadores/*" element={<Coordinadores />} />
            <Route path="configuracion/*" element={<Configuracion />} />
            <Route path="beneficiarios/*" element={<Beneficiarios />} />
          </Route>

          <Route path="reportes">
            <Route index element={<Reportes />} />
          </Route>

          <Route path="capital-humano">
            <Route index element={<SolicitarVacaciones />} />
            <Route
              path="aprobacion-vacaciones"
              element={<AprobarVacaciones />}
            />
            <Route
              path="administrar-vacaciones"
              element={<AdministrarVacaciones />}
            />
            <Route
              path="solicitar-vacaciones"
              element={<SolicitarVacaciones />}
            />
            <Route path="prestamos" element={<Prestamos />} />
          </Route>

          <Route path="pagos">
            <Route index element={<SolicitudPagos />} />
            <Route path="solicitud/*" element={<SolicitudPagos />} />
            <Route path="aprobaciones/*" element={<PagosAprobaciones />} />
            <Route path="alta-proveedores/*" element={<AltaProveedores />} />
          </Route>

          <Route path="cancelaciones">
            <Route index element={<Cancelaciones />} />
            <Route path="detail/:id" element={<CancelacionesDetail/>}/> 
          </Route>

          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </>
  );
};

export default Routess;