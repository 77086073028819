import { Button,Table,TableBody,TableCell,TableHead,TableRow,CircularProgress } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import DateInTable from "../../general/DateInTable";
import { getInteractions } from "../../../utils/crm/buscador";

const InteraTab = ({ correo }) => {

    const [interacciones, setInteracciones] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const loadInteracciones = async () => {
        if (!correo) {
          return;
        }
        setIsLoading(true);
        try {
          const newInteracciones = await getInteractions(correo)
          setInteracciones(newInteracciones);
        } catch (error) {
          console.error("handleSearch", error);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        loadInteracciones();
    }, []);

    return (
        <>
            {isLoading && <CircularProgress />}
            {interacciones && (
            <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Fecha</TableCell>
                    <TableCell>Tipo</TableCell>
                    <TableCell>Descripcion</TableCell>
                    <TableCell>Canal</TableCell>
                    <TableCell>Contacto</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {interacciones &&
                    interacciones.map((interaccion, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <DateInTable dateString={interaccion.date_register} />
                        </TableCell>
                        <TableCell>{interaccion.type}</TableCell>
                        <TableCell>
                          {interaccion.description?.slice(0, 100) ?? ""}
                        </TableCell>
                        <TableCell>{interaccion.channel}</TableCell>
                        <TableCell>{interaccion.contact}</TableCell>
                        <TableCell>{interaccion.name_s}</TableCell>
                        <TableCell>
                          <Button
                            variant="outlined"
                            size="small"
                            disableElevation
                          >
                            Detalle
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
            </Table>
            )}
        </>
    )
};

export default InteraTab;