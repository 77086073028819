import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";

const ResourcesContext = createContext();

const ResourcesProvider = ({ children }) => {
  const [colleges, setColleges] = useState([]);
  const [channels, setChannels] = useState([]);
  const [typeCases, setTypeCases] = useState([]);
  const [topics, setTopics] = useState([]);
  const [aclarationsTypes, setAclarationsTypes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [themes, setThemes] = useState([]);

  const getAclarationsTypes = (currentTopics) => {
    const newCategories = new Map();
    currentTopics.forEach((t) => {
      const { id_categoria, nombreCategoria } = t;
      if (id_categoria && nombreCategoria) {
        newCategories.set(nombreCategoria, id_categoria);
      }
    });
    setCategories(newCategories);

    const newThemes = new Map();
    currentTopics.forEach((t) => {
      const { id_tema, nombreTema } = t;
      if (id_tema && nombreTema) {
        newThemes.set(nombreTema, id_tema);
      }
    });
    setThemes(newThemes);

    const valid = currentTopics.filter((t) => t.typecase === "Back Office");
    const aclarations = new Map();
    const result = {};

    valid.forEach((v) => {
      const current = aclarations.get(v.nombreCategoria) ?? [];
      current.push(v.nombreTema);
      aclarations.set(v.nombreCategoria, current);
    });

    for (const aclaration of aclarations) {
      const [key, value] = aclaration;

      result[key] = value;
    }
    return result;
  };

  useEffect(() => {
    let isCancel = false;
    const getResources = async () => {
      if (isCancel) return;
      try {
        const result = await axios.get(`${process.env.REACT_APP_BASEURL}/crm/tickets/resources`);

        if (result?.status === 200) {
          const { colleges, channels, type_cases, topics } = result.data.data;
          setColleges(colleges);
          setChannels(channels);
          setTypeCases(type_cases);
          setTopics(topics);
          setAclarationsTypes(getAclarationsTypes(topics));
        }
      } catch (error) {
        console.error("getResources", error.message);
      }
    };

    getResources();

    return () => {
      isCancel = true;
    };
  }, []);

  return (
    <ResourcesContext.Provider
      value={{
        colleges,
        channels,
        typeCases,
        topics,
        aclarationsTypes,
        categories,
        themes,
      }}
    >
      {children}
    </ResourcesContext.Provider>
  );
};

const useResourses = () => useContext(ResourcesContext);

export { ResourcesProvider, useResourses };
