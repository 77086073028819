import { Delete } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { deleteSchool } from "../../../utils/vales/coordinadores/coordinadores";

const ModalEditSchools = ({
  openModalMC,
  handleCloseModalMC,
  selectedSchoolId,
  handleSchoolChange,
  schools,
  schoolList,
  handleSchoolChangeA,
  setRows,
  setReload,
  handleDeleteUser
}) => {
  return (
    <Dialog open={openModalMC} onClose={() => handleCloseModalMC()}>
      <DialogTitle>
        <Typography>
          <strong>Modificar Colegios</strong>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <ListItem>
          <Select
            label="Escuela"
            variant="outlined"
            value={selectedSchoolId}
            onChange={handleSchoolChange}
            sx={{ width: "500px" }}
          >
            {schools.map((school) => (
              <MenuItem key={school.school_id} value={school.school_id}>
                {school.name}
              </MenuItem>
            ))}
          </Select>
        </ListItem>

        <List>
          {schoolList.map((school) => (
            <ListItem
              key={school.school_id}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={(e) => { handleDeleteUser(school.school_id)}}
                >
                  <Delete />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton dense>
                <ListItemText primary={school.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            handleCloseModalMC();
            setRows([]);
            setReload((prevReload) => (prevReload === 0 ? 1 : 0));
          }}
        >
          Cerrar
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={handleSchoolChangeA}
        >
          Agregar Colegio
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalEditSchools;
