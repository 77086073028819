import {
  Box,
  Button,
  Card,
  CardContent,
  LinearProgress,
  Typography,
  Tabs,
  Tab,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useContext, useEffect, useRef, useState } from "react";
import { useAuth } from "../../../../context/AuthContext";
import { profiles } from "../../../../utils/trazabilidad/profilesTrazabilidad";
import BreadCrumb from "../../../general/BreadCrumb";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import {
  editQuotes,
  getEditQuoteInf,
  getEstados,
  getProductEditC,
} from "../../../../utils/ventaMayoreo/cotizaciones/cotizaciones";
import CotizacionDetailItem from "../CotizacionDetailItem";
import { IndexTabs } from "../tabs/IndexTabs";
import InformacionCDD from "./InformacionCDD";
import InformacionRCE from "./InformacionRCE";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { SizeContext } from "../SizeProvider";

const itemsBreadCrumbs = [
  {
    label: "Venta mayoreo",
    icon: <ContentPasteSearchIcon sx={{ mr: 0.5 }} fontSize="small" />,
    link: "/venta-mayoreo",
  },
  { label: "Cotizaciones", link: "/venta-mayoreo/cotizaciones" },
  { label: "Editar" },
];

function CustomCellInfProducts({ row }) {
  return (
    <Table size="small" sx={{ margin: "10px", border: "1px solid #dedede" }}>
      <TableBody>
        <TableRow sx={{ backgroundColor: "#0000000a" }}>
          <TableCell align="left">
            <Typography variant="subtitle2">Nombre:</Typography>
          </TableCell>
          <TableCell align="right">{row?.nombre}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">
            <Typography variant="subtitle2">SKU:</Typography>
          </TableCell>
          <TableCell align="right">{row?.sku}</TableCell>
        </TableRow>
        <TableRow sx={{ backgroundColor: "#0000000a" }}>
          <TableCell align="left">
            <Typography variant="subtitle2">Proveedor:</Typography>
          </TableCell>
          <TableCell align="right">{row?.razon_social}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">
            <Typography variant="subtitle2">Cantidad:</Typography>
          </TableCell>
          <TableCell align="right">{row?.cantidad_disponible}</TableCell>
        </TableRow>
        <TableRow sx={{ backgroundColor: "#0000000a" }}>
          <TableCell align="left">
            <Typography variant="subtitle2">Validación:</Typography>
          </TableCell>
          <TableCell align="right">{row?.validacion_status}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">
            <Typography variant="subtitle2">Precio proveedor:</Typography>
          </TableCell>
          <TableCell align="right">{row?.precio_historial}</TableCell>
        </TableRow>
        <TableRow sx={{ backgroundColor: "#0000000a" }}>
          <TableCell align="left">
            <Typography variant="subtitle2">Descuento proveedor:</Typography>
          </TableCell>
          <TableCell align="right">{row?.descuento_historial}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">
            <Typography variant="subtitle2">
              Porcentaje devolución proveedor:
            </Typography>
          </TableCell>
          <TableCell align="right">
            {row?.porcentaje_devolucion_historial}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

function CustomCellCotizacionC({ row, onChangeCelda }) {
  const [precioCliente, setPrecioCliente] = useState("");
  const [descuentoCliente, setDescuentoCliente] = useState("");
  const [tiempoDevolucion, setTiempoDevolucion] = useState("");
  const [porcentajeDevolucion, setPorcentajeDevolucion] = useState("");

  useEffect(() => {
    if (row.precio !== null) setPrecioCliente(row.precio);
    if (row.descuento !== null) setDescuentoCliente(row.descuento);
    if (row.tiempo_devolucion !== null)
      setTiempoDevolucion(row.tiempo_devolucion);
    if (row.porcentaje_devolucion !== null)
      setPorcentajeDevolucion(row.porcentaje_devolucion);
  }, [row]);

  const onChangePrecio = (e) => {
    setPrecioCliente(e.target.value);
    onChangeCelda(row.id_proveedor_producto, "precioCliente", e.target.value);
  };

  const onChangeDescuento = (e) => {
    setDescuentoCliente(e.target.value);
    onChangeCelda(
      row.id_proveedor_producto,
      "descuentoCliente",
      e.target.value
    );
  };

  const onChangeTiempoDevolucion = (e) => {
    setTiempoDevolucion(e.target.value);
    onChangeCelda(
      row.id_proveedor_producto,
      "tiempoDevolucion",
      e.target.value
    );
  };

  const onChangePorcentajeDevolucion = (e) => {
    setPorcentajeDevolucion(e.target.value);
    onChangeCelda(
      row.id_proveedor_producto,
      "porcentajeDevolucion",
      e.target.value
    );
  };

  const stylesSeccion = {
    textFieldStyle: {
      backgroundColor: "white",
      borderRadius: "6px",
      marginTop: "4px",
    },
  };

  return (
    <Box
      sx={{ backgroundColor: "#3c8dbc", width: "100%", padding: "10px" }}
      p={2}
      color="white"
    >
      <Box mb={1}>
        <Typography variant="subtitle2">Precio al Cliente</Typography>
        <TextField
          sx={{
            ...stylesSeccion.textFieldStyle,
            "& .MuiInputBase-input": {
              paddingY: "5px",
            },
          }}
          size="small"
          value={precioCliente}
          onChange={onChangePrecio}
          variant="outlined"
          fullWidth
        />
      </Box>
      <Box mb={1}>
        <Typography variant="subtitle2">Descuento al Cliente</Typography>
        <TextField
          sx={{
            ...stylesSeccion.textFieldStyle,
            "& .MuiInputBase-input": {
              paddingY: "5px",
            },
          }}
          size="small"
          value={descuentoCliente}
          onChange={onChangeDescuento}
          variant="outlined"
          fullWidth
        />
      </Box>
      <Box mb={1}>
        <Typography variant="subtitle2">Tiempo Devolución</Typography>
        <TextField
          sx={{
            ...stylesSeccion.textFieldStyle,
            "& .MuiInputBase-input": {
              paddingY: "5px",
            },
          }}
          size="small"
          value={tiempoDevolucion}
          onChange={onChangeTiempoDevolucion}
          variant="outlined"
          fullWidth
        />
      </Box>
      <Box mb={1}>
        <Typography variant="subtitle2">Porcentaje Devolución</Typography>
        <TextField
          sx={{
            ...stylesSeccion.textFieldStyle,
            "& .MuiInputBase-input": {
              paddingY: "5px",
            },
          }}
          size="small"
          value={porcentajeDevolucion}
          onChange={onChangePorcentajeDevolucion}
          variant="outlined"
          fullWidth
        />
      </Box>
    </Box>
  );
}

const EditarCotizacion = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;
  const [isLoading, setIsLoading] = useState(false);
  const [inforRCE, setInforRCE] = useState(null);
  const [estados, setEstados] = useState([]);
  const [openM, setOpenM] = useState(false);

  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [keyWord, setKeytoSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [reloadPage, setReloadPage] = useState(0);

  const [valoresFilas, setValoresFilas] = useState({});
  const [valoresEditados, setValoresEditados] = useState({});

  useEffect(() => {
    if (!profiles.rma.includes(auth.user.role.idProfile)) {
      navigate("/dashboard", { replace: true });
    } else {
      const getDetallePedido = async () => {
        try {
          setIsLoading(true);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      };
      getDetallePedido();
    }
  }, [id, auth.user.data.awsid]);

  const columns = [
    {
      field: "producto",
      headerName: "Información del producto",
      flex: 2,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => <CustomCellInfProducts row={params.row} />,
    },
    {
      field: "proveedor",
      headerName: "Cotización al cliente",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <CustomCellCotizacionC row={params.row} onChangeCelda={onChangeCelda} />
      ),
    },
  ];

  if (
    document.querySelector(
      ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
    )
  )
    document
      .querySelector(
        ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
      )
      .remove();

  const onChangeCelda = (id, campo, valor) => {
    setValoresFilas((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        [campo]: valor,
      },
    }));
  };

  const onChangeInfo = (campo, valor) => {
    setValoresEditados((prev) => ({ ...prev, [campo]: valor }));
  };

  const sendData = async () => {
    if (Object.keys(valoresEditados).length > 0) {
      valoresEditados.id = id;
    }

    if (
      Object.keys(valoresEditados).length == 0 &&
      Object.keys(valoresFilas).length == 0
    ) {
      console.log("Ningun campo fue modificado");
      setOpenM(true);
    } else {
      let dataSend = { id, valoresFilas, valoresEditados };
      await editQuotes(dataSend);
      setValoresFilas({});
      setValoresEditados({});
      reloadPageFn();
    }
  };

  useEffect(() => {
    let active = true;
    setLoading(true);
    setRows([]);
    setInforRCE(null);

    (async () => {
      let newRows = null;
      let estados = await getEstados();
      setEstados(estados.data);
      const resInfoRCE = await getEditQuoteInf(id);
      setInforRCE(resInfoRCE.data);

      if (
        typeof keyWord === "undefined" ||
        keyWord === undefined ||
        keyWord.length === 0
      ) {
        setLoading(true);
        newRows = await getProductEditC(page, id);
      } else if (keyWord.length >= 3) {
        setLoading(true);
      } else active = false;

      if (!active) {
        return;
      }
      setTotal(newRows.total);
      setRows(newRows.data);
      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [page, keyWord, reloadPage]);

  const handleCloseM = () => {
    setOpenM(false);
  };

  const reloadPageFn = () => {
    setReloadPage((prevState) => (prevState === 0 ? 1 : 0));
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" sx={{ my: 2 }}>
          Editar Cotización
        </Typography>
        <Box>
          <BreadCrumb items={itemsBreadCrumbs} />
        </Box>
      </Box>

      <motion.div
        initial={{ y: 20 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Card>
          <Box>
            <Box>
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <Typography variant="h6" sx={{ ml: 2, my: 2, mb: 0 }}>
                    <AssignmentIcon sx={{ m: 0 }} /> Editar Cotización
                  </Typography>
                  <Card>
                    <Box style={{ height: 1050, width: "100%" }}>
                      <DataGridPro
                        rows={rows}
                        columns={columns}
                        pagination
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        getRowHeight={() => "auto"}
                        getRowId={(row) => row.id_requerimiento_producto}
                        rowCount={total}
                        paginationMode="server"
                        onPageChange={(newPage) => {
                          setPage(newPage);
                        }}
                        loading={loading}
                        disableColumnMenu
                        disableSelectionOnClick
                        getDetailPanelHeight={() => "auto"}
                      />
                    </Box>
                    <Box>
                      <Grid container spacing={2}>
                        {inforRCE && <InformacionRCE inforRCE={inforRCE} />}

                        {inforRCE && (
                          <InformacionCDD
                            inforRCE={inforRCE}
                            onChange={onChangeInfo}
                            estados={estados}
                          />
                        )}
                      </Grid>

                      <Box sx={{ marginY: "30px" }}>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            md={4}
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Button
                              variant="contained"
                              color="success"
                              onClick={sendData}
                              sx={{ marginX: "10px" }}
                            >
                              Enviar
                            </Button>
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() => {
                                navigate("/venta-mayoreo/cotizaciones");
                              }}
                              sx={{ marginTop: "10px", marginX: "10px" }}
                            >
                              Cancelar
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Card>
      </motion.div>

      <Box>&nbsp;</Box>
      <Dialog open={openM} onClose={handleCloseM}>
        <DialogTitle>{"Error"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            No se ha modificado ningún campo
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseM} color="error" variant="outlined">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditarCotizacion;
