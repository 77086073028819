import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";


const ModalStatusUser = ({ isModalOpen, handleDeny, handleAccept }) => {
  return (
    <Dialog open={isModalOpen} onClose={handleDeny}>
      <DialogTitle>¿Deseas cambiar el estado?</DialogTitle>
      <DialogContent>
        <DialogContentText>¿Estás seguro?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDeny} color="error" variant="outlined" autoFocus>
          Denegar
        </Button>
        <Button onClick={handleAccept} color="success" variant="outlined">
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalStatusUser;
