import { Box,  Chip, LinearProgress, Typography, Tabs, Tab, Grid, Stack, TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle, ListItem,  AppBar, Toolbar, ListItemText, List,  } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState, Fragment } from "react";
import { useAuth } from "../../../context/AuthContext";
import BreadCrumb from "../../general/BreadCrumb";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import { LoadingButton } from "@mui/lab";
import VisibilityIcon from '@mui/icons-material/Visibility';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getAclarations, getAddressHistory, getBackOrders, getComments, getDeliveryAddress, getDetails, getDetailsException, getHRiskDetails, getIncidence, getInvoice, getVerifications, saveComment, updateDirections } from "../../../utils/trazabilidad/pedidos/pedidos";
import IndexTabs from "./tabs/IndexTabs";
import MessageIcon from '@mui/icons-material/Message';
import TabPanel from "../../general/TabPanel";
import AltoRiesgoTab from "./AltoRiesgoTab";
import BackOrdersTab from "./BackOrdersTab";
import ExcepcionesTab from "./ExcepcionesTab";
import AclaracionesTab from "./AclaracionesTab";
import VerificacionesTab from "./VerificacionesTab";
import local from "dayjs/plugin/localizedFormat";
import relative from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import IncidenciasTab from "./IncidenciasTab";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ArticleIcon from '@mui/icons-material/Article';
import WarningIcon from '@mui/icons-material/Warning';
import ImprimirDetalle from "./ImprimirDetalle";


dayjs.locale("es-mx");
dayjs.extend(local);
dayjs.extend(relative);
dayjs.extend(utc);

const ListItemDetail = ({ itemName, itemValue }) => {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: 1,
            }}
        >
            <Typography sx={{ fontWeight: "bold" }}>{itemName}</Typography>
            <Typography>{itemValue}</Typography>
        </Box>
    );
};
const chipCarrierColor = {
    Fedex: "#f44336",
    DHL: "#2196f3",
    Estafeta: "#e53935",
};

const chipColors = {
    "canceled": "#e53935",
    "complete": "#43a047",
    "completed": "#43a047",
    "entregado": "#43a047",
    "exception": "#ffa000",
    "paid": "#43a047",
    "pending": "#ffa000",
    "in_route": "#ffa000",
    "today": "#8e24aa",
    "closed": "#e53935",
    "stocking": "#3c8dbc",
};
const chipLabels = {
    "canceled": "Cancelado",
    "complete": "Completado",
    "completed": "Completado",
    "entregado": "Entregado",
    "exception": "Excepción",
    "paid": "Pagado",
    "pending": "Pendiente",
    "in_route": "En Ruta",
    "today": "Hoy",
    "closed": "Cerrado",
    "stocking": "Stocking",
};

const itemsBreadCrumbs = [
    {
        label: 'Trazabilidad',
        icon: <ContentPasteSearchIcon sx={{ mr: 0.5 }} fontSize="small" />,
        link: '/trazabilidad/inicio'
    },
    { label: 'Pedidos', link: '/trazabilidad/pedidos', },
    { label: 'Detalle' }
];



const direccionLimpia = {
    increment_id: "",
    customer_name: "",
    customer_lastname: "",
    customer_mothersname: "",
    shipping_street: "",
    shipping_outside_number: "",
    shipping_inside_number: "",
    shipping_delegation: "",
    shipping_suburb: "",
    shipping_postal_code: "",
    shipping_state: "",
    telephone: "",
    shipping_city: "",
    between_streets: "",
    country: ""
};

const PedidosDetail = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const params = useParams();
    const id = params.id; //id = id_season
    const [enabled, setEnabled] = useState(false);
    const [nuevaDireccion, setNuevaDireccion] = useState(direccionLimpia);
    const [antiguaDireccion, setAntiguaDireccion] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [altoR, setAltoR] = useState(null);
    const [backO, setBackO] = useState(null);
    const [exceptions, setExceptions] = useState(null);
    const [aclaraciones, setAclaraciones] = useState(null);
    const [incidencias, setIncidencias] = useState(null);
    const [verificaciones, setVerificaciones] = useState(null);

    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [keyWord, setKeytoSearch] = useState("");

    const [mensaje, setMensaje] = useState("");

    const [tab, setTab] = useState(0);
    const [tab2, setTab2] = useState(0);

    const [detalleDomicilio, setDetalleDomicilio] = useState(null);
    const [detalleHistorico, setDetalleHistorico] = useState(null);

    const [loading, setLoading] = useState(false); //Loading Index
    const [loadingDetalle, setLoadingDetalle] = useState(false); //Loading detalle del pedido
    const [isLoading, setIsLoading] = useState(false);

    const [detallePedido, setDetallePedido] = useState(null);
    const [detalleComentarios, setDetalleComentarios] = useState(null);
    const [detalleInvoice, setDetalleInvoice] = useState(null);

    const [openModalDeliveryA, setOpenModalDeliveryA] = useState(false);

    const handleOpenModalDeliveryA = () => {
        getDomicilio();
        getHistoricoD();
        setOpenModalDeliveryA(true);
    };

    const handleCloseModalDeliveryA = () => {
        setEnabled(false);
        getDomicilio();
        setOpenModalDeliveryA(false);
    };

    const [openModalComments, setOpenModalComments] = useState(false);

    const handleOpenModalComments = () => {
        setOpenModalComments(true);
    };

    const handleCloseModalComments = () => {
        setOpenModalComments(false);
    };

    const handleTextFieldChange = (event) => {
        const { name, value } = event.target;
        setInputValue(event.target.value);
        setNuevaDireccion({ ...nuevaDireccion, [name]: value });
    };

    const getDetalle = async () => {
        try {
            setLoadingDetalle(true);
            const res = await getDetails(id);
            setDetallePedido(res);
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingDetalle(false);
        }
    };

    const getComentarios = async () => {
        try {
            setIsLoading(true);
            const res = await getComments(id);
            setDetalleComentarios(res);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const getDomicilio = async () => {
        try {
            setIsLoading(true);
            const res = await getDeliveryAddress(id);
            setDetalleDomicilio(res);
            setNuevaDireccion({ ...res.data[0] });
        } catch (error) {
            console.error(error);
        } finally {
            setInputValue('true');
            setIsLoading(false);
        }
    };

    const habEditDomicilio = () => {
        setEnabled(!enabled);
    };

    const getHistoricoD = async () => {
        try {
            setIsLoading(true);
            const res = await getAddressHistory(id);
            setDetalleHistorico(res);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const getInvoiceDetails = async () => {
        try {
            setIsLoading(true);
            const res = await getInvoice(id);
            setDetalleInvoice(res);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDescargarFactura = (id, pdf, xml) => {
        handleDescargarPDF(id, pdf);
        handleDescargarXML(id, xml);
    };

    const handleDescargarPDF = (id, base64) => {
        console.log(id, base64);
        const linkSource = `data:application/pdf;base64,${base64}`;
        const downloadLink = document.createElement("a");

        const fileNameWithExt = `invoice - ${id}.pdf`;

        downloadLink.href = linkSource;
        downloadLink.download = fileNameWithExt;
        downloadLink.click();
    };

    const handleDescargarXML = (id, base64) => {
        console.log(id, base64);
        const linkSource = `data:application/xml;base64,${base64}`;
        const downloadLink = document.createElement("a");

        const fileNameWithExt = `invoice - ${id}.xml`;

        downloadLink.href = linkSource;
        downloadLink.download = fileNameWithExt;
        downloadLink.click();
    };

    const handleEditarDomicilio = async () => {

        let direccionActualizada = {
            id_usuario: user.data.awsid,
            name_user: user.data.displayName,
            created_at: dayjs().format('YYYY-MM-DD HH:mm:ss'),
            increment_id: id,
            name: nuevaDireccion.customer_name,
            lastname: nuevaDireccion.customer_lastname,
            mothersname: nuevaDireccion.customer_mothersname,
            street: nuevaDireccion.shipping_street,
            inside_number: nuevaDireccion.shipping_inside_number,
            outside_number: nuevaDireccion.shipping_outside_number,
            delegation: nuevaDireccion.shipping_delegation,
            suburb: nuevaDireccion.shipping_suburb,
            ciudad: nuevaDireccion.shipping_city,
            postal_code: nuevaDireccion.shipping_postal_code,
            state: nuevaDireccion.shipping_state,
            telephone: nuevaDireccion.telephone,
            between_streets: nuevaDireccion.between_streets,
            oldName: detalleDomicilio.data[0].customer_name,
            oldLastName: detalleDomicilio.data[0].customer_lastname,
            oldMothersName: detalleDomicilio.data[0].customer_mothersname,
            oldShippingStreet: detalleDomicilio.data[0].shipping_street,
            oldShippingOutsideNumber: detalleDomicilio.data[0].shipping_outside_number,
            oldShippingInsideNumber: detalleDomicilio.data[0].shipping_inside_number,
            oldShippingDelegation: detalleDomicilio.data[0].shipping_delegation,
            oldShippingSuburb: detalleDomicilio.data[0].shipping_suburb,
            oldShippingPostalCode: detalleDomicilio.data[0].shipping_postal_code,
            oldShippingState: detalleDomicilio.data[0].shipping_state,
            oldTelephone: detalleDomicilio.data[0].telephone,
            oldShippingCity: detalleDomicilio.data[0].shipping_city,
            oldBetweenStreets: detalleDomicilio.data[0].between_streets,
            oldCountry: detalleDomicilio.data[0].country
        }
        try {
            setIsLoading(true);
            console.log(direccionActualizada);
            await updateDirections(direccionActualizada);
        } catch (error) {
            console.error("handleEditarDomicilio", error);
        } finally {
            setEnabled(false);
            setIsLoading(false);
        }
    };

    const getAltoRiesgo = async () => {
        try {
            setIsLoading(true);
            const res = await getHRiskDetails(id);
            setAltoR(res);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const getBackO = async () => {
        try {
            setIsLoading(true);
            const res = await getBackOrders (id, 0);
            setBackO(res);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const getExceptions = async () => {
        try {
            setIsLoading(true);
            const res = await getDetailsException(id);
            setExceptions(res);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const getAclaraciones = async () => {
        try {
            setIsLoading(true);
            const res = await getAclarations (id, 0);
            setAclaraciones(res);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const getIncidencias = async () => {
        try {
            setIsLoading(true);
            const res = await getIncidence (id, 0);
            setIncidencias(res);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const getVerificaciones = async () => {
        try {
            setIsLoading(true);
            const res = await getVerifications (id, 0);
            setVerificaciones(res);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleClose = () => {
        setMensaje("");
    };

    const handleSaveMessage = async () => {
        try {
            setIsLoading(true);
            await saveComment (id, mensaje);
            getComentarios();
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
            handleClose();
        }
    };

    if (document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"))
        document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']").remove();

    useEffect(() => {
        getAltoRiesgo();
        getBackO();
        getExceptions();
        getAclaraciones();
        getIncidencias();
        getVerificaciones();

        getInvoiceDetails();
        getDetalle();
        getComentarios();
        getDomicilio();
        getHistoricoD();
    }, [id]);

    return (<>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography variant="h4" sx={{ my: 2 }}>Pedidos</Typography>
            <Box><BreadCrumb items={itemsBreadCrumbs} /></Box>
        </Box>
        {loadingDetalle && <LinearProgress />}
        {detallePedido && <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Grid container spacing={4} >
                    <Grid item md={6}>

                        <Box sx={{ mb: 2, px: 2, pb: 1, pt: 2 }} component={Paper}>
                            <Stack direction="row" justifyContent="space-between" alignItems="" mb={1} spacing={1}>
                                <Typography variant="h5">{detallePedido.data.increment_id}</Typography>
                                <Chip label={chipLabels[detallePedido.data.status]} size="small" sx={{ backgroundColor: chipColors[detallePedido.data.status], color: "#fff", }} />
                            </Stack>
                            <ListItemDetail
                                itemName="Número pedido"
                                itemValue={detallePedido.data.increment_id}
                            />
                            <ListItemDetail
                                itemName="Total de guías"
                                itemValue={detallePedido.data.total_guias}
                            />
                            <ListItemDetail
                                itemName="Fecha de creación"
                                itemValue={dayjs(detallePedido.data.created_at).format("DD MMM YYYY, hh:mm:ss A")}
                            />
                            <ListItemDetail
                                itemName="Fecha de pago"
                                itemValue={dayjs(detallePedido.data.payment_date).format("DD MMM YYYY, hh:mm:ss A")}
                            />
                            <ListItemDetail
                                itemName="Método de pago"
                                itemValue={detallePedido.data.method_name}
                            />
                            <ListItemDetail
                                itemName="Tipo Servicio"
                                itemValue={detallePedido.data.shipping_type ? detallePedido.data.shipping_type : "S/Servicio"}
                            />
                            <ListItemDetail
                                itemName="Carrier"
                                itemValue={detallePedido.data.shipping_carrier ? <Chip size="small" label={detallePedido.data.shipping_carrier} sx={{ backgroundColor: chipCarrierColor[detallePedido.data.shipping_carrier], color: "#fff", }} /> : "S/Carrier"}
                            />
                            <ListItemDetail
                                itemName="Números de productos"
                                itemValue={detallePedido.data.total_productos}
                            />
                            <ListItemDetail
                                itemName="Enviado a WMS"
                                itemValue={detallePedido.data.is_sended_logistic === 1 ? "Si" : "No"}
                            />
                            <ListItemDetail
                                itemName="Status WMS"
                                itemValue={detallePedido.data.STATUS ? (detallePedido.data.STATUS != null ? detallePedido.data.STATUS : "Sin Estatus") : "Sin Estatus "}
                            />
                            {(detallePedido.data.is_ocurreForzoso) === null && <>
                                <ListItemDetail
                                    itemName="Ocurre Forzoso"
                                    itemValue="No"
                                /></>}
                            {(detallePedido.data.is_ocurreForzoso) !== null && <>
                                <ListItemDetail
                                    itemName="Ocurre Forzoso"
                                    itemValue={detallePedido.data.is_ocurreForzoso != null ? "Si" : "No"}
                                /></>}
                            <Stack sx={{ my: 2 }} direction="row" alignItems="center" spacing={1}>
                                {detalleDomicilio && <ImprimirDetalle details={detallePedido.data} domicilio={detalleDomicilio.data[0]} />}
                                <Button startIcon={<VisibilityIcon />} size="small" variant="contained" onClick={() => handleOpenModalDeliveryA()}>
                                    Ver Domicilio
                                </Button>
                                <Button startIcon={<MessageIcon />} size="small" variant="contained" onClick={() => handleOpenModalComments()}>
                                    Comentario
                                </Button>
                            </Stack>
                        </Box>

                    </Grid>

                    <Grid item md={6}>
                        <Box component={Paper}>
                            {detalleInvoice && <>
                                {detalleInvoice.data.map(({ invoice_required, invoice_pdf, invoice_xml, invoice_date }) => (
                                    <>
                                        {!(invoice_required === 0) && <>
                                            <Box sx={{ ml: 7, py: 2 }} >
                                                <Stack direction="row" justifyContent={"space-evenly"} alignItems={"center"}>
                                                    <Button startIcon={<FileDownloadIcon />} size="small" variant="contained" color="success" onClick={() => handleDescargarFactura(id, invoice_pdf, invoice_xml)}>Factura</Button>
                                                    <Button startIcon={<ArticleIcon />} size="small" variant="contained" color="error" onClick={() => handleDescargarPDF(id, invoice_pdf)}>PDF</Button>
                                                    <Button startIcon={<ArticleIcon />} size="small" variant="contained" color="info" onClick={() => handleDescargarXML(id, invoice_xml)}>XML</Button>
                                                </Stack>
                                            </Box>
                                        </>}
                                    </>
                                ))}
                            </>}

                            <Tabs value={tab2} onChange={(e, v) => setTab2(v)} variant="scrollable" scrollButtons="auto">
                                {console.log(altoR)}
                                <Tab label="Alto Riesgo" icon={altoR.data[0].id_orden !== null ? <WarningIcon/> : ""} iconPosition="end" disabled={altoR.data[0].id_orden === null}/>
                                <Tab label="Backorders" icon={backO.total !== 0 ? <WarningIcon/> : ""} iconPosition="end" disabled={backO.total === 0}/>
                                <Tab label="Excepciones" icon={exceptions.data.id ? <WarningIcon/> : ""} iconPosition="end" disabled={exceptions.data.id ? false : true}/>
                                <Tab label="Aclaraciones" icon={aclaraciones.total !== 0 ? <WarningIcon/> : ""} iconPosition="end" disabled={aclaraciones.total === 0}/>
                                <Tab label="Incidencias" icon={incidencias.total !== 0 ? <WarningIcon/> : ""} iconPosition="end" disabled={incidencias.total === 0}/>
                                <Tab label="Verificaciones" icon={verificaciones.total !== 0 ? <WarningIcon/> : ""} iconPosition="end" disabled={verificaciones.total === 0}/>
                            </Tabs>

                            <TabPanel value={tab2} index={0} >
                                {altoR.data[0].id_orden !== null && <AltoRiesgoTab id={id} />}
                            </TabPanel>

                            <TabPanel value={tab2} index={1}>
                                {backO.total !== 0 && <BackOrdersTab id={id} />}
                            </TabPanel>

                            <TabPanel value={tab2} index={2}>
                                {exceptions.data.id && <ExcepcionesTab id={id} />}
                            </TabPanel>

                            <TabPanel value={tab2} index={3}>
                                {aclaraciones.total !== 0 && <AclaracionesTab id={id} />}
                            </TabPanel>

                            <TabPanel value={tab2} index={4}>
                                {incidencias.total !== 0 && <IncidenciasTab id={id} />}
                            </TabPanel>

                            <TabPanel value={tab2} index={5}>
                                {verificaciones.total !== 0 && <VerificacionesTab id={id} />}
                            </TabPanel>

                        </Box>
                    </Grid>
                </Grid>
            </Box>

            {detalleDomicilio &&
                <Box sx={{ pb:2 }} component={Paper}>
                    {<IndexTabs increment_id={id} domicilio={detalleDomicilio} />}
                </Box>
            }


            <Dialog open={openModalDeliveryA} onClose={() => handleCloseModalDeliveryA()} fullWidth={true}>
                <DialogTitle>
                    <Typography><strong>Domicilio de entrega</strong></Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid item xs={8}>
                        <Tabs value={tab} onChange={(e, v) => setTab(v)}>
                            <Tab label="Domicilio" />
                            <Tab label="Historico" />
                        </Tabs>
                        <TabPanel value={tab} index={0}>
                            <Box sx={{ mt: 1 }}>
                                <Box component="form"
                                    sx={{
                                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                                    }}
                                    noValidate
                                    autoComplete="off">
                                    {detalleDomicilio && <>
                                        {detalleDomicilio.data.map(({
                                            customer_name,
                                            customer_lastname,
                                            customer_mothersname,
                                            shipping_street,
                                            shipping_outside_number,
                                            shipping_inside_number,
                                            shipping_delegation,
                                            shipping_suburb,
                                            shipping_postal_code,
                                            shipping_state,
                                            telephone,
                                            shipping_city,
                                            between_streets
                                        }) => (<>
                                            <Stack direction={'row'}>
                                                <TextField disabled={!enabled} id="outlined-disabled" label="Nombre" value={nuevaDireccion.customer_name} name="customer_name" onChange={handleTextFieldChange} required maxLength={40} />
                                                <TextField disabled={!enabled} id="outlined-disabled" label="Apellido Paterno" value={nuevaDireccion.customer_lastname} name="customer_lastname" onChange={handleTextFieldChange} required maxLength={40} />
                                                <TextField disabled={!enabled} id="outlined-disabled" label="Apellido materno" value={nuevaDireccion.customer_mothersname} name="customer_mothersname" onChange={handleTextFieldChange} required maxLength={40} />
                                            </Stack>
                                            <Stack direction={'row'}>
                                                <TextField disabled={!enabled} id="outlined-disabled" label="Calle" value={nuevaDireccion.shipping_street} name="shipping_street" onChange={handleTextFieldChange} required maxLength={255} />
                                                <TextField disabled={!enabled} id="outlined-disabled" label="Num. Exterior" value={nuevaDireccion.shipping_outside_number} name="shipping_outside_number" onChange={handleTextFieldChange} required maxLength={10} />
                                                <TextField disabled={!enabled} id="outlined-disabled" label="Num. Interior" value={nuevaDireccion.shipping_inside_number} name="shipping_inside_number" onChange={handleTextFieldChange} required maxLength={10} />
                                            </Stack>

                                            <Stack direction={'row'}>
                                                <TextField disabled={!enabled} id="outlined-disabled" label="Delegación o Municipio" value={nuevaDireccion.shipping_delegation} name="shipping_delegation" onChange={handleTextFieldChange} required maxLength={60} />
                                                <TextField disabled={!enabled} id="outlined-disabled" label="Colonia" value={nuevaDireccion.shipping_suburb} name="shipping_suburb" onChange={handleTextFieldChange} required maxLength={60} />
                                                <TextField disabled={!enabled} id="outlined-disabled" label="C. P." value={nuevaDireccion.shipping_postal_code} name="shipping_postal_code" onChange={handleTextFieldChange} required maxLength={7} />
                                            </Stack>

                                            <Stack direction={'row'}>
                                                <TextField disabled={!enabled} id="outlined-disabled" label="Estado" value={nuevaDireccion.shipping_state} name="shipping_state" onChange={handleTextFieldChange} required maxLength={60} />
                                                <TextField disabled={!enabled} id="outlined-disabled" label="Teléfono" value={nuevaDireccion.telephone} name="telephone" onChange={handleTextFieldChange} required maxLength={45} />
                                            </Stack>
                                            <Stack direction={'row'}>
                                                <TextField disabled={!enabled} id="outlined-disabled" label="Ciudad" value={nuevaDireccion.shipping_city} name="shipping_city" onChange={handleTextFieldChange} required maxLength={60} />
                                                <TextField disabled={!enabled} id="outlined-disabled" label="Entre que calles" value={nuevaDireccion.between_streets} name="between_streets" onChange={handleTextFieldChange} required maxLength={60} />
                                            </Stack>
                                        </>))}
                                    </>}
                                </Box>
                            </Box>
                        </TabPanel>

                        <TabPanel value={tab} index={1}>
                            <Typography>Histórico de direcciones</Typography>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography>Modificó</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>Dirección</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {detalleHistorico && <>
                                        {detalleHistorico.data.map(({
                                            id_tz_order_adress_history,
                                            created_at,
                                            customer_name,
                                            customer_lastname,
                                            customer_mothersname,
                                            state,
                                            city,
                                            delegation,
                                            suburb,
                                            postal_code,
                                            street,
                                            outside_number,
                                            inside_number,
                                            reference,
                                            phone_number,
                                            creation_user,
                                        }) => (<>
                                            <TableRow>
                                                <TableCell>
                                                    <Stack direction={'column'}>
                                                        <Typography>{'Nombre: ' + creation_user}</Typography>
                                                        <Typography>{'Fecha: ' + dayjs(created_at).format("DD MMM YYYY, hh:mm:ss A")} </Typography>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell>
                                                    <Stack direction={'column'}>
                                                        <Typography>{'Cliente: ' + customer_name ?? + " " + customer_lastname ?? + " " + customer_mothersname}</Typography>
                                                        <Typography>{'Estado: ' + state}</Typography>
                                                        <Typography>{'Ciudad: ' + city}</Typography>
                                                        <Typography>{'Delegación: ' + delegation}</Typography>
                                                        <Typography>{'Colonia: ' + suburb}</Typography>
                                                        <Typography>{'Código Postal: ' + postal_code}</Typography>
                                                        <Typography>{'Calle: ' + street}</Typography>
                                                        <Typography>{'# Exterior: ' + outside_number}</Typography>
                                                        <Typography>{'# Interior: ' + inside_number}</Typography>
                                                        <Typography>{'Referencia: ' + reference}</Typography>
                                                        <Typography>{'Teléfono: ' + phone_number}</Typography>
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        </>))}
                                    </>}
                                </TableBody>
                            </Table>
                        </TabPanel>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={() => handleCloseModalDeliveryA()}>Cerrar</Button>
                    {(tab === 0) && (!enabled) && <Button variant="contained" color="info" onClick={() => (habEditDomicilio())}>Editar domicilio</Button>}
                    {(tab === 0) && (enabled) && <Button variant="contained" color="info" onClick={() => (handleEditarDomicilio())} disabled={!inputValue}>Guardar</Button>}
                </DialogActions>
            </Dialog>

            <Dialog open={openModalComments} onClose={() => handleCloseModalComments()} fullWidth={true}>
                <DialogTitle>
                    <Typography><strong>Comentarios del pedido</strong></Typography>
                </DialogTitle>

                <DialogContent>
                    <Box sx={{ mb: 2, ml: 2, mr: 2 }}>
                        <Fragment>
                            <Box sx={{ height: 200 }}>
                                <Paper square>
                                    {detalleComentarios.data[0].observation && (
                                        <List sx={{ width: '100%', bgcolor: 'background.paper', overflow: 'auto', maxHeight: 'auto', minHeight: 'auto' }}>
                                            <Fragment>
                                                <ListItem button>
                                                    <ListItemText primary="Aún no hay mensajes" />
                                                </ListItem>
                                            </Fragment>
                                        </List>
                                    )}
                                    {detalleComentarios && (<>
                                        {detalleComentarios.data.map(({ observation }) => (<>
                                            <ListItemText>
                                                <Typography>
                                                    {observation}
                                                </Typography>
                                            </ListItemText>
                                        </>))}
                                    </>)}
                                </Paper>
                            </Box>

                            <AppBar position="sticky" color="transparent" sx={{ top: 'auto' }}>
                                <Toolbar>
                                    <TextField
                                        id="outlined-textarea"
                                        label="Escribir mensaje..."
                                        size="small"
                                        value={mensaje}
                                        fullWidth
                                        onChange={(e) => setMensaje(e.target.value)}
                                    />
                                    <LoadingButton loading={isLoading} variant="contained" color="success" size="medium" sx={{ ml: 1 }}
                                    onClick={handleSaveMessage} disabled={!mensaje}
                                    >Enviar</LoadingButton>
                                </Toolbar>
                            </AppBar>
                        </Fragment>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={() => handleCloseModalComments()}>Cerrar</Button>
                </DialogActions>
            </Dialog>
        </>}
    </>);
};

export default PedidosDetail;