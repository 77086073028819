import { Box, Typography, Grid, List, ListItem, ListItemText, LinearProgress, Chip } from "@mui/material";
import DateInTable from "../../general/DateInTable";

const AclaracionesDSDetail = ({ aId, detalle }) => {
    return (
    <>            
        <Grid container>                
            {detalle && (
            <Grid item md={4}>                                    
                <List>
                    <ListItem>
                        <ListItemText primary="Número de pedido" secondary={detalle.id_orden}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Fecha de creación" secondary={<DateInTable dateString={detalle.creation_date} />}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Fecha de pago" secondary={detalle.payment_date ? <DateInTable dateString={detalle.payment_date} /> : '-'}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Nombre de la escuela" secondary={detalle.sname}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Motivo" secondary={detalle.motivo ?? 'S/M'}/>
                    </ListItem>
                </List>
            </Grid>)}

            {detalle && (
            <Grid item md={4}>
                <List>
                    <ListItem>
                        <ListItemText primary="Nombre de cliente" secondary={`${detalle.customer_name} ${detalle.customer_lastname} ${detalle.customer_mothersname}`} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Teléfono" secondary={detalle.telephone ?? 'S/N'}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Email" secondary={detalle.email}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Dirección de envio" secondary={`${detalle.shipping_street}, ${detalle.shipping_city}, ${detalle.shipping_state}, ${detalle.shipping_suburb}, ${detalle.shipping_delegation}`} />
                    </ListItem>
                </List>
            </Grid>)}

            {detalle && (
            <Grid item md={4}>
                <List>
                    <ListItem>
                        <ListItemText primary="Tipificación" secondary={<Chip label={detalle.nombreTipo} variant="outlined" size="small" />}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Carrier" secondary={detalle.shipping_carrier}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Tipo de servicio" secondary={detalle.shipping_type}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Método de pago" secondary={detalle.paymethod}/>
                    </ListItem>
                </List>
            </Grid>)}
        </Grid>            
    </>
    );
};

export default AclaracionesDSDetail;