import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  CardActions,
  LinearProgress,
  Tab,
  Tabs,
  tabsClasses,
} from "@mui/material";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useAuth } from "../../../context/AuthContext";
import { getAclaracionById } from "../../../utils/trazabilidad/aclaraciones/aclaraciones";
import { getAllItems } from "../../../utils/trazabilidad/aclaraciones/articulos";
import { getClavesCuotas } from "../../../utils/trazabilidad/aclaraciones/clavesCuotas";
import { getDatosEnvio } from "../../../utils/trazabilidad/aclaraciones/datosEnvio";
import { getEvidencias } from "../../../utils/trazabilidad/aclaraciones/evidencias";
import { getGuias } from "../../../utils/trazabilidad/aclaraciones/guias";
import { getMensajes } from "../../../utils/trazabilidad/aclaraciones/mensajes";
import { getMovimientos } from "../../../utils/trazabilidad/aclaraciones/movimientos";
import {
  generateReembolso,
  getBCAValues,
  getReembolsos,
} from "../../../utils/trazabilidad/aclaraciones/reembolsos";
import {
  generateRefacturacion,
  getRefacturacion,
  getRFValues,
} from "../../../utils/trazabilidad/aclaraciones/refacturacion";
import TabPanel from "../../general/TabPanel";
import ArticulosTab from "./tabs/ArticulosTab";
import ClavesCuotasTab from "./tabs/ClavesCuotasTab";
import DatosEnvio from "./tabs/DatosEnvioTab";
import EvidenciasTab from "./tabs/EvidenciasTab";
import GuiasTab from "./tabs/GuiasTab";
import MensajesTab from "./tabs/MensajesTab";
import MovimientosTab from "./tabs/MovimentosTab";
import ReembolsoTab from "./tabs/ReembolsoTab";
import RefacturacionTab from "./tabs/RefacturacionTab";

const DetalleTabs = ({ aclaracionId }) => {
  const auth = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [tab, setTab] = useState(0);

  const [detalle, setDetalle] = useState(null);

  const [articulos, setArticulos] = useState(null);
  const [mensajes, setMensajes] = useState(null);
  const [datosEnvio, setDatosEnvio] = useState(null);
  const [guias, setGuias] = useState(null);
  const [reembolso, setReembolso] = useState(null);
  const [refacturacion, setRefacturacion] = useState(null);
  const [evidencias, setEvidencias] = useState(null);
  const [clavesCuotas, setClavesCuotas] = useState(null);
  const [cancelacionesClaves] = useState(null);
  const [movimientos, setMovimientos] = useState(null);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleGenerarRefacturacion = async () => {
    try {
      const rf = await getRFValues(aclaracionId, auth.user.data.awsid);
      console.log("rf", rf);
      await generateRefacturacion(
        aclaracionId,
        auth.user.data.awsid,
        detalle.id_orden,
        rf
      );
    } catch (error) {
      console.error(error);
    }
  };
  const handleGenerarReembolso = async () => {
    try {
      const bca = await getBCAValues(aclaracionId, auth.user.data.awsid);
      console.log("bca", bca);
      await generateReembolso(
        aclaracionId,
        auth.user.data.awsid,
        detalle.id_orden,
        bca
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);

        const res = await getAclaracionById(aclaracionId, auth.user.data.awsid);
        setDetalle(res);

        const mensajesData = await getMensajes(aclaracionId);
        setMensajes(mensajesData);

        const datosEnvioData = await getDatosEnvio(aclaracionId);
        setDatosEnvio(datosEnvioData);

        const guiasData = await getGuias(aclaracionId);
        setGuias(guiasData);

        const reembolsoData = await getReembolsos(aclaracionId);
        setReembolso(reembolsoData);

        const refacturacionData = await getRefacturacion(
          aclaracionId,
          auth.user.data.awsid
        );
        setRefacturacion(refacturacionData);

        const evidenciasData = await getEvidencias(aclaracionId);
        setEvidencias(evidenciasData);

        const clavesCuotasData = await getClavesCuotas(aclaracionId);
        setClavesCuotas(clavesCuotasData);

        // TODO: cancelacionesClaves

        const movimientosData = await getMovimientos(aclaracionId);
        setMovimientos(movimientosData);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    getData();
  }, [aclaracionId, auth.user.data.awsid]);

  // console.log("detalle", detalle);
  useEffect(() => {
    const handleGetAllItems = async () => {
      if (!detalle) return;
      const articulosData = await getAllItems(detalle.id_orden);
      setArticulos(articulosData);
      console.log("articulosData", articulosData);
    };

    handleGetAllItems();
  }, [detalle]);

  return (
    <motion.div
      initial={{ y: 20 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Card sx={{ mb: 3 }}>
        {isLoading && <LinearProgress />}
        {detalle && (
          <Box
            sx={{
              flexGrow: 1,
              bgcolor: "background.paper",
            }}
          >
            <Tabs
              value={tab}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              sx={{
                [`& .${tabsClasses.scrollButtons}`]: {
                  "&.Mui-disabled": { opacity: 0.3 },
                },
              }}
            >
              <Tab
                label="Articulos"
                disabled={
                  ![10, 7, 5, 6, 3].includes(detalle.id_tipificacion_ac)
                }
              />
              <Tab
                label="Mensajes"
                disabled={
                  ![10, 7, 5, 6, 3].includes(detalle.id_tipificacion_ac)
                }
              />
              <Tab
                label="Datos de envio"
                disabled={![].includes(detalle.id_tipificacion_ac)}
              />
              <Tab
                label="Guias"
                disabled={![].includes(detalle.id_tipificacion_ac)}
              />
              <Tab
                label="Reembolso"
                disabled={![7, 6, 5].includes(detalle.id_tipificacion_ac)}
              />
              <Tab
                label="Refacturacion"
                disabled={![4].includes(detalle.id_tipificacion_ac)}
              />
              <Tab
                label="Evidencias"
                disabled={![6].includes(detalle.id_tipificacion_ac)}
              />
              <Tab
                label="Claves y cuotas"
                disabled={![10].includes(detalle.id_tipificacion_ac)}
              />
              <Tab
                label="Cancelaciones de claves"
                disabled={![].includes(detalle.id_tipificacion_ac)}
              />
              <Tab label="Movimientos" />
            </Tabs>
            <TabPanel value={tab} index={0}>
              {/* {JSON.stringify(articulos, null, 2)} */}
              <ArticulosTab
                articulos={articulos}
                detalle={detalle}
                sinStock={detalle.idAcTipo === 3}
              />
            </TabPanel>
            <TabPanel value={tab} index={1}>
              {/* {JSON.stringify(mensajes, null, 2)} */}
              <MensajesTab
                mensajes={mensajes}
                setMensajes={setMensajes}
                aclaracionId={aclaracionId}
              />
            </TabPanel>
            <TabPanel value={tab} index={2}>
              {/* {JSON.stringify(datosEnvio, null, 2)} */}
              <DatosEnvio datosEnvio={datosEnvio} />
            </TabPanel>
            <TabPanel value={tab} index={3}>
              {/* {JSON.stringify(guias, null, 2)} */}
              <GuiasTab guias={guias} />
            </TabPanel>
            <TabPanel value={tab} index={4}>
              {/* {JSON.stringify(reembolso, null, 2)} */}
              <ReembolsoTab data={reembolso} />
            </TabPanel>
            <TabPanel value={tab} index={5}>
              {/* {JSON.stringify(refacturacion, null, 2)} */}
              <RefacturacionTab refacturacion={refacturacion} />
            </TabPanel>
            <TabPanel value={tab} index={6}>
              {/* <pre>{JSON.stringify(evidencias, null, 2)}</pre> */}
              <EvidenciasTab evidencias={evidencias} />
            </TabPanel>
            <TabPanel value={tab} index={7}>
              {/* {JSON.stringify(clavesCuotas, null, 2)} */}
              <ClavesCuotasTab clavesCuotas={clavesCuotas} />
            </TabPanel>
            <TabPanel value={tab} index={8}>
              {JSON.stringify(cancelacionesClaves, null, 2)}
            </TabPanel>
            <TabPanel value={tab} index={9}>
              <MovimientosTab movimientos={movimientos} />
            </TabPanel>
          </Box>
        )}
        {detalle && (
          <CardActions>
            {![13, 12, 11, 10, 9, 8, 7, 6, 5, 3, 2, 1].includes(
              detalle.id_tipificacion_ac
            ) && (
              <LoadingButton
                variant="contained"
                disableElevation
                onClick={handleGenerarRefacturacion}
              >
                Generar refacturacion
              </LoadingButton>
            )}
            {![15, 14, 11, 10, 6, 4, 3].includes(
              detalle.id_tipificacion_ac
            ) && (
              <LoadingButton
                variant="contained"
                disableElevation
                onClick={handleGenerarReembolso}
              >
                Generar reembolso
              </LoadingButton>
            )}
          </CardActions>
        )}
      </Card>
    </motion.div>
  );
};

export default DetalleTabs;
