import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  Slide,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { forwardRef, useEffect, useState } from "react";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CerrarCasoDialog = ({ open, setOpen, details }) => {
  const [solution, setSolution] = useState("");
  const [isSpam, setIsSpam] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const closeDialog = () => {
    setIsSpam(false);
    setSolution("");
    setOpen(false);
  };
  const handleCloseCase = async () => {
    if (!isSpam && solution.length < 50) {
      setError(true);
      return;
    }

    setIsLoading(true);

    try {
      const ticketId = +details.id_crm_main;
      const userId = +details.id_taken;
      const res = await axios.post(
        `${process.env.REACT_APP_BASEURL}/crm/tickets/close`,
        {
          idMain: ticketId,
          idUser: userId,
          solution,
          isSpam,
        },
        {
          headers: {
            "Content-Type": "Application/json",
          },
        }
      );

      if (res.statusText !== "OK" || !res.data.success) {
        throw new Error("handleCloseCase: No se pudo cerrar el caso");
      }

      navigate("..", { replace: true });
    } catch (error) {
      console.error(error.message);
    } finally {
      setIsLoading(false);
      closeDialog();
    }
  };

  useEffect(() => {
    if (solution.length >= 50) {
      setError(false);
    }
  }, [solution]);

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      TransitionComponent={Transition}
      scroll="body"
      fullWidth={true}
      maxWidth="md"
    >
      {isLoading && <LinearProgress />}
      <DialogTitle>Cerrar ticket</DialogTitle>
      <DialogContent>
        <TextField
          error={error}
          autoFocus
          multiline
          fullWidth
          rows={5}
          margin="dense"
          label="Solucion"
          helperText="Minimo 50 caracteres"
          type="text"
          variant="filled"
          value={solution}
          onChange={(e) => setSolution(e.target.value)}
        />
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={isSpam}
                onChange={() => setIsSpam((is) => !is)}
              />
            }
            label="Es spam"
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Cancelar</Button>
        <Button
          variant="contained"
          disableElevation
          onClick={handleCloseCase}
          disabled={isLoading}
        >
          Cerrar ticket
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CerrarCasoDialog;
