import { useState } from "react";
import {
  Box,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  getCompany,
  getProductsDetail,
  searchProductDetail,
} from "../../../../utils/ventaMayoreo/cotizaciones/cotizaciones";
import { useEffect } from "react";
import { useRef } from "react";
import { useContext } from "react";
import { SizeContext } from "../SizeProvider";

function CustomCell({ row }) {
  return (
    <Table size="small" sx={{ margin: "10px", border: "1px solid #dedede" }}>
      <TableBody>
        <TableRow sx={{ backgroundColor: "#0000000a" }}>
          <TableCell align="left">
            <Typography variant="subtitle2">Producto:</Typography>
          </TableCell>
          <TableCell align="right">{row?.nombre}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">
            <Typography variant="subtitle2">SKU:</Typography>
          </TableCell>
          <TableCell align="right">{row?.sku}</TableCell>
        </TableRow>
        <TableRow sx={{ backgroundColor: "#0000000a" }}>
          <TableCell align="left">
            <Typography variant="subtitle2">Cantidad:</Typography>
          </TableCell>
          <TableCell align="right">{row?.cantidad_disponible}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left">
            <Typography variant="subtitle2">Validación:</Typography>
          </TableCell>
          <TableCell align="right">{row?.validacion_status}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

function CustomCellProveedor({ row }) {
  const { maxSize, setMaxSize } = useContext(SizeContext);
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      const width = ref.current.offsetWidth;
      if (width > maxSize) setMaxSize(width);
    }
  }, [ref.current]);

  return (
    <Box
      sx={{
        border: "1px solid #dedede",
        backgroundColor: "#0073b7",
        color: "#fff",
        margin: "5px",
      }}
      ref={ref}
      style={{ width: maxSize || "100%", flex: "1 1 auto" }}
    >
      <Typography
        variant="h6"
        align="center"
        sx={{ fontSize: "0.91rem", marginX: "8px", marginTop: "7px" }}
      >
        {row?.razon_social}
      </Typography>
      <Box sx={{ margin: "15px" }}>
        <Table size="small">
          <TableBody>
            <TableRow sx={{ backgroundColor: "#00c0ef" }}>
              <TableCell align="left" sx={{ border: "none" }}>
                <Typography variant="subtitle2" sx={{ color: "#fff" }}>
                  Precio:
                </Typography>
              </TableCell>
              <TableCell align="right" sx={{ border: "none", color: "#fff" }}>
                {row?.precio_historial
                  ? `$${row?.precio_historial}`
                  : "S/Productos"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left" sx={{ border: "none" }}>
                <Typography variant="subtitle2" sx={{ color: "#fff" }}>
                  Descuento:
                </Typography>
              </TableCell>
              <TableCell align="right" sx={{ border: "none", color: "#fff" }}>
                {row?.descuento_historial
                  ? `${row?.descuento_historial}%`
                  : "S/SKU"}
              </TableCell>
            </TableRow>
            <TableRow sx={{ backgroundColor: "#00c0ef" }}>
              <TableCell align="left" sx={{ border: "none" }}>
                <Typography variant="subtitle2" sx={{ color: "#fff" }}>
                  Devolución:
                </Typography>
              </TableCell>
              <TableCell align="right" sx={{ border: "none", color: "#fff" }}>
                {row?.porcentaje_devolucion_historial
                  ? `${row?.porcentaje_devolucion_historial}%`
                  : "S/Status"}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
}

function CustomCellCliente({ row, productsDetail }) {
  const { maxSize, setMaxSize } = useContext(SizeContext);
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      const width = ref.current.offsetWidth;
      if (width > maxSize) setMaxSize(width);
    }
  }, [ref.current]);

  const { empresa, descuentoValidacion, devolucionValidacion } =
    productsDetail.data;

  return (
    <Box
      sx={{
        border: "1px solid #dedede",
        backgroundColor: "#0073b7",
        color: "#fff",
        margin: "5px",
      }}
      ref={ref}
      style={{ width: maxSize || "100%", flex: "1 1 auto" }}
    >
      <Typography
        variant="h6"
        align="center"
        sx={{ fontSize: "0.91rem", marginX: "8px", marginTop: "7px" }}
      >
        {empresa?.razon_social}
      </Typography>
      <Box sx={{ margin: "15px" }}>
        <Table size="small">
          <TableBody>
            <TableRow sx={{ backgroundColor: "#00c0ef" }}>
              <TableCell align="left" sx={{ border: "none" }}>
                <Typography variant="subtitle2" sx={{ color: "#fff" }}>
                  Precio:
                </Typography>
              </TableCell>
              <TableCell align="right" sx={{ border: "none", color: "#fff" }}>
                {row?.precio ? `$${row?.precio}` : "S/Productos"}
              </TableCell>
            </TableRow>
            {row.descuento >= descuentoValidacion ? (
              <TableRow sx={{ backgroundColor: "#ff851b" }}>
                <TableCell align="left" sx={{ border: "none" }}>
                  <Typography variant="subtitle2" sx={{ color: "#fff" }}>
                    Descuento:
                  </Typography>
                </TableCell>
                <TableCell align="right" sx={{ border: "none", color: "#fff" }}>
                  {row?.descuento ? `${row?.descuento}%` : "S/Descuento"}
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell align="left" sx={{ border: "none" }}>
                  <Typography variant="subtitle2" sx={{ color: "#fff" }}>
                    Descuento:
                  </Typography>
                </TableCell>
                <TableCell align="right" sx={{ border: "none", color: "#fff" }}>
                  {row?.descuento ? `${row?.descuento}%` : "S/Descuento"}
                </TableCell>
              </TableRow>
            )}
            {row.porcentaje_devolucion >= devolucionValidacion ? (
              <TableRow sx={{ backgroundColor: "#ff851b" }}>
                <TableCell align="left" sx={{ border: "none" }}>
                  <Typography variant="subtitle2" sx={{ color: "#fff" }}>
                    Devolución:
                  </Typography>
                </TableCell>
                <TableCell align="right" sx={{ border: "none", color: "#fff" }}>
                  {row?.porcentaje_devolucion
                    ? `${row?.porcentaje_devolucion}%`
                    : "S/Status"}
                </TableCell>
              </TableRow>
            ) : (
              <TableRow sx={{ backgroundColor: "#00c0ef" }}>
                <TableCell align="left" sx={{ border: "none" }}>
                  <Typography variant="subtitle2" sx={{ color: "#fff" }}>
                    Devolución:
                  </Typography>
                </TableCell>
                <TableCell align="right" sx={{ border: "none", color: "#fff" }}>
                  {row?.porcentaje_devolucion
                    ? `${row?.porcentaje_devolucion}%`
                    : "S/Status"}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
}

export const ProductsTab = ({ cotizacionId, productsDetail }) => {
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [keyWord, setKeytoSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      field: "producto",
      headerName: "Producto",
      flex: 0.8,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => <CustomCell row={params.row} />,
    },
    {
      field: "proveedor",
      headerName: "Proveedor",
      flex: 0.8,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => <CustomCellProveedor row={params.row} />,
    },
    {
      field: "cliente",
      headerName: "Cliente",
      flex: 0.8,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <CustomCellCliente row={params.row} productsDetail={productsDetail} />
      ),
    },
  ];

  if (
    document.querySelector(
      ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
    )
  )
    document
      .querySelector(
        ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
      )
      .remove();

  useEffect(() => {
    let active = true;

    (async () => {
      let newRows = null;

      if (
        typeof keyWord === "undefined" ||
        keyWord === undefined ||
        keyWord.length === 0
      ) {
        setLoading(true);
        newRows = await getProductsDetail(page, cotizacionId);
      } else if (keyWord.length >= 3) {
        setLoading(true);
        newRows = await searchProductDetail(page, cotizacionId, keyWord);
      } else active = false;

      if (!active) {
        return;
      }
      setTotal(newRows.total);
      setRows(newRows.data);
      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [page, keyWord]);

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Grid container sx={{ mb: 2, mt: 2 }}>
          <Grid item md={4} sx={{ px: 2 }}>
            <Stack direction="row" spacing={1}>
              <TextField
                size="small"
                variant="outlined"
                fullWidth
                label={`Buscar`}
                value={keyWord}
                onChange={(e) => setKeytoSearch(e.target.value)}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box style={{ height: 1050, width: "100%" }}>
        <DataGridPro
          rows={rows}
          columns={columns}
          pagination
          pageSize={10}
          rowsPerPageOptions={[10]}
          getRowHeight={() => "auto"}
          getRowId={(row) => row.id_requerimiento_producto}
          rowCount={total}
          paginationMode="server"
          onPageChange={(newPage) => {
            setPage(newPage);
          }}
          loading={loading}
          disableColumnMenu
          disableSelectionOnClick
          getDetailPanelHeight={() => "auto"}
        />
      </Box>
    </>
  );
};
