import axios from "axios";

/**
 * GET
**/

const getRequest = async ( currentPage , idUser ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/pagos/request/request/all/new?page=${currentPage}&idUser=${idUser}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const searchRequest = async ( currentPage , idUser, search ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/pagos/request/request/all/search?page=${currentPage}&idUser=${idUser}&search=${search}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const searchPagos = async ( currentPage, search, idUser ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/pagos/request/request/all/search?page=${currentPage}&search=${search}&idUser=${idUser}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

/**
 * POST
**/

const sendMassiveMessages = async (  idUser, type, pagos ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/pagos/request/request/approve/save`;
  try {
    const res = await axios.post(URL, { idUser, type, pagos });
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("sendMassiveMessages", error);
  }
};

const sendSingleMessage = async (  idUser, type, pagos ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/pagos/request/request/approve/save`;
  try {
    const res = await axios.post(URL, { idUser, type, pagos });
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("sendSingleMessage", error);
  }
};


/**
 * EXCEL EXPORT
**/

const getExcelRequest = async (idUser) => {
  const URL = `${process.env.REACT_APP_BASEURL}/pagos/request/request/all/new?page=All&idUser=${idUser}`;
  try {
    let res = await axios(URL);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach(element => {
      let aux2 = {};
      let status = "";
      let estatusDireccionG = "";
      let estatusDireccionF = "";
      let estatusDireccionDDRC = "";
      if (element.status === 0) {
        status = "";
      } else if (element.status === 1) {
        status = "En espera de aprobacion";
      }else if (element.status === 2) {
        status = "Aprobado por dirección";
      }else if (element.status === 3) {
        status = "Aprobado";
      }else if (element.status === 4) {
        status = "No aprobado";
      }
      if (element.estatusDireccionG === 0) {
        estatusDireccionG = "";
      } else if (element.estatusDireccionG === 1) {
        estatusDireccionG = "En espera de aprobacion";
      }else if (element.estatusDireccionG === 2) {
        estatusDireccionG = "Aprobado por dirección";
      }else if (element.estatusDireccionG === 3) {
        estatusDireccionG = "Aprobado";
      }else if (element.estatusDireccionG === 4) {
        estatusDireccionG = "No aprobado";
      }
      if (element.estatusDireccionF === 0) {
        estatusDireccionF = "";
      } else if (element.estatusDireccionF === 1) {
        estatusDireccionF = "En espera de aprobacion";
      }else if (element.estatusDireccionF === 2) {
        estatusDireccionF = "Aprobado por dirección";
      }else if (element.estatusDireccionF === 3) {
        estatusDireccionF = "Aprobado";
      }else if (element.estatusDireccionF === 4) {
        estatusDireccionF = "No aprobado";
      }
      if (element.estatusDireccionDDRC === 0) {
        estatusDireccionDDRC = "";
      } else if (element.estatusDireccionDDRC === 1) {
        estatusDireccionDDRC = "En espera de aprobacion";
      }else if (element.estatusDireccionDDRC === 2) {
        estatusDireccionDDRC = "Aprobado por dirección";
      }else if (element.estatusDireccionDDRC === 3) {
        estatusDireccionDDRC = "Aprobado";
      }else if (element.estatusDireccionDDRC === 4) {
        estatusDireccionDDRC = "No aprobado";
      }
      aux2 = {
          "DocEntry": element.DocEntry,
          "Codigo": element.Codigo,
          "Nombre": element.Nombre,
          "Numero": element.Numero,
          "Referencia": element.Referencia,
          "SubTotal": element.SubTotal,
          "MontoPagar": element.MontoPagar,
          "ImporteAplicado": element.ImporteAplicado,
          "EstadoBanco": element.EstadoBanco,
          "CuentaBancaria": element.CuentaBancaria,
          "ClabeBancaria": element.ClabeBancaria,
          "Observaciones": element.Observaciones,
          "status": status,
          "estatusDireccionG": estatusDireccionG,
          "estatusDireccionF": estatusDireccionF,
          "estatusDireccionDDRC": estatusDireccionDDRC,
        };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

export {
  getRequest, searchRequest, getExcelRequest, sendMassiveMessages, sendSingleMessage, searchPagos
};