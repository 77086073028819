import { Box, Card, Tab, Tabs, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import { profiles } from "../../../utils/vales/profilesVales";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import BreadCrumb from "../../general/BreadCrumb";
import { CalendarMonth, Person } from "@mui/icons-material";
import TemporadasDataTable from "./TemporadasDataTable";
import UserRHDataTable from "./UserRHDataTable";

const itemsBreadCrumbs = [
  {
    label: "Vales",
    icon: <CreditCardIcon sx={{ mr: 0.5 }} fontSize="small" />,
    link: "/vales",
  },
  { label: "Configuracion" },
];

const Configuracion = () => {
  const [nextRender, setNextRender] = useState(false);
  const [crmTab, setCrmTab] = useState(0);
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleTabChange = (event, newValue) => {
    setCrmTab(newValue);
  };

  useEffect(() => {
    if (!profiles.configuracion.includes(user.role.idProfile)) {
      navigate("/dashboard", { replace: true });
    } else setNextRender(true);
  }, []);

  return (
    <>
      {nextRender && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h4" sx={{ my: 2 }}>
              Configuracion
            </Typography>
            <Box>
              <BreadCrumb items={itemsBreadCrumbs} />
            </Box>
          </Box>

          <motion.div
            initial={{ y: 20 }}
            animate={{ y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Card sx={{ mb: 4 }}>
              <Box>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs value={crmTab} onChange={handleTabChange}>
                    <Tab
                      icon={<CalendarMonth />}
                      iconPosition="start"
                      label="Temporadas"
                    />
                    <Tab
                      icon={<Person />}
                      iconPosition="start"
                      label="RH de la RSA"
                    />
                  </Tabs>
                </Box>
                {crmTab === 0 ? <TemporadasDataTable /> : <UserRHDataTable />}
              </Box>
            </Card>
          </motion.div>
        </>
      )}
    </>
  );
};

export default Configuracion;
