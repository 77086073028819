import axios from "axios";

const getArticulos = async (idAcl) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/clarifications/clarifications/details/tab/items?idAcl=${idAcl}`;

  try {
    const articulos = await axios(URL);

    return articulos.data?.success ? articulos.data.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getAllItems = async (increment_id) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/clarifications/clarifications/details/tab/allitems?increment_id=${increment_id}`;

  try {
    const items = await axios(URL);

    return items.data?.success ? items.data.data : [];
  } catch (error) {
    console.error(error);
  }
};

export { getArticulos, getAllItems };
