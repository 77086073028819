import axios from "axios";

const createBackOffice = async ({ tipificacion, pedido, awsid, aclaracion, email, guia, solution, channel, college }) => {
  try {
    const formData = new FormData();

    formData.append(
      "id_tipificacion_ac", 
      aclaracion
    );
    formData.append("increment_id", pedido);
    formData.append("user_id", awsid);
    formData.append("id_tema", tipificacion);
    formData.append("idUrl", "0");
    formData.append("canal", channel);
    formData.append("is_ticket", "0");
    formData.append("contact", email);
    formData.append("id_guia", guia);
    formData.append("solution", solution);
    formData.append("id_school", college);

    const dd = {
      id_tipificacion_ac: aclaracion,
      increment_id: pedido,
      user_id: awsid,
      id_tema: tipificacion,
      idUrl: "0",
      canal: channel,
      is_ticket: "0",
      contact: email,
      id_guia: guia,
      solution: solution,
      id_school: college,
    };

    console.log(dd);

    await axios({
      url: `${process.env.REACT_APP_BASEURL}/crm/cases/save`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });
  } catch (error) {
    console.error(error);
  }
};

export { createBackOffice };
