import { Box, Grid, TextField, InputLabel, Select, FormControl, MenuItem, CircularProgress, Typography, Alert, Card, CardContent, Stack, ListSubheader, List, ListItem, ListItemText } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../context/AuthContext";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { getAddresses, saveShippingData, getShippingData, getGuide, updateShippingData, assignGuide } from "../../../../utils/trazabilidad/rma/rma";

const CambiosTab = ({ lista, rmaId, orderId, incrementId, clientEmail, clientName, status, rmaDl }) => {
    const auth = useAuth();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isEditSD, setEditSD] = useState(false);
    const [articulos, setArticulos] = useState(null);
    const [detalle, setDetalle] = useState(null);
    const [addresses, setAddresses] = useState([]);
    const [archivo, setArchivo] = useState(null);
    const [address, setAddress] = useState({
        nombre: rmaDl.customer_name + " " +rmaDl.customer_lastname,
        pais: 'México',
        estado: rmaDl.shipping_state,
        dele: rmaDl.shipping_delegation,
        calle: rmaDl.shipping_street,
        colonia: rmaDl.shipping_suburb,
        ciudad: rmaDl.shipping_city,
        entre: rmaDl.between_streets,
        fachada: '',
        cp: rmaDl.shipping_postal_code,
        telefono: '',
        ne: rmaDl.shipping_outside_number,
        ni: rmaDl.shipping_inside_number,
        groupedselect: ''
    });

    const handleEditSD = async () => {
        setIsLoading(true);
        const resp = await getAddresses(clientEmail);
        setAddresses(resp);
        setAddress((prevalue) => {
            return {
                ...prevalue,
                ["nombre"]: detalle.dir_nombre,
                ["pais"]: detalle.dir_pais,
                ["estado"]: detalle.dir_estado,
                ["ciudad"]: detalle.dir_ciudad,
                ["calle"]: detalle.dir_calle,
                ["dele"]: detalle.dir_municipio,
                ["colonia"]: detalle.dir_colonia,
                ["entre"]: detalle.dir_entre_calles,
                ["fachada"]: detalle.dir_fachada,
                ["cp"]: detalle.dir_cp,
                ["telefono"]: detalle.dir_telefono,
                ["ni"]: detalle.dir_numero_int,
                ["ne"]: detalle.dir_numero_ext
            }
        })
        setEditSD(true);
        setIsLoading(false);
    };

    const handleChangeAddress = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        if(name == "groupedselect")
        {
            if(value != undefined && value != 0 && value != 1)
            {
                let local = getSelectedAddress(addresses, value);
                local = local[0];
                setAddress((prevalue) => {
                    return {
                        ...prevalue,
                        ["calle"]: local.Calle,
                        ["dele"]: local.Municipio,
                        ["colonia"]: local.Colonia,
                        ["ni"]: local["Número interior"],
                        ["ne"]: local["Número exterior"],
                        ["groupedselect"]: value
                    }
                })
            }
            else if(value == 1)
            {
                setAddress((prevalue) => {
                    return {
                        ...prevalue,
                        ["pais"]: 'México',
                        ["estado"]: rmaDl.shipping_state,
                        ["ciudad"]: rmaDl.shipping_city,
                        ["calle"]: rmaDl.shipping_street,
                        ["dele"]: rmaDl.shipping_delegation,
                        ["colonia"]: rmaDl.shipping_suburb,
                        ["entre"]: rmaDl.between_streets,
                        ["fachada"]: '',
                        ["cp"]: rmaDl.shipping_postal_code,
                        ["telefono"]: '',
                        ["ni"]: rmaDl.shipping_inside_number,
                        ["ne"]: rmaDl.shipping_outside_number,
                        ["groupedselect"]: value
                    }
                })
            }
            else if(value == 0)
            {
                setAddress((prevalue) => {
                    return {
                        ...prevalue,
                        ["pais"]: '',
                        ["estado"]: '',
                        ["ciudad"]: '',
                        ["calle"]: '',
                        ["dele"]: '',
                        ["colonia"]: '',
                        ["entre"]: '',
                        ["fachada"]: '',
                        ["cp"]: '',
                        ["telefono"]: '',
                        ["ni"]: '',
                        ["ne"]: '',
                        ["groupedselect"]: value
                    }
                })
            }
        }else
        {
            setAddress((prevalue) => {
                return {
                    ...prevalue,
                    [name]: value
                }
            })
        }            
    };

    const handleSaveSD = async () => {
        try {
          setIsLoading(true);
          await saveShippingData(rmaId, auth.user.data.awsid, orderId, incrementId, address.nombre, address.pais, address.cp, address.estado, address.dele, address.calle, address.ne, address.ni, address.colonia, address.ciudad, address.entre, address.fachada, address.telefono);
          setTimeout(function(){
            navigate(`/dashboard/trazabilidad/rma/detail/${rmaId}`);
          }, 3000);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
    };

    const handleUpdateSD = async () => {
        try {
          setIsLoading(true);
          await updateShippingData(rmaId, auth.user.data.awsid, orderId, incrementId, address.nombre, address.pais, address.cp, address.estado, address.dele, address.calle, address.ne, address.ni, address.colonia, address.ciudad, address.entre, address.fachada, address.telefono);
          setTimeout(function(){
            navigate(`/dashboard/trazabilidad/rma/detail/${rmaId}`);
          }, 3000);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
    };

    const handleSaveGuide = async () => {
        try {
          setIsLoading(true);
          var aux_mail = clientEmail;
          await assignGuide(rmaId, auth.user.data.awsid, orderId, incrementId, detalle.dir_nombre, detalle.dir_pais, detalle.dir_cp, detalle.dir_estado, detalle.dir_municipio, detalle.dir_calle, detalle.dir_numero_ext, detalle.dir_numero_int, detalle.dir_colonia, detalle.dir_ciudad, detalle.dir_entre_calles, detalle.dir_fachada, detalle.dir_telefono, detalle.dir_telefono, 'Nueva guía', rmaDl.customer_name, rmaDl.customer_lastname, rmaDl.customer_mothersname, aux_mail);
          setTimeout(function(){
            navigate(`/dashboard/trazabilidad/rma/detail/${rmaId}`);
          }, 3000);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
    };

    function getSCitems(items){
        return items.filter(element => element.if_cambio_log > 0);
    }

    function getSelectedAddress(items, val){
        return items.filter(element => element.id == val);
    }

    function isempt(){
        if(address.nombre && address.pais && address.cp && address.estado && address.dele && address.calle && address.ne && address.colonia && address.ciudad && address.entre && address.fachada && address.telefono)
            return false;
        else 
            return true;
    }

    function getCantidadR(params) {
        if(params.row.cantidad_devuelta_log)
            return params.row.cantidad_devuelta_log;
        else
            return "0";
    }

    const columns = [
        { field: "product_sku", headerName: "SKU", flex: 1 },
        { field: "product_name", headerName: "Nombre", flex: 3 },
        { field: "attributes", headerName: "Categoría", flex: 1 },
        { field: "category_name", headerName: "Grupo de Artículos", flex: 1 },
        { field: "price", headerName: "Precio", flex: 1 },
        { field: "cantidad_devuelta_log", headerName: "Cantidad Recibida", flex: 1, valueGetter: getCantidadR},
        { field: "total", headerName: "Total", flex: 1 }
    ];

    useEffect(() => {
        const getArticulos = async () => {
            try {
                setIsLoading(true);
                const res = getSCitems(lista);
                setArticulos(res);
                if(status.id_status_cambio === 3){
                    const resp = await getAddresses(clientEmail);
                    setAddresses(resp);
                }else if(status.id_status_cambio === 12){
                    const resp = await getShippingData(orderId, incrementId);
                    setDetalle(resp);
                }else if(status.id_status_cambio === 6){
                    const resp = await getShippingData(orderId, incrementId);
                    setDetalle(resp);
                    const respo = await getGuide(rmaId);
                    setArchivo(respo);
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };
    
        getArticulos();
    }, []);

    return (
        <>        
            <Box sx={{mb: 2, ml: 2, mr: 2}}>
                {isLoading && <CircularProgress />}
                {articulos && (<div style={{ display: "flex", height: "100%" }}>
                    <div style={{ flexGrow: 1 }}>
                        <DataGrid
                            autoHeight
                            rows={articulos}
                            columns={columns}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                            getRowId={(row) => row.eze3_item_id}
                            disableColumnFilter
                            disableColumnMenu
                            disableColumnSelector
                            disableDensitySelector
                            disableExtendRowFullWidth
                            disableSelectionOnClick

                            initialState={{
                                sorting: {
                                sortModel: [{ field: 'product_sku', sort: 'asc' }],
                                },
                            }}
                        />
                    </div>
                </div>
                )}

                {(status.id_status_cambio === 3) && (
                <Grid container sx={{mt:2, mb:2}}>
                    <Grid item md={12}>
                        <Alert variant="filled" severity="info"><Typography variant="p"><strong>Datos del cliente</strong></Typography></Alert>
                        <Card>
                            <CardContent>
                                <div>
                                    <FormControl fullWidth sx={{mb: 2}}>
                                        <InputLabel id="selecte" htmlFor="groupedselect">Seleccionar Dirección</InputLabel>
                                        <Select id="groupedselect" name="groupedselect" label="Seleccionar Dirección" value={address.groupedselect} onChange={handleChangeAddress}>
                                            <MenuItem value=""><em>Seleccionar dirección</em></MenuItem>
                                            <MenuItem value={0}>Agregar nueva dirección</MenuItem>
                                            <ListSubheader>Dirección de pedido</ListSubheader>
                                            <MenuItem value={1}>{rmaDl.shipping_city} - {rmaDl.shipping_street}</MenuItem>
                                            <ListSubheader>Direcciones adicionales</ListSubheader>
                                            {addresses && addresses.map((row) => (
                                                <MenuItem key={row.id} value={row.id}>{row.Calle}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <Stack sx={{m: 2}} direction="row" spacing={3} autoComplete="off">
                                    <TextField fullWidth name="nombre" label="Nombre" variant="outlined" inputProps={{maxlength: 30}} value={address.nombre} onChange={handleChangeAddress} />
                                    <TextField fullWidth name="pais" label="País" variant="outlined" value={address.pais} onChange={handleChangeAddress} />
                                    <TextField fullWidth name="estado" label="Estado" variant="outlined" value={address.estado} onChange={handleChangeAddress} />
                                </Stack>
                                <Stack sx={{m: 2}} direction="row" spacing={3} autoComplete="off">
                                    <TextField fullWidth name="dele" label="Delegación/Municipio" variant="outlined" value={address.dele} onChange={handleChangeAddress} />
                                    <TextField fullWidth name="calle" label="Calle" variant="outlined" value={address.calle} onChange={handleChangeAddress} />
                                    <TextField fullWidth name="colonia" label="Colonia" variant="outlined" value={address.colonia} onChange={handleChangeAddress} />
                                </Stack>
                                <Stack sx={{m: 2}} direction="row" spacing={3} autoComplete="off">
                                    <TextField fullWidth name="ciudad" label="Ciudad" variant="outlined" value={address.ciudad} onChange={handleChangeAddress} />
                                    <TextField fullWidth name="entre" label="Entre calles" variant="outlined" value={address.entre} onChange={handleChangeAddress} />
                                    <TextField fullWidth name="fachada" label="Fachada" variant="outlined" value={address.fachada} onChange={handleChangeAddress} />
                                </Stack>
                                <Stack sx={{m: 2}} direction="row" spacing={4} autoComplete="off">                    
                                    <TextField fullWidth name="cp" label="Código Postal" variant="outlined" value={address.cp} onChange={handleChangeAddress} />
                                    <TextField fullWidth name="telefono" label="Teléfono" variant="outlined" value={address.telefono} onChange={handleChangeAddress} />
                                    <TextField fullWidth name="ne" label="Número exterior" variant="outlined" value={address.ne} onChange={handleChangeAddress} />
                                    <TextField fullWidth name="ni" label="Número interior" variant="outlined" value={address.ni} onChange={handleChangeAddress} />
                                </Stack>
                                <div style={{ textAlign: 'right', marginTop: '2em' }}>
                                    <LoadingButton loading={isLoading} size="medium" variant="contained" color="success" onClick={handleSaveSD} disabled={(isempt())}>Guardar datos de envío</LoadingButton>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                )}

                {(status.id_status_cambio === 12 && detalle) && (
                <Grid container sx={{mt:2, mb:2}}>
                    <Grid item md={12}>
                        <Alert variant="filled" severity="info"><Typography variant="p"><strong>Datos del cliente</strong></Typography></Alert>
                        {isEditSD && (
                        <Card>
                            <CardContent>
                                <div>
                                    <FormControl fullWidth sx={{mb: 2}}>
                                        <InputLabel id="selecte" htmlFor="groupedselect">Seleccionar Dirección</InputLabel>
                                        <Select id="groupedselect" name="groupedselect" label="Seleccionar Dirección" value={address.groupedselect} onChange={handleChangeAddress}>
                                            <MenuItem value=""><em>Seleccionar dirección</em></MenuItem>
                                            <MenuItem value={0}>Agregar nueva dirección</MenuItem>
                                            <ListSubheader>Dirección de pedido</ListSubheader>
                                            <MenuItem value={1}>{rmaDl.shipping_city} - {rmaDl.shipping_street}</MenuItem>
                                            <ListSubheader>Direcciones adicionales</ListSubheader>
                                            {addresses && addresses.map((row) => (
                                                <MenuItem key={row.id} value={row.id}>{row.Calle}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <Stack sx={{m: 2}} direction="row" spacing={3} autoComplete="off">
                                    <TextField fullWidth name="nombre" label="Nombre" variant="outlined" inputProps={{maxlength: 30}} value={address.nombre} onChange={handleChangeAddress} />
                                    <TextField fullWidth name="pais" label="País" variant="outlined" value={address.pais} onChange={handleChangeAddress} />
                                    <TextField fullWidth name="estado" label="Estado" variant="outlined" value={address.estado} onChange={handleChangeAddress} />
                                </Stack>
                                <Stack sx={{m: 2}} direction="row" spacing={3} autoComplete="off">
                                    <TextField fullWidth name="dele" label="Delegación/Municipio" variant="outlined" value={address.dele} onChange={handleChangeAddress} />
                                    <TextField fullWidth name="calle" label="Calle" variant="outlined" value={address.calle} onChange={handleChangeAddress} />
                                    <TextField fullWidth name="colonia" label="Colonia" variant="outlined" value={address.colonia} onChange={handleChangeAddress} />
                                </Stack>
                                <Stack sx={{m: 2}} direction="row" spacing={3} autoComplete="off">
                                    <TextField fullWidth name="ciudad" label="Ciudad" variant="outlined" value={address.ciudad} onChange={handleChangeAddress} />
                                    <TextField fullWidth name="entre" label="Entre calles" variant="outlined" value={address.entre} onChange={handleChangeAddress} />
                                    <TextField fullWidth name="fachada" label="Fachada" variant="outlined" value={address.fachada} onChange={handleChangeAddress} />
                                </Stack>
                                <Stack sx={{m: 2}} direction="row" spacing={4} autoComplete="off">                    
                                    <TextField fullWidth name="cp" label="Código Postal" variant="outlined" value={address.cp} onChange={handleChangeAddress} />
                                    <TextField fullWidth name="telefono" label="Teléfono" variant="outlined" value={address.telefono} onChange={handleChangeAddress} />
                                    <TextField fullWidth name="ne" label="Número exterior" variant="outlined" value={address.ne} onChange={handleChangeAddress} />
                                    <TextField fullWidth name="ni" label="Número interior" variant="outlined" value={address.ni} onChange={handleChangeAddress} />
                                </Stack>
                                <div style={{ textAlign: 'right', marginTop: '2em' }}>
                                    <LoadingButton loading={isLoading} size="medium" variant="contained" color="success" onClick={handleUpdateSD} disabled={(isempt())}>Guardar datos de envío</LoadingButton>
                                </div>
                            </CardContent>
                        </Card>
                        )}
                    </Grid>                    
                    {!isEditSD && (<>
                    <Grid item md={6}>
                        <List>
                            <ListItem>
                                <ListItemText primary="Nombre" secondary={detalle.dir_nombre}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Estado" secondary={detalle.dir_estado}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Calle" secondary={detalle.dir_calle}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Ciudad" secondary={detalle.dir_ciudad}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Código Postal" secondary={detalle.dir_cp}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Teléfono" secondary={detalle.dir_telefono}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Número Exterior" secondary={detalle.dir_numero_ext} />
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item md={6}>
                        <List>
                            <ListItem>
                                <ListItemText primary="País" secondary={detalle.dir_pais}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Deleg. / Munic" secondary={detalle.dir_municipio}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Colonia" secondary={detalle.dir_colonia}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Entre calles" secondary={detalle.dir_entre_calles}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Fachada" secondary={detalle.dir_fachada}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Número Interior" secondary={detalle.dir_numero_int}/>
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item md={3}></Grid>
                    <Grid item md={6}>
                        <Alert variant="filled" severity="info"><Typography variant="p"><strong>Guías</strong></Typography></Alert>
                        <Card>
                            <CardContent>
                                <Grid container sx={{alignItems: 'end'}}>
                                    <Grid item md={6}>
                                        <Typography variant="p" component="div"><strong>Carrier: </strong>Estafeta</Typography>
                                        <Typography variant="p" component="div" sx={{mb:2, mt:2}}><strong>Servicio: </strong>Standard</Typography>
                                        <LoadingButton loading={isLoading} variant="contained" color="success" onClick={handleSaveGuide} startIcon={<AssignmentIcon />}>Asignar guía</LoadingButton>
                                    </Grid>
                                    <Grid item md={6}>
                                        <Box sx={{ textAlign: 'right' }}>
                                            <LoadingButton loading={isLoading} variant="contained" color="error" onClick={handleEditSD} startIcon={<EditLocationAltIcon />}>Modificar datos de envío</LoadingButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={3}></Grid>
                    </>)}
                </Grid>
                )}

                {(status.id_status_cambio === 6 && detalle) && (
                <Grid container sx={{mt:2, mb:2}}>
                    <Grid item md={12}><Alert variant="filled" severity="info"><Typography variant="p"><strong>Datos del cliente</strong></Typography></Alert></Grid>                    
                    <Grid item md={6}>

                        <List>
                            <ListItem>
                                <ListItemText primary="Nombre" secondary={detalle.dir_nombre}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Estado" secondary={detalle.dir_estado}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Calle" secondary={detalle.dir_calle}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Ciudad" secondary={detalle.dir_ciudad}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Código Postal" secondary={detalle.dir_cp}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Teléfono" secondary={detalle.dir_telefono}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Número Exterior" secondary={detalle.dir_numero_ext} />
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item md={6}>
                        <List>
                            <ListItem>
                                <ListItemText primary="País" secondary={detalle.dir_pais}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Deleg. / Munic" secondary={detalle.dir_municipio}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Colonia" secondary={detalle.dir_colonia}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Entre calles" secondary={detalle.dir_entre_calles}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Fachada" secondary={detalle.dir_fachada}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Número Interior" secondary={detalle.dir_numero_int}/>
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item md={3}></Grid>
                    <Grid item md={6}>
                        <Alert variant="filled" severity="info"><Typography variant="p"><strong>Guías</strong></Typography></Alert>
                        <Card variant="outlined">
                            <CardContent>
                                <Grid container sx={{alignItems: 'center'}}>
                                    <Grid item md={8}>
                                        <Typography variant="p" component="div"><strong>Número de guía</strong></Typography>
                                        {archivo && <Typography variant="p" component="div">{archivo.guia}</Typography>}
                                    </Grid>
                                    <Grid item md={4}>
                                        <Box sx={{ textAlign: 'right' }}>
                                            <LoadingButton loading={isLoading} variant="contained" color="primary" startIcon={<FileDownloadIcon />}>
                                                {archivo && <a href={"data:application/pdf;base64,"+archivo.base64} download style={{ textDecoration: 'none', color: '#fff'}}>Descargar guía</a>}
                                            </LoadingButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={3}></Grid>
                </Grid>
                )}
            </Box>
        </>
    );
}

export default CambiosTab;