import { LoadingButton } from "@mui/lab";
import { Chip, TableBody, TableCell, TableRow } from "@mui/material";
import DateInTable from "../general/DateInTable";
import VisibilityIcon from "@mui/icons-material/Visibility";

const TicketsTableBody = ({ tickets, validateTicket, chipColors, loading }) => {
  return (
    <TableBody>
      {tickets.map((row) => (
        <TableRow key={row.id_crm_main}>
          <TableCell>
            <DateInTable dateString={row.date_register} />
          </TableCell>
          <TableCell>
            <Chip
              label={row.type}
              size="small"
              color={row.type === "Online" ? "success" : "error"}
              variant="outlined"
            />
          </TableCell>
          <TableCell>{row.title}</TableCell>
          <TableCell>{row.channel}</TableCell>
          <TableCell>{row.contact}</TableCell>
          <TableCell>{row.responsable}</TableCell>
          <TableCell>
            <Chip
              label={row.status}
              size="small"
              sx={{
                backgroundColor: chipColors[row.status],
                color: "#fff",
              }}
            />
          </TableCell>
          <TableCell>
            <LoadingButton
              variant="contained"
              size="small"
              disableElevation
              startIcon={<VisibilityIcon />}
              onClick={() => validateTicket(row.id_crm_main)}
              loading={loading[row.id_crm_main]}
            >
              Detalle
            </LoadingButton>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

export default TicketsTableBody;
