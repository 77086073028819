import { Box, Button, Card, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, InputLabel, Select, FormControl, MenuItem } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ViewListIcon from '@mui/icons-material/ViewList';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import PublishIcon from '@mui/icons-material/Publish';
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../../../context/AuthContext";
import CargasTable from "./CargasTable";
import { uploadFiles } from "../../../../utils/trazabilidad/excepciones/excepciones";
import BreadCrumb from "../../../general/BreadCrumb";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";

const itemsBreadCrumbs = [
  {
    label: 'Trazabilidad',
    icon: <ContentPasteSearchIcon sx={{ mr: 0.5 }} fontSize="small"/>,
    link: '/trazabilidad/inicio'
  },
  { label: 'Excepciones', link: '/trazabilidad/excepciones' },
  { label: 'Cargas', }
];

const Cargas = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [carrier, setCarrier] = useState(1);

  const checkP = (permit) => {
    return (permit == 0);
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
		setIsFilePicked(true);
	};

  const handleChangeCarrier = (event) => {
    setCarrier(event.target.value);
  };

  const handleSaveSC = async () => {
    try {
      setIsLoading(true);
      await uploadFiles(user.data.awsid, carrier, selectedFile);
      refreshPage();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const refreshPage = () => {
    const current = location.pathname;
    navigate(`/reload`, { replace: true });
    setTimeout(() => {
      navigate(current, { replace: true });
    });
  };
  
  useEffect(() => {
    if((user.role.permission.find(checkP))){    
      navigate("/dashboard", { replace: true });
    }
  }, [navigate]);

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", }} >
        <Typography variant="h4" sx={{ my: 2 }}>Cargas</Typography>
        <BreadCrumb items={itemsBreadCrumbs} />
      </Box>
      <Box sx={{ display: "flex", justifyContent: 'end', mb: 2 }}>
        <Button sx={{mr: 2}} variant="contained" color="success" startIcon={<FileUploadIcon />} onClick={handleClickOpen}>Cargar archivo CSV</Button>
        <Button sx={{mr: 2}} variant="contained" color="info" startIcon={<FileDownloadIcon />}>
            <a download href="https://firebasestorage.googleapis.com/v0/b/klaar-gea.appspot.com/o/public%2Fformato-carga_excepciones.csv?alt=media&token=917a57ed-f3c5-49a9-9f38-8bcf12866d50" style={{ textDecoration: 'none', color: '#fff' }}>Descargar formato</a>
        </Button>
        <Button variant="contained" color="info" startIcon={<ViewListIcon />} onClick={() => navigate(`/trazabilidad/excepciones/tipificaciones`, { replace: true })}>Tipificaciones</Button>
      </Box>

      <motion.div initial={{ y: 20 }} animate={{ y: 0 }} transition={{ duration: 0.5 }}>        
        <Card sx={{mb:4}}>          
          <Box>            
            <CargasTable />
          </Box>
        </Card>
      </motion.div>

      <Dialog open={open} fullWidth={true} maxWidth="sm" onClose={handleClose}>
        <DialogTitle>Selecciona al carrier y el archivo a cargar</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{mt: 2, mb: 2}}>
            <InputLabel id="selecte" htmlFor="groupedselect">Carrier</InputLabel>
            <Select id="groupedselect" name="groupedselect" label="Carrier" value={carrier} onChange={handleChangeCarrier}>
              <MenuItem value={1}>Estafeta</MenuItem>
              <MenuItem value={2}>Fedex</MenuItem>
              <MenuItem value={3}>DHL</MenuItem>
            </Select>
          </FormControl>
          <Typography align="center" sx={{mb:2}}>{isFilePicked ? (selectedFile.name.length <= 32 ? selectedFile.name: 'El nombre del archivo no debe exceder 32 caracteres') : 'Ningún archivo seleccionado'}</Typography>
          <Button align="center" variant="contained" component="label" sx={{mb:4}} startIcon={<FileUploadIcon />}>
            {isFilePicked ? 'Elegir otro archivo' : 'Elegir archivo'}
            <input hidden accept=".csv" type="file" onChange={changeHandler} />
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <LoadingButton loading={isLoading} variant="contained" color="info" startIcon={<PublishIcon />} disabled={(!isFilePicked || !carrier || (selectedFile.name.length > 32))} onClick={handleSaveSC}>Cargar</LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Cargas;