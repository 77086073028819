import { Button } from "@mui/material";
import { PDFDownloadLink } from "@react-pdf/renderer";
import axios from "axios";
import { useEffect, useState } from "react";
import PedidoDetailsDocument from "./pdf/PedidoDetailsDocument";
import PrintIcon from '@mui/icons-material/Print';

const ImprimirDetalle = ({ details, domicilio }) => {
    const [items, setItems] = useState(null);

    useEffect(() => {
        const getItems = async () => {
            try {
                const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/orders/orders/all/orderdetails?id=${details.increment_id}&page=0`;
                const result = await axios.get(URL);
                if (result?.data?.success) {
                    setItems(result.data.data);
                }
            } catch (error) {
                console.log(error);
            }
        };

        getItems();
    }, [details.increment_id]);

    if (!items) return null;

    return (
        <PDFDownloadLink
            document={
                <PedidoDetailsDocument
                    details={details}
                    domicilio={domicilio}
                    items={items}
                />
            }
            fileName={`Pedido-${details.increment_id}`}
            style={{ textDecoration: "none" }}
        >
            <Button startIcon={<PrintIcon/>} size="small" variant="contained" disableElevation sx={{ mr: 1 }}>
                Imprimir
            </Button>
        </PDFDownloadLink>
    );
};

export default ImprimirDetalle;