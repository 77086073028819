import {
  Box,
  Grid,
  Button,
  Stack,
  Typography,
  TextField,
  Chip,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DateInTable from "../../general/DateInTable";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  getVerifications,
  searchVerifications,
} from "../../../utils/trazabilidad/verificaciones/verificaciones";

const chipColors = {
  1: "#c8e6c9",
  2: "#f5f5f5",
  3: "#ffcdd2",
};
const chipLabels = {
  1: "Abierta",
  2: "En proceso",
  3: "Cerrada",
};

const VerificacionesDataTable = () => {
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [keyWord, setKeytoSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      field: "creation_date",
      headerName: "Fecha",
      flex: 1,
      renderCell: (params) => (
        <Typography>
          <DateInTable dateString={params.row.creation_date} />
        </Typography>
      ),
    },
    {
      field: "increment_id",
      headerName: "Pedido",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "id_status",
      headerName: "Status",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Chip
          label={chipLabels[params.row.id_status]}
          size="small"
          sx={{
            backgroundColor: chipColors[params.row.id_status],
            color: "#000",
          }}
        />
      ),
    },
    {
      field: "name_tipo",
      headerName: "Tipificación",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "name_guia",
      headerName: "Guía",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "accion",
      headerName: "Acción",
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => (
        <Button
          variant="contained"
          size="small"
          disableElevation
          startIcon={<VisibilityIcon />}
          onClick={() => navigate(`detail/${params.row.id_verificaciones}`)}
        >
          Detalle
        </Button>
      ),
    },
  ];

  if (
    document.querySelector(
      ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
    )
  )
    document
      .querySelector(
        ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
      )
      .remove();

  useEffect(() => {
    let active = true;

    (async () => {
      let newRows = null;

      if (
        typeof keyWord === "undefined" ||
        keyWord === undefined ||
        keyWord.length === 0
      ) {
        setLoading(true);
        newRows = await getVerifications(page);
      } else if (keyWord.length >= 5) {
        setLoading(true);
        newRows = await searchVerifications(page, keyWord);
      } else active = false;

      if (!active) {
        return;
      }
      setTotal(newRows.total);
      setRows(newRows.data);
      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [page, keyWord]);

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Grid container sx={{ my: 2 }}>
          <Grid item md={4} sx={{ px: 2 }}>
            <Stack direction="row" spacing={1}>
              <TextField
                size="small"
                variant="outlined"
                fullWidth
                label="Buscar Verificación..."
                value={keyWord}
                onChange={(e) => setKeytoSearch(e.target.value)}
              />
            </Stack>
          </Grid>
          <Grid item md sx={{ px: 2, textAlign: "right" }}></Grid>
        </Grid>
      </Box>
      <Box style={{ height: 635, width: "100%" }}>
        <DataGridPro
          rows={rows}
          columns={columns}
          pagination
          pageSize={10}
          rowsPerPageOptions={[10]}
          getRowId={(row) => row.id_verificaciones}
          rowCount={total}
          paginationMode="server"
          onPageChange={(newPage) => {
            setPage(newPage);
          }}
          loading={loading}
          disableColumnMenu
          disableSelectionOnClick
          style={{
            border: "none",
          }}
        />
      </Box>
    </>
  );
};

export default VerificacionesDataTable;
