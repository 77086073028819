import { Box, CircularProgress, Typography, Tabs, Tab } from "@mui/material";
import { useEffect, useState } from "react";
import { useTabs } from "../../../../context/TabsContext";
import { getHistorialExcep } from "../../../../utils/trazabilidad/excepciones/excepciones";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EmailIcon from '@mui/icons-material/Email';
import EditIcon from '@mui/icons-material/Edit';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import HistorialTab from "./HistorialTab";
import MessagesTab from "./MessagesTab";
import DETab from "./DETab";
import HDTab from "./HDTab";

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
      </div>
    );
};

const IndexTabs = ({ excepId, excepDl, status }) => {
    const { crmTab, setCrmTab } = useTabs();
    const [isLoading, setIsLoading] = useState(false);
    const [historial, setHistorial] = useState(null);

    const handleTabChange = (event, value) => {
        setCrmTab(value);
    };

    useEffect(() => {
        const getHistorial = async () => {
            try {
                setIsLoading(true);
                const resp = await getHistorialExcep(excepId);
                setHistorial(resp);                
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };
    
        getHistorial();
    }, []);

    return (
        <>            
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={crmTab} onChange={handleTabChange} >
                    <Tab icon={<CalendarMonthIcon />} iconPosition="start" label="Historial" />
                    <Tab icon={<EmailIcon />} iconPosition="start" label="Mensajes" />
                    <Tab icon={<EditIcon />} iconPosition="start" label="Datos de envío" />
                    <Tab icon={<EditLocationAltIcon />} iconPosition="start" label="Historial de direcciones" />
                </Tabs>
            </Box>
            {isLoading && (<Box sx={{ml:2, mt:2, mr:2, mb:2}}> <Typography variant="p"> Cargando ...</Typography> <CircularProgress /> </Box>)}
            <TabPanel value={crmTab} index={0}><HistorialTab historial={historial} excepId={excepId} /></TabPanel>
            <TabPanel value={crmTab} index={1}><MessagesTab excepId={excepId} excepDl={excepDl} /></TabPanel>
            <TabPanel value={crmTab} index={2}><DETab excepId={excepId} excepDl={excepDl} messa={historial} /></TabPanel>
            <TabPanel value={crmTab} index={3}><HDTab excepId={excepId} /></TabPanel>
        </>
    );
};

export default IndexTabs;