import { LoadingButton } from "@mui/lab";
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useEffect, useState } from "react";
import { getAllGuides, getAllServices, getCarries, getCountries, insertGuide } from "../../../../utils/trazabilidad/pedidos/pedidos";
import Toast from "../../../general/Toast";
import { useAuth } from "../../../../context/AuthContext";



const direccionLimpia = {
    //---------------------------Domicilio-------------------------------------
    customer_name: "",
    customer_lastname: "",
    customer_mothersname: "",
    shipping_street: "",
    shipping_outside_number: "",
    shipping_inside_number: "",
    shipping_delegation: "",
    shipping_suburb: "",
    shipping_postal_code: "",
    shipping_state: "",
    telephone: "",
    shipping_city: "",
    between_streets: "",
    country: "",
    //----------------------Datos adicionales-------------------------------------
    fachada: "",
    celular: "",
    id_servicio_carrier: "",
    id_tz_carriers: "",
    id_tipo_guia: "",
    observaciones: "",
};
const domicilioLimpio = {
    fachada: "",
    celular: "",
    id_servicio_carrier: "",
    id_tz_carriers: "",
    id_tipo_guia: "",
    observaciones: "",
};


const AgregarGuiasTab = ({ increment_id, domicilio }) => {
    const { user } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [toast, setToast] = useState({ open: false, message: "", type: "success" });
    const [option, setOption] = useState(100);

    const [tipificacion, setTipificacion] = useState(null);
    const [pais, setPais] = useState(null);
    const [servicio, setServicio] = useState(null);
    const [carrier, setCarrier] = useState(null);

    const [noGuias, setNoGuias] = useState(1);
    const [nuevaDireccion, setNuevaDireccion] = useState(direccionLimpia);

    /*
    setIsLoading(true);
        try {
            const success = await insertGuide(direccion);
            if (success) {
                setToast({ open: true, message: "Guia creada correctamente", type: "success" });
                setOption(0);
            } else {
                setToast({ open: true, message: "Error al crear guia", type: "error" });
            }
        } catch (error) {
            setToast({ open: true, message: "Error al crear guia", type: "error" });
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    */

    const handleCreateNewGuia = async () => {
        console.log(nuevaDireccion);
        console.log(direccion);

        setIsLoading(true);
        try {
            const success = await insertGuide(direccion);
            if (success) {
                setToast({ open: true, message: "Guia creada correctamente", type: "success" });
                setOption(100);
            } else {
                setToast({ open: true, message: "Error al crear guia", type: "error" });
            }
        } catch (error) {
            setToast({ open: true, message: "Error al crear guia", type: "error" });
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const getTipificacion = async () => {
        try {
            const res = await getAllGuides();
            console.log(res);
            setTipificacion(res);
        } catch (error) {
            console.error("getTipificacion", error);
        }
    };

    const getPais = async () => {
        try {
            const res = await getCountries();
            console.log(res);
            setPais(res);
        } catch (error) {
            console.error("getPais", error);
        }
    };

    const getServicio = async () => {
        try {
            const res = await getAllServices();
            console.log(res);
            setServicio(res);
        } catch (error) {
            console.error("getServicio", error);
        }
    };

    const getCarrier = async () => {
        try {
            const res = await getCarries();
            console.log(res);
            setCarrier(res);
        } catch (error) {
            console.error("getCarrier", error);
        }
    };

    const handleTextFieldChange = (event) => {
        const { name, value } = event.target;
        setNuevaDireccion({ ...nuevaDireccion, [name]: value });
    };

    const direccion = {
        idUser: user.data.awsid,
        nombre: nuevaDireccion.customer_name+" "+nuevaDireccion.customer_lastname+" "+nuevaDireccion.customer_mothersname,
        pais: nuevaDireccion.country,
        cp: nuevaDireccion.shipping_postal_code,
        estado: nuevaDireccion.shipping_state,
        municipio: nuevaDireccion.shipping_delegation,
        calle: nuevaDireccion.shipping_street,
        numero_ext: nuevaDireccion.shipping_outside_number,
        numero_int: nuevaDireccion.shipping_inside_number,
        colonia: nuevaDireccion.shipping_suburb,
        ciudad: nuevaDireccion.shipping_city,
        entre_calles: nuevaDireccion.between_streets,
        fachada: nuevaDireccion.fachada,
        telefono: nuevaDireccion.telephone,
        celular: nuevaDireccion.celular,
        justificacion:
            nuevaDireccion.id_tipo_guia === 1 ? "Nueva Orden" :
                nuevaDireccion.id_tipo_guia === 2 ? "RMA" :
                    nuevaDireccion.id_tipo_guia === 3 ? "Backorder" :
                        nuevaDireccion.id_tipo_guia === 4 ? "Aclaracion" :
                            nuevaDireccion.id_tipo_guia === 5 ? "Excepción" :
                                nuevaDireccion.id_tipo_guia === 6 ? "Cambio de domicilio" :
                                    nuevaDireccion.id_tipo_guia === 7 ? "Devolución extemporanea" :
                                        nuevaDireccion.id_tipo_guia === 8 ? "Peso o cubicaje" :
                                            nuevaDireccion.id_tipo_guia === 9 ? "Faltante" :
                                                nuevaDireccion.id_tipo_guia === 10 ? "Cambio de producto" :
                                                    nuevaDireccion.id_tipo_guia === 11 ? "Robo de extravío" :
                                                        nuevaDireccion.id_tipo_guia === 12 ? "Productos de requerimientos" :
                                                            nuevaDireccion.id_tipo_guia === 13 ? "Productos en pedidos" :
                                                                nuevaDireccion.id_tipo_guia === 14 ? "RMA adicional" : "",
        id_tipo_guia: nuevaDireccion.id_tipo_guia,
        increment_id: increment_id,
        servicio:
            nuevaDireccion.id_servicio_carrier === "70" ? "standard" :
                nuevaDireccion.id_servicio_carrier === "60" ? "express" :
                    nuevaDireccion.id_servicio_carrier === "D0" ? "2dias" :
                        nuevaDireccion.id_servicio_carrier === "G" ? "standard" :
                            nuevaDireccion.id_servicio_carrier === "N" ? "express" :
                                nuevaDireccion.id_servicio_carrier === "D0" ? "standard" :
                                    nuevaDireccion.id_servicio_carrier === "LET" ? "LocalET" :
                                        nuevaDireccion.id_servicio_carrier === "LED" ? "LocalED" :"",
        id_tipo_servicio: nuevaDireccion.id_servicio_carrier,
        carrier:
            nuevaDireccion.id_tz_carriers === 1 ? "Estafeta" :
                nuevaDireccion.id_tz_carriers === 3 ? "DHL" :
                    nuevaDireccion.id_tz_carriers === 5 ? "Local" : "",
        idCarrier: nuevaDireccion.id_tz_carriers,
        cantidad_guias: noGuias,
    };

    useEffect(() => {
        setNuevaDireccion(option === 100 ? direccionLimpia : { ...domicilio.data[option], ...domicilioLimpio });
    }, [option, domicilio]);


    useEffect(() => {
        getTipificacion();
        getPais();
        getServicio();
        getCarrier();
    }, [increment_id, domicilio]);

    return (
        <>
            <Toast
                open={toast.open}
                message={toast.message}
                type={toast.type}
                onClose={() => setToast({ open: false, message: "", type: "success" })}
            />

            {tipificacion && pais && servicio && carrier && <>
                <Grid sx={{ px: 2 }} container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl variant="filled" fullWidth>
                            <InputLabel id="demo-simple-select-filled-label">Direccion</InputLabel>
                            <Select
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                value={option}
                                onChange={(e) => setOption(+e.target.value)}
                            >
                                <MenuItem value={100}>Nueva direccion</MenuItem>
                                {domicilio.data.map((domicilios, i) => (
                                    <MenuItem value={i}>{`${domicilios.shipping_city} - ${domicilios.shipping_street}`}</MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl variant="filled" fullWidth>
                            <InputLabel id="demo-simple-select-filled-label">Tipificacion</InputLabel>
                            <Select
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                name="id_tipo_guia"
                                value={nuevaDireccion.id_tipo_guia}
                                onChange={handleTextFieldChange}
                            >
                                {tipificacion.data.map(({ id_tipo_guia, nombre_tipo, status, generacionManual, tipificacion }) => (
                                    <MenuItem value={id_tipo_guia} >
                                        {nombre_tipo}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            variant="filled"
                            label="Nombre"
                            name="customer_name"
                            defaultValue={nuevaDireccion.customer_name}
                            onChange={handleTextFieldChange}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            variant="filled"
                            label="Apellido Paterno"
                            name="customer_lastname"
                            value={nuevaDireccion.customer_lastname}
                            onChange={handleTextFieldChange}

                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            variant="filled"
                            label="Apellido Materno"
                            name="customer_mothersname"
                            value={nuevaDireccion.customer_mothersname}
                            onChange={handleTextFieldChange}

                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            variant="filled"
                            label="Fachada"
                            name="fachada"
                            value={nuevaDireccion.fachada}
                            onChange={handleTextFieldChange}

                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl variant="filled" fullWidth>
                            <InputLabel>Pais</InputLabel>
                            <Select
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                name="country"
                                value={nuevaDireccion.country}
                                onChange={handleTextFieldChange}
                            >
                                <MenuItem value={"MX"}>Mexico</MenuItem>
                                <MenuItem value={"España"}>España</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            variant="filled"
                            label="Estado"
                            name="shipping_state"
                            value={nuevaDireccion.shipping_state}
                            onChange={handleTextFieldChange}

                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            variant="filled"
                            label="Municipio"
                            name="shipping_delegation"
                            value={nuevaDireccion.shipping_delegation}
                            onChange={handleTextFieldChange}

                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            variant="filled"
                            label="Calle"
                            name="shipping_street"
                            value={nuevaDireccion.shipping_street}
                            onChange={handleTextFieldChange}

                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            variant="filled"
                            label="Colonia"
                            name="shipping_suburb"
                            value={nuevaDireccion.shipping_suburb}
                            onChange={handleTextFieldChange}

                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            variant="filled"
                            label="Ciudad"
                            name="shipping_city"
                            value={nuevaDireccion.shipping_city}
                            onChange={handleTextFieldChange}

                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            variant="filled"
                            label="Entre calles"
                            name="between_streets"
                            value={nuevaDireccion.between_streets}
                            onChange={handleTextFieldChange}

                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            variant="filled"
                            label="Codigo postal"
                            name="shipping_postal_code"
                            value={nuevaDireccion.shipping_postal_code}
                            onChange={handleTextFieldChange}

                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            variant="filled"
                            label="No. Exterior"
                            name="shipping_outside_number"
                            value={nuevaDireccion.shipping_outside_number}
                            onChange={handleTextFieldChange}

                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            variant="filled"
                            label="No. Interior"
                            name="shipping_inside_number"
                            value={nuevaDireccion.shipping_inside_number}
                            onChange={handleTextFieldChange}

                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            variant="filled"
                            label="Telefono"
                            name="telephone"
                            value={nuevaDireccion.telephone}
                            onChange={handleTextFieldChange}

                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            variant="filled"
                            label="Celular"
                            name="celular"
                            value={nuevaDireccion.celular}
                            onChange={handleTextFieldChange}

                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl variant="filled" fullWidth>
                            <InputLabel id="demo-simple-select-filled-label">Servicio</InputLabel>
                            <Select
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                name="id_servicio_carrier"
                                value={nuevaDireccion.id_servicio_carrier}
                                onChange={handleTextFieldChange}

                            >
                                {servicio.data.map(({ id_tipo_servicio, servicio, id_carrier, id_servicio_carrier, label, active }) => (
                                    <MenuItem value={id_servicio_carrier}>
                                        {`${id_servicio_carrier} - ${label}`}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl variant="filled" fullWidth>
                            <InputLabel id="demo-simple-select-filled-label">Carrier</InputLabel>
                            <Select
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                name="id_tz_carriers"
                                value={nuevaDireccion.id_tz_carriers}
                                onChange={handleTextFieldChange}

                            >
                                {carrier.data.map(({ id_tz_carriers, nombre, status }) => (
                                    <MenuItem value={id_tz_carriers}>
                                        {nombre}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl variant="filled" fullWidth>
                            <InputLabel id="demo-simple-select-filled-label">Guias</InputLabel>
                            <Select
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                value={noGuias}
                                onChange={(e) => setNoGuias(+e.target.value)}
                            >
                                <MenuItem value="1">1</MenuItem>
                                <MenuItem value="2">2</MenuItem>
                                <MenuItem value="3">3</MenuItem>
                                <MenuItem value="4">4</MenuItem>
                                <MenuItem value="5">5</MenuItem>
                                <MenuItem value="6">6</MenuItem>
                                <MenuItem value="7">7</MenuItem>
                                <MenuItem value="8">8</MenuItem>
                                <MenuItem value="9">9</MenuItem>
                                <MenuItem value="10">10</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            rows={3}
                            multiline
                            fullWidth
                            variant="filled"
                            label="Observaciones"
                            name="observaciones"
                            value={nuevaDireccion.observaciones}
                            onChange={handleTextFieldChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <LoadingButton variant="contained" disableElevation loading={isLoading} onClick={handleCreateNewGuia}>
                                Crear
                            </LoadingButton>
                        </Box>
                    </Grid>
                </Grid>
            </>}
        </>
    );
};

export default AgregarGuiasTab;