import { CssBaseline, ThemeProvider } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AuthProvider } from "./context/AuthContext";
import { DialogsProvider } from "./context/DialogsContext";
import { ResourcesProvider } from "./context/ResourcesContext";
import { TabsProvider } from "./context/TabsContext";
import theme from "./theme/main";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { HashRouter } from "react-router-dom";

const container = document.getElementById("root");

const root = ReactDOM.createRoot(container);

root.render(
  <HashRouter basename="/">
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <DialogsProvider>
          <ResourcesProvider>
            <TabsProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <App />
              </LocalizationProvider>
            </TabsProvider>
          </ResourcesProvider>
        </DialogsProvider>
      </ThemeProvider>
    </AuthProvider>
  </HashRouter>
);
