import { Alert, Slide, Snackbar } from "@mui/material";

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

const Toast = ({ open, message, type, onClose }) => {
  return (
    <Snackbar
      autoHideDuration={6000}
      open={open}
      onClose={onClose}
      TransitionComponent={SlideTransition}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert variant="filled" onClose={onClose} severity={type}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
