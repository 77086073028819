import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect } from "react";
import { useState } from "react";
import {
  deleteChildren,
  getChildrens,
} from "../../../utils/vales/beneficiarios/beneficiarios";
import TapUpdateChildren from "./TapUpdateChildren";

const TabChildrensList = ({
  onClose,
  open,
  beneficiaryIdUpdate,
  updateUsr,
  setLoading,
  setRows,
  setEstado,
  setValue,
  schools,
}) => {
  const [childrens, setChildrens] = useState([]);
  const [loadingChildrens, setLoadingChildrens] = useState(true);
  const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [currentChildId, setCurrentChildId] = useState(null);
  const [reloadTable, setReloadTable] = useState(0);

  const [isEditing, setIsEditing] = useState(false);
  const [selectedChild, setSelectedChild] = useState(null);

  useEffect(() => {
    const fetchChildrensData = async () => {
      const response = await getChildrens(beneficiaryIdUpdate);
      const userData = response.data;
      setChildrens(userData);
      setLoadingChildrens(false);
    };
    fetchChildrensData();
  }, [open, beneficiaryIdUpdate, reloadTable]);

  const handleDelete = async () => {
    let data = {
      idUser: updateUsr,
      idChildren: currentChildId,
    };
    setModalDeleteOpen(false);
    setLoadingChildrens(true);
    setChildrens([]);
    await deleteChildren(data);
    setReloadTable((prevValue) => (prevValue === 0 ? 1 : 0));
  };

  const onCloseAndClear = () => {
    onClose();
    setLoading(true);
    setRows([]);
    setEstado((prevEstado) => (prevEstado === 0 ? 1 : 0));
    setValue(0);
  };

  const handleEdit = (child) => {
    setIsEditing(true);
    setSelectedChild(child);
  };

  const handleCloseEdit = () => {
    setIsEditing(false);
    setSelectedChild(null);
  };

  const styles = {
    table: { maxWidth: "900px" },
    skeletonContainer: { width: 530, position: "absolute" },
    noRowsText: {
      position: "absolute",
      marginLeft: "200px",
      marginY: "120px",
    },
    rowHover: {
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        cursor: "pointer",
      },
      "&:active": {
        backgroundColor: "rgba(0, 0, 0, 0.1)",
      },
      transition: "background-color 0.3s",
    },
    cellPadding: { paddingX: "10px", paddingY: "5px" },
    actionButtons: {
      display: "flex",
      alignItems: "center",
    },
  };

  return (
    <>
      {isEditing ? (
        <TapUpdateChildren
          child={selectedChild}
          onClose={handleCloseEdit}
          schools={schools}
          open={isEditing}
          updateUsr={updateUsr}
          setLoadingChildrens={setLoadingChildrens}
          setChildrens={setChildrens}
          setReloadTable={setReloadTable}
        />
      ) : (
        <>
          <Box
            sx={{
              minHeight: "350px",
              minWidth: "530px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Table sx={styles.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Escuela</TableCell>
                  <TableCell>Grado</TableCell>
                  <TableCell>Acción</TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ display: "none" }}></TableBody>
              {loadingChildrens ? (
                <TableRow>
                  <TableCell colSpan={4} sx={{ display: "none" }}></TableCell>
                  <Box sx={styles.skeletonContainer}>
                    {Array(4)
                      .fill()
                      .map((_, idx) => (
                        <Skeleton key={idx} sx={{ height: "50px" }} />
                      ))}
                  </Box>
                </TableRow>
              ) : childrens.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Typography sx={styles.noRowsText}>No Rows</Typography>
                  </TableCell>
                </TableRow>
              ) : (
                childrens.map((child) => (
                  <TableRow key={child.id_children} sx={styles.rowHover}>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={styles.cellPadding}
                    >
                      {`${child.name} ${child.last_name} ${child.mothes_name}`}
                    </TableCell>
                    <TableCell sx={styles.cellPadding}>
                      {child.school_name}
                    </TableCell>
                    <TableCell sx={styles.cellPadding}>
                      {child.name_grade}
                    </TableCell>
                    <TableCell sx={styles.cellPadding}>
                      <div style={styles.actionButtons}>
                        <IconButton
                          aria-label="edit"
                          color="primary"
                          sx={{ borderRadius: 0, padding: "6px" }}
                          onClick={() => handleEdit(child.id_children)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          color="primary"
                          sx={{ borderRadius: 0, padding: "6px" }}
                          onClick={() => {
                            setCurrentChildId(child.id_children);
                            setModalDeleteOpen(true);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </Table>
            <DialogActions>
              <Button
                color="error"
                variant="outlined"
                onClick={onCloseAndClear}
              >
                Cerrar
              </Button>
            </DialogActions>
          </Box>
          <Dialog
            open={isModalDeleteOpen}
            onClose={() => setModalDeleteOpen(false)}
          >
            <DialogTitle>Confirmar eliminación</DialogTitle>
            <DialogContent>
              <DialogContentText>
                ¿Estás seguro de que quieres eliminar?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setModalDeleteOpen(false)}
                color="error"
                variant="outlined"
              >
                Cerrar
              </Button>
              <Button onClick={handleDelete} color="success" variant="outlined">
                Eliminar
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
};

export default TabChildrensList;
