import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ModalCreateBeneficiario from "./ModalCreateBeneficiario";
import { useAuth } from "../../../context/AuthContext";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  getBeneficiaries,
  getSchools,
  searchBeneficiaries,
} from "../../../utils/vales/beneficiarios/beneficiarios";
import ModalDelete from "./ModalDelete";
import DialogUpdateBeneficiario from "./DialogUpdateBeneficiario";

const BeneficiariosDataTable = () => {
  const { user } = useAuth();
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [schools, setSchools] = useState([]);
  const [keyWord, setKeytoSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [openDialogUpdate, setOpenDialogUpdate] = useState(false);
  const [beneficiaryIdUpdate, setBeneficiaryIdUpdate] = useState(null);

  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [selectedUserIdDel, setSelectedUserIdDel] = useState(null);
  const [selectedUserNameDel, setSelectedUserNameDel] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(null);

  const [openModalCreate, setOpenModalCreate] = useState(false);
  const [estado, setEstado] = useState(0);

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setOpen(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(null);
  };

  const handleOpenModalCreate = () => {
    setOpenModalCreate(true);
  };

  const handleCloseModalCreate = () => {
    setOpenModalCreate(false);
  };

  const handleClickUpdate = (beneficiary) => {
    setBeneficiaryIdUpdate(beneficiary);
  };

  //Modal Delete
  function handleCloseModalDel() {
    setIsModalDeleteOpen(false);
    setSelectedUserIdDel(null);
    setSelectedUserNameDel(null);
  }

  const columns = [
    {
      field: "name",
      headerName: "Nombre",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Typography variant="subtitle1" align="center">
          {params.row.name +
            " " +
            params.row.last_name +
            " " +
            params.row.mothers_name}
        </Typography>
      ),
    },
    {
      field: "mail",
      headerName: "Correo",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Typography variant="subtitle1" align="center">
          {params.row.mail}
        </Typography>
      ),
    },
    {
      field: "accion",
      headerName: "Acción",
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => (
        <>
          <Button
            variant="contained"
            size="small"
            endIcon={<KeyboardArrowDownIcon />}
            disableElevation
            aria-controls={open ? this : undefined}
            aria-haspopup="menu"
            onClick={(event) => handleClick(event, params.row.id_beneficiary)}
          >
            Acción
          </Button>
          <Menu
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            anchorEl={anchorEl}
            open={open === params.row.id_beneficiary ? true : false}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                setOpenDialogUpdate(true);
                handleClickUpdate(params.row.id_beneficiary);
                handleClose();
              }}
            >
              <Typography>Editar datos</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setIsModalDeleteOpen(true);
                setSelectedUserIdDel(params.row.id_beneficiary);
                setSelectedUserNameDel(
                  `${params.row.name} ${params.row.last_name} ${params.row.mothers_name}`
                );
                handleClose();
              }}
            >
              <Typography>Eliminar</Typography>
            </MenuItem>
          </Menu>
        </>
      ),
    },
  ];

  if (
    document.querySelector(
      ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
    )
  )
    document
      .querySelector(
        ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
      )
      .remove();

  useEffect(() => {
    let active = true;

    (async () => {
      let newRows = null;
      let schoolsD = [];

      if (
        typeof keyWord === "undefined" ||
        keyWord === undefined ||
        keyWord.length === 0
      ) {
        setLoading(true);
        newRows = await getBeneficiaries(page);
        schoolsD = await getSchools();
      } else if (keyWord.length >= 3) {
        setLoading(true);
        newRows = await searchBeneficiaries(page, keyWord);
        schoolsD = await getSchools();
      } else active = false;

      if (!active) {
        return;
      }
      setTotal(newRows.total);
      setRows(newRows.data);
      setSchools(schoolsD.data);
      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [page, keyWord, estado]);

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Grid container sx={{ mb: 2, mt: 2 }}>
          <Grid item md={4} sx={{ px: 2 }}>
            <Stack direction="row" spacing={1}>
              <TextField
                size="small"
                variant="outlined"
                fullWidth
                label={`Buscar Beneficiarios`}
                value={keyWord}
                onChange={(e) => setKeytoSearch(e.target.value)}
              />
            </Stack>
          </Grid>

          <Grid item md sx={{ px: 2, textAlign: "right" }}>
            <LoadingButton
              variant="contained"
              color="info"
              onClick={handleOpenModalCreate}
            >
              Agregar Beneficiario
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
      <Box style={{ height: 635, width: "100%" }}>
        <DataGridPro
          rows={rows}
          columns={columns}
          pagination
          pageSize={10}
          rowsPerPageOptions={[10]}
          getRowId={(row) => row.id_beneficiary}
          rowCount={total}
          paginationMode="server"
          onPageChange={(newPage) => {
            setPage(newPage);
          }}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
          loading={loading}
          disableColumnMenu
          disableSelectionOnClick
          getDetailPanelHeight={() => "auto"}
        />
      </Box>
      <ModalCreateBeneficiario
        open={openModalCreate}
        onClose={handleCloseModalCreate}
        setLoading={setLoading}
        setRows={setRows}
        setEstado={setEstado}
        schools={schools}
        creationUsr={user.data.awsid}
      />
      <DialogUpdateBeneficiario
        open={openDialogUpdate}
        onClose={() => setOpenDialogUpdate(false)}
        schools={schools}
        setLoading={setLoading}
        setRows={setRows}
        setEstado={setEstado}
        updateUsr={user.data.awsid}
        beneficiaryIdUpdate={beneficiaryIdUpdate}
      />
      <ModalDelete
        isOpen={isModalDeleteOpen}
        onClose={handleCloseModalDel}
        userId={selectedUserIdDel}
        selectedUserName={selectedUserNameDel}
        setLoading={setLoading}
        setRows={setRows}
        setEstado={setEstado}
        updateUsr={user.data.awsid}
      />
    </>
  );
};

export default BeneficiariosDataTable;
