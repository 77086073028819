import axios from "axios";

/**
 * GET
 **/

const getValidations = async (currentPage) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/validaciones/validaciones/all/new?page=${currentPage}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getTypeValidations = async () => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/validaciones/validaciones/all/typevalidations`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getSuppliers = async () => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/validaciones/validaciones/all/suppliers`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getRequirements = async (idRequerimiento) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/validaciones/validaciones/detail/requirements?idRequerimiento=${idRequerimiento}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getProduct = async (idRequerimiento, idRequerimientoProducto) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/validaciones/validaciones/detail/product?idRequerimiento=${idRequerimiento}&idRequerimientoProducto=${idRequerimientoProducto}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getProducts = async (page, idRequerimiento, idRequerimientoProducto) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/validaciones/validaciones/detail/products?page=${page}&idRequerimiento=${idRequerimiento}&idRequerimientoProducto=${idRequerimientoProducto}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const searchProducts = async (
  page,
  idRequerimiento,
  idRequerimientoProducto,
  search
) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/validaciones/validaciones/detail/products/search?page=${page}&idRequerimiento=${idRequerimiento}&idRequerimientoProducto=${idRequerimientoProducto}&search=${search}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const searchNewsProducts = async (search) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/validaciones/validaciones/all/search/newsproducts?search=${search}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

// Menssages
const addMessage = async (dataMsj) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/validaciones/validaciones/detail/add/message`;
  try {
    await axios.post(URL, dataMsj);
  } catch (error) {
    console.error("addMessage", error);
  }
};

const validateProduct = async (data) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/validaciones/validaciones/all/validateproduct`;
  try {
    await axios.post(URL, data);
  } catch (error) {
    console.error("validateProduct", error);
  }
};

export {
  getValidations,
  getTypeValidations,
  getSuppliers,
  getRequirements,
  getProduct,
  getProducts,
  searchProducts,
  addMessage,
  validateProduct,
  searchNewsProducts
};
