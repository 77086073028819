import {
  Box,
  TextField,
  Select,
  FormControl,
  MenuItem,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Checkbox,
  Text,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import { LoadingButton } from "@mui/lab";
import { DataGrid } from "@mui/x-data-grid";
import { useState, forwardRef } from "react";
import SaveIcon from "@mui/icons-material/Save";
import { useAuth } from "../../../../context/AuthContext";
import {
  takeRMA,
  changeSolution,
  saveItemQuantity,
  changeStatusRMA,
  toggleItemStatus,
} from "../../../../utils/trazabilidad/rma/rma";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ArticulosTab = ({ articulos, status, rmaId, incrementId, rmaDl, getArticulos }) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [quantity, setQuantity] = useState("");
  const [resolucion, setResolucion] = useState("");
  const [open, setOpen] = useState(false);
  const [openn, setOpenn] = useState(false);
  const [comment, setComment] = useState("");
  const [vertical, setVertical] = useState("top");
  const [horizontal, setHorizontal] = useState("right");

  const handleClickN = () => {
    setOpenn(true);
  };

  const handleCloseN = () => {
    setOpenn(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleComment = (event) => {
    setComment(event.target.value);
  };

  const refreshPage = () => {
    console.log(location.pathname);
    const current = location.pathname;
    navigate(`/reload`, { replace: true });
    setTimeout(() => {
      navigate(current, { replace: true });
    });
  };

  const handleSaveDataRMA = async (event) => {
    try {
      setIsLoading(true);
      let value = event.target.value === "1" ? 1 : 0;
      let clientName = rmaDl.customer_name + " " + rmaDl.customer_lastname;
      await changeStatusRMA(
        rmaId,
        auth.user.data.awsid,
        rmaDl.order_increment_id,
        incrementId,
        rmaDl.email,
        rmaDl.sap_id_method,
        rmaDl.diasTranscurridos,
        value,
        clientName
      );
      refreshPage();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeQ = async (event) => {
    try {
      setIsLoading(true);
      let item = event.target.name;
      let value = event.target.value;
      await saveItemQuantity(
        rmaId,
        auth.user.data.awsid,
        incrementId,
        item.order_increment_id,
        item.ifRMA,
        value,
        item.eze3_item_id,
        item.product_sku
      );
      handleClickN();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeR = async (event) => {
    try {
      setIsLoading(true);
      let item = event.target.name;
      let value = getNResolucionT(event.target.value);
      if (value !== 0)
        await changeSolution(
          incrementId,
          rmaId,
          item.product_name,
          auth.user.data.awsid,
          item.product_sku,
          item.order_increment_id,
          item.eze3_item_id,
          value
        );
      handleClickN();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  function getGrupo(params) {
    if (params.row.category_name) return `${params.row.category_name}`;
    else return "S/Grupo";
  }

  function getResolucion(params) {
    if (params.row.resolucionRMA.resolucion)
      return `${params.row.resolucionRMA.resolucion}`;
    else return "-";
  }

  function getCantidadR(params) {
    if (params.row.cantidad_devuelta_log)
      return params.row.cantidad_devuelta_log;
    else return 0;
  }

  function getResolucionT(params) {
    if (params.row.if_storecredit_log) return "Store Credit";
    else if (params.row.if_reembolso_log) return "Reembolso";
    else if (params.row.if_cambio_log) return "Cambio";
    else return "-";
  }

  function getNResolucionT(val) {
    if (val === "Store Credit") return 1;
    else if (val === "Reembolso") return 2;
    else if (val === "Cambio") return 3;
    else return 0;
  }

  function canEdit() {
    let sc = status.id_status_storeCredit;
    let re = status.id_status_reembolso;
    let ca = status.id_status_cambio;

    if (sc === 2 && re === 2 && ca === 2) {
      return true;
    } else {
      return false;
    }
  }

  const handleTakeRMA = async () => {
    try {
      setIsLoading(true);
      await takeRMA(rmaId, auth.user.data.awsid, incrementId, comment);
      refreshPage();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggleItemStatus = async (itemId, sku) => {
    try {
      setIsLoading(true);
      await toggleItemStatus(itemId, rmaId, auth.user.data.awsid, sku);
      await getArticulos();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    { field: "product_sku", headerName: "SKU", flex: 1 },
    { field: "product_name", headerName: "Nombre", flex: 3 },
    { field: "attributes", headerName: "Categoría", flex: 1 },
    {
      field: "category_name",
      headerName: "Grupo de Artículos",
      flex: 1,
      valueGetter: getGrupo,
    },
    { field: "quantity", headerName: "Cantidad", flex: 1 },
    { field: "price", headerName: "Precio", flex: 1 },
    { field: "total", headerName: "Total", flex: 1 },
    {
      field: "resolucionRMA.resolucion",
      headerName: "Solicitud RMA (Cantidad/Resolución)",
      flex: 1,
      valueGetter: getResolucion,
    },
    {
      field: "cantidad_devuelta_log",
      headerName: "Cantidad Recibida",
      flex: 1,
      valueGetter: getCantidadR,
    },
    {
      field: "resolucion",
      headerName: "Resolución",
      flex: 1,
      valueGetter: getResolucionT,
    },
    {
      field: "test",
      headerName: "En reparacion",
      flex: 1,
      renderCell: (params) => {
        return (
          <Checkbox
            defaultChecked={params.row.statusItem !== 1}
            onChange={() => handleToggleItemStatus(params.row.idItem, params.row.product_sku)}
          />
        );
      },
    },
  ];

  const columnsRec = [
    { field: "product_sku", headerName: "SKU", flex: 1 },
    { field: "product_name", headerName: "Nombre", flex: 3 },
    { field: "attributes", headerName: "Categoría", flex: 1 },
    {
      field: "category_name",
      headerName: "Grupo de Artículos",
      flex: 1,
      valueGetter: getGrupo,
    },
    { field: "quantity", headerName: "Cantidad", flex: 1 },
    { field: "price", headerName: "Precio", flex: 1 },
    { field: "total", headerName: "Total", flex: 1 },
    {
      field: "resolucionRMA.resolucion",
      headerName: "Solicitud RMA (Cantidad/Resolución)",
      flex: 1,
      valueGetter: getResolucion,
    },
    {
      field: "cantidad",
      headerName: "Cantidad Recibida",
      flex: 1,
      renderCell: (params) => (
        <FormControl variant="standard" fullWidth>
          <Select
            name={params.row}
            defaultValue={getCantidadR(params)}
            displayEmpty
            onChange={handleChangeQ}
          >
            {(function () {
              let options = [];
              for (let i = 0; i <= params.row.quantity; i++) {
                options.push(
                  <MenuItem key={i} value={i}>
                    {i}
                  </MenuItem>
                );
              }
              return options;
            })()}
          </Select>
        </FormControl>
      ),
    },
    {
      field: "resolucion",
      headerName: "Resolución",
      flex: 1,
      renderCell: (params) => (
        <FormControl variant="standard" fullWidth>
          <Select
            name={params.row}
            defaultValue={getResolucionT(params)}
            displayEmpty
            onChange={handleChangeR}
          >
            <MenuItem value="-">
              <em>Ninguna</em>
            </MenuItem>
            <MenuItem value="Store Credit">Store Credit</MenuItem>
            <MenuItem value="Reembolso">Reembolso</MenuItem>
            <MenuItem value="Cambio">Cambio</MenuItem>
          </Select>
        </FormControl>
      ),
    },
    {
      field: "test",
      headerName: "En reparacion",
      flex: 1,
      renderCell: (params) => {
        return (
          <Checkbox
            defaultChecked={params.row.statusItem !== 1}
            onChange={() => handleToggleItemStatus(params.row.idItem, params.row.product_sku)}
          />
        );
      },
    },
  ];

  return (
    <>
      <Box sx={{ mb: 2, ml: 2, mr: 2 }}>
        {articulos && (
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                autoHeight
                rows={articulos}
                columns={canEdit() === true ? columnsRec : columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                getRowId={(row) => row.eze3_item_id}
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                disableDensitySelector
                disableExtendRowFullWidth
                disableSelectionOnClick
                initialState={{
                  sorting: {
                    sortModel: [
                      { field: "resolucionRMA.resolucion", sort: "desc" },
                    ],
                  },
                }}
              />
            </div>
          </div>
        )}
      </Box>
      {status.id_status_storeCredit === 1 &&
        status.id_status_reembolso === 1 &&
        status.id_status_cambio === 1 && (
          <Box sx={{ mb: 2, ml: 2, mr: 2 }}>
            <Button
              variant="contained"
              color="success"
              onClick={handleClickOpen}
            >
              Registrar como RMA recibida
            </Button>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>Registrar datos de la guía</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Ingresar información adicional como carrier externo, guía,
                  forma de entrega, persona que lo trajo, etc...
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Comentarios"
                  type="text"
                  fullWidth
                  variant="standard"
                  multiline
                  rows={2}
                  value={comment}
                  onChange={handleComment}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancelar</Button>
                <LoadingButton
                  loading={isLoading}
                  variant="contained"
                  onClick={handleTakeRMA}
                  disabled={!comment}
                >
                  Guardar
                </LoadingButton>
              </DialogActions>
            </Dialog>
          </Box>
        )}

      {status.id_status_storeCredit === 2 &&
        status.id_status_reembolso === 2 &&
        status.id_status_cambio === 2 && (
          <Box sx={{ mb: 2, ml: 2, mr: 2, textAlign: "right" }}>
            <LoadingButton
              loading={isLoading}
              variant="contained"
              color="info"
              onClick={handleClickOpen}
              startIcon={<SaveIcon />}
            >
              Guardar Datos RMA
            </LoadingButton>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Notificar al cliente"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  ¿Enviar correo al cliente de que el pedido ha sido recibido?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="error"
                  value="0"
                  onClick={handleSaveDataRMA}
                  disabled={isLoading}
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  value="1"
                  onClick={handleSaveDataRMA}
                  autoFocus
                  disabled={isLoading}
                >
                  Sí
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        )}

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}
        open={openn}
        onClose={handleCloseN}
      >
        <Alert onClose={handleCloseN} severity="success" sx={{ width: "100%" }}>
          Cambios realizados
        </Alert>
      </Snackbar>
    </>
  );
};

export default ArticulosTab;
