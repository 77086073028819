import { Box, CircularProgress, Typography, Alert, Grid, List, ListItem, ListItemText, Card, CardContent, Select, FormControl, MenuItem, InputLabel, Stack, TextField, ListSubheader } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAuth } from "../../../../context/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { getBackOrderAddress, generateGuide, setGuide } from "../../../../utils/trazabilidad/backorders/backorders";

const DETab = ({ boId, detalle }) => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [address, setAddress] = useState({
        nombre: detalle.shipping_name+' '+detalle.shipping_lastname+' '+detalle.shipping_mothersname,
        pais: 'México',
        estado: '',
        dele: '',
        calle: '',
        colonia: '',
        ciudad: '',
        entre: '',
        fachada: '',
        cp: '',
        telefono: '',
        ne: '',
        ni: '',
        groupedselect: ''
    });

    const handleChangeAddress = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        if(name == "groupedselect")
        {
            if(value == 1)
            {
                setAddress((prevalue) => {
                    return {
                        ...prevalue,
                        ["pais"]: 'México',
                        ["estado"]: detalle.shipping_state,
                        ["ciudad"]: detalle.shipping_city,
                        ["calle"]: detalle.shipping_street,
                        ["dele"]: detalle.shipping_delegation,
                        ["colonia"]: detalle.shipping_suburb,
                        ["entre"]: detalle.between_streets,
                        ["fachada"]: '',
                        ["cp"]: detalle.shipping_postal_code,
                        ["telefono"]: detalle.telf,
                        ["ni"]: detalle.shipping_inside_number,
                        ["ne"]: detalle.shipping_outside_number,
                        ["groupedselect"]: value
                    }
                })
            }
            else if(value == 0)
            {
                setAddress((prevalue) => {
                    return {
                        ...prevalue,
                        ["pais"]: 'México',
                        ["estado"]: '',
                        ["ciudad"]: '',
                        ["calle"]: '',
                        ["dele"]: '',
                        ["colonia"]: '',
                        ["entre"]: '',
                        ["fachada"]: '',
                        ["cp"]: '',
                        ["telefono"]: '',
                        ["ni"]: '',
                        ["ne"]: '',
                        ["groupedselect"]: value
                    }
                })
            }
        }else
        {
            setAddress((prevalue) => {
                return {
                    ...prevalue,
                    [name]: value
                }
            })
        }            
    };

    function isempt(){
        if(address.nombre && address.pais && address.cp && address.estado && address.dele && address.calle && address.ne && address.colonia && address.ciudad && address.entre && address.fachada && address.telefono)
            return false;
        else 
            return true;
    }

    const handleSaveSD = async () => {
        try {
          setIsLoading(true);
          if(!isempt()){
            let dir = {
                names: detalle.shipping_name,
                lastname: detalle.shipping_lastname,
                mothersname: detalle.shipping_mothersname,
                estado: address.estado,
                municipio: address.dele,
                ciudad: address.ciudad,
                colonia: address.colonia,
                calle: address.calle,
                entre_calles: address.entre,
                numero_ext: address.ne,
                numero_int: address.ni,
                cp: address.cp,
                telefono: address.telefono,
                celular: "",
                pais: address.pais,
                fachada: address.fachada
            }
            const res = await generateGuide(user.data.awsid, boId, detalle.increment_id, 1, dir);
            console.log(res);
            //await setGuide(res.idguia, user.data.awsid, boId, detalle.increment_id);
          }
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
    };

    useEffect(() => {
        
        const getDireccionBO = async () => {
            try {
                setIsLoading(true);
                let res = await getBackOrderAddress(boId, detalle.increment_id);
                setAddress(res);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };

        if(detalle.guia !== null) getDireccionBO();

    }, [boId, detalle]);

    return (
    <Box sx={{mb: 2, ml: 2, mr: 2}}>
        {isLoading && <CircularProgress />}
        {(detalle.guia !== null && address && !isLoading) && (
        <>
            <Alert variant="filled" severity="info"><Typography variant="p"><strong>Datos del cliente</strong></Typography></Alert>
            <Grid container sx={{mt:2, mb:2}}>                
                <Grid item md={4}>
                    <List>
                        <ListItem>
                            <ListItemText primary="Nombre" secondary={address.nombre}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Estado" secondary={address.estado}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Núm. Ext" secondary={address.numero_ext}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Ciudad" secondary={address.ciudad}/>
                        </ListItem>
                    </List>
                </Grid>

                <Grid item md={4}>
                    <List>
                        <ListItem>
                            <ListItemText primary="Municipio" secondary={address.municipio}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Calle" secondary={address.calle}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Núm. Int" secondary={address.numero_int ?? 'S/N'}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Entre Calles" secondary={address.entre_calles}/>
                        </ListItem>
                    </List>
                </Grid>

                <Grid item md={4}>
                    <List>
                        <ListItem>
                            <ListItemText primary="Código Postal" secondary={address.cp}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Colonia" secondary={address.colonia}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Teléfono" secondary={address.telefono}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Fachada" secondary={address.fachada ?? 'S/I'}/>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </>
        )}

        {(detalle.guia === null) && (
        <Grid container sx={{mb:2}}>
            <Grid item md={12}>
                <Alert variant="filled" severity="info"><Typography variant="p"><strong>Datos del cliente</strong></Typography></Alert>
                <Card>
                    <CardContent>
                        <div>
                            <FormControl fullWidth sx={{mb: 2}}>
                                <InputLabel id="selecte" htmlFor="groupedselect">Seleccionar Dirección</InputLabel>
                                <Select id="groupedselect" name="groupedselect" label="Seleccionar Dirección" value={address.groupedselect} onChange={handleChangeAddress}>
                                    <MenuItem value=""><em>Seleccionar dirección</em></MenuItem>
                                    <MenuItem value={0}>Agregar nueva dirección</MenuItem>
                                    <ListSubheader>Dirección de pedido</ListSubheader>
                                    <MenuItem value={1}>{detalle.shipping_city} - {detalle.shipping_street}</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <Stack sx={{m: 2}} direction="row" spacing={3} autoComplete="off">
                            <TextField fullWidth name="nombre" label="Nombre" variant="outlined" inputProps={{maxlength: 30}} value={address.nombre} onChange={handleChangeAddress} disabled />
                            <TextField fullWidth name="pais" label="País" variant="outlined" value={address.pais} onChange={handleChangeAddress} />
                            <TextField fullWidth name="estado" label="Estado" variant="outlined" value={address.estado} onChange={handleChangeAddress} />
                        </Stack>
                        <Stack sx={{m: 2}} direction="row" spacing={3} autoComplete="off">
                            <TextField fullWidth name="dele" label="Delegación/Municipio" variant="outlined" value={address.dele} onChange={handleChangeAddress} />
                            <TextField fullWidth name="calle" label="Calle" variant="outlined" value={address.calle} onChange={handleChangeAddress} />
                            <TextField fullWidth name="colonia" label="Colonia" variant="outlined" value={address.colonia} onChange={handleChangeAddress} />
                        </Stack>
                        <Stack sx={{m: 2}} direction="row" spacing={3} autoComplete="off">
                            <TextField fullWidth name="ciudad" label="Ciudad" variant="outlined" value={address.ciudad} onChange={handleChangeAddress} />
                            <TextField fullWidth name="entre" label="Entre calles" variant="outlined" value={address.entre} onChange={handleChangeAddress} />
                            <TextField fullWidth name="fachada" label="Fachada" variant="outlined" value={address.fachada} onChange={handleChangeAddress} />
                        </Stack>
                        <Stack sx={{m: 2}} direction="row" spacing={4} autoComplete="off">                    
                            <TextField fullWidth name="cp" label="Código Postal" variant="outlined" value={address.cp} onChange={handleChangeAddress} />
                            <TextField fullWidth name="telefono" label="Teléfono" variant="outlined" value={address.telefono} onChange={handleChangeAddress} />
                            <TextField fullWidth name="ne" label="Número exterior" variant="outlined" value={address.ne} onChange={handleChangeAddress} />
                            <TextField fullWidth name="ni" label="Número interior" variant="outlined" value={address.ni} onChange={handleChangeAddress} />
                        </Stack>
                        <div style={{ textAlign: 'right', marginTop: '2em' }}>
                            <LoadingButton loading={isLoading} size="medium" variant="contained" color="success" onClick={handleSaveSD} disabled={(isempt())}>Guardar datos de envío</LoadingButton>
                        </div>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
        )}
    </Box>
    );
};

export default DETab;