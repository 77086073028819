import { Box, CircularProgress, Typography, Tabs, Tab } from "@mui/material";
import { useEffect, useState } from "react";
import { useTabs } from "../../../../context/TabsContext";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EmailIcon from '@mui/icons-material/Email';
import EditIcon from '@mui/icons-material/Edit';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import DETab from "./DETab";
import MovimientosTab from "./MovimientosTab";

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
      </div>
    );
};

const IndexTabs = ({ verifId, verifDl }) => {
    const { crmTab, setCrmTab } = useTabs();
    const [isLoading, setIsLoading] = useState(false);

    const handleTabChange = (event, value) => {
        setCrmTab(value);
    };

    return (
        <>            
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={crmTab} onChange={handleTabChange} >
                    <Tab icon={<EditLocationAltIcon />} iconPosition="start" label="Datos de envío" />
                    <Tab icon={<FormatListBulletedIcon />} iconPosition="start" label="Movimientos" />
                </Tabs>
            </Box>
            {isLoading && (<Box sx={{ml:2, mt:2, mr:2, mb:2}}> <Typography variant="p"> Cargando ...</Typography> <CircularProgress /> </Box>)}
            <TabPanel value={crmTab} index={0}><DETab verifId={verifId} verifDl={verifDl} /></TabPanel>
            <TabPanel value={crmTab} index={1}><MovimientosTab verifId={verifId} /></TabPanel>
        </>
    );
};

export default IndexTabs;