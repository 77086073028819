import {
  Box,
  Grid,
  Button,
  Stack,
  Typography,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputLabel,
  Select,
  FormControl,
  Menu,
  MenuItem,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import DateInTable from "../../general/DateInTable";
import Toast from "../../general/Toast";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SaveIcon from "@mui/icons-material/Save";
import UploadIcon from "@mui/icons-material/Upload";
import CircleIcon from "@mui/icons-material/Circle";
import ExcelFile from "../../general/ExcelFile";
import {
  getHR,
  searchHR,
  searchDetailHR,
  getExcelHR,
  saveMessage,
  cancelHR,
  rejectHR,
  releaseToLHR,
  returnHR,
  uploadFilesRefundHR,
  creditNoteHR,
} from "../../../utils/altoRiesgo/alto/alto";

const chipLColors = {
  "S/Estatus": "#ffecb3",
  Entregado: "#c8e6c9",
  "Enterado Logistica": "#bbdefb",
  Detenido: "#ffcdd2",
  "Confirmado cancelacion": "#ffcdd2",
  "En ruta": "#bbdefb",
  "Confirmado liberacion": "#c8e6c9",
};
const chipACColors = {
  "S/Estatus": "#ffecb3",
  "En Prorroga": "#c8e6c9",
  "Con evidencia": "#c8e6c9",
  "Enterado Atencion a Clientes": "#c8e6c9",
};
const chipARColors = {
  "S/Estatus": "#ffecb3",
  Cerrada: "#ffcdd2",
  "En Espera de Reemboolso": "#ffecb3",
  "En Prorroga": "#ffcdd2",
  "En Espera de Nota de Credito": "#ffecb3",
};
const chipFColors = {
  "S/Estatus": "#ffecb3",
  "En espera de validacion": "#bbdefb",
  Cerrada: "#ffcdd2",
  Rechazadas: "#ffcdd2",
  Nuevas: "#bbdefb",
  Aprobadas: "#c8e6c9",
};
const chipCirColors = {
  Aprobadas: "#d2d6de",
  Cerrada: "#d2d6de",
  Rechazadas: "#d2d6de",
  Verde: "#c8e6c9",
  "En Prorroga": "#ffcdd2",
  Nuevas: "#ffcdd2",
  "Cancelacion Directa": "#ffcdd2",
  "-": "#ffcdd2",
};

const AltoDataTable = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [keyWord, setKeytoSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isELoading, setIsELoading] = useState(false);
  const [excelData, setExcelData] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(null);
  const [opena, setOpenA] = useState(null);
  const [isAF, setIsAF] = useState(false);
  const [advancedS, setAdvancedS] = useState({
    fecha_alta: "",
    fecha_vencimiento: "",
    titular: "",
    banco: "",
    pedido: "",
    email: "",
    telefono: "",
    pais: "",
    estado: "",
    ciudad: "",
    delegacion: "",
    cp: "",
    metodo: 0,
    nombre_producto: "",
    sku: "",
    status_fl: 0,
    status_ar: 0,
    status_ac: 0,
    status_lo: 0,
  });
  const [idop, setIdOp] = useState(0);
  const [message, setMessage] = useState("");
  const [observation, setObservation] = useState("");
  const [selectedFile, setSelectedFile] = useState([]);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [openmodal, setOpenModal] = useState({
    cancelacion: false,
    rechazar: false,
    liberar: false,
    regresar: false,
    mensaje: false,
    nota: false,
    reembolso: false,
  });
  const [confalert, setConfAlert] = useState({
    severidad: "success",
    mensaje: "El mensaje fue agregado con éxito",
  });

  const handleChangeAdvancedS = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setAdvancedS((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
  };

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  const handleOpenA = (severidad, mensaje) => {
    setConfAlert({ severidad: severidad, mensaje: mensaje });
    setOpenA(true);
  };

  const handleCloseA = () => {
    setOpenA(false);
  };

  const handleChangeMessa = (event) => {
    setMessage(event.target.value);
  };

  const handleChangeObser = (event) => {
    setObservation(event.target.value);
  };

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setOpen(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(null);
  };

  const handleClickOpenModal = (id, name) => {
    setIdOp(id);
    setOpenModal((prevalue) => {
      return {
        ...prevalue,
        [name]: true,
      };
    });
  };

  const handleCloseMD = () => {
    setMessage("");
    setObservation("");
    setSelectedFile([]);
    setIsFilePicked(false);
    setIdOp(0);
    setOpenModal({
      cancelacion: false,
      rechazar: false,
      liberar: false,
      regresar: false,
      mensaje: false,
      nota: false,
      reembolso: false,
    });
  };

  const handleSaveMessage = async () => {
    try {
      setIsLoading(true);
      if (idop && message) {
        await saveMessage(idop, user.data.awsid, message);
        handleCloseMD();
        handleOpenA("success", "El mensaje fue agregado con éxito");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveInfoHR = async (event) => {
    let val = event.target.value;
    try {
      setIsLoading(true);
      if ((idop && observation) || (idop && isFilePicked)) {
        if (val === "cancelacion")
          await cancelHR(idop, user.data.awsid, observation);
        if (val === "rechazar")
          await rejectHR(idop, user.data.awsid, observation);
        if (val === "liberar")
          await releaseToLHR(idop, user.data.awsid, observation);
        if (val === "regresar")
          await returnHR(idop, user.data.awsid, observation);
        if (val === "reembolso")
          await uploadFilesRefundHR(idop, user.data.awsid, selectedFile);
        if (val === "nota")
          await creditNoteHR(idop, user.data.awsid, observation);
        handleCloseMD();
        handleOpenA("success", "Cambios realizados");
        refreshPage();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelHR = async () => {
    try {
      setIsLoading(true);
      if (idop && observation) {
        await cancelHR(idop, user.data.awsid, observation);
        handleCloseMD();
        handleOpenA("success", "Cancelación realizada");
        refreshPage();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const refreshPage = () => {
    const current = location.pathname;
    navigate(`/reload`, { replace: true });
    setTimeout(() => {
      navigate(current, { replace: true });
    });
  };

  const getAllExcelData = async () => {
    try {
      setIsELoading(true);
      const data = await getExcelHR();
      setExcelData(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsELoading(false);
    }
  };

  const columns = [
    {
      field: "semaforo",
      headerName: "",
      flex: 0.25,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => (
        <CircleIcon
          fontSize="small"
          sx={{
            color:
              chipCirColors[params.row.sfnombre ? params.row.sfnombre : "-"],
          }}
        />
      ),
    },
    {
      field: "increment_id",
      headerName: "Orden",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "fecha_alta",
      headerName: "Fecha de alta",
      flex: 1,
      renderCell: (params) => (
        <Typography>
          <DateInTable dateString={params.row.fecha_alta} />
        </Typography>
      ),
    },
    {
      field: "ultimaActividad",
      headerName: "Actividad",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "slnombre",
      headerName: "Estatus logística",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Chip
          label={params.row.slnombre ?? "S/Estatus"}
          size="small"
          sx={{
            backgroundColor: chipLColors[params.row.slnombre ?? "S/Estatus"],
            color: "#000",
          }}
        />
      ),
    },
    {
      field: "acnombre",
      headerName: "Estatus AC",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Chip
          label={params.row.acnombre ?? "S/Estatus"}
          size="small"
          sx={{
            backgroundColor: chipACColors[params.row.acnombre ?? "S/Estatus"],
            color: "#000",
          }}
        />
      ),
    },
    {
      field: "arnombre",
      headerName: "Estatus AR",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Chip
          label={params.row.arnombre ?? "S/Estatus"}
          size="small"
          sx={{
            backgroundColor: chipARColors[params.row.arnombre ?? "S/Estatus"],
            color: "#000",
          }}
        />
      ),
    },
    {
      field: "sfnombre",
      headerName: "Estatus flujo",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Chip
          label={params.row.sfnombre}
          size="small"
          sx={{
            backgroundColor: chipFColors[params.row.sfnombre],
            color: "#000",
          }}
        />
      ),
    },
    {
      field: "accion",
      headerName: "Acciones",
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => (
        <>
          <Button
            variant="contained"
            size="small"
            endIcon={<KeyboardArrowDownIcon />}
            disableElevation
            aria-controls={open ? this : undefined}
            aria-haspopup="menu"
            onClick={(event) => handleClick(event, params.row.id_operacion)}
          >
            Acción
          </Button>
          <Menu
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            anchorEl={anchorEl}
            open={open === params.row.id_operacion ? true : false}
            onClose={handleClose}
          >
            <MenuItem>
              <Link
                to={`detail/${params.row.id_operacion}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "none",
                  color: "#000",
                  width: "100%",
                  height: "100%",
                }}
              >
                Ver detalle
              </Link>
            </MenuItem>
            {(params.row.sfnombre === "Aprobadas" ||
              params.row.sfnombre === "Nuevas" ||
              params.row.arnombre === "En Prorroga") && (
              <MenuItem
                onClick={() =>
                  handleClickOpenModal(params.row.id_operacion, "cancelacion")
                }
              >
                Cancelación directa
              </MenuItem>
            )}
            {params.row.arnombre === "En Espera de Nota de Credito" && (
              <MenuItem
                onClick={() =>
                  handleClickOpenModal(params.row.id_operacion, "nota")
                }
              >
                Nota de crédito
              </MenuItem>
            )}
            {params.row.arnombre === "En Espera de Reemboolso" && (
              <MenuItem
                onClick={() =>
                  handleClickOpenModal(params.row.id_operacion, "reembolso")
                }
              >
                Reemboolso
              </MenuItem>
            )}
            {params.row.sfnombre === "En espera de validacion" && (
              <>
                <MenuItem
                  onClick={() =>
                    handleClickOpenModal(params.row.id_operacion, "cancelacion")
                  }
                >
                  Cancelación directa
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    handleClickOpenModal(params.row.id_operacion, "rechazar")
                  }
                >
                  Rechazar
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    handleClickOpenModal(params.row.id_operacion, "liberar")
                  }
                >
                  Liberar
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    handleClickOpenModal(params.row.id_operacion, "regresar")
                  }
                >
                  Regresar a AC
                </MenuItem>
              </>
            )}
            <MenuItem
              onClick={() =>
                handleClickOpenModal(params.row.id_operacion, "mensaje")
              }
            >
              Mensaje
            </MenuItem>
          </Menu>
        </>
      ),
    },
  ];

  if (
    document.querySelector(
      ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
    )
  )
    document
      .querySelector(
        ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
      )
      .remove();

  useEffect(() => {
    let active = true;

    (async () => {
      let newRows = null;

      if (isAF) {
        setLoading(true);
        newRows = await searchDetailHR(page, advancedS);
      } else if (
        typeof keyWord === "undefined" ||
        keyWord === undefined ||
        keyWord.length === 0
      ) {
        setLoading(true);
        newRows = await getHR(page);
      } else if (keyWord.length >= 3) {
        setLoading(true);
        newRows = await searchHR(page, keyWord);
      } else active = false;

      if (!active) {
        return;
      }
      setTotal(newRows.total);
      setRows(newRows.data);
      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [page, keyWord, isAF, advancedS]);

  return (
    <>
      <Box>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Búsqueda avanzada</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              <Stack
                sx={{ m: 2 }}
                direction="row"
                spacing={3}
                autoComplete="off"
              >
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  type={"date"}
                  name="fecha_alta"
                  label="Fecha de alta"
                  variant="outlined"
                  value={advancedS.fecha_alta}
                  onChange={handleChangeAdvancedS}
                />
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  type={"date"}
                  name="fecha_vencimiento"
                  label="Fecha de vencimiento"
                  variant="outlined"
                  value={advancedS.fecha_vencimiento}
                  onChange={handleChangeAdvancedS}
                />
                <div style={{ width: "100%" }}></div>
              </Stack>
              <Stack
                sx={{ m: 2 }}
                direction="row"
                spacing={3}
                autoComplete="off"
              >
                <TextField
                  fullWidth
                  name="titular"
                  label="Titular de la tarjeta"
                  variant="outlined"
                  value={advancedS.titular}
                  onChange={handleChangeAdvancedS}
                />
                <TextField
                  fullWidth
                  name="banco"
                  label="Banco"
                  variant="outlined"
                  value={advancedS.banco}
                  onChange={handleChangeAdvancedS}
                />
                <div style={{ width: "100%" }}></div>
              </Stack>
              <Stack
                sx={{ m: 2 }}
                direction="row"
                spacing={3}
                autoComplete="off"
              >
                <TextField
                  fullWidth
                  name="pedido"
                  label="# de pedido"
                  variant="outlined"
                  value={advancedS.pedido}
                  onChange={handleChangeAdvancedS}
                />
                <TextField
                  fullWidth
                  name="email"
                  label="Correo electrónico"
                  variant="outlined"
                  value={advancedS.email}
                  onChange={handleChangeAdvancedS}
                />
                <TextField
                  fullWidth
                  name="telefono"
                  label="Número de teléfono"
                  variant="outlined"
                  value={advancedS.telefono}
                  onChange={handleChangeAdvancedS}
                />
              </Stack>
              <Stack
                sx={{ m: 2 }}
                direction="row"
                spacing={3}
                autoComplete="off"
              >
                <TextField
                  fullWidth
                  name="pais"
                  label="País"
                  variant="outlined"
                  value={advancedS.pais}
                  onChange={handleChangeAdvancedS}
                />
                <TextField
                  fullWidth
                  name="estado"
                  label="Estado"
                  variant="outlined"
                  value={advancedS.estado}
                  onChange={handleChangeAdvancedS}
                />
                <TextField
                  fullWidth
                  name="ciudad"
                  label="Ciudad"
                  variant="outlined"
                  value={advancedS.ciudad}
                  onChange={handleChangeAdvancedS}
                />
              </Stack>
              <Stack
                sx={{ m: 2 }}
                direction="row"
                spacing={3}
                autoComplete="off"
              >
                <TextField
                  fullWidth
                  name="delegacion"
                  label="Delegación"
                  variant="outlined"
                  value={advancedS.delegacion}
                  onChange={handleChangeAdvancedS}
                />
                <TextField
                  fullWidth
                  name="cp"
                  label="Código Postal"
                  variant="outlined"
                  value={advancedS.cp}
                  onChange={handleChangeAdvancedS}
                />
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel id="selectM" htmlFor="metodo">
                    Método de pago
                  </InputLabel>
                  <Select
                    id="metodo"
                    name="metodo"
                    label="Método de pago"
                    value={advancedS.metodo}
                    onChange={handleChangeAdvancedS}
                  >
                    <MenuItem value={0}>Todos</MenuItem>
                    <MenuItem value={2}>Visa/Master Card</MenuItem>
                    <MenuItem value={3}>American Express</MenuItem>
                    <MenuItem value={10}>Paypal</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
              <Stack
                sx={{ m: 2 }}
                direction="row"
                spacing={3}
                autoComplete="off"
              >
                <TextField
                  fullWidth
                  name="producto"
                  label="Producto"
                  variant="outlined"
                  value={advancedS.producto}
                  onChange={handleChangeAdvancedS}
                />
                <TextField
                  fullWidth
                  name="sku"
                  label="SKU"
                  variant="outlined"
                  value={advancedS.sku}
                  onChange={handleChangeAdvancedS}
                />
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel id="selectEF" htmlFor="status_fl">
                    Estatus flujo
                  </InputLabel>
                  <Select
                    id="status_fl"
                    name="status_fl"
                    label="Estatus flujo"
                    value={advancedS.status_fl}
                    onChange={handleChangeAdvancedS}
                  >
                    <MenuItem value={0}>Todos</MenuItem>
                    <MenuItem value={1}>Nuevas</MenuItem>
                    <MenuItem value={3}>Rechazadas</MenuItem>
                    <MenuItem value={4}>Aprobadas</MenuItem>
                    <MenuItem value={11}>Incompleta</MenuItem>
                    <MenuItem value={14}>Cerrada</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
              <Stack
                sx={{ m: 2 }}
                direction="row"
                spacing={3}
                autoComplete="off"
              >
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel id="selectEAR" htmlFor="status_ar">
                    Estatus Alto Riesgo
                  </InputLabel>
                  <Select
                    id="status_ar"
                    name="status_ar"
                    label="Estatus Alto Riesgo"
                    value={advancedS.status_ar}
                    onChange={handleChangeAdvancedS}
                  >
                    <MenuItem value={0}>Todos</MenuItem>
                    <MenuItem value={2}>En espera de validación</MenuItem>
                    <MenuItem value={13}>En Espera de Nota de Crédito</MenuItem>
                    <MenuItem value={15}>En Espera de Reemboolso</MenuItem>
                    <MenuItem value={14}>Cerrada</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel id="selectEAC" htmlFor="status_ac">
                    Estatus Atención al cliente
                  </InputLabel>
                  <Select
                    id="status_ac"
                    name="status_ac"
                    label="Estatus Atención al cliente"
                    value={advancedS.status_ac}
                    onChange={handleChangeAdvancedS}
                  >
                    <MenuItem value={0}>Todos</MenuItem>
                    <MenuItem value={9}>Enterado Atención a Clientes</MenuItem>
                    <MenuItem value={10}>Con evidencia</MenuItem>
                    <MenuItem value={12}>En Prórroga</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel id="selectEL" htmlFor="status_lo">
                    Estatus Logística
                  </InputLabel>
                  <Select
                    id="status_lo"
                    name="status_lo"
                    label="Estatus Logística"
                    value={advancedS.status_lo}
                    onChange={handleChangeAdvancedS}
                  >
                    <MenuItem value={0}>Todos</MenuItem>
                    <MenuItem value={6}>Enterado Logística</MenuItem>
                    <MenuItem value={7}>Detenido</MenuItem>
                    <MenuItem value={8}>En ruta</MenuItem>
                    <MenuItem value={16}>Entregado</MenuItem>
                    <MenuItem value={19}>Confirmado cancelación</MenuItem>
                    <MenuItem value={20}>Confirmado liberación</MenuItem>
                  </Select>
                </FormControl>
              </Stack>

              <div style={{ textAlign: "right", marginTop: "2em" }}>
                <LoadingButton
                  startIcon={<SearchIcon />}
                  sx={{ mx: 2 }}
                  loading={isLoading}
                  size="medium"
                  variant="contained"
                  color="success"
                  onClick={() => setIsAF(true)}
                  disableElevation
                >
                  Consultar
                </LoadingButton>
              </div>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Grid container sx={{ my: 2 }}>
            <Grid item md={4} sx={{ px: 2 }}>
              <Stack direction="row" spacing={1}>
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  label={"Buscar Alto Riesgo..."}
                  value={keyWord}
                  onChange={(e) => {
                    setIsAF(false);
                    setKeytoSearch(e.target.value);
                  }}
                />
              </Stack>
            </Grid>
            <Grid item md sx={{ px: 2, textAlign: "right" }}>
              {!excelData && (
                <LoadingButton
                  loading={isELoading}
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={getAllExcelData}
                >
                  Generar excel
                </LoadingButton>
              )}
              {excelData && (
                <ExcelFile excelData={excelData} fileName={"AltoRiesgo"} />
              )}
            </Grid>
          </Grid>
        </Box>
        <Box style={{ height: 635, width: "100%" }}>
          <DataGridPro
            rows={rows}
            columns={columns}
            pagination
            pageSize={10}
            rowsPerPageOptions={[10]}
            getRowId={(row) => row.id_operacion}
            rowCount={total}
            paginationMode="server"
            onPageChange={(newPage) => {
              setPage(newPage);
            }}
            loading={loading}
            disableColumnMenu
            disableSelectionOnClick
          />
        </Box>
      </Box>

      <Dialog
        open={openmodal.mensaje}
        onClose={handleCloseMD}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Agregar mensaje</DialogTitle>
        <DialogContent>
          <TextField
            multiline
            autoFocus
            margin="dense"
            label="Escribe tu mensaje"
            fullWidth
            variant="standard"
            maxRows={4}
            value={message}
            onChange={handleChangeMessa}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMD}>Cancelar</Button>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            color="info"
            startIcon={<SaveIcon />}
            onClick={handleSaveMessage}
          >
            Guardar
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openmodal.cancelacion}
        onClose={handleCloseMD}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Cancelar operación</DialogTitle>
        <DialogContent>
          <TextField
            multiline
            autoFocus
            margin="dense"
            label="Escribe tus observaciones"
            fullWidth
            variant="standard"
            maxRows={4}
            value={observation}
            onChange={handleChangeObser}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMD}>Cancelar</Button>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            color="info"
            startIcon={<SaveIcon />}
            value="cancelacion"
            onClick={handleSaveInfoHR}
          >
            Guardar cambios
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openmodal.rechazar}
        onClose={handleCloseMD}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Rechazar operación</DialogTitle>
        <DialogContent>
          <TextField
            multiline
            autoFocus
            margin="dense"
            label="Escribe tus observaciones"
            fullWidth
            variant="standard"
            maxRows={4}
            value={observation}
            onChange={handleChangeObser}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMD}>Cancelar</Button>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            color="info"
            startIcon={<SaveIcon />}
            value="rechazar"
            onClick={handleSaveInfoHR}
          >
            Guardar cambios
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openmodal.liberar}
        onClose={handleCloseMD}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Liberar operación</DialogTitle>
        <DialogContent>
          <TextField
            multiline
            autoFocus
            margin="dense"
            label="Escribe tus observaciones"
            fullWidth
            variant="standard"
            maxRows={4}
            value={observation}
            onChange={handleChangeObser}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMD}>Cancelar</Button>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            color="info"
            startIcon={<SaveIcon />}
            value="liberar"
            onClick={handleSaveInfoHR}
          >
            Guardar cambios
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openmodal.regresar}
        onClose={handleCloseMD}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Regresar a AC</DialogTitle>
        <DialogContent>
          <TextField
            multiline
            autoFocus
            margin="dense"
            label="Escribe tus observaciones"
            fullWidth
            variant="standard"
            maxRows={4}
            value={observation}
            onChange={handleChangeObser}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMD}>Cancelar</Button>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            color="info"
            startIcon={<SaveIcon />}
            value="regresar"
            onClick={handleSaveInfoHR}
          >
            Guardar cambios
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openmodal.reembolso}
        onClose={handleCloseMD}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Cargar evidencia reembolso</DialogTitle>
        <DialogContent>
          <Typography align="center" sx={{ mb: 2 }}>
            {isFilePicked ? selectedFile.name : "Ningún archivo seleccionado"}
          </Typography>
          <Button
            align="center"
            variant="contained"
            component="label"
            sx={{ mb: 4 }}
            endIcon={<UploadIcon />}
          >
            {isFilePicked ? "Elegir otro archivo" : "Elegir archivo"}
            <input
              hidden
              accept="image/*,.pdf"
              type="file"
              onChange={changeHandler}
            />
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMD}>Cancelar</Button>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            color="info"
            startIcon={<SaveIcon />}
            value="reembolso"
            onClick={handleSaveInfoHR}
            disabled={!isFilePicked}
          >
            Guardar archivo
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openmodal.nota}
        onClose={handleCloseMD}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Agregar Nota</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Escribe la nota de crédito"
            fullWidth
            variant="standard"
            value={observation}
            onChange={handleChangeObser}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMD}>Cancelar</Button>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            color="info"
            startIcon={<SaveIcon />}
            value="nota"
            onClick={handleSaveInfoHR}
            disabled={!observation}
          >
            Guardar cambios
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Toast
        open={opena}
        message={confalert.mensaje}
        type={confalert.severidad}
        onClose={handleCloseA}
      />
    </>
  );
};

export default AltoDataTable;
