import {
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import dayjs from "dayjs";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useAuth } from "../../../context/AuthContext";
import { getAclaracionById } from "../../../utils/trazabilidad/aclaraciones/aclaraciones";

const ItemDetail = ({ title, value }) => {
  return (
    <Grid item md={4}>
      <Box>
        <Typography sx={{ fontWeight: "bold" }}>{title}</Typography>
        <Typography>{value}</Typography>
      </Box>
    </Grid>
  );
};

const DetallePedido = ({ aclaracionId }) => {
  const auth = useAuth();

  const [isLoading, setIsLoading] = useState(false);

  const [detalle, setDetalle] = useState(null);

  useEffect(() => {
    const getDetallePedido = async () => {
      try {
        setIsLoading(true);
        const res = await getAclaracionById(aclaracionId, auth.user.data.awsid);
        setDetalle(res);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    getDetallePedido();
  }, [aclaracionId, auth.user.data.awsid]);

  return (
    <motion.div
      initial={{ y: 20 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Card sx={{ mb: 2 }}>
        {isLoading && <LinearProgress />}
        <CardContent>
          <Typography variant="h5">Aclaracion {aclaracionId}</Typography>
          {detalle && (
            <Grid container spacing={3} sx={{ mt: 1 }}>
              <ItemDetail title="Número de pedido" value={detalle.id_orden} />
              <ItemDetail
                title="Nombre de cliente"
                value={`${detalle.cname} ${detalle.lastname ?? ""} ${
                  detalle.mothersname ?? ""
                }`}
              />
              <ItemDetail title="Tipíficación" value={detalle.nombreTipo} />
              <ItemDetail
                title="Fecha de creación"
                value={dayjs(detalle.created_at).format(
                  "DD MMM YYYY, hh:mm:ss A"
                )}
              />
              <ItemDetail title="Teléfono" value={detalle.telf} />
              <ItemDetail title="Carrier" value={detalle.shipping_carrier} />
              <ItemDetail
                title="Fecha de pago"
                value={dayjs(detalle.pdate).format("DD MMM YYYY, hh:mm:ss A")}
              />
              <ItemDetail title="Email" value={detalle.emailAux} />
              <ItemDetail
                title="Tipo de servicio"
                value={detalle.shipping_type}
              />
              <ItemDetail title="Nombre de la escuela" value={detalle.sname} />
              <ItemDetail
                title="Dirección de envio"
                value={`${detalle.shipping_street} ${
                  detalle.shipping_outside_number
                }, int. ${detalle.shipping_inside_number ?? ""} ${
                  detalle.shipping_suburb
                } ${detalle.shipping_delegation}`}
              />
              <ItemDetail title="Método de pago" value={detalle.paymethod} />
              <ItemDetail title="Motivo" value={detalle.motivo} />
            </Grid>
          )}
        </CardContent>
      </Card>
    </motion.div>
  );
};

export default DetallePedido;
