import { Box, Grid, Button, Stack, Paper, IconButton, Typography, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Snackbar, Alert } from "@mui/material";
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../../context/AuthContext";
import DateInTable from "../../general/DateInTable";
import DownloadIcon from '@mui/icons-material/Download';
import { getLabelsList, getOrderLabel, searchLabelsList, updateOCLabel } from "../../../utils/proveedores/asignarOC/asignarOC";
import { getItems } from "../../../utils/proveedores/etiquetasProveedor/etiquetasProveedor";
import { getDataForCSV } from "../../../utils/proveedores/pedidosRecibidos/pedidosRecibidos";

function DetailPanelContentChild({ row: rowProp }) {
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);

    const columns = [
        { field: "product_sku", headerName: "SKU", flex: 1, align: "center", headerAlign: "center" },
        { field: "product_name", headerName: "Nombre del Producto", flex: 2, align: "center", headerAlign: "center" },
        { field: "estudiante", headerName: "Estudiante", flex: 1, align: "center", headerAlign: "center", valueGetter: ({ row }) => (`${row.student_name}`) },
        { field: "quantity", headerName: "Cantidad", flex: 1, align: "center", headerAlign: "center" },
    ];

    if(document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"))
        document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']").remove();

    useEffect(() => {

        let active = true;

        (async () => {
            let newRows = null;
            setLoading(true);
            newRows = await getItems(page, rowProp.increment_id);
            setTotal(newRows.total);
            setRows(newRows.data);
            setLoading(false);
        })();

        return () => {
            active = false;
        };

    }, [page]);

    return (
        <Stack sx={{ py: 2, height: '100%', boxSizing: 'border-box' }} direction="column" >
            <Paper sx={{ flex: 1, mx: 'auto', width: '90%', p: 1 }}>
                <Box style={{ height: 335, width: '100%' }}>
                    <DataGridPro
                        rows={rows}
                        columns={columns}
                        pagination
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        getRowId={((row) => (row.product_sku+'-'+row.student_name))}
                        rowCount={total}
                        paginationMode="server"
                        onPageChange={(newPage) => {
                            setPage(newPage);
                        }}
                        loading={loading}
                        disableColumnMenu
                        disableSelectionOnClick
                    />
                </Box>
            </Paper>
        </Stack>
    );
}

function DetailPanelContent({ row: rowProp }) {
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);

    const columns = [
        { field: "increment_id", headerName: "Pedido", flex: 1, align: "center", headerAlign: "center" },
        { field: "comprador", headerName: "Comprador", flex: 1, align: "center", headerAlign: "center", valueGetter: ({ row }) => (`${row.customer_name} ${row.customer_lastname} ${row.customer_mothersname}`) },
        { field: "csv", headerName: "CSV", flex: 1, align: "center", headerAlign: "center", sortable: false, renderCell: ({row}) => (
            <IconButton color="primary" size="small" onClick={() => DownloadCsvItems(row.increment_id)}><DownloadIcon /></IconButton>
        )}
    ];

    if(document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"))
        document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']").remove();

    useEffect(() => {

        let active = true;

        (async () => {
            let newRows = null;
            setLoading(true);
            newRows = await getOrderLabel(rowProp.id_pv_order_label);
            setTotal(newRows.total);
            setRows(newRows.data);
            setLoading(false);
        })();

        return () => {
            active = false;
        };

    }, [page]);

    function objectToCsvRow(obj, columns) {
        return columns.map(col => {
          const value = obj[col] || '';
          if (col === "Increment Id") {
            return value;
          }
          return `"${value.toString().replace(/"/g, '""')}"`;
        }).join(',');
    }

    function arrayToCsv(data, columns) {
        const header = columns.map(col => (col === "Increment Id" ? col : `"${col}"`)).join(',');
        const rows = data.map(obj => objectToCsvRow(obj, columns));
        return [header, ...rows].join('\n');
      }
      

    const DownloadCsvItems = async (id) => {
        const DataCsv = await getDataForCSV(id, "items");
        if (DataCsv.length === 0 ) {
            return console.log("No Data");
        }
        const {data, columns} = DataCsv;
    
        const csv = arrayToCsv(data, columns);
        const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csv], { type: 'text/csv' });

        const url = URL.createObjectURL(blob);
    
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `Itemsfromorder${id}.csv`;
    
        document.body.appendChild(a);
        a.click();
    
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      };

    return (
        <Stack sx={{ py: 2, height: '100%', boxSizing: 'border-box' }} direction="column" >
            <Paper sx={{ flex: 1, mx: 'auto', width: '100%', p: 1 }}>
                <Box style={{ height: 335, width: '100%' }}>
                    <DataGridPro
                        rows={rows}
                        columns={columns}
                        pagination
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        getRowId={((row) => (row.id_pv_order_label+'-'+row.increment_id))}
                        rowCount={total}
                        paginationMode="server"
                        onPageChange={(newPage) => {
                            setPage(newPage);
                        }}
                        loading={loading}
                        disableColumnMenu
                        disableSelectionOnClick
                        getDetailPanelHeight={() => 'auto'}
                        getDetailPanelContent={({ row }) => (<DetailPanelContentChild row={row} />)}
                    />
                </Box>
            </Paper>
        </Stack>
    );
}

const AsignarOCDataTable = () => {
    const { user } = useAuth();
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [keyWord, setKeytoSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const textFieldRefs = useRef({}); 
    const [showModal, setShowModal] = useState(false);
    const [currentData, setCurrentData] = useState(null);
    const [fieldErrors, setFieldErrors] = useState({});
    const [reload, setReload] = useState(0);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [alertType, setAlertType] = useState('success');
    const [alertMessage, setAlertMessage] = useState('');

    const columns = [
        { field: "id_pv_order_label", headerName: "ID", flex: 0.75, align: "center", headerAlign: "center" },
        { field: "created_at", headerName: "Fecha", flex: 1, renderCell: (params) => (
            <Typography><DateInTable dateString={params.row.created_at} /></Typography>
        )},
        { field: "id_pv_label_status", headerName: "Estatus", flex: 0.6, align: "center", headerAlign: "center", renderCell: ({ row }) => (row.status && <Button variant="contained" size="small" color="success" sx={{lineHeight: 1, fontSize: "0.7rem", minWidth: 0}}>{row.status}</Button>) },
        { field: "formato", headerName: "Formato OC", flex: 0.5, align: "center", headerAlign: "center", sortable: false, renderCell: ({ row }) => (
            <IconButton onClick={() => DownloadCsvFormatoOc(row.id_pv_order_label)} color="primary" size="small"><DownloadIcon /></IconButton>
        )},
        { field: "orden", headerName: "Orden de compra", flex: 1, align: "center", headerAlign: "center", renderCell: (params) => (
            <>{params.row.orden_compra ? 
                (`${params.row.orden_compra}`)
                : (
            <TextField 
                id="outlined-basic" 
                label= {fieldErrors[params.row.id_pv_order_label] ? fieldErrors[params.row.id_pv_order_label] : "Orden de compra"} 
                variant="outlined" 
                inputRef={ref => textFieldRefs.current[params.row.id_pv_order_label] = ref} 
                error={Boolean(fieldErrors[params.row.id_pv_order_label])}/>
            )}</>
        )},
        { field: "accion", headerName: "Accion", flex: 0.4, align: "center", headerAlign: "center", sortable: false, renderCell: ({row}) => (
            <>{!row.orden_compra ? (<Button variant="contained" size="small" onClick={() => handleSaveOCLabel(row.id_pv_order_label)}>Guardar</Button>) : (<Button variant="contained" size="small" disabled>--</Button>)}</>
        )},
    ];

    if(document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"))
        document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']").remove();

    useEffect(() => {

        let active = true;
        setRows([]);

        (async () => {

            let newRows = null;

            if(typeof keyWord === 'undefined' || keyWord === undefined || keyWord.length === 0){
                setLoading(true);
                newRows = await getLabelsList(page);
            }   
            else if(keyWord.length >= 3)
            {
                setLoading(true);
                newRows = await searchLabelsList(page, keyWord);
            }
            else active = false;

            if (!active) {
                return;
            }
            setTotal(newRows.total);
            setRows(newRows.data);
            setLoading(false);
        })();

        return () => {
            active = false;
        };

    }, [page, keyWord, reload]);

    const handleSaveOCLabel = (id) => {
        const inputValue = textFieldRefs.current[id].value;

        if (inputValue.length <= 1) {
            setFieldErrors(prevErrors => ({
                ...prevErrors,
                [id]: 'El campo esta vacio'
            }));
        } else {
            let dataSave = {
                idLabel: id,
                payOr: inputValue,
                idUser: user.data.awsid
            }
            setCurrentData(dataSave);
            setShowModal(true);
            setFieldErrors(prevErrors => ({
                ...prevErrors,
                [id]: null
            }));
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleAcceptModal = async () => {
        setShowModal(false);
        let resSave = await updateOCLabel(currentData);
        if (resSave) {
            setAlertType('success');
            setAlertMessage('Datos guardados correctamente');
        } else {
            setAlertType('error');
            setAlertMessage('Hubo un error al guardar los datos');
        }
        setOpenSnackbar(true);
        setRows([]);
        setReload(prevValue => (prevValue === 0 ? 1 : 0));
    };
    
    function objectToCsvRow(obj, columns) {
        return columns.map(col => {
          const value = obj[col] || '';
          if (col === "Nombre del producto") {
            return `"${value.toString().replace(/"/g, '""')}"`;
          }
          return value.toString();
        }).join(',');
      }
      
      function arrayToCsv(data, columns) {
        const header = columns.join(',');
        const rows = data.map(obj => objectToCsvRow(obj, columns));
        return [header, ...rows].join('\n');
      }
      
      const DownloadCsvFormatoOc = async (id) => {
        const DataCsv = await getDataForCSV(id, "formatooc");
        console.log(DataCsv);
        if (DataCsv.length === 0) {
          return console.log("No Data");
        }
      
        const { data, columns } = DataCsv;

        const lastObj = data[data.length - 1];
        let specialCsv = "";
        if (lastObj && lastObj.cadena) {
          specialCsv = `"${lastObj.cadena}"`;
          data.pop();
        }
      
        const mainDataCsv = arrayToCsv(data, columns);
      
        const finalCsv = specialCsv ? [mainDataCsv, specialCsv].join('\n') : mainDataCsv;
      
        const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), finalCsv], { type: 'text/csv' });

        const url = URL.createObjectURL(blob);
      
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `formatooc${id}.csv`;
      
        document.body.appendChild(a);
        a.click();
      
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      };
      
      const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };


    return (
        <>
          <Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Grid container sx={{ my:2 }}>
                    <Grid item md={4} sx={{ px:2 }}>
                        <Stack direction="row" spacing={1}>
                            <TextField size="small" variant="outlined" fullWidth label={'Buscar...'} value={keyWord} onChange={(e) => {setKeytoSearch(e.target.value);}} />
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
            <Box style={{ height: 635, width: '100%' }}>
                <DataGridPro
                    rows={rows}
                    columns={columns}
                    pagination
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    getRowId={((row) => (row.id_pv_order_label))}
                    rowCount={total}
                    paginationMode="server"
                    onPageChange={(newPage) => {
                        setPage(newPage);
                    }}
                    loading={loading}
                    disableColumnMenu
                    disableSelectionOnClick
                    getDetailPanelHeight={() => 'auto'}
                    getDetailPanelContent={({ row }) => (<DetailPanelContent row={row} />)}
                />
            </Box>
        </Box>
        <Dialog open={showModal} onClose={handleCloseModal}>
            <DialogTitle>{"¿Estás seguro?"}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Estás a punto de guardar el valor: {currentData?.payOr}. ¿Deseas continuar?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseModal} color="primary">
                    Cancelar
                </Button>
                <Button onClick={handleAcceptModal} color="primary" autoFocus>
                    Aceptar
                </Button>
            </DialogActions>
        </Dialog>

        <Snackbar         
            anchorOrigin={{
            vertical: "top",
            horizontal: "right",
            }} 
            open={openSnackbar} 
            autoHideDuration={6000} 
            onClose={handleCloseSnackbar}
        >
            <Alert onClose={handleCloseSnackbar} severity={alertType} variant="filled">
                {alertMessage}
            </Alert>
        </Snackbar>
        </>

    );
};

export default AsignarOCDataTable;