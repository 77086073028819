import { Card, Typography } from "@mui/material";
import { Box } from "@mui/system";
import img from "../assets/empty.svg";

const NotFound = () => {
  return (
    <Card sx={{ py: 8, display: "flex", justifyContent: "space-evenly" }}>
      <img
        src={img}
        alt="404 Page not found"
        width="500px"
      />
      <Box
        sx={{
          color: "#041C32",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box textAlign="center">
          <Typography variant="h1" sx={{ fontWeight: "bold" }}>
            404
          </Typography>
          <Typography variant="h4">Página no encontrada</Typography>
        </Box>
      </Box>
    </Card>
  );
};

export default NotFound;
