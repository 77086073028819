import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { forwardRef, useEffect, useState } from "react";
import { useResourses } from "../../context/ResourcesContext";
import dayjs from "dayjs";
import { getInteractions } from "../../utils/crm/buscador";
import { useAuth } from "../../context/AuthContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { asociarCaso } from "../../utils/crm/asociaciones";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CrearAsesoriaDialog = ({ open, setOpen, details }) => {
  const { colleges, topics } = useResourses();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [college, setCollege] = useState(0);
  const [topic, setTopic] = useState(0);
  const [solution, setSolution] = useState(
    details.channel === "Chat" ? details.description : ""
  );
  const [isLoading, setIsLoading] = useState(false);
  const [asociations, setAsosiations] = useState([]);
  const [interactions, setInteractions] = useState([]);

  const closeDialog = () => {
    setCollege(0);
    setTopic(0);
    setAsosiations([]);
    setOpen(false);
  };

  const handleCreateAsesoria = async () => {
    setIsLoading(true);

    try {
      const formData = new FormData();

      formData.append("id_crm_main", details.id_crm_main);
      formData.append("solution", solution);
      formData.append("titulo", details.title ?? "Solucionado");
      formData.append("contacto", details.mail);
      formData.append("canal", details.channel);
      formData.append("idUser", user.data.awsid);
      formData.append("id_school", college);
      formData.append("id_tema", topic);

      await axios({
        url: `${process.env.REACT_APP_BASEURL}/crm/advisories/create`,
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      });

      closeDialog();
      navigate("../", { replace: true });
    } catch (error) {
      console.error(error.message);
    }

    setIsLoading(false);
  };

  const handleCheckboxChange = async (id) => {
    const checked = asociations.includes(id);

    try {
      if (checked) {
        return;
      } else {
        await asociarCaso({
          idMainFather: details.id_crm_main,
          idMainChild: id,
          idUrl: details.id_crm_main,
          idUser: user.data.awsid,
        });
        setAsosiations([...asociations, id]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const columns = [
    {
      field: "date_register",
      headerName: "Fecha Registro",
      minWidth: 200,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            {dayjs(params.row.date_register).format("DD MMM YYYY, hh:mm A")}
          </Typography>
          <Typography variant="caption">
            {dayjs(params.row.date_register).fromNow()}
          </Typography>
        </Box>
      ),
    },
    {
      field: "type",
      headerName: "Tipo",
      width: 110,
      renderCell: (params) => (
        <Chip
          label={params.row.type}
          size="small"
          color={params.row.type === "Online" ? "success" : "error"}
          variant="outlined"
        />
      ),
      disableColumnMenu: true,
    },
    {
      field: "title",
      headerName: "Titulo",
      minWidth: 300,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "channel",
      headerName: "Canal",
      width: 120,
      disableColumnMenu: true,
    },
    {
      field: "name_s",
      headerName: "Status",
      width: 120,
      renderCell: (params) => (
        <Chip
          label={params.row.name_s}
          size="small"
          color={params.row.name_s === "Nuevo" ? "success" : "info"}
        />
      ),
      disableColumnMenu: true,
    },
    {
      field: "action",
      headerName: "Asociar",
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <Checkbox
          checked={asociations.includes(params.row.id_crm_main)}
          onChange={() => handleCheckboxChange(params.row.id_crm_main)}
        />
      ),
      disableColumnMenu: true,
    },
  ];
  useEffect(() => {
    let isCancelled = false;

    const getRelatedInteractions = async () => {
      try {
        const newInteractions = await getInteractions(details.contact);
        if (!isCancelled) setInteractions(newInteractions);
      } catch (error) {
        console.error(error.message);
      }
    };

    getRelatedInteractions();

    return () => {
      isCancelled = true;
    };
  }, [details.contact]);

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      TransitionComponent={Transition}
      scroll="body"
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle>Crear asesoria</DialogTitle>
      <DialogContent>
        <Box>
          <Autocomplete
            disablePortal
            isOptionEqualToValue={(e) => true}
            options={colleges.map((c) => ({ id: c.id, label: c.name }))}
            sx={{
              width: "100%",
              mb: 2,
            }}
            onChange={(e, val) => {
              setCollege(+val.id);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Colegio"
                helperText="Selecciona el colegio"
                variant="filled"
              />
            )}
          />
          <Autocomplete
            disablePortal
            isOptionEqualToValue={(e) => true}
            options={topics
              .filter((t) => t.nombreCategoria === "Asesoría a Clientes")
              .map((t) => ({ id: t.id_tema, label: t.nombreTema }))}
            sx={{
              width: "100%",
              mb: 2,
            }}
            onChange={(e, val) => {
              setTopic(+val.id);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Tema"
                helperText="Selecciona el tema"
                variant="filled"
              />
            )}
          />
          <TextField
            multiline
            fullWidth
            rows={5}
            margin="dense"
            label="Solucion"
            type="text"
            variant="filled"
            value={solution}
            onChange={(e) => setSolution(e.target.value)}
          />
        </Box>
        <Divider />
        {interactions?.length && (
          <div style={{ width: "100%" }}>
            <DataGrid
              columns={columns}
              rows={interactions}
              autoHeight
              disableSelectionOnClick
              disableColumnMenu
              disableColumnFilter
              sx={{ border: "none" }}
              getRowId={(row) => row.id_crm_main}
              hideFooter
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} disabled={isLoading}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          disableElevation
          onClick={handleCreateAsesoria}
          disabled={isLoading}
        >
          Crear asesoria
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CrearAsesoriaDialog;
