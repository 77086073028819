import CheckIcon from "@mui/icons-material/Check";
import MessageIcon from "@mui/icons-material/Message";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import AddIcon from "@mui/icons-material/Add";

export const moveTypes = {
  1: { icon: <VisibilityIcon />, color: "#607d8b" },
  2: { icon: <VisibilityIcon />, color: "#607d8b" },
  3: { icon: <VisibilityIcon />, color: "#607d8b" },
  4: { icon: <VisibilityIcon />, color: "#607d8b" },
  5: { icon: <VisibilityIcon />, color: "#607d8b" },
  6: { icon: <VisibilityIcon />, color: "#607d8b" },
  7: { icon: <VisibilityIcon />, color: "#607d8b" },
  8: { icon: <VisibilityIcon />, color: "#607d8b" },
  9: { icon: <VisibilityIcon />, color: "#607d8b" },
  10: { icon: <AddLocationIcon />, color: "#ab47bc" },
  11: { icon: <AddLocationIcon />, color: "#ab47bc" },
  12: { icon: <MessageIcon />, color: "#2196f3" },
  13: { icon: <MessageIcon />, color: "#2196f3" },
  14: { icon: <MessageIcon />, color: "#2196f3" },
  15: { icon: <MessageIcon />, color: "#2196f3" },
  16: { icon: <MessageIcon />, color: "#2196f3" },
  17: { icon: <MessageIcon />, color: "#2196f3" },
  18: { icon: <MessageIcon />, color: "#2196f3" },
  19: { icon: <VisibilityIcon />, color: "#607d8b" },
  20: { icon: <AddIcon />, color: "#4caf50" },
  21: { icon: <AddIcon />, color: "#4caf50" },
  22: { icon: <CheckIcon />, color: "#4caf50" },
  23: { icon: <CheckIcon />, color: "#43a047" },
  24: { icon: <CheckIcon />, color: "#43a047" },
  25: { icon: <CheckIcon />, color: "#43a047" },
  26: { icon: <CheckIcon />, color: "#43a047" },
  27: { icon: <CheckIcon />, color: "#43a047" },
  28: { icon: <CheckIcon />, color: "#43a047" },
  29: { icon: <CheckIcon />, color: "#43a047" },
  30: { icon: <CheckIcon />, color: "#43a047" },
  31: { icon: <CheckIcon />, color: "#43a047" },
  32: { icon: <CheckIcon />, color: "#43a047" },
  33: { icon: <CheckIcon />, color: "#43a047" },
  34: { icon: <CheckIcon />, color: "#43a047" },
  35: { icon: <CheckIcon />, color: "#43a047" },
  36: { icon: <CheckIcon />, color: "#43a047" },
  37: { icon: <CheckIcon />, color: "#43a047" },
  38: { icon: <CheckIcon />, color: "#43a047" },
  39: { icon: <VisibilityIcon />, color: "#607d8b" },
  40: { icon: <VisibilityIcon />, color: "#607d8b" },
  41: { icon: <VisibilityIcon />, color: "#607d8b" },
  42: { icon: <VisibilityIcon />, color: "#607d8b" },
  43: { icon: <VisibilityIcon />, color: "#607d8b" },
  44: { icon: <VisibilityIcon />, color: "#607d8b" },
  45: { icon: <VisibilityIcon />, color: "#607d8b" },
  46: { icon: <VisibilityIcon />, color: "#607d8b" },
  47: { icon: <VisibilityIcon />, color: "#607d8b" },
  48: { icon: <VisibilityIcon />, color: "#607d8b" },
  49: { icon: <VisibilityIcon />, color: "#607d8b" },
  50: { icon: <VisibilityIcon />, color: "#607d8b" },
  51: { icon: <VisibilityIcon />, color: "#607d8b" },
  52: { icon: <VisibilityIcon />, color: "#607d8b" },
  53: { icon: <VisibilityIcon />, color: "#607d8b" },
  54: { icon: <VisibilityIcon />, color: "#607d8b" },
  55: { icon: <VisibilityIcon />, color: "#607d8b" },
  56: { icon: <VisibilityIcon />, color: "#607d8b" },
  57: { icon: <VisibilityIcon />, color: "#607d8b" },
  58: { icon: <VisibilityIcon />, color: "#607d8b" },
  59: { icon: <VisibilityIcon />, color: "#607d8b" },
  60: { icon: <VisibilityIcon />, color: "#607d8b" },
  61: { icon: <VisibilityIcon />, color: "#607d8b" },
  62: { icon: <VisibilityIcon />, color: "#607d8b" },
  63: { icon: <VisibilityIcon />, color: "#607d8b" },
  64: { icon: <VisibilityIcon />, color: "#607d8b" },
  65: { icon: <VisibilityIcon />, color: "#607d8b" },
};
