import { Box, Grid, Button, Stack, Paper, TextField, Chip, Table, TableBody, TableCell, TableHead, TableRow, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useEffect, useState } from "react";
import { useAuth } from "../../../context/AuthContext";
import { getRequest, searchRequest, sendMassiveMessages, getExcelRequest, sendSingleMessage } from "../../../utils/pagos/solicitud/solicitud";
import ExcelFile from "../../general/ExcelFile";
import SendIcon from '@mui/icons-material/Send';
import Toast from "../../general/Toast";

const chipColors = {
    "0": "#ffffff",
    "1": "#f39d13",
    "2": "#00a65a",
    "3": "#00a65a",
    "4": "#dd4b39"
};
const chipLabels = {
    "0": "",
    "1": "En espera de aprobación",
    "2": "Aprobado por dirección",
    "3": "Aprobado",
    "4": "No aprobado"
};

function DetailPanelContent({ row: rowProp }) {
    return (
        <Stack sx={{ py: 2, height: '100%', boxSizing: 'border-box' }} direction="column" >
            <Paper sx={{ flex: 1, mx: 'auto', width: '90%', p: 1 }}>
                <Stack direction="column" spacing={1} sx={{ height: 1 }}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ textAlign: 'center' }}>Codigo</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>Observaciones</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>Number</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>Referencia</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>Estado Banco</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>Cuenta Bancaria</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>Clabe Bancaria</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>Sub Total</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>Impuesto Aplicado</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{ border: 0 }} >
                                <TableCell sx={{ width: '15%', textAlign: 'center' }}>{rowProp.Codigo}</TableCell>
                                <TableCell sx={{ width: '10%', textAlign: 'center' }}>{rowProp.Observaciones}</TableCell>
                                <TableCell sx={{ width: '25%', textAlign: 'center' }}>{rowProp.Numero}</TableCell>
                                <TableCell sx={{ width: '25%', textAlign: 'center' }}>{rowProp.Referencia}</TableCell>
                                <TableCell sx={{ width: '25%', textAlign: 'center' }}>{rowProp.EstadoBanco}</TableCell>
                                <TableCell sx={{ width: '25%', textAlign: 'center' }}>{rowProp.CuentaBancaria}</TableCell>
                                <TableCell sx={{ width: '25%', textAlign: 'center' }}>{rowProp.ClabeBancaria}</TableCell>
                                <TableCell sx={{ width: '25%', textAlign: 'center' }}>{rowProp.SubTotal}</TableCell>
                                <TableCell sx={{ width: '25%', textAlign: 'center' }}>{rowProp.ImporteAplicado}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Stack>
            </Paper>
        </Stack>
    );
}

const SolicitudDataTable = () => {
    const { user } = useAuth();
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [keyWord, setKeytoSearch] = useState("");
    const [confalert, setConfAlert] = useState({ severidad: 'success', mensaje: '' });
    const [selectionModel, setSelectionModel] = useState([]);
    const [excelData, setExcelData] = useState(null);

    const [DocEntry, setDocEntry] = useState("");
    const [Numero, setNumero] = useState("");
    const [MontoPagar, setMontoPagar] = useState("");


    const [loading, setLoading] = useState(false); //Loading Index
    const [isELoading, setIsELoading] = useState(false); //Loading Excel
    const [isMmLoading, setIsMmLoading] = useState(false); //Loading Massive Message
    const [isSmLoading, setIsSmLoading] = useState(false); //Loading Single Message
    
    //----------------------------Single------------------------------------
    const [openSingle, setOpenSingle] = useState(false);

    const handleOpenSingle = () => {
        setOpenSingle(true);
    };
    const handleCloseSingle = () => {
        setDocEntry("");
        setNumero("");
        setMontoPagar("");
        setOpenSingle(false);
    };
    //-----------------------Massive-----------------------------------------
    const [openMassive, setOpenMassive] = useState(false);

    const handleOpenMassive = () => {
        if (selectionModel.length === 0) {
            setConfAlert({ severidad: 'warning', mensaje: 'Debe seleccionar al menos una fila de la tabla' });
            handleOpenAlert();
        }
        else
            setOpenMassive(true);
    };

    const handleCloseMassive = () => {
        setOpenMassive(false);
    };
    //------------------------Alert-----------------------------------
    const [openAlert, setOpenAlert] = useState(false);

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };
    const handleCloseAlert = () => {
        setOpenAlert(false);
    };
    //----------------------------------------------------------------
    const handleSaveMessage = async () => {
        try {
            setIsMmLoading(true);
            if (selectionModel.length > 0) { //&& user.role.idProfile === 1 ||  user.role.idProfile === 25 || user.role.idProfile === 53 
                let aux = [];
                let type = 2;
                selectionModel.forEach(item => {
                    let aux2 = item.split("-");
                    aux2[3] = 1;
                    let aux3 = {
                        "docEntry": parseInt(aux2[0]),
                        "number": parseInt(aux2[1]),
                        "montoPagar": parseInt(aux2[2]),
                        "ifReturn": parseInt(aux2[3])
                    };
                    aux.push(aux3);
                })
                let response = await sendMassiveMessages(user.data.awsid, type, aux);
                handleCloseMassive();
                if (response)
                    setConfAlert({ severidad: 'success', mensaje: 'Solicitudes enviadas con éxito' });
                else
                    setConfAlert({ severidad: 'error', mensaje: 'No pudo enviarse las solicitudes, intente más tarde' });
                handleOpenAlert(true);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsMmLoading(false);
        }
    };
    //----------------------------------------------------------------
    const handleSaveMessageSingle = async () => {
        try {
            setIsSmLoading(true);
                let aux = [];
                let type = 2;
                let aux2 = {
                    "docEntry": DocEntry,
                    "number": Numero,
                    "montoPagar": MontoPagar,
                    "ifReturn": 1
                };
                aux.push(aux2);

                let response = await sendSingleMessage(user.data.awsid, type, aux);
                handleCloseSingle();
                if (response)
                    setConfAlert({ severidad: 'success', mensaje: 'Solicitud enviada con éxito' });
                else
                    setConfAlert({ severidad: 'error', mensaje: 'No pudo enviar la solicitud, intente más tarde' });
                handleOpenAlert(true);
        } catch (error) {
            console.error(error);
        } finally {
            setIsSmLoading(false);
        }
    };
    //----------------------------------------------------------------
    const handleGetSolicitud = async (DocEntry, Numero, MontoPagar) => {
        setDocEntry(DocEntry);
        setNumero(Numero);
        setMontoPagar(MontoPagar);
        handleOpenSingle();
    }
    //----------------------------------------------------------------
    const getAllExcelData = async () => {
        try {
            setIsELoading(true);
            const data = await getExcelRequest(user.data.awsid);
            setExcelData(data);
        } catch (error) {
            console.error(error);
        } finally {
            setIsELoading(false);
        }
    };
    //------------------------------DetailMassiveMessage----------------------------------
    function DetailMassiveMessage() {
        let aux = [];
        let aux2;
        let aux3;
        if (selectionModel.length > 0) { //&& user.role.idProfile === 1 ||  user.role.idProfile === 25 || user.role.idProfile === 53 
            selectionModel.forEach(item => {
                aux2 = item.split("-");
                aux3 = {
                        "docEntry": parseInt(aux2[0]),
                        "number": parseInt(aux2[1]),
                        "montoPagar": aux2[2],
                    };
                aux.push(aux3);
                console.log(aux2);
            })
        } return (<>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ textAlign: 'center' }}>
                            DocEntry
                        </TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>
                            Numero
                        </TableCell >
                        <TableCell sx={{ textAlign: 'center' }}>
                            Monto a Pagar
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {aux.map((elemento) => (<>
                            <TableRow>
                                <TableCell sx={{ alignItems: 'center' }}><TextField disabled fullWidth value={elemento.docEntry}/></TableCell>
                                <TableCell sx={{ textAlign: 'center' }}><TextField disabled fullWidth value={elemento.number}/></TableCell>
                                <TableCell sx={{ textAlign: 'center' }}><TextField disabled fullWidth value={elemento.montoPagar} /></TableCell>
                            </TableRow>
                        </>
                        ))}
                </TableBody>
            </Table>
        </>
        );
    };

    //----------------------------------------------------------------
    const columns = [
        { field: "DocEntry", headerName: "DocEntry", flex: 0.8, align: "center", headerAlign: "center" },
        { field: "Nombre", headerName: "Nombre", flex: 0.8, align: "center", headerAlign: "center" },
        { field: "MontoPagar", headerName: "Monto a Pagar", flex: 0.9, align: "center", headerAlign: "center" },
        {
            field: "status", headerName: "Estatus", flex: 0.9, align: "center", headerAlign: "center", renderCell: (params) => (
                <Chip label={chipLabels[params.row.status]} size="small" sx={{ backgroundColor: chipColors[params.row.status], color: "#fff", textAlign: "center" }} />
            )
        },
        {
            field: "estatusDireccionG", headerName: "Dir. General", flex: 1, align: "center", headerAlign: "center", renderCell: (params) => (
                <Chip label={chipLabels[params.row.estatusDireccionG]} size="small" sx={{ backgroundColor: chipColors[params.row.estatusDireccionG], color: "#fff", }} />
            )
        },
        {
            field: "estatusDireccionF", headerName: "Dir. de Finanzas", flex: 1.1, align: "center", headerAlign: "center", renderCell: (params) => (
                <Chip label={chipLabels[params.row.estatusDireccionF]} size="small" sx={{ backgroundColor: chipColors[params.row.estatusDireccionF], color: "#fff", }} />
            )
        },
        {
            field: "estatusDireccionDDRC", headerName: "Dir. de DDRC", flex: 0.9, align: "center", headerAlign: "center", renderCell: (params) => (
                <Chip label={chipLabels[params.row.estatusDireccionDDRC]} size="small" sx={{ backgroundColor: chipColors[params.row.estatusDireccionDDRC], color: "#fff", }} />
            )
        },
        {
            field: "acciones", headerName: "Acciones", flex: 1.6, align: "center", headerAlign: "center", sortable: false,
            renderCell: (params) =>
            (<>{(params.row.status === 0) && (<Button  variant="contained" size="small" disableElevation onClick={()=>handleGetSolicitud(params.row.DocEntry, params.row.Numero, params.row.MontoPagar)}>Solicitar Aprobacion</Button>
            )}</>)
        },
    ];

    if (document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"))
        document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']").remove();

    useEffect(() => {
        let active = true;
        (async () => {
            let newRows = null;
            if (typeof keyWord === 'undefined' || keyWord === undefined || keyWord.length === 0) {
                setLoading(true);
                newRows = await getRequest(page, user.data.awsid);
            }
            else if (keyWord.length >= 5) {
                setLoading(true);
                newRows = await searchRequest(page, user.data.awsid, keyWord);
            }
            else active = false;
            if (!active) {
                return;
            }
            setTotal(newRows.total);
            setRows(newRows.data);
            setLoading(false);
        })();
        return () => {
            active = false;
        };
    }, [page, keyWord, user]);

    return (
        <>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Grid container sx={{ my: 2 }}>
                    <Grid item md={4} sx={{ px: 2 }}>
                        <Stack direction="row" spacing={1}>
                            <TextField size="small" variant="outlined" fullWidth label="Buscar Solicitud..." value={keyWord} onChange={(e) => setKeytoSearch(e.target.value)} />
                        </Stack>
                    </Grid>

                    <Grid item md sx={{ px: 2, textAlign: 'right' }}>
                        {!excelData && <LoadingButton loading={isELoading} variant="contained" color="info" onClick={getAllExcelData}>Generar excel</LoadingButton>}
                        {excelData && (<ExcelFile excelData={excelData} fileName={`Solicitud de Pagos`} />)}
                    </Grid>

                </Grid>
            </Box>

            <Box style={{ height: 635, width: '100%' }}>
                <DataGridPro
                    //components={{ Toolbar: GridToolbar }}
                    rows={rows}
                    columns={columns}
                    pagination
                    isRowSelectable={(params) => params.row.status === 0}
                    checkboxSelection
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    getRowId={((row) => (row.DocEntry + '-' + row.Numero + '-' + row.MontoPagar))}
                    rowCount={total}
                    paginationMode="server"
                    onPageChange={(newPage) => {
                        setPage(newPage);
                    }}
                    onSelectionModelChange={(newSelectionModel) => {
                        setSelectionModel(newSelectionModel);
                    }}
                    selectionModel={selectionModel}
                    loading={loading}
                    disableColumnMenu
                    disableSelectionOnClick
                    getDetailPanelHeight={() => 'auto'}
                    getDetailPanelContent={({ row }) => (<DetailPanelContent row={row} />)}
                />
            </Box>

            <LoadingButton loading={isMmLoading} sx={{ m: 2 }} variant="contained" color="info" endIcon={<SendIcon />} onClick={handleOpenMassive}>Enviar mensaje masivo</LoadingButton>

            <Dialog open={openMassive} onClose={handleCloseMassive} maxWidth="sm" fullWidth>
                <DialogTitle>Solicitar Aprobaciones</DialogTitle>
                <DialogContent>
                    <DetailMassiveMessage/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseMassive}>Cancelar</Button>
                    <LoadingButton loading={isMmLoading} variant="contained" color="info" loadingIndicator="Enviando…" endIcon={<SendIcon />} onClick={handleSaveMessage}>Enviar</LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog open={openSingle} onClose={handleCloseSingle} maxWidth="sm" fullWidth>
                <DialogTitle>Solicitar Aprobacion</DialogTitle>
                <DialogContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ textAlign: 'center' }}>
                                    DocEntry
                                </TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>
                                    Numero
                                </TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>
                                    Monto a Pagar
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <TextField disabled fullWidth value={DocEntry}/>
                                </TableCell>
                                <TableCell>
                                    <TextField disabled fullWidth value={Numero}/>
                                </TableCell>
                                <TableCell>
                                    <TextField disabled fullWidth value={MontoPagar}/>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSingle}>Cancelar</Button>
                    <LoadingButton loading={isSmLoading} variant="contained" color="info" loadingIndicator="Enviando…" endIcon={<SendIcon />} onClick={handleSaveMessageSingle}>Enviar</LoadingButton>
                </DialogActions>
            </Dialog>


            <Toast open={openAlert} message={confalert.mensaje} type={confalert.severidad} onClose={handleCloseAlert} />
        </>
    );
};

export default SolicitudDataTable;