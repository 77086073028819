import useSWR from "swr";
import {
  Button,
  Box,
  Chip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableFooter,
  TablePagination,
  TableBody,
  CircularProgress,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import "dayjs/locale/es";
import local from "dayjs/plugin/localizedFormat";
import relative from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import DateInTable from "../general/DateInTable";
import { searchClosedTicket } from "../../utils/crm/tickets";

dayjs.locale("es");
dayjs.extend(local);
dayjs.extend(relative);

const chipColors = {
  Cerrado: "#f44336",
  Canalizado: "#673ab7",
  Tomado: "#1e88e5",
  Nuevo: "#43a047",
};

const useTickets = (currentPage) => {
  const fetcher = (...args) => fetch(...args).then((res) => res.json());
  const url = `${process.env.REACT_APP_BASEURL}/crm/tickets/closed?page=${currentPage}`;
  const { data } = useSWR(url, fetcher, {
    refreshInterval: 2000,
    revalidateOnFocus: true,
  });

  return {
    page: currentPage,
    rowsPerPage: data ? data.length : 0,
    rows: data || [],
    total: 700,
  };
};

const TicketsCerradosTable = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const tickets = useTickets(currentPage);

  const [isLoading, setIsLoading] = useState(false);
  const [ticketABuscar, setTicketABuscar] = useState("");
  const [ticketsFiltrados, setTicketsFiltrados] = useState(null);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    const handleSearchTicket = async () => {
      if (ticketABuscar.length < 5) return;
      try {
        setIsLoading(true);
        const ticketsF = await searchClosedTicket(ticketABuscar);
        console.log(ticketsF);
        setTicketsFiltrados(ticketsF);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    handleSearchTicket();
  }, [ticketABuscar]);

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <TextField
          size="small"
          variant="filled"
          label="Buscar caso"
          sx={{ m: 1 }}
          value={ticketABuscar}
          onChange={(e) => setTicketABuscar(e.target.value)}
        />
        {isLoading && <CircularProgress size={30} />}
      </Box>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Fecha de registro</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Descripcion</TableCell>
              <TableCell>Canal</TableCell>
              <TableCell>Contacto</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          {ticketABuscar.length > 4 && ticketsFiltrados ? (
            <TableBody>
              {ticketsFiltrados.map((row) => (
                <TableRow key={row.id_crm_main}>
                  <TableCell>
                    <DateInTable dateString={row.date_register} />
                  </TableCell>
                  <TableCell>{row.type}</TableCell>
                  <TableCell>{row.title}</TableCell>
                  <TableCell>{row.channel}</TableCell>
                  <TableCell>{row.contact}</TableCell>
                  <TableCell>
                    <Chip
                      label={row.status}
                      size="small"
                      sx={{
                        backgroundColor: chipColors[row.status],
                        color: "#fff",
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Link to={`closed/${row.id_crm_main}`} style={{ textDecoration: "none" }}>
                      <Button variant="contained" size="small" disableElevation startIcon={<VisibilityIcon />}>
                        Detalle
                      </Button>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : tickets.rows.length > 0 ? (
            <TableBody>
              {tickets.rows.map((row) => (
                <TableRow key={row.id_crm_main}>
                  <TableCell>
                    <DateInTable dateString={row.date_register} />
                  </TableCell>
                  <TableCell>{row.type}</TableCell>
                  <TableCell>{row.title}</TableCell>
                  <TableCell>{row.channel}</TableCell>
                  <TableCell>{row.contact}</TableCell>
                  <TableCell>
                    <Chip
                      label={row.status}
                      size="small"
                      sx={{
                        backgroundColor: chipColors[row.status],
                        color: "#fff",
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Link to={`closed/${row.id_crm_main}`} style={{ textDecoration: "none" }}>
                      <Button variant="contained" size="small" disableElevation startIcon={<VisibilityIcon />}>
                        Detalle
                      </Button>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          <TableFooter>
            {ticketABuscar.length < 5 && (
              <TableRow>
                <TablePagination
                  rowsPerPage={tickets.rowsPerPage}
                  rowsPerPageOptions={[tickets.rowsPerPage]}
                  count={tickets.total}
                  page={tickets.page}
                  onPageChange={handleChangePage}
                />
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TicketsCerradosTable;
