import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getCarrieService,
  getCotizacion,
  insertGuide,
} from "../../../../../utils/ventaMayoreo/ordenesVenta/ordenesVenta";
import { useAuth } from "../../../../../context/AuthContext";

export const SaveGuia = ({ carriers, idRequerimiento, id, toggleEstado, numCotizaacion }) => {
  const { user } = useAuth();
  const [servicesCarrier, setServicesCarrier] = useState([]);
  const [formData, setFormData] = useState({
    nombre: "",
    fachada: "",
    pais: "MX",
    estado: "",
    municipio: "",
    calle: "",
    colonia: "",
    ciudad: "",
    entre_calles: "",
    cp: "",
    numero_ext: "",
    numero_int: "",
    telefono: "",
    celular: "",
    id_tipo_servicio: "",
    servicio: "",
    id_tz_carriers: 1,
    carrier: "Estafeta",
    cantidad_guias: 1,
    justificacion: "",
    id_tipo_guia: "",
    justificacionG: "",
  });

  useEffect(() => {
    (async () => {
      let servicesC = await getCarrieService(formData.id_tz_carriers || 1);
      setServicesCarrier(servicesC.data);
    })();
  }, [formData.id_tz_carriers]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getCotizacion(id);
        const data = response.data[0];
        console.log(data);

        setFormData({
          nombre: data.razon_social_facturacion || "",
          estado: encodeUtf8(data.estado_envio) || "",
          numero_int: data.numeroInt_envio || "",
          numero_ext: data.numeroExt_envio || "",
          cp: data.cp_envio || "",
          entre_calles: encodeUtf8(data.entreCalles_envio) || "",
          ciudad: encodeUtf8(data.ciudad_envio) || "",
          colonia: data.colonia_envio || "",
          municipio: encodeUtf8(data.delegacion_envio) || "",
          calle: encodeUtf8(data.calle_envio) || "",
          telefono: encodeUtf8(data.telefono) || "",
          fachada: encodeUtf8(data.fachada_envio) || "",
        });
      } catch (error) {
        console.error("Hubo un error obteniendo los datos:", error);
      }
    };

    fetchData();
  }, [id]);

  const numbersGuide = Array.from({ length: 10 }, (_, i) => i + 1);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "selectServicio") {
      const selectedService = servicesCarrier.find(
        (service) => service.id_tipo_servicio === value
      );
      setFormData((prevState) => ({
        ...prevState,
        id_tipo_servicio: selectedService.id_tipo_servicio,
        servicio: selectedService.label,
      }));
    } else if (name === "selectCarrier") {
      const selectedCarrier = carriers.find(
        (carrier) => carrier.id_tz_carriers === value
      );
      setFormData((prevState) => ({
        ...prevState,
        id_tz_carriers: selectedCarrier.id_tz_carriers,
        carrier: selectedCarrier.nombre,
      }));
    } else if (name === "selectTipificacion") {
      setFormData((prevState) => ({
        ...prevState,
        id_tipo_guia: value,
        justificacionG: "Faltante",
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const saveGuide = async () => {

    let dataGuia = {
      idUser: user.data.awsid,
      idCarrier: formData.id_tz_carriers,
      increment_id: numCotizaacion,
      nombre: formData.nombre,
      pais: "MX",
      cp: formData.cp,
      estado: formData.estado,
      municipio: formData.municipio,
      calle: formData.calle,
      numero_ext: formData.numero_ext,
      numero_int: formData.numero_int,
      colonia: formData.colonia,
      ciudad: formData.ciudad,
      entre_calles: formData.entre_calles,
      fachada: formData.fachada,
      telefono: formData.telefono,
      celular: formData.celular,
      justificacion: formData.justificacion,
      id_tipo_guia: 9,
      servicio: formData.servicio,
      id_tipo_servicio: formData.id_tipo_servicio,
      carrier: formData.carrier,
      cantidad_guias: formData.cantidad_guias,
    };

    console.log(dataGuia);
    await insertGuide(dataGuia);

    toggleEstado();
  };

  function encodeUtf8(val) {
    try {
      return decodeURIComponent(escape(val));
    } catch (err) {
      return val;
    }
  }

  return (
    <Paper sx={{ padding: "10px", marginTop: "20px" }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl
            variant="outlined"
            size="small"
            fullWidth
            sx={{ marginTop: "5px" }}
          >
            <InputLabel>Tipificacion:</InputLabel>
            <Select
              label="Tipificacion:"
              name="selectTipificacion"
              value={formData.id_tipo_guia || 9}
              disabled
            >
              <MenuItem value={9}>Faltante</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            label="Nombre:"
            name="nombre"
            value={formData.nombre}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            label="Fachada:"
            name="fachada"
            value={formData.fachada}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            // label="País:"
            name="pais"
            value={formData.pais}
            onChange={handleChange}
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            label="Estado:"
            name="estado"
            value={formData.estado}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            label="Minicipio:"
            name="municipio"
            value={formData.municipio}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            label="Calle:"
            name="calle"
            value={formData.calle}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            label="Colonia:"
            name="colonia"
            value={formData.colonia}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            label="Ciudad:"
            name="ciudad"
            value={formData.ciudad}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            label="Entre calles:"
            name="entre_calles"
            value={formData.entre_calles}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            label="Código Postal:"
            name="cp"
            value={formData.cp}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            label="Número Exterior:"
            name="numero_ext"
            value={formData.numero_ext}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            label="Número Interior:"
            name="numero_int"
            value={formData.numero_int}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            label="Teléfono:"
            name="telefono"
            value={formData.telefono}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            size="small"
            variant="outlined"
            fullWidth
            label="Celular:"
            name="celular"
            value={formData.celular}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl
            variant="outlined"
            size="small"
            fullWidth
            sx={{ marginTop: "5px" }}
          >
            <InputLabel>Servicio:</InputLabel>
            <Select
              label="Servicio:"
              name="selectServicio"
              value={formData.id_tipo_servicio || ""}
              onChange={handleChange}
            >
              {servicesCarrier.map((service) => (
                <MenuItem
                  key={service.id_tipo_servicio}
                  value={service.id_tipo_servicio}
                >
                  {service.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl
            variant="outlined"
            size="small"
            fullWidth
            sx={{ marginTop: "5px" }}
          >
            <InputLabel>Carrier:</InputLabel>
            <Select
              label="Carrier:"
              name="selectCarrier"
              value={formData.id_tz_carriers || ""}
              onChange={handleChange}
            >
              {carriers.map((carrier) => (
                <MenuItem
                  key={carrier.id_tz_carriers}
                  value={carrier.id_tz_carriers}
                >
                  {carrier.nombre}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl
            variant="outlined"
            size="small"
            fullWidth
            sx={{ marginTop: "5px" }}
          >
            <InputLabel>Numero de Guias:</InputLabel>
            <Select
              label="Numero de Guias:"
              name="cantidad_guias"
              value={formData.cantidad_guias || ""}
              onChange={handleChange}
            >
              {numbersGuide.map((num) => (
                <MenuItem key={num} value={num}>
                  {num}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            label="Observaciones:"
            name="justificacion"
            value={formData.justificacion}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => saveGuide()}
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};
