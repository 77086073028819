import { useState } from "react";
import { Box, Button, Tab, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Forum as ForumIcon, Info as InfoIcon } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";
import { InfoTab } from "./tabs/InfoTab";
import { MensajesTab } from "./tabs/MensajesTab";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";

export const IndexTabs = ({
  requerimiento,
  producto,
  idRequerimiento,
  idRequerimientoProducto,
}) => {
  const navigate = useNavigate();
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ margin: "20px", marginBottom: "30px" }}>
          <Button
            variant="contained"
            color="info"
            size="small"
            startIcon={<ArrowBackIosIcon />}
            onClick={() => navigate(`/venta-mayoreo/validaciones`)}
          >
            Regresar
          </Button>
        </Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            aria-label="TabsDetails"
            sx={{ button: { minHeight: 0 }, borderTop: "1px solid #d2d6de" }}
          >
            <Tab
              icon={<InfoIcon />}
              iconPosition="start"
              label="Informacion"
              value="1"
            />
            <Tab
              icon={<ForumIcon />}
              iconPosition="start"
              label="Mensajes"
              value="2"
            />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ padding: "15px" }}>
          <InfoTab
            requerimiento={requerimiento}
            producto={producto}
            idRequerimiento={idRequerimiento}
            idRequerimientoProducto={idRequerimientoProducto}
          />
        </TabPanel>
        <TabPanel value="2">
          {requerimiento ? (
            <MensajesTab idRequerimiento={idRequerimiento} requerimiento={requerimiento} />
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </TabPanel>
      </TabContext>
    </Box>
  );
};
