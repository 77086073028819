import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../../context/AuthContext";
import {
  Box,
  Button,
  Card,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import BreadCrumb from "../../../general/BreadCrumb";
import { motion } from "framer-motion";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import { useState } from "react";
import { useEffect } from "react";
import { profiles } from "../../../../utils/ventaMayoreo/profilesVentaMayoreo";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { getPurchaseOrderItems } from "../../../../utils/ventaMayoreo/ordenesCompra/ordenesCompra";

const itemsBreadCrumbs = [
  {
    label: "Venta mayoreo",
    icon: <ContentPasteSearchIcon sx={{ mr: 0.5 }} fontSize="small" />,
    link: "/venta-mayoreo",
  },
  { label: "Órdenes de compra", link: "/venta-mayoreo/ordenes-compra" },
  { label: "Generar" },
];

export const GenerarOrdenCompra = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;
  const [isLoading, setIsLoading] = useState(false);
  const [inforRCE, setInforRCE] = useState(null);
  const [estados, setEstados] = useState([]);
  const [openM, setOpenM] = useState(false);

  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [keyWord, setKeytoSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [reloadPage, setReloadPage] = useState(0);

  useEffect(() => {
    if (!profiles.ordenesC.includes(auth.user.role.idProfile)) {
      navigate("/dashboard", { replace: true });
    }
  }, [id, auth.user.data.awsid]);

  const columns = [
    {
      field: "numero",
      headerName: "Numero",
      flex: 0.6,
      align: "center",
      headerAlign: "center",
      renderCell: ({row}) => (
        <Typography variant="subtitle2">
          {row.numero_cotizacion}
        </Typography>
      ),
    },
    {
      field: "tipo",
      headerName: "Tipo",
      flex: 0.6,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => {
        return (
          <Button
            variant="contained"
            color="success"
            size="small"
            sx={{
              textTransform: "none",
              lineHeight: 1,
              fontSize: "0.7rem",
              minWidth: 0,
            }}
          >
            {row.tipo_seguimiento === "RO" ? "Cotización" : "Pedido"}
          </Button>
        );
      },
    },
    {
      field: "producto",
      headerName: "Producto",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: ({ row }) => row.nombre,
    },
    {
      field: "sku",
      headerName: "SKU",
      flex: 0.8,
      align: "center",
      headerAlign: "center",
      valueGetter: ({ row }) => row.sku,
    },
    {
      field: "cantidad",
      headerName: "Cantidad",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: ({ row }) => row.cantidad,
    },
    {
      field: "provedor",
      headerName: "Provedor",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: ({ row }) =>
        row.razon_social ? row.razon_social : "S/Proveedor",
    },
  ];

  if (
    document.querySelector(
      ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
    )
  )
    document
      .querySelector(
        ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
      )
      .remove();

  useEffect(() => {
    let active = true;
    setLoading(true);
    setRows([]);
    setInforRCE(null);

    (async () => {
      let newRows = null;
      //   let estados = await getEstados();
      //   setEstados(estados.data);
      //   const resInfoRCE = await getEditQuoteInf(id);
      //   setInforRCE(resInfoRCE.data);

      if (
        typeof keyWord === "undefined" ||
        keyWord === undefined ||
        keyWord.length === 0
      ) {
        setLoading(true);
        newRows = await getPurchaseOrderItems(page);
      } else if (keyWord.length >= 3) {
        setLoading(true);
      } else active = false;

      if (!active) {
        return;
      }
      setTotal(newRows.total);
      setRows(newRows.data);
      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [page, keyWord, reloadPage]);

  const handleCloseM = () => {
    setOpenM(false);
  };

  const reloadPageFn = () => {
    setReloadPage((prevState) => (prevState === 0 ? 1 : 0));
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" sx={{ my: 2 }}>
          Detalle
        </Typography>
        <Box>
          <BreadCrumb items={itemsBreadCrumbs} />
        </Box>
      </Box>

      <motion.div
        initial={{ y: 20 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Card>
          <Box>
            <Box sx={{ margin: "8px" }}>
              <Card>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Grid container sx={{ mb: 2, mt: 2 }}>
                    <Grid item md={4} sx={{ px: 2 }}>
                      <Stack direction="row" spacing={1}>
                        <TextField
                          size="small"
                          variant="outlined"
                          fullWidth
                          label={`Buscar`}
                          value={keyWord}
                          onChange={(e) => setKeytoSearch(e.target.value)}
                        />
                      </Stack>
                    </Grid>
                    <Grid item md sx={{ px: 2, textAlign: "right" }}>
                      <Button
                        variant="contained"
                        color="info"
                        //   onClick={handleOpenModalCreate}
                      >
                        Generar Orden
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
                <Box style={{ height: 635, width: "100%" }}>
                  <DataGridPro
                    rows={rows}
                    columns={columns}
                    pagination
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    getRowId={(row) => row.id_requerimiento_producto}
                    rowCount={total}
                    paginationMode="server"
                    onPageChange={(newPage) => {
                      setPage(newPage);
                    }}
                    loading={loading}
                    disableColumnMenu
                    disableSelectionOnClick
                    getDetailPanelHeight={() => "auto"}
                  />
                </Box>
              </Card>
            </Box>
          </Box>
        </Card>
      </motion.div>

      <Box>&nbsp;</Box>
      {/* <Dialog open={openM} onClose={handleCloseM}>
          <DialogTitle>{"Error"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              No se ha modificado ningún campo
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseM} color="error" variant="outlined">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog> */}
    </>
  );
};
