import axios from "axios";

/**
 * GET
 **/

const getOrders = async (currentPage) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/ordenescompra/ordenescompra/all/new?page=${currentPage}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const searchOrders = async (currentPage, search) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/ordenescompra/ordenescompra/all/search?page=${currentPage}&search=${search}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getOrder = async (idOrder) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/ordenescompra/ordenescompra/order?idOrder=${idOrder}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getProducts = async (page, id) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/ordenescompra/ordenescompra/detail/products?idOrder=${id}&page=${page}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getPurchaseOrderItems = async (page) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/ordenescompra/ordenescompra/genorden/compra?page=${page}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

export { getOrders, searchOrders, getOrder, getProducts, getPurchaseOrderItems };
