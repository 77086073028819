import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { Box } from "@mui/system";
import { forwardRef, useState } from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  MenuItem,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useDialogs } from "../../context/DialogsContext";
import { useResourses } from "../../context/ResourcesContext";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function NuevoTicketDialog() {
  const [isLoading, setIsLoading] = useState(false);
  const [college, setCollege] = useState(0);
  const [channel, setChannel] = useState("Redes sociales");
  const [typeCase, setTypeCase] = useState(1); // id_crm_typecases
  const [title, setTitle] = useState("");
  const [solution, setSolution] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const { colleges, channels, typeCases } = useResourses();

  const { setOpenNuevoTicketDialog, openNuevoTicketDialog } = useDialogs();

  const closeDialog = () => setOpenNuevoTicketDialog(false);

  const clearValues = () => {
    setCollege(0);
    setChannel("Redes sociales");
    setTypeCase(1);
    setTitle("");
    setSolution("");
    setEmail("");
    setPhone("");
  };

  const handleCrearTicket = async () => {
    setIsLoading(true);

    try {
      if (!title || !solution || !email || !phone) {
        setIsLoading(false);
        return;
      }

      const json = JSON.stringify({
        channel,
        title,
        description: solution,
        contact: email,
        contact2: phone,
      });
      const result = await axios.post(
        `${process.env.REACT_APP_BASEURL}/crm/tickets/create`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (result.status === 200) {
        closeDialog();
        clearValues();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Dialog
        open={openNuevoTicketDialog}
        onClose={closeDialog}
        TransitionComponent={Transition}
      >
        {isLoading && <LinearProgress />}
        <DialogTitle>Crear nuevo ticket</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: 2,
            }}
          >
            <TextField
              autoFocus
              margin="dense"
              label="Email"
              type="email"
              variant="filled"
              sx={{
                width: 300,
                mr: 1,
              }}
              disabled={isLoading}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="dense"
              label="Telefono"
              variant="filled"
              sx={{
                width: 300,
                ml: 1,
              }}
              disabled={isLoading}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              mb: 2,
            }}
          >
            <TextField
              select
              label="Colegio"
              value={college}
              onChange={(e) => setCollege(e.target.value)}
              helperText="Selecciona el colegio"
              variant="filled"
              sx={{
                width: "100%",
              }}
              disabled={isLoading}
            >
              {colleges &&
                colleges.length &&
                colleges.map((col, i) => (
                  <MenuItem key={i} value={i}>
                    {col.name}
                  </MenuItem>
                ))}
            </TextField>
          </Box>
          <Box
            sx={{
              display: "flex",
              mb: 2,
            }}
          >
            <TextField
              select
              label="Canal"
              value={channel}
              onChange={(e) => setChannel(e.target.value)}
              helperText="Selecciona el canal"
              variant="filled"
              sx={{
                width: "100%",
              }}
              disabled={isLoading}
            >
              {channels &&
                channels.length &&
                channels.map((chan) => (
                  <MenuItem
                    key={chan.id_crm_channels}
                    value={chan.channel_name}
                  >
                    {chan.channel_name}
                  </MenuItem>
                ))}
            </TextField>
          </Box>
          <Box
            sx={{
              display: "flex",
              mb: 2,
            }}
          >
            <TextField
              autoFocus
              margin="dense"
              label="Titulo"
              type="text"
              variant="filled"
              sx={{
                width: "100%",
              }}
              disabled={isLoading}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              mb: 2,
            }}
          >
            <TextField
              id="outlined-textarea"
              label="Solucion"
              multiline
              variant="filled"
              rows={3}
              sx={{
                width: "100%",
              }}
              disabled={isLoading}
              value={solution}
              onChange={(e) => setSolution(e.target.value)}
            />
          </Box>
          <Box>
            <TextField
              select
              label="Tipo de caso"
              value={typeCase}
              onChange={(e) => setTypeCase(e.target.value)}
              helperText="Selecciona el numero de caso"
              variant="filled"
              sx={{
                width: "100%",
              }}
              disabled={isLoading}
            >
              {typeCases &&
                typeCases.length &&
                typeCases.map((tc) => (
                  <MenuItem
                    key={tc.id_crm_typecases}
                    value={tc.id_crm_typecases}
                  >
                    {tc.typecase_name}
                  </MenuItem>
                ))}
            </TextField>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancelar</Button>
          <Button
            variant="contained"
            disableElevation
            onClick={handleCrearTicket}
            disabled={isLoading}
          >
            Crear ticket
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
