const profiles = {
    inicio: [1,7,9,10,20,23,29,30,34,35,37,46,58,],
    pedidos: [1,7,9,10,15,20,23,29,30,34,35,37,46,52,58,],
    rma: [1,7,9,10,20,23,24,29,30,34,35,37,46,58,],
    aclaraciones: [1,],
    aclaraciones_all: [1,7,9,10,15,20,23,29,30,34,35,37,46,58,],
    backorders: [1,7,9,10,15,20,23,29,30,34,35,37,46,58,],
    excepciones: [1,7,8,9,10,15,20,23,29,30,34,35,37,46,58,],
    excepcionesInt: [1,7,9,20,23,29,30,34,46,58,],
    carriers: [1,],
    carriers_all: [1,7,23,29,30,34,46,58,],
    incidenciasLog: [1,7,9,23,34,46,58,],
    verificaciones: [1,7,9,23,34,46,58,],
};

const belongsTrazabilidad = ( profile ) => {
    let aux = profiles;
    return  aux.inicio.includes( profile ) ? true : aux.pedidos.includes( profile )
            ? true : aux.rma.includes( profile ) ? true : aux.aclaraciones.includes( profile )
            ? true : aux.backorders.includes( profile ) ? true : aux.excepciones.includes( profile )
            ? true : aux.excepcionesInt.includes( profile ) ? true : aux.carriers.includes( profile )
            ? true : aux.incidenciasLog.includes( profile ) ? true : aux.verificaciones.includes( profile );
}

export{
    profiles, belongsTrazabilidad
};