import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import { LoadingButton } from "@mui/lab";

const AdvShearch = ({ advancedS, handleChangeAdvancedS, setIsAF }) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Busqueda avanzada</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              type={"date"}
              name="initDate"
              label="Fecha de alta"
              variant="outlined"
              value={advancedS.endDate}
              onChange={handleChangeAdvancedS}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              type={"date"}
              name="endDate"
              label="Fecha de alta"
              variant="outlined"
              value={advancedS.endDate}
              onChange={handleChangeAdvancedS}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              name="pedido"
              label="Pedido"
              variant="outlined"
              value={advancedS.pedido}
              onChange={handleChangeAdvancedS}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              name="cotizacion"
              label="Cotizacion"
              variant="outlined"
              value={advancedS.cotizacion}
              onChange={handleChangeAdvancedS}
            />
          </Grid>

          <Grid item xs={4}>
            <FormControl fullWidth variant="filled">
              <InputLabel id="er">Estatus Requerimiento</InputLabel>
              <Select
                labelId="er"
                // value={}
                // onChange={}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField fullWidth label="Orden de venta" variant="filled" />
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth variant="filled">
              <InputLabel id="er">Tipo Orden venta</InputLabel>
              <Select
                labelId="er"
                // value={}
                // onChange={}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField fullWidth label="Producto" variant="filled" />
          </Grid>
          <Grid item xs={4}>
            <TextField fullWidth label="SKU" variant="filled" />
          </Grid>

          <Grid item xs={12}>
            <div style={{ textAlign: "right", marginTop: "2em" }}>
              <LoadingButton
                startIcon={<SearchIcon />}
                sx={{ mx: 2 }}
                // loading={isLoading}
                size="medium"
                variant="contained"
                color="success"
                onClick={() => setIsAF(true)}
                disableElevation
              >
                Consultar
              </LoadingButton>
            </div>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default AdvShearch;
