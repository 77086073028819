import { Box, Typography, Grid, List, ListItem, ListItemText, LinearProgress, Chip } from "@mui/material";
import DateInTable from "../../general/DateInTable";

const VerificacionesDSDetail = ({ verifId, detalle }) => {

    function isInProOrClo(){
        if(detalle.id_status !== 1)
            return true;
        else
            return false;
    }

    return (
        <>
            <Grid container>
                {detalle && (
                <Grid item md={12} sx={{ ml:2, mr:2, mt:2}}>
                    <Typography>Guía: <a href={"https://www.estafeta.com/Tracking/searchByGet/?wayBillType=1&wayBill="+detalle.name_guia} target="_blank">{detalle.name_guia}</a></Typography>
                </Grid>)}

                {detalle && (
                <Grid item md={4}>
                    <List>
                        <ListItem>
                            <ListItemText primary="Número de pedido" secondary={detalle.increment_id}/>                                        
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Fecha de creación" secondary={<DateInTable dateString={detalle.creation_date} />}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Fecha de pago" secondary={<DateInTable dateString={detalle.payment_date} />}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Nombre de la escuela" secondary={detalle.school}/>
                        </ListItem>
                    </List>
                </Grid>)}

                {detalle && (
                <Grid item md={4}>
                    <List>
                        <ListItem>
                            <ListItemText primary="Nombre de cliente" secondary={(isInProOrClo ? detalle.dir_nombre : (detalle.customer_name + " " + detalle.customer_lastname + " " + detalle.customer_mothersname))}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Teléfono" secondary={(isInProOrClo ? detalle.dir_telefono : (detalle.telephone))}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Email" secondary={detalle.email}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Dirección de envio" secondary={(isInProOrClo ? (detalle.dir_calle+", "+detalle.dir_ciudad+", "+detalle.dir_municipio+", "+detalle.dir_colonia) : (detalle.shipping_street+", "+detalle.shipping_city+", "+detalle.shipping_suburb+", "+detalle.shipping_delegation))}/>
                        </ListItem>
                    </List>
                </Grid>)}

                {detalle && (
                <Grid item md={4}>
                    <List>
                        <ListItem>
                            <ListItemText primary="Carrier" secondary={detalle.nombre_carriers}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Tipo de servicio" secondary={detalle.nombre_servicio}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Método de pago" secondary={detalle.paymethod}/>
                        </ListItem>
                    </List>
                </Grid>)}
            </Grid>
        </>
    );
};

export default VerificacionesDSDetail;