import { Box, Grid, Button, Chip } from "@mui/material";
import { useEffect, useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { DataGridPro } from '@mui/x-data-grid-pro';
import Paper from '@mui/material/Paper';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import { getAclarations, } from "../../../utils/trazabilidad/pedidos/pedidos";

import local from "dayjs/plugin/localizedFormat";
import relative from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";

dayjs.locale("es-mx");
dayjs.extend(local);
dayjs.extend(relative);
dayjs.extend(utc);

const chipColors = {
    "Nuevo": "#dd4b39",
    "En Proceso": "#f39c12",
    "Sin Stock": "#007aa6",
    "En Espera de Reembolso": "#b73384",
    "Liberado": "#00a65a",
    "En Espera de Refacturación": "#118B87",
    "Cerrada": "#6d6d6d",
    "En espera de autorización": "#1c108e",
    "En espera de liberar clave": "#118B87",
    "En espera de resolucion": "#1c108e",
    "En proceso de autorización": "#1c108e"
};

const AclaracionesTab = ({ id }) => {
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [keyWord, setKeytoSearch] = useState("");
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const columns = [

        {
            field: "fecha", headerName: "Fecha", flex: 1.2, align: "center", headerAlign: "center",
            renderCell: (params) => (<>
                {dayjs.utc(params.row.creation_date).format("DD MMM YYYY, hh:mm:ss A")}
            </>)
        },
        {
            field: "tipificacion", headerName: "Tipificación", flex: 1, align: "center", headerAlign: "center",
        },
        {
            field: "Ustatus", headerName: "Último Status", flex: 0.8, align: "center", headerAlign: "center",
            renderCell: (params) => (<>
                {params.row.status_acl ? ((params.row.status_acl === "Nuevo" || "En Espera de Reembolso" || "En Proceso" || "Sin Stock" || "Liberado") ? <Chip label={params.row.status_acl} size="small" sx={{ backgroundColor: chipColors[params.row.status_acl], color: "#fff", }}/> : params.row.status_acl ) : "sn"}
            </>)
            
        },
        {
            field: "accion", headerName: "Accion", flex: 0.8, align: "center", headerAlign: "center",
            renderCell: (params) => (<>
                <Button variant="contained" size="small" disableElevation onClick={() => navigate(`/trazabilidad/aclaraciones/detail/${params.row.id_aclaracion}`)}>
                    <VisibilityIcon />
                </Button>
            </>)
        },
    ];

    if (document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"))
        document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']").remove();

    useEffect(() => {
        let active = true;
        (async () => {
            let newRows = null;
            if (typeof keyWord === 'undefined' || keyWord === undefined || keyWord.length === 0) {
                setLoading(true);
                newRows = await getAclarations (id, page);
                console.log(newRows);
            }
            else if (keyWord.length >= 1) {
                setLoading(true);
                //newRows = await searchOrders (page, keyWord);
            }
            else active = false;
            if (!active) {
                return;
            }
            setTotal(newRows.total);
            setRows(newRows.data);
            setLoading(false);
        })();
        return () => {
            active = false;
        };
    }, [id]);

    return (<>
        <Box sx={{ p:2 }} style={{ height: 300 }} component={Paper}>
            <DataGridPro
                rows={rows}
                columns={columns}
                pagination
                pageSize={5}
                rowsPerPageOptions={[5]}
                getRowId={((row) => (row.id_aclaracion))}
                rowCount={total}
                paginationMode="server"
                onPageChange={(newPage) => {
                    setPage(newPage);
                }}
                loading={loading}
                disableColumnMenu
                disableSelectionOnClick
            />
        </Box>
    </>);
};

export default AclaracionesTab;