import { Button,Box,Table,TableBody,TableCell,TableHead,TableRow,CircularProgress,Chip,Typography,TextField,Paper } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useCallback, useEffect, useRef, useState } from "react";
import DateInTable from "../../general/DateInTable";
import { useAuth } from "../../../context/AuthContext";
import { saveEmailInstance } from "../../../services/firebase/emails";
import dayjs from "dayjs";
import { getEmails, sendEmail } from "../../../utils/crm/emails";
import { getDataDownloadFromBase64, uploadFiles } from "../../../utils/crm/files";

const EmailsTab = ({ details }) => {
    const auth = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [emails, setEmails] = useState(null);
    const [asunto, setAsunto] = useState("");
    const [mensaje, setMensaje] = useState("");
    const emailFilesRef = useRef(null);

    const handleSendEmail = async () => {
        try {
          setIsLoading(true);
          await sendEmail({
            asunto,
            message: mensaje,
            files: emailFilesRef.current.files,
            email: details.contact,
            idCase: details.id_crm_main || details.id_crm_case,
            idUser: auth.user.data.awsid,
            aName: auth.user.data.displayName,
            cName: details.contact,
            object: details.id_crm_main ? "Ticket" : "Caso",
          });
          await saveEmailInstance({
            asunto,
            message: mensaje,
            files: emailFilesRef.current.files,
            emailto: details.contact,
            caseid: details.id_crm_main || details.id_crm_case,
            emailfrom: auth.user.data.email,
          });
          await getEmailsAws();
          setAsunto("");
          setMensaje("");
          emailFilesRef.current.value = "";
          setIsLoading(false);
        } catch (error) {
          console.error(error);
          setIsLoading(false);
        }
    };

    const getEmailsAws = useCallback(async () => {
        const newEmails = await getEmails(details.id_crm_main);
        setEmails(newEmails);
    }, [details.id_crm_main]);

    useEffect(() => {
        getEmailsAws();
    }, []);

    return (
        <>
            <Box>
                <Typography variant="h6" gutterBottom>
                  Enviar email
                </Typography>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr",
                    gap: 2,
                    mb: 2,
                  }}
                >
                  <TextField
                    variant="filled"
                    label="Asunto"
                    value={asunto}
                    onChange={(e) => setAsunto(e.target.value)}
                  />
                  <TextField
                    variant="filled"
                    multiline
                    rows={3}
                    label="Mensaje"
                    value={mensaje}
                    onChange={(e) => setMensaje(e.target.value)}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <input type="file" ref={emailFilesRef} />
                    <LoadingButton
                      sx={{ ml: 2 }}
                      loading={isLoading}
                      variant="contained"
                      disableElevation
                      onClick={handleSendEmail}
                    >
                      Enviar
                    </LoadingButton>
                  </Box>
                </Box>
                {isLoading && <CircularProgress />}
                {emails && (                
                <Box>
                    <Typography variant="h6" gutterBottom>
                    Lista de emails
                    </Typography>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Fecha envio</TableCell>
                        <TableCell>Asunto</TableCell>
                        <TableCell>Mensaje</TableCell>
                        <TableCell>Asesor</TableCell>
                        <TableCell>Archivos</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {emails &&
                        emails.map((email, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <DateInTable dateString={email.date_sent} />
                              {/* <Box>
													<Typography noWrap variant="subtitle2">{dayjs(email.date.toDate()).format("DD MMM YYYY, hh:mm A")}</Typography>
													<Typography noWrap variant="caption">{dayjs(email.date.toDate()).fromNow()}</Typography>
												</Box> */}
                            </TableCell>
                            <TableCell>{email.asunto}</TableCell>
                            <TableCell>{email.mensaje}</TableCell>
                            <TableCell>{`${email.firstname_user}${email.lastname_user}`}</TableCell>
                            <TableCell>
                              {(() => {
                                if (email.att_name === "null") {
                                  return "No hay archivos";
                                }

                                const { href, download } =
                                  getDataDownloadFromBase64(
                                    email.attachment,
                                    email.att_name,
                                    email.att_type
                                  );
                                return (
                                  <Typography>
                                    <a href={href} download={download}>
                                      {email.att_name}
                                    </a>
                                  </Typography>
                                );
                              })()}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </Box>
                )}
            </Box>
        </>
    )
};

export default EmailsTab;