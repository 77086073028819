import axios from "axios";

const getMovimientos = async (idAcl) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/clarifications/clarifications/details/tab/movements?idAcl=${idAcl}`;
  try {
    const movimientos = await axios(URL);

    return movimientos.data?.success ? movimientos.data.data : [];
  } catch (error) {
    console.error(error);
  }
};

export { getMovimientos };
