import { Box, Grid, CircularProgress, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useEffect, useState, useMemo } from "react";
import { useNavigate, useLocation, Router } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import { getHRProducts } from "../../../utils/altoRiesgo/alto/alto";

import InventoryIcon from '@mui/icons-material/Inventory';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const AltoProductos = ({ altoId }) => {
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [products, setProducts] = useState(null);

    function getComplemento(params) {
        if(params.row.isComplemento)
          return <CheckIcon />;
        else
          return <CloseIcon />;
    }

    const columns = [
        { field: "product_name", headerName: "Producto", flex: 3 },
        { field: "product_sku", headerName: "SKU", flex: 1.5 },
        { field: "attributes", headerName: "Categoría", flex: 1 },
        {
            field: "isComplemento",
            headerClassName: 'blue-table-header',
            cellClassName: 'blue-table-header',
            align: "center",
            headerAlign: 'center',
            headerName: "Complemento",
            flex: 1,
            renderCell: (params) => (
                <>{getComplemento(params)}</>
            ),
        },
        { field: "quantity", headerName: "Cantidad", align: "center", headerAlign: 'center', flex: 1 },
    ];

    useEffect(() => {
        const getDetallePedido = async () => {
            try {
                setIsLoading(true);
                const res = await getHRProducts(altoId);
                setProducts(res);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };
        getDetallePedido();
    }, []);

    return (
        <Box>
            <Grid container>
                <Grid item md={12} sx={{'& .blue-table-header': { backgroundColor: '#00c0ef', color: '#ffffff' }}}>
                    <Typography variant="h6" sx={{ ml: 2, my: 2 }}><InventoryIcon sx={{ m: 0 }} /> Productos</Typography>
                    {isLoading && (<Box sx={{ml:2, mt:2, mr:2, mb:2}}> <Typography variant="p"> Cargando ...</Typography> <CircularProgress /> </Box>)}
                    {products && (<DataGrid
                        autoHeight
                        rows={products}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        getRowId={(row) => row.product_sku}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        disableColumnMenu

                        components={{ Toolbar: GridToolbar }}
                        componentsProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: { debounceMs: 500 },
                            },
                        }}
                    />)}
                </Grid>
            </Grid>
        </Box>
    );
};

export default AltoProductos;