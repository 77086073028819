import { Box, CircularProgress, Typography, Alert, Card, CardContent, Grid, TextField, InputLabel, Select, FormControl, MenuItem, Stack, Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { LoadingButton } from "@mui/lab";
import { useAuth } from "../../../../context/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState, forwardRef } from "react";
import { getSDExcep, saveSDExcep } from "../../../../utils/trazabilidad/excepciones/excepciones";
import SaveIcon from '@mui/icons-material/Save';

const Alerta = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const DETab = ({ excepId, excepDl, messa }) => {
    const auth = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [openn, setOpenn] = useState(false);
    const [vertical, setVertical] = useState("top");
    const [horizontal, setHorizontal] = useState("right");
    const [address, setAddress] = useState({
        nombre: '',
        calle: '',
        ne: '',
        ni: '',
        entre: '',
        colonia: '',
        dele: '',
        cp: '',
        ciudad: '',
        estado: '',
        fachada: '',
        tel: ''
    });

    const handleClickN = () => {
        setOpenn(true);
    };

    const handleCloseN = () => {
        setOpenn(false);
    };

    const handleChangeAddress = (event) => {    
        let name = event.target.name;
        let value = event.target.value;
        setAddress((prevalue) => {
          return {
            ...prevalue,
            [name]: value
          }
        });
    };

    function isempt(){
        if(address.nombre && address.calle && address.ne && address.entre && address.colonia && address.dele && address.cp && address.ciudad && address.estado && address.fachada && address.tel)
            return false;
        else
            return true;
    }

    const handleChangeAF = async () => {
        try {
            setIsLoading(true);
            if(!isempt())
            {
                await saveSDExcep(
                    excepId, auth.user.data.awsid, address.nombre, address.calle, 
                    address.ne, address.ni, address.entre, address.colonia, address.dele, 
                    address.cp, address.ciudad, address.estado, address.fachada, address.tel
                );
                handleClickN();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const getAddress = async () => {
        try {
            setIsLoading(true);
            const resp = await getSDExcep(excepId, excepDl.id_order);
            if(resp)
            {            
                setAddress({
                    nombre: (resp.shipping_name ? resp.shipping_name : ''),
                    calle: (resp.shipping_street ? resp.shipping_street : ''),
                    ne: (resp.shipping_outside_number ? resp.shipping_outside_number : ''),
                    ni: (resp.shipping_inside_number ? resp.shipping_inside_number : ''),
                    entre: (resp.between_streets ? resp.between_streets : ''),
                    colonia: (resp.shipping_suburb ? resp.shipping_suburb : ''),
                    dele: (resp.shipping_delegation ? resp.shipping_delegation : ''),
                    cp: (resp.shipping_postal_code ? resp.shipping_postal_code : ''),
                    ciudad: (resp.shipping_city ? resp.shipping_city : ''),
                    estado: (resp.shipping_state ? resp.shipping_state : ''),
                    fachada: (resp.shipping_reference ? resp.shipping_reference : ''),
                    tel: (resp.shipping_telephone ? resp.shipping_telephone : '')
                });
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getAddress();
    }, []);

    return (
        <>
            <Box sx={{mb: 2, ml: 2, mr: 2}}>
            {isLoading && <CircularProgress />}
            
            {address && (<Grid container sx={{mt:2, mb:2}}>                
                <Grid item md={12}>
                    {(messa && messa[0].datos_solicitados) && (<Alert variant="filled" severity="info" sx={{mb: 2}}><Typography variant="p">{messa[0].datos_solicitados}</Typography></Alert>)}
                    <Card>
                        <CardContent>
                            <Stack sx={{m: 2}} direction="row" spacing={3} autoComplete="off">
                                <TextField fullWidth name="nombre" label="Nombre" variant="outlined" value={address.nombre} onChange={handleChangeAddress} />
                                <TextField fullWidth name="calle" label="Calle" variant="outlined" value={address.calle} onChange={handleChangeAddress} />
                                <TextField fullWidth name="ne" label="Número exterior" variant="outlined" value={address.ne} onChange={handleChangeAddress} />
                            </Stack>
                            <Stack sx={{m: 2}} direction="row" spacing={3} autoComplete="off">
                                <TextField fullWidth name="ni" label="Número interior" variant="outlined" value={address.ni} onChange={handleChangeAddress} />
                                <TextField fullWidth name="entre" label="Entre calles" variant="outlined" value={address.entre} onChange={handleChangeAddress} />
                                <TextField fullWidth name="colonia" label="Colonia" variant="outlined" value={address.colonia} onChange={handleChangeAddress} />
                            </Stack>
                            <Stack sx={{m: 2}} direction="row" spacing={3} autoComplete="off">
                                <TextField fullWidth name="dele" label="Delegación" variant="outlined" value={address.dele} onChange={handleChangeAddress} />
                                <TextField fullWidth name="cp" label="C.P." variant="outlined" value={address.cp} onChange={handleChangeAddress} />
                                <TextField fullWidth name="ciudad" label="Ciudad" variant="outlined" value={address.ciudad} onChange={handleChangeAddress} />
                            </Stack>
                            <Stack sx={{m: 2}} direction="row" spacing={3} autoComplete="off">
                                <TextField fullWidth name="estado" label="Estado" variant="outlined" value={address.estado} onChange={handleChangeAddress} />
                                <TextField fullWidth name="fachada" label="Fachada" variant="outlined" value={address.fachada} onChange={handleChangeAddress} />
                                <TextField fullWidth name="tel" label="Teléfono" variant="outlined" value={address.tel} onChange={handleChangeAddress} />
                            </Stack>
                            <div style={{ textAlign: 'right', marginTop: '2em' }}>
                                <LoadingButton startIcon={<SaveIcon />} sx={{ mx: 2}} loading={isLoading} size="medium" variant="contained" color="success" onClick={handleChangeAF} disabled={isempt()}>Guardar datos</LoadingButton>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            )}
            </Box>

            <Snackbar anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal} open={openn} onClose={handleCloseN}>
                <Alerta onClose={handleCloseN} severity="success" sx={{ width: '100%' }}>Datos de envío actualizados</Alerta>
            </Snackbar>
        </>
    );
};

export default DETab;