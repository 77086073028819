import axios from "axios";

/**
 * GET
**/

const getIncidences = async ( currentPage ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/incidences/incidences/all/new?page=${currentPage}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const searchIncidences = async ( currentPage, search ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/incidences/incidences/all/search?page=${currentPage}&search=${search}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getInciDetails = async ( idInc ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/incidences/incidences/details?idInc=${idInc}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getInciMovements = async ( idInc ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/incidences/incidences/movements?idInc=${idInc}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data.data : [];
  } catch (error) {
    console.error(error);
  }
};

/**
 * POST
**/

const addInci = async ( idUser, increment_id, type, idGuide ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/incidences/incidences/create`;
  try {
    const res = await axios.post(URL, { idUser, increment_id, type, idGuide });
    return res.data?.success ? res.data.insertedID : [];
  } catch (error) {
    console.error("addInci", error);
  }
};

const changeAddress = async ( idUser, idInc, addName, addCtry, addPC, addState, addMunic, addStr, addENum, addINum, addCol, addCity, addBetSt, addFac ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/incidences/incidences/address/save`;
  try {
    await axios.post(URL, { idUser, idInc, addName, addCtry, addPC, addState, addMunic, addStr, addENum, addINum, addCol, addCity, addBetSt, addFac });
  } catch (error) {
    console.error("changeAddress", error);
  }
};

const verifProcess = async ( idUser, idInc, ifProcede, type ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/incidences/incidences/process/verificate`;
  try {
    await axios.post(URL, { idUser, idInc, ifProcede, type });
  } catch (error) {
    console.error("verifProcess", error);
  }
};

const saveGuideMove = async ( idUser, idInc, message, dateDeliv ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/incidences/incidences/guides/save`;
  try {
    await axios.post(URL, { idUser, idInc, message, dateDeliv });
  } catch (error) {
    console.error("saveGuideMove", error);
  }
};

const saveRequestOccur = async ( idUser, idInc, ifOcurr ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/incidences/incidences/occur/request`;
  try {
    await axios.post(URL, { idUser, idInc, ifOcurr });
  } catch (error) {
    console.error("saveRequestOccur", error);
  }
};

export {
  getInciDetails, getInciMovements, addInci, changeAddress, verifProcess,
  saveGuideMove, saveRequestOccur, getIncidences, searchIncidences
};