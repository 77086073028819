import { LoadingButton } from "@mui/lab";
import {
  Box,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import React, { useEffect } from "react";
import { useAuth } from "../../../context/AuthContext";
import { useState } from "react";
import {
  genExlStock,
  getStock,
  getSumStock,
  searchStock,
} from "../../../utils/proveedores/stock/stock";
import CardsInfo from "./CardsInfo";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import GraphInfo from "./GraphInfo";
import ExcelFile from "../../general/ExcelFile";

const styles = {
  root: {
    width: "100%",
  },
  card: {
    display: "flex",
    flexDirection: "row",
    margin: "16px",
    border: "1px solid #e0e0e0",
    borderRadius: "4px",
    overflow: "hidden",
  },
  iconContainer: (color) => ({
    backgroundColor: color,
    width: "100px",
    height: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }),
  content: {
    flex: "1 0 auto",
    padding: "16px",
  },
};

const StockDataTable = () => {
  const { user } = useAuth();
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [excelData, setExcelData] = useState(null);
  const [isELoading, setIsELoading] = useState(false);
  const [sumStock, setSumStock] = useState([]);
  const [keyWord, setKeytoSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const getAllExcelData = async () => {
    try {
      setIsELoading(true);
      const data = await genExlStock(user.data.awsid, keyWord);
      setExcelData(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsELoading(false);
    }
  };

  const columns = [
    {
      field: "sku",
      headerName: "SKU",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => `${params.row.SKU}`,
    },
    {
      field: "name",
      headerName: "Nombre",
      flex: 2,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => `${params.row.Nombre}`,
    },
    {
      field: "stock",
      headerName: "Stock",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => `${params.row.Stock}`,
    },
    {
      field: "price",
      headerName: "Valor",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => `$${formatNumber(params.row.Valor)}`,
    },
    {
      field: "store",
      headerName: "Almacén",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => `${params.row.Almacen}`,
    },
  ];

  if (
    document.querySelector(
      ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
    )
  )
    document
      .querySelector(
        ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
      )
      .remove();

  useEffect(() => {
    (async () => {
      let getSuma = await getSumStock(user.data.awsid);
      setSumStock(getSuma.data[0]);
    })();
  }, []);

  useEffect(() => {
    let active = true;
    setRows([]);

    (async () => {
      let newRows = null;
      setExcelData(null);

      if (
        typeof keyWord === "undefined" ||
        keyWord === undefined ||
        keyWord.length === 0
      ) {
        setLoading(true);
        newRows = await getStock(page, user.data.awsid);
      } else if (keyWord.length >= 3) {
        setLoading(true);
        newRows = await searchStock(user.data.awsid, keyWord);
      } else active = false;

      if (!active) {
        return;
      }
      setTotal(newRows.total);
      setRows(newRows.data);
      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [page, keyWord]);

  const data = [
    {
      title: "VALOR DE INVENTARIO",
      total: sumStock?.TotalValor ? sumStock.TotalValor : 0,
      color: "#283a6e",
      icon: AttachMoneyIcon,
      type: "price",
    },
    {
      title: "TOTAL DE PIEZAS",
      total: sumStock?.TotalStock ? sumStock.TotalStock : 0,
      color: "#00c0ef",
      icon: MenuOpenIcon,
    },
    {
      title: "Total de skus",
      total: sumStock?.TotalRegistros ? sumStock.TotalRegistros : 0,
      color: "#00a65a",
      icon: FormatListNumberedIcon,
    },
  ];

  function formatNumber(num) {
    return num.toLocaleString("en-US");
  }

  return (
    <>
      <Box style={styles.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={8}>
            <Grid container spacing={2}>
              {data.map((item, index) => (
                <Grid item xs={12} sm={12} md={6} key={index}>
                  <CardsInfo
                    title={item.title}
                    total={item.total}
                    color={item.color}
                    IconComponent={item.icon}
                    type={item.type}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <GraphInfo sumStock={sumStock} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Grid container sx={{ mb: 2, mt: 2 }}>
          <Grid item md={4} sx={{ px: 2 }}>
            <Stack direction="row" spacing={1}>
              <TextField
                size="small"
                variant="outlined"
                fullWidth
                label={`Buscar`}
                value={keyWord}
                onChange={(e) => setKeytoSearch(e.target.value)}
              />
            </Stack>
          </Grid>

          <Grid item md sx={{ px: 2, textAlign: "right" }}>
            {!excelData && (
              <LoadingButton
                loading={isELoading}
                variant="contained"
                color="info"
                onClick={getAllExcelData}
              >
                Generar excel
              </LoadingButton>
            )}
            {excelData && (
              <ExcelFile excelData={excelData} fileName={"Klaar"} />
            )}
          </Grid>
        </Grid>
      </Box>
      <Box style={{ height: 635, width: "100%" }}>
        <DataGridPro
          rows={rows}
          columns={columns}
          pagination
          pageSize={10}
          rowsPerPageOptions={[10]}
          getRowId={(row) => row.SKU}
          rowCount={total}
          paginationMode="server"
          onPageChange={(newPage) => {
            setPage(newPage);
          }}
          loading={loading}
          disableColumnMenu
          disableSelectionOnClick
          getDetailPanelHeight={() => "auto"}
        />
      </Box>
    </>
  );
};

export default StockDataTable;
