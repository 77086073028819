import axios from "axios";

/**
 * GET Excep
 */

function get_url_af(currentPage, advancedS)
{
  let aux = `${process.env.REACT_APP_BASEURL}/altoRiesgo/hrisk/hrisk/all/searchD?page=${currentPage}`;
  
  if(advancedS.fecha_alta && advancedS.fecha_alta.length > 5)
    aux = aux+`&fecha_alta=${advancedS.fecha_alta}`;
  if(advancedS.fecha_vencimiento && advancedS.fecha_vencimiento.length > 5)
    aux = aux+`&fecha_vencimiento=${advancedS.fecha_vencimiento}`;
  if(advancedS.titular && advancedS.titular.length > 2)
    aux = aux+`&titular=${advancedS.titular}`;
  if(advancedS.banco && advancedS.banco.length > 3)
    aux = aux+`&banco=${advancedS.banco}`;
  if(advancedS.pedido && advancedS.pedido.length > 3)
    aux = aux+`&pedido=${advancedS.pedido}`;
  if(advancedS.email && advancedS.email.length > 3)
    aux = aux+`&email=${advancedS.email}`;
  if(advancedS.telefono && advancedS.telefono.length > 3)
    aux = aux+`&telefono=${advancedS.telefono}`;
  if(advancedS.pais && advancedS.pais.length > 3)
    aux = aux+`&pais=${advancedS.pais}`;
  if(advancedS.estado && advancedS.estado.length > 3)
    aux = aux+`&estado=${advancedS.estado}`;
  if(advancedS.ciudad && advancedS.ciudad.length > 3)
    aux = aux+`&ciudad=${advancedS.ciudad}`;
  if(advancedS.delegacion && advancedS.delegacion.length > 3)
    aux = aux+`&delegacion=${advancedS.delegacion}`;
  if(advancedS.cp && advancedS.cp.length > 3)
    aux = aux+`&cp=${advancedS.cp}`;
  if(advancedS.metodo >= 0)
    aux = aux+`&metodo=${advancedS.metodo}`;
  if(advancedS.nombre_producto && advancedS.nombre_producto.length > 3)
    aux = aux+`&nombre_producto=${advancedS.nombre_producto}`;
  if(advancedS.sku && advancedS.sku.length > 3)
    aux = aux+`&sku=${advancedS.sku}`;
  if(advancedS.status_fl >= 0)
    aux = aux+`&status_fl=${advancedS.status_fl}`;
  if(advancedS.status_ar >= 0)
    aux = aux+`&status_ar=${advancedS.status_ar}`;
  if(advancedS.status_ac >= 0)
    aux = aux+`&status_ac=${advancedS.status_ac}`;
  if(advancedS.status_lo >= 0)
    aux = aux+`&status_lo=${advancedS.status_lo}`;

  return aux;
}

const getHR = async ( page ) => 
{
  const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/hrisk/hrisk/all/new?page=${page}`;
  try {
    const res = await axios(URL);

    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const searchHR = async ( page, search ) => 
{
  const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/hrisk/hrisk/all/search?page=${page}&search=${search}`;
  try {
    const res = await axios(URL);

    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const searchDetailHR = async ( page, advancedS ) => 
{
  const URL = get_url_af(page, advancedS);
  try {
    const res = await axios(URL);

    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getAllHRList = async ( page ) => 
{
    const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/hrisk/hrisk/all/new?page=${page}`;
    try {
        const res = await axios(URL);
        return res.data?.success ? res.data.data : [];
    } catch (error) {
        console.error(error);
    }
};

const getHRById = async ( idOper ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/hrisk/hrisk/details?idOper=${idOper}`;
    try {
        const res = await axios(URL);
        return res.data?.success ? res.data.data[0] : null;
    } catch (error) {
        console.error(error);
    }
};

const getHROrderD = async ( idOper ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/hrisk/hrisk/order?idOper=${idOper}`;
    try {
        const res = await axios(URL);
        return res.data?.success ? res.data.data[0] : null;
    } catch (error) {
        console.error(error);
    }
};

const getHRObservations = async ( idOper ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/hrisk/hrisk/observations?idOper=${idOper}`;
    try {
        const res = await axios(URL);
        return res.data?.success ? res.data.data : null;
    } catch (error) {
        console.error(error);
    }
};

const getHRProducts = async ( idOper ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/hrisk/hrisk/products/all?idOper=${idOper}`;
    try {
        const res = await axios(URL);
        return res.data?.success ? res.data.data : null;
    } catch (error) {
        console.error(error);
    }
};

const getHRMarks = async ( idOper ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/hrisk/hrisk/marks?idOper=${idOper}`;
    try {
        const res = await axios(URL);
        return res.data?.success ? res.data.data : null;
    } catch (error) {
        console.error(error);
    }
};

const getHRFiles = async ( idOper ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/hrisk/hrisk/files?idOper=${idOper}`;
    try {
        const res = await axios(URL);
        return res.data?.success ? res.data.data : null;
    } catch (error) {
        console.error(error);
    }
};

const getHRHistoric = async ( idOper ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/hrisk/hrisk/history?idOper=${idOper}`;
    try {
        const res = await axios(URL);
        return res.data?.success ? res.data.data : null;
    } catch (error) {
        console.error(error);
    }
};

/**
 * POST Excep
 */

const saveMessage = async ( idOper, idUser, observation ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/hrisk/hrisk/message`;
    try {
      await axios.post(URL, { idOper, idUser, observation });
    } catch (error) {
      console.error("saveMessage", error);
    }
};

const cancelHR = async ( idOper, idUser, observation ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/hrisk/hrisk/cancel`;
    try {
      await axios.post(URL, { idOper, idUser, observation });
    } catch (error) {
      console.error("cancelHR", error);
    }
};

const rejectHR = async ( idOper, idUser, observation ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/hrisk/hrisk/reject`;
    try {
      await axios.post(URL, { idOper, idUser, observation });
    } catch (error) {
      console.error("rejectHR", error);
    }
};

const releaseToLHR = async ( idOper, idUser, observation ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/hrisk/hrisk/release`;
    try {
      await axios.post(URL, { idOper, idUser, observation });
    } catch (error) {
      console.error("releaseToLHR", error);
    }
};

const returnHR = async ( idOper, idUser, observation ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/hrisk/hrisk/return`;
    try {
      await axios.post(URL, { idOper, idUser, observation });
    } catch (error) {
      console.error("returnHR", error);
    }
};

const uploadFilesRefundHR = async (idOper, idUser, files) => {
    const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/hrisk/hrisk/refund`;
    try {
      const formData = new FormData();
      formData.append("idOper", idOper);
      formData.append("idUser", idUser);
      formData.append("files", files);
  
      const res = await axios({
        method: "POST",
        url: URL,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data;",
        },
      });
  
      if (!res?.data?.success) {
        throw new Error("uploadFilesRefundHR:", "Error al enviar los archivos");
      }
    } catch (error) {
      console.error("uploadFilesRefundHR", error);
    }
};

const uploadEvidenceHR = async (idOper, idUser, files) => {
    const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/hrisk/hrisk/evidence`;
    try {
      const formData = new FormData();
      formData.append("idOper", idOper);
      formData.append("idUser", idUser);
      formData.append("files", files);
  
      const res = await axios({
        method: "POST",
        url: URL,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data;",
        },
      });
  
      if (!res?.data?.success) {
        throw new Error("uploadEvidenceHR:", "Error al enviar los archivos");
      }
    } catch (error) {
      console.error("uploadEvidenceHR", error);
    }
};

const awareHR = async ( idOper, idUser ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/hrisk/hrisk/aware`;
    try {
      await axios.post(URL, { idOper, idUser });
    } catch (error) {
      console.error("awareHR", error);
    }
};

const protogationHR = async ( idOper, idUser ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/hrisk/hrisk/prorogation`;
    try {
      await axios.post(URL, { idOper, idUser });
    } catch (error) {
      console.error("protogationHR", error);
    }
};

const creditNoteHR = async ( idOper, idUser, note ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/hrisk/hrisk/creditN`;
  try {
    await axios.post(URL, { idOper, idUser, note });
  } catch (error) {
    console.error("creditNoteHR", error);
  }
};

/**
 * EXCEL EXPORT
**/

const getExcelHR = async () => {
  const URL = `${process.env.REACT_APP_BASEURL}/altoRiesgo/hrisk/hrisk/all/new?page=All`;
  try {
    let res = await axios(URL);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach(element => {
      let aux2 = {
        "Orden": element.increment_id,
        "Fecha alta": new Date(element.fecha_alta).toLocaleDateString('es-mx', {timeZone: 'UTC'}),
        "Actividad": element.ultimaActividad,
        "Estatus logística": element.slnombre,
        "Estatus AC": element.acnombre,
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

export{
    getAllHRList, getHRById, getHROrderD, getHRObservations, getHRProducts, 
    getHRMarks, getHRFiles, getHRHistoric, saveMessage, cancelHR, rejectHR, 
    releaseToLHR, returnHR, uploadFilesRefundHR, awareHR, uploadEvidenceHR, 
    protogationHR, creditNoteHR, getHR, searchHR, searchDetailHR, getExcelHR
};