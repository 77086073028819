import { Box, Card, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import { profiles } from "../../../utils/trazabilidad/profilesTrazabilidad";
import ExcepcionesIDataTable from "./ExcepcionesIDataTable";
import BreadCrumb from "../../general/BreadCrumb";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";

const itemsBreadCrumbs = [
  {
    label: 'Trazabilidad',
    icon: <ContentPasteSearchIcon sx={{ mr: 0.5 }} fontSize="small"/>,
    link: '/trazabilidad/inicio'
  },
  { label: 'Excepciones Internas', }
];

const ExcepcionesI = () => {
  const [nextRender, setNextRender] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {

    if(!profiles.excepcionesInt.includes(user.role.idProfile)){
      navigate("/dashboard", { replace: true });
    }else setNextRender(true);

  }, []);

  return (
    <>
      {nextRender && (<>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", }} >
        <Typography variant="h4" sx={{ my: 2 }}>Excepciones Internas</Typography>
        <Box><BreadCrumb items={itemsBreadCrumbs} /></Box>
      </Box>

      <motion.div initial={{ y: 20 }} animate={{ y: 0 }} transition={{ duration: 0.5 }}>        
        <Card sx={{mb:4}}>          
          <Box>            
            <ExcepcionesIDataTable />
          </Box>
        </Card>
      </motion.div>
      </>)}
    </>
  )
}

export default ExcepcionesI;