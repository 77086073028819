import { Box, Paper, Stack } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useEffect } from "react";
import { useState } from "react";

const DetailsDataTable = ({ row: rowProp }) => {
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const total = rowProp.items.length;

  const columns = [
    {
      field: "01",
      headerName: "SKU",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => `${params.row.SKU}`,
    },
    {
      field: "02",
      headerName: "Nombre",
      flex: 1.5,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => `${params.row.Nombre}`,
    },
    {
      field: "03",
      headerName: "Cantidad solícitada",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => `${params.row.CantidadSolicitada}`,
    },
    {
      field: "04",
      headerName: "Cantidad Recibida",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => `${params.row.CantidadRecibida}`,
    },
    {
      field: "05",
      headerName: "Cantidad Restante",
      flex: 1.5,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => `${params.row.CantidadRestante}`,
    },
    {
      field: "06",
      headerName: "Precio Total",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => `$${formatNumber(params.row.Price)}`,
    },
    {
      field: "07",
      headerName: "Fecha de Entrega",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) =>
        `${
          params.row.Fechaentrega
            ? new Date(params.row.Fechaentrega).toLocaleDateString("es-MX", {
                timeZone: "UTC",
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })
            : ""
        }`,
    },
    {
      field: "08",
      headerName: "Estatus de la línea",
      flex: 1.5,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => `${params.row.StatusLinea}`,
    },
    {
      field: "09",
      headerName: "Almacén Destino",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => `${params.row.AlmacenRecepcion}`,
    },
  ];

  if (
    document.querySelector(
      ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
    )
  )
    document
      .querySelector(
        ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
      )
      .remove();

  useEffect(() => {
    setLoading(true);
    const timeout = setTimeout(() => {
      setRows(rowProp.items);
      setLoading(false);
    }, 500);

    return () => clearTimeout(timeout);
  }, []);

  function formatNumber(num) {
    return num.toLocaleString("en-US");
  }

  return (
    <Stack
      sx={{ py: 2, height: "100%", boxSizing: "border-box" }}
      direction="column"
    >
      <Paper sx={{ flex: 1, mx: "auto", width: "90%", p: 1 }}>
        <Box style={{ height: 380, width: "100%" }}>
          <DataGridPro
            rows={rows}
            columns={columns}
            pagination
            pageSize={5}
            rowsPerPageOptions={[5]}
            getRowId={(row) => `${row.SKU}`}
            rowCount={total}
            loading={loading}
            disableColumnMenu
            disableSelectionOnClick
          />
        </Box>
      </Paper>
    </Stack>
  );
};

export default DetailsDataTable;
