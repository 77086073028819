import {
  Box,
  Button,
  Card,
  CardContent,
  LinearProgress,
  Typography,
  Tabs,
  Tab,
  Grid,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useEffect, useState } from "react";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import { useAuth } from "../../../../context/AuthContext";
import { profiles } from "../../../../utils/ventaMayoreo/profilesVentaMayoreo";
import BreadCrumb from "../../../general/BreadCrumb";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { IndexTabs } from "./IndexTabs";
import {
  getProduct,
  getRequirements,
} from "../../../../utils/ventaMayoreo/validaciones/validaciones";

const itemsBreadCrumbs = [
  {
    label: "Venta mayoreo",
    icon: <ContentPasteSearchIcon sx={{ mr: 0.5 }} fontSize="small" />,
    link: "/venta-mayoreo",
  },
  { label: "Validaciones", link: "/venta-mayoreo/validaciones" },
  { label: "Detalle" },
];
export const ValidacionesDetail = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const id1 = params.id1;
  const id2 = params.id2;
  const [isLoading, setIsLoading] = useState(false);
  const [estado, setEstado] = useState(0);

  const [requerimiento, setRequerimiento] = useState(null);
  const [producto, setProducto] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!profiles.validaciones.includes(auth.user.role.idProfile)) {
      navigate("/dashboard", { replace: true });
    } else {
      const getDetallePedido = async () => {
        try {
          let requerimientoD = await getRequirements(id1);
          setRequerimiento(requerimientoD.data[0]);
          let productoD = await getProduct(id1, id2);
          setProducto(productoD.data);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      };
      getDetallePedido();
    }
  }, [id1, id2, auth.user.data.awsid, estado]);

  const toggleEstado = () => {
    setEstado((prevEstado) => (prevEstado === 0 ? 1 : 0));
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" sx={{ my: 2 }}>
          Validaciones
        </Typography>
        <Box>
          <BreadCrumb items={itemsBreadCrumbs} />
        </Box>
      </Box>

      <motion.div
        initial={{ y: 20 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Card>
          <Box>
            <Box>
              <IndexTabs
                requerimiento={requerimiento}
                producto={producto}
                idRequerimiento={id1}
                idRequerimientoProducto={id2}
              />
            </Box>
          </Box>
        </Card>
      </motion.div>

      <Box>&nbsp;</Box>
    </>
  );
};
