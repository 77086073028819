import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Warning as WarningIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import { useAuth } from "../../../context/AuthContext";
import {
  getTransfers,
  searchTransfers,
} from "../../../utils/ventaMayoreo/traspasos/traspasos";
import DateInTable from "../../general/DateInTable";

const TraspasosDataTable = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [keyWord, setKeytoSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(null);
  const [reloadPage, setReloadPage] = useState(0);

  const [estado, setEstado] = useState(0);

  const [isAF, setIsAF] = useState(false);
  const [advancedS, setAdvancedS] = useState({
    cotizacion: "",
    requerimiento: "",
    dateMail: "",
    dateDisch: "",
    dateApro: "",
    email: "",
    salesOrder: "",
  });

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setOpen(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(null);
  };

  const columns = [
    {
      field: "transaccion",
      headerName: "Transacción",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: ({ row }) => row.numero_transaccion,
    },
    {
      field: "fechaAlta",
      headerName: "Fecha Alta",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) =>
        row.created_date ? (
          <Typography>
            <DateInTable dateString={row.created_date} />
          </Typography>
        ) : (
          "S/Fecha"
        ),
    },
    {
      field: "status",
      headerName: "Estatus",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: ({ row }) => row.cat_nombre,
      renderCell: (params) => {
        let color = "default";
        let label = params.row.cat_nombre || "S/Estatus";

        if (!params.row.id_cat_status) {
          color = "warning";
        } else if (
          params.row.id_cat_status === 33 ||
          params.row.id_cat_status === 32
        ) {
          color = "warning";
        } else if (params.row.id_cat_status === 34) {
          color = "success";
        }

        return (
          <Button
            variant="contained"
            size="small"
            color={color}
            sx={{
              textTransform: "none",
              lineHeight: 1,
              fontSize: "0.7rem",
              minWidth: 0,
            }}
          >
            {label}
          </Button>
        );
      },
    },
    {
      field: "acciones",
      headerName: "Acciones",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          <Button
            variant="contained"
            size="small"
            endIcon={<KeyboardArrowDownIcon />}
            disableElevation
            aria-controls={open ? "actions-menu" : undefined}
            aria-haspopup="menu"
            onClick={(event) => handleClick(event, params.row.id_traspaso)}
          >
            Acción
          </Button>
          <Menu
            id="actions-menu"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            anchorEl={anchorEl}
            open={open === params.row.id_traspaso}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                navigate(`detail/${params.row.id_traspaso}`);
                handleClose();
              }}
            >
              <Typography sx={{ width: "80px" }}>Ver</Typography>
            </MenuItem>
            {params.row.id_cat_status === 33 && (
              <>
                {!params.row.numero_transaccion && (
                  <>
                    <MenuItem
                      onClick={() => {
                        navigate(`detail/${params.row.id_traspaso}`);
                        handleClose();
                      }}
                    >
                      <Typography sx={{ width: "150px" }}>
                        Asignar transacción
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        navigate(`deleteTransaction/${params.row.id_traspaso}`);
                        handleClose();
                      }}
                    >
                      <Typography sx={{ width: "150px" }}>Eliminar</Typography>
                    </MenuItem>
                  </>
                )}
              </>
            )}
          </Menu>
        </>
      ),
    },
  ];

  if (
    document.querySelector(
      ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
    )
  )
    document
      .querySelector(
        ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
      )
      .remove();

  useEffect(() => {
    let active = true;

    (async () => {
      let newRows = null;

      if (isAF) {
        console.log("isAF");
        setLoading(true);
        //   newRows = await searchQuotesAdv(page, advancedS);
        console.log(newRows.data);
      } else if (
        typeof keyWord === "undefined" ||
        keyWord === undefined ||
        keyWord.length === 0
      ) {
        setLoading(true);
        newRows = await getTransfers(page);
      } else if (keyWord.length >= 3) {
        setLoading(true);
        newRows = await searchTransfers(page, keyWord);
      } else active = false;

      if (!active) {
        return;
      }
      setTotal(newRows.total);
      setRows(newRows.data);
      //   setSchools(schoolsD.data);
      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [page, keyWord, estado, isAF, advancedS, reloadPage]);

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          variant="contained"
          color="info"
          sx={{ marginY: "15px", marginRight: "12px" }}
          onClick={() => navigate("generarTraspaso")}
        >
          Generar traspasos
        </Button>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Grid container sx={{ mb: 2, mt: 2 }}>
          <Grid item md={4} sx={{ px: 2 }}>
            <Stack direction="row" spacing={1}>
              <TextField
                size="small"
                variant="outlined"
                fullWidth
                label={`Buscar`}
                value={keyWord}
                onChange={(e) => setKeytoSearch(e.target.value)}
              />
            </Stack>
          </Grid>

          <Grid item md sx={{ px: 2, textAlign: "right" }}>
            <LoadingButton
              variant="contained"
              color="info"
              //   onClick={handleOpenModalCreate}
            >
              Excel
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
      <Box style={{ height: 635, width: "100%" }}>
        <DataGridPro
          rows={rows}
          columns={columns}
          pagination
          pageSize={10}
          rowsPerPageOptions={[10]}
          getRowId={(row) => row.id_traspaso}
          rowCount={total}
          paginationMode="server"
          onPageChange={(newPage) => {
            setPage(newPage);
          }}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
          loading={loading}
          disableColumnMenu
          disableSelectionOnClick
          getDetailPanelHeight={() => "auto"}
        />
      </Box>
    </>
  );
};

export default TraspasosDataTable;
