import { profiles } from "./profilesPagos";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
const dicon = <RadioButtonUncheckedIcon fontSize='small' />;

export const pagosModules = [
    {
        label: "Solicitud de pagos",
        icon: dicon,
        slug: "",
        profiles: profiles.solicitud
    },
    {
        label: "Pagos por aprobar",
        icon: dicon,
        slug: "aprobaciones",
        profiles: profiles.aprobaciones
    },
    {
        label: "Alta proveedores",
        icon: dicon,
        slug: "alta-proveedores",
        profiles: profiles.proveedores
    },
];