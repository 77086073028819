import axios from "axios";

/**
 * GET
 */

function get_url_end(currentPage, advancedS)
{
  let aux = `${process.env.REACT_APP_BASEURL}/proveedores/etiquetas/labels/all/searchD?page=${currentPage}&downloaded=${advancedS.downloaded}`;
  
  if(advancedS.type && advancedS.type.length > 3)
    aux = aux+`&type=${advancedS.type}`;
  if(advancedS.size)
    aux = aux+`&size=${advancedS.size}`;
  if(advancedS.desde && advancedS.desde.length > 5)
    aux = aux+`&srtDate=${advancedS.desde}`;
  if(advancedS.hasta && advancedS.hasta.length > 5)
    aux = aux+`&endDate=${advancedS.hasta}`;

  return aux;
}

const getLabels = async ( page ) => 
{
  const URL = `${process.env.REACT_APP_BASEURL}/proveedores/etiquetas/labels/all/new?page=${page}`;
  try {
    const res = await axios(URL);

    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const searchLabels = async ( page, search ) => 
{
  const URL = `${process.env.REACT_APP_BASEURL}/proveedores/etiquetas/labels/all/search?page=${page}&search=${search}`;
  try {
    const res = await axios(URL);

    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const searchDetailLabels = async ( page, advancedS ) => 
{
  const URL = get_url_end(page, advancedS);
  try {
    const res = await axios(URL);

    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getPdfEEzetera = async ( id ) => 
{
  const URL = `${process.env.REACT_APP_BASEURL}/proveedores/etiquetas/labels/all/pdf?id=${id}`;
  try {
    const res = await axios(URL);

    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};


export 
{
  getLabels, searchLabels, searchDetailLabels, getPdfEEzetera
};