import { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { DescriptionOutlined } from "@mui/icons-material";

const urlsHistoricos = {
  "Pedidos y Escuelas": [
    {
      titulo: "reporte_pedidos_escuelas_2021",
      url: "https://docs.google.com/spreadsheets/d/1No8gmy3JifSyUnQSHRgLhLZ0MSv7t9-Y/edit?usp=sharing&ouid=107978340556008730254&rtpof=true&sd=true",
    },
    {
      titulo: "reporte_pedidos_escuelas_2022",
      url: "https://docs.google.com/spreadsheets/d/12C6anPMRKJY9g8_RYQsJfIJhFcE2W9Wr/edit?usp=sharing&ouid=107978340556008730254&rtpof=true&sd=true",
    },
  ],
  "NPS Tiendas": [
    {
      titulo: "nps_tiendas_2021",
      url: "https://docs.google.com/spreadsheets/d/11kLgPQPfAex8XXAI5EGqlCdds5StdcWn/edit?usp=sharing&ouid=107978340556008730254&rtpof=true&sd=true",
    },
    {
      titulo: "nps_tiendas_2022",
      url: "https://docs.google.com/spreadsheets/d/1PyTh6kqj2eY-Cpv1zIKbruLv8fs1K3_2/edit?usp=sharing&ouid=107978340556008730254&rtpof=true&sd=true",
    },
  ],
  "Venta Mayoreo General": [
    {
      titulo: "reporte_general_VM_2021",
      url: "https://docs.google.com/spreadsheets/d/1a337V6S9Q0iRLhJEboby9Qgx1kX8x0ow/edit?usp=sharing&ouid=107978340556008730254&rtpof=true&sd=true",
    },
    {
      titulo: "reporte_general_VM_2022",
      url: "https://docs.google.com/spreadsheets/d/1nKVpY8VFnLwnPPyE3fg_FCeTAPsurutO/edit?usp=sharing&ouid=107978340556008730254&rtpof=true&sd=true",
    },
  ],
  "RMA - Skus": [
    {
      titulo: "reporte_RMA_Skus_2021",
      url: "https://docs.google.com/spreadsheets/d/1KU5yyyds422_qPvOAFHtAg4cxZEJU4-o/edit?usp=sharing&ouid=107978340556008730254&rtpof=true&sd=true",
    },
    {
      titulo: "reporte_RMA_Skus_2022",
      url: "https://docs.google.com/spreadsheets/d/1I_G0EzJJw1ff7HixUm1ypopSXXSZLCkV/edit?usp=sharing&ouid=107978340556008730254&rtpof=true&sd=true",
    },
  ],
  "Aclaraciones - Skus": [
    {
      titulo: "reporte_Aclaraciones_SKUS_2021",
      url: "https://docs.google.com/spreadsheets/d/1MZEMiYKvG43mPMtjPDyCWNnTEw317Pta/edit?usp=sharing&ouid=107978340556008730254&rtpof=true&sd=true",
    },
    {
      titulo: "reporte_Aclaraciones_SKUS_2022",
      url: "https://docs.google.com/spreadsheets/d/1nns3P9qg5EkuFClWpkb_TzK3hFMFkrIN/edit?usp=sharing&ouid=107978340556008730254&rtpof=true&sd=true",
    },
  ],
  "Casos": [
    {
      titulo: "reporte_casos-2021",
      url: "https://docs.google.com/spreadsheets/d/1cLwV9PDn9Mw6M98nId93NVyZeBNmU3u_/edit?usp=sharing&ouid=107978340556008730254&rtpof=true&sd=true",
    },
    {
      titulo: "reporte_casos_2022",
      url: "https://docs.google.com/spreadsheets/d/19lKFE_yBE-Zs7k_b4BN7wdinCv9COUxT/edit?usp=sharing&ouid=107978340556008730254&rtpof=true&sd=true",
    },
  ],
  "Casos y Asociados": [
    {
      titulo: "CRM_asociados_2021",
      url: "https://docs.google.com/spreadsheets/d/1HVMDjlTyWBb8nXUReS7Mhm2oXHaB0baJ/edit?usp=sharing&ouid=107978340556008730254&rtpof=true&sd=true",
    },
    {
      titulo: "CRM_asociados_2022",
      url: "https://docs.google.com/spreadsheets/d/1ol0GZ89tDi84XfmbHxcZBuF7mJAIJR9E/edit?usp=sharing&ouid=107978340556008730254&rtpof=true&sd=true",
    },
  ],
  "Inventarios": [
    {
      titulo: "Reporte_de_inventarios_2021",
      url: "https://docs.google.com/spreadsheets/d/1QyQJU8BlHcR0G0m0-H2-euG-fEYF8si5/edit?usp=sharing&ouid=107978340556008730254&rtpof=true&sd=true",
    },
    {
      titulo: "Reporte_de_inventarios_2022",
      url: "https://docs.google.com/spreadsheets/d/1Q2MpMvXct1C-NBfaTlkV0MzqDMMsGr4G/edit?usp=sharing&ouid=107978340556008730254&rtpof=true&sd=true",
    },
  ],
  "Comentarios - Aclaraciones": [
    {
      titulo: "Aclaraciones_comentarios_2021",
      url: "https://docs.google.com/spreadsheets/d/1n_MgyXPUHeCQm5bS9Ucn_GnFoJ1GNaW4/edit?usp=sharing&ouid=107978340556008730254&rtpof=true&sd=true",
    },
    {
      titulo: "Aclaraciones_comentarios_2022",
      url: "https://docs.google.com/spreadsheets/d/1Puh2O1C0fG-dk9FkXbJoK4VdqO8dTjWR/edit?usp=sharing&ouid=107978340556008730254&rtpof=true&sd=true",
    },
  ],
  "Tickets": [
    {
      titulo: "Reporte_tickets_2021",
      url: "https://docs.google.com/spreadsheets/d/1lDG2SIoyLBcagwqK_lWweV7U9Hqivtjk/edit?usp=sharing&ouid=107978340556008730254&rtpof=true&sd=true",
    },
    {
      titulo: "Reporte_tickets_2022",
      url: "https://docs.google.com/spreadsheets/d/1rNdP6LC0mgD0vlMVGsmQF_IUchIfJ2Mp/edit?usp=sharing&ouid=107978340556008730254&rtpof=true&sd=true",
    },
  ],
  "Pedidos por fecha": [
    {
      titulo: "Reporte_PedidosPorFecha_2021",
      url: "https://docs.google.com/spreadsheets/d/16mqzkiovzdo2H6RlXsqstBwuSf6DOBop/edit?usp=sharing&ouid=107978340556008730254&rtpof=true&sd=true",
    },
    {
      titulo: "Reporte_PedidosPorFecha_2022",
      url: "https://docs.google.com/spreadsheets/d/1DW4RzAO7iP_CCo2WTAbS9TROhFT5Mgyq/edit?usp=sharing&ouid=107978340556008730254&rtpof=true&sd=true",
    },
  ],
  "Cupones Usados": [
    {
      titulo: "reporteCuponesUsados_2021",
      url: "https://docs.google.com/spreadsheets/d/1nbYxuohiYKt9sIxDZf1dZji_HYFremUH/edit?usp=sharing&ouid=107978340556008730254&rtpof=true&sd=true",
    },
    {
      titulo: "reporteCuponesUsados_2022",
      url: "https://docs.google.com/spreadsheets/d/1mlPFl1du6xj5yWkqSDAJDPKI1upw1BF4/edit?usp=sharing&ouid=107978340556008730254&rtpof=true&sd=true",
    },
  ],
  "Carritos Abandonados": [
    {
      titulo: "carritosabandonados_2021",
      url: "https://docs.google.com/spreadsheets/d/1aEBHRlAWhjWRRw8T4DFUdTP_MQ8ire9t/edit?usp=sharing&ouid=107978340556008730254&rtpof=true&sd=true",
    },
    {
      titulo: "carritosabandonados_2022",
      url: "https://docs.google.com/spreadsheets/d/1j2NtuJm0yERLRW3xGhY0Llk_sZJCbPPS/edit?usp=sharing&ouid=107978340556008730254&rtpof=true&sd=true",
    },
  ],
  "Poblacion de Colegios": [
    {
      titulo: "listadopoblaciondecolegios_2021",
      url: "https://docs.google.com/spreadsheets/d/1veV8ogOz-KjyhiMwbhnEnV5YG334H-Al/edit?usp=sharing&ouid=107978340556008730254&rtpof=true&sd=true",
    },
    {
      titulo: "listadopoblaciondecolegios_2022",
      url: "https://docs.google.com/spreadsheets/d/1-iSzNgAKj1YSdigTJ7r5pelbqOkFL1Fn/edit?usp=sharing&ouid=107978340556008730254&rtpof=true&sd=true",
    },
  ],
  "Out of Stock Subscribers": [
    {
      titulo: "OutofStockSubscribers_2021",
      url: "https://docs.google.com/spreadsheets/d/1Fk4R7jU9K8QAnPPOlt4hdHTuqT4pTQen/edit?usp=sharing&ouid=107978340556008730254&rtpof=true&sd=true",
    },
    {
      titulo: "OutofStockSubscribers_2022",
      url: "https://docs.google.com/spreadsheets/d/1gc5CjV5TQMbEWGdB3-G5V0XlHTmALU8j/edit?usp=sharing&ouid=107978340556008730254&rtpof=true&sd=true",
    },
  ],
  "M Team": [
    {
      titulo: "reportem_team_2021",
      url: "https://docs.google.com/spreadsheets/d/1leMTNGIJbO-_pC91kP3R--0A_eynOpYS/edit?usp=sharing&ouid=107978340556008730254&rtpof=true&sd=true",
    },
    {
      titulo: "reportem_team_2022",
      url: "https://docs.google.com/spreadsheets/d/1d2DOhkJ1fdXLOyrs8nUvkeltP8l5mwEf/edit?usp=sharing&ouid=107978340556008730254&rtpof=true&sd=true",
    },
  ],
  "Montos Web M": [
    {
      titulo: "reporte_web_m_2021",
      url: "https://docs.google.com/spreadsheets/d/1MM6ZGt6hGFb6Imi-h5KuRUNPhXBW6UjZ/edit?usp=sharing&ouid=107978340556008730254&rtpof=true&sd=true",
    },
    {
      titulo: "reporte_web_m_2022",
      url: "https://docs.google.com/spreadsheets/d/1zx9bL4RsVrVHpMpa2ieVZvF9XT_E2N46/edit?usp=sharing&ouid=107978340556008730254&rtpof=true&sd=true",
    },
  ],
  "NPS": [
    {
      titulo: "reporte_nps_2021",
      url: "https://docs.google.com/spreadsheets/d/1fNrhIgXLEDUIvW0WliBBlQpPKISgXpj_/edit?usp=sharing&ouid=107978340556008730254&rtpof=true&sd=true",
    },
    {
      titulo: "reporte_nps_2022",
      url: "https://docs.google.com/spreadsheets/d/1RLAXzj-tDeMTTWI5kloqBGZpnO1oZ0YS/edit?usp=sharing&ouid=107978340556008730254&rtpof=true&sd=true",
    },
  ],
  "Gastos de Envio": [
    {
      titulo: "reportegastosEnvios_2021",
      url: "https://docs.google.com/spreadsheets/d/1AmwRnU-p4zbWva-fY0JUwD-iH3Sm6eFp/edit?usp=sharing&ouid=107978340556008730254&rtpof=true&sd=true",
    },
    {
      titulo: "reportegastosEnvios_2022",
      url: "https://docs.google.com/spreadsheets/d/1g6-iLd_ApbmslJO1J-DliB1aEnDxKogp/edit?usp=sharing&ouid=107978340556008730254&rtpof=true&sd=true",
    },
  ],
};

export const HistoricoLinks = ({ row }) => {
  const [urls, setUrls] = useState([]);

  useEffect(() => {
    setUrls(urlsHistoricos[row.report] || []);
  }, [row]);

  if (urls.length === 0) {
    return null;
  }

  return (
    <div>
      <Typography variant="h6" sx={{ textAlign: "center", margin: "auto" }}>
        Historico
      </Typography>
      <div>
        <List>
          {urls.map((urlObj, index) => (
            <ListItem
              disablePadding
              key={index}
              sx={{
                ":hover": {
                  backgroundColor: "rgb(224 224 224)",
                },
                borderTop: "solid rgb(224 224 224)",
                borderBottom:
                  index === urls.length - 1 ? "solid rgb(224 224 224)" : "none",
              }}
            >
              <ListItemButton
                component="a"
                href={urlObj.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ListItemIcon>
                  <DescriptionOutlined />
                </ListItemIcon>
                <ListItemText primary={urlObj.titulo} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );
};
