import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import DateInTable from "../../../general/DateInTable";

export const OrdenVentaDetailItem = ({ detalle }) => {
  const { requerimiento, cotizacion } = detalle;

  function encodeUtf8(val) {
    try {
      return decodeURIComponent(escape(val));
    } catch (err) {
      return val;
    }
  }
  return (
    <>
      <Grid container>
        {detalle && (
          <Grid item md={4}>
            <List>
              <ListItem>
                <ListItemText
                  primary="Número de requerimiento:"
                  secondary={requerimiento.numero_requerimiento}
                  sx={{ span: { fontWeight: "500" } }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Orden de venta:"
                  secondary={
                    cotizacion.orden_venta
                      ? encodeUtf8(cotizacion.orden_venta)
                      : "S/Orden de venta"
                  }
                  sx={{ span: { fontWeight: "500" } }}
                />
              </ListItem>
            </List>
          </Grid>
        )}
        {detalle && (
          <Grid item md={4}>
            <List>
              <List>
                <ListItem>
                  <ListItemText
                    primary="Número de cotización:"
                    secondary={cotizacion.numero_cotizacion}
                    sx={{ span: { fontWeight: "500" } }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Fecha de alta:"
                    secondary={
                      cotizacion.fecha_cotizacion ? (
                        <Typography
                          component="div"
                          sx={{ h6: { fontWeight: "normal" } }}
                        >
                          <DateInTable
                            dateString={cotizacion.fecha_cotizacion}
                          />
                        </Typography>
                      ) : (
                        "S/Fecha"
                      )
                    }
                    sx={{ span: { fontWeight: "500" } }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Fecha de aprobación:"
                    secondary={
                      cotizacion.fecha_aprobacion ? (
                        <Typography
                          component="div"
                          sx={{ h6: { fontWeight: "normal" } }}
                        >
                          <DateInTable
                            dateString={cotizacion.fecha_aprobacion}
                          />
                        </Typography>
                      ) : (
                        "S/Fecha"
                      )
                    }
                    sx={{ span: { fontWeight: "500" } }}
                  />
                </ListItem>
              </List>
            </List>
          </Grid>
        )}
        {detalle && (
          <Grid item md={4}>
            <List>
              <List>
                <ListItem>
                  <ListItemText
                    primary="Status:"
                    secondary={
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        sx={{
                          textTransform: "none",
                          lineHeight: 1,
                          fontSize: "0.7rem",
                          minWidth: 0,
                        }}
                      >
                        {encodeUtf8(cotizacion.nombre_categoria)}
                      </Button>
                    }
                    sx={{ span: { fontWeight: "500" } }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Forma de pago:"
                    secondary={
                      cotizacion.forma_pago
                        ? encodeUtf8(cotizacion.forma_pago)
                        : "S/Forma de pago"
                    }
                    sx={{ span: { fontWeight: "500" } }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Plazo:"
                    secondary={
                      cotizacion.plazo
                        ? encodeUtf8(cotizacion.plazo)
                        : "S/Plazo"
                    }
                    sx={{ span: { fontWeight: "500" } }}
                  />
                </ListItem>
              </List>
            </List>
          </Grid>
        )}
      </Grid>
    </>
  );
};
