import React, { useEffect, useState } from "react";
import { Warning as WarningIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import { LoadingButton } from "@mui/lab";
import { DataGridPro } from "@mui/x-data-grid-pro";
import AdvShearch from "./AdvShearch";
import { getOrders, searchSaleOrders } from "../../../utils/ventaMayoreo/ordenesVenta/ordenesVenta";
import DateInTable from "../../general/DateInTable";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const OrdenesVentaDataTable = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [keyWord, setKeytoSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(null);
  const [reloadPage, setReloadPage] = useState(0);

  const [estado, setEstado] = useState(0);
  const [isAF, setIsAF] = useState(false);
  const [advancedS, setAdvancedS] = useState({
    cotizacion: "",
    requerimiento: "",
    dateMail: "",
    dateDisch: "",
    dateApro: "",
    email: "",
    salesOrder: "",
  });

  const handleChangeAdvancedS = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setAdvancedS((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
  };

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setOpen(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(null);
  };

  const columns = [
    {
      field: "ordenDeVenta",
      headerName: "Orden de Venta",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => row.orden_venta,
    },
    {
      field: "numero",
      headerName: "Número",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => row.numero_cotizacion,
    },
    {
      field: "tipo",
      headerName: "Tipo",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => (
        <Button
          variant="contained"
          color="info"
          size="small"
          sx={{
            textTransform: "none",
            lineHeight: 1,
            fontSize: "0.7rem",
            minWidth: 0,
          }}
        >
          COTIZACIÓN
        </Button>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => (
        <Button
          variant="contained"
          color={
            row.id_status_ov === 39 || row.id_status_ov === 40
              ? "warning"
              : "success"
          }
          size="small"
          sx={{
            textTransform: "none",
            lineHeight: 1,
            fontSize: "0.7rem",
            minWidth: 0,
          }}
        >
          {row.nombre}
        </Button>
      ),
    },
    {
      field: "fechaAlta",
      headerName: "Fecha alta",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) =>
        row.fecha_alta ? (
          <Typography>
            <DateInTable dateString={row.fecha_alta} />
          </Typography>
        ) : (
          "S/Fecha"
        ),
    },
    {
      field: "contactoEmpresa",
      headerName: "Contacto/Empresa",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) =>
        row.nombre_completo ? row.nombre_completo : "S/Contacto",
    },
    {
      field: "acciones",
      headerName: "Acciones",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          <Button
            variant="contained"
            size="small"
            endIcon={<KeyboardArrowDownIcon />}
            disableElevation
            aria-controls={open ? this : undefined}
            aria-haspopup="menu"
            onClick={(event) => handleClick(event, params.row.id_requerimiento)}
          >
            Acción
          </Button>
          <Menu
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            anchorEl={anchorEl}
            open={open === params.row.id_requerimiento ? true : false}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                navigate(`detail/${params.row.id_requerimiento}`);
                handleClose();
              }}
            >
              <Typography sx={{width: "80px"}}>Ver</Typography>
            </MenuItem>
          </Menu>
        </>
      ),
    },
  ];

  if (
    document.querySelector(
      ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
    )
  )
    document
      .querySelector(
        ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
      )
      .remove();

  useEffect(() => {
    let active = true;

    (async () => {
      let newRows = null;
      let schoolsD = [];

      if (isAF) {
        setLoading(true);
        // newRows = await searchQuotesAdv(page, advancedS);
        // console.log(newRows.data);
      } else if (
        typeof keyWord === "undefined" ||
        keyWord === undefined ||
        keyWord.length === 0
      ) {
        setLoading(true);
        newRows = await getOrders(page);
        // schoolsD = await getSchools();
      } else if (keyWord.length >= 3) {
        setLoading(true);
        newRows = await searchSaleOrders(page, keyWord);
        // schoolsD = await getSchools();
      } else active = false;

      if (!active) {
        return;
      }
      setTotal(newRows.total);
      setRows(newRows.data);
      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [page, keyWord, estado, isAF, advancedS, reloadPage]);

  return (
    <>
      <AdvShearch
        advancedS={advancedS}
        handleChangeAdvancedS={handleChangeAdvancedS}
        setIsAF={setIsAF}
      />

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Grid container sx={{ mb: 2, mt: 2 }}>
          <Grid item md={4} sx={{ px: 2 }}>
            <Stack direction="row" spacing={1}>
              <TextField
                size="small"
                variant="outlined"
                fullWidth
                label={`Buscar`}
                value={keyWord}
                onChange={(e) => setKeytoSearch(e.target.value)}
              />
            </Stack>
          </Grid>

          {/* <Grid item md sx={{ px: 2, textAlign: "right" }}>
            <LoadingButton
              variant="contained"
              color="info"
              //   onClick={handleOpenModalCreate}
            >
              Excel
            </LoadingButton>
          </Grid> */}
        </Grid>
      </Box>
      <Box style={{ height: 635, width: "100%" }}>
        <DataGridPro
          rows={rows}
          columns={columns}
          pagination
          pageSize={10}
          rowsPerPageOptions={[10]}
          getRowId={(row) => row.id_requerimiento}
          rowCount={total}
          paginationMode="server"
          onPageChange={(newPage) => {
            setPage(newPage);
          }}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
          loading={loading}
          disableColumnMenu
          disableSelectionOnClick
          getDetailPanelHeight={() => "auto"}
        />
      </Box>
    </>
  );
};

export default OrdenesVentaDataTable;
