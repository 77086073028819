import {
  Box,
  Button,
  Card,
  CardContent,
  LinearProgress,
  Typography,
  Tabs,
  Tab,
  Grid,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ForumIcon from "@mui/icons-material/Forum";
import { useEffect, useState } from "react";
import { useAuth } from "../../../context/AuthContext";
import { profiles } from "../../../utils/trazabilidad/profilesTrazabilidad";
import RmaDSDetail from "./RmaDSDetail";
import RmaMessages from "./RmaMessages";
import RmaStatus from "./RmaStatus";
import IndexTabs from "./tabs/IndexTabs";
import { getRMAById, getStatusRma } from "../../../utils/trazabilidad/rma/rma";
import BreadCrumb from "../../general/BreadCrumb";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";

const itemsBreadCrumbs = [
  {
    label: "Trazabilidad",
    icon: <ContentPasteSearchIcon sx={{ mr: 0.5 }} fontSize="small" />,
    link: "/trazabilidad/inicio",
  },
  { label: "RMA", link: "/trazabilidad/rma" },
  { label: "Detalle" },
];

const RmaDetail = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;
  const [isLoading, setIsLoading] = useState(false);
  const [detalle, setDetalle] = useState(null);
  const [status, setStatus] = useState(null);
  
  useEffect(() => {

    if (!profiles.rma.includes(auth.user.role.idProfile)) {
      navigate("/dashboard", { replace: true });
    } else {
      const getDetallePedido = async () => {
        try {
          setIsLoading(true);
          const res = await getRMAById(id, auth.user.data.awsid);
          setDetalle(res);
          const resp = await getStatusRma(id);
          setStatus(resp);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      };
      getDetallePedido();
    }
  }, [id, auth.user.data.awsid]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" sx={{ my: 2 }}>
          Detalle Rma
        </Typography>
        <Box>
          <BreadCrumb items={itemsBreadCrumbs} />
        </Box>
      </Box>

      <motion.div
        initial={{ y: 20 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Card>
          <Box>
            <Box>
              <Grid container spacing={2}>
                <Grid item md={7}>
                  <Typography variant="h6" sx={{ ml: 2, my: 2, mb: 0 }}>
                    <AssignmentIcon sx={{ m: 0 }} /> Detalles del pedido
                  </Typography>
                  {isLoading && <LinearProgress />}
                  {detalle && <RmaDSDetail rmaId={id} detalle={detalle} />}
                </Grid>
                <Grid item md={5}>
                  <Box sx={{ mr: 2 }}>
                    <Typography variant="h6" sx={{ my: 2 }}>
                      <ForumIcon sx={{ m: 0 }} /> Mensajes
                    </Typography>
                    {detalle && (
                      <RmaMessages
                        rmaId={id}
                        incrementId={detalle.order_increment_id}
                      />
                    )}
                    {detalle && status && (
                      <RmaStatus rmaId={id} status={status} />
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {detalle && status && (
              <IndexTabs rmaId={id} rmaDl={detalle} status={status} />
            )}
          </Box>
        </Card>
      </motion.div>

      <Box>&nbsp;</Box>
    </>
  );
};

export default RmaDetail;
