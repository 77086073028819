import { Autocomplete, Button, Grid, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { useDialogs } from "../../context/DialogsContext";
import { createBackOffice } from "../../utils/crm/backoffice";

const BackOfficeOnCrm = ({ solution, channel, college, email }) => {
  const [isLoading, setIsLoading] = useState(false);
  const auth = useAuth();
  const { setOpenNuevoCasoOnCrmDialog } = useDialogs();

  const [pedido, setPedido] = useState("");
  const [aclaracion, setAclaracion] = useState(6);
  const [tipificacion, setTipificacion] = useState(1);

  const [details, setDetails] = useState(null);
  const [aclaraciones, setAclaraciones] = useState(null);
  const [tipificaciones, setTipificaciones] = useState(null);

  const hadleCloseDialog = () => {
    setPedido("");
    setAclaracion(6);
    setTipificacion(1);
    setDetails(null);
    setOpenNuevoCasoOnCrmDialog(false);
  };

  const handleCreateBackOffice = async () => {
    try {
      setIsLoading(true);
      await createBackOffice({
        tipificacion,
        pedido,
        awsid: auth.user.data.awsid,
        aclaracion,
        email: email,
        guia: details.guia,
        solution,
        channel,
        college,
      });

      hadleCloseDialog();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (pedido.length < 9) return;

    const getDetails = async () => {
      const url = `${process.env.REACT_APP_BASEURL}/crm/searcher/orders?id=${pedido}&mail&tel`;
      try {
        setIsLoading(true);
        const res = await axios.get(url);
        if (res.data.success) {
          setDetails(res.data.data[0]);
          console.log(res.data.data[0]);
        }
      } catch (error) {
        console.error("getDetails", error.message);
      } finally {
        setIsLoading(false);
      }
    };

    getDetails();
  }, [pedido]);

  useEffect(() => {
    const getResourcesForNewCase = async () => {
      const url = `${process.env.REACT_APP_BASEURL}/crm/cases/resources`;
      try {
        const res = await axios.get(url);
        if (res.data.success) {
          const resources = res.data.data;

          const aclarations = resources.reduce((result, item) => {
            if (!result.filter((r) => r.id === item.tipo_id).length)
              result.push({ id: item.tipo_id, name: item.tipo_name });
            return result;
          }, []);
          setAclaraciones(aclarations);

          const typifications = {};
          resources.forEach((res) => {
            if (!typifications[res.tipo_id]) typifications[res.tipo_id] = [{ id: res.tema_id, name: res.tema_name }];
            else typifications[res.tipo_id].push({ id: res.tema_id, name: res.tema_name });
          });
          setTipificaciones(typifications);
        }
      } catch (error) {
        console.error("getResourcesForNewCase", error.message);
      }
    };

    getResourcesForNewCase();
  }, []);

  return (
    <Box>
      <TextField
        autoFocus
        margin="dense"
        label="No. Pedido"
        variant="filled"
        disabled={isLoading}
        value={pedido}
        onChange={(e) => setPedido(+e.target.value)}
      />
      {details && (
        <Box sx={{ mt: 4 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Información del pedido</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                <Typography component="span" sx={{ fontWeight: "bold" }}>
                  Número de pedido:
                </Typography>{" "}
                <Typography component="span">{details.increment_id}</Typography>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                <Typography component="span" sx={{ fontWeight: "bold" }}>
                  Nombre del cliente:
                </Typography>{" "}
                <Typography component="span">{details.customer_name}</Typography>{" "}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                <Typography component="span" sx={{ fontWeight: "bold" }}>
                  Fecha de creación:
                </Typography>{" "}
                <Typography component="span"> {details.created_at}</Typography>{" "}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                <Typography component="span" sx={{ fontWeight: "bold" }}>
                  Teléfono:
                </Typography>{" "}
                <Typography component="span">{details.telephone}</Typography>{" "}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                <Typography component="span" sx={{ fontWeight: "bold" }}>
                  Fecha de pago:
                </Typography>{" "}
                <Typography component="span">{details.payment_date}</Typography>{" "}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                <Typography component="span" sx={{ fontWeight: "bold" }}>
                  Email:
                </Typography>{" "}
                <Typography component="span">{details.email}</Typography>{" "}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <Typography component="span" sx={{ fontWeight: "bold" }}>
                  Escuela:
                </Typography>{" "}
                <Typography component="span">{details.school_name ?? ""}</Typography>{" "}
              </Typography>
            </Grid>
            {aclaraciones && tipificaciones && (
              <>
                <Grid item xs={6}>
                  <Autocomplete
                    disablePortal
                    isOptionEqualToValue={(e) => true}
                    options={aclaraciones.map((ac) => ({ id: ac.id, label: ac.name }))}
                    sx={{
                      width: "100%",
                      mb: 2,
                    }}
                    onChange={(e, val) => {
                      setAclaracion(val.id);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tipo de aclaracion"
                        helperText="Selecciona el tipo de aclaracion"
                        variant="filled"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    disablePortal
                    isOptionEqualToValue={(e) => true}
                    options={tipificaciones[aclaracion].map((tip) => ({ id: tip.id, label: tip.name }))}
                    sx={{
                      width: "100%",
                      mb: 2,
                    }}
                    onChange={(e, val) => {
                      setTipificacion(val.id);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tipificacion"
                        helperText="Selecciona la tipificacion"
                        variant="filled"
                      />
                    )}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      )}
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
        <Button sx={{ mr: 1 }} onClick={hadleCloseDialog}>
          Cancelar
        </Button>
        <Button variant="contained" disableElevation disabled={isLoading} onClick={handleCreateBackOffice}>
          Crear caso Back Office
        </Button>
      </Box>
    </Box>
  );
};

export default BackOfficeOnCrm;
