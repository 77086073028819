import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { ProductosDataTable } from "./ProductosDataTable";
import DateInTable from "../../../../general/DateInTable";

const STATUS_CONFIG = {
  default: { color: "error", label: (name) => name },
  "": { color: "warning", label: () => "S/Estatus" },
  "En proceso de validación": { color: "warning", label: (name) => name },
  Creado: { color: "primary", label: (name) => name },
  Terminado: { color: "success", label: (name) => name },
  Validado: { color: "success", label: (name) => name },
  Cotizado: { color: "success", label: (name) => name },
};

function encodeUtf8(val) {
  try {
    return decodeURIComponent(escape(val));
  } catch (err) {
    return val;
  }
}

function renderStatusButton(cat_nombre) {
  const config = STATUS_CONFIG[encodeUtf8(cat_nombre)] || STATUS_CONFIG.default;

  return (
    <Button
      variant="contained"
      size="small"
      color={config.color}
      sx={{
        textTransform: "none",
        lineHeight: 1,
        fontSize: "0.7rem",
        minWidth: 0,
        marginY: "4px"
      }}
    >
      {config.label(encodeUtf8(cat_nombre))}
    </Button>
  );
}

export const InfoTab = ({
  requerimiento,
  producto,
  idRequerimiento,
  idRequerimientoProducto,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item md={3.5}>
        <Paper
          elevation={3}
          sx={{
            padding: "8px",
            borderTop: "3px solid #d2d6de",
            minHeight: "170px",
          }}
        >
          {requerimiento ? (
            <>
              <Box sx={{ borderBottom: "1px solid #f4f4f4;" }}>
                <Typography variant="h6" style={{ paddingY: "12px" }}>
                  Requerimiento {requerimiento.numero_requerimiento}
                </Typography>
              </Box>
              <Table sx={{ marginTop: "12px" }}>
                <TableBody sx={{ td: { padding: 0 } }}>
                  <TableRow sx={{ backgroundColor: "#f9f9f9" }}>
                    <TableCell>
                      <Typography variant="subtitle2">
                        Fecha de alta:
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {requerimiento.fecha_alta ? (
                        <Typography
                          component="div"
                          sx={{ h6: { fontWeight: "normal" } }}
                        >
                          <DateInTable dateString={requerimiento.fecha_alta} />
                        </Typography>
                      ) : (
                        "S/Fecha"
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle2">Estatus:</Typography>
                    </TableCell>
                    <TableCell align="left">
                      {renderStatusButton(requerimiento.cat_nombre)}
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ backgroundColor: "#f9f9f9" }}>
                    <TableCell>
                      <Typography variant="subtitle2">
                        Fecha en validación:
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {requerimiento.fecha_en_validacion ? (
                        <Typography
                          component="div"
                          sx={{ h6: { fontWeight: "normal" } }}
                        >
                          <DateInTable
                            dateString={requerimiento.fecha_en_validacion}
                          />
                        </Typography>
                      ) : (
                        "S/Fecha"
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle2">
                        Fecha validado:
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {requerimiento.fecha_validacion ? (
                        <Typography
                          component="div"
                          sx={{ h6: { fontWeight: "normal" } }}
                        >
                          <DateInTable
                            dateString={requerimiento.fecha_validacion}
                          />
                        </Typography>
                      ) : (
                        "S/Fecha"
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ backgroundColor: "#f9f9f9" }}>
                    <TableCell>
                      <Typography variant="subtitle2">
                        Fecha último movimiento:
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {requerimiento.fecha_ultimo_movimiento ? (
                        <Typography
                          component="div"
                          sx={{ h6: { fontWeight: "normal" } }}
                        >
                          <DateInTable
                            dateString={requerimiento.fecha_ultimo_movimiento}
                          />
                        </Typography>
                      ) : (
                        "S/Fecha"
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "160px",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Paper>
      </Grid>
      <Grid item md={8.5}>
        <Paper
          elevation={2}
          sx={{
            padding: "8px",
            borderTop: "3px solid #d2d6de",
            minHeight: "142px",
          }}
        >
          {producto ? (
            <>
              <Box sx={{ borderBottom: "1px solid #f4f4f4" }}>
                <Typography variant="h6" sx={{ py: "12px" }}>
                  Información del producto
                </Typography>
              </Box>

              <Grid container>
                <Grid item xs={7}>
                  <Table>
                    <TableBody sx={{ td: { padding: 0 } }}>
                      <TableRow sx={{ backgroundColor: "#f9f9f9" }}>
                        <TableCell>
                          <Typography variant="subtitle2">Nombre:</Typography>
                        </TableCell>
                        <TableCell align="left">
                          {producto.nombre ? encodeUtf8(producto.nombre) : ""}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography variant="subtitle2">Autor:</Typography>
                        </TableCell>
                        <TableCell align="left">
                          {producto.autor ? encodeUtf8(producto.autor) : ""}
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ backgroundColor: "#f9f9f9" }}>
                        <TableCell>
                          <Typography variant="subtitle2">Edición:</Typography>
                        </TableCell>
                        <TableCell align="left">
                          {producto.edicion ? encodeUtf8(producto.edicion) : ""}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>

                <Grid item xs={5}>
                  <Table>
                    <TableBody sx={{ td: { padding: 0 } }}>
                      <TableRow sx={{ backgroundColor: "#f9f9f9" }}>
                        <TableCell>
                          <Typography variant="subtitle2">SKU:</Typography>
                        </TableCell>
                        <TableCell align="left">
                          {producto.sku ? encodeUtf8(producto.sku) : ""}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography variant="subtitle2">
                            Editorial:
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          {producto.editorial
                            ? encodeUtf8(producto.editorial)
                            : ""}
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ backgroundColor: "#f9f9f9" }}>
                        <TableCell>
                          <Typography variant="subtitle2">Cantidad:</Typography>
                        </TableCell>
                        <TableCell align="left">
                          {producto.cantidad
                            ? encodeUtf8(producto.cantidad)
                            : ""}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "125px",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Paper>

        <Paper
          elevation={2}
          sx={{
            padding: "8px",
            borderTop: "3px solid #d2d6de",
            marginTop: "20px",
          }}
        >
          <ProductosDataTable
            idRequerimiento={idRequerimiento}
            idRequerimientoProducto={idRequerimientoProducto}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};
