import { Box, LinearProgress, CircularProgress, Typography, Table, TableCell, TableContainer, TableRow, Grid } from "@mui/material";
import { useEffect, useState, Fragment } from "react";

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TimelineOppositeContent, { timelineOppositeContentClasses} from '@mui/lab/TimelineOppositeContent';

import { getSeasonMoves } from "../../../../utils/vales/temporadas/temporadas";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

import local from "dayjs/plugin/localizedFormat";
import relative from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";

import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

dayjs.locale("es-mx");
dayjs.extend(local);
dayjs.extend(relative);
dayjs.extend(utc);

const chipColors = {
    "fa-share": "#0073b7",
    "fa-plus": "#0073b7",
};

const chipIcons = {
    "fa-share": <SendIcon fontSize="small" />,
    "fa-plus": <AddIcon fontSize="small"/>,
};

const HistoryTab = ({ id_beneficiary, id_season }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [detalle, setDetalle] = useState(null);

    const getHistory = async () => {
        let rObj = [];
        try {
            setIsLoading(true);
            const res = await getSeasonMoves (id_beneficiary, id_season);
            res.data.map(function (obj) {
                console.log(obj);
                if (obj !== null) {
                    rObj.push(obj);
                }
            });    
            setDetalle(rObj);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const nombreCompleto = (nombre, apellidoP, apellidoM) => {
        const usuario = [];
        if (nombre) {
            usuario.push(nombre + " ");
        }
        if (apellidoP) {
            usuario.push(apellidoP + " ");
        }
        if (apellidoM) {
            usuario.push(apellidoM);
        }
        return (
            usuario
        );
    };

    useEffect(() => {
        getHistory();
    }, [id_beneficiary, id_season]);
    return (<>
        {isLoading && <LinearProgress />}
        {detalle && (<>
            <Timeline sx={{ [`& .${timelineOppositeContentClasses.root}`]: { flex: 0.3, } }}>
                {detalle.map(({nombre,apellidoP,apellidoM,descripcion,creation_date, before, after,icono}) => (
                    <TimelineItem>
                        <TimelineOppositeContent sx={{ m: 'auto 0' }} align="right" variant="body2" color="text.secondary">
                            <ListItemText>
                                <Typography><strong>{nombreCompleto(nombre, apellidoP, apellidoM)}</strong></Typography>
                            </ListItemText>
                            <ListItemText>
                                <Typography variant="caption">{dayjs.utc(creation_date).format("DD MMM YYYY, hh:mm A")}</Typography>
                            </ListItemText>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot sx={{ backgroundColor: chipColors[icono], color: "#fff" }}>
                                    {chipIcons[icono]}
                                </TimelineDot>
                            <TimelineConnector>
                            </TimelineConnector>
                        </TimelineSeparator>
                        <TimelineContent>
                            <ListItemText primary={descripcion} />
                            <ListItemText>
                                <Typography variant="caption">{before + ' - ' + after }</Typography>
                            </ListItemText>
                        </TimelineContent>
                    </TimelineItem>
                ))}
            </Timeline>

        </>)}
    </>);
};

export default HistoryTab;