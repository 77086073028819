import { useEffect, useState } from "react";
import { useAuth } from "../../../context/AuthContext";
import { Box, Button, Chip, Grid, Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  getMoveInv,
  getMovePDF,
  searchMoveInv,
} from "../../../utils/proveedores/movimientosInventario/movimientosInventario";
import SearchIcon from "@mui/icons-material/Search";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import EditIcon from "@mui/icons-material/Edit";
import MenuIcon from "@mui/icons-material/Menu";
import CardsInfo from "./CardsInfo";
import ExcelFile from "../../general/ExcelFile";
import DetailsDataTable from "./DetailsDataTable";

const MovimientosInvDataTable = () => {
  const { user } = useAuth();
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [initDate, setInitDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [keyWord, setKeytoSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [initDateF, setInitDateF] = useState(null);
  const [endDateF, setEndDateF] = useState(null);
  const [reload, setReload] = useState(0);
  const [infoCards, setInfoCards] = useState([]);
  const [excelData, setExcelData] = useState(null);
  const [isELoading, setIsELoading] = useState(false);

  const chipColorsByStatus = {
    Cerrado: "#5cb85c",
    Abierta: "#d9534f",
    Cancelado: "#605ca8",
  };

  const columns = [
    {
      field: "pOrder",
      headerName: "Orden de Compra",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => `${params.row.OrdendeCompra}`,
    },
    {
      field: "supplier",
      headerName: "Proveedor",
      flex: 1.5,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => `${params.row.Proveedor}`,
    },
    {
      field: "status",
      headerName: "Estatus en la OC",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const color = chipColorsByStatus[params.row.StatusOc];
        return (
          <Chip
            label={params.row.StatusOc}
            size="small"
            style={{ backgroundColor: color, color: "white" }}
          />
        );
      },
    },
    {
      field: "price",
      headerName: "Precio Total",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => `$${formatNumber(params.row.PrecioTotal)}`,
    },
  ];

  if (
    document.querySelector(
      ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
    )
  )
    document
      .querySelector(
        ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
      )
      .remove();

  useEffect(() => {
    let active = true;
    (async () => {
      let newRows = null;
      setExcelData(null);
      setRows([]);

      if (
        typeof keyWord === "undefined" ||
        keyWord === undefined ||
        keyWord.length === 0
      ) {
        setLoading(true);
        newRows = await getMoveInv(page, user.data.awsid, initDateF, endDateF);
      } else if (keyWord.length >= 1) {
        setLoading(true);
        newRows = await searchMoveInv(
          page,
          user.data.awsid,
          initDateF,
          endDateF,
          keyWord
        );
      } else active = false;

      if (!active) {
        return;
      }
      setTotal(newRows.total !== "" ? newRows.total : 0);
      setRows(newRows.data);
      setInfoCards({
        ...newRows.infoCards,
      });
      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [page, reload]);

  const handleDateChange = (field, date) => {
    if (field === "initDate") {
      setInitDate(date);
      setExcelData(null);

      if (endDate && new Date(date) > new Date(endDate)) {
        handleDateError();
      }
    } else if (field === "endDate") {
      setEndDate(date);
      setExcelData(null);

      if (initDate && new Date(date) < new Date(initDate)) {
        handleDateError();
      }
    }
  };

  const handleDateError = () => {
    setInitDate(null);
    setEndDate(null);
  };

  const validate = () => {
    let newErrors = {};
    if (!initDate) newErrors.startDate = "Fecha de inicio es requerida.";
    if (!endDate) newErrors.endDate = "Fecha final es requerida.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (validate()) {
      setLoading(true);
      setInitDateF(initDate);
      setEndDateF(endDate);
      reloadEff();
    }
  };

  const reloadEff = () => {
    setReload((prevValue) => (prevValue === 0 ? 1 : 0));
  };

  const data = [
    {
      title: "TOTAL DE O. C.",
      total: infoCards[0]?.totalC ? infoCards[0].totalC : 0,
      color: "#00c0ef",
      icon: MenuOpenIcon,
    },
    {
      title: "MONTO TOTAL DE LAS O. C.",
      total: infoCards[0]?.totalValor ? infoCards[0].totalValor : 0,
      color: "#283a6e",
      icon: AttachMoneyIcon,
      type: "price",
    },
    {
      title: "O. C. ABIERTAS",
      total: infoCards[0]?.ocAbiertas ? infoCards[0].ocAbiertas : 0,
      color: "#f39c12",
      icon: EditIcon,
    },
    {
      title: "PIEZAS ABIERTAS",
      total: infoCards[0]?.lineas_Abiertas ? infoCards[0].lineas_Abiertas : 0,
      color: "#9a77e1",
      icon: MenuIcon,
    },
    {
      title: "MONTO TOTAL DE PIE...",
      total: infoCards[0]?.totalValorLineas ? infoCards[0].totalValorLineas : 0,
      color: "#4db45d",
      icon: AttachMoneyIcon,
      type: "price",
    },
  ];

  const getAllExcelData = async () => {
    try {
      setIsELoading(true);
      const data = await getMovePDF(user.data.awsid, initDateF, endDateF);
      setExcelData(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsELoading(false);
    }
  };

  function formatNumber(num) {
    return num.toLocaleString("en-US");
  }

  return (
    <>
      <Box sx={{ width: "100%", padding: "7px" }}>
        <Grid container spacing={1}>
          {data.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <CardsInfo
                title={item.title}
                total={item.total}
                color={item.color}
                IconComponent={item.icon}
                type={item.type}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Grid container sx={{ mb: 2, mt: 2 }}>
          <Grid item md={8} sx={{ px: 2 }}>
            <Stack direction="row" spacing={1}>
              <Grid container alignItems="center">
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    margin="dense"
                    label="Fecha de inicio"
                    type="date"
                    value={initDate || ""}
                    onChange={(e) =>
                      handleDateChange("initDate", e.target.value)
                    }
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.initDate}
                    helperText={errors.initDate}
                    size="small"
                    required
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    margin="dense"
                    label="Fecha final"
                    type="date"
                    value={endDate || ""}
                    onChange={(e) =>
                      handleDateChange("endDate", e.target.value)
                    }
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ min: initDate }}
                    disabled={!initDate}
                    error={!!errors.endDate}
                    helperText={errors.endDate}
                    size="small"
                    required
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    margin="dense"
                    label="Buscar"
                    type="text"
                    value={keyWord}
                    onChange={(e) => setKeytoSearch(e.target.value)}
                    size="small"
                    disabled={!initDate || !endDate}
                  />
                </Grid>
                <Grid item xs={2} sx={{ marginLeft: "1px" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleSubmit}
                    sx={{
                      minWidth: "auto",
                      padding: "6px",
                      marginTop: "3.1px",
                      marginLeft: "2px",
                    }}
                    disabled={!initDate || !endDate}
                  >
                    <SearchIcon />
                  </Button>
                </Grid>
              </Grid>
            </Stack>
          </Grid>

          <Grid item md sx={{ px: 2, textAlign: "right" }}>
            {!excelData && (
              <LoadingButton
                loading={isELoading}
                variant="contained"
                color="info"
                onClick={getAllExcelData}
                disabled={!initDateF || !endDateF}
              >
                Generar excel
              </LoadingButton>
            )}
            {excelData && (
              <ExcelFile excelData={excelData} fileName={"Klaar"} />
            )}
          </Grid>
        </Grid>
      </Box>

      <Box style={{ height: 635, width: "100%" }}>
        <DataGridPro
          rows={rows}
          columns={columns}
          pagination
          pageSize={10}
          rowsPerPageOptions={[10]}
          getRowId={(row) => row.OrdendeCompra}
          rowCount={total}
          paginationMode="server"
          onPageChange={(newPage) => {
            setPage(newPage);
          }}
          loading={loading}
          disableColumnMenu
          disableSelectionOnClick
          getDetailPanelHeight={() => "auto"}
          getDetailPanelContent={({ row }) => <DetailsDataTable row={row} />}
        />
      </Box>
    </>
  );
};

export default MovimientosInvDataTable;
