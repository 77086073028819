import { profiles } from "./profilesTrazabilidad";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
const dicon = <RadioButtonUncheckedIcon fontSize='small' />;

export const trazabilidadModules = [
  {
    label: "Inicio",
    icon: dicon,
    slug: "inicio",
    profiles: profiles.inicio
  },
  {
    label: "Pedidos",
    icon: dicon,
    slug: "pedidos",
    profiles: profiles.pedidos
  },
  {
    label: "RMA",
    icon: dicon,
    slug: "rma",
    profiles: profiles.rma
  },
  {
    label: "Aclaraciones",
    icon: dicon,
    slug: "aclaraciones",
    profiles: profiles.aclaraciones
  },
  {
    label: "Backorders",
    icon: dicon,
    slug: "backorders",
    profiles: profiles.backorders
  },
  {
    label: "Excepciones",
    icon: dicon,
    slug: "excepciones",
    profiles: profiles.excepciones
  },
  {
    label: "Excepciones Internas",
    icon: dicon,
    slug: "excepciones-internas",
    profiles: profiles.excepcionesInt
  },
  {
    label: "Carriers",
    icon: dicon,
    slug: "carriers",
    profiles: profiles.carriers
  },
  {
    label: "Incidencias a logística",
    icon: dicon,
    slug: "incidencias",
    profiles: profiles.incidenciasLog
  },
  {
    label: "Verificaciones",
    icon: dicon,
    slug: "verificaciones",
    profiles: profiles.verificaciones
  },
];
