import {
  Box,
  Grid,
  Button,
  Stack,
  Paper,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import DateInTable from "../../general/DateInTable";
import Toast from "../../general/Toast";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SendIcon from "@mui/icons-material/Send";
import {
  getBackOrders,
  searchBackOrders,
  sendMassiveMessages,
  getExcelBackOrders,
} from "../../../utils/trazabilidad/backorders/backorders";
import ExcelFile from "../../general/ExcelFile";

function DetailPanelContent({ row: rowProp }) {
  return (
    <Stack
      sx={{ py: 2, height: "100%", boxSizing: "border-box" }}
      direction="column"
    >
      <Paper sx={{ flex: 1, mx: "auto", width: "90%", p: 1 }}>
        <Stack direction="column" spacing={1} sx={{ height: 1 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ textAlign: "center" }}>Grupo</TableCell>
                <TableCell sx={{ textAlign: "center" }}>Status WMS</TableCell>
                <TableCell sx={{ textAlign: "center" }}>C</TableCell>
                <TableCell sx={{ textAlign: "center" }}>L</TableCell>
                <TableCell sx={{ textAlign: "center" }}>AC</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ border: 0 }}>
                <TableCell sx={{ width: "15%", textAlign: "center" }}>
                  {rowProp.attributes}
                </TableCell>
                <TableCell sx={{ width: "10%", textAlign: "center" }}>
                  {rowProp.status_wms}
                </TableCell>
                <TableCell sx={{ width: "25%", textAlign: "center" }}>
                  {rowProp.msg_producto}
                </TableCell>
                <TableCell sx={{ width: "25%", textAlign: "center" }}>
                  {rowProp.msg_logistica}
                </TableCell>
                <TableCell sx={{ width: "25%", textAlign: "center" }}>
                  {rowProp.msg_ateclientes}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Stack>
      </Paper>
    </Stack>
  );
}

const BackordersDataTable = ({ typeP }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [keyWord, setKeytoSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isELoading, setIsELoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [opena, setOpenA] = useState(false);
  const [message, setMessage] = useState("");
  const [selectionModel, setSelectionModel] = useState([]);
  const [confalert, setConfAlert] = useState({
    severidad: "success",
    mensaje: "",
  });
  const [excelData, setExcelData] = useState(null);

  const handleClickOpen = () => {
    if (selectionModel.length === 0) {
      setConfAlert({
        severidad: "warning",
        mensaje: "Debe seleccionar al menos una fila de la tabla",
      });
      handleOpenA(true);
    } else setOpen(true);
  };

  const handleClose = () => {
    setMessage("");
    setOpen(false);
  };

  const handleOpenA = () => {
    setOpenA(true);
  };

  const handleCloseA = () => {
    setOpenA(false);
  };

  const handleSaveMessage = async () => {
    try {
      setIsLoading(true);
      if (message && selectionModel.length > 0) {
        let aux = [];
        selectionModel.forEach((item) => {
          let aux2 = item.split("-");
          let aux3 = {
            idBO: parseInt(aux2[0]),
            idItem: parseInt(aux2[1]),
            sku: parseInt(aux2[2]),
            product: aux2[3],
          };
          aux.push(aux3);
        });

        let response = await sendMassiveMessages(
          message,
          user.role.idProfile,
          user.data.awsid,
          aux
        );
        handleClose();
        if (response)
          setConfAlert({
            severidad: "success",
            mensaje: "El mensaje fue enviado con éxito",
          });
        else
          setConfAlert({
            severidad: "error",
            mensaje: "No pudo enviar el mensaje, intente más tarde",
          });
        handleOpenA(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getAllExcelData = async () => {
    try {
      setIsELoading(true);
      let tb =
        typeP === 1 ? "crossdock" : typeP === 2 ? "historical" : "backorders";
      const data = await getExcelBackOrders(tb);
      setExcelData(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsELoading(false);
    }
  };

  function getCBO(params) {
    if (params.row.backorder_number !== 0)
      return params.row.increment_id + `-${params.row.backorder_number}`;
    else return params.row.increment_id;
  }

  function getStatusK(params) {
    let sta = "";
    if(typeP === 2) 
    {
      !params.row.status_item ? sta = "F. Facturado" :
      params.row.status_item === 6 ? sta = "F. Facturado" :
      params.row.status_item === 7 ? sta = "F. Reembolso" :
      params.row.status_item === 7 ? sta = "F. Store Credit" : sta = "Finalizado";
    }else {
      sta = params.row.status_item ?? params.row.status_acl;
    }
    
    return sta;
  }

  const columns = [
    {
      field: "increment_id",
      headerName: "Backorder",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: getCBO,
    },
    {
      field: "joinSku",
      headerName: "SKU",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "wms_id",
      headerName: "WMS Id",
      flex: 1,
      align: "center",
      headerAlign: "center",
      hide: typeP !== 0 ? false : true,
    },
    { field: "joinProducto", headerName: "Nombre Producto", flex: 1 },
    { field: "attributes", headerName: "Grupo", flex: 1, hide: true },
    {
      field: "joinCant_faltante",
      headerName: "Cant. Faltante",
      flex: 0.7,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "created_at",
      headerName: "Fecha BO",
      flex: 1,
      renderCell: (params) => (
        <Typography>
          <DateInTable dateString={params.row.created_at} />
        </Typography>
      ),
    },
    { field: "msg_producto", headerName: "C", flex: 1, hide: true },
    { field: "msg_logistica", headerName: "L", flex: 1, hide: true },
    { field: "msg_ateclientes", headerName: "AC", flex: 1, hide: true },
    { field: "status_wms", headerName: "Status WMS", flex: 1, hide: true },
    {
      field: "status_item",
      headerName: "Status Klaar",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: getStatusK,
    },
    {
      field: "accion",
      headerName: "Acción",
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => (
        <Button
          variant="contained"
          size="small"
          disableElevation
          startIcon={<VisibilityIcon />}
        >
          <Link
            to={`detail/${params.row.id_backorder}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "none",
              color: "#fff",
              width: "100%",
              height: "100%",
            }}
          >
            Detalle
          </Link>
        </Button>
      ),
    },
  ];

  if (
    document.querySelector(
      ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
    )
  )
    document
      .querySelector(
        ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
      )
      .remove();

  useEffect(() => {
    let active = true;

    (async () => {
      let tb =
        typeP === 1 ? "crossdock" : typeP === 2 ? "historical" : "backorders";
      let newRows = null;

      if (
        typeof keyWord === "undefined" ||
        keyWord === undefined ||
        keyWord.length === 0
      ) {
        setLoading(true);
        newRows = await getBackOrders(tb, page);
      } else if (keyWord.length >= 5) {
        setLoading(true);
        newRows = await searchBackOrders(tb, page, keyWord);
      } else active = false;

      if (!active) {
        return;
      }
      setTotal(newRows.total);
      setRows(newRows.data);
      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [page, keyWord, typeP]);

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Grid container sx={{ mb: 2 }}>
          <Grid item md={4} sx={{ px: 2 }}>
            <Stack direction="row" spacing={1}>
              <TextField
                size="small"
                variant="outlined"
                fullWidth
                label={`Buscar ${
                  typeP === 1
                    ? "cross docs"
                    : typeP === 2
                    ? "históricos"
                    : "pendientes por liberar"
                }`}
                value={keyWord}
                onChange={(e) => setKeytoSearch(e.target.value)}
              />
            </Stack>
          </Grid>
          <Grid item md sx={{ px: 2, textAlign: "right" }}>
            {!excelData && (
              <LoadingButton
                loading={isELoading}
                variant="contained"
                color="info"
                onClick={getAllExcelData}
              >
                Generar excel
              </LoadingButton>
            )}
            {excelData && (
              <ExcelFile
                excelData={excelData}
                fileName={`${
                  typeP === 1
                    ? "CrossDocs"
                    : typeP === 2
                    ? "Historicos"
                    : "PendientesPorLiberar"
                }`}
              />
            )}
          </Grid>
        </Grid>
      </Box>
      <Box style={{ height: 635, width: "100%" }}>
        <DataGridPro
          rows={rows}
          columns={columns}
          pagination
          checkboxSelection
          pageSize={10}
          rowsPerPageOptions={[10]}
          getRowId={(row) =>
            row.id_backorder +
            "-" +
            row.id_item +
            "-" +
            row.joinSku +
            "-" +
            row.joinProducto
          }
          rowCount={total}
          paginationMode="server"
          onPageChange={(newPage) => {
            setPage(newPage);
          }}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
          loading={loading}
          disableColumnMenu
          disableSelectionOnClick
          getDetailPanelHeight={() => "auto"}
          getDetailPanelContent={({ row }) => <DetailPanelContent row={row} />}
        />
      </Box>

      <Button
        sx={{ m: 2 }}
        variant="contained"
        color="info"
        endIcon={<SendIcon />}
        onClick={handleClickOpen}
      >
        Enviar mensaje masivo
      </Button>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Agregar mensaje para envío masivo</DialogTitle>
        <DialogContent>
          <TextField
            multiline
            autoFocus
            margin="dense"
            label="Escribe tu mensaje"
            fullWidth
            variant="standard"
            maxRows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            color="info"
            loadingIndicator="Enviando…"
            endIcon={<SendIcon />}
            onClick={handleSaveMessage}
          >
            Enviar
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Toast
        open={opena}
        message={confalert.mensaje}
        type={confalert.severidad}
        onClose={handleCloseA}
      />
    </>
  );
};

export default BackordersDataTable;
