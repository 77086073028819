import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  CardContent,
  Chip,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import "dayjs/locale/es";
import local from "dayjs/plugin/localizedFormat";
import relative from "dayjs/plugin/relativeTime";
import { useEffect, useState } from "react";
import AsesoriaCasoInfo from "./AsesoriaCasoInfo";
import { motion } from "framer-motion";

dayjs.locale("es");
dayjs.extend(local);
dayjs.extend(relative);

const chipColors = {
  Cerrado: "#f44336",
  Canalizada: "#673ab7",
  Tomado: "#1e88e5",
  Nuevo: "#43a047",
};

// TODO: Movimientos, mensajes, observaciones, interacciones asociadas
const AsesoriaCasoById = () => {
  const params = useParams();
  const navigate = useNavigate();
  const id = params.id;
  const [details, setDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleCloseCase = async () => {
    if (!details) return;
    setIsLoading(true);

    try {
      const ticketId = +details.id_crm_case;
      const userId = +details.id_taken;

      const res = await axios.post(
        `${process.env.REACT_APP_BASEURL}/crm/cases/close`,
        {
          idMain: ticketId,
          idUser: userId,
        },
        {
          headers: {
            "Content-Type": "Application/json",
          },
        }
      );

      if (res.statusText !== "OK" || !res.data.success) {
        throw new Error("handleCloseCase", "No se pudo cerrar el caso");
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setIsLoading(false);
      navigate("../", { replace: true });
    }
  };

  useEffect(() => {
    let isEffectCancelled = false;
    const getData = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_BASEURL}/crm/cases/details?id=${id}`
        );
        const data = await res.json();
        if (!isEffectCancelled) {
          setDetails(data[0]);
        }
      } catch (error) {
        console.error("getData", error);
      }
    };

    getData();

    return () => {
      isEffectCancelled = true;
    };
  }, [id]);

  return (
    <>
      <motion.div
        initial={{ y: 20 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Card sx={{ mb: 2 }}>
          {!details && <LinearProgress />}
          <CardContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <Typography variant="h4" gutterBottom>
                {details?.type ?? "Ticket"} #{id}
              </Typography>
              <LoadingButton
                variant="contained"
                color="error"
                onClick={handleCloseCase}
                loading={isLoading}
                disableElevation
              >
                Cerrar caso
              </LoadingButton>
            </Box>
            {details && (
              <Box sx={{ display: "flex", mt: 4 }}>
                <Box sx={{ flex: 1 }}>
                  <Typography variant="h6">Título</Typography>
                  <Typography gutterBottom>{details.title ?? "Sin título"}</Typography>
                  <Typography variant="h6">Canal</Typography>
                  <Typography gutterBottom>{details.channel}</Typography>
                  <Typography variant="h6">Fecha</Typography>
                  <Typography gutterBottom>
                    {dayjs(details.date_register).format(
                      "DD MMM YYYY, hh:mm A"
                    )}{" "}
                    ({dayjs(details.date_register).fromNow()})
                  </Typography>
                  <Typography variant="h6">Status</Typography>
                  <Chip
                    label={details.estatus}
                    sx={{
                      backgroundColor: chipColors[details.estatus],
                      color: "#fff",
                      mb: 1,
                    }}
                    size="small"
                  />

                  <Typography variant="h6">Contacto</Typography>
                  <Typography gutterBottom>{details.contact}</Typography>
                  <Typography variant="h6">Tomado por</Typography>
                  <Typography gutterBottom>{`${details.nombre ?? ""} ${
                    details.apellidoP ?? ""
                  }`}</Typography>
                  <Typography variant="h6">Colegio</Typography>
                  <Typography gutterBottom>{details.college ?? "Sin colegio"}</Typography>
                  <Typography variant="h6">Tipificación</Typography>
                  <Typography gutterBottom>{details.theme ?? "Sin tipificación"}</Typography>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Typography variant="h6">Mensaje</Typography>
                  <TextField
                    multiline
                    variant="filled"
                    rows={6}
                    sx={{
                      width: "100%",
                      mb: 2,
                    }}
                    value={details.title ?? ""}
                    fullWidth
                  />
                  <Typography variant="h6">Solucion</Typography>
                  <TextField
                    multiline
                    variant="filled"
                    rows={6}
                    sx={{
                      width: "100%",
                    }}
                    value={details.description ?? ""}
                    fullWidth
                  />
                </Box>
              </Box>
            )}
          </CardContent>
        </Card>
      </motion.div>
      {details && <AsesoriaCasoInfo details={details} />}
    </>
  );
};

export default AsesoriaCasoById;
