import { profiles } from "./profilesVales";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
const dicon = <RadioButtonUncheckedIcon fontSize='small' />;

export const valesModules = [
    {
        label: "Temporadas",
        icon: dicon,
        slug: "temporadas",
        profiles: profiles.temporadas
    },
    {
        label: "Coordinadores",
        icon: dicon,
        slug: "coordinadores",
        profiles: profiles.coordinadores
    },
    {
        label: "Configuración",
        icon: dicon,
        slug: "configuracion",
        profiles: profiles.configuracion
    },
    {
        label: "Beneficiarios",
        icon: dicon,
        slug: "beneficiarios",
        profiles: profiles.beneficiarios
    },
];