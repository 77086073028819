import { Box, Button, Card, CardContent, LinearProgress, Typography, Tabs, Tab, Grid } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import AssignmentIcon from '@mui/icons-material/Assignment';
import ForumIcon from '@mui/icons-material/Forum';
import { useEffect, useState } from "react";
import { useAuth } from "../../../context/AuthContext";
import { profiles } from "../../../utils/trazabilidad/profilesTrazabilidad";
import ExcepcionesIDSDetail from "./ExcepcionesIDSDetail";
import IndexTabs from "../excepciones/tabs/IndexTabs";
import { getExcepById } from "../../../utils/trazabilidad/excepciones/excepciones";
import BreadCrumb from "../../general/BreadCrumb";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";

const itemsBreadCrumbs = [
  {
    label: 'Trazabilidad',
    icon: <ContentPasteSearchIcon sx={{ mr: 0.5 }} fontSize="small"/>,
    link: '/trazabilidad/inicio'
  },
  { label: 'Excepciones Internas', link: '/trazabilidad/excepciones-internas', },
  { label: 'Detalle' }
];

const chipColors = {
    "1": "#3c8dbc",
    "2": "#00a65a",
    "3": "#dd4b39",
    "4": "#f39c12",
    "5": "#777777",
    "6": "#00c0ef"
};

const chipLabels = {
    "1": "Nueva",
    "2": "Resuelta",
    "3": "Cerrada",
    "4": "Abierta",
    "5": "En proceso",
    "6": "Info"
};

const ExcepcionesIDetail = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const params = useParams();
    const id = params.id;
    const [isLoading, setIsLoading] = useState(false);
    const [detalle, setDetalle] = useState(null);
    const [status, setStatus] = useState(null);

    useEffect(() => {

        if(!profiles.excepcionesInt.includes(user.role.idProfile)){
            navigate("/dashboard", { replace: true });
        }
        else
        {
            const getDetallePedido = async () => {
                try {
                    setIsLoading(true);
                    const res = await getExcepById(id);
                    setDetalle(res);
                    setStatus(1);
                } catch (error) {
                    console.error(error);
                } finally {
                    setIsLoading(false);
                }
            };
            getDetallePedido();
        }

    }, [id]);

    return (
        <>
            <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <Typography variant="h4" sx={{ my: 2 }}>Excepciones</Typography>
                <Box><BreadCrumb items={itemsBreadCrumbs} /></Box>
            </Box>

            <motion.div initial={{ y: 20 }} animate={{ y: 0 }} transition={{ duration: 0.5 }}>
                <Card>
                    <Box>
                        <Box>                        
                            <Grid container spacing={2}>
                                <Grid item md={12}>
                                    <Typography variant="h6" sx={{ ml: 2, my: 2, mb: 0 }}><AssignmentIcon sx={{ m: 0 }} /> Detalles de la excepción</Typography>
                                    {isLoading && <LinearProgress />}
                                    {detalle && (<ExcepcionesIDSDetail excepId={id} detalle={detalle} chipColors={chipColors} chipLabels={chipLabels} />)}
                                </Grid>
                            </Grid>
                        </Box>
                        {detalle && (<IndexTabs excepId={id} excepDl={detalle} status={detalle.estado} />)}
                    </Box>
                </Card>
            </motion.div>

            <Box>&nbsp;</Box>
        </>
    );
};

export default ExcepcionesIDetail;