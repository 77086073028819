import { Box, Grid, TextField, InputLabel, Select, FormControl, MenuItem, CircularProgress, Typography, Alert, Card, CardContent, Stack, ListSubheader, List, ListItem, ListItemText } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../context/AuthContext";
import { useEffect, useState } from "react";
import { getClarificationAddresses, saveClarificationDetails } from "../../../../utils/trazabilidad/aclaraciones/aclaraciones";
import { profilesAclaraciones } from "../../../../utils/trazabilidad/aclaraciones/profilesAclaraciones";

const DatosEnvio = ({ aId, detalle }) => {
  const { user } = useAuth();
  const [isEditSD, setEditSD] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [address, setAddress] = useState({
      nombre: detalle.customer_name + " " +detalle.customer_lastname,
      pais: 'México',
      estado: detalle.shipping_state,
      dele: detalle.shipping_delegation,
      calle: detalle.shipping_street,
      colonia: detalle.shipping_suburb,
      ciudad: detalle.shipping_city,
      entre: detalle.between_streets,
      fachada: '',
      cp: detalle.shipping_postal_code,
      telefono: detalle.telephone,
      ne: detalle.shipping_outside_number,
      ni: detalle.shipping_inside_number,
      groupedselect: ''
  });

  const handleEditSD = async () => {
    setIsLoading(true);
    const resp = await getClarificationAddresses(detalle.email);
    setAddresses(resp);
    setAddress((prevalue) => {
      return {
        ...prevalue,
        "nombre": detalle.dir_nombre,
        "pais": detalle.dir_pais,
        "estado": detalle.dir_estado,
        "ciudad": detalle.dir_ciudad,
        "calle": detalle.dir_calle,
        "dele": detalle.dir_municipio,
        "colonia": detalle.dir_colonia,
        "entre": detalle.dir_entre_calles,
        "fachada": detalle.dir_fachada,
        "cp": detalle.dir_cp,
        "telefono": detalle.telephone,
        "ni": detalle.dir_numero_int,
        "ne": detalle.dir_numero_ext
      }
    })
    setEditSD(true);
    setIsLoading(false);
  };

  const handleChangeAddress = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    if(name == "groupedselect")
    {
      if(value != undefined && value != 0 && value != 1)
      {
        let local = getSelectedAddress(addresses, value);
        local = local[0];
        setAddress((prevalue) => {
          return {
            ...prevalue,
            "calle": local.Calle,
            "dele": local.Municipio,
            "colonia": local.Colonia,
            "ni": local["Número interior"],
            "ne": local["Número exterior"],
            "groupedselect": value
          }
        })
      }
      else if(value == 1)
      {
        setAddress((prevalue) => {
          return {
            ...prevalue,
            "pais": 'México',
            "estado": detalle.shipping_state,
            "ciudad": detalle.shipping_city,
            "calle": detalle.shipping_street,
            "dele": detalle.shipping_delegation,
            "colonia": detalle.shipping_suburb,
            "entre": detalle.between_streets,
            "fachada": '',
            "cp": detalle.shipping_postal_code,
            "telefono": detalle.telephone,
            "ni": detalle.shipping_inside_number,
            "ne": detalle.shipping_outside_number,
            "groupedselect": value
          }
        })
      }
      else if(value == 0)
      {
        setAddress((prevalue) => {
          return {
            ...prevalue,
            "pais": '',
            "estado": '',
            "ciudad": '',
            "calle": '',
            "dele": '',
            "colonia": '',
            "entre": '',
            "fachada": '',
            "cp": '',
            "telefono": '',
            "ni": '',
            "ne": '',
            "groupedselect": value
          }
        })
      }
    }else
    {
      setAddress((prevalue) => {
        return {
          ...prevalue,
          [name]: value
        }
      })
    }            
  };

  function isempt(){
    if(address.nombre && address.pais && address.cp && address.estado && address.dele && address.calle && address.ne && address.colonia && address.ciudad && address.entre && address.telefono)
      return false;
    else 
      return true;
  }

  function getSelectedAddress(items, val){
    return items.filter(element => element.id == val);
  }

  const handleSaveSD = async () => {
    try {
      setIsLoading(true);
      let resp = await saveClarificationDetails(aId, user.data.awsid, detalle.status_acl_id, detalle.id_orden, address.nombre, address.pais, address.cp, address.estado, address.dele, address.calle, address.ne, address.ni, address.colonia, address.ciudad, address.entre, address.fachada, address.telefono);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const getAddreses = async () => {
      try {
        setIsLoading(true);
        const resp = await getClarificationAddresses(detalle.email);
        setAddresses(resp);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    getAddreses();
  }, [aId]);

  return (
    <>
      <Box sx={{mb: 2, ml: 2, mr: 2}}>
        {(detalle.status_acl_id === 1 && profilesAclaraciones.editarDatosDeEnvio.includes( user.role.idProfile )) && (
          <Grid container sx={{mt:2, mb:2}}>
            <Grid item md={12}>
              <Alert variant="filled" severity="info"><Typography variant="p"><strong>Datos del cliente</strong></Typography></Alert>
              <Card>
                <CardContent>
                  <div>
                    <FormControl fullWidth sx={{mb: 2}}>
                        <InputLabel id="selecte" htmlFor="groupedselect">Seleccionar Dirección</InputLabel>
                        <Select id="groupedselect" name="groupedselect" label="Seleccionar Dirección" value={address.groupedselect} onChange={handleChangeAddress}>
                            <MenuItem value=""><em>Seleccionar dirección</em></MenuItem>
                            <MenuItem value={0}>Agregar nueva dirección</MenuItem>
                            <ListSubheader>Dirección de pedido</ListSubheader>
                            <MenuItem value={1}>{detalle.shipping_city} - {detalle.shipping_street}</MenuItem>
                            <ListSubheader>Direcciones adicionales</ListSubheader>
                            {addresses && addresses.map((row) => (
                              <MenuItem key={row.id} value={row.id}>{row.Calle}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                  </div>
                  <Stack sx={{m: 2}} direction="row" spacing={3} autoComplete="off">
                    <TextField fullWidth name="nombre" label="Nombre" variant="outlined" inputProps={{maxlength: 30}} value={address.nombre} onChange={handleChangeAddress} />
                    <TextField fullWidth name="pais" label="País" variant="outlined" value={address.pais} onChange={handleChangeAddress} />
                    <TextField fullWidth name="estado" label="Estado" variant="outlined" value={address.estado} onChange={handleChangeAddress} />
                  </Stack>
                  <Stack sx={{m: 2}} direction="row" spacing={3} autoComplete="off">
                    <TextField fullWidth name="dele" label="Delegación/Municipio" variant="outlined" value={address.dele} onChange={handleChangeAddress} />
                    <TextField fullWidth name="calle" label="Calle" variant="outlined" value={address.calle} onChange={handleChangeAddress} />
                    <TextField fullWidth name="colonia" label="Colonia" variant="outlined" value={address.colonia} onChange={handleChangeAddress} />
                  </Stack>
                  <Stack sx={{m: 2}} direction="row" spacing={3} autoComplete="off">
                    <TextField fullWidth name="ciudad" label="Ciudad" variant="outlined" value={address.ciudad} onChange={handleChangeAddress} />
                    <TextField fullWidth name="entre" label="Entre calles" variant="outlined" value={address.entre} onChange={handleChangeAddress} />
                    <TextField fullWidth name="fachada" label="Fachada" variant="outlined" value={address.fachada} onChange={handleChangeAddress} />
                  </Stack>
                  <Stack sx={{m: 2}} direction="row" spacing={4} autoComplete="off">                    
                    <TextField fullWidth name="cp" label="Código Postal" variant="outlined" value={address.cp} onChange={handleChangeAddress} />
                    <TextField fullWidth name="telefono" label="Teléfono" variant="outlined" value={address.telefono} onChange={handleChangeAddress} />
                    <TextField fullWidth name="ne" label="Número exterior" variant="outlined" value={address.ne} onChange={handleChangeAddress} />
                    <TextField fullWidth name="ni" label="Número interior" variant="outlined" value={address.ni} onChange={handleChangeAddress} />
                  </Stack>
                  <div style={{ textAlign: 'right', marginTop: '2em' }}>
                    <LoadingButton loading={isLoading} startIcon={<SaveIcon />} size="medium" variant="contained" color="success" onClick={handleSaveSD} disabled={(isempt())}>Guardar Aclaración</LoadingButton>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}

        {(detalle.status_acl_id !== 1 || !profilesAclaraciones.editarDatosDeEnvio.includes( user.role.idProfile )) && (
          <Grid container sx={{mt:2, mb:2}}>
            <Grid item md={12}><Alert variant="filled" severity="info"><Typography variant="p"><strong>Datos del cliente</strong></Typography></Alert></Grid>
            <Grid item md={6}>
              <List>
                <ListItem>
                  <ListItemText primary="Nombre" secondary={detalle.dir_nombre ?? "-"}/>
                </ListItem>
                <ListItem>
                  <ListItemText primary="Estado" secondary={detalle.dir_estado ?? "-"}/>
                </ListItem>
                <ListItem>
                  <ListItemText primary="Calle" secondary={detalle.dir_calle ?? "-"}/>
                </ListItem>
                <ListItem>
                  <ListItemText primary="Ciudad" secondary={detalle.dir_ciudad ?? "-"}/>
                </ListItem>
                <ListItem>
                  <ListItemText primary="Código Postal" secondary={detalle.dir_cp ?? "-"}/>
                </ListItem>
                <ListItem>
                  <ListItemText primary="Teléfono" secondary={detalle.telephone ?? "-"}/>
                </ListItem>
                <ListItem>
                  <ListItemText primary="Número Exterior" secondary={detalle.dir_numero_ext ?? "S/N"} />
                </ListItem>
              </List>
            </Grid>
            <Grid item md={6}>
              <List>
                <ListItem>
                  <ListItemText primary="País" secondary={detalle.dir_pais ?? "-"}/>
                </ListItem>
                <ListItem>
                  <ListItemText primary="Deleg. / Munic" secondary={detalle.dir_municipio ?? "-"}/>
                </ListItem>
                <ListItem>
                  <ListItemText primary="Colonia" secondary={detalle.dir_colonia ?? "-"}/>
                </ListItem>
                <ListItem>
                  <ListItemText primary="Entre calles" secondary={detalle.dir_entre_calles ?? "-"}/>
                </ListItem>
                <ListItem>
                  <ListItemText primary="Fachada" secondary={detalle.dir_fachada ?? "-"}/>
                </ListItem>
                <ListItem>
                  <ListItemText primary="Número Interior" secondary={detalle.dir_numero_int ?? "S/N"}/>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
};

export default DatosEnvio;