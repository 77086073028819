import { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

function InformacionCDD({ inforRCE, onChange, estados }) {
  const { cotizacion } = inforRCE;

  const [correoResponsable, setCorreoResponsable] = useState(cotizacion.correo_responsable || "");
  const [metodoPago, setMetodoPago] = useState(cotizacion.forma_pago || "");
  const [plazo, setPlazo] = useState(cotizacion.plazo || "");

  const [calleEnvio, setCalleEnvio] = useState(cotizacion.calle_envio || "");
  const [numeroExtEnvio, setNumeroExtEnvio] = useState(cotizacion.numeroExt_envio || "");
  const [coloniaEnvio, setColoniaEnvio] = useState(cotizacion.colonia_envio || "");
  const [ciudadEnvio, setCiudadEnvio] = useState(cotizacion.ciudad_envio || "");
  const [delegacionEnvio, setDelegacionEnvio] = useState(cotizacion.delegacion_envio || "");
  const [cpEnvio, setCpEnvio] = useState(cotizacion.cp_envio || "");
  const [estadoEnvio, setEstadoEnvio] = useState(cotizacion.estado_envio || "");
  const [paisEnvio, setPaisEnvio] = useState(cotizacion.pais_envio || "");
  const [totalEnvio, setTotalEnvio] = useState(cotizacion.total_envio || "");

  const [calleFacturacion, setCalleFacturacion] = useState(cotizacion.calle_facturacion || "");
  const [numeroExtFacturacion, setNumeroExtFacturacion] = useState(cotizacion.numeroExt_facturacion || "");
  const [coloniaFacturacion, setColoniaFacturacion] = useState(cotizacion.colonia_facturacion || "");
  const [ciudadFacturacion, setCiudadFacturacion] = useState(cotizacion.ciudad_facturacion || "");
  const [delegacionFacturacion, setDelegacionFacturacion] = useState(cotizacion.delegacion_facturacion || "");
  const [cpFacturacion, setCpFacturacion] = useState(cotizacion.cp_facturacion || "");
  const [estadoFacturacion, setEstadoFacturacion] = useState(cotizacion.estado_facturacion || "");
  const [paisFacturacion, setPaisFacturacion] = useState(cotizacion.pais_facturacion || "");
  const [razonSocial, setRazonSocial] = useState(cotizacion.razon_social_facturacion || "");
  const [rfc, setRfc] = useState(cotizacion.rfc_facturacion || "");

  const createOnChangeHandler = (setter, key) => (e) => {
    const nuevoValor = e.target.value;
    setter(nuevoValor);
    onChange(key, nuevoValor);
  };

  const onChangeCorreoResponsable = createOnChangeHandler(setCorreoResponsable,"correoResponsable");
  const onChangeMetodoPago = createOnChangeHandler(setMetodoPago, "metodoPago");
  const onChangePlazo = createOnChangeHandler(setPlazo, "plazo");
  const onChangeCalleEnvio = createOnChangeHandler(setCalleEnvio, "calleEnvio");
  const onChangeNumeroExtEnvio = createOnChangeHandler(setNumeroExtEnvio, "numeroExtEnvio");
  const onChangeColoniaEnvio = createOnChangeHandler(setColoniaEnvio, "coloniaEnvio");
  const onChangeCiudadEnvio = createOnChangeHandler(setCiudadEnvio, "ciudadEnvio");
  const onChangeDelegacionEnvio = createOnChangeHandler(setDelegacionEnvio,"delegacionEnvio");
  const onChangeCpEnvio = createOnChangeHandler(setCpEnvio, "cpEnvio");
  const onChangeEstadoEnvio = createOnChangeHandler(setEstadoEnvio, "estadoEnvio");
  const onChangePaisEnvio = createOnChangeHandler(setPaisEnvio, "paisEnvio");
  const onChangeTotalEnvio = createOnChangeHandler(setTotalEnvio, "totalEnvio");
  const onChangeCalleFacturacion = createOnChangeHandler(setCalleFacturacion, "calleFacturacion");
  const onChangeNumeroExtFacturacion = createOnChangeHandler(setNumeroExtFacturacion, "numeroExtFacturacion");
  const onChangeColoniaFacturacion = createOnChangeHandler(setColoniaFacturacion, "coloniaFacturacion");
  const onChangeCiudadFacturacion = createOnChangeHandler(setCiudadFacturacion, "ciudadFacturacion");
  const onChangeDelegacionFacturacion = createOnChangeHandler(setDelegacionFacturacion, "delegacionFacturacion");
  const onChangeCpFacturacion = createOnChangeHandler(setCpFacturacion, "cpFacturacion");
  const onChangeEstadoFacturacion = createOnChangeHandler(setEstadoFacturacion, "estadoFacturacion");
  const onChangePaisFacturacion = createOnChangeHandler(setPaisFacturacion, "paisFacturacion");
  const onChangeRazonSocial = createOnChangeHandler(setRazonSocial, "razonSocial");
  const onChangeRfc = createOnChangeHandler(setRfc, "rfc");

  function encodeUtf8(val) {
    try {
      return decodeURIComponent(escape(val));
    } catch (err) {
      return val;
    }
  }

  return (
    <Grid item md={8}>
      <Box>
        <Box
          sx={{
            border: "1px solid #dedede",
            borderTop: "3px solid #00a65a",
            margin: "10px",
            marginBottom: "25px",
            borderRadius: "5px",
          }}
        >
          <Box sx={{ borderBottom: "1px solid #dedede" }}>
            <Typography
              variant="h6"
              sx={{
                fontSize: "1.18rem",
                margin: "7px",
                marginLeft: "12px",
              }}
            >
              Información de la cotización
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <List
                sx={{
                  "& .MuiListItem-root": { paddingTop: 0 },
                }}
              >
                <ListItem>
                  <ListItemText
                    primary="Correo del responsable"
                    secondary={
                      <TextField
                        label="Correo"
                        variant="outlined"
                        size="small"
                        sx={{ marginTop: "5px" }}
                        value={encodeUtf8(correoResponsable)}
                        onChange={onChangeCorreoResponsable}
                        fullWidth
                      />
                    }
                    sx={{ span: { fontWeight: "500" } }}
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} md={4}>
              <List
                sx={{
                  "& .MuiListItem-root": { paddingTop: 0 },
                }}
              >
                <ListItem>
                  <ListItemText
                    primary="Método de pago"
                    secondary={
                      <FormControl
                        variant="outlined"
                        size="small"
                        fullWidth
                        sx={{ marginTop: "5px" }}
                      >
                        <InputLabel>Método de pago</InputLabel>
                        <Select
                          label="Método de pago"
                          value={encodeUtf8(metodoPago)}
                          onChange={onChangeMetodoPago}
                        >
                          <MenuItem value="Depósito bancario">
                            Depósito bancario
                          </MenuItem>
                          <MenuItem value="Cheque">Cheque</MenuItem>
                          <MenuItem value="Transferencia bancaria">
                            Transferencia bancaria
                          </MenuItem>
                          <MenuItem value="Crédito">Crédito</MenuItem>
                        </Select>
                      </FormControl>
                    }
                    sx={{ span: { fontWeight: "500" } }}
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} md={4}>
              <List
                sx={{
                  "& .MuiListItem-root": { paddingTop: 0 },
                }}
              >
                <ListItem>
                  <ListItemText
                    primary="Plazo"
                    secondary={
                      <FormControl
                        variant="outlined"
                        size="small"
                        fullWidth
                        sx={{ marginTop: "5px" }}
                      >
                        <InputLabel>Plazo</InputLabel>
                        <Select
                          label="Plazo"
                          value={encodeUtf8(plazo)}
                          onChange={onChangePlazo}
                        >
                          <MenuItem value="Contado">Contado</MenuItem>
                          <MenuItem value="30 días">30 días</MenuItem>
                          <MenuItem value="40 días">40 días</MenuItem>
                          <MenuItem value="45 días">45 días</MenuItem>
                          <MenuItem value="60 días">60 días</MenuItem>
                        </Select>
                      </FormControl>
                    }
                    sx={{ span: { fontWeight: "500" } }}
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            border: "1px solid #dedede",
            borderTop: "3px solid #f39c12",
            margin: "10px",
            marginBottom: "25px",
            borderRadius: "5px",
          }}
        >
          <Box sx={{ borderBottom: "1px solid #dedede" }}>
            <Typography
              variant="h6"
              sx={{
                fontSize: "1.18rem",
                margin: "7px",
                marginLeft: "12px",
              }}
            >
              Datos de envío
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <List
                sx={{
                  "& .MuiListItem-root": { paddingTop: 0 },
                }}
              >
                <ListItem>
                  <ListItemText
                    primary="Calle"
                    secondary={
                      <TextField
                        label="Calle"
                        variant="outlined"
                        size="small"
                        sx={{ marginTop: "5px" }}
                        value={encodeUtf8(calleEnvio)}
                        onChange={onChangeCalleEnvio}
                        fullWidth
                      />
                    }
                    sx={{ span: { fontWeight: "500" } }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Ciudad"
                    secondary={
                      <TextField
                        label="Ciudad"
                        variant="outlined"
                        size="small"
                        sx={{ marginTop: "5px" }}
                        value={encodeUtf8(ciudadEnvio)}
                        onChange={onChangeCiudadEnvio}
                        fullWidth
                      />
                    }
                    sx={{ span: { fontWeight: "500" } }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Estado"
                    secondary={
                      <FormControl
                        variant="outlined"
                        size="small"
                        fullWidth
                        sx={{ marginTop: "5px" }}
                      >
                        <InputLabel>Estado</InputLabel>
                        <Select
                          label="Estado"
                          value={encodeUtf8(estadoEnvio)}
                          onChange={onChangeEstadoEnvio}
                        >
                          {estados.map((estado) => (
                            <MenuItem
                              key={estado.id_estado}
                              value={estado.name}
                            >
                              {estado.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    }
                    sx={{ span: { fontWeight: "500" } }}
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} md={4}>
              <List
                sx={{
                  "& .MuiListItem-root": { paddingTop: 0 },
                }}
              >
                <ListItem>
                  <ListItemText
                    primary="Número Exterior"
                    secondary={
                      <TextField
                        label="Número Exterior"
                        variant="outlined"
                        size="small"
                        sx={{ marginTop: "5px" }}
                        value={encodeUtf8(numeroExtEnvio)}
                        onChange={onChangeNumeroExtEnvio}
                        fullWidth
                      />
                    }
                    sx={{ span: { fontWeight: "500" } }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Delegación"
                    secondary={
                      <TextField
                        label="Delegación"
                        variant="outlined"
                        size="small"
                        sx={{ marginTop: "5px" }}
                        value={encodeUtf8(delegacionEnvio)}
                        onChange={onChangeDelegacionEnvio}
                        fullWidth
                      />
                    }
                    sx={{ span: { fontWeight: "500" } }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="País"
                    secondary={
                      <TextField
                        label="País"
                        variant="outlined"
                        size="small"
                        sx={{ marginTop: "5px" }}
                        value={encodeUtf8(paisEnvio)}
                        onChange={onChangePaisEnvio}
                        fullWidth
                      />
                    }
                    sx={{ span: { fontWeight: "500" } }}
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} md={4}>
              <List
                sx={{
                  "& .MuiListItem-root": { paddingTop: 0 },
                }}
              >
                <ListItem>
                  <ListItemText
                    primary="Colonia"
                    secondary={
                      <TextField
                        label="Colonia"
                        variant="outlined"
                        size="small"
                        sx={{ marginTop: "5px" }}
                        value={encodeUtf8(coloniaEnvio)}
                        onChange={onChangeColoniaEnvio}
                        fullWidth
                      />
                    }
                    sx={{ span: { fontWeight: "500" } }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Código postal"
                    secondary={
                      <TextField
                        label="Código postal"
                        variant="outlined"
                        size="small"
                        sx={{ marginTop: "5px" }}
                        value={encodeUtf8(cpEnvio)}
                        onChange={onChangeCpEnvio}
                        fullWidth
                      />
                    }
                    sx={{ span: { fontWeight: "500" } }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Costo de envío"
                    secondary={
                      <TextField
                        label="Costo de envío"
                        variant="outlined"
                        size="small"
                        sx={{ marginTop: "5px" }}
                        value={encodeUtf8(totalEnvio)}
                        onChange={onChangeTotalEnvio}
                        fullWidth
                      />
                    }
                    sx={{ span: { fontWeight: "500" } }}
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            border: "1px solid #dedede",
            borderTop: "3px solid #00c0ef",
            margin: "10px",
            borderRadius: "5px",
          }}
        >
          <Box sx={{ borderBottom: "1px solid #dedede" }}>
            <Typography
              variant="h6"
              sx={{
                fontSize: "1.18rem",
                margin: "7px",
                marginLeft: "12px",
              }}
            >
              Datos de facturación
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <List
                sx={{
                  "& .MuiListItem-root": { paddingTop: 0 },
                }}
              >
                <ListItem>
                  <ListItemText
                    primary="RFC"
                    secondary={
                      <TextField
                        label="RFC"
                        variant="outlined"
                        size="small"
                        sx={{ marginTop: "5px" }}
                        value={encodeUtf8(rfc)}
                        onChange={onChangeRfc}
                        fullWidth
                      />
                    }
                    sx={{ span: { fontWeight: "500" } }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Calle"
                    secondary={
                      <TextField
                        label="Calle"
                        variant="outlined"
                        size="small"
                        sx={{ marginTop: "5px" }}
                        value={encodeUtf8(calleFacturacion)}
                        onChange={onChangeCalleFacturacion}
                        fullWidth
                      />
                    }
                    sx={{ span: { fontWeight: "500" } }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Ciudad"
                    secondary={
                      <TextField
                        label="Ciudad"
                        variant="outlined"
                        size="small"
                        sx={{ marginTop: "5px" }}
                        value={encodeUtf8(ciudadFacturacion)}
                        onChange={onChangeCiudadFacturacion}
                        fullWidth
                      />
                    }
                    sx={{ span: { fontWeight: "500" } }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Estado"
                    secondary={
                      <FormControl
                        variant="outlined"
                        size="small"
                        fullWidth
                        sx={{ marginTop: "5px" }}
                      >
                        <InputLabel>Estado</InputLabel>
                        <Select
                          label="Estado"
                          value={encodeUtf8(estadoFacturacion)}
                          onChange={onChangeEstadoFacturacion}
                        >
                          {estados.map((estado) => (
                            <MenuItem
                              key={estado.id_estado}
                              value={estado.name}
                            >
                              {estado.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    }
                    sx={{ span: { fontWeight: "500" } }}
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} md={8} sx={{ paddingTop: 0 }}>
              <List
                sx={{
                  "& .MuiListItem-root": { paddingTop: 0 },
                  paddingBottom: 0,
                }}
              >
                <ListItem>
                  <ListItemText
                    primary="Razón social"
                    secondary={
                      <TextField
                        label="Razón social"
                        variant="outlined"
                        size="small"
                        sx={{ marginTop: "5px" }}
                        value={encodeUtf8(razonSocial)}
                        onChange={onChangeRazonSocial}
                        fullWidth
                      />
                    }
                    sx={{ span: { fontWeight: "500" } }}
                  />
                </ListItem>
              </List>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6} sx={{ paddingTop: 0 }}>
                  <List
                    sx={{
                      "& .MuiListItem-root": { paddingTop: 0 },
                      paddingTop: 0,
                    }}
                  >
                    <ListItem>
                      <ListItemText
                        primary="Número Exterior"
                        secondary={
                          <TextField
                            label="Número Exterior"
                            variant="outlined"
                            size="small"
                            sx={{ marginTop: "5px" }}
                            value={encodeUtf8(numeroExtFacturacion)}
                            onChange={onChangeNumeroExtFacturacion}
                            fullWidth
                          />
                        }
                        sx={{ span: { fontWeight: "500" } }}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Delegación"
                        secondary={
                          <TextField
                            label="Delegación"
                            variant="outlined"
                            size="small"
                            sx={{ marginTop: "5px" }}
                            value={encodeUtf8(delegacionFacturacion)}
                            onChange={onChangeDelegacionFacturacion}
                            fullWidth
                          />
                        }
                        sx={{ span: { fontWeight: "500" } }}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="País"
                        secondary={
                          <TextField
                            label="País"
                            variant="outlined"
                            size="small"
                            sx={{ marginTop: "5px" }}
                            value={encodeUtf8(paisFacturacion)}
                            onChange={onChangePaisFacturacion}
                            fullWidth
                          />
                        }
                        sx={{ span: { fontWeight: "500" } }}
                      />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={12} md={6}>
                  <List
                    sx={{
                      "& .MuiListItem-root": { paddingTop: 0 },
                      paddingTop: 0,
                    }}
                  >
                    <ListItem>
                      <ListItemText
                        primary="Colonia"
                        secondary={
                          <TextField
                            label="Colonia"
                            variant="outlined"
                            size="small"
                            sx={{ marginTop: "5px" }}
                            value={encodeUtf8(coloniaFacturacion)}
                            onChange={onChangeColoniaFacturacion}
                            fullWidth
                          />
                        }
                        sx={{ span: { fontWeight: "500" } }}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Código postal"
                        secondary={
                          <TextField
                            label="Código postal"
                            variant="outlined"
                            size="small"
                            sx={{ marginTop: "5px" }}
                            value={encodeUtf8(cpFacturacion)}
                            onChange={onChangeCpFacturacion}
                            fullWidth
                          />
                        }
                        sx={{ span: { fontWeight: "500" } }}
                      />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
}

export default InformacionCDD;
