import axios from "axios";

export const asociarCaso = async (data) => {
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_BASEURL}/crm/association/create`,
      data
    );
    console.log("asociar caso result:", result);
  } catch (error) {
    console.log(error);
  }
};
