import axios from "axios";

/**
 * GET
 **/

const getMoveInv = async (currentPage, idUser, initDate, endDate) => {
  let URL = ``;
  if (initDate && endDate) {
    URL = `${process.env.REACT_APP_BASEURL}/proveedores/ventas/products/all/new?page=${currentPage}&idUser=${idUser}&initDate=${initDate}&endDate=${endDate}`;
  } else {
    URL = `${process.env.REACT_APP_BASEURL}/proveedores/ventas/products/all/new?page=${currentPage}&idUser=${idUser}`;
  }
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const searchMoveInv = async (
  currentPage,
  idUser,
  initDate,
  endDate,
  search
) => {
  let URL = ``;
  if (initDate && endDate) {
    URL = `${process.env.REACT_APP_BASEURL}/proveedores/ventas/products/all/search?page=${currentPage}&idUser=${idUser}&initDate=${initDate}&endDate=${endDate}&search=${search}`;
  } else {
    URL = `${process.env.REACT_APP_BASEURL}/proveedores/ventas/products/all/search?page=${currentPage}&idUser=${idUser}`;
  }
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getMovePDF = async (idUser, initDate, endDate) => {
  let URL = `${process.env.REACT_APP_BASEURL}/proveedores/ventas/products/moves/get?idUser=${idUser}&initDate=${initDate}&endDate=${endDate}`;

  function formatNumber(num) {
    return num.toLocaleString("en-US");
  }

  try {
    let res = await axios(URL);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        OrdenDeCompra: element.OrdendeCompra,
        StatusOrdenDeCompra: element.Status_OC,
        Proveedor: element.CardName,
        SKU: `'${element.ItemCode[0]}'`, //+ indices
        Nombre: element.Dscription[0], //+ indices
        CantidadSolicitada: element.Cantidad_Solicitada,
        CantidadRecibida: element.Cantidad_Recibida,
        CantidadRestante: element.OpenQty,
        Fechaentrega: element.FechaEntrega
          ? new Date(element.FechaEntrega).toLocaleDateString("es-MX", {
              timeZone: "UTC",
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })
          : "",
        StatusLinea: element.Status_Linea,
        Price: formatNumber(element.AvgPrice * element.Cantidad_Solicitada),
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

export { getMoveInv, searchMoveInv, getMovePDF };
