import { Box, Grid, Button, Chip} from "@mui/material";
import { useEffect, useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { getHRiskDetails } from "../../../utils/trazabilidad/pedidos/pedidos";

import local from "dayjs/plugin/localizedFormat";
import relative from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";

dayjs.locale("es-mx");
dayjs.extend(local);
dayjs.extend(relative);
dayjs.extend(utc);

const chipColors = {
    "SinEstatus": "#ffa000",
    "Aprobadas": "#43a047",
    "Cerrada": "#e53935",
    "Rechazadas": "#e53935",
    "En Espera de Nota de Credito": "#ffa000",
    "Other": "#3c8dbc",
    "StatusCliente": "#43a047",
    "ELoER": "#3c8dbc",
    "EoCL":"#43a047",
    "OtherSL": "#ffa000",
    "EEDNDCoEEDR": "#ffa000",
    "OtherSF":"#e53935",
};

const AltoRiesgo = ({ id }) => {
    const [altoR, setAltoR] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const getAltoRiesgo = async () => {
        try {
            setIsLoading(true);
            const res = await getHRiskDetails(id);
            setAltoR(res);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getAltoRiesgo();
    }, [id]);

    return (<>
        <Box sx={{ p:2 }} component={Paper}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Tipificación</TableCell>
                            <TableCell>Status general</TableCell>
                            <TableCell>Status at clientes</TableCell>
                            <TableCell>Status logistica</TableCell>
                            <TableCell>Status logistica</TableCell>
                            <TableCell>Acción</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {altoR && altoR.data.map(({id_operacion, nombre_tip, status_general, status_atencion_cliente, status_logistica, status_finanzas, }) => (
                            <TableRow>
                                <TableCell>{nombre_tip ? nombre_tip : "Sin definir"}</TableCell>
                                <TableCell>{status_general ? (status_general === "Aprobadas" ?  <Chip label={"Aprobadas"} size="small" sx={{ backgroundColor: chipColors["Aprobadas"], color: "#fff", }}/> : (status_general === "Cerrada" ? <Chip label={"Cerrada"} size="small" sx={{ backgroundColor: chipColors["Cerrada"], color: "#fff", }}/> : (status_general === "Rechazadas" ? <Chip label={"Rechazadas"} size="small" sx={{ backgroundColor: chipColors["Rechazadas"], color: "#fff", }}/> : (status_general === "En Espera de Nota de Credito" ? <Chip label={"En Espera de Nota de Credito"} size="small" sx={{ backgroundColor: chipColors["En Espera de Nota de Credito"], color: "#fff", }}/> : <Chip label={status_general} size="small" sx={{ backgroundColor: chipColors["Other"], color: "#fff", }}/> ))) ) : <Chip label="S/Estatus" size="small" sx={{ backgroundColor: chipColors["SinEstatus"], color: "#fff", }}/> }</TableCell>
                                <TableCell>{status_atencion_cliente ? <Chip label={status_atencion_cliente} size="small" sx={{ backgroundColor: chipColors["StatusCliente"], color: "#fff", }}/> : <Chip label="S/Estatus" size="small" sx={{ backgroundColor: chipColors["SinEstatus"], color: "#fff", }}/> }</TableCell>
                                <TableCell>{status_logistica ? ((status_logistica === "Enterado Logistica" ||  "En ruta") ? <Chip label={status_logistica} size="small" sx={{ backgroundColor: chipColors["ELoER"], color: "#fff", }}/> : ((status_logistica === "Entregado" ||  "Confirmado liberacion") ? <Chip label={status_logistica} size="small" sx={{ backgroundColor: chipColors["EoCL"], color: "#fff", }}/> : <Chip label={status_logistica} size="small" sx={{ backgroundColor: chipColors["OtherSL"], color: "#fff", }}/> ) ) : <Chip label="S/Estatus" size="small" sx={{ backgroundColor: chipColors["SinEstatus"], color: "#fff", }}/>}</TableCell>
                                <TableCell>{status_finanzas ? ((status_finanzas === "En Espera de Nota de Credito" || "En Espera de Reemboolso") ? <Chip label={status_finanzas} size="small" sx={{ backgroundColor: chipColors["EEDNDCoEEDR"], color: "#fff", }}/> : <Chip label={status_finanzas} size="small" sx={{ backgroundColor: chipColors["OtherSF"], color: "#fff", }}/>) : <Chip label="S/Estatus" size="small" sx={{ backgroundColor: chipColors["SinEstatus"], color: "#fff", }}/> }</TableCell>
                                <TableCell>
                                    <Button variant="contained" color="primary" disableElevation onClick={() => window.open(`https://klaar.ezetera.com/klaar/alto/alto/detalle/${id_operacion}`, '_blank')}>
                                        <VisibilityIcon />
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    </>);
};

export default AltoRiesgo;