import axios from "axios";

/**
 * GET
 **/

const getTransfers = async (currentPage) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/traspasos/traspasos/all/new?page=${currentPage}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const searchTransfers = async (currentPage, search) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/traspasos/traspasos/all/search/transfers?page=${currentPage}&search=${search}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getProducts = async (idTraspaso, currentPage) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/traspasos/traspasos/detail/products?idTraspaso=${idTraspaso}&page=${currentPage}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const searchProducts = async (idTraspaso, currentPage, search) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/traspasos/traspasos/detail/search/products?idTraspaso=${idTraspaso}&page=${currentPage}&search=${search}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getTransfersById = async (idTraspaso) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/traspasos/traspasos/detail/transfer?idTraspaso=${idTraspaso}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const addTransaction = async (data) => {
  const URL = `${process.env.REACT_APP_BASEURL}/ventaMayoreo/traspasos/traspasos/detail/add/transaction`;
  try {
    await axios.post(URL, data);
  } catch (error) {
    console.error("addTransaction", error);
  }
};

export {
  getTransfers,
  searchTransfers,
  getProducts,
  searchProducts,
  getTransfersById,
  addTransaction
};
