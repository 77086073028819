import { useEffect, useState } from "react";
import {
  Alert,
  Paper,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useAuth } from "../../../context/AuthContext";
import {
  getReportePedidosEscuelas,
  getReporteNpsTiendas,
  getReporteVentaMayoreoGeneral,
  getReporteRMASkus,
  getReporteAclaracionesSku,
  getReporteNPSKlipp,
  getReporteCasos,
  getReporteCasosyAsociados,
  getReporteInventarios,
  getReporteComentariosAclaraciones,
  getReporteTickets,
  getReportePedidosPorFecha,
  getReporteCuponesUsados,
  getReporteCarritosAbandonados,
  getReportePoblacionDeColegios,
  getReporteOutOfStockSubscribers,
  getReporteMTeam,
  getReporteBundles,
  getReporteMontosWebM,
  getReporteNPS,
  getReporteGastosEnvios,
  getReporteMontosWebS,
  getReporteModeloResurtidoTiendas,
  getReporteRMAFinanzas,
  getReporteAclaracionesFinanzas,
  getReporteEntradasMercanciaDevoluciones,
  getReporteVentasNetas
} from "../../../utils/reportes/reportes";
import ExcelFile from "../../general/ExcelFile";
import { LoadingButton } from "@mui/lab";
import { HistoricoLinks } from "./HistoricoLinks";

export const DetailPanelContent = ({ row: rowProp }) => {
  const [fechaInicio, setFechaInicio] = useState(null);
  const [fechaFinal, setFechaFinal] = useState(null);
  const [excelData, setExcelData] = useState(null);
  const [isELoading, setIsELoading] = useState(false);
  const [fechaError, setFechaError] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const { user } = useAuth();
  const email = user.data.email;

  useEffect(() => {
    const validarFechas = () => {
      if (fechaInicio && fechaFinal) {
        const fechaInicioObj = new Date(fechaInicio);
        const fechaFinalObj = new Date(fechaFinal);
        if (fechaInicioObj > fechaFinalObj) {
          setFechaError(true);
          setOpenSnackbar(true);
        } else {
          setFechaError(false);
          setOpenSnackbar(false);
        }
      }
    };

    validarFechas();
  }, [fechaInicio, fechaFinal]);

  const handleButtonClick = (row) => {
    buscarReporte(fechaInicio, fechaFinal, row);
  };

  const buscarReporte = async (initDate, endDate, row) => {
    var horaActual = new Date().toTimeString().slice(0, 8).replace(/:/g, "");
    switch (row.report) {
      case "Pedidos y Escuelas":
        try {
          setIsELoading(true);
          const response = await getReportePedidosEscuelas(initDate, endDate);
          setExcelData({
            data: response,
            name: `reporte_pedidos_escuelas ${initDate} a ${endDate} ${horaActual}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      case "NPS Tiendas":
        try {
          setIsELoading(true);
          const response = await getReporteNpsTiendas(initDate, endDate);
          setExcelData({
            data: response,
            name: `nps_tiendas ${initDate} a ${endDate} ${horaActual}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      case "Venta Mayoreo General":
        try {
          setIsELoading(true);
          const response = await getReporteVentaMayoreoGeneral(
            initDate,
            endDate
          );
          setExcelData({
            data: response,
            name: `reporte_general_VM ${initDate} a ${endDate} ${horaActual}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      case "RMA - Skus":
        try {
          setIsELoading(true);
          const response = await getReporteRMASkus(initDate, endDate);
          setExcelData({
            data: response,
            name: `reporte_RMA_Skus ${initDate} a ${endDate} ${horaActual}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      case "Aclaraciones - Skus":
        try {
          setIsELoading(true);
          const response = await getReporteAclaracionesSku(initDate, endDate);
          setExcelData({
            data: response,
            name: `reporte_Aclaraciones_SKUS ${initDate} a ${endDate} ${horaActual}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      case "NPS-Klipps":
        try {
          setIsELoading(true);
          const response = await getReporteNPSKlipp(initDate, endDate, email);
          setExcelData({
            data: response,
            name: `Reporte NPS Klipps ${initDate} a ${endDate} ${horaActual}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      case "Casos":
        try {
          setIsELoading(true);
          const response = await getReporteCasos(initDate, endDate);
          setExcelData({
            data: response,
            name: `reporte_casos ${initDate} a ${endDate} ${horaActual}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      case "Casos y Asociados":
        try {
          setIsELoading(true);
          const response = await getReporteCasosyAsociados(initDate, endDate);
          setExcelData({
            data: response,
            name: `CRM_asociados ${initDate} a ${endDate} ${horaActual}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      case "Inventarios":
        try {
          setIsELoading(true);
          const response = await getReporteInventarios(initDate, endDate);
          setExcelData({
            data: response,
            name: `Reporte de inventarios ${initDate} a ${endDate} ${horaActual}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      case "Comentarios - Aclaraciones":
        try {
          setIsELoading(true);
          const response = await getReporteComentariosAclaraciones(
            initDate,
            endDate
          );
          setExcelData({
            data: response,
            name: `Aclaraciones_comentarios ${initDate} a ${endDate} ${horaActual}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      case "Tickets":
        try {
          setIsELoading(true);
          const response = await getReporteTickets(initDate, endDate);
          setExcelData({
            data: response,
            name: `Reporte_tickets ${initDate} a ${endDate} ${horaActual}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      case "Pedidos por fecha":
        try {
          setIsELoading(true);
          const response = await getReportePedidosPorFecha(initDate, endDate);
          setExcelData({
            data: response,
            name: `Reporte_PedidosPorFecha ${initDate} a ${endDate} ${horaActual}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      case "Cupones Usados":
        try {
          setIsELoading(true);
          const response = await getReporteCuponesUsados(initDate, endDate);
          setExcelData({
            data: response,
            name: `reporteCupones ${initDate} a ${endDate} ${horaActual}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      case "Carritos Abandonados":
        try {
          setIsELoading(true);
          const response = await getReporteCarritosAbandonados(
            initDate,
            endDate
          );
          setExcelData({
            data: response,
            name: `carritosabandonados ${initDate} a ${endDate} ${horaActual}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      case "Poblacion de Colegios":
        try {
          setIsELoading(true);
          const response = await getReportePoblacionDeColegios(
            initDate,
            endDate
          );
          setExcelData({
            data: response,
            name: `listadopoblaciondecolegios ${initDate} a ${endDate} ${horaActual}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      case "Out of Stock Subscribers":
        try {
          setIsELoading(true);
          const response = await getReporteOutOfStockSubscribers(
            initDate,
            endDate
          );
          setExcelData({
            data: response,
            name: `OutofStockSubscribers_${initDate} a ${endDate} ${horaActual}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      case "M Team":
        try {
          setIsELoading(true);
          const response = await getReporteMTeam(initDate, endDate);
          setExcelData({
            data: response,
            name: `reportem_team ${initDate} a ${endDate} ${horaActual}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      case "Bundle's":
        try {
          setIsELoading(true);
          const response = await getReporteBundles(initDate, endDate);
          setExcelData({
            data: response,
            name: `Reporte_bundles ${initDate} a ${endDate} ${horaActual}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      case "Montos Web M":
        try {
          setIsELoading(true);
          const response = await getReporteMontosWebM(initDate, endDate);
          setExcelData({
            data: response,
            name: `reporte_web_m ${initDate} a ${endDate} ${horaActual}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      case "NPS":
        try {
          setIsELoading(true);
          const response = await getReporteNPS(initDate, endDate);
          setExcelData({
            data: response,
            name: `reporte_nps ${initDate} a ${endDate} ${horaActual}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      case "Gastos de Envio":
        try {
          setIsELoading(true);
          const response = await getReporteGastosEnvios(initDate, endDate);
          setExcelData({
            data: response,
            name: `reportegastosEnvios ${initDate} a ${endDate} ${horaActual}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      case "Montos WEB S":
        try {
          setIsELoading(true);
          const response = await getReporteMontosWebS(initDate, endDate);
          setExcelData({
            data: response,
            name: `reportesap ${initDate} a ${endDate} ${horaActual}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      case "Modelo de Resurtido de Tiendas":
        try {
          setIsELoading(true);
          const response = await getReporteModeloResurtidoTiendas(
            initDate,
            endDate
          );
          setExcelData({
            data: response,
            name: `reporte_Modelo_Resurtido_Tiendas ${initDate} a ${endDate} ${horaActual}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      case "RMA - Finanzas":
        try {
          setIsELoading(true);
          const response = await getReporteRMAFinanzas(initDate, endDate);
          setExcelData({
            data: response,
            name: `reporte_rma_finanzas_m ${initDate} a ${endDate} ${horaActual}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      case "Aclaraciones - Finanzas":
        try {
          setIsELoading(true);
          const response = await getReporteAclaracionesFinanzas(
            initDate,
            endDate
          );
          setExcelData({
            data: response,
            name: `reporte_rma_aclaraciones_m ${initDate} a ${endDate} ${horaActual}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      case "Entradas de Mercancía y Devoluciones":
        try {
          setIsELoading(true);
          const response = await getReporteEntradasMercanciaDevoluciones(
            initDate,
            endDate
          );
          setExcelData({
            data: response,
            name: `reporte_web_m ${initDate} a ${endDate} ${horaActual}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      case "Ventas Netas":
        try {
          setIsELoading(true);
          const response = await getReporteVentasNetas(
            initDate,
            endDate
          );
          setExcelData({
            data: response,
            name: `VentasNetas2 ${initDate} a ${endDate} ${horaActual}`,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsELoading(false);
        }
        break;
      default:
        console.log("No se econtro la opcion");
    }
  };

  return (
    <Stack
      sx={{ py: 2, height: "100%", boxSizing: "border-box" }}
      direction="column"
    >
      <Paper
        sx={{
          flex: 1,
          mx: "auto",
          width: "90%",
          p: 1,
          minWidth: "530px",
          backgroundColor: "rgb(245 245 245 / 30%)",
        }}
      >
        <Stack direction="column" spacing={1} sx={{ height: 1 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ textAlign: "center" }}>Fecha Inicio</TableCell>
                <TableCell sx={{ textAlign: "center" }}>Fecha Final</TableCell>
                <TableCell sx={{ textAlign: "center" }}>Accion</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ border: 0 }}>
                <TableCell sx={{ width: "15%", textAlign: "center" }}>
                  <TextField
                    type="date"
                    onChange={(e) => {
                      setFechaInicio(e.target.value);
                      setExcelData(null);
                    }}
                    inputProps={{
                      min: `${new Date().getFullYear()}-01-01`,
                      max: new Date().toISOString().split("T")[0],
                    }}
                    value={fechaInicio || ""}
                    error={fechaError}
                    helperText={fechaError ? "Error en la fecha" : ""}
                  />
                </TableCell>
                <TableCell sx={{ width: "10%", textAlign: "center" }}>
                  <TextField
                    type="date"
                    onChange={(e) => {
                      setFechaFinal(e.target.value);
                      setExcelData(null);
                    }}
                    inputProps={{
                      min: `${new Date().getFullYear()}-01-01`,
                      max: new Date().toISOString().split("T")[0],
                    }}
                    value={fechaFinal || ""}
                    error={fechaError}
                    helperText={fechaError ? "Error en la fecha" : ""}
                  />
                </TableCell>
                <TableCell sx={{ width: "10%", textAlign: "center" }}>
                  {!excelData && (
                    <LoadingButton
                      variant="contained"
                      loading={isELoading}
                      onClick={() => handleButtonClick(rowProp)}
                      disabled={!fechaInicio || !fechaFinal || fechaError}
                      sx={{ mt: 1 }}
                    >
                      Buscar
                    </LoadingButton>
                  )}
                  {excelData && (
                    <ExcelFile
                      excelData={excelData.data}
                      fileName={excelData.name}
                    />
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <HistoricoLinks row={rowProp} />
        </Stack>
      </Paper>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{ marginTop: "50px" }}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="error"
          variant="filled"
        >
          La fecha de inicio debe ser menor que la fecha final
        </Alert>
      </Snackbar>
    </Stack>
  );
};
