import axios from "axios";

/**
 * GET
 **/

const getVerifications = async (currentPage) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/verifications/verifications/all/new?page=${currentPage}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const searchVerifications = async (currentPage, search) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/verifications/verifications/all/search?page=${currentPage}&search=${search}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getOrderData = async (idOrder) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/verifications/verifications/orders/details?idOrder=${idOrder}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data.data[0] : [];
  } catch (error) {
    console.error(error);
  }
};

const getOrderGuides = async (idOrder) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/verifications/verifications/orders/guides?idOrder=${idOrder}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getVerifDetails = async (idVer) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/verifications/verifications/details?idVer=${idVer}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data.data[0] : [];
  } catch (error) {
    console.error(error);
  }
};

const getVerifMovements = async (idVer) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/verifications/verifications/movements?idVer=${idVer}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getCarriers = async () => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/verifications/verifications/carriers/all`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getCarriersServices = async (idCarrier) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/verifications/verifications/carriers/services?idCarrier=${idCarrier}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getVerifGuides = async (idVer) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/verifications/verifications/details/guides?idVer=${idVer}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data.data : [];
  } catch (error) {
    console.error(error);
  }
};

/**
 * POST
 **/

const addVerif = async (idOrder, idUser, idGuide) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/verifications/verifications/add`;
  try {
    await axios.post(URL, { idOrder, idUser, idGuide });
  } catch (error) {
    console.error("addVerif", error);
  }
};

const saveVSD = async (
  idVer,
  idUser,
  name,
  country,
  street,
  oNumber,
  iNumber,
  betStr,
  sub,
  del,
  cp,
  city,
  state,
  ref,
  tel
) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/verifications/verifications/shipdata/save`;
  try {
    await axios.post(URL, {
      idVer,
      idUser,
      name,
      country,
      street,
      oNumber,
      iNumber,
      betStr,
      sub,
      del,
      cp,
      city,
      state,
      ref,
      tel,
    });
  } catch (error) {
    console.error("saveVSD", error);
  }
};

const saveTipification = async (idVer, idUser, idTipo, increment_id) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/verifications/verifications/tipifications/save`;
  try {
    await axios.post(URL, { idVer, idUser, idTipo, increment_id });
  } catch (error) {
    console.error("saveTipification", error);
  }
};

const newGuide = async (idVer, idUser, idService, idCarrier) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/verifications/verifications/guide/generate`;
  try {
    await axios.post(URL, { idVer, idUser, idService, idCarrier });
  } catch (error) {
    console.error("newGuide", error);
  }
};

export const getVerificationsExcel = async () => {
  try {
    const vers = await getVerifications("All");
    const rows = [];
    vers.data.forEach(async (ver) => {
      const row = {
        "ID Verificacion": ver.id_verificaciones,
        Pedido: ver.increment_id,
        Guia: ver.name_guia,
        Estatus: ver.name_status,
        Tipificacion: ver.name_tipo,
        "Fecha creacion": ver.creation_date
          ? new Date(ver.creation_date).toLocaleDateString("es-mx", {
              timeZone: "UTC",
            })
          : "",
        "Fecha cierre": ver.fecha_cierre
          ? new Date(ver.fecha_cierre).toLocaleDateString("es-mx", {
              timeZone: "UTC",
            })
          : "",
      };

      rows.push(row);
    });

    return rows;
  } catch (error) {
    console.error("getVerificationsExcel()", error);
  }

  return [];
};

export {
  getOrderData,
  getOrderGuides,
  addVerif,
  getVerifDetails,
  saveVSD,
  getVerifMovements,
  saveTipification,
  newGuide,
  getCarriers,
  getCarriersServices,
  getVerifGuides,
  getVerifications,
  searchVerifications,
};
