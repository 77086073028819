import { profiles } from "./profilesVentaMayoreo";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
const dicon = <RadioButtonUncheckedIcon fontSize='small' />;

export const ventaMayoreoModules = [
  {
    label: "Requerimientos",
    icon: dicon,
    slug: "requerimientos",
    profiles: profiles.requerimientos
  },
  {
    label: "Validaciones",
    icon: dicon,
    slug: "validaciones",
    profiles: profiles.validaciones
  },
  {
    label: "Cotizaciones",
    icon: dicon,
    slug: "cotizaciones",
    profiles: profiles.cotizaciones
  },
  {
    label: "Traspasos",
    icon: dicon,
    slug: "traspasos",
    profiles: profiles.traspasos
  },
  {
    label: "Surtidos",
    icon: dicon,
    slug: "surtidos",
    profiles: profiles.surtidos
  },
  {
    label: "Órdenes de compra",
    icon: dicon,
    slug: "ordenes-compra",
    profiles: profiles.ordenesC
  },
  {
    label: "Órdenes de venta",
    icon: dicon,
    slug: "ordenes-venta",
    profiles: profiles.ordenesV
  },
  {
    label: "Proveedores",
    icon: dicon,
    slug: "proveedores",
    profiles: profiles.proveedores
  },
  {
    label: "Clientes",
    icon: dicon,
    slug: "clientes",
    profiles: profiles.clientes
  },
  {
    label: "Distribuidores",
    icon: dicon,
    slug: "distribuidores",
    profiles: profiles.distribuidores
  },
  {
    label: "Resellers",
    icon: dicon,
    slug: "resellers",
    profiles: profiles.resellers
  },
  {
    label: "Uniformes",
    icon: dicon,
    slug: "uniformes",
    profiles: profiles.uniformes
  },
  {
    label: "Productos",
    icon: dicon,
    slug: "productos",
    profiles: profiles.productos
  },
  {
    label: "Configuraciones",
    icon: dicon,
    slug: "configuraciones",
    profiles: profiles.configuraciones
  },
  {
    label: "Bitácora de envíos",
    icon: dicon,
    slug: "bitacora-envios",
    profiles: profiles.bitacora
  },
];
