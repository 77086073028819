import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  FormHelperText,
  Select,
  MenuItem,
  List,
  ListItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useState } from "react";
import { createBeneficiary } from "../../../utils/vales/beneficiarios/beneficiarios";

const ModalCreateBeneficiario = ({
  open,
  onClose,
  setEstado,
  schools,
  creationUsr,
  setLoading,
  setRows,
}) => {
  const [name, setName] = useState("");
  const [apellidoP, setApellidoP] = useState("");
  const [apellidoM, setApellidoM] = useState("");
  const [mail, setMail] = useState("");
  const [selectedSchoolId, setSelectedSchoolId] = useState("");
  const [errors, setErrors] = useState({});

  const isValidEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return regex.test(email);
  };

  const handleAdd = async () => {
    let tempErrors = {};

    if (name.length <= 3)
      tempErrors.name = "El nombre debe tener más de 3 caracteres";
    if (apellidoP.length <= 3)
      tempErrors.apellidoP =
        "El apellido paterno debe tener más de 3 caracteres";
    if (apellidoM.length <= 3)
      tempErrors.apellidoM =
        "El apellido materno debe tener más de 3 caracteres";
    if (!isValidEmail(mail))
      tempErrors.mail = "Introduce un correo electrónico válido";
    if (selectedSchoolId === "")
      tempErrors.selectedSchoolId = "Selecciona la Sede del Beneficiario";

    setErrors(tempErrors);

    if (Object.keys(tempErrors).length === 0) {
      let data = {
        name: name,
        last_name: apellidoP,
        mothers_name: apellidoM,
        mail,
        id_school: selectedSchoolId,
        creationUsr,
      };
      onClose();
      setLoading(true);
      setRows([]);
      await createBeneficiary(data);
      setEstado((prevEstado) => (prevEstado === 0 ? 1 : 0));
      setName("");
      setApellidoM("");
      setApellidoP("");
      setMail("");
      setSelectedSchoolId("");
    }
  };

  const onCloseAndClear = () => {
    onClose();
    setName("");
    setApellidoM("");
    setApellidoP("");
    setMail("");
    setSelectedSchoolId("");
    setErrors({});
  };

  //Schools
  const handleSchoolChange = (event) => {
    setSelectedSchoolId(event.target.value);
  };

  return (
    <Dialog open={open} onClose={onCloseAndClear}>
      <DialogTitle>Agregar Beneficiario</DialogTitle>
      <DialogContent>
        <List>
          <ListItem>
            <TextField
              variant="outlined"
              required
              fullWidth
              label="Nombre"
              value={name}
              onChange={(e) => setName(e.target.value)}
              error={!!errors.name}
              helperText={errors.name}
              sx={{ width: "500px" }}
            />
          </ListItem>
          <ListItem>
            <TextField
              variant="outlined"
              required
              fullWidth
              label="Apellido Paterno"
              value={apellidoP}
              onChange={(e) => setApellidoP(e.target.value)}
              error={!!errors.apellidoP}
              helperText={errors.apellidoP}
              sx={{ width: "500px" }}
            />
          </ListItem>
          <ListItem>
            <TextField
              variant="outlined"
              required
              fullWidth
              label="Apellido Materno"
              value={apellidoM}
              onChange={(e) => setApellidoM(e.target.value)}
              error={!!errors.apellidoM}
              helperText={errors.apellidoM}
              sx={{ width: "500px" }}
            />
          </ListItem>
          <ListItem>
            <TextField
              variant="outlined"
              id="mail"
              name="mail"
              required
              fullWidth
              label="Email"
              value={mail}
              onChange={(e) => setMail(e.target.value)}
              error={!!errors.mail}
              helperText={errors.mail}
              sx={{ width: "500px" }}
            />
          </ListItem>
          <ListItem>
            <FormControl
              variant="outlined"
              sx={{ width: "500px" }}
              error={!!errors.selectedSchoolId}
            >
              <InputLabel id="school-label">Sede del Beneficiario</InputLabel>
              <Select
                labelId="school-label"
                label="Sede del Beneficiario"
                value={selectedSchoolId}
                onChange={handleSchoolChange}
              >
                {schools.map((school) => (
                  <MenuItem key={school.school_id} value={school.school_id}>
                    {school.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.selectedSchoolId}</FormHelperText>
            </FormControl>
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseAndClear} color="error" variant="outlined">
          Cancelar
        </Button>
        <Button onClick={handleAdd} color="success" variant="outlined">
          Agregar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalCreateBeneficiario;
