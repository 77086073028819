import { Box, CircularProgress, Typography, Tabs, Tab } from "@mui/material";
import { useState } from "react";
import { useTabs } from "../../../../context/TabsContext";

import GuiasTab from "./GuiasTab";
import DetallesPedidoTab from "./DetallesPedidoTab";
import AgregarGuiasTab from "./AgregarGuiasTab";

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
        </div>
    );
};

const IndexTabs = ({ increment_id, domicilio }) => {
    const { crmTab, setCrmTab } = useTabs();
    const [isLoading, setIsLoading] = useState(false);

    const handleTabChange = (event, value) => {
        setCrmTab(value);
    };

    return (
        <>            
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={crmTab} onChange={handleTabChange} >
                    <Tab label="Guias" />
                    <Tab label="Detalles de pedido" />
                    <Tab label="Agregar guias" />
                    <Tab label="Emails Transaccionales" />
                </Tabs>
            </Box>
            {isLoading && (<Box> <Typography variant="p"> Cargando ...</Typography> <CircularProgress /> </Box>)}
            <TabPanel value={crmTab} index={0}> <GuiasTab increment_id={ increment_id } /> </TabPanel>
            <TabPanel value={crmTab} index={1}> <DetallesPedidoTab increment_id={ increment_id } /> </TabPanel >
            <TabPanel value={crmTab} index={2}> <AgregarGuiasTab increment_id={increment_id} domicilio={domicilio} /></TabPanel>
            <TabPanel value={crmTab} index={3}> </TabPanel>
        </>
    );
};

export default IndexTabs;