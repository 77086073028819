import useSWR from "swr";
import {
  Box,
  Chip,
  CircularProgress,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAuth } from "../../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { useLocation } from "react-router-dom";
import CargasTableBody from "./CargasTableBody";

const useCargas = (keyWordC, currentPageC, user) => {
    const fetcher = (...args) => fetch(...args).then((res) => res.json());
    let optc = "";
    
    if(typeof keyWordC === 'undefined' || typeof keyWordC === undefined || keyWordC.length < 3)
      optc = `${process.env.REACT_APP_BASEURL}/trazabilidad/exceptions/loads/all?page=${currentPageC}&idUser=${user.data.awsid}`;  
    else
      optc = `${process.env.REACT_APP_BASEURL}/trazabilidad/exceptions/loads/search?page=${currentPageC}&search=${keyWordC}&idUser=${user.data.awsid}`;
    
    const urlc = optc;
    const { data } = useSWR(urlc, fetcher, {
      //refreshInterval: 2000,
      revalidateOnFocus: true,
    });
  
    return {
      page: currentPageC,
      rowsPerPage: data?.data?.length ?? 0,
      rows: data?.data ?? [],
      total: data?.total ?? 0,
    };
};

const CargasTable = () => {
    const [keyWordC, setExcepABuscar] = useState("");
    const [currentPageC, setCurrentPageC] = useState(0);
    const [loading, setLoading] = useState({});
    const { user } = useAuth();    
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [excepFiltradosC, setExcepFiltradosC] = useState(null);
    const cargas = useCargas(keyWordC, currentPageC, user);

    const handleChangePageC = (event, newPage) => {
        setCurrentPageC(newPage);
    };

    return (    
        <Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <TextField
                size="small"
                variant="filled"
                label="Buscar"
                sx={{ mx: 1, my: 2 }}
                value={keyWordC}
                onChange={(e) => setExcepABuscar(e.target.value)}
                />
                {isLoading && <CircularProgress size={30} />}
            </Box>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Descargar</TableCell>
                            <TableCell>ID</TableCell>
                            <TableCell>Archivo</TableCell>
                            <TableCell>Fecha</TableCell>
                            <TableCell>Logs</TableCell>
                            <TableCell>Errores</TableCell>
                        </TableRow>
                    </TableHead>
                    <CargasTableBody cargas={excepFiltradosC ?? cargas.rows} loading={loading} />
                    <TableFooter>
                        {cargas.total > 10 && (
                        <TableRow>
                            <TablePagination
                            rowsPerPage={cargas.rowsPerPage}
                            rowsPerPageOptions={[cargas.rowsPerPage]}
                            count={cargas.total}
                            page={cargas.page}
                            onPageChange={handleChangePageC}
                            />
                        </TableRow>
                        )}
                    </TableFooter>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default CargasTable;