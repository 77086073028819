import React from "react";

export const AlmacenRow = ({ ar, almacenes }) => {
  console.log("ar", ar);
  console.log("almacenes", almacenes);
  const nombresAlm = ["CENTRAL", "B2S", "BOLIVAR", "TR1"];
  let idsAlmacen = [];

  return (
    <>
      {ar.map((a) => {
        if (nombresAlm.includes(a.nombre_almacen) && a.nombre_almacen) {
          idsAlmacen.push(a.id_almacen);
          return (
            <div key={a.id_almacen}>
              <strong>
                {a.nombre_almacen}: {a.cantidad_disponible || 0}
              </strong>
              <br />
              <input
                type="hidden"
                value={a.cantidad_disponible}
                id={`${a.id_almacen}-${a.id_requerimiento_producto}`}
              />
            </div>
          );
        }
        return null;
      })}

      {almacenes.map((alm) => {
        if (
          !idsAlmacen.includes(alm.id_almacen) &&
          nombresAlm.includes(alm.nombre_almacen)
        ) {
          return (
            <div key={alm.id_almacen}>
              <strong>{alm.nombre_almacen}: 0</strong>
              <br />
              <input
                type="hidden"
                value="0"
                id={`${alm.id_almacen}-${alm.id_requerimiento_producto}`}
              />
            </div>
          );
        }
        return null;
      })}
    </>
  );
};
