const profiles = {
    aprobarVac: [1,],
    administrarVac: [1,],
    solicitarVac: [1,],
    prestamos: [1,],
};

const belongsCapitalHumano = ( profile ) => {
    let aux = profiles;
    return  aux.aprobarVac.includes( profile ) ? true : aux.administrarVac.includes( profile )
            ? true : aux.solicitarVac.includes( profile ) ? true : aux.prestamos.includes( profile );
}

export{
    profiles, belongsCapitalHumano
};