const profilesAclaraciones = {
    verDatosDeEnvio: [1,7,9,10,15,20,23,29,30,34,35,37,46,58,],
    verGuias: [1,7,9,10,15,20,23,29,30,34,35,37,46,58,],
    verReembolso: [1,7,10,15,23,29,30,34,35,37,58,],
    verRefacturacion: [1,7,15,23,29,30,34,58,],
    verAclarPago: [1,7,10,15,23,29,30,34,35,37,58,],
    editarDatosDeEnvio: [1,7,23,29,30,34,58,],
    editarGuias: [1,9,20,23,29,],
    editarReembolso: [1,7,23,29,30,34,58,],
    editarRefacturacion: [1,7,23,29,30,34,58,],
    editarAclarPago: [1,7,23,29,30,34,58,],
    verNumeroStoreCredit: [1,7,10,23,29,30,34,35,37,58,],
    editarNumeroStoreCredit: [1,10,23,29,35,37,],
    subirArchivosReembolso: [1,10,23,29,35,37,],
    cerraAclaracion: [1,7,9,23,29,30,34,46,58,],
    editarClave: [1,7,23,34,58,],
    cerraResolucion: [1,15,23,],

    aclaraciones_all: [1,7,9,10,15,20,23,29,30,34,35,37,46,58,],
};

const isETabDatosEnvio = ( tip, profile, status, detalle ) => {
    let aux = profilesAclaraciones;
    if(tip === 1){
        if(status === 1){
            if(aux.verDatosDeEnvio.includes( profile ) && aux.editarDatosDeEnvio.includes( profile ) && !(detalle.dir_cp)){
                return true;
            }
        }else if(status === 2){
            return true;
        }else if(status === 4 || status === 5){
            if(detalle.dir_cp){
                return true;
            }
        }
    }else if(tip === 2 || tip === 9 || tip === 12 || tip === 13){
        if(status === 1){
            if(aux.verDatosDeEnvio.includes( profile ) && aux.editarDatosDeEnvio.includes( profile ) && !(detalle.dir_cp)){
                return true;
            }
        }else if(status === 2 || status === 4){
            return true;
        }else if(status === 5 && detalle.dir_cp){
            return true;
        }
    }else if(tip === 3){
        if(status === 1 || status === 2 || status === 4 || status === 5){
            return true;
        }
    }else if(tip === 10){
        if(status === 2 || status === 4){
            return true;
        }else if(status === 5 && detalle.dir_cp){
            return true;
        }
    }else if(tip === 11){
        if(status === 2 || status === 4 || status === 8 || status === 10){
            return true;
        }else if(status === 5 && detalle.dir_cp){
            return true;
        }
    }

    return false;
};

const isETabReembolso = ( tip, profile, status, detalle ) => {
    let aux = profilesAclaraciones;
    if(tip === 1){
        if(status === 1 || status === 4){
            if(aux.verReembolso.includes( profile ) && aux.editarReembolso.includes( profile ) && (!detalle.if_reembolso || !detalle.if_storecredit)){
                return true;
            }
        }
    }else if(tip === 2 || tip === 3 || tip === 9 || tip === 10 || tip === 11 || tip === 12 || tip === 13){
        if(status === 3){
            if(aux.verReembolso.includes( profile ) && aux.editarReembolso.includes( profile ) && (!detalle.if_reembolso || !detalle.if_storecredit)){
                return true;
            }
        }else if(tip === 11 && status === 11){
            if(aux.verReembolso.includes( profile ) && aux.editarReembolso.includes( profile ) && (!detalle.if_reembolso || !detalle.if_storecredit)){
                return true;
            }
        }
    }else if(tip === 5 || tip === 6 || tip === 7 || tip === 8){
        if(status === 1 || status === 4){
            if(aux.verReembolso.includes( profile ) && aux.editarReembolso.includes( profile ) && (!detalle.if_reembolso || !detalle.if_storecredit)){
                return true;
            }
        }
    }

    return false;
};

const isETabGuias = ( tip, profile, status, detalle ) => {
    let aux = profilesAclaraciones;
    if(tip === 1 || tip === 2 || tip === 9 || tip === 10 || tip === 12 || tip === 13){
        if(status === 2){
            if(aux.verGuias.includes( profile ) && aux.editarGuias.includes( profile ) && !(detalle.guia)){
                return true;
            }
        }
    }else if(tip === 11){
        if(status === 2 || status === 8 || status === 9 || status === 10){
            if(aux.verGuias.includes( profile ) && aux.editarGuias.includes( profile ) && !(detalle.guia)){
                return true;
            }
        }
    }else if(tip === 3){
        if(status === 2 || status === 5){
            return true;
        }
    }

    return false;
};

const isETabRefacturacion = ( tip, profile, status, detalle ) => {
    let aux = profilesAclaraciones;
    if(tip === 4){
        if(status === 1 || status === 6){
            if(aux.verRefacturacion.includes( profile ) && aux.editarRefacturacion.includes( profile )){
                return true;
            }
        }
    }

    return false;
};

const isETabAclarPago = ( tip, profile, status, detalle ) => {
    let aux = profilesAclaraciones;
    if(tip === 2 || tip === 9 || tip === 12 || tip === 13){
        if(status === 1 || status === 2 || status === 3 || status === 5){
            return true;
        }
    }else if(tip === 6 || tip === 8){
        if(status === 1){
            if(aux.verAclarPago.includes( profile ) && aux.editarAclarPago.includes( profile )){
                return true;
            }
        }else if(status === 4 || status === 5){
            return true;
        }
    }

    return false;
};

const isETabClavesCuotas = ( tip, profile, status, detalle ) => {
    let aux = profilesAclaraciones;
    if(tip === 10){
        if(status === 1 || status === 2 || status === 3 || status === 4 || status === 5 || status === 10){
            return true;
        }
    }else if(tip === 11){
        if(status === 1 || status === 10){
            if(aux.editarClave.includes( profile )){
                return true;
            }
        }else if(status === 3 || status === 8){
            return true;
        }
    }

    return false;
};

const isETabCancelClaves = ( tip, profile, status, detalle ) => {
    let aux = profilesAclaraciones;
    if(tip === 11){
        if(status === 1 || status === 2 || status === 4 || status === 5 || status === 8 || status === 9 || status === 10 || status === 11){
            return true;
        }
    }

    return false;
};

export{
    profilesAclaraciones, isETabDatosEnvio, isETabReembolso, isETabGuias, 
    isETabRefacturacion, isETabAclarPago, isETabClavesCuotas, isETabCancelClaves, 
};