import axios from "axios";

const getMensajes = async (idAcl) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/clarifications/clarifications/details/tab/messages?idAcl=${idAcl}&idCont=12`;

  try {
    const articulos = await axios(URL);

    return articulos.data?.success ? articulos.data.data : [];
  } catch (error) {
    console.error(error);
  }
};

const saveNewMessage = async (msj, idAcl, increment_id, idUser) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/clarifications/messages/save`;
  try {
    const saved = await axios.post(URL, { msj, idAcl, increment_id, idUser });
  } catch (error) {
    console.error(error);
  }
};

export { getMensajes, saveNewMessage };
