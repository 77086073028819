import { Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";

const styles = {
  card: {
    display: "flex",
    flexDirection: "row",
    margin: "5px",
    borderRadius: "4px",
    overflow: "hidden",
    borderTop: "1px solid #f3f3f3"
  },
  icon: {
    color: "white",
    fontSize: "3.5rem",
  },
  iconContainer: (color) => ({
    backgroundColor: color,
    minWidth: "90px",
    minHeight: "90px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }),
  content: {
    flex: "1 0 auto",
    padding: "16px",
  },
};

function formatNumber(num) {
  return num.toLocaleString("en-US");
}

const CardsInfo = ({ title, total, color, IconComponent, type, sumStock }) => {
  return (
    <Card style={styles.card}>
      <Box style={styles.iconContainer(color)}>
        <IconComponent fontSize="large" style={styles.icon} />
      </Box>
      <CardContent style={styles.content}>
        <Typography
          variant="subtitle1"
          component="h2"
          style={{ textTransform: "uppercase" }}
        >
          {title}
        </Typography>
        <Typography variant="h6" component="p" sx={{ lineHeight: 1 }}>
          {type ? `$${formatNumber(total)}` : total}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CardsInfo;
