import { Box, LinearProgress, Typography, TextField, List, ListItem, ListItemText, Paper, ListItemAvatar, Avatar, AppBar, Toolbar } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAuth } from "../../../../context/AuthContext";
import { useEffect, useState, Fragment } from "react";
import { deepOrange, deepPurple } from "@mui/material/colors";

import { profiles } from "../../../../utils/vales/profilesVales";
import { getMessages, saveMessage } from "../../../../utils/vales/temporadas/temporadas";

import local from "dayjs/plugin/localizedFormat";
import relative from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";


dayjs.locale("es-mx");
dayjs.extend(local);
dayjs.extend(relative);
dayjs.extend(utc);

const MessagesTab = ({ id_beneficiary, id_season, id_season_children}) => {
    const { user } = useAuth();
    const auth = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [detalle, setDetalle] = useState(null);
    const [mensaje, setMensaje] = useState("");

    const nombreCompleto = (nombre, apellidoP, apellidoM) => {
        const usuario = [];
        if (nombre) {
            usuario.push(nombre + " ");
        }
        if (apellidoP) {
            usuario.push(apellidoP + " ");
        }
        if (apellidoM) {
            usuario.push(apellidoM);
        }
        return (
            usuario
        );
    };

    const getmessages = async () => {
        try {
            setIsLoading(true);
            console.log(id_season);
            console.log(id_beneficiary);
            const res = await getMessages ( id_season, id_beneficiary );
            setDetalle(res);
            console.log(res);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };
    
    const handleSaveMessage = async () => {
        try {
            setIsLoading(true);
            console.log(auth.user.data.awsid);
            console.log(mensaje);
            console.log(id_season);
            console.log(user.role.idProfile);
            console.log(id_season_children);
            await saveMessage(auth.user.data.awsid, mensaje, id_season, user.role.idProfile,id_season_children);
            getmessages();
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
            handleClose();
        }
    };

    const handleClose = () => {
        setMensaje("");
    };

    useEffect(() => {
        getmessages();
    }, [id_beneficiary, id_season, id_season_children]);

    return (<>
        <Box sx={{ mb: 2, ml: 2, mr: 2 }}>
            {isLoading && <LinearProgress />}
            <Fragment>
                <Paper square>
                    {!detalle && (
                        <List sx={{ width: '100%', bgcolor: 'background.paper', overflow: 'auto', maxHeight: 'auto', minHeight: 'auto' }}>
                            <Fragment>
                                <ListItem button>
                                    <ListItemText primary="Aún no hay mensajes" />
                                </ListItem>
                            </Fragment>
                        </List>
                    )}

                    {detalle && (
                        <List sx={{ width: '100%', bgcolor: 'background.paper', overflow: 'auto', maxHeight: 'auto', minHeight: 'auto' }}>
                            {detalle.data.map(({ id_mensaje, comentario, fecha_reg, nombre, apellidoP, apellidoM, foto}) => (
                                <Fragment key={id_mensaje}>
                                    {auth.user.data.displayName == nombreCompleto(nombre, apellidoP, apellidoM) && (
                                        <ListItem button>
                                            <ListItemText align="right" primary={nombreCompleto(nombre, apellidoP, apellidoM)} secondary={
                                                <Fragment>
                                                    <Typography>{comentario}</Typography>
                                                    <Typography variant="caption">
                                                        {dayjs.utc(fecha_reg).format("DD MMM YYYY, hh:mm A")} - {dayjs.utc(fecha_reg).from(dayjs().utc(true))}
                                                    </Typography>
                                                </Fragment>
                                            } />
                                            <ListItemAvatar sx={{ ml: 2, mr: 0 }}>
                                                {foto && (<Avatar alt="Profile Picture"><img src={foto} style={{ width: '100%' }} /></Avatar>)}
                                                {!foto && (<Avatar alt="Profile Picture" sx={{ bgcolor: deepOrange[500] }} ></Avatar>)}
                                            </ListItemAvatar>
                                        </ListItem>
                                    )}

                                    {auth.user.data.displayName != nombreCompleto(nombre, apellidoP, apellidoM) && (
                                        <ListItem button>
                                            <ListItemAvatar>
                                                {foto && (<Avatar alt="Profile Picture"><img src={foto} style={{ width: '100%' }} /></Avatar>)}
                                                {!foto && (<Avatar alt="Profile Picture" sx={{ bgcolor: deepPurple[500] }} ></Avatar>)}
                                            </ListItemAvatar>
                                            <ListItemText primary={nombreCompleto(nombre, apellidoP, apellidoM)} secondary={
                                                <Fragment>
                                                    <Typography>{comentario}</Typography>
                                                    <Typography variant="caption">
                                                        {dayjs.utc(fecha_reg).format("DD MMM YYYY, hh:mm A")} - {dayjs.utc(fecha_reg).from(dayjs().utc(true))}
                                                    </Typography>
                                                </Fragment>
                                            } />
                                        </ListItem>
                                    )}
                                </Fragment>
                            ))}
                        </List>
                    )}
                </Paper>
                <AppBar position="sticky" color="transparent" sx={{ top: 'auto' }}>
                    <Toolbar>
                        <TextField
                            id="outlined-textarea"
                            label="Escribir mensaje..."
                            size="small"
                            value={mensaje}
                            fullWidth
                            onChange={(e) => setMensaje(e.target.value)}
                        />
                        <LoadingButton loading={isLoading} variant="contained" color="success" size="medium" sx={{ ml: 1 }}
                        onClick={handleSaveMessage} disabled={!mensaje}
                        >Enviar</LoadingButton>
                    </Toolbar>
                </AppBar>
            </Fragment>
        </Box>
    </>);
};

export default MessagesTab;