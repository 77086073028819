const profiles = {
    temporadas: [1,],
    coordinadores: [1,],
    configuracion: [1,],
    beneficiarios: [1,],
};

const belongsVales = ( profile ) => {
    let aux = profiles;
    return  aux.temporadas.includes( profile ) ? true : aux.coordinadores.includes( profile )
            ? true : aux.configuracion.includes( profile ) ? true : aux.beneficiarios.includes( profile );
}

export{
    profiles, belongsVales
};