import { Box, Typography, Grid, List, ListItem, ListItemText, LinearProgress, Chip, Card, CardContent } from "@mui/material";
import DateInTable from "../../general/DateInTable";

const IncidenciasDSDetail = ({ incId, detalle }) => {

    function isInProOrClo(){
        if(detalle.data.id_status !== 1)
            return true;
        else
            return false;
    }

    return (
        <>
            
            <Card sx={{ m:2 }}>
                <CardContent>
                    <Grid container>
                        {detalle && (
                        <Grid item md={12} sx={{ ml:2, mr:2, mt:2}}>
                            <Typography>{(detalle.data.dir_nombre ? detalle.data.dir_nombre + ' / ' : '')} Guía: <a href={"https://www.estafeta.com/Tracking/searchByGet/?wayBillType=1&wayBill="+detalle.data.name_guia} target="_blank">{detalle.data.name_guia}</a></Typography>
                        </Grid>)}

                        {detalle && (
                        <Grid item md={4}>
                            <List>
                                <ListItem>
                                    <ListItemText primary="Número de pedido" secondary={detalle.order[0].id_increment}/>                                        
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Fecha de creación" secondary={<DateInTable dateString={detalle.order[0].created_at} />}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Fecha de pago" secondary={<DateInTable dateString={detalle.order[0].payment_date} />}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Nombre de la escuela" secondary={detalle.order[0].school}/>
                                </ListItem>
                            </List>
                        </Grid>)}

                        {detalle && (
                        <Grid item md={4}>
                            <List>
                                <ListItem>
                                    <ListItemText primary="Nombre de cliente" secondary={detalle.order[0].customer_name + " " + detalle.order[0].customer_lastname + " " + detalle.order[0].customer_mothersname}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Teléfono" secondary={detalle.order[0].telephone}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Email" secondary={detalle.order[0].email}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Dirección de envio" secondary={detalle.order[0].shipping_street+", "+detalle.order[0].shipping_city+", "+detalle.order[0].shipping_suburb+", "+detalle.order[0].shipping_delegation}/>
                                </ListItem>
                            </List>
                        </Grid>)}

                        {detalle && (
                        <Grid item md={4}>
                            <List>
                                <ListItem>
                                    <ListItemText primary="Carrier" secondary={detalle.order[0].nombre_carriers}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Tipo de servicio" secondary={detalle.order[0].nombre_servicio}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Método de pago" secondary={detalle.order[0].payment_date}/>
                                </ListItem>
                            </List>
                        </Grid>)}
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
};

export default IncidenciasDSDetail;