import axios from "axios";
import { getAclaracionById } from "./aclaraciones";

const getGuias = async (aclaracionId, idUser) => {
  try {
    const acl = await getAclaracionById(aclaracionId, idUser);
    const increment_id = acl.id_orden;
    const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/clarifications/clarifications/details/tab/labels?increment_id=${increment_id}`;
    const guias = await axios(URL);

    return guias.data?.success ? guias.data.data : [];
  } catch (error) {
    console.error(error);
  }
};

export { getGuias };
