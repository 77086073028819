import axios from "axios";

/**
 * GET
**/

const getTemporadasIndex = async (currentPage) => {
  const URL = `${process.env.REACT_APP_BASEURL}/vales/temporadas/all/new?page=${currentPage}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getTemporadasChildrenIndex = async (currentPage, id_season) => {
  const URL = `${process.env.REACT_APP_BASEURL}/vales/temporadas/children/all/new?page=${currentPage}&id_season=${id_season}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const searchTemporadas = async (currentPage, search) => {
  const URL = `${process.env.REACT_APP_BASEURL}/vales/temporadas/all/search?page=${currentPage}&search=${search}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const searchTemporadasChildren = async (currentPage, id_season, search) => {
  const URL = `${process.env.REACT_APP_BASEURL}/vales/temporadas/children/all/search?page=${currentPage}&id_season=${id_season}&search=${search}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getSeasonMoves = async ( beneficiaryId, seasonId ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/vales/temporadas/children/moves?beneficiaryId=${beneficiaryId}&seasonId=${seasonId}`;
    try {
        const res = await axios(URL);
        return res.data?.success ? res.data : [];
    } catch (error) {
        console.error("getSeasonMoves", error);
    }
};

const getBeneficiaries = async ( id_profile, user_id ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/vales/temporadas/children/beneficiaries?id_profile=${id_profile}&user_id=${user_id}`;
    try {
        const res = await axios(URL);
        return res.data?.success ? res.data : [];
    } catch (error) {
        console.error("getBeneficiaries", error);
    }
};

const getStatus = async ( id_profile, id_season_children ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/vales/temporadas/children/status?id_profile=${id_profile}&id_season_children=${id_season_children }`;
    try {
        const res = await axios(URL);
        return res.data?.success ? res.data : [];
    } catch (error) {
        console.error("getStatus", error);
    }
};

const getMessages = async ( id_season, id_beneficiary ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/vales/temporadas/children/messages?id_season=${id_season}&id_beneficiary=${id_beneficiary}`;
    try {
        const res = await axios(URL);
        return res.data?.success ? res.data : [];
    } catch (error) {
        console.error("getMessages", error);
    }
};

const getChildrenBeneficiaries = async ( idSeason, idBenef) => {
    const URL = `${process.env.REACT_APP_BASEURL}/vales/temporadas/children/beneficiaries/all?idSeason=${idSeason}&idBenef=${idBenef}`;
    try {
        const res = await axios(URL);
        return res.data?.success ? res.data : [];
    } catch (error) {
        console.error("getChildrenBeneficiaries", error);
    }
};

/**
 * POST
**/

const deleteChildren = async ( idUser, idChildren ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/vales/temporadas/children/delete`;
    try {
        const res = await axios.post(URL, { idUser, idChildren });
        return res.data?.success ? true : false;
    } catch (error) {
        console.error("deleteChildren", error);
    }
};

const saveMessage = async ( idUser, comment, idSeason, creatUser, idChildren ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/vales/temporadas/children/messages/save`;
    try {
        const res = await axios.post(URL, { idUser, comment, idSeason, creatUser, idChildren });
        return res.data?.success ? true : false;
    } catch (error) {
        console.error("saveMessage", error);
    }
};

const updateChildren = async ( idUser, idSeasonChildren, idStatus, creatUser ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/vales/temporadas/children/update`;
    try {
        const res = await axios.post(URL, { idUser, idSeasonChildren, idStatus, creatUser  });
        return res.data?.success ? true : false;
    } catch (error) {
        console.error("updateChildren", error);
    }
};

const saveChildren = async ( idUser, idChildren, idSeason ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/vales/temporadas/children/save`;
    try {
        const res = await axios.post(URL, { idUser, idChildren, idSeason });
        return res.data?.success ? true : false;
    } catch (error) {
        console.error("saveChildren", error);
    }
};

/**
 * EXCEL EXPORT
**/

const getExcelRequest = async (id_season) => {
  const URL = `${process.env.REACT_APP_BASEURL}/vales/temporadas/excel?id_season=${id_season}`;
  try {
    let res = await axios(URL);
    console.log(res);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach(element => {
      let aux2 = {};

      aux2 = {
        "name": element.name,
        "label": element.label,
        "code": element.code,
        "conditions_aggregator": element.conditions_aggregator,
        "conditions": element.conditions,
        "actions_aggregator": element.actions_aggregator,
        "actions": element.actions,
        "usage_limit": element.usage_limit,
        "expiration_date": element.expiration_date,
        "description": element.description,
        "from_date": element.from_date,
        "to_date": element.to_date,
        "uses_per_customer": element.uses_per_customer,
        "customer_group_ids": element.customer_group_ids,
        "is_active": element.is_active,
        "is_primary": element.is_primary,
        "stop_rules_processing": element.stop_rules_processing,
        "is_advanced": element.is_advanced,
        "product_ids": element.product_ids,
        "sort_order": element.sort_order,
        "simple_action": element.simple_action,
        "discount_amount": element.discount_amount,
        "discount_qty": element.discount_qty,
        "discount_step": element.discount_step,
        "simple_free_shipping": element.simple_free_shipping,
        "apply_to_shipping": element.apply_to_shipping,
        "times_used": element.times_used,
        "is_rss": element.is_rss,
        "coupon_type": element.coupon_type,
        "use_auto_generation": element.use_auto_generation,
        "type": element.type,
        "coupon_times_used": element.coupon_times_used,
        "website_ids": element.website_ids,
        "uses_per_coupon": element.uses_per_coupon,
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

export {
  getTemporadasIndex, getExcelRequest, searchTemporadas, getTemporadasChildrenIndex, searchTemporadasChildren, getSeasonMoves,
  getBeneficiaries, getStatus, getMessages, deleteChildren, saveMessage, saveChildren, getChildrenBeneficiaries, updateChildren
};