import axios from "axios";

/**
 * GET
 */

const getOrders = async (page) => {
  const URL = `${process.env.REACT_APP_BASEURL}/proveedores/etiquetas/orders/all/new?page=${page}`;
  try {
    const res = await axios(URL);

    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const searchOrders = async (currentPage, initDate, endDate, search) => {
    let URL = ``;
    if (initDate && endDate) {
        if(search) {
            URL = `${process.env.REACT_APP_BASEURL}/proveedores/etiquetas/orders/all/search?page=${currentPage}&initDate=${initDate}&endDate=${endDate}&search=${search}`;
        } else {
            URL = `${process.env.REACT_APP_BASEURL}/proveedores/etiquetas/orders/all/new?page=${currentPage}&initDate=${initDate}&endDate=${endDate}`;
        }
    } else {
      URL = `${process.env.REACT_APP_BASEURL}/proveedores/etiquetas/orders/all/search?page=${currentPage}&search=${search}`;
    }
  try {
    const res = await axios(URL);

    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getItems = async (page, increment_id) => {
  const URL = `${process.env.REACT_APP_BASEURL}/proveedores/etiquetas/items/all/new?page=${page}&increment_id=${increment_id}`;
  try {
    const res = await axios(URL);

    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

/**
 * POST
 */

const saveOrderRelease = async (details) => {
    const URL = `${process.env.REACT_APP_BASEURL}/proveedores/asignaroc/labels/order/save`;
    try {
      const res = await axios.post(URL, details);
      return res.data?.success ? true : false;
    } catch (error) {
      console.error("saveOrderRelease", error);
    }
  };

export { getOrders, searchOrders, getItems, saveOrderRelease };
