import {
  Box,
  Grid,
  TextField,
  InputLabel,
  Select,
  FormControl,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Typography,
  Alert,
  Card,
  CardContent,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAuth } from "../../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import {
  getSCRma,
  saveStoreCredit,
} from "../../../../utils/trazabilidad/rma/rma";

const SCTab = ({ lista, rmaId, orderId, incrementId, clientName }) => {
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [articulos, setArticulos] = useState(null);
  const [detalle, setDetalle] = useState(null);
  const [cnote, setCNote] = useState(null);
  const [quanti, setQuanti] = useState(null);
  const [referenc, setReferenc] = useState(null);
  const [checked, setChecked] = useState(false);

  const handleChangeC = (event) => {
    setCNote(event.target.value);
  };

  const handleChangeQ = (event) => {
    setQuanti(event.target.value);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleClose = () => {
    setCNote(null);
    setQuanti(null);
    setChecked(false);
  };

  const handleSaveSC = async () => {
    try {
      setIsLoading(true);
      await saveStoreCredit(
        incrementId,
        rmaId,
        auth.user.data.email,
        clientName,
        auth.user.data.awsid,
        orderId,
        auth.user.data.displayName,
        quanti,
        cnote
      );
      getArticulos();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      handleClose();
    }
  };

  function getSCitems(items) {
    return items.filter((element) => element.if_storecredit_log > 0);
  }

  function getCantidadR(params) {
    if (params.row.cantidad_devuelta_log)
      return params.row.cantidad_devuelta_log;
    else return "0";
  }

  function getPTotal(arti) {
    let total = 0.0;
    arti.map((row) => (total = total + parseFloat(row.total.slice(1))));
    setQuanti(total);
    setReferenc(total);
  }

  const columns = [
    { field: "product_sku", headerName: "SKU", flex: 1 },
    { field: "product_name", headerName: "Nombre", flex: 3 },
    { field: "attributes", headerName: "Categoría", flex: 1 },
    { field: "category_name", headerName: "Grupo de Artículos", flex: 1 },
    { field: "price", headerName: "Precio", flex: 1 },
    {
      field: "cantidad_devuelta_log",
      headerName: "Cantidad Recibida",
      flex: 1,
      valueGetter: getCantidadR,
    },
    { field: "total", headerName: "Total", flex: 1 },
    {
      field: "acciones",
      headerName: "Mover a",
      flex: 1,
      renderCell: (params) => "No disponible",
    },
  ];

  const getArticulos = async () => {
    try {
      setIsLoading(true);
      const res = getSCitems(lista);
      const resp = await getSCRma(orderId, incrementId);
      setArticulos(res);
      getPTotal(res);
      setDetalle(resp);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getArticulos();
  }, []);

  return (
    <Box sx={{ mb: 2, ml: 2, mr: 2 }}>
      {isLoading && <CircularProgress />}
      {articulos && (
        <div style={{ display: "flex", height: "100%" }}>
          <div style={{ flexGrow: 1 }}>
            <DataGrid
              autoHeight
              rows={articulos}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              getRowId={(row) => row.eze3_item_id}
              disableColumnFilter
              disableColumnMenu
              disableColumnSelector
              disableDensitySelector
              disableExtendRowFullWidth
              disableSelectionOnClick
              initialState={{
                sorting: {
                  sortModel: [{ field: "product_sku", sort: "asc" }],
                },
              }}
            />
          </div>
        </div>
      )}

      {detalle &&
        detalle.numero_NotaCredito &&
        detalle.cantidadFinalReembolsada && (
          <Grid container sx={{ mt: 2, mb: 2 }} spacing={2}>
            <Grid item md={4}>
              {/* <Alert variant="filled" severity="info" elevation={0} square>
                <Typography variant="p">
                  <strong>Costo de envio</strong>
                </Typography>
              </Alert>
              <Card variant="outlined" square>
                <CardContent>
                  <Typography variant="p" component="div">Nota de crédito: <strong>{detalle.numero_NotaCredito}</strong></Typography>
                                <Typography variant="p" component="div">Cantidad del reembolso en Store Credit:</Typography>
                  <Typography variant="h4" align="center" component="div">${detalle.cantidadFinalReembolsada}</Typography>
                  <Typography variant="h4" align="center" component="div">
                    $243.99
                  </Typography>
                </CardContent>
              </Card> */}
            </Grid>
            <Grid item md={4}>
              <Alert variant="filled" severity="success" elevation={0} square>
                <Typography variant="p">
                  <strong>¡Crédito en tienda asignado!</strong>
                </Typography>
              </Alert>
              <Card variant="outlined" square>
                <CardContent>
                  <Typography variant="p" component="div">
                    Nota de crédito:{" "}
                    <strong>{detalle.numero_NotaCredito}</strong>
                  </Typography>
                  <Typography variant="p" component="div">
                    Cantidad del reembolso en Store Credit:
                  </Typography>
                  <Typography variant="h4" align="center" component="div">
                    ${detalle.cantidadFinalReembolsada}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={4}></Grid>
          </Grid>
        )}

      {detalle &&
        !detalle.numero_NotaCredito &&
        !detalle.cantidadFinalReembolsada && (
          <Grid container sx={{ mt: 2, mb: 2 }}>
            <Grid item md={3}></Grid>
            <Grid item md={6}>
              <Card>
                <CardContent>
                  <Grid container>
                    <Grid item md={3}></Grid>
                    <Grid item md={6} sx={{ mt: 2, mb: 2 }}>
                      <TextField
                        fullWidth
                        sx={{ mb: 2 }}
                        id="cnote"
                        label="Nota de crédito"
                        value={cnote}
                        onChange={handleChangeC}
                      />
                      <TextField
                        fullWidth
                        sx={{ mb: 2 }}
                        id="quanti"
                        label="Cantidad"
                        value={quanti}
                        onChange={handleChangeQ}
                        type="number"
                      />
                      <Typography
                        sx={{ mb: 2 }}
                        variant="h4"
                        align="center"
                        component="div"
                      >
                        ${quanti}
                      </Typography>
                      <LoadingButton
                        loading={isLoading}
                        fullWidth
                        size="medium"
                        variant="contained"
                        color="success"
                        onClick={handleSaveSC}
                        disabled={!cnote || !quanti || quanti < referenc}
                      >
                        Asignar crédito en tienda
                      </LoadingButton>
                    </Grid>
                    <Grid item md={3}></Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={3}></Grid>
          </Grid>
        )}
    </Box>
  );
};

export default SCTab;
