import axios from "axios";

/**
 * GET
 **/
const getSalesIndex = async (currentPage, id_season, initDate, endDate) => {
  const URL = `${process.env.REACT_APP_BASEURL}/proveedores/ventas/sales/all/new?page=${currentPage}&idUser=736&initDate=${initDate}&endDate=${endDate}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getLevel = async (idUser, initDate, endDate) => {
  const URL = `${process.env.REACT_APP_BASEURL}/proveedores/ventas/sales/level/get?idUser=736&initDate=${initDate}&endDate=${endDate}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getGrade = async (idUser, initDate, endDate, level) => {
  const URL = `${process.env.REACT_APP_BASEURL}/proveedores/ventas/sales/grade/get?idUser=736&initDate=${initDate}&endDate=${endDate}&level=${level}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getColegio = async (idUser, initDate, endDate, level, grade) => {
  const URL = `${process.env.REACT_APP_BASEURL}/proveedores/ventas/sales/colegio/get?idUser=736&initDate=${initDate}&endDate=${endDate}&level=${level}&grade=${grade}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getSalesFinal = async (
  page,
  idUser,
  initDate,
  endDate,
  level,
  grade,
  colegio
) => {
  const URL = `${process.env.REACT_APP_BASEURL}/proveedores/ventas/sales/salesfinal/get?page=${page}&idUser=736&initDate=${initDate}&endDate=${endDate}&level=${level}&grade=${grade}&colegio=${colegio}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getVentasNetas = async (idUser, initDate, endDate) => {
  const URL = `${process.env.REACT_APP_BASEURL}/proveedores/ventas/sales/ventasnetas/get?idUser=736&initDate=${initDate}&endDate=${endDate}`;
  try {
    let res = await axios(URL);
    console.log(res.data.data);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        Pedido: element.Pedido,
        Fecha: element.FechaMagento
          ? `${new Date(element.FechaMagento)
              .toLocaleString("es-mx", {
                timeZone: "UTC",
              })
              .replace(",", "")}`
          : "",
        Nivel: element.Nivel,
        "Grupo de colegios": element.GrupoColegios,
        Grado: element.Grade,
        Escuela: element.Colegio,
        Alumno: element.Alumno,
        SKU: element.Sku,
        AccessCode1: element.access1,
        AccessCode2: element.access2,
        Descripcion: element.Producto,
        Piezas: element.Cantidad,
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

export {
  getSalesIndex,
  getLevel,
  getGrade,
  getColegio,
  getSalesFinal,
  getVentasNetas,
};
