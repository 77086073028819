import { Box, Button, Card, CardContent, LinearProgress, Typography, Divider } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useAuth } from "../../../context/AuthContext";
import { profiles } from "../../../utils/altoRiesgo/profilesAltoRiesgo";
import { getHRById, getHROrderD } from "../../../utils/altoRiesgo/alto/alto";

import AltoDSDetail from "./AltoDSDetail";
import IndexTabs from "./tabs/IndexTabs";
import BreadCrumb from "../../general/BreadCrumb";
import GppMaybeIcon from '@mui/icons-material/GppMaybe';

const itemsBreadCrumbs = [
  {
    label: 'Alto Riesgo',
    icon: <GppMaybeIcon sx={{ mr: 0.5 }} fontSize="small"/>,
    link: '/alto-riesgo/inicio'
  },
  { label: 'Alto Riesgo', link: '/alto-riesgo/alto' },
  { label: 'Detalle', }
];

const AltoDetail = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const params = useParams();
    const id = params.id;
    const [isLoading, setIsLoading] = useState(false);
    const [detalle, setDetalle] = useState(null);
    const [orderd, setOrderd] = useState(null);

    useEffect(() => {

        if(!profiles.altoRiesgo.includes(user.role.idProfile)){
            navigate("/dashboard", { replace: true });
        }
        else
        {
            const getDetallePedido = async () => {
                try {
                    setIsLoading(true);
                    const res = await getHRById(id);
                    setDetalle(res);
                    const resp = await getHROrderD(id);
                    setOrderd(resp);
                } catch (error) {
                    console.error(error);
                } finally {
                    setIsLoading(false);
                }
            };
            getDetallePedido();
        }
    }, [id]);

    return (
        <>
            <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <Typography variant="h4" sx={{ my: 2 }}>Seguimiento Alto Riesgo</Typography>
                <BreadCrumb items={itemsBreadCrumbs} />
            </Box>

            <motion.div initial={{ y: 20 }} animate={{ y: 0 }} transition={{ duration: 0.5 }}>
                <Card>
                    <Box>
                        {isLoading && <LinearProgress />}
                        <IndexTabs altoId={id} />
                        <Divider variant="middle" />
                                             
                    </Box>
                </Card>
                <Box>
                {(detalle && orderd) && (<AltoDSDetail altoId={id} detalle={detalle} orderd={orderd} />)}
                </Box>   
            </motion.div>

            <Box>&nbsp;</Box>
        </>
    );
};

export default AltoDetail;