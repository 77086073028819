import {
  Alert,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  FormControl,
  FormHelperText,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import { useState, useEffect } from "react";
import {
  getBeneficiary,
  updateBeneficiary,
} from "../../../utils/vales/beneficiarios/beneficiarios";

const TabUpdateBeneficiary = ({
  open,
  onClose,
  setLoading,
  setRows,
  setEstado,
  schools,
  updateUsr,
  beneficiaryIdUpdate,
}) => {
  const [name, setName] = useState("");
  const [apellidoP, setApellidoP] = useState("");
  const [apellidoM, setApellidoM] = useState("");
  const [mail, setMail] = useState("");
  const [selectedSchoolId, setSelectedSchoolId] = useState("");
  const [errors, setErrors] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [reloadData, setReloadData] = useState(0);

  useEffect(() => {
    const fetchBeneficiartData = async () => {
      if (open && beneficiaryIdUpdate) {
        try {
          const response = await getBeneficiary(beneficiaryIdUpdate);
          const userData = response.data[0];

          setName(userData.name || "");
          setApellidoP(userData.last_name || "");
          setApellidoM(userData.mothers_name || "");
          setMail(userData.mail || "");
          setSelectedSchoolId(userData.id_school || 0);
        } catch (error) {
          console.error("Error al obtener los datos:", error);
        }
      }
    };

    fetchBeneficiartData();
  }, [open, beneficiaryIdUpdate, reloadData]);

  const isValidEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return regex.test(email);
  };

  const handleAdd = async () => {
    let tempErrors = {};

    if (name.length <= 3)
      tempErrors.name = "El nombre debe tener más de 3 caracteres";
    if (apellidoP.length <= 3)
      tempErrors.apellidoP =
        "El apellido paterno debe tener más de 3 caracteres";
    if (apellidoM.length <= 3)
      tempErrors.apellidoM =
        "El apellido materno debe tener más de 3 caracteres";
    if (!isValidEmail(mail))
      tempErrors.mail = "Introduce un correo electrónico válido";
    if (selectedSchoolId === "")
      tempErrors.selectedSchoolId = "Selecciona la Sede del Beneficiario";

    setErrors(tempErrors);

    if (Object.keys(tempErrors).length === 0) {
      let data = {
        id_beneficiary: beneficiaryIdUpdate,
        name: name,
        last_name: apellidoP,
        mothers_name: apellidoM,
        mail,
        id_school: selectedSchoolId,
        updateUsr,
      };
      setLoadingForm(true);
      await new Promise((resolve) => setTimeout(resolve, 500));
      await updateBeneficiary(data);
      setLoadingForm(false);
      setSnackbarOpen(true);
      setName("");
      setApellidoM("");
      setApellidoP("");
      setMail("");
      setSelectedSchoolId("");
      setReloadData((prevEstado) => (prevEstado === 0 ? 1 : 0));
    }
  };

  const handleSchoolChange = (event) => {
    setSelectedSchoolId(event.target.value);
  };

  const onCloseAndClear = () => {
    onClose();
    setName("");
    setApellidoM("");
    setApellidoP("");
    setMail("");
    setSelectedSchoolId("");
    setErrors({});
    setLoading(true);
    setRows([]);
    setEstado((prevEstado) => (prevEstado === 0 ? 1 : 0));
  };

  return (
    <>
      {loadingForm ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "530px",
            height: "379px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <List>
          <ListItem>
            <TextField
              variant="outlined"
              required
              fullWidth
              label="Nombre"
              value={name}
              onChange={(e) => setName(e.target.value)}
              error={!!errors.name}
              helperText={errors.name}
              sx={{ width: "500px" }}
            />
          </ListItem>
          <ListItem>
            <TextField
              variant="outlined"
              required
              fullWidth
              label="Primer Apellido"
              value={apellidoP}
              onChange={(e) => setApellidoP(e.target.value)}
              error={!!errors.apellidoP}
              helperText={errors.apellidoP}
              sx={{ width: "500px" }}
            />
          </ListItem>
          <ListItem>
            <TextField
              variant="outlined"
              required
              fullWidth
              label="Segundo Apellido"
              value={apellidoM}
              onChange={(e) => setApellidoM(e.target.value)}
              error={!!errors.apellidoM}
              helperText={errors.apellidoM}
              sx={{ width: "500px" }}
            />
          </ListItem>
          <ListItem>
            <TextField
              variant="outlined"
              id="mail"
              name="mail"
              required
              fullWidth
              label="Mail"
              value={mail}
              onChange={(e) => setMail(e.target.value)}
              error={!!errors.mail}
              helperText={errors.mail}
              sx={{ width: "500px" }}
            />
          </ListItem>
          <ListItem>
            <FormControl
              variant="outlined"
              sx={{ width: "500px" }}
              error={!!errors.selectedSchoolId}
            >
              <InputLabel id="school-label" required>
                Sede del Beneficiario
              </InputLabel>
              <Select
                labelId="school-label"
                label="Sede del Beneficiario"
                required
                value={selectedSchoolId}
                onChange={handleSchoolChange}
              >
                {schools.map((school) => (
                  <MenuItem key={school.school_id} value={school.school_id}>
                    {school.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.selectedSchoolId}</FormHelperText>
            </FormControl>
          </ListItem>
        </List>
      )}
      <DialogActions>
        <Button onClick={onCloseAndClear} color="error" variant="outlined">
          Cerrar
        </Button>
        <Button onClick={handleAdd} color="success" variant="outlined">
          Agregar
        </Button>
      </DialogActions>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="success"
          variant="filled"
        >
          Beneficiario Actualizado Correctamente
        </Alert>
      </Snackbar>
    </>
  );
};

export default TabUpdateBeneficiary;
