// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBe5P_x4rs1Rxz7HUo1uOyr06bG3SIS_TE",
  authDomain: "klaar-gea.firebaseapp.com",
  projectId: "klaar-gea",
  storageBucket: "klaar-gea.appspot.com",
  messagingSenderId: "184248134484",
  appId: "1:184248134484:web:9f31fca0df6e23ef0b76c1"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { app, db, auth, storage };
