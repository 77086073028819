import {
  Box,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  LinearProgress,
  Button,
  styled,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Warning as WarningIcon } from "@mui/icons-material";
import DateInTable from "../../general/DateInTable";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const CotizacionDetailItem = ({ cotizacionId, detalle }) => {
  const { cotizacion, requerimiento, utilidadBruta, utilidadBrutaValidacion } =
    detalle;

  // Status:
  let nombreCategoria = cotizacion.nombre_categoria;

  let variant = "contained";
  let color = "default";
  let labelText = nombreCategoria;

  if (
    !nombreCategoria ||
    nombreCategoria === "En proceso" ||
    nombreCategoria === "En espera de oferta de compra" ||
    nombreCategoria === "En espera de orden de compra"
    // nombreCategoria === "Cotización modificada"
  ) {
    color = "warning";
    labelText = nombreCategoria || "S/Estatus";
  } else if (nombreCategoria === "Creado") {
    color = "info";
  } else if (
    nombreCategoria === "Aprobada" ||
    nombreCategoria === "Cotización validada"
    // nombreCategoria === "Marcas validadas"
  ) {
    color = "success";
  } else {
    color = "error";
  }

  // Status:
  let catNombre = requerimiento.cat_nombre;

  let variant01 = "contained";
  let color01 = "default";
  let labelText01 = nombreCategoria;

  if (
    !catNombre ||
    catNombre === "En proceso de validación" ||
    catNombre === "Modificado" ||
    catNombre === "En espera de OV"
  ) {
    color01 = "warning";
    labelText01 = catNombre || "S/Estatus";
  } else if (catNombre === "Creado") {
    color = "info";
  } else if (
    catNombre === "Terminado" ||
    catNombre === "Validado" ||
    catNombre === "Cotizado" ||
    catNombre === "En espera de OC" ||
    catNombre === "En seguimiento"
  ) {
    color01 = "success";
  } else {
    color01 = "error";
  }

  let utilidadLabel01 = 100 * (utilidadBruta / cotizacion.total);

  function formatNumber(num) {
    return Math.round(num * 100) / 100;
  }

  function get_string(val) {
    try {
      return decodeURIComponent(escape(val));
    } catch (err) {
      return val;
    }
  }

  return (
    <>
      <Grid container>
        {detalle && (
          <Grid item md={4}>
            <List>
              <ListItem>
                <ListItemText
                  primary="Número de Cotización:"
                  secondary={cotizacion.numero_cotizacion}
                  sx={{ span: { fontWeight: "500" } }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Status:"
                  secondary={
                    <Button
                      variant={variant}
                      color={color}
                      size="small"
                      sx={{
                        textTransform: "none",
                        lineHeight: 1,
                        fontSize: "0.7rem",
                        minWidth: 0,
                      }}
                    >
                      {labelText}
                    </Button>
                  }
                  sx={{ span: { fontWeight: "500" } }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Total:"
                  secondary={
                    <Button
                      variant="contained"
                      color="success"
                      size="small"
                      sx={{
                        textTransform: "none",
                        lineHeight: 1,
                        fontSize: "0.7rem",
                        minWidth: 0,
                      }}
                    >
                      ${cotizacion.total}
                    </Button>
                  }
                  sx={{ span: { fontWeight: "500" } }}
                />
              </ListItem>
              <ListItem>
                {utilidadLabel01 >= utilidadBrutaValidacion ? (
                  <ListItemText
                    primary="Utilidad:"
                    secondary={
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        sx={{
                          textTransform: "none",
                          lineHeight: 1,
                          fontSize: "0.7rem",
                          minWidth: 0,
                        }}
                      >
                        ${utilidadBruta ? formatNumber(utilidadBruta) : "0"}
                      </Button>
                    }
                    sx={{ span: { fontWeight: "500" } }}
                  />
                ) : utilidadLabel01 > 1 ? (
                  <ListItemText
                    primary={
                      <Box component="span" display="flex" alignItems="center">
                        <WarningIcon
                          fontSize="small"
                          style={{ marginRight: "4px" }}
                          color="warning"
                        />
                        Utilidad:
                      </Box>
                    }
                    secondary={
                      <BootstrapTooltip
                        title={`La utilidad es menor al ${utilidadBrutaValidacion}%!`}
                        placement="top"
                        arrow
                      >
                        <Button
                          variant="contained"
                          color="warning"
                          size="small"
                          sx={{
                            textTransform: "none",
                            lineHeight: 1,
                            fontSize: "0.7rem",
                            minWidth: 0,
                          }}
                        >
                          ${utilidadBruta}
                        </Button>
                      </BootstrapTooltip>
                    }
                    sx={{ span: { fontWeight: "500" } }}
                  />
                ) : (
                  <ListItemText
                    primary={
                      <Box component="span" display="flex" alignItems="center">
                        <WarningIcon
                          fontSize="small"
                          style={{ marginRight: "4px" }}
                          color="warning"
                        />
                        Utilidad:
                      </Box>
                    }
                    secondary={
                      <BootstrapTooltip
                        title="Perdida en el pedido!"
                        placement="top"
                        arrow
                      >
                        <Button
                          variant="contained"
                          color="error"
                          size="small"
                          sx={{
                            textTransform: "none",
                            lineHeight: 1,
                            fontSize: "0.7rem",
                            minWidth: 0,
                          }}
                        >
                          ${utilidadBruta}
                        </Button>
                      </BootstrapTooltip>
                    }
                    sx={{ span: { fontWeight: "500" } }}
                  />
                )}
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Número de requerimiento:"
                  secondary={requerimiento.numero_requerimiento}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Estatus:"
                  secondary={
                    <Button
                      variant={variant01}
                      color={color01}
                      size="small"
                      sx={{
                        textTransform: "none",
                        lineHeight: 1,
                        fontSize: "0.7rem",
                        minWidth: 0,
                      }}
                    >
                      {labelText01}
                    </Button>
                  }
                  sx={{ span: { fontWeight: "500" } }}
                />
              </ListItem>
            </List>
          </Grid>
        )}
        {detalle && (
          <Grid item md={4}>
            <List>
              <List>
                <ListItem>
                  <ListItemText
                    primary="Orden de venta:"
                    secondary={
                      cotizacion.orden_venta ? (
                        <Typography
                          component="div"
                          sx={{ h6: { fontWeight: "normal" } }}
                        >
                          <DateInTable dateString={cotizacion.orden_venta} />
                        </Typography>
                      ) : (
                        "S/Orden de venta"
                      )
                    }
                    sx={{ span: { fontWeight: "500" } }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Fecha de alta:"
                    secondary={
                      cotizacion.fecha_cotizacion ? (
                        <Typography
                          component="div"
                          sx={{ h6: { fontWeight: "normal" } }}
                        >
                          <DateInTable
                            dateString={cotizacion.fecha_cotizacion}
                          />
                        </Typography>
                      ) : (
                        "S/Fecha"
                      )
                    }
                    sx={{ span: { fontWeight: "500" } }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Fecha correo:"
                    secondary={
                      cotizacion.fecha_correo ? (
                        <Typography
                          component="div"
                          sx={{ h6: { fontWeight: "normal" } }}
                        >
                          <DateInTable dateString={cotizacion.fecha_correo} />
                        </Typography>
                      ) : (
                        "S/Fecha"
                      )
                    }
                    sx={{ span: { fontWeight: "500" } }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Fecha aprobación:"
                    secondary={
                      cotizacion.fecha_ultimo_movimiento ? (
                        <Typography
                          component="div"
                          sx={{ h6: { fontWeight: "normal" } }}
                        >
                          <DateInTable
                            dateString={cotizacion.fecha_ultimo_movimiento}
                          />
                        </Typography>
                      ) : (
                        "S/Fecha"
                      )
                    }
                    sx={{ span: { fontWeight: "500" } }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Fecha de alta:"
                    secondary={
                      requerimiento.fecha_alta ? (
                        <Typography
                          component="div"
                          sx={{ h6: { fontWeight: "normal" } }}
                        >
                          <DateInTable dateString={requerimiento.fecha_alta} />
                        </Typography>
                      ) : (
                        "S/Fecha"
                      )
                    }
                    sx={{ span: { fontWeight: "500" } }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Fecha de último movimiento:"
                    secondary={
                      requerimiento.fecha_ultimo_movimiento ? (
                        <Typography
                          component="div"
                          sx={{ h6: { fontWeight: "normal" } }}
                        >
                          <DateInTable
                            dateString={requerimiento.fecha_ultimo_movimiento}
                          />
                        </Typography>
                      ) : (
                        "S/Fecha"
                      )
                    }
                    sx={{ span: { fontWeight: "500" } }}
                  />
                </ListItem>
              </List>
            </List>
          </Grid>
        )}
        {detalle && (
          <Grid item md={4}>
            <List>
              <List>
                <ListItem>
                  <ListItemText
                    primary="Correo del responsable:"
                    secondary={
                      cotizacion.correo_responsable
                        ? cotizacion.correo_responsable
                        : "S/Correo"
                    }
                    sx={{ span: { fontWeight: "500" } }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Forma de pago:"
                    secondary={
                      cotizacion.forma_pago
                        ? get_string(cotizacion.forma_pago)
                        : "S/Forma de pago"
                    }
                    sx={{ span: { fontWeight: "500" } }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Plazo:"
                    secondary={
                      <Box component="span" display="flex" alignItems="center">
                        {cotizacion.plazo ? cotizacion.plazo : "S/Plazo"}
                        {cotizacion.forma_pago == "Crédito" &&
                          (cotizacion.plazo == "60 Días" ||
                            cotizacion.plazo == "60 días") && (
                            <WarningIcon
                              fontSize="small"
                              style={{ marginRight: "4px" }}
                              color="warning"
                            />
                          )}
                      </Box>
                    }
                    sx={{ span: { fontWeight: "500" } }}
                  />
                </ListItem>
                <ListItem sx={{ marginTop: { md: "120px" } }}>
                  <ListItemText
                    primary="Fecha en validación:"
                    secondary={
                      requerimiento.fecha_en_validacion ? (
                        <Typography
                          component="div"
                          sx={{ h6: { fontWeight: "normal" } }}
                        >
                          <DateInTable
                            dateString={requerimiento.fecha_en_validacion}
                          />
                        </Typography>
                      ) : (
                        "S/Fecha"
                      )
                    }
                    sx={{ span: { fontWeight: "500" } }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Fecha validado:"
                    secondary={
                      requerimiento.fecha_validacion ? (
                        <Typography
                          component="div"
                          sx={{ h6: { fontWeight: "normal" } }}
                        >
                          <DateInTable
                            dateString={requerimiento.fecha_validacion}
                          />
                        </Typography>
                      ) : (
                        "S/Fecha"
                      )
                    }
                    sx={{ span: { fontWeight: "500" } }}
                  />
                </ListItem>
              </List>
            </List>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default CotizacionDetailItem;
