import { Box, CircularProgress, Typography, Tabs, Tab } from "@mui/material";
import { useState } from "react";
import { useTabs } from "../../../../context/TabsContext";

import DTab from "./DTab";
import HistoryTab from "./HistoryTab";
import MessagesTab from "./MessagesTab";

import ListIcon from '@mui/icons-material/List';
import ChatIcon from '@mui/icons-material/Chat';
import HistoryIcon from '@mui/icons-material/History';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
        </div>
    );
};

const IndexTabs = ({ id_beneficiary, id_season, id_season_children}) => {
    const { crmTab, setCrmTab } = useTabs();
    const [isLoading, setIsLoading] = useState(false);

    const handleTabChange = (event, value) => {
        setCrmTab(value);
    };

    return (
        <>            
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={crmTab} onChange={handleTabChange} >
                    <Tab icon={<ListIcon />} iconPosition="start" label="Detalle" />
                    <Tab icon={<ChatIcon />} iconPosition="start" label="Mensajes" />
                    <Tab icon={<HistoryIcon />} iconPosition="start" label="Histórico" />
                </Tabs>
            </Box>
            {isLoading && (<Box sx={{ml:2, mt:2, mr:2, mb:2}}> <Typography variant="p"> Cargando ...</Typography> <CircularProgress /> </Box>)}
            <TabPanel value={crmTab} index={0}> <DTab id_beneficiary={id_beneficiary} id_season={id_season} id_season_children={id_season_children}  /> </TabPanel>
            <TabPanel value={crmTab} index={1}> <MessagesTab id_beneficiary={id_beneficiary} id_season={id_season} id_season_children={id_season_children}  /> </TabPanel >
            <TabPanel value={crmTab} index={2}> < HistoryTab id_beneficiary={id_beneficiary} id_season={id_season} /></TabPanel>
        </>
    );
};

export default IndexTabs;