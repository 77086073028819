import { profiles } from "./profilesControlAccesos";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
const dicon = <RadioButtonUncheckedIcon fontSize='small' />;

export const controlAccesosModules = [
    {
        label: "Accesos",
        icon: dicon,
        slug: "accesos",
        profiles: profiles.accesos
    },
    {
        label: "Solicitudes",
        icon: dicon,
        slug: "solicitudes",
        profiles: profiles.solicitudes
    },
    {
        label: "Administración",
        icon: dicon,
        slug: "administracion",
        profiles: profiles.administracion
    },
    {
        label: "Reportes",
        icon: dicon,
        slug: "reportes",
        profiles: profiles.reportes
    },
    {
        label: "Matrices de puestos",
        icon: dicon,
        slug: "matrices",
        profiles: profiles.matrices
    },
    {
        label: "Administración de proveedores",
        icon: dicon,
        slug: "administracion-proveedores",
        profiles: profiles.adminProv
    },
];