import { Button,Table,TableBody,TableCell,TableHead,TableRow,CircularProgress,Chip } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import DateInTable from "../../general/DateInTable";
import { getOrders } from "../../../utils/crm/buscador";

const PedidosTab = ({ pedido, telefono, correo, rma }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [pedidos, setPedidos] = useState(null);

    const loadPedidos = async () => {
        if (!pedido && !telefono && !correo && !rma) {
          return;
        }
        setIsLoading(true);
        try {
          const newPedidos = await getOrders(pedido, telefono, correo);
          setPedidos(newPedidos);
        } catch (error) {
          console.error("handleSearch", error);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        loadPedidos();
    }, []);

    return (
        <>
            {isLoading && <CircularProgress />}
            {pedidos && (
            <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Pedido</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Fecha pago</TableCell>
                    <TableCell>Telefono</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Carrier</TableCell>
                    <TableCell>Estado</TableCell>
                    <TableCell>No. Guia</TableCell>
                    <TableCell>Tipo Guia</TableCell>
                    <TableCell>Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pedidos &&
                    pedidos.map((pedido, index) => (
                      <TableRow key={index}>
                        <TableCell>{pedido.increment_id}</TableCell>
                        <TableCell>{pedido.status}</TableCell>
                        <TableCell>
                          <DateInTable dateString={pedido.payment_date} />
                        </TableCell>
                        <TableCell>{pedido.telephone}</TableCell>
                        <TableCell>{pedido.email}</TableCell>
                        <TableCell>{pedido.shipping_carrier}</TableCell>
                        <TableCell>{pedido.shipping_state}</TableCell>
                        <TableCell>
                          <Chip label={pedido.guia} variant="outlined" />
                        </TableCell>
                        <TableCell>{pedido.tipo_guia}</TableCell>
                        <TableCell>
                          <Button
                            variant="outlined"
                            size="small"
                            disableElevation
                          >
                            Detalle
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
            </Table>
            )}
        </>
    )
};

export default PedidosTab;