import { LoadingButton } from "@mui/lab";
import {
  Button,
  Chip,
  CircularProgress,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import {
  getCases,
  getInteractions,
  getOrders,
  getProcesses,
} from "../../utils/crm/buscador";
import DateInTable from "../general/DateInTable";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const TabSearcher = () => {
  const [pedido, setPedido] = useState("");
  const [telefono, setTelefono] = useState("");
  const [correo, setCorreo] = useState("");
  const [rma, setRma] = useState("");

  const [casos, setCasos] = useState(null);
  const [interacciones, setInteracciones] = useState(null);
  const [procesos, setProcesos] = useState(null);
  const [pedidos, setPedidos] = useState(null);

  const [tab, setTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = async () => {
    if (!pedido && !telefono && !correo && !rma) {
      return;
    }
    setIsLoading(true);
    try {
      const newCasos = await getCases(pedido, telefono, correo);
      const newInteracciones = await getInteractions(correo);
      const newProcesos = await getProcesses(pedido, telefono, correo, rma);
      const newPedidos = await getOrders(pedido, telefono, correo);

      setCasos(newCasos);
      setInteracciones(newInteracciones);
      setProcesos(newProcesos);
      setPedidos(newPedidos);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  return (
    <Box sx={{ p: 2 }}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "3fr 3fr 3fr 3fr 1fr",
          gap: 2,
          mb: 2,
        }}
      >
        <TextField
          value={pedido}
          onChange={(e) => setPedido(e.target.value)}
          variant="filled"
          label="Pedido"
        />
        <TextField
          value={telefono}
          onChange={(e) => setTelefono(e.target.value)}
          variant="filled"
          label="Telefono"
        />
        <TextField
          value={correo}
          onChange={(e) => setCorreo(e.target.value)}
          variant="filled"
          label="Correo"
        />
        <TextField
          value={rma}
          onChange={(e) => setRma(e.target.value)}
          variant="filled"
          label="RMA"
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <LoadingButton
            loading={isLoading}
            variant="contained"
            disableElevation
            onClick={handleSearch}
          >
            Buscar
          </LoadingButton>
        </Box>
      </Box>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            py: 8,
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <Tabs value={tab} onChange={(e, v) => setTab(v)}>
            <Tab label="Casos" />
            <Tab label="Interacciones" />
            <Tab label="Procesos" />
            <Tab label="Pedidos" />
          </Tabs>
          <TabPanel value={tab} index={0}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Caso</TableCell>
                  <TableCell>Fecha</TableCell>
                  <TableCell>Tipo</TableCell>
                  <TableCell>Tipificacion</TableCell>
                  <TableCell>Descripcion</TableCell>
                  <TableCell>Contacto</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {casos &&
                  casos.map((caso, index) => (
                    <TableRow key={index}>
                      <TableCell>{caso.id_crm_case}</TableCell>
                      <TableCell>
                        <DateInTable dateString={caso.lastmove} />
                      </TableCell>
                      <TableCell>{caso.case_type}</TableCell>
                      <TableCell>{caso.tipification}</TableCell>
                      <TableCell>{caso.description.slice(0, 100)}</TableCell>
                      <TableCell>{caso.contact}</TableCell>
                      <TableCell>{caso.name_s}</TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          size="small"
                          disableElevation
                        >
                          Detalle
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Fecha</TableCell>
                  <TableCell>Tipo</TableCell>
                  <TableCell>Descripcion</TableCell>
                  <TableCell>Canal</TableCell>
                  <TableCell>Contacto</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {interacciones &&
                  interacciones.map((interaccion, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <DateInTable dateString={interaccion.date_register} />
                      </TableCell>
                      <TableCell>{interaccion.type}</TableCell>
                      <TableCell>
                        {interaccion.description.slice(0, 100)}
                      </TableCell>
                      <TableCell>{interaccion.channel}</TableCell>
                      <TableCell>{interaccion.contact}</TableCell>
                      <TableCell>{interaccion.name_s}</TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          size="small"
                          disableElevation
                        >
                          Detalle
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TabPanel>
          <TabPanel value={tab} index={2}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Tipo</TableCell>
                  <TableCell>Pedido</TableCell>
                  <TableCell>Descripcion</TableCell>
                  <TableCell>Fecha creacion</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {procesos &&
                  procesos.map((proceso, index) => (
                    <TableRow key={index}>
                      <TableCell>{proceso.ID}</TableCell>
                      <TableCell>{proceso.Tipo}</TableCell>
                      <TableCell>
                        <Chip label={proceso.Pedido} variant="outlined" />
                      </TableCell>
                      <TableCell>{proceso.Descripcion.slice(0, 100)}</TableCell>
                      <TableCell>
                        <DateInTable dateString={proceso.FechaCreacion} />
                      </TableCell>
                      <TableCell>{proceso.Estatus}</TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          size="small"
                          disableElevation
                        >
                          Detalle
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TabPanel>
          <TabPanel value={tab} index={3}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Pedido</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Fecha pago</TableCell>
                  <TableCell>Telefono</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Carrier</TableCell>
                  <TableCell>Estado</TableCell>
                  <TableCell>No. Guia</TableCell>
                  <TableCell>Tipo Guia</TableCell>
                  <TableCell>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pedidos &&
                  pedidos.map((pedido, index) => (
                    <TableRow key={index}>
                      <TableCell>{pedido.increment_id}</TableCell>
                      <TableCell>{pedido.status}</TableCell>
                      <TableCell>
                        <DateInTable dateString={pedido.payment_date} />
                      </TableCell>
                      <TableCell>{pedido.telephone}</TableCell>
                      <TableCell>{pedido.email}</TableCell>
                      <TableCell>{pedido.shipping_carrier}</TableCell>
                      <TableCell>{pedido.shipping_state}</TableCell>
                      <TableCell>
                        <Chip label={pedido.guia} variant="outlined" />
                      </TableCell>
                      <TableCell>{pedido.tipo_guia}</TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          size="small"
                          disableElevation
                        >
                          Detalle
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TabPanel>
        </Box>
      )}
    </Box>
  );
};

export default TabSearcher;
