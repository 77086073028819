import { Box, Grid, Button, Stack, TextField, Menu, MenuItem, Typography, List, Dialog, DialogTitle, DialogContent, DialogActions, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useEffect, useState } from "react";
import { useAuth } from "../../../../context/AuthContext";
import { useNavigate } from "react-router-dom";

import local from "dayjs/plugin/localizedFormat";
import relative from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";

import { getOrderDetails } from "../../../../utils/trazabilidad/pedidos/pedidos";

import Paper from '@mui/material/Paper';

dayjs.locale("es-mx");
dayjs.extend(local);
dayjs.extend(relative);
dayjs.extend(utc);

function DetailPanelContent({ row: rowProp }) {
    return (
        <Stack sx={{ py: 2, height: '100%', boxSizing: 'border-box' }} direction="column" >
            <Paper sx={{ flex: 1, mx: 'auto', width: '90%', p: 1 }}>
                <Stack direction="column" spacing={1} sx={{ height: 1 }}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ textAlign: 'center' }}>Producto</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>Sku</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>Clave de Acceso</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{ border: 0 }} >
                                <TableCell sx={{ width: '15%', textAlign: 'center' }}>{rowProp.product_name}</TableCell>
                                <TableCell sx={{ width: '15%', textAlign: 'center' }}>{rowProp.product_sku}</TableCell>
                                <TableCell sx={{ width: '15%', textAlign: 'center' }}>{rowProp.clave}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Stack>
            </Paper>
        </Stack>
    );
}

const DetallesPedidoTab = ({ increment_id }) => {
    const { user } = useAuth();
    const navigate = useNavigate();

    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [keyWord, setKeytoSearch] = useState("");

    const [loading, setLoading] = useState(false); //Loading Index
    const [isELoading, setIsELoading] = useState(false); //Loading Excel

    const columns = [
        {
            field: "school", headerName: "Escuela", flex: 1, align: "center", headerAlign: "center",
        },
        {
            field: "grade", headerName: "Grado", flex: 1, align: "center", headerAlign: "center",
        },
        {
            field: "quantity", headerName: "Cantidad", flex: 0.8, align: "center", headerAlign: "center",
        },
        {
            field: "attributes", headerName: "Categoria", flex: 1, align: "center", headerAlign: "center",
        },
        {
            field: "grupo_articulos", headerName: "Grupo de Artículos", flex: 1, align: "center", headerAlign: "center",
        },
    ];

    if (document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"))
        document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']").remove();

    useEffect(() => {
        let active = true;
        (async () => {
            let newRows = null;
            if (typeof keyWord === 'undefined' || keyWord === undefined || keyWord.length === 0) {
                setLoading(true);
                newRows = await getOrderDetails (increment_id, page);
            }
            else if (keyWord.length >= 1) {
                setLoading(true);
                //newRows = await searchOrders (page, keyWord);
            }
            else active = false;
            if (!active) {
                return;
            }
            setTotal(newRows.total);
            setRows(newRows.data);
            setLoading(false);
        })();
        return () => {
            active = false;
        };
    }, [increment_id]);

    return (<>
        <Box sx={{mx:3}} style={{ height: 380, width: 'auto' }} component={Paper}>
            <DataGridPro
                rows={rows}
                columns={columns}
                pagination
                pageSize={10}
                rowsPerPageOptions={[10]}
                getRowId={((row) => (row.order_item_id))}
                rowCount={total}
                paginationMode="server"
                onPageChange={(newPage) => {
                    setPage(newPage);
                }}
                loading={loading}
                disableColumnMenu
                disableSelectionOnClick
                getDetailPanelHeight={() => 'auto'}
                getDetailPanelContent={({ row }) => (<DetailPanelContent row={row} />)}
            />
        </Box>
    </>);
};

export default DetallesPedidoTab;