import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../../context/AuthContext";
import { Box, Button, Card, Grid, Typography } from "@mui/material";
import BreadCrumb from "../../../general/BreadCrumb";
import { motion } from "framer-motion";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import { useState } from "react";
import { useEffect } from "react";
import { profiles } from "../../../../utils/ventaMayoreo/profilesVentaMayoreo";
import InfoOrdenCompra from "./InfoOrdenCompra";
import AsignarOrdenCompra from "./AsignarOrdenCompra";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  getOrder,
  getProducts,
} from "../../../../utils/ventaMayoreo/ordenesCompra/ordenesCompra";

const itemsBreadCrumbs = [
  {
    label: "Venta mayoreo",
    icon: <ContentPasteSearchIcon sx={{ mr: 0.5 }} fontSize="small" />,
    link: "/venta-mayoreo",
  },
  { label: "Órdenes de compra", link: "/venta-mayoreo/ordenes-compra" },
  { label: "Editar" },
];

export const DetalleOCompra = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;
  const [isLoading, setIsLoading] = useState(false);
  const [inforRCE, setInforRCE] = useState(null);
  const [estados, setEstados] = useState([]);
  const [openM, setOpenM] = useState(false);

  const [order, setOrder] = useState(null);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [keyWord, setKeytoSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [reloadPage, setReloadPage] = useState(0);

  useEffect(() => {
    if (!profiles.ordenesC.includes(auth.user.role.idProfile)) {
      navigate("/dashboard", { replace: true });
    }
  }, [id, auth.user.data.awsid]);

  const columns = [
    {
      field: "ordenVenta",
      headerName: "Orden Venta",
      flex: 0.7,
      align: "center",
      headerAlign: "center",
      valueGetter: ({ row }) => encodeUtf8(row.orden_venta),
    },
    {
      field: "producto",
      headerName: "Producto",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: ({ row }) => encodeUtf8(row.nombre),
    },
    {
      field: "sku",
      headerName: "SKU",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: ({ row }) => encodeUtf8(row.sku),
    },
    {
      field: "provedor",
      headerName: "Proveedor",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: ({ row }) =>
        row.razon_social ? encodeUtf8(row.razon_social) : "S/Proveedor",
    },
    {
      field: "entegado",
      headerName: "Entegado",
      flex: 0.8,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) =>
        row.entregado === 1 ? (
          <Button
            variant="contained"
            color="success"
            size="small"
            sx={{
              textTransform: "none",
              lineHeight: 1,
              fontSize: "0.7rem",
              minWidth: 0,
            }}
          >
            Si
          </Button>
        ) : (
          <Button
            variant="contained"
            color="error"
            size="small"
            sx={{
              textTransform: "none",
              lineHeight: 1,
              fontSize: "0.7rem",
              minWidth: 0,
            }}
          >
            No
          </Button>
        ),
    },
    {
      field: "entrada",
      headerName: "Entrada",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: ({ row }) => row.num_entrada ? encodeUtf8(row.num_entrada) : "",
    },
  ];

  if (
    document.querySelector(
      ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
    )
  )
    document
      .querySelector(
        ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
      )
      .remove();

  useEffect(() => {
    let active = true;
    setLoading(true);
    setRows([]);
    setInforRCE(null);

    (async () => {
      let newRows = null;
      const order = await getOrder(id);
      setOrder(order.data);

      if (
        typeof keyWord === "undefined" ||
        keyWord === undefined ||
        keyWord.length === 0
      ) {
        setLoading(true);
        newRows = await getProducts(page, id);
      } else if (keyWord.length >= 3) {
        setLoading(true);
      } else active = false;

      if (!active) {
        return;
      }
      setTotal(newRows.total);
      setRows(newRows.data);
      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [page, keyWord, reloadPage]);

  const handleCloseM = () => {
    setOpenM(false);
  };

  const reloadPageFn = () => {
    setReloadPage((prevState) => (prevState === 0 ? 1 : 0));
  };

  function encodeUtf8(val) {
    try {
      return decodeURIComponent(escape(val));
    } catch (err) {
      return val;
    }
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" sx={{ my: 2 }}>
          Detalle
        </Typography>
        <Box>
          <BreadCrumb items={itemsBreadCrumbs} />
        </Box>
      </Box>

      <motion.div
        initial={{ y: 20 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Card>
          <Box>
            <Box sx={{ margin: "8px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={3.5}>
                  <Box>
                    <InfoOrdenCompra order={order} />
                    {!order?.orden_compra && (
                      <AsignarOrdenCompra order={order} />
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={8.5}>
                  <Card>
                    <Box style={{ height: 635, width: "100%" }}>
                      <DataGridPro
                        rows={rows}
                        columns={columns}
                        pagination
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        // getRowHeight={() => "auto"}
                        getRowId={(row) => row.orden_venta}
                        rowCount={total}
                        paginationMode="server"
                        onPageChange={(newPage) => {
                          setPage(newPage);
                        }}
                        loading={loading}
                        disableColumnMenu
                        disableSelectionOnClick
                        getDetailPanelHeight={() => "auto"}
                      />
                    </Box>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Card>
      </motion.div>

      <Box>&nbsp;</Box>
      {/* <Dialog open={openM} onClose={handleCloseM}>
        <DialogTitle>{"Error"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            No se ha modificado ningún campo
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseM} color="error" variant="outlined">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog> */}
    </>
  );
};
