import { Box, Grid, Table, TableBody, TableCell, TableContainer, Typography , TableRow, CircularProgress, IconButton, TableHead } from '@mui/material';
import { useEffect, useState, Fragment } from "react";
import DateInTable from '../../../general/DateInTable';

import { getHRHistoric } from '../../../../utils/altoRiesgo/alto/alto';

const HistorialTab = ({ altoId }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [detalle, setDetalle] = useState(null);

    function get_string(val){
        try{
            return decodeURIComponent(escape(val));
        }catch(err){
            return val;
        }        
    }

    useEffect(() => {
        const getHisticHR = async () => {
            try {
                setIsLoading(true);
                const res = await getHRHistoric(altoId);
                setDetalle(res);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };
        getHisticHR();
    }, [altoId]);

    return (
        <>            
            <Box sx={{mb: 2, ml: 2, mr: 2}}>
                {isLoading && <CircularProgress />}
                <Grid container sx={{mb: 2}}>
                    {detalle && (
                    <Grid item md={12}>
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }} size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><Typography variant="p" sx={{fontWeight: 700}}>Fecha</Typography></TableCell>
                                        <TableCell><Typography variant="p" sx={{fontWeight: 700}}>Usuario</Typography></TableCell>
                                        <TableCell><Typography variant="p" sx={{fontWeight: 700}}>Mensaje</Typography></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {detalle.map((row) => (
                                    <TableRow key={row.date_created}>
                                        <TableCell><DateInTable dateString={row.date_created} /></TableCell>
                                        <TableCell>
                                            <Typography>{row.nombre + " " + row.apellidoP}</Typography>
                                        </TableCell>
                                        <TableCell><Typography>{get_string(row.message)}</Typography></TableCell>
                                    </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    )}
                </Grid>
            </Box>
        </>
    );
};

export default HistorialTab;