import { addDoc, getDocs, query, Timestamp, where } from "firebase/firestore";
import { emailsCollection } from "./collections";
import { uploadFileAndGetUrl } from "./storage";

const saveEmailInstance = async ({
  asunto,
  message,
  files,
  emailto,
  caseid,
  emailfrom,
}) => {
  try {
    const savedFiles = [];
    for (let file of files) {
      const url = await uploadFileAndGetUrl(file, "emails");
      savedFiles.push(url);
    }

    const emailInstance = {
      subject: asunto,
      message,
      attachments: savedFiles,
      to: emailto,
      user: emailfrom,
      caseid,
      date: Timestamp.fromDate(new Date()),
    };

    await addDoc(emailsCollection, emailInstance);
  } catch (error) {
    console.error(error);
  }
};

const getEmailsByCaseId = async (caseid) => {
  try {
    const q = query(emailsCollection, where("caseid", "==", +caseid));
    const docs = [];

    const snap = await getDocs(q);
    snap.forEach((d) => {
      docs.push(d.data());
    });

    return docs;
  } catch (error) {
    console.error(error);
  }

  return [];
};

export { saveEmailInstance, getEmailsByCaseId };
