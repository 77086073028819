import {
  Box,
  Card,
  LinearProgress,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";
import GoogleButton from "react-google-button";
// import LoginExt from "./LoginExt";

const Login = () => {
  const { user, login } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const theme = useTheme();

  const handleLogin = async () => {
    try {
      setIsLoading(true);
      await login();
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleChangeTab = (e, tab) => {
    setCurrentTab(tab);
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  };

  useEffect(() => {
    const findLastPath = () => {
      setIsLoading(true);
      const lastPath = localStorage.getItem("lastPath");
      console.log("last path", lastPath);
      if (lastPath) {
        return lastPath;
      }
      return "/login";
    };
    if (user) {
      navigate(findLastPath(), { replace: true });
    }
  }, [user, navigate]);

  return (
    <div
      style={{ backgroundColor: "#041C32", height: "100vh", display: "block" }}
    >
      <Box sx={{ backgroundColor: "#fff", py: 12, height: "700px" }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography
            variant="h3"
            sx={{ mb: 4 }}
            color={theme.palette.primary.main}
          >
            Klaar
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Card
            sx={{
              width: "360px",
            }}
          >
            {isLoading && <LinearProgress />}
            <Box>
              <Tabs value={currentTab} onChange={handleChangeTab} centered>
                <Tab label="Internos" />
                <Tab label="Externos" />
              </Tabs>
            </Box>

            <TabPanel value={currentTab} index={0}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <GoogleButton onClick={handleLogin} />
              </Box>
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
              {/* <LoginExt /> */}
            </TabPanel>
          </Card>
        </Box>
      </Box>
    </div>
  );
};

export default Login;
