import {
  Box,
  Card,
  CardContent,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/es";
import local from "dayjs/plugin/localizedFormat";
import relative from "dayjs/plugin/relativeTime";
import { useEffect, useState } from "react";

dayjs.locale("es");
dayjs.extend(local);
dayjs.extend(relative);

const TicketCerradoById = () => {
  const params = useParams();
  const id = params.id;
  const [details, setDetails] = useState(null);

  useEffect(() => {
    let isEffectCancelled = false;
    const getData = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_BASEURL}/crm/tickets/details?id=${id}`
        );
        const data = await res.json();
        if (!isEffectCancelled) {
          setDetails(data[0]);
        }
      } catch (error) {
        console.error("getData", error);
      }
    };

    getData();

    return () => {
      isEffectCancelled = true;
    };
  }, [id]);

  return (
    <>
      <Card sx={{ mb: 2 }}>
        {!details && <LinearProgress />}
        <CardContent>
          <Typography variant="h4" gutterBottom>
            Ticket #{id}
          </Typography>
          {details && (
            <Box sx={{ display: "flex", mt: 4 }}>
              <Box sx={{ flex: 1 }}>
                <Typography variant="h6">Titulo</Typography>
                <Typography gutterBottom>{details.title}</Typography>
                <Typography variant="h6">Canal</Typography>
                <Typography gutterBottom>{details.channel}</Typography>
                <Typography variant="h6">Fecha</Typography>
                <Typography gutterBottom>
                  {dayjs(details.date_register).format("DD MMM YYYY, hh:mm A")}{" "}
                  ({dayjs(details.date_register).fromNow()})
                </Typography>
                <Typography variant="h6">Estatus</Typography>
                <Typography gutterBottom>{details.estatus}</Typography>
                <Typography variant="h6">Contacto</Typography>
                <Typography gutterBottom>{details.contact}</Typography>
                <Typography variant="h6">Tomado por</Typography>
                <Typography
                  gutterBottom
                >{`${details.nombre} ${details.apellidoP}`}</Typography>
              </Box>
              <Box sx={{ flex: 1 }}>
                <Typography variant="h6">Mensaje</Typography>
                <TextField
                  multiline
                  variant="filled"
                  rows={14}
                  sx={{
                    width: "100%",
                  }}
                  value={details.description}
                  fullWidth
                />
              </Box>
            </Box>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default TicketCerradoById;
