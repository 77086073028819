import { Box, Grid, Button, Stack, TextField,  Menu, MenuItem} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useEffect, useState } from "react";
import { useAuth } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { getExcelRequest, getTemporadasIndex, searchTemporadas } from "../../../utils/vales/temporadas/temporadas";
import ExcelFile from "../../general/ExcelFile";
import VisibilityIcon from '@mui/icons-material/Visibility';

const TemporadasDataTable = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [keyWord, setKeytoSearch] = useState("");
    const [excelData, setExcelData] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(null);
    const [loading, setLoading] = useState(false); //Loading Index
    const [isELoading, setIsELoading] = useState(false); //Loading Excel
    
    const handleClick = (event, id) => {
        setAnchorEl(event.currentTarget);
        setOpen(id);
        setExcelData(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(null)
    };

    const getAllExcelData = async (id_season) => {
        try {
            setIsELoading(true);
            const data = await getExcelRequest(id_season);
            setExcelData(data);
        } catch (error) {
            console.error(error);
        } finally {
            setIsELoading(false);
        }
    };

    const columns = [
        {
            field: "name", headerName: "Temporada", flex: 2, align: "center", headerAlign: "center"
        },
        {
            field: "beneficiarios", headerName: "Beneficiarios", flex: 1, align: "center", headerAlign: "center",
            renderCell: (params) => (<>
                {params.row.beneficiarys_ok + "/" + params.row.beneficiarys_total}
            </>)
        },
        {
            field: "alumnos", headerName: "Alumnos", flex: 1, align: "center", headerAlign: "center",
            renderCell: (params) => (<>
                {params.row.childrens_ok + "/" + params.row.childrens_total}
            </>)
        },
        {
            field: "errors", headerName: "Errores", flex: 1, align: "center", headerAlign: "center"
        },
        {
            field: "mensajes", headerName: "Mensajes", flex: 1, align: "center", headerAlign: "center"
        },
        {
            field: "name_status", headerName: "Status", flex: 1, align: "center", headerAlign: "center"
        },
        {
            field: "accion", headerName: "Acción", flex: 1, align: "center", headerAlign: "center",
            renderCell: (params) => (<>
                <Button variant="contained" size="small" endIcon={<KeyboardArrowDownIcon />} disableElevation aria-controls={open ? this : undefined} aria-haspopup="menu" onClick={(event) => handleClick(event, params.row.id_season)}>Acción</Button>
                <Menu anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} transformOrigin={{ vertical: 'top', horizontal: 'right', }} anchorEl={anchorEl} open={open === params.row.id_season ? true : false} onClose={handleClose}>
                    <MenuItem sx={{ justifyContent: "space-evenly" }} onClick={() => navigate(`detail/${params.row.id_season}`)}><VisibilityIcon/>Ver detalle</MenuItem>
                    <MenuItem>
                        {!excelData && <LoadingButton loading={isELoading} variant="contained" color="info" onClick={() => getAllExcelData(params.row.id_season)}>Generar excel</LoadingButton>}
                        {(excelData && <ExcelFile excelData={excelData} fileName={`reporte_vales`} />)}
                    </MenuItem>
                </Menu>
                
        </>)},
    ];

    if (document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"))
        document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']").remove();

    useEffect(() => {
        let active = true;
        (async () => {
            let newRows = null;
            if (typeof keyWord === 'undefined' || keyWord === undefined || keyWord.length === 0) {
                setLoading(true);
                newRows = await getTemporadasIndex(page);
            }
            else if (keyWord.length >= 1) {
                setLoading(true);
                newRows = await searchTemporadas (page, keyWord);
            }
            else active = false;
            if (!active) {
                return;
            }
            setTotal(newRows.total);
            setRows(newRows.data);
            setLoading(false);
        })();
        return () => {
            active = false;
        };
    }, [page, keyWord, user]);

    return (<>
        <Box sx={{ display: "flex", alignItems: "center" }}>
            <Grid container sx={{ my: 2 }}>
                <Grid item md={4} sx={{ px: 2 }}>
                    <Stack direction="row" spacing={1}>
                        <TextField size="small" variant="outlined" fullWidth label="Buscar temporada..." value={keyWord} onChange={(e) => setKeytoSearch(e.target.value)} />
                    </Stack>
                </Grid>
            </Grid>
        </Box>
        <Box style={{ height: 635, width: '100%' }}>
            <DataGridPro
                rows={rows}
                columns={columns}
                pagination
                pageSize={10}
                rowsPerPageOptions={[10]}
                getRowId={((row) => (row.id_season))}
                rowCount={total}
                paginationMode="server"
                onPageChange={(newPage) => {
                    setPage(newPage);
                }}
                loading={loading}
                disableColumnMenu
                disableSelectionOnClick
            />
        </Box>
    </>);
}

export default TemporadasDataTable;