import React from "react";
import { Box, Card, CardContent, Skeleton, Typography } from "@mui/material";
import { useState } from "react";

const COLORS = ["#8ea3f3", "#d6f3dd", "#fff6"];

function Legend({ items }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      {items.map((item, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 2,
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              minWidth: "240px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: 25,
                height: 12,
                backgroundColor: item.color,
                marginRight: 8,
              }}
            ></div>
            <Typography variant="caption">{item.text}</Typography>
          </Box>
        </div>
      ))}
    </div>
  );
}

function FooterSegment({
  cx,
  cy,
  radius,
  startAngle,
  endAngle,
  color,
  onMouseOver,
  onMouseOut,
}) {
  const start = polarToCartesian(cx, cy, radius, endAngle);
  const end = polarToCartesian(cx, cy, radius, startAngle);
  const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

  const d = [
    `M ${start.x} ${start.y}`,
    `A ${radius} ${radius} 0 ${largeArcFlag} 0 ${end.x} ${end.y}`,
    `L ${cx} ${cy}`,
    "Z",
  ].join(" ");

  return (
    <path
      d={d}
      fill={color}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    />
  );
}

function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
}

function GraphInfo({ sumStock }) {
  const [hoveredSegment, setHoveredSegment] = useState(null);
  const TotalInventariableY = sumStock?.TotalInventariableY || 0;
  const TotalInventariableN = sumStock?.TotalInventariableN || 0;

  let data = [];
  if (!TotalInventariableY && !TotalInventariableN) {
    data = [
      {
        name: `${TotalInventariableY} articulos inventariables`,
        value: TotalInventariableY ? TotalInventariableY : 1,
      },
      {
        name: `${TotalInventariableN} articulos no inventariables`,
        value: TotalInventariableN ? TotalInventariableN : 1,
      },
      { name: "", value: 100 },
    ];
  } else {
    data = [
        {
          name: `${TotalInventariableY} articulos inventariables`,
          value: TotalInventariableY ? TotalInventariableY : 1,
        },
        {
          name: `${TotalInventariableN} articulos no inventariables`,
          value: TotalInventariableN ? TotalInventariableN : 1,
        },
      ];
  }

  function createPie(data) {
    const total = data.reduce((sum, item) => sum + item.value, 0);
    let startAngle = 0;
    let endAngle = 0;

    return data.map((item) => {
      startAngle = endAngle;
      endAngle = startAngle + (item.value / total) * 360;
      return { ...item, startAngle, endAngle };
    });
  }

  const pieData = createPie(data);

  const CHART_RADIUS = 40;

  return (
    <Card sx={{width: "270px", marginTop: "5px" }}>
      <CardContent>
        <Typography
          variant="subtitle2"
          gutterBottom
          sx={{ textAlign: "center" }}
        >
          Cantidad de Piezas
        </Typography>
        <Legend
          items={[
            {
              text: `${TotalInventariableY} articulos inventariables`,
              color: COLORS[0],
            },
            {
              text: `${TotalInventariableN} articulos no inventariables`,
              color: COLORS[1],
            },
          ]}
        />
        <svg width="180" height="90">
          {pieData.map((segment, index) => (
            <FooterSegment
              key={index}
              cx={125}
              cy={45}
              radius={
                hoveredSegment === segment.name
                  ? CHART_RADIUS + 5
                  : CHART_RADIUS
              }
              startAngle={segment.startAngle}
              endAngle={segment.endAngle}
              color={COLORS[index % COLORS.length]}
              onMouseOver={() => setHoveredSegment(segment.name)}
              onMouseOut={() => setHoveredSegment(null)}
            />
          ))}
        </svg>
        {hoveredSegment ? (
          <Typography
            variant="subtitle1"
            style={{ textAlign: "center", fontSize: "13px" }}
          >
            {hoveredSegment}
          </Typography>
        ) : (
          <Skeleton nimation="wave" sx={{ height: "23px" }} />
        )}
      </CardContent>
    </Card>
  );
}

export default GraphInfo;
