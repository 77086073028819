import axios from "axios";

/**
 * GET
**/

const getBackOrders = async ( tb, currentPage ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/backorders/${tb}/all/new?page=${currentPage}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const searchBackOrders = async ( tb, currentPage, search ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/backorders/${tb}/all/search?page=${currentPage}&search=${search}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getBackOrderDetail = async ( idBO ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/backorders/backorders/details?idBO=${idBO}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getBackOrderItems = async ( idBO ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/backorders/backorders/items?idBO=${idBO}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getBackOrderAddress = async ( idBO, increment_id ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/backorders/backorders/address?idBO=${idBO}&increment_id=${increment_id}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getBackOrderMessages = async ( idBO ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/backorders/backorders/msgs?idBO=${idBO}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getBackOrderEvidences = async ( idBO ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/backorders/backorders/files?idBO=${idBO}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getBackOrderRefund = async ( idBO ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/backorders/backorders/refund?idBO=${idBO}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data.data : [];
  } catch (error) {
    console.error(error);
  }
};

const checkStock = async ( idBO ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/backorders/backorders/stock/check?idBO=${idBO}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data.data : [];
  } catch (error) {
    console.error(error);
  }
};

/**
 * POST
**/

const sendMassiveMessages = async ( comentario, idProf, idUser, idsItems ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/backorders/backorders/massmsg/send`;
  try {
    const res = await axios.post(URL, { comentario, idProf, idUser, idsItems });
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("sendMassiveMessages", error);
  }
};

const sendMessage = async ( comentario, idUser, idBO, increment_id ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/backorders/backorders/msg/send`;
  try {
    const res = await axios.post(URL, { comentario, idUser, idBO, increment_id });
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("sendMessage", error);
  }
};

const updateResolution = async ( idBO, idUser, resolution, increment_id, idCustomer, bNumAcc, bName, bOwnName, bBranch, bAccTy, reqAmo, rows ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/backorders/backorders/resolution/update`;
  try {
    const res = await axios.post(URL, { idBO, idUser, resolution, increment_id, idCustomer, bNumAcc, bName, bOwnName, bBranch, bAccTy, reqAmo, rows });
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("updateResolution", error);
  }
};

const requestData = async ( idBO, idUser, idRef, rows, idRows ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/backorders/backorders/data/request`;
  try {
    const res = await axios.post(URL, { idBO, idUser, idRef, rows, idRows });
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("requestData", error);
  }
};

const completeData = async ( idBO, idUser, idRef, bNumAcc, bName, bOwnName, bBranch, bAccTy, rows, idRows ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/backorders/backorders/data/complete`;
  try {
    const res = await axios.post(URL, { idBO, idUser, idRef, bNumAcc, bName, bOwnName, bBranch, bAccTy, rows, idRows });
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("completeData", error);
  }
};

const closeRefund = async ( idBO, idUser, idRef, cNote, rows, idRows ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/backorders/backorders/refund/close`;
  try {
    const res = await axios.post(URL, { idBO, idUser, idRef, cNote, rows, idRows });
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("closeRefund", error);
  }
};

const closeStore = async ( idStore, idUser, cNote, idBO, idCustomer, increment_id, aAmount, wShipp, uName, idRows, rows ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/backorders/backorders/store/close`;
  try {
    const res = await axios.post(URL, { idStore, idUser, cNote, idBO, idCustomer, increment_id, aAmount, wShipp, uName, idRows, rows });
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("closeStore", error);
  }
};

const saveRefund = async ( idBO, idUser, increment_id, ifDebit, ifCredit, bNumAcc, bName, bOwnName, bBranch ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/backorders/backorders/refund/save`;
  try {
    const res = await axios.post(URL, { idBO, idUser, increment_id, ifDebit, ifCredit, bNumAcc, bName, bOwnName, bBranch });
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("saveRefund", error);
  }
};

const setGuide = async ( idGuia, idUser, idBO, increment_id ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/backorders/backorders/guide/set`;
  try {
    const res = await axios.post(URL, { idGuia, idUser, idBO, increment_id });
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("setGuide", error);
  }
};

const generateGuide = async ( idUser, idBO, increment_id, idCarrier, addData ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/backorders/backorders/guide/generate`;
  try {
    const res = await axios.post(URL, { idUser, idBO, increment_id, idCarrier, addData });
    return res.data?.success ? res.data : false;
  } catch (error) {
    console.error("generateGuide", error);
  }
};

const saveBO = async ( idUser, idBO, increment_id, addName, addCtry, addState, addMunic, addStr, addCol, addCity, addBetSt, addPC, addFac, addENum, addINum, addTel, addCel, comment ) => { 
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/backorders/backorders/new/save`;
  try {
    const res = await axios.post(URL, { idUser, idBO, increment_id, addName, addCtry, addState, addMunic, addStr, addCol, addCity, addBetSt, addPC, addFac, addENum, addINum, addTel, addCel, comment });
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("saveBO", error);
  }
};

const saveSC = async ( idBO, idUser ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/backorders/backorders/store/save`;
  try {
    const res = await axios.post(URL, { idBO, idUser });
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("saveSC", error);
  }
};

const setStock = async ( idBO, sku, stock ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/backorders/backorders/stock/set`;
  try {
    const res = await axios.post(URL, { idBO, sku, stock });
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("setStock", error);
  }
};

const endRefund = async ( idBO, idUser, idStatus ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/backorders/backorders/refund/end`;
  try {
    const res = await axios.post(URL, { idBO, idUser, idStatus });
    return res.data?.success ? true : false;
  } catch (error) {
    console.error("endRefund", error);
  }
};

const uploadFiles = async ( idBO, files, idUser, idRes, resType ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/backorders/backorders/files/upload`;
  try {
    const formData = new FormData();
    formData.append("idBO", idBO);
    formData.append("files", files);
    formData.append("idUser", idUser);
    formData.append("idRes", idRes);
    formData.append("resType", resType);

    const res = await axios({
      method: "POST",
      url: URL,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data;",
      },
    });

    return res.data?.success ? true : false;
  } catch (error) {
    console.error("uploadFiles", error);
  }
};


/**
 * EXCEL EXPORT
**/

function getCBO(params) {
  if(params.backorder_number !== 0)
    return params.increment_id+`-${params.backorder_number}`;
  else
    return params.increment_id;
}

const getExcelBackOrders = async ( tb ) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/backorders/${tb}/all/new?page=All`;
  try {
    let res = await axios(URL);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach(element => {
      let aux2 = {};
      if(tb === 1 || tb === 2){
        aux2 = {
          "Backorder": getCBO(element),
          "SKU": element.joinSku,
          "WMS ID": element.wms_id,
          "Nombre Producto": element.joinProducto,
          "Grupo": element.attributes,
          "Cant. Faltante": element.joinCant_faltante,
          "Fecha BO": new Date(element.created_at).toLocaleDateString('es-mx', {timeZone: 'UTC'}),
          "C": element.msg_producto,
          "L": element.msg_logistica,
          "AC": element.msg_ateclientes,
          "Status WMS": element.status_wms,
          "Status Klaar": element.status_item ?? 'Nuevo',
        };
      }else{
        aux2 = {
          "Backorder": getCBO(element),
          "SKU": element.joinSku,
          "Nombre Producto": element.joinProducto,
          "Grupo": element.attributes,
          "Cant. Faltante": element.joinCant_faltante,
          "Fecha BO": new Date(element.created_at).toLocaleDateString('es-mx', {timeZone: 'UTC'}),
          "C": element.msg_producto,
          "L": element.msg_logistica,
          "AC": element.msg_ateclientes,
          "Status WMS": element.status_wms,
          "Status Klaar": element.status_item ?? 'Nuevo',
        };
      }
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

export {
  getBackOrders, searchBackOrders, getBackOrderDetail, getBackOrderItems, getBackOrderAddress, 
  getBackOrderMessages, getBackOrderEvidences, getBackOrderRefund, sendMassiveMessages, getExcelBackOrders,
  checkStock, sendMessage, updateResolution, requestData, completeData, closeRefund, closeStore, saveRefund, 
  setGuide, generateGuide, saveBO, saveSC, setStock, endRefund, uploadFiles
};