import { LoadingButton } from "@mui/lab";
import {
  Card,
  CardContent,
  Chip,
  LinearProgress,
  Link,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import dayjs from "dayjs";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { getFiles } from "../../utils/crm/buscador";
import { getEmails, sendEmail } from "../../utils/crm/emails";
import { getDataDownloadFromBase64 } from "../../utils/crm/files";
import DateInTable from "../general/DateInTable";
import TabPanel from "../general/TabPanel";

const AsesoriaCasoInfo = ({ details }) => {
  const id = details.id_crm_case;

  const auth = useAuth();

  const [asunto, setAsunto] = useState("");
  const [mensaje, setMensaje] = useState("");

  const [archivos, setArchivos] = useState(null);
  const [emails, setEmails] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [tab, setTab] = useState(0);

  const emailFilesRef = useRef(null);

  console.log(details);

  const handleSendEmail = async () => {
    try {
      setIsLoading(true);
      await sendEmail({
        asunto,
        message: mensaje,
        files: emailFilesRef.current.files,
        email: details.contact,
        idCase: id,
        idUser: auth.user.data.awsid,
        aName: auth.user.data.displayName,
        cName: details.nombre,
        object: "Caso",
      });

      await getEmailsAws();
      setAsunto("");
      setMensaje("");
      emailFilesRef.current.value = "";
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const getEmailsAws = async () => {
    const newEmails = await getEmails(id);
    setEmails(newEmails);
  };

  useEffect(() => {
    const getInfo = async () => {
      setIsLoading(true);
      try {
        const newArchivos = await getFiles(id);
        setArchivos(newArchivos);
      } catch (error) {}
      setIsLoading(false);
    };
    getInfo();
  }, [id]);

  return (
    <motion.div
      initial={{ y: 20 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Card sx={{ mb: 4 }}>
        {isLoading && <LinearProgress />}
        <CardContent>
          <Tabs value={tab} onChange={(e, v) => setTab(v)}>
            <Tab label="Movimientos" />
            <Tab label="Mensajes" />
            <Tab label="Observaciones" />
            <Tab label="Archivos" />
            <Tab label="Interacciones asociadas" />
            <Tab label="Emails" />
          </Tabs>
          <TabPanel value={tab} index={0}></TabPanel>
          <TabPanel value={tab} index={1}></TabPanel>
          <TabPanel value={tab} index={2}></TabPanel>
          <TabPanel value={tab} index={3}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gap: 2,
              }}
            >
              {archivos &&
                archivos.map((archivo) => {
                  const { href, download } = getDataDownloadFromBase64(
                    archivo.base64,
                    archivo.name,
                    archivo.type
                  );
                  return (
                    <Link
                      key={archivo.id_file}
                      href={href}
                      download={download}
                      color="inherit"
                      underline="none"
                    >
                      <Paper variant="outlined" sx={{ p: 1 }}>
                        <Chip label={archivo.id_file} size="small" />
                        <Typography
                          variant="button"
                          display="block"
                          sx={{ mt: 2 }}
                        >
                          {archivo.name}
                        </Typography>
                        <Typography variant="caption">
                          {dayjs(archivo.creation_date).format("DD/MM/YYYY")}
                        </Typography>
                      </Paper>
                    </Link>
                  );
                })}
            </Box>
          </TabPanel>
          <TabPanel value={tab} index={4}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Fecha</TableCell>
                  <TableCell>Tipo</TableCell>
                  <TableCell>Descripcion</TableCell>
                  <TableCell>Canal</TableCell>
                  <TableCell>Contacto</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TabPanel>
          <TabPanel value={tab} index={5}>
            <Box>
              <Typography variant="h6" gutterBottom>
                Enviar email
              </Typography>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr",
                  gap: 2,
                  mb: 2,
                }}
              >
                <TextField
                  variant="filled"
                  label="Asunto"
                  value={asunto}
                  onChange={(e) => setAsunto(e.target.value)}
                />
                <TextField
                  variant="filled"
                  multiline
                  rows={3}
                  label="Mensaje"
                  value={mensaje}
                  onChange={(e) => setMensaje(e.target.value)}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <input type="file" ref={emailFilesRef} />
                  <LoadingButton
                    sx={{ ml: 2 }}
                    loading={isLoading}
                    variant="contained"
                    disableElevation
                    onClick={handleSendEmail}
                  >
                    Enviar
                  </LoadingButton>
                </Box>
              </Box>
              <Typography variant="h6" gutterBottom>
                Lista de emails
              </Typography>
              <Box>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Fecha envio</TableCell>
                      <TableCell>Asunto</TableCell>
                      <TableCell>Mensaje</TableCell>
                      <TableCell>Asesor</TableCell>
                      <TableCell>Archivos</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {emails &&
                      emails.map((email, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <DateInTable dateString={email.date_sent} />
                          </TableCell>
                          <TableCell>{email.asunto}</TableCell>
                          <TableCell>{email.mensaje}</TableCell>
                          <TableCell>{`${email.firstname_user}${email.lastname_user}`}</TableCell>
                          <TableCell>
                            {(() => {
                              if (email.att_name === "null") {
                                return "No hay archivos";
                              }

                              const { href, download } =
                                getDataDownloadFromBase64(
                                  email.attachment,
                                  email.att_name,
                                  email.att_type
                                );
                              return (
                                <Typography>
                                  <a href={href} download={download}>
                                    {email.att_name}
                                  </a>
                                </Typography>
                              );
                            })()}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>
            </Box>
          </TabPanel>
        </CardContent>
      </Card>
    </motion.div>
  );
};

export default AsesoriaCasoInfo;
