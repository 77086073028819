import axios from "axios";

const getClavesCuotas = async (id_aclaracion) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/clarifications/clarifications/details/tab/clavescuotas?id_aclaracion=${id_aclaracion}`;

  try {
    const clavesCuotas = await axios(URL);

    return clavesCuotas.data?.success ? clavesCuotas.data.data : null;
  } catch (error) {
    console.error(error);
  }
};

export { getClavesCuotas };
