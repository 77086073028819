import { Box, Grid, Button, Stack, Paper, IconButton, Typography, TextField, Table, TableBody, TableCell, TableHead, TableRow, Accordion, AccordionSummary, AccordionDetails, Card, CardContent, Checkbox, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useEffect, useState } from "react";
import { useAuth } from "../../../context/AuthContext";
import DateInTable from "../../general/DateInTable";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import { getOrders, searchOrders, getItems, saveOrderRelease } from "../../../utils/proveedores/etiquetasProveedor/etiquetasProveedor";
import { getDataForCSV } from "../../../utils/proveedores/pedidosRecibidos/pedidosRecibidos";
import { red } from "@mui/material/colors";

function DetailPanelContent({ row: rowProp }) {
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);

    const columns = [
        { field: "product_sku", headerName: "SKU", flex: 1, align: "center", headerAlign: "center" },
        { field: "product_name", headerName: "Nombre del Producto", flex: 1, align: "center", headerAlign: "center" },
        { field: "quantity", headerName: "Cantidad", flex: 1, align: "center", headerAlign: "center" },
    ];

    if(document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"))
        document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']").remove();

    useEffect(() => {

        let active = true;

        (async () => {
            let newRows = null;
            setLoading(true);
            newRows = await getItems(page, rowProp.increment_id);
            setTotal(newRows.total);
            setRows(newRows.data);
            setLoading(false);
        })();

        return () => {
            active = false;
        };

    }, [page]);

    return (
        <Stack sx={{ py: 2, height: '100%', boxSizing: 'border-box' }} direction="column" >
            <Paper sx={{ flex: 1, mx: 'auto', width: '90%', p: 1 }}>
                <Box style={{ height: 335, width: '100%' }}>
                    <DataGridPro
                        rows={rows}
                        columns={columns}
                        pagination
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        getRowId={((row) => (row.product_sku+'-'+row.student_name))}
                        rowCount={total}
                        paginationMode="server"
                        onPageChange={(newPage) => {
                            setPage(newPage);
                        }}
                        loading={loading}
                        disableColumnMenu
                        disableSelectionOnClick
                    />
                </Box>
            </Paper>
        </Stack>
    );
}

const EtiquetasProveedorDataTable = () => {
    const { user } = useAuth();
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [keyWord, setKeytoSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [initDate, setInitDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [initDateF, setInitDateF] = useState(null);
    const [endDateF, setEndDateF] = useState(null);
    const [errors, setErrors] = useState({});
    const [reload, setReload] = useState(0);
    const [selectedData, setSelectedData] = useState([]);
    const [openME, setOpenME] = useState(false);
    const [openDialogA, setOpenDialogA] = useState(false);

    const columns = [
        {
            field: 'select',
            headerName: 'Select',
            flex: 0.3, align: "center", headerAlign: "center",
            renderCell: ({row}) => ( 
                row.liberado ? (
                    <Checkbox
                        disabled checked
                        sx={{
                            color: red[900],
                            '&.Mui-checked': {
                                color: red[800],
                            },
                        }}
                    />
                ) : (
                    <Checkbox
                        onChange={(e) => handleSelectI(e, row)}
                        checked={isSelected(row.increment_id)}
                    />
                )
            )
        },        
        { field: "increment_id", headerName: "Pedido", flex: 0.75, align: "center", headerAlign: "center" },
        { field: "comprador", headerName: "Comprador", flex: 1, align: "center", headerAlign: "center", valueGetter: ({ row }) => (`${row.customer_name} ${row.customer_lastname} ${row.customer_mothersname}`) },
        { field: "created_at", headerName: "Fecha de pedido", flex: 1, renderCell: (params) => (
            <Typography><DateInTable dateString={params.row.created_at} /></Typography>
        )},
        { field: "payment_date", headerName: "Fecha de pago", flex: 1, renderCell: (params) => (
            <Typography><DateInTable dateString={params.row.payment_date} /></Typography>
        )},
        { field: "liberado", headerName: "Liberado", flex: 0.6, align: "center", headerAlign: "center", 
            renderCell: ({ row }) => 
                (row.liberado ? 
                    (<Button variant="contained" size="small" color="success" sx={{lineHeight: 1, fontSize: "0.7rem", minWidth: 0}}>Si</Button>) 
                    : (<Button variant="contained" size="small" color="error" sx={{lineHeight: 1, fontSize: "0.7rem", minWidth: 0}}>No</Button>) ) },
        { field: "estatus", headerName: "Estatus", flex: 0.5, align: "center", headerAlign: "center", 
            renderCell: ({ row }) => 
                (row.status ? (<Button variant="contained" size="small" color="success" sx={{lineHeight: 1, fontSize: "0.7rem", minWidth: 0}}>{row.status}</Button>) 
                : (<Button variant="contained" size="small" sx={{lineHeight: 1, fontSize: "0.7rem", backgroundColor: "#3498db", minWidth: 0}}>Nuevo</Button>)) },
        { field: "accion", headerName: "CSV", flex: 0.4, align: "center", headerAlign: "center", sortable: false, renderCell: (params) => (
            <IconButton color="primary" size="small" onClick={() => DownloadCsvItems(params.row.increment_id)}><DownloadIcon /></IconButton>
        )},
    ];

    if(document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"))
        document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']").remove();

    useEffect(() => {

        let active = true;
        setRows([]);
        setSelectedData([]);

        (async () => {
            let newRows = null;

            if(typeof keyWord === 'undefined' || keyWord === undefined || keyWord.length === 0){
                if(!initDateF && !endDateF){
                    setLoading(true);
                    newRows = await getOrders(page);
                } else {
                    setLoading(true);
                    newRows = await searchOrders(page, initDateF, endDateF);
                }
            }   
            else if(keyWord.length >= 3)
            {
                setLoading(true);
                newRows = await searchOrders(page, initDateF, endDateF, keyWord);
            }
            else active = false;

            if (!active) {
                return;
            }
            setTotal(newRows.total);
            setRows(newRows.data);
            setLoading(false);
        })();

        return () => {
            active = false;
        };

    }, [page, keyWord, reload]);

    const isSelected = (id) => {
        return selectedData.some(item => item.id === id);
    };    

    function objectToCsvRow(obj, columns) {
        return columns.map(col => {
          const value = obj[col] || '';
          if (col === "Increment Id") {
            return value;
          }
          return `"${value.toString().replace(/"/g, '""')}"`;
        }).join(',');
    }

    function arrayToCsv(data, columns) {
        const header = columns.map(col => (col === "Increment Id" ? col : `"${col}"`)).join(',');
        const rows = data.map(obj => objectToCsvRow(obj, columns));
        return [header, ...rows].join('\n');
      }

    const DownloadCsvItems = async (id) => {
        const DataCsv = await getDataForCSV(id, "items");
        if (DataCsv.length === 0 ) {
            return console.log("No Data");
        }
        const {data, columns} = DataCsv;
    
        const csv = arrayToCsv(data, columns);
        const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csv], { type: 'text/csv' });

        const url = URL.createObjectURL(blob);
    
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `Itemsfromorder${id}.csv`;
    
        document.body.appendChild(a);
        a.click();
    
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      };

      const handleDateChange = (field, date) => {
        if (field === "initDate") {
          setInitDate(date);
    
          if (endDate && new Date(date) > new Date(endDate)) {
            handleDateError();
          }
        } else if (field === "endDate") {
          setEndDate(date);
    
          if (initDate && new Date(date) < new Date(initDate)) {
            handleDateError();
          }
        }
      };
    
      const handleDateError = () => {
        setInitDate(null);
        setEndDate(null);
      };
    
      const validate = () => {
        let newErrors = {};
        if (!initDate) newErrors.startDate = "Fecha de inicio es requerida.";
        if (!endDate) newErrors.endDate = "Fecha final es requerida.";
    
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
      };


      const handleSubmit = async () => {
        if (validate()) {
          setLoading(true);
          setInitDateF(initDate);
          setEndDateF(endDate);
          reloadEff();
        }
      };

     const clearDate = async () => {
        setInitDateF(null);
        setEndDateF(null);
        setInitDate(null);
        setEndDate(null);
        reloadEff();
     }

      const reloadEff = () => {
        setReload((prevValue) => (prevValue === 0 ? 1 : 0));
      };
    
      const handleInputChange = (event) => {
        setKeytoSearch(event.target.value);
      };

      const handleSelectI = (e, row) => {
        if (e.target.checked) {
            setSelectedData([...selectedData, {
                id: row.increment_id,
                customer_name: `${row.customer_name} ${row.customer_lastname} ${row.customer_mothersname}`,
                payment_date: row.payment_date
            }]);
        } else {
            setSelectedData(selectedData.filter(item => item.id !== row.increment_id));
        }
    };    

    const handleCloseME = () => {
        setOpenME(false);
    };

    const liberarSeleccion = async () => {
        if(selectedData.length) {
            let idsToSend = selectedData.map(item => item.id);
            
            let dataS = {
                idUser: user.data.awsid,
                orders: idsToSend
            }
            const res = await saveOrderRelease(dataS);
            if(res === true) {
                reloadEff();
            } else {
                alert("Ocurrio un error")
            }
        } else {
            setOpenME(true);
        }
    };

    return (
        <Box>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography>Búsqueda avanzada</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Card>
                        <CardContent>
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    label="Fecha de inicio"
                                    type="date"
                                    value={initDate || ""}
                                    onChange={(e) =>
                                    handleDateChange("initDate", e.target.value)
                                    }
                                    InputLabelProps={{ shrink: true }}
                                    error={!!errors.initDate}
                                    helperText={errors.initDate}
                                    size="small"
                                    required
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    label="Fecha final"
                                    type="date"
                                    value={endDate || ""}
                                    onChange={(e) =>
                                    handleDateChange("endDate", e.target.value)
                                    }
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ min: initDate }}
                                    disabled={!initDate}
                                    error={!!errors.endDate}
                                    helperText={errors.endDate}
                                    size="small"
                                    required
                                />
                            </Grid>
                            </Grid>
                            </Box>
                            <div style={{ textAlign: 'right', marginTop: '2em' }}>
                            <LoadingButton startIcon={<SearchIcon />} disabled={!initDateF || !endDateF} size="medium" variant="contained" color="error" onClick={() => clearDate()}>Limpiar</LoadingButton>
                                <LoadingButton sx={{ mx: 2}} disabled={!initDate || !endDate} size="medium" variant="contained" color="success" onClick={() => handleSubmit()}>Consultar</LoadingButton>
                            </div>
                        </CardContent>
                    </Card>
                </AccordionDetails>
            </Accordion>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Grid container sx={{ my:2 }}>
                    <Grid item md={4} sx={{ px:2 }}>
                        <Stack direction="row" spacing={1}>
                            <TextField size="small" variant="outlined" fullWidth label={'Buscar Etiqueta'} value={keyWord} onChange={handleInputChange} />
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
            <Box style={{ height: 635, width: '100%' }}>
                <DataGridPro
                    rows={rows}
                    columns={columns}
                    pagination
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    getRowId={((row) => (row.increment_id))}
                    rowCount={total}
                    paginationMode="server"
                    onPageChange={(newPage) => {
                        setPage(newPage);
                    }}
                    loading={loading}
                    disableColumnMenu
                    disableSelectionOnClick
                    getDetailPanelHeight={() => 'auto'}
                    getDetailPanelContent={({ row }) => (<DetailPanelContent row={row} />)}
                />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Grid container sx={{ my: 2 }}>
                    <Grid item md={4} sx={{ px: 2 }}>
                        <Stack direction="row" spacing={1}>
                        <LoadingButton
                        variant="contained"
                        color="info"
                        onClick={() => setOpenDialogA(true)}
                        >
                        Liberar Seleccionados
                        </LoadingButton>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
            <Dialog
                open={openME}
                onClose={handleCloseME}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Seleccione un elemento"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Seleccione al menos un elemento.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleCloseME} color="error">
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
            open={openDialogA}
            onClose={() => setOpenDialogA(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Elementos seleccionados, ¿Deseas continuar?</DialogTitle>
                <DialogContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>No. Pedido</TableCell>
                                <TableCell>Nombre Completo</TableCell>
                                <TableCell>Fecha Pago</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {selectedData.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>{item.id}</TableCell>
                                    <TableCell>{item.customer_name}</TableCell>
                                    <TableCell><Typography><DateInTable dateString={item.payment_date} /></Typography></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialogA(false)} color="error">
                        Cancelar
                    </Button>
                    <Button 
                        onClick={() => {
                            liberarSeleccion();
                            setOpenDialogA(false);
                        }} 
                        color="success" 
                        autoFocus
                    >
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default EtiquetasProveedorDataTable;