import { Box, CircularProgress, Typography, Alert, Card, CardContent, Grid, List, ListItem, ListItemText } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAuth } from "../../../../context/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import DateInTable from "../../../general/DateInTable";
import { getAExcep } from "../../../../utils/trazabilidad/excepciones/excepciones";

const HDTab = ({ excepId, excepDl }) => {
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [historial, setHistorial] = useState(null);

    const columns = [
        {
            field: "fecha",
            headerName: "Fecha",
            flex: 1,
            renderCell: (params) => (
                <Typography><DateInTable dateString={params.row.fecha_reg} /></Typography>
            ),
        },
        {
            field: "usuario",
            headerName: "Usuario",
            flex: 2,
            renderCell: (params) => (
                <Typography>{params.row.nombre} {params.row.apellidoP}</Typography>
            ),
        },
        {
            field: "direccion",
            headerName: "Dirección",
            flex: 4,
            renderCell: (params) => (
                <p>
                    <strong>Cliente:</strong> {params.row.customer_fullname} <br />
                    <strong>Estado:</strong> {params.row.state} <br />
                    <strong>Ciudad:</strong> {params.row.city} <br />
                    <strong>Delegación:</strong> {params.row.delegation} <br />
                    <strong>Colonia:</strong> {params.row.suburb} <br />
                    <strong>Código Postal:</strong> {params.row.postal_code} <br />
                    <strong>Calle:</strong> {params.row.street} <br />
                    <strong># Exterior:</strong> {params.row.outside_number} <br />
                    <strong># Interior:</strong> {params.row.inside_number} <br />
                    <strong>Referencia:</strong> {params.row.reference} <br />
                    <strong>Teléfono:</strong> {params.row.phone_number} <br />
                </p>
            ),
        }
    ];

    const getHistorial = async () => {
        try {
            setIsLoading(true);
            const resp = await getAExcep(excepId);
            setHistorial(resp);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getHistorial();
    }, []);

    return (
        <Box sx={{mb: 2, ml: 2, mr: 2}}>
            {isLoading && <CircularProgress />}
            {(historial && historial.length > 0) && (
                <div style={{ display: "flex", height: "auto", minHeight: 800 }}>
                    <div style={{ flexGrow: 1 }}>
                        <DataGrid
                            rows={historial}
                            columns={columns}
                            getRowHeight={() => 'auto'}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                            getRowId={(row) => row.id_tz_exc_address_history}
                            disableColumnFilter
                            disableColumnMenu
                            disableColumnSelector
                            disableDensitySelector
                            disableExtendRowFullWidth
                            disableSelectionOnClick
                        />
                    </div>
                </div>
            )}

            {(historial && historial.length === 0) && (
                <Grid container sx={{mb:2}}>
                    <Grid item md={3}></Grid>
                    <Grid item md={6}>
                        <Alert variant="filled" severity="info"><Typography variant="p">No se encontraron registros históricos</Typography></Alert>
                    </Grid>
                    <Grid item md={3}></Grid>
                </Grid>                
            )}
        </Box>
    );
};

export default HDTab;