import { Box, Typography, Grid, List, ListItem, ListItemText, LinearProgress } from "@mui/material";
import DateInTable from "../../general/DateInTable";

const RmaDSDetail = ({ rmaId, detalle }) => {

    return (
        <>            
            <Grid container>                
                {detalle && (
                <Grid item md={7}>                                    
                    <List>
                        <ListItem>
                            <ListItemText primary="Número de pedido" secondary={detalle.order_increment_id}/>                                        
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Método de pago" secondary={detalle.method_name}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Días hábiles a partir de fecha de pago" secondary={detalle.diasTranscurridos}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Nombre de cliente" secondary={detalle.customer_name + " " + detalle.customer_lastname + " " + detalle.customer_mothersname}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Dirección de envio" secondary={detalle.shipping_street + " " + detalle.shipping_outside_number + ", " + detalle.shipping_suburb + ", " + detalle.shipping_delegation + ", C.P. " + detalle.shipping_postal_code + ", " + detalle.shipping_city + ", " + detalle.shipping_state}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Email" secondary={detalle.email}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Fecha de última modificación" secondary={<DateInTable dateString={detalle.ultimaModificacion} />}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Comentarios" secondary={detalle.comments}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Tipo de Envío" secondary={detalle.Envio}/>
                        </ListItem>
                    </List>
                </Grid>)}
                {detalle && (
                <Grid item md={5}>
                    <List>
                        <ListItem>
                            <ListItemText primary="Número de RMA" secondary={detalle.increment_id}/>                                        
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Fecha de pago" secondary={<DateInTable dateString={detalle.payment_date} />}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Fecha de solicitud de RMA" secondary={<DateInTable dateString={detalle.created_at} />}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Colegio(s)" secondary={detalle.escuela[0].school}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Número de guía" secondary={detalle.guia}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Cupón" secondary={detalle.coupon_code}/>
                        </ListItem>                                    
                    </List>
                </Grid>)}
            </Grid>            
        </>
    );
};

export default RmaDSDetail;