import { Box, CircularProgress, Typography, Alert, Card, CardContent, Grid, TextField, Switch, Chip, InputLabel, Select, FormControlLabel, FormControl, FormLabel, Radio, RadioGroup, MenuItem, Stack, Snackbar, List, ListItem, ListItemText } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { LoadingButton } from "@mui/lab";
import { useAuth } from "../../../../context/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState, forwardRef, ChangeEvent } from "react";
import DateInTable from "../../../general/DateInTable";
import SaveIcon from '@mui/icons-material/Save';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PrintIcon from '@mui/icons-material/Print';
import { verifProcess, saveGuideMove, saveRequestOccur } from "../../../../utils/trazabilidad/incidencias/indicencias";

const Alerta = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const DETab = ({ incId, incDl }) => {
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [openn, setOpenn] = useState(false);
    const [checked, setChecked] = useState(false);
    const [vertical, setVertical] = useState("top");
    const [horizontal, setHorizontal] = useState("right");
    const [guiamoves, setGuiaMoves] = useState({
        fecha_envio: '',
        observaciones: ''
    });
    
    const handleChangeGuiaMoves = (event) => {    
        let name = event.target.name;
        let value = event.target.value;
        setGuiaMoves((prevalue) => {
            return {
                ...prevalue,
                [name]: value
            }
        });
    };

    const handleClickN = () => {
        setOpenn(true);
    };

    const handleCloseN = () => {
        setOpenn(false);
    };

    const handleChangeCheck = (event) => {
        setChecked(event.target.checked);
    };

    const handleSaveProcede = async () => {
        try {
            setIsLoading(true);
            let dchecked = (checked ? 1 : 2);
            await verifProcess(auth.user.data.awsid, incId, dchecked, incDl.data.id_tipo);
            handleClickN();
            refreshPage();
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSaveGuideMove = async () => {
        try {
            setIsLoading(true);
            if(guiamoves.fecha_envio && guiamoves.observaciones)
            {
                await saveGuideMove(auth.user.data.awsid, incId, guiamoves.observaciones, guiamoves.fecha_envio);
                handleClickN();
                refreshPage();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSaveOcurre = async () => {
        try {
            setIsLoading(true);
            let dchecked = (checked ? 1 : 2);
            await saveRequestOccur(auth.user.data.awsid, incId, dchecked);
            handleClickN();
            refreshPage();
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const refreshPage = () => {
        const current = location.pathname;
        navigate(`/reload`, { replace: true });
        setTimeout(() => {
          navigate(current, { replace: true });
        });
    };

    useEffect(() => {
        /*const getAddress = async () => {
            try {
                setIsLoading(true);
                if(incDl.id_status === 1)
                {
                    setAddress({
                        nombre: (incDl.customer_name + " " + incDl.customer_lastname),
                        calle: (incDl.shipping_street),
                        ne: '',
                        ni: '',
                        entre: '',
                        colonia: (incDl.shipping_suburb),
                        dele: (incDl.shipping_delegation),
                        cp: '',
                        ciudad: (incDl.shipping_city),
                        estado: (incDl.shipping_state),
                        fachada: '',
                        tel: (incDl.telephone)
                    });
                }

                if(incDl.id_status === 3 && incDl.id_tipo === 3)
                {
                    const resp = await getVerifGuides(incId);
                    setGuias(resp);
                }
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };
        getAddress();*/
    }, []);

    return (
        <>
            <Box sx={{mb: 2, ml: 2, mr: 2}}>
            {isLoading && <CircularProgress />}

            <Card sx={{ m:2 }}>
                <CardContent>
                    <Grid container>
                        <Grid item md={3}>
                            <List>
                                <ListItem>
                                    <ListItemText primary="Tipificación" secondary={incDl.data.name_tipo}/>
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item md={3}>
                            <List>
                                <ListItem>
                                    <ListItemText primary="#" secondary={incDl.data.name_guia}/>
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item md={3}>
                            <List>
                                {(incDl.data.id_tipo === 1) && (
                                <ListItem>
                                    <ListItemText primary="Procede" secondary={ (incDl.data.if_procede === 1 ? 'Sí' : 'No') }/>
                                </ListItem>
                                )}
                                {(incDl.data.id_tipo === 2 && incDl.data.id_status === 3) && (
                                <ListItem>
                                    <ListItemText primary="Mensaje" secondary={incDl.data.message}/>
                                </ListItem>
                                )}
                                {(incDl.data.id_tipo === 3 && incDl.data.id_status === 3) && (
                                <ListItem>
                                    <ListItemText primary="Ocurre" secondary={ (incDl.data.if_ocurre === 1 ? 'Sí' : 'No') }/>
                                </ListItem>
                                )}
                            </List>
                        </Grid>
                        <Grid item md={3}>
                            <List>
                                {(incDl.data.id_tipo === 2 && incDl.data.id_status === 3) && (
                                <ListItem>
                                    <ListItemText primary="Fecha" secondary={<DateInTable dateString={incDl.data.date_delivered} />}/>
                                </ListItem>
                                )}
                            </List>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            
            {(incDl.data.id_tipo === 1) && (
            <>
            <Alert variant="filled" severity="info"><Typography variant="p"><strong>Datos del domicilio</strong></Typography></Alert>
            <Grid container sx={{mt:2, mb:2}}>                
                <Grid item md={4}>
                    <List>
                        <ListItem>
                            <ListItemText primary="Estatus" secondary={<Chip label={incDl.data.name_status} variant="outlined" size="small" />}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Calle" secondary={incDl.data.dir_calle}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Núm. Ext" secondary={incDl.data.dir_numero_ext}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Núm. Int" secondary={incDl.data.dir_numero_int}/>
                        </ListItem>
                    </List>
                </Grid>

                <Grid item md={4}>
                    <List>
                        <ListItem>
                            <ListItemText primary="Colonia" secondary={incDl.data.dir_colonia}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Ciudad" secondary={incDl.data.dir_ciudad}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Municipio" secondary={incDl.data.dir_municipio}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Estado" secondary={incDl.data.dir_estado}/>
                        </ListItem>
                    </List>
                </Grid>

                <Grid item md={4}>
                    <List>
                        <ListItem>
                            <ListItemText primary="País" secondary={incDl.data.dir_pais}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Código Postal" secondary={incDl.data.dir_cp}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Fachada" secondary={incDl.data.dir_fachada}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Entre Calles" secondary={incDl.data.dir_entre_calles}/>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
            
            {(incDl.data.id_status === 2) && (
            <Grid container>
                <Grid item md></Grid>
                <Grid item md={5}>
                    <Alert variant="filled" severity="warning"><Typography variant="p"><strong>¿Procede el cambio de domicilio?</strong></Typography></Alert>
                    <Card>
                        <CardContent>
                            <Stack sx={{m: 2}} direction="row" spacing={1} alignItems="center" justifyContent="center">
                                <Typography>No procede</Typography>
                                <Switch checked={checked} onChange={handleChangeCheck} inputProps={{ 'aria-label': 'controlled' }} color="success" />
                                <Typography>Sí procede</Typography>
                            </Stack>
                            <div style={{ textAlign: 'center' }}>
                                <LoadingButton startIcon={<SaveIcon />} sx={{ m: 2}} loading={isLoading} size="medium" variant="contained" color="success" onClick={handleSaveProcede} >Guardar</LoadingButton>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md></Grid>
            </Grid>
            )}
            </>
            )}

            {(incDl.data.id_tipo === 2 && incDl.data.id_status === 1) && (
            <>
            <Grid container sx={{mt:2, mb:2}}>
                <Grid item md></Grid>
                <Grid item md={5}>
                    <Alert variant="filled" severity="warning"><Typography variant="p"><strong>Cerrar incidencia</strong></Typography></Alert>
                    <Card>
                        <CardContent>
                            <TextField InputLabelProps={{ shrink: true }} fullWidth type={"date"} name="fecha_envio" label="Fecha de envío" variant="outlined" value={guiamoves.fecha_envio} onChange={handleChangeGuiaMoves} />
                            <TextField sx={{ mt:2, mb:4 }} fullWidth multiline rows={2} maxRows={4} name="observaciones" label="Observaciones" variant="outlined" value={guiamoves.observaciones} onChange={handleChangeGuiaMoves} />
                            <div style={{ textAlign: 'center' }}>
                                <LoadingButton startIcon={<SaveIcon />} sx={{ mx: 2}} loading={isLoading} size="medium" variant="contained" color="success" onClick={handleSaveGuideMove} disabled={ !(guiamoves.fecha_envio) || !(guiamoves.observaciones) }>Guardar datos</LoadingButton>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md></Grid>
            </Grid>
            </>
            )}

            {(incDl.data.id_tipo === 3 && incDl.data.id_status === 1) && (
            <>
            <Grid container sx={{mt:2, mb:2}}>
                <Grid item md></Grid>
                    <Grid item md={5}>
                    <Alert variant="filled" severity="warning"><Typography variant="p"><strong>¿Ocurre solicitud?</strong></Typography></Alert>
                    <Card>
                        <CardContent>
                            <Stack sx={{m: 2}} direction="row" spacing={1} alignItems="center" justifyContent="center">
                                <Typography>No ocurre</Typography>
                                <Switch checked={checked} onChange={handleChangeCheck} inputProps={{ 'aria-label': 'controlled' }} color="success" />
                                <Typography>Sí ocurre</Typography>
                            </Stack>
                            <div style={{ textAlign: 'center' }}>
                                <LoadingButton startIcon={<SaveIcon />} sx={{ m: 2}} loading={isLoading} size="medium" variant="contained" color="success" onClick={handleSaveOcurre} >Guardar</LoadingButton>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md></Grid>
            </Grid>
            </>
            )}

            </Box>

            <Snackbar anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal} open={openn} onClose={handleCloseN}>
                <Alerta onClose={handleCloseN} severity="success" sx={{ width: '100%' }}>Información actualizada</Alerta>
            </Snackbar>
        </>
    );
};

export default DETab;