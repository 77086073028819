import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { deleteBeneficiary } from "../../../utils/vales/beneficiarios/beneficiarios";

const ModalDelete = ({
  isOpen,
  onClose,
  userId,
  selectedUserName,
  setLoading,
  setRows,
  setEstado,
  updateUsr,
}) => {
  async function handleDeleteClick() {
    let data = {
      idBeneficiary: userId,
      idUser: updateUsr,
    };
    setLoading(true);
    setRows([]);
    onClose();
    await deleteBeneficiary(data);
    setEstado((prevEstado) => (prevEstado === 0 ? 1 : 0));
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{`Eliminar ${selectedUserName}`}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          ¿Estás seguro de eliminar el usuario?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error" variant="outlined">
          Cancelar
        </Button>
        <Button onClick={handleDeleteClick} color="success" variant="outlined">
          Eliminar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalDelete;
