import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const findLastPath = () => {
      const lastPath = localStorage.getItem("lastPath");
      console.log("last path", lastPath);
      if (lastPath) {
        return lastPath;
      }
      return "/login";
    };
    navigate(findLastPath(), { replace: true });
  }, []);

  return null;
};

export default Home;
