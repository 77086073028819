import { Box, Grid, Button, Stack, TextField, Menu, MenuItem, Typography, List, Dialog, DialogTitle, DialogContent, DialogActions, Table, TableBody, TableCell, TableHead, TableRow, AppBar, Toolbar } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useEffect, useState } from "react";
import { useAuth } from "../../../../context/AuthContext";
import { useNavigate } from "react-router-dom";

import local from "dayjs/plugin/localizedFormat";
import relative from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";

import { getGuides, insertGuide } from "../../../../utils/trazabilidad/pedidos/pedidos";

import Paper from '@mui/material/Paper';
import ArticleIcon from '@mui/icons-material/Article';
import PrintIcon from '@mui/icons-material/Print';
import DeleteIcon from '@mui/icons-material/Delete';

dayjs.locale("es-mx");
dayjs.extend(local);
dayjs.extend(relative);
dayjs.extend(utc);

function DetailPanelContent({ row: rowProp }) {
    return (
        <Stack sx={{ py: 2, height: '100%', boxSizing: 'border-box' }} direction="column" >
            <Paper sx={{ flex: 1, mx: 'auto', width: '90%', p: 1 }}>
                <Stack direction="column" spacing={1} sx={{ height: 1 }}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ textAlign: 'center' }}>Referencia</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>Fecha de entrega</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>Tipo</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>Fecha estafeta</TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>Fecha de captura</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{ border: 0 }} >
                                <TableCell sx={{ width: '20%', textAlign: 'center' }}>{rowProp.referencia}</TableCell>
                                <TableCell sx={{ width: '20%', textAlign: 'center' }}>{dayjs(rowProp.fechaEntrega).format("DD MMM YYYY, hh:mm:ss A")}</TableCell>
                                <TableCell sx={{ width: '20%', textAlign: 'center' }}>{rowProp.tipo}</TableCell>
                                <TableCell sx={{ width: '20%', textAlign: 'center' }}>{dayjs(rowProp.fechaRecoleccion).format("DD MMM YYYY, hh:mm:ss A")}</TableCell>
                                <TableCell sx={{ width: '20%', textAlign: 'center' }}>{dayjs(rowProp.created_at).format("DD MMM YYYY, hh:mm:ss A")}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Stack>
            </Paper>
        </Stack>
    );
}

const GuiasTab = ({ increment_id }) => {
    const { user } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [detalle, setDetalle] = useState(null);

    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [keyWord, setKeytoSearch] = useState("");

    const [loading, setLoading] = useState(false); //Loading Index
    const [isELoading, setIsELoading] = useState(false); //Loading Excel

    const [auxIdTzGuias, setAuxIdTzGuias] = useState(null); 
    const [razon, setRazon] = useState("");

    const [openModalEliminarGuia, setOpenModalEliminarGuia] = useState(false);

    const handleOpenModalEliminarGuia = (idtzguias) => {
        setAuxIdTzGuias(idtzguias);
        setOpenModalEliminarGuia(true);
    };

    const handleCloseModalEliminarGuia = () => {
        setAuxIdTzGuias(null);
        setRazon("");
        setOpenModalEliminarGuia(false);
    };

    const handleEliminarGuia = async (guia) => {
        const guiaEliminada = {
            idGuia: guia,
            idUser: user.data.awsid,
            razones: razon,
            incrementId: increment_id
        };

        try {
            await insertGuide(guiaEliminada);
        } catch (error) {
            console.error("handleEliminarGuia", error);
        }
    };

    const handleDescargarPDF = (id, base64) => {
        console.log(id, base64);
        const linkSource = `data:application/pdf;base64,${base64}`;
        const downloadLink = document.createElement("a");

        const fileNameWithExt = `Guia - ${id}.pdf`;

        downloadLink.href = linkSource;
        downloadLink.download = fileNameWithExt;
        downloadLink.click();
    };

    const columns = [

        {
            field: "guia", headerName: "Guia", flex: 1.5, align: "center", headerAlign: "center",
        },
        {
            field: "rastreo", headerName: "No. Rastreo", flex: 1, align: "center", headerAlign: "center",
        },
        {
            field: "destino", headerName: "Destino", flex: 1, align: "center", headerAlign: "center",
        },
        {
            field: "estatus", headerName: "Estatus", flex: 1, align: "center", headerAlign: "center",
        },
        {
            field: "carrier", headerName: "Carrier", flex: 1, align: "center", headerAlign: "center",
        },
        {
            field: "Imprimir-Eliminar", headerName: " ", flex: 1, align: "center", headerAlign: "center",
            renderCell: (params) => (<>
                {params.row.pdf !== null && <Button sx={{ mx:1 }} size="small" variant="contained" color="success" onClick={() => handleDescargarPDF(params.row.id_tz_guias, params.row.pdf)}><PrintIcon /></Button>}
                {params.row.estatus === null && <Button sx={{ mx:1 }} variant="contained" size="small" color="error" disableElevation onClick={() => handleOpenModalEliminarGuia(params.row.id_tz_guias)}><DeleteIcon /></Button>}
            </>)
        },
    ];

    if (document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"))
        document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']").remove();


    useEffect(() => {
        let active = true;
        (async () => {
            let newRows = null;
            if (typeof keyWord === 'undefined' || keyWord === undefined || keyWord.length === 0) {
                setLoading(true);
                newRows = await getGuides(increment_id, page);
                console.log(newRows);
            }
            else if (keyWord.length >= 1) {
                setLoading(true);
                //newRows = await searchOrders (page, keyWord);
            }
            else active = false;
            if (!active) {
                return;
            }
            setTotal(newRows.total);
            setRows(newRows.data);
            setLoading(false);
        })();
        return () => {
            active = false;
        };
    }, [increment_id]);

    return (<>
        <Box sx={{ mx: 3 }} style={{ height: 380 }} component={Paper} >
            <DataGridPro
                rows={rows}
                columns={columns}
                pagination
                pageSize={5}
                rowsPerPageOptions={[5]}
                getRowId={((row) => (row.id_tz_guias))}
                rowCount={total}
                paginationMode="server"
                onPageChange={(newPage) => {
                    setPage(newPage);
                }}
                loading={loading}
                disableColumnMenu
                disableSelectionOnClick
                getDetailPanelHeight={() => 'auto'}
                getDetailPanelContent={({ row }) => (<DetailPanelContent row={row} />)}
            />
        </Box>

        <Dialog open={openModalEliminarGuia} onClose={() => handleCloseModalEliminarGuia()} fullWidth={true}>
            <DialogTitle>
                <Typography>
                    <strong>¿Por que quieres eliminar la guia?</strong>
                </Typography>
            </DialogTitle>
            <DialogContent>
                <AppBar position="sticky" color="transparent" sx={{ top: 'auto' }}>
                    <Toolbar>
                        <TextField
                            id="outlined-textarea"
                            label="Escribir mensaje..."
                            size="small"
                            value={razon}
                            fullWidth
                            onChange={(e) => setRazon(e.target.value)}
                        />
                        <LoadingButton loading={isLoading} variant="contained" color="success" size="medium" sx={{ ml: 1 }}
                            onClick={()=>handleEliminarGuia()} disabled={!razon}
                        >Eliminar</LoadingButton>
                    </Toolbar>
                </AppBar>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={() => handleCloseModalEliminarGuia()}>Cerrar</Button>
            </DialogActions>
        </Dialog>
    </>);
};

export default GuiasTab;