import { Box, LinearProgress, Typography, TextField, List, ListItem, ListItemText, Paper, ListItemAvatar, Avatar, AppBar, Toolbar } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAuth } from "../../../../context/AuthContext";
import { useEffect, useState, Fragment } from "react";
import { getTabMessages, saveMessage } from "../../../../utils/cancelaciones/cancelaciones/cancelaciones";
import { deepOrange, deepPurple } from "@mui/material/colors";
import local from "dayjs/plugin/localizedFormat";
import relative from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";

dayjs.locale("es-mx");
dayjs.extend(local);
dayjs.extend(relative);
dayjs.extend(utc);

const MessagesTab = ({ id , idOrder}) => {
    const auth = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [detalle, setDetalle] = useState(null);
    const [mensaje, setMensaje] = useState("");

    const getMessages = async () => {
        try {
            setIsLoading(true);
            const res = await getTabMessages(id);
            setDetalle(res);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };
    
    const handleSaveMessage = async () => {
        console.log(mensaje);
        console.log(id);
        console.log(idOrder);
        console.log(auth.user.data.awsid);
        try {
            setIsLoading(true);
            await saveMessage(mensaje, id, idOrder, auth.user.data.awsid);
            getMessages();
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
            handleClose();
        }
    };

    const handleClose = () => {
        setMensaje("");
    };

    useEffect(() => {
        getMessages();
    }, [id]);

    return (<>
        <Box sx={{ mb: 2, ml: 2, mr: 2 }}>
            {isLoading && <LinearProgress />}
            <Fragment>
                <Paper square>
                    {!detalle && (
                        <List sx={{ width: '100%', bgcolor: 'background.paper', overflow: 'auto', maxHeight: 400, minHeight: 400 }}>
                            <Fragment>
                                <ListItem button>
                                    <ListItemText primary="Aún no hay mensajes" />
                                </ListItem>
                            </Fragment>
                        </List>
                    )}

                    {detalle && (
                        <List sx={{ width: '100%', bgcolor: 'background.paper', overflow: 'auto', maxHeight: 400, minHeight: 400 }}>
                            {detalle.data.map(({ id_mensaje, comentario, fecha_reg, usuario, user_photo }) => (
                                <Fragment key={id_mensaje}>
                                    {auth.user.data.displayName == usuario && (
                                        <ListItem button>
                                            <ListItemText align="right" primary={usuario} secondary={
                                                <Fragment>
                                                    <Typography>{comentario}</Typography>
                                                    <Typography variant="caption">
                                                        {dayjs.utc(fecha_reg).format("DD MMM YYYY, hh:mm A")} - {dayjs.utc(fecha_reg).from(dayjs().utc(true))}
                                                    </Typography>
                                                </Fragment>
                                            } />
                                            <ListItemAvatar sx={{ ml: 2, mr: 0 }}>
                                                {user_photo && (<Avatar alt="Profile Picture"><img src={user_photo} style={{ width: '100%' }} /></Avatar>)}
                                                {!user_photo && (<Avatar alt="Profile Picture" sx={{ bgcolor: deepOrange[500] }} >{usuario.charAt(0)}</Avatar>)}
                                            </ListItemAvatar>
                                        </ListItem>
                                    )}

                                    {auth.user.data.displayName != usuario && (
                                        <ListItem button>
                                            <ListItemAvatar>
                                                {user_photo && (<Avatar alt="Profile Picture"><img src={user_photo} style={{ width: '100%' }} /></Avatar>)}
                                                {!user_photo && (<Avatar alt="Profile Picture" sx={{ bgcolor: deepPurple[500] }} >{usuario.charAt(0)}</Avatar>)}
                                            </ListItemAvatar>
                                            <ListItemText primary={usuario} secondary={
                                                <Fragment>
                                                    <Typography>{comentario}</Typography>
                                                    <Typography variant="caption">
                                                        {dayjs.utc(fecha_reg).format("DD MMM YYYY, hh:mm A")} - {dayjs.utc(fecha_reg).from(dayjs().utc(true))}
                                                    </Typography>
                                                </Fragment>
                                            } />
                                        </ListItem>
                                    )}
                                </Fragment>
                            ))}
                        </List>
                    )}
                </Paper>
                <AppBar position="sticky" color="transparent" sx={{ top: 'auto' }}>
                    <Toolbar>
                        <TextField
                            id="outlined-textarea"
                            label="Escribir mensaje..."
                            size="small"
                            value={mensaje}
                            fullWidth
                            onChange={(e) => setMensaje(e.target.value)}
                        />
                        <LoadingButton loading={isLoading} variant="contained" color="success" size="medium" sx={{ ml: 1 }}
                        onClick={handleSaveMessage} disabled={!mensaje}
                        >Enviar</LoadingButton>
                    </Toolbar>
                </AppBar>
            </Fragment>
        </Box>
    </>);
};

export default MessagesTab;