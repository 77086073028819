import { LoadingButton } from "@mui/lab";
import { Chip, TableBody, TableCell, TableRow, Button, Menu, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, InputLabel, Select, FormControl } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useAuth } from "../../../../context/AuthContext";
import VisibilityIcon from "@mui/icons-material/Visibility";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SaveIcon from '@mui/icons-material/Save';
import { getDetailTipification, saveTipifications, DeleteTipification } from "../../../../utils/trazabilidad/excepciones/excepciones";

const TipificacionesTableBody = ( exceps, loading ) => {
  const { user } = useAuth();  
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(null);
  const [openedit, setOpenEdit] = useState(false);
  const [opendel, setOpenDel] = useState(false);
  const [iddel, setIdDel] = useState(0);
  const [tipi, setTipi] = useState({
    id: 0,
    tipo: '',
    clave: '',
    accion: 'sf',
    carrier: 1,
    interna: 0,
  });

  function isTipi(){
    if(tipi.tipo && tipi.clave && tipi.accion)
      return true;
    else
      return false;
  }

  const handleChangeTipi = (event) => {    
    let name = event.target.name;
    let value = event.target.value;
    setTipi((prevalue) => {
      return {
        ...prevalue,
        [name]: value
      }
    });
  };

  const handleClickEditOpen = async (id) => {
    try {
      setIsLoading(true);
      const resp = await getDetailTipification(id);
      setTipi((prevalue) => {
        return {
          ...prevalue,
          ["id"]: resp.id,
          ["tipo"]: resp.tipo,
          ["clave"]: resp.clave,
          ["accion"]: resp.accion,
          ["carrier"]: resp.id_carrier,
          ["interna"]: (resp.interna) ? resp.interna : 0,
        }
      });
      setOpenEdit(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const handleClickDelOpen = (id) => {
    setIdDel(id);
    setOpenDel(true);
  };

  const handleDelClose = () => {
    setIdDel(0);
    setOpenDel(false);
  };

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setOpen(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(null)
  };

  const handleSaveTipi = async () => {
    try {
      setIsLoading(true);
      await saveTipifications(tipi.id, user.data.awsid, tipi.tipo, tipi.accion, tipi.carrier, tipi.clave, tipi.interna);
      refreshPage();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteTipi = async () => {
    try {
      setIsLoading(true);
      await DeleteTipification(iddel, user.data.awsid);
      refreshPage();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const refreshPage = () => {
    const current = location.pathname;
    navigate(`/reload`, { replace: true });
    setTimeout(() => {
      navigate(current, { replace: true });
    });
  };

  return (
    <>
    
      <TableBody>
        {exceps.exceps.map((row) => (
          <TableRow key={row.id}>
              <TableCell>{row.id}</TableCell>
              <TableCell>{row.tipo}</TableCell>
              <TableCell><Chip label={row.clave} variant="outlined" size="small" /></TableCell>
              <TableCell>{row.accion}</TableCell>
              <TableCell>{row.nombre}</TableCell>
              <TableCell>
                  <Button variant="contained" size="small" endIcon={<KeyboardArrowDownIcon />} disableElevation aria-controls={open ? this : undefined} aria-haspopup="menu" onClick={(event) => handleClick(event, row.id)}>Acciones</Button>
                  <Menu anchorEl={anchorEl} open={open === row.id ? true : false} onClose={handleClose}>
                    <MenuItem onClick={() => handleClickEditOpen(row.id)}>Editar</MenuItem>
                    <MenuItem onClick={() => handleClickDelOpen(row.id)}>Eliminar</MenuItem>
                  </Menu>
              </TableCell>
          </TableRow>
        ))}
      </TableBody>
      
      <Dialog open={openedit} fullWidth={true} maxWidth="sm" onClose={handleEditClose}>
        <DialogTitle>Editar tipificación - ID: {tipi.id}</DialogTitle>
        <DialogContent>
            <TextField fullWidth sx={{mt: 2, mb: 2}} id="tipo" name="tipo" label="Tipo" variant="outlined" value={tipi.tipo} onChange={handleChangeTipi} />
            <TextField fullWidth sx={{mb: 2}} id="clave" name="clave" label="Clave" variant="outlined" value={tipi.clave} onChange={handleChangeTipi} />
            <FormControl fullWidth sx={{mb: 2}}>
                <InputLabel htmlFor="accion">Acción</InputLabel>
                <Select id="accion" name="accion" label="Acción" value={tipi.accion} onChange={handleChangeTipi}>
                    <MenuItem value="sf">Sales Force</MenuItem>
                    <MenuItem value="magento">Magento</MenuItem>
                    <MenuItem value="info">Info</MenuItem>
                </Select>
            </FormControl>
            <FormControl fullWidth sx={{mb: 2}}>
                <InputLabel htmlFor="carrier">Carrier</InputLabel>
                <Select id="carrier" name="carrier" label="Carrier" value={tipi.carrier} onChange={handleChangeTipi}>
                    <MenuItem value={1}>Estafeta</MenuItem>
                    <MenuItem value={2}>Fedex</MenuItem>
                    <MenuItem value={3}>DHL</MenuItem>
                </Select>
            </FormControl>
            <FormControl fullWidth sx={{mb: 2}}>
                <InputLabel htmlFor="interna">Interna</InputLabel>
                <Select id="interna" name="interna" label="Interna" value={tipi.interna} onChange={handleChangeTipi}>
                    <MenuItem value={0}>No</MenuItem>
                    <MenuItem value={1}>Logística-AC</MenuItem>
                    <MenuItem value={2}>TI</MenuItem>
                </Select>
            </FormControl>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleEditClose}>Cancelar</Button>
            <LoadingButton loading={isLoading} variant="contained" color="info" startIcon={<SaveIcon />} disabled={!isTipi()} onClick={handleSaveTipi}>Actualizar</LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog open={opendel} fullWidth={true} maxWidth="xs" onClose={handleDelClose} >
        <DialogTitle id="alert-dialog-title">
          {"Eliminar tipificación"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Quiere eliminar la tipificación con ID: {iddel}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>          
          <Button variant="contained" color="error" onClick={handleDeleteTipi} autoFocus disabled={isLoading}>Sí, eliminar</Button>
          <Button onClick={handleDelClose}>Cancelar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TipificacionesTableBody;