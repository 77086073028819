import axios from "axios";

const getEvidencias = async (idAcl) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/clarifications/clarifications/details/tab/files?idAcl=${idAcl}`;
  try {
    const evidencias = await axios(URL);

    return evidencias.data?.success ? evidencias.data.data : [];
  } catch (error) {
    console.error(error);
  }
};

export { getEvidencias };
