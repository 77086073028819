import { Box, Grid, Button, Stack, Paper, Typography, TextField, Table, TableBody, TableCell, TableHead, TableRow, Dialog, DialogActions, DialogContent, DialogTitle, Chip, } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import DateInTable from "../../general/DateInTable";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { getRequest, searchPagos, getExcelRequest } from "../../../utils/pagos/solicitud/solicitud";
import ExcelFile from "../../general/ExcelFile";

const chipColors = {
    "0": "#ffffff",
    "1": "#f39d13",
    "2": "#00a65a",
    "3": "#00a65a",
    "4": "#dd4b39"
};
const chipLabels = {
    "0": "",
    "1": "En espera de aprobación",
    "2": "Aprobado por dirección",
    "3": "Aprobado",
    "4": "No aprobado"
};

const AprobacionesDataTable = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [keyWord, setKeytoSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [isELoading, setIsELoading] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [excelData, setExcelData] = useState(null);

    const getAllExcelData = async () => {
        try {
            setIsELoading(true);
            const data = await getExcelRequest(user.data.awsid);
            setExcelData(data);
        } catch (error) {
            console.error(error);
        } finally {
            setIsELoading(false);
        }
    };
    
    const columns = [
        { field: "DocEntry", headerName: "DocEntry", flex: 1, align: "center", headerAlign: "center" },
        { field: "Codigo", headerName: "Código", flex: 1, align: "center", headerAlign: "center" },
        { field: "Nombre", headerName: "Nombre", flex: 1, align: "center", headerAlign: "center" },
        { field: "MontoPagar", headerName: "Monto a Pagar", flex: 1, align: "center", headerAlign: "center" },
        { field: "Observaciones", headerName: "Observaciones", flex: 1, align: "center", headerAlign: "center" },
        { field: "status", headerName: "Estatus", flex: 1, align: "center", headerAlign: "center", renderCell: (params) => (
            <Chip label={chipLabels[params.row.status]} size="small" sx={{ backgroundColor: chipColors[params.row.status], color: "#fff", textAlign: "center" }} />
        )},
        { field: "acciones", headerName: "Acciones", flex: 1.6, align: "center", headerAlign: "center", sortable: false, renderCell: (params) => (<>
            {(params.row.status === 0) && (<Button  variant="contained" size="small" disableElevation>Solicitar Aprobacion</Button>
            )}
        </>)},
    ];

    if(document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"))
        document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']").remove();

    useEffect(() => {

        let active = true;

        (async () => {
            let newRows = null;

            if(typeof keyWord === 'undefined' || keyWord === undefined || keyWord.length === 0){
                setLoading(true);
                newRows = await getRequest(page, user.data.awsid);
            }   
            else if(keyWord.length >= 5)
            {
                setLoading(true);
                newRows = await searchPagos(page, keyWord, user.data.awsid);
            }
            else active = false;

            if (!active) {
                return;
            }
            setTotal(newRows.total);
            setRows(newRows.data);
            setLoading(false);
        })();

        return () => {
            active = false;
        };

    }, [page, keyWord]);

    return (
    <>
        <Box sx={{ display: "flex", alignItems: "center" }}>
            <Grid container sx={{ my:2 }}>
                <Grid item md sx={{ px:2, textAlign: 'lefth' }}>
                    {!excelData && <LoadingButton loading={isELoading} variant="contained" color="success" onClick={getAllExcelData}>+ Aprobar</LoadingButton>}
                    {excelData && (<ExcelFile excelData={excelData} fileName={"RMAs"} />)}
                </Grid>
            </Grid>
        </Box>
        <Box style={{ height: 635, width: '100%' }}>
            <DataGridPro
                rows={rows}
                columns={columns}
                pagination
                pageSize={10}
                rowsPerPageOptions={[10]}
                getRowId={((row) => (row.DocEntry + '-' + row.Numero + '-' + row.MontoPagar))}
                rowCount={total}
                paginationMode="server"
                onPageChange={(newPage) => {
                    setPage(newPage);
                }}
                onSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel);
                }}
                selectionModel={selectionModel}
                loading={loading}
                disableColumnMenu
                disableSelectionOnClick
                checkboxSelection
            />
            </Box>
    </>
    );
};

export default AprobacionesDataTable;