import axios from "axios";

export const getEmails = async (id) => {
  try {
    const url = `${process.env.REACT_APP_BASEURL}/crm/mails/list`;
    const res = await axios.get(url, {
      params: {
        idMain: id,
      },
    });

    return res?.data?.success ? res.data.data : [];
  } catch (error) {
    console.error("getEmails", error.message);
    return [];
  }
};

export const sendEmail = async ({
  asunto,
  message,
  files,
  email,
  idCase,
  idUser,
  aName,
  cName,
  object,
}) => {
  try {
    const formData = new FormData();
    formData.append("asunto", asunto);
    formData.append("msj", message);
    formData.append("files", files[0]);
    formData.append("email", email);
    formData.append("idCase", idCase);
    formData.append("idUser", idUser);
    formData.append("aName", aName);
    formData.append("cName", cName);
    formData.append("object", object);
    const url = `${process.env.REACT_APP_BASEURL}/crm/mails/send`;
    const res = await axios({
      method: "POST",
      url,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (res.statusText !== "OK" || !res.data.success) {
      throw new Error("Error al enviar los archivos");
    }
  } catch (error) {
    console.error("sendEmail", error.message);
  }
};
