const profiles = {
    solicitud: [1,],
    aprobaciones: [1,],
    proveedores: [1,],
};

const belongsPagos = ( profile ) => {
    let aux = profiles;
    return  aux.solicitud.includes( profile ) ? true : aux.aprobaciones.includes( profile )
            ? true : aux.proveedores.includes( profile );
}

export{
    profiles, belongsPagos
};