import { Box, Button, Card, CardContent, LinearProgress, Typography, Tabs, Tab, Grid } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import AssignmentIcon from '@mui/icons-material/Assignment';
import ForumIcon from '@mui/icons-material/Forum';
import { useEffect, useState } from "react";
import { useAuth } from "../../../context/AuthContext";
import { profiles } from "../../../utils/trazabilidad/profilesTrazabilidad";
import VerificacionesDSDetail from "./VerificacionesDSDetail";
import IndexTabs from "./tabs/IndexTabs";
import { getVerifDetails } from "../../../utils/trazabilidad/verificaciones/verificaciones";
import BreadCrumb from "../../general/BreadCrumb";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";

const itemsBreadCrumbs = [
  {
    label: 'Trazabilidad',
    icon: <ContentPasteSearchIcon sx={{ mr: 0.5 }} fontSize="small"/>,
    link: '/trazabilidad/inicio'
  },
  { label: 'Verificaciones', link: '/trazabilidad/verificaciones' },
  { label: 'Detalle' }
];

const VerificacionesDetail = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const params = useParams();
    const id = params.id;
    const [isLoading, setIsLoading] = useState(false);
    const [detalle, setDetalle] = useState(null);

    useEffect(() => {

        if(!profiles.verificaciones.includes(user.role.idProfile)){
            navigate("/dashboard", { replace: true });
        }
        else
        {
            const getDetallePedido = async () => {
                try {
                    setIsLoading(true);
                    const res = await getVerifDetails(id);
                    setDetalle(res);
                } catch (error) {
                    console.error(error);
                } finally {
                    setIsLoading(false);
                }
            };
            getDetallePedido();
        }
    }, [id]);

    return (
        <>
            <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <Typography variant="h4" sx={{ my: 2 }}>Verificaciones</Typography>
                <BreadCrumb items={itemsBreadCrumbs} />
            </Box>

            <motion.div initial={{ y: 20 }} animate={{ y: 0 }} transition={{ duration: 0.5 }}>
                <Card>
                    <Box>
                        <Box>                        
                            <Grid container spacing={2}>
                                <Grid item md={12}>
                                    <Typography variant="h6" sx={{ ml: 2, my: 2, mb: 0 }}><AssignmentIcon sx={{ m: 0 }} /> Detalles de la verificación</Typography>
                                    {isLoading && <LinearProgress />}
                                    {detalle && (<VerificacionesDSDetail verifId={id} detalle={detalle} />)}
                                </Grid>
                            </Grid>
                        </Box>
                        {detalle && (<IndexTabs verifId={id} verifDl={detalle} />)}
                    </Box>
                </Card>
            </motion.div>

            <Box>&nbsp;</Box>
        </>
    );
};

export default VerificacionesDetail;