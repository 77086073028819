import axios from "axios";
import dayjs from "dayjs";

const createAsesoria = async ({ solution, email, channel, idUser, college, theme }) => {
  try {
    const asesoria = {
      solution: solution,
      object: "Asesoría",
      contacto: email,
      channel: channel,
      idUser: idUser,
      estatus: 1,
      id_school: college,
      id_tema: theme,
    };

    await axios.post(`${process.env.REACT_APP_BASEURL}/crm/cases/create`, asesoria);
  } catch (error) {
    console.error(error);
  }
};

const getOnCloseDay = (firstDay, lastDay) => {
  const f = dayjs(firstDay);
  const l = dayjs(lastDay);

  return l.diff(f, "day");
};

const getSemaphoreColor = (diastranscurridos, a, b, c, d) => {
  if (diastranscurridos <= a) {
    return "#43a047";
  } else if (diastranscurridos <= b) {
    return "#fdd835";
  } else if (diastranscurridos <= c) {
    return "#e53935";
  } else if (diastranscurridos <= d) {
    return "#5e35b1";
  } else {
    return "#5e35b1";
  }
};

export { createAsesoria, getOnCloseDay, getSemaphoreColor };
