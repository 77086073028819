import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  IconButton,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  getListProductsTracking,
  getProductsReq,
  reqShippingRequirement,
} from "../../../../../utils/ventaMayoreo/ordenesVenta/ordenesVenta";
import AddIcon from "@mui/icons-material/Add";
import { AlmacenRow } from "./AlmacenRow";

export const ProductosTab = ({
  id,
  idRequerimiento,
  ordCotizacion,
  ordenVenta,
}) => {
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);

  const [seguimientosAsignados, setSeguimientosAsignados] = useState([]);
  const [productos, setProductos] = useState({});
  const [requerimiento, setRequerimiento] = useState([]);
  const [productosRequerimiento, setProductosRequerimiento] = useState([]);
  const [productosSeguimiento, setProductosSeguimiento] = useState([]);
  const [seguimientosD, setSeguimientosD] = useState([]);
  const [almacenesRequerimiento, setAlmacenesRequerimiento] = useState([]);
  const [almacenesD, setAlmacenesD] = useState([]);
  const [contadorProductosParaGuiaD, setContadorProductosParaGuiaD] =
    useState(null);
  const [idsSeleccionados, setIdsSeleccionados] = useState([]);

  const [expandedRow, setExpandedRow] = useState(null);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [detailsData, setDetailsData] = useState({});
  const [myState, setMyState] = useState(0);
  

  function encodeUtf8(val) {
    try {
      return decodeURIComponent(escape(val));
    } catch (err) {
      return val;
    }
  }

  const toggleState = () => {
    setMyState((currentState) => (currentState === 0 ? 1 : 0));
  };

  useEffect(() => {
    setSeguimientosAsignados([]);
    setProductos({});
    setRequerimiento([]);
    setProductosRequerimiento([]);
    setProductosSeguimiento([]);
    setAlmacenesRequerimiento([]);
    setAlmacenesD([]);
    setContadorProductosParaGuiaD(null);
    setIdsSeleccionados([]);
    setExpandedRow(null);
    setLoadingDetails(false);
    setDetailsData({});

    (async () => {
      setLoading(true);
      let allData = await getProductsReq(
        idRequerimiento,
        ordCotizacion,
        ordenVenta
      );
      let {
        productos,
        requerimiento,
        segumientos_asignados,
        productos_requerimiento,
        productos_seguimiento,
        seguimientos,
        almacenes_requerimiento,
        almacenes,
        contadorProductosParaGuia,
      } = allData.data;
      setProductos(productos);
      setRequerimiento(requerimiento);
      setSeguimientosAsignados(segumientos_asignados);
      setProductosRequerimiento(productos_requerimiento);
      setProductosSeguimiento(productos_seguimiento);
      setSeguimientosD(seguimientos);
      setAlmacenesRequerimiento(almacenes_requerimiento);
      setAlmacenesD(almacenes);
      setContadorProductosParaGuiaD(contadorProductosParaGuia);
      setLoading(false);
      console.log(seguimientos);
      console.log(almacenes_requerimiento);
    })();
  }, [id, myState]);
  

  const handleCheckboxChange = (id) => {
    setIdsSeleccionados((prevIds) => {
      if (prevIds.includes(id)) {
        return prevIds.filter((prevId) => prevId !== id);
      } else {
        return [...prevIds, id];
      }
    });
  };

  const solicitarEnvio = async () => {
    try {
      let data = {
        products: idsSeleccionados,
      };
      console.log(data);
      await reqShippingRequirement(data);
      toggleState();
    } catch (error) {
      console.error(error);
    }
  };

  const handleRowExpansion = async (id) => {
    if (detailsData[id]) {
      setExpandedRow(expandedRow === id ? null : id);
      return;
    }
    setLoadingDetails(true);

    try {
      const response = await getListProductsTracking(ordenVenta, id);
      const data = response.data;
      console.log(data);
      setDetailsData((prevDetails) => ({
        ...prevDetails,
        [id]: data,
      }));
      setExpandedRow(id);
    } catch (error) {
      console.error("Hubo un error al cargar los detalles: ", error);
    } finally {
      setLoadingDetails(false);
    }
  };

  return (
    <>
      {productosSeguimiento.length === 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Producto</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>SKU</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Cantidad</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Almacenes</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Asignación</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productosRequerimiento.map((req, index) => {
                if (req.precio !== 0) {
                  const almacenes = almacenesRequerimiento[index];
                  let totalCantidadAlmacenes = 0;
                  almacenes.forEach((alm) => {
                    totalCantidadAlmacenes += alm.cantidad_disponible;
                  });

                  let cantidadParaAlmacen = 0;
                  let cantidadParaOrdenes = 0;

                  if (almacenes && almacenes.length > 0) {
                    totalCantidadAlmacenes = almacenes.reduce(
                      (acc, alm) => acc + (alm.cantidad_disponible || 0),
                      0
                    );
                  }

                  if (req.cantidad_disponible < totalCantidadAlmacenes) {
                    cantidadParaAlmacen = req.cantidad_disponible;
                  } else if (
                    totalCantidadAlmacenes - req.cantidad_disponible <
                    0
                  ) {
                    cantidadParaOrdenes = -(
                      totalCantidadAlmacenes - req.cantidad_disponible
                    );
                    cantidadParaAlmacen =
                      req.cantidad_disponible - cantidadParaOrdenes;
                  } else {
                    cantidadParaOrdenes = req.cantidad_disponible;
                  }

                  return (
                    <TableRow key={req.id_requerimiento_producto}>
                      <TableCell>{encodeUtf8(req.nombre)}</TableCell>
                      <TableCell align="center">{req.sku}</TableCell>
                      <TableCell align="center">
                        {req.cantidad_disponible}
                      </TableCell>
                      <TableCell align="center">
                        <AlmacenRow ar={almacenes} almacenes={almacenesD} />
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                          label="Almacén"
                          size="small"
                          value={cantidadParaAlmacen}
                          onChange={(e) => {}}
                        />
                        <TextField
                          label="Órdenes"
                          size="small"
                          value={cantidadParaOrdenes}
                          onChange={(e) => {}}
                        />
                      </TableCell>
                    </TableRow>
                  );
                }
                return null;
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <strong>Producto</strong>
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <strong>SKU</strong>
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <strong>Cantidad</strong>
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <strong>Seguimiento</strong>
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <strong>Entregado</strong>
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <strong>Num. Guía</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(productos).map(([key, value]) => {
                  const productInfo = value
                    .split(/(?<!\\), /)
                    .map((item) => item.replace(/\\,/g, ","));

                  return (
                    <>
                      <TableRow key={key} sx={{ "& td": { padding: 0 } }}>
                        <TableCell sx={{ textAlign: "center" }}>
                          <Checkbox
                            name={`productos[]`}
                            value={key}
                            onChange={() => handleCheckboxChange(key)}
                            checked={idsSeleccionados.includes(key)}
                          />
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          <IconButton onClick={() => handleRowExpansion(key)}>
                            <AddIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          <Typography>{productInfo[2]}</Typography>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          <Typography>{productInfo[1]}</Typography>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          <Typography>{productInfo[5]}</Typography>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          <Button
                            variant="contained"
                            color="success"
                            size="small"
                            sx={{
                              textTransform: "none",
                              lineHeight: 1,
                              fontSize: "0.7rem",
                              minWidth: 0,
                            }}
                          >
                            Listo para envío
                          </Button>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {productInfo[7] ? (
                            <Button
                              variant="contained"
                              color="success"
                              size="small"
                              sx={{
                                textTransform: "none",
                                lineHeight: 1,
                                fontSize: "0.7rem",
                                minWidth: 0,
                              }}
                            >
                              Si
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              color="error"
                              size="small"
                              sx={{
                                textTransform: "none",
                                lineHeight: 1,
                                fontSize: "0.7rem",
                                minWidth: 0,
                              }}
                            >
                              No
                            </Button>
                          )}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {productInfo[6] !== "null"
                            ? productInfo[6]
                            : "S/Guía"}
                        </TableCell>
                      </TableRow>
                      {expandedRow === key && (
                        <TableRow>
                          <TableCell colSpan={8}>
                            {loadingDetails ? (
                              <Typography>Cargando...</Typography>
                            ) : (
                              <Paper>
                                <Table size="small">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Cantidad</TableCell>
                                      <TableCell>Tipo</TableCell>
                                      <TableCell>Status</TableCell>
                                      <TableCell>Orden</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {detailsData[key]?.map((detail, index) => (
                                      <TableRow key={index}>
                                        <TableCell>{detail.cantidad}</TableCell>
                                        <TableCell>
                                          {detail.tipo_seguimiento}
                                        </TableCell>
                                        <TableCell>
                                          <Button
                                            variant="contained"
                                            size="small"
                                            color={
                                              detail.status === 1 ||
                                              detail.status === 29 ||
                                              detail.status === 30 ||
                                              detail.status === 32 ||
                                              detail.status === 33
                                                ? "warning"
                                                : "success"
                                            }
                                            sx={{
                                              textTransform: "none",
                                              lineHeight: 1,
                                              fontSize: "0.7rem",
                                              minWidth: 0,
                                            }}
                                          >
                                            {detail.status_nombre}
                                          </Button>
                                        </TableCell>

                                        <TableCell>
                                          {detail.numero_orden}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </Paper>
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  );
                })}
                {seguimientosAsignados.map((seg) => (
                  <React.Fragment key={seg.id_proveedor_producto}>
                    <TableRow sx={{ "& td": { padding: 0 } }}>
                      <TableCell sx={{ textAlign: "center" }}></TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <IconButton
                          onClick={() =>
                            handleRowExpansion(seg.id_proveedor_producto)
                          }
                        >
                          <AddIcon />
                        </IconButton>
                      </TableCell>

                      <TableCell>
                        <Typography>{seg.nombre}</Typography>
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <Typography>{seg.sku}</Typography>
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <Typography>{seg.cantidad}</Typography>
                        <input
                          type="hidden"
                          id={`ov-${seg.sku}-${seg.sku}`}
                          value={seg.orden_venta}
                        />
                        <input
                          type="hidden"
                          id={`irp-${seg.sku}-${seg.sku}`}
                          value={seg.id_proveedor_producto}
                        />
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {seg.status_seguimiento === 1 && (
                          <Button
                            variant="contained"
                            color="warning"
                            size="small"
                            sx={{
                              textTransform: "none",
                              lineHeight: 1,
                              fontSize: "0.7rem",
                              minWidth: 0,
                            }}
                          >
                            En proceso
                          </Button>
                        )}
                        {seg.status_seguimiento === 3 && (
                          <Button
                            variant="contained"
                            color="success"
                            size="small"
                            sx={{
                              textTransform: "none",
                              lineHeight: 1,
                              fontSize: "0.7rem",
                              minWidth: 0,
                            }}
                          >
                            Envío solicitado
                          </Button>
                        )}
                        {seg.status_seguimiento === 4 && (
                          <Button
                            variant="contained"
                            color="success"
                            size="small"
                            sx={{
                              textTransform: "none",
                              lineHeight: 1,
                              fontSize: "0.7rem",
                              minWidth: 0,
                            }}
                          >
                            Con Num. Guía
                          </Button>
                        )}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {seg.entregado_ov == 1 ? (
                          <Button
                            variant="contained"
                            color="success"
                            size="small"
                            sx={{
                              textTransform: "none",
                              lineHeight: 1,
                              fontSize: "0.7rem",
                              minWidth: 0,
                            }}
                          >
                            Si
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            color="error"
                            size="small"
                            sx={{
                              textTransform: "none",
                              lineHeight: 1,
                              fontSize: "0.7rem",
                              minWidth: 0,
                            }}
                          >
                            No
                          </Button>
                        )}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {seg.guia ? seg.guia : "S/Guía"}
                      </TableCell>
                    </TableRow>
                    {expandedRow === seg.id_proveedor_producto && (
                      <TableRow>
                        <TableCell colSpan={8}>
                          {loadingDetails ? (
                            <Typography>Cargando...</Typography>
                          ) : (
                            <Paper>
                              <Table size="small">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Cantidad</TableCell>
                                    <TableCell>Tipo</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Orden</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {detailsData[seg.id_proveedor_producto]?.map(
                                    (detail, index) => (
                                      <TableRow key={index}>
                                        <TableCell>{detail.cantidad}</TableCell>
                                        <TableCell>
                                          {detail.tipo_seguimiento}
                                        </TableCell>
                                        <TableCell>
                                          <Button
                                            variant="contained"
                                            size="small"
                                            color={
                                              detail.status === 1 ||
                                              detail.status === 29 ||
                                              detail.status === 30 ||
                                              detail.status === 32 ||
                                              detail.status === 33
                                                ? "warning"
                                                : "success"
                                            }
                                            sx={{
                                              textTransform: "none",
                                              lineHeight: 1,
                                              fontSize: "0.7rem",
                                              minWidth: 0,
                                            }}
                                          >
                                            {detail.status_nombre}
                                          </Button>
                                        </TableCell>

                                        <TableCell>
                                          {detail.numero_orden}
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                            </Paper>
                          )}
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {contadorProductosParaGuiaD && contadorProductosParaGuiaD > 0 ? (
            <Box sx={{ display: "flex", justifyContent: "end" }}>
              <Button
                variant="contained"
                color="info"
                sx={{
                  marginTop: "25px",
                  marginBottom: "10px",
                  marginRight: "5px",
                }}
                onClick={() => solicitarEnvio()}
              >
                Solicitar envio
              </Button>
            </Box>
          ) : null}
        </>
      )}
      {loading && (
        <>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </>
      )}
    </>
  );
};
