import { Box, Grid, Button, CircularProgress, Stack, Typography, Paper, TextField, Chip, Table, TableBody, TableCell, TableHead, TableRow, Dialog, DialogActions, DialogContent, DialogTitle, Menu, MenuItem, List, ListItem, ListItemText, LinearProgress } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useEffect, useState } from "react";
import { useAuth } from "../../../context/AuthContext";
import { confirmCancelation, getOrderDetails, getCancelations, saveCancelation, saveCreditNote, searchCancelation, uploadFiles, uploadMultipleFiles, getTabEvidences } from "../../../utils/cancelaciones/cancelaciones/cancelaciones";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SendIcon from '@mui/icons-material/Send';
import SearchIcon from '@mui/icons-material/Search';
import DateInTable from "../../general/DateInTable";
import Toast from "../../general/Toast";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SaveIcon from '@mui/icons-material/Save';
import { profiles } from '../../../utils/cancelaciones/profilesCancelaciones';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AddIcon from "@mui/icons-material/Add";
import ExcelFile from "../../general/ExcelFile";
import local from "dayjs/plugin/localizedFormat";
import relative from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import { deleteSingleFile, getCancelationExcel } from "../../../utils/cancelaciones/cancelaciones/cancelaciones";

dayjs.locale("es-mx");
dayjs.extend(local);
dayjs.extend(relative);
dayjs.extend(utc);


const chipColors = {
    "Nuevo": "#2ecc71",
    "Cancelado": "#e74c3c",
    "Cerrado": "#e74c3c",
    "En Proceso": "#2980b9",
    "En Espera": "#4a519b",
    "Detenido": "#ff4500",
    "null": "transparent",
    "No Detenido": "#e74c3c",
    "En Espera de Nota de credito": "#4a519b",
    "En espera de Comprobante": "#4a519b",
};
const chipLabels = {
    "Nuevo": "Nuevo",
    "Cancelado": "Cancelado",
    "Cerrado": "Cerrado",
    "En Proceso": "En Proceso",
    "En Espera": "En Espera",
    "Detenido": "Detenido",
    "null": "-",
    "No Detenido": "No Detenido",
    "En Espera de Nota de credito": "En espera de Nota de credito",
    "En espera de Comprobante": "En espera de Comprobante",
};

//Checar : "id_can_controller"

const CancelacionesDataTable = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [isELoading, setIsELoading] = useState(false);
    const [excelData, setExcelData] = useState(null);
    const [file, setFile] = useState([]);

    const [keyWord, setKeytoSearch] = useState("");
    const [keyOrder, setKeyOrdertoSearch] = useState("");
    const [keyNoteCredit, setKeyNoteCredit] = useState("");

    const [confalert, setConfAlert] = useState({ severidad: 'success', mensaje: '' });
    const [data, setData] = useState(null);
    const [statusorder, setStatusOrder] = useState(null);
    const [loading, setLoading] = useState(false); //Loading Index
    const [isLoading, setIsLoading] = useState(false);
    const [loadingSD, setLoadingSD] = useState(false); //Loading Index
    const [loadingRCO, setLoadingRCO] = useState(false); //Loading Index
    const [loadingSNC, setLoadingSNC] = useState(false); //Loading Index
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(null);

    const [detalle, setDetalle] = useState(null);

    const [isScLoading, setIsScLoading] = useState(false); //Loading Massive Message

    const [idorden, setIdorden] = useState("");
    const [idCancelation, setidCancelation] = useState("");
    //----------------------------------------------------------------
    const [openModalSC, setOpenModalSC] = useState(null);

    const handleOpenModalSC = (id_cancelacion) => {
        setLoadingSD(true);
        handleGetIdCancelation(id_cancelacion);
        setFile(null);
        setLoadingSD(false);
        setOpenModalSC(true);
    };

    const handleCloseModalSC = () => {
        setOpenModalSC(false);
    };
    //----------------------------------------------------------------
    const [openModalSNC, setOpenModalSA] = useState(null);

    const handleOpenModalSNC = (id_cancelacion) => {
        handleGetIdCancelation(id_cancelacion);
        setOpenModalSA(true);
    };

    const handleCloseModalSNC = () => {
        setidCancelation("");
        setKeyNoteCredit("");
        setOpenModalSA(false);
    };

    //----------------------------------------------------------------
    const [openSaveNoteCredit, setOpenSaveNoteCredit] = useState(null);

    const handleOpenSaveNoteCredit = () => {
        setOpenSaveNoteCredit(true);
    };

    const handleCloseSaveNoteCredit = () => {
        setOpenSaveNoteCredit(false);
    };

    //----------------------------------------------------------------

    const [openSaveCancel, setOpenSaveCancel] = useState(false);

    const handleOpenSaveCancel = () => {
        setOpenSaveCancel(true);
    };

    const handleCloseSaveCancel = () => {
        setOpenSaveCancel(false);
    };
    //----------------------------------------------------------------
    const handleClick = (event, id) => {
        setAnchorEl(event.currentTarget);
        setOpen(id);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(null)
    };
    //------------------------Alert-----------------------------------
    const [openAlert, setOpenAlert] = useState(false);

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };
    const handleCloseAlert = () => {
        setOpenAlert(false);
    };
    //----------------------------------------------------------------
    const handleGetOrden = async (id_orden) => {
        setIdorden(id_orden);
        handleOpenSaveCancel();
    }

    const handleGetIdCancelation = (id_cancelation) => {
        setidCancelation(id_cancelation);
    };

    const nombreCompleto = (nombres, apellidos) => {
        const usuario = [];
        if (nombres) {
            usuario.push(nombres + " ");
        }
        if (apellidos) {
            usuario.push(apellidos + " ");
        }
        return (
            usuario
        );
    };

    const handleDownload = (base64, name) => {
        const linkSource = `data:application/pdf;base64,${base64}`;
        const downloadLink = document.createElement("a");
        const fileNameWithExt = `${name}.pdf`;

        downloadLink.href = linkSource;
        downloadLink.download = fileNameWithExt;
        downloadLink.click();
    };


    const searchData = async () => {
        try {
            setLoadingSD(true);
            const resp = await getOrderDetails(keyOrder);
            if (resp.data) {
                setData(resp);
            } else {
                setConfAlert({ severidad: 'error', mensaje: 'Orden no encontrada.' });
                handleOpenAlert(true);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingSD(false);
        }
    };

    const Confirm = async (id_cancelacion, area, opcion) => {
        try {
            setLoadingSNC(true);
            const status = await confirmCancelation(
                id_cancelacion, area, opcion, user.data.awsid
            );
            setStatusOrder(status);
            if (status)
                setConfAlert({ severidad: 'success', mensaje: 'Se ha registrado la cancelación con exito.' });
            else
                setConfAlert({ severidad: 'error', mensaje: 'El pedido ya esta en proceso de cancelacion.' });
            handleOpenAlert(true);
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingSNC(false);
        }
    };

    const registerCancelOrder = async () => {
        try {
            setLoadingRCO(true);
            const status = await saveCancelation(keyOrder, user.data.awsid);
            setStatusOrder(status);
            if (status)
                setConfAlert({ severidad: 'success', mensaje: 'Se ha registrado la cancelación con exito.' });
            else
                setConfAlert({ severidad: 'error', mensaje: 'El pedido ya esta en proceso de cancelacion.' });
            handleOpenAlert(true);
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingRCO(false);
        }
    };

    const saveCreditN = (idCancelation, keyNoteCredit) => {
        try {
            setLoadingSNC(true);
            const note = saveCreditNote(idCancelation, keyNoteCredit);
            setStatusOrder(note);
            if (note)
                Confirm(idCancelation, 'Finanzas-Nota', 1);
            else
                setConfAlert({ severidad: 'error', mensaje: 'Cambios no aplicados. Verifica la información e intenta más tarde' });
            handleOpenAlert(true);
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingSNC(false);
        }
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleUpload = async () => {

        try {
            setLoadingSD(true);
            await uploadFiles (file, idCancelation, user.data.awsid );
        } catch (error) {
            console.error(error);
        } finally {
            getEvidences();
            setLoadingSD(false);
        }
    };

    const handleDelete = async (id_file) => {

        try {
            setLoadingSD(true);
            await deleteSingleFile (id_file, user.data.awsid );
        } catch (error) {
            console.error(error);
        } finally {
            getEvidences();
            setLoadingSD(false);
        }
    };

    const getEvidences = async () => {
        try {
            setIsLoading(true);
            const res = await getTabEvidences (idCancelation);
            setDetalle(res);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const getAllExcelData = async () => {
        try {
            setIsELoading(true);
            const data = await getCancelationExcel();
            setExcelData(data);
        } catch (error) {
            console.error(error);
        } finally {
            setIsELoading(false);
        }
    };

    const columns = [
        {
            field: "id_cancelacion", headerName: "ID Cancelación", flex: 0.75, align: "center", headerAlign: "center"
        },
        { field: "id_orden", headerName: "Orden", flex: 0.6, align: "center", headerAlign: "center" },
        { field: "id_caso_crm", headerName: "Caso CRM", flex: 0.6, align: "center", headerAlign: "center" },
        { field: "cretaed_at", headerName: "Fecha Alta", flex: 1.1, align: "center", headerAlign: "center", renderCell: (params) => (
            <Typography><DateInTable dateString={params.row.cretaed_at} /></Typography>
        )},
        { field: "channel", headerName: "Channel", flex: 1, align: "center", headerAlign: "center" },
        { field: "type", headerName: "Type", flex: 0.5, align: "center", headerAlign: "center" },

        {
            field: "estatus", headerName: "Estatus Flujo", flex: 1, align: "center", headerAlign: "center", renderCell: (params) => (
                <Chip label={chipLabels[params.row.estatus]} size="small" sx={{ backgroundColor: chipColors[params.row.estatus], color: "#fff", textAlign: "center" }} />
            )
        },
        {
            field: "id_status_logistica", headerName: "Estatus Logistica", flex: 1, align: "center", headerAlign: "center", renderCell: (params) => (
                <Chip label={chipLabels[params.row.id_status_logistica]} size="small" sx={{ backgroundColor: chipColors[params.row.id_status_logistica], color: "#fff", textAlign: "center" }} />
            )
        },
        {
            field: "id_status_acc", headerName: "Estatus AC", flex: 1, align: "center", headerAlign: "center", renderCell: (params) => (
                <Chip label={chipLabels[params.row.id_status_acc]} size="small" sx={{ backgroundColor: chipColors[params.row.id_status_acc], color: "#fff", textAlign: "center" }} />
            )
        },
        {
            field: "id_status_finanza", headerName: "Estatus Finanzas", flex: 1, align: "center", headerAlign: "center", renderCell: (params) => (
                <Chip label={chipLabels[params.row.id_status_finanza]} size="small" sx={{ backgroundColor: chipColors[params.row.id_status_finanza], color: "#fff", textAlign: "center" }} />
            )
        },

        {
            field: "Acciones", headerName: "Acciones", flex: 1.1, align: "center", headerAlign: "center", sortable: false,
            renderCell: (params) => (<>
                <Button variant="contained" size="small" endIcon={<KeyboardArrowDownIcon />} disableElevation aria-controls={open ? this : undefined} aria-haspopup="menu" onClick={(event) => handleClick(event, params.row.id_orden)}>Acción</Button>
                <Menu anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }} transformOrigin={{ vertical: 'top', horizontal: 'right', }} anchorEl={anchorEl} open={open === params.row.id_orden ? true : false} onClose={handleClose}>

                    {(params.row.id_can_controller === 1 || params.row.id_can_controller === 2) && (user.role.idProfile === 9 || user.role.idProfile === 45 || user.role.idProfile === 1) && (params.row.estatus === "Cancelado Automatico" || params.row.estatus === "Cerrado") && <>
                        <MenuItem onClick={() => navigate(`detail/${params.row.id_cancelacion}`)}>Ver detalle</MenuItem>
                    </>}
                    {(params.row.id_can_controller === 1 || params.row.id_can_controller === 2) && (user.role.idProfile === 9 || user.role.idProfile === 45 || user.role.idProfile === 1) && !(params.row.estatus === "Cancelado Automatico" || params.row.estatus === "Cerrado") && <>
                        <MenuItem onClick={() => navigate(`detail/${params.row.id_cancelacion}`)}>Ver detalle</MenuItem>
                        <MenuItem onClick={() => Confirm(params.row.id_cancelacion,"Logistica", 1)}>Confirmación de detenido</MenuItem>
                        <MenuItem onClick={() => Confirm(params.row.id_cancelacion,"Logistica", 0)}>No se pudo detener</MenuItem>
                    </>}

                    {(params.row.id_can_controller === 3 && (user.role.idProfile === 7 || user.role.idProfile === 30 || user.role.idProfile === 1)) && (params.row.estatus === "Cancelado Automatico" || params.row.estatus === "Cerrado") && <>
                        <MenuItem onClick={() => navigate(`detail/${params.row.id_cancelacion}`)}>Ver detalle</MenuItem>
                    </>}
                    {(params.row.id_can_controller === 3 && (user.role.idProfile === 7 || user.role.idProfile === 30 || user.role.idProfile === 1)) && !(params.row.estatus === "Cancelado Automatico" || params.row.estatus === "Cerrado") && <>
                        <MenuItem onClick={() => navigate(`detail/${params.row.id_cancelacion}`)}>Ver detalle</MenuItem>
                        <MenuItem onClick={() => Confirm(params.row.id_cancelacion,"AC", 1)}>Cliente notificado</MenuItem>
                    </>}
                    
                    {(params.row.id_can_controller === 4 || params.row.id_can_controller === 5 || params.row.id_can_controller === 6 || params.row.id_can_controller === 7 || params.row.id_can_controller === 8) && (user.role.idProfile === 10 || user.role.idProfile === 1) && (params.row.estatus === "Cancelado Automatico" || params.row.estatus === "Cerrado") && <>
                        <MenuItem onClick={() => navigate(`detail/${params.row.id_cancelacion}`)}>Ver detalle</MenuItem>
                    </>}
                    {(params.row.id_can_controller === 4 || params.row.id_can_controller === 5 || params.row.id_can_controller === 6 || params.row.id_can_controller === 7 || params.row.id_can_controller === 8) && (user.role.idProfile === 10 || user.role.idProfile === 1) && !(params.row.estatus === "Cancelado Automatico" || params.row.estatus === "Cerrado") && <>
                        <MenuItem onClick={() => navigate(`detail/${params.row.id_cancelacion}`)}>Ver detalle</MenuItem>
                        <MenuItem onClick={() => handleOpenModalSNC(params.row.id_cancelacion)}>Subir nota de credito</MenuItem>
                    </>}
                    

                    {(params.row.id_can_controller === 9 || params.row.id_can_controller === 10) && (user.role.idProfile === 10 || user.role.idProfile === 1) && (params.row.estatus === "Cancelado Automatico" || params.row.estatus === "Cerrado" || params.row.estatus === "Cancelado") && <>
                        <MenuItem onClick={() => navigate(`detail/${params.row.id_cancelacion}`)}>Ver detalle</MenuItem>
                    </>}
                    {(params.row.id_can_controller === 9 || params.row.id_can_controller === 10) && (user.role.idProfile === 10 || user.role.idProfile === 1) && !(params.row.estatus === "Cancelado Automatico" || params.row.estatus === "Cerrado" || params.row.estatus === "Cancelado") && <>
                        <MenuItem onClick={() => navigate(`detail/${params.row.id_cancelacion}`)}>Ver detalle</MenuItem>
                        <MenuItem onClick={() => handleOpenModalSC(params.row.id_cancelacion)}>Subir comprobante</MenuItem>
                    </>}

                    {!(params.row.id_can_controller === 1 || params.row.id_can_controller === 2 || params.row.id_can_controller === 3 || params.row.id_can_controller === 4 || params.row.id_can_controller === 5 || params.row.id_can_controller === 6 || params.row.id_can_controller === 7 || params.row.id_can_controller === 8 || params.row.id_can_controller === 9 || params.row.id_can_controller === 10) && <>
                        <MenuItem onClick={() => navigate(`detail/${params.row.id_cancelacion}`)}>Ver detalle</MenuItem>
                    </>}
                </Menu>
            </>)
        },
    ];

    if (document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"))
        document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']").remove();

    useEffect(() => {
        let active = true;
        (async () => {
            let newRows = null;
            if (keyOrder.length != 9) {
                setData(null);
            }
            if (typeof keyWord === 'undefined' || keyWord === undefined || keyWord.length === 0) {
                setLoading(true);
                newRows = await getCancelations(page, user.data.awsid);
            }
            else if (keyWord.length >= 5) {
                setLoading(true);
                newRows = await searchCancelation(page, keyWord);
            }
            else active = false;
            if (!active) {
                return;
            }
            setTotal(newRows.total);
            setRows(newRows.data);
            setLoading(false);
        })();
        return () => {
            active = false;
        };
    }, [page, keyWord, user, keyOrder, keyNoteCredit, loadingSD]);

    return (<>
        <Box sx={{ display: "flex", alignItems: "center" }}>
            <Grid container sx={{ my: 2 }}>
                <Grid item md={4} sx={{ px: 2 }}>
                    <Stack direction="row" spacing={1}>
                        <TextField size="small" variant="outlined" fullWidth label="Buscar Cancelacion..." value={keyWord} onChange={(e) => setKeytoSearch(e.target.value)} />
                    </Stack>
                </Grid>
                <Grid item md sx={{ px: 2, textAlign: 'right' }}>
                    {!excelData && <LoadingButton loading={isELoading} variant="contained" color="info" onClick={getAllExcelData}>Generar excel</LoadingButton>}
                    {excelData && (<ExcelFile excelData={excelData} fileName={"Cancelaciones"} />)}

                    <Button variant="contained" color="success" startIcon={<AddIcon />} onClick={() => setOpenSaveCancel(true)} sx={{ ml: 2 }} > Nueva cancelación </Button>
                </Grid>
            </Grid>
        </Box>

        <Box style={{ height: 635, width: '100%' }}>
            <DataGridPro
                rows={rows}
                columns={columns}
                pagination
                pageSize={10}
                rowsPerPageOptions={[10]}
                getRowId={((row) => (row.id_cancelacion + '-' + row.id_orden))}
                rowCount={total}
                paginationMode="server"
                onPageChange={(newPage) => {
                    setPage(newPage);
                }}
                loading={loading}
                disableColumnMenu
                disableSelectionOnClick
            //getDetailPanelHeight={() => 'auto'}
            //getDetailPanelContent={({ row }) => (<DetailPanelContent row={row} />)}
            />
        </Box>

        <Dialog open={openSaveCancel} onClose={() => handleCloseSaveCancel()} maxWidth="sm" fullWidth>
            <DialogTitle>Crear Cancelación</DialogTitle>

            <DialogContent>
                <Stack sx={{ mt: 2, mb: 2 }} direction="row" spacing={1}>
                    <TextField fullWidth label="Ingresa número de pedido..." variant="outlined" value={keyOrder} onChange={(e) => setKeyOrdertoSearch(e.target.value)} />
                    <Button variant="contained" color="info" startIcon={<SearchIcon />} disabled={(!keyOrder || keyOrder.length < 9)} onClick={() => searchData(keyOrder) && (setLoadingSD(true))}>Buscar</Button>
                </Stack>
                <Stack>
                    {(loadingSD && (!data || keyOrder.length <= 0)) && (
                        <><Typography variant="p"> Cargando ...</Typography> <CircularProgress /></>
                    )}
                </Stack>
                {data && (<>
                    <Typography variant="p">Información del pedido:</Typography>
                    <Grid container>
                        <Grid item md={6}>
                            <List>
                                <ListItem><ListItemText primary="Número de pedido:" secondary={data.data.increment_id} /></ListItem>
                                <ListItem><ListItemText primary="Fecha de creación:" secondary={<DateInTable dateString={data.data.created_at} />} /></ListItem>
                                <ListItem><ListItemText primary="Fecha de pago" secondary={<DateInTable dateString={data.data.payment_date} />} /></ListItem>
                                <ListItem><ListItemText primary="Escuela(s)" secondary={data.data.schools} /></ListItem>
                            </List>
                        </Grid>
                        <Grid item md={6}>
                            <List>
                                <ListItem><ListItemText primary="Nombre del cliente" secondary={data.data.customer_name + " " + data.data.customer_lastname + " " + data.data.customer_mothersname} /></ListItem>
                                <ListItem><ListItemText primary="Teléfono" secondary={data.data.telephone} /></ListItem>
                                <ListItem><ListItemText primary="Email" secondary={data.data.email} /></ListItem>
                            </List>
                        </Grid>
                    </Grid></>
                )}
            </DialogContent>

            <DialogActions>
                <Button variant="contained" color="error" onClick={() => setOpenSaveCancel(false)}>Cerrar</Button>
                <LoadingButton loading={isScLoading} variant="contained" color="info" loadingIndicator="Enviando…" endIcon={<SendIcon />} onClick={registerCancelOrder} disabled={(!keyOrder || keyOrder.length < 9 || !data)}>Cancelar pedido</LoadingButton>
            </DialogActions>
        </Dialog>

        <Dialog open={openModalSNC} onClose={() => handleCloseModalSNC()}>
            <DialogTitle>
                Ingresar nota de crédito
            </DialogTitle>
            <DialogContent>
                <TextField size="small" variant="outlined" fullWidth label="Ingresar número de nota..." value={keyNoteCredit} onChange={(e) => setKeyNoteCredit(e.target.value)}/>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={() => handleCloseModalSNC()}>Cerrar</Button>
                <LoadingButton disable={(!keyNoteCredit || keyNoteCredit.length <= 0)} loading={loadingSNC} variant="contained" color="info" loadingIndicator="Enviando…" endIcon={<SendIcon />} onClick={()=>saveCreditN(idCancelation, keyNoteCredit)}>Confirmar</LoadingButton>
            </DialogActions>
        </Dialog>

        <Dialog open={openModalSC} onClose={() => handleCloseModalSC()} maxWidth="sm" fullWidth>
            <DialogTitle>
                Subir Archivo
            </DialogTitle>
            <DialogContent>
                <Box sx={{ mb: 2, ml: 2, mr: 2 }}>
                    {loadingSD && <LinearProgress />}
                    <Grid container sx={{ mt: 2, mb: 2 }}>
                        {detalle && (<>
                            {detalle.data.map(({ id_file, name, firstname_user, lastname_user, size, creation_date, base64 }) => (<>
                                <Box sx={{ width: 'auto', height: 'auto', backgroundColor: "#ffff", boxShadow: 2, border: 1, borderRadius: 2, p: 2 }}>
                                    <List >
                                        <ListItemText primary={name} />
                                        <ListItemText primary="Usuario:" secondary={nombreCompleto(firstname_user, lastname_user)} />
                                        <ListItemText primary="Peso:" secondary={size} />
                                        <Typography variant="caption">
                                            {dayjs.utc(creation_date).format("DD MMM YYYY, hh:mm A")}
                                        </Typography>
                                    </List>
                                    <Button variant="contained" startIcon={<FileDownloadIcon />} onClick={() => handleDownload(base64, name)}>Descargar PDF</Button>
                                    <Button variant="contained" startIcon={<FileDownloadIcon />} onClick={() => handleDelete(id_file, user.data.awsid)}>Eliminar</Button>
                                </Box>
                            </>
                            ))}
                        </>)}
                    </Grid>
                </Box>

                <Box sx={{m:4}} >
                    <Stack sx={{ alignItems: "center" }}>
                        <input  accept=".pdf" type="file" onChange={handleFileChange}/>
                    </Stack>
                </Box>
                <Stack sx={{alignItems: "center"}}>
                    <Button disabled={!file} sx={{width:"40%"}} startIcon={<FileUploadIcon />} variant="contained" component="label" onClick={handleUpload}>
                        Subir archivo(s)
                    </Button>
                </Stack>
                
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={() => handleCloseModalSC()}>Cerrar</Button>
                {detalle && (<LoadingButton loading={loadingSNC} variant="contained" color="info" loadingIndicator="Enviando…" startIcon={<SaveIcon/> } onClick={()=>Confirm(idCancelation, 'Finanzas-Comprobante', 1)} >Confirmar</LoadingButton>)}
            </DialogActions>
        </Dialog>

        <Toast open={openAlert} message={confalert.mensaje} type={confalert.severidad} onClose={handleCloseAlert} />
    </>);
}


export default CancelacionesDataTable;