import axios from "axios";

/**
 * GET
**/

const getOrdersIndex = async (currentPage) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/orders/orders/all/new?page=${currentPage}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error("getOrdersIndex", error);
  }
};

const searchOrders = async (currentPage, search) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/orders/orders/all/search?page=${currentPage}&search=${search}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error("searchOrders", error);
  }
};

const getDetails = async (id) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/orders/orders/all/details?id=${id}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error("getDetails", error);
  }
};

const getGuides = async (id, currentPage) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/orders/orders/all/guias?id=${id}&page=${currentPage}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error("getGuides", error);
  }
};

const getOrderDetails = async (id, currentPage) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/orders/orders/all/orderdetails?id=${id}&page=${currentPage}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error("getOrderDetails", error);
  }
};

const getDeliveryAddress = async (id) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/orders/orders/all/deliveryaddress?id=${id}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error("getDeliveryAddress", error);
  }
};

const getAddressHistory = async (id) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/orders/orders/all/addresshistory?id=${id}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error("getAddressHistory", error);
  }
};

const getComments = async ( id ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/orders/orders/all/getcomments?id=${id}`;
    try {
        const res = await axios(URL);
        return res.data?.success ? res.data : [];
    } catch (error) {
        console.error("getComments", error);
    }
};

const getCountries = async () => {
    const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/orders/orders/all/countries`;
    try {
        const res = await axios(URL);
        return res.data?.success ? res.data : [];
    } catch (error) {
        console.error("getCountries", error);
    }
};

const getAllGuides = async () => {
    const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/orders/orders/all/allguides`;
    try {
        const res = await axios(URL);
        return res.data?.success ? res.data : [];
    } catch (error) {
        console.error("getAllGuides", error);
    }
};

const getCarries = async () => {
    const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/orders/orders/all/carries`;
    try {
        const res = await axios(URL);
        return res.data?.success ? res.data : [];
    } catch (error) {
        console.error("getCarries", error);
    }
};

const getAllServices = async () => {
    const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/orders/orders/all/allservices`;
    try {
        const res = await axios(URL);
        return res.data?.success ? res.data : [];
    } catch (error) {
        console.error("getAllServices", error);
    }
};

const getHRiskDetails = async ( id ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/orders/orders/details/hrisk?id=${id}`;
    try {
        const res = await axios(URL);
        return res.data?.success ? res.data : [];
    } catch (error) {
        console.error("getHRiskDetails", error);
    }
};

const getBackOrders = async ( id, currentPage ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/orders/orders/details/backorders?id=${id}&page=${currentPage}`;
    try {
        const res = await axios(URL);
        return res.data?.success ? res.data : [];
    } catch (error) {
        console.error("getBackOrders", error);
    }
};

const getDetailsException = async ( id ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/orders/orders/details/exception?id=${id}`;
    try {
        const res = await axios(URL);
        return res.data?.success ? res.data : [];
    } catch (error) {
        console.error("getDetailsException", error);
    }
};

const getAclarations = async ( id, currentPage ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/orders/orders/details/aclarations?id=${id}&page=${currentPage}`;
    try {
        const res = await axios(URL);
        return res.data?.success ? res.data : [];
    } catch (error) {
        console.error("getBackOrders", error);
    }
};

const getIncidence = async ( id, currentPage ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/orders/orders/details/incidence?id=${id}&page=${currentPage}`;
    try {
        const res = await axios(URL);
        return res.data?.success ? res.data : [];
    } catch (error) {
        console.error("getIncidence", error);
    }
};

const getVerifications = async ( id, currentPage ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/orders/orders/details/verifications?id=${id}&page=${currentPage}`;
    try {
        const res = await axios(URL);
        return res.data?.success ? res.data : [];
    } catch (error) {
        console.error("getVerifications", error);
    }
};

const getInvoice = async ( increment_id ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/orders/orders/all/invoice?increment_id=${increment_id}`;
    try {
        const res = await axios(URL);
        return res.data?.success ? res.data : [];
    } catch (error) {
        console.error("getInvoice", error);
    }
};

/**
 * POST
**/

const saveComment = async ( details ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/orders/order/comment/save`;
    try {
        const res = await axios.post(URL, { details });
        return res.data?.success ? true : false;
    } catch (error) {
        console.error("saveComment", error);
    }
};

const updateDirections = async ( details ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/orders/order/directions/save`;
    try {
        const res = await axios.post(URL, details );
        return res.data?.success ? true : false;
    } catch (error) {
        console.error("updateDirections", error);
    }
};

const insertGuide = async ( details ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/orders/order/guides/save`;
    try {
        const res = await axios.post(URL, details );
        return res.data?.success ? true : false;
    } catch (error) {
        console.error("insertGuide", error);
    }
};

const deleteGuide = async ( details ) => {
    const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/orders/order/guide/delete`;
    try {
        const res = await axios.post(URL,  details );
        return res.data?.success ? true : false;
    } catch (error) {
        console.error("deleteGuide", error);
    }
};

/**
 * EXCEL EXPORT
**/

const getExcelRequest = async (initDate, endDate) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/orders/orders/all/manifiestoxls?initDate=${initDate}&endDate=${endDate}`;
  try {
    let res = await axios(URL);
    console.log(res);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach(element => {
      let aux2 = {};
      
      aux2 = {
        "Pedidos": element.increment_id,
        "Num Guia": "'" + element.guia,
        "Num Guia Corto": "'" + element.guia.substring(((element.guia.length) - 10), element.guia.length),
        "Codigo Rastreo": element.rastreo,
        "Estatus": element.estatus,
        "Destino": element.destino,
        "Siglas Origen": element.siglasOrigen,
        "Peso": element.total_weight,
        "Fecha Entrega": element.fechaEntrega,
        "Destinatario": element.referencia,
        "Fecha de recoleccion": element.fechaRecoleccion,
        "Fecha de pago": element.payment_date,
        "V Guia": element.tipificacion,
        "Tipo de Guia": element.nombre_tipo,
        "Observacion": element.observaciones_elimine,
        "Servicio": element.servicio,
        "Carrier": element.carrier,
        "Estatus Carrier": element.EstatusCarrier,
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error("getExcelRequest", error);
  }
};

const getExcelRequestRemision = async (initDate, endDate) => {
  const URL = `${process.env.REACT_APP_BASEURL}/trazabilidad/orders/orders/all/remisionxls?initDate=${initDate}&endDate=${endDate}`;
  try {
    let res = await axios(URL);
    console.log(res);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach(element => {
      let aux2 = {};
      
      aux2 = {
        "Colegio": element.school,
        "SKU": "'" + element.product_sku,
        "Descripcion": element.product_name,
        "Cantidad": element.quantity,
        "No. Pedido": element.increment_id,
        "Cliente": element.customer_name,
        "Id Categoria": element.attributes_id,
        "Categoria": element.attributes,
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error("getExcelRequestRemision", error);
  }
};

export {
  getOrdersIndex, searchOrders, getExcelRequest, getDetails, getGuides, getOrderDetails,
  getDeliveryAddress, getAddressHistory, getExcelRequestRemision, getComments, getHRiskDetails,
  getBackOrders, getDetailsException, getAclarations, getIncidence, getVerifications, insertGuide,
  getCountries, getAllGuides, getCarries, getAllServices, saveComment, updateDirections, deleteGuide, getInvoice

};