import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import DateInTable from "../../../general/DateInTable";

const InformacionRCE = ({ inforRCE }) => {
  const { requerimiento, empresa } = inforRCE;

  function encodeUtf8(val) {
    try {
      return decodeURIComponent(escape(val));
    } catch (err) {
      return val;
    }
  }

  let etiqueta;
  let color;

  if (
    !requerimiento.cat_nombre ||
    requerimiento.cat_nombre === "En proceso de validación"
  ) {
    etiqueta = requerimiento.cat_nombre || "S/Estatus";
    color = "warning";
  } else if (requerimiento.cat_nombre === "Creado") {
    etiqueta = requerimiento.cat_nombre;
    color = "info";
  } else if (
    requerimiento.cat_nombre === "Terminado" ||
    requerimiento.cat_nombre === "Validado" ||
    requerimiento.cat_nombre === "Cotizado"
  ) {
    etiqueta = requerimiento.cat_nombre;
    color = "success";
  } else {
    etiqueta = requerimiento.cat_nombre;
    color = "error";
  }

  return (
    <Grid item md={4}>
      <Box>
        <Box
          sx={{
            border: "1px solid #dedede",
            borderTop: "3px solid #d2d6de",
            margin: "10px",
            marginBottom: "25px",
            borderRadius: "5px",
          }}
        >
          <Box sx={{ borderBottom: "1px solid #dedede" }}>
            <Typography
              variant="h6"
              sx={{
                fontSize: "1.18rem",
                margin: "7px",
                marginLeft: "12px",
              }}
            >
              Requerimiento {requerimiento.numero_requerimiento}
            </Typography>
          </Box>
          <Box>
            <Table size="small">
              <TableBody>
                <TableRow sx={{ backgroundColor: "#0000000a" }}>
                  <TableCell align="left">
                    <Typography variant="subtitle2">Fecha de alta:</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      component="div"
                      sx={{ h6: { fontWeight: "normal" } }}
                    >
                      <DateInTable dateString={requerimiento.fecha_alta} />
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">
                    <Typography variant="subtitle2">Estatus:</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      color={color}
                      size="small"
                      sx={{
                        textTransform: "none",
                        lineHeight: 1,
                        fontSize: "0.7rem",
                        minWidth: 0,
                      }}
                    >
                      {etiqueta}
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow sx={{ backgroundColor: "#0000000a" }}>
                  <TableCell align="left">
                    <Typography variant="subtitle2">
                      Fecha en validación:
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    {requerimiento.fecha_en_validacion ? (
                      <Typography
                        component="div"
                        sx={{ h6: { fontWeight: "normal" } }}
                      >
                        <DateInTable
                          dateString={requerimiento.fecha_en_validacion}
                        />
                      </Typography>
                    ) : (
                      "S/Fecha"
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">
                    <Typography variant="subtitle2">Fecha validado:</Typography>
                  </TableCell>
                  <TableCell align="right">
                    {requerimiento.fecha_validacion ? (
                      <Typography
                        component="div"
                        sx={{ h6: { fontWeight: "normal" } }}
                      >
                        <DateInTable
                          dateString={requerimiento.fecha_validacion}
                        />
                      </Typography>
                    ) : (
                      "S/Fecha"
                    )}
                  </TableCell>
                </TableRow>
                <TableRow sx={{ backgroundColor: "#0000000a" }}>
                  <TableCell align="left">
                    <Typography variant="subtitle2">
                      Fecha último movimiento::
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    {requerimiento.fecha_ultimo_movimiento ? (
                      <Typography
                        component="div"
                        sx={{ h6: { fontWeight: "normal" } }}
                      >
                        <DateInTable
                          dateString={requerimiento.fecha_ultimo_movimiento}
                        />
                      </Typography>
                    ) : (
                      "S/Fecha"
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Box>
        <Box
          sx={{
            border: "1px solid #dedede",
            borderTop: "3px solid #d2d6de",
            margin: "10px",
            marginBottom: "25px",
            borderRadius: "5px",
          }}
        >
          <Box sx={{ borderBottom: "1px solid #dedede" }}>
            <Typography
              variant="h6"
              sx={{
                fontSize: "1.18rem",
                margin: "7px",
                marginLeft: "12px",
              }}
            >
              Contacto
            </Typography>
          </Box>
          <Box>
            <Table size="small">
              <TableBody>
                <TableRow sx={{ backgroundColor: "#0000000a" }}>
                  <TableCell align="left">
                    <Typography variant="subtitle2">
                      Nombre contacto:
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    {requerimiento.nombre_completo
                      ? encodeUtf8(requerimiento.nombre_completo)
                      : "S/Nombre"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">
                    <Typography variant="subtitle2">
                      Correo electrónico:
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    {requerimiento.email
                      ? encodeUtf8(requerimiento.email)
                      : "S/Correo electrónico"}
                  </TableCell>
                </TableRow>
                <TableRow sx={{ backgroundColor: "#0000000a" }}>
                  <TableCell align="left">
                    <Typography variant="subtitle2">
                      Fecha en validación:
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    {requerimiento.telefono
                      ? encodeUtf8(requerimiento.telefono)
                      : "S/Teléfono"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Box>
        <Box
          sx={{
            border: "1px solid #dedede",
            borderTop: "3px solid #d2d6de",
            margin: "10px",
            borderRadius: "5px",
          }}
        >
          <Box sx={{ borderBottom: "1px solid #dedede" }}>
            <Typography
              variant="h6"
              sx={{
                fontSize: "1.18rem",
                margin: "7px",
                marginLeft: "12px",
              }}
            >
              Empresa
            </Typography>
          </Box>
          <Box>
            <Table size="small">
              <TableBody>
                <TableRow sx={{ backgroundColor: "#0000000a" }}>
                  <TableCell align="left">
                    <Typography variant="subtitle2">Razon social:</Typography>
                  </TableCell>
                  <TableCell align="right">
                    {empresa.razon_social
                      ? encodeUtf8(empresa.razon_social)
                      : "S/Razon social"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">
                    <Typography variant="subtitle2">RFC:</Typography>
                  </TableCell>
                  <TableCell align="right">
                    {empresa.rfc ? encodeUtf8(empresa.rfc) : "S/RFC"}
                  </TableCell>
                </TableRow>
                <TableRow sx={{ backgroundColor: "#0000000a" }}>
                  <TableCell align="left">
                    <Typography variant="subtitle2">Calle:</Typography>
                  </TableCell>
                  <TableCell align="right">
                    {empresa.calle ? encodeUtf8(empresa.calle) : "S/Calle"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">
                    <Typography variant="subtitle2">Número:</Typography>
                  </TableCell>
                  <TableCell align="right">
                    {empresa.numeroExt
                      ? encodeUtf8(empresa.numeroExt)
                      : "S/Número"}
                  </TableCell>
                </TableRow>
                <TableRow sx={{ backgroundColor: "#0000000a" }}>
                  <TableCell align="left">
                    <Typography variant="subtitle2">Colonia:</Typography>
                  </TableCell>
                  <TableCell align="right">
                    {empresa.colonia
                      ? encodeUtf8(empresa.colonia)
                      : "S/Colonia"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">
                    <Typography variant="subtitle2">Ciudad:</Typography>
                  </TableCell>
                  <TableCell align="right">
                    {empresa.ciudad ? encodeUtf8(empresa.ciudad) : "S/Ciudad"}
                  </TableCell>
                </TableRow>
                <TableRow sx={{ backgroundColor: "#0000000a" }}>
                  <TableCell align="left">
                    <Typography variant="subtitle2">Delegación:</Typography>
                  </TableCell>
                  <TableCell align="right">
                    {empresa.delegacion
                      ? encodeUtf8(empresa.delegacion)
                      : "S/Delegación"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">
                    <Typography variant="subtitle2">Código postal:</Typography>
                  </TableCell>
                  <TableCell align="right">
                    {empresa.cp ? encodeUtf8(empresa.cp) : "S/Código postal"}
                  </TableCell>
                </TableRow>{" "}
                <TableRow sx={{ backgroundColor: "#0000000a" }}>
                  <TableCell align="left">
                    <Typography variant="subtitle2">Estado:</Typography>
                  </TableCell>
                  <TableCell align="right">
                    {empresa.estado ? encodeUtf8(empresa.estado) : "S/Estado"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">
                    <Typography variant="subtitle2">País:</Typography>
                  </TableCell>
                  <TableCell align="right">
                    {empresa.pais ? encodeUtf8(empresa.pais) : "S/País"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default InformacionRCE;
