import { Box, Card, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import { profiles } from "../../../utils/cancelaciones/profilesCancelaciones";
import CancelIcon from '@mui/icons-material/Cancel';
import BreadCrumb from "../../general/BreadCrumb";
import CancelacionesDataTable from "./CancelacionesDataTable";

const itemsBreadCrumbs = [
    {
        label: 'Cancelaciones',
        icon: <CancelIcon sx={{ mr: 0.5 }} fontSize="small" />,
        link: '/cancelaciones'
    }
];

const Cancelaciones = () => {
    const [nextRender, setNextRender] = useState(false);
    const { user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if(!profiles.cancelaciones.includes(user.role.idProfile)){
            navigate("/dashboard", { replace: true });
        }else setNextRender(true);
    }, [user, navigate]);

    return (<>
        {nextRender && (<>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", }} >
                <Typography variant="h4" sx={{ my: 2 }}>Cancelaciones</Typography>
                <Box>
                    <BreadCrumb items={itemsBreadCrumbs} />
                </Box>
            </Box>
            <motion.div initial={{ y: 20 }} animate={{ y: 0 }} transition={{ duration: 0.5 }}>        
            <Card sx={{mb:4}}>          
                <Box>
                    <CancelacionesDataTable/>
                </Box>
            </Card>
        </motion.div>
        </>)}
    </>);
};

export default Cancelaciones;