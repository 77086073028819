import axios from "axios";

/**
 * GET
 */
function formatNumber(num) {
  return num.toLocaleString("en-US");
}

function getArrayWithoutRepeatingElements(arr) {
  let arrayAux = [];
  let keyAux = null;
  for (let i = 0; i < arr.length; i++) {
    if (keyAux !== arr[i]) {
      arrayAux.push(arr[i]);
    }
    keyAux = arr[i];
  }
  return arrayAux;
}

const getDataForCSV = async (id, tInfo) => {
  const URL = `${process.env.REACT_APP_BASEURL}/proveedores/asignaroc/orders/data/get?id=${id}&csv=${tInfo}`;
  try {
    let res = await axios(URL);
    res = res.data?.success ? res.data.data : [];
    let aux = { data: [] };
    let columns = [];
    if (tInfo == "items") {
      columns = [
        "Increment Id",
        "Sku",
        "Category",
        "Type",
        "Text",
        "Color",
        "Font",
        "Icon",
        "School",
        "Gender",
        "Shape",
        "Size",
        "Price",
        "Qty",
      ];

      res.forEach((element) => {
        if (element.icon == null) {
          element.icon = "Sin icono";
        }

        let aux2 = {
          "Increment Id": element.increment_id,
          Sku: `'${element.sku}`,
          Category: element.category,
          Type: element.type,
          Text: element.text,
          Color: element.color,
          Font: element.font,
          Icon: element.icon,
          School: element.school,
          Gender: element.gender,
          Shape: element.shape,
          Size: element.size,
          Price: element.price,
          Qty: element.qty,
        };
        aux.data.push(aux2);
      });
    } else {
      columns = ["SKU", "Nombre del producto", "Cantidad", "Precio"];

      let array_orden = [];
      res.forEach((element) => {
        array_orden.push(element.increment_id);
        let aux2 = {
          SKU: `'${element.product_sku}`,
          "Nombre del producto": element.product_name,
          Cantidad: element.quantity,
          Precio: element.price ? formatNumber(element.price) : 0,
        };
        aux.data.push(aux2);
      });
      let ordencompras = getArrayWithoutRepeatingElements(array_orden);
      let cadena = "";

      for (let i = 0; i < ordencompras.length; i++) {
        if (i == ordencompras.length - 1) {
          cadena += ordencompras[i];
        } else {
          cadena += ordencompras[i] + ", ";
        }
      }
      aux.data.push({cadena});
    }

    return { data: aux.data, columns: columns };
  } catch (error) {
    console.error(error);
  }
};

export { getDataForCSV };
