import { profiles } from './profilesAltoRiesgo';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
const dicon = <RadioButtonUncheckedIcon fontSize='small' />;

export const altoRiesgoModules = [
    {
        label: "Inicio",
        icon: dicon,
        slug: "inicio",
        profiles: profiles.inicio
    },
    {
        label: "Órdenes",
        icon: dicon,
        slug: "ordenes",
        profiles: profiles.ordenes
    },
    {
        label: "Alto Riesgo",
        icon: dicon,
        slug: "alto",
        profiles: profiles.altoRiesgo
    },
    {
        label: "Blacklist",
        icon: dicon,
        slug: "blacklist",
        profiles: profiles.blacklist
    },
    {
        label: "Notificaciones",
        icon: dicon,
        slug: "notificaciones",
        profiles: profiles.notificaciones
    },
    {
        label: "Contracargos",
        icon: dicon,
        slug: "contracargos",
        profiles: profiles.contracargos
    },
    {
        label: "Complementos",
        icon: dicon,
        slug: "complementos",
        profiles: profiles.complementos
    },
    {
        label: "Atención a clientes",
        icon: dicon,
        slug: "atencion-clientes",
        profiles: profiles.atencionC
    },
    {
        label: "Logística",
        icon: dicon,
        slug: "logistica",
        profiles: profiles.logistica
    },
    {
        label: "Blacklist nombres",
        icon: dicon,
        slug: "blacklist-nombres",
        profiles: profiles.blacklistNom
    },
];