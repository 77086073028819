import axios from "axios";

/**
 * GET
 **/

const getStock = async (currentPage, idUser) => {
  const URL = `${process.env.REACT_APP_BASEURL}/proveedores/ventas/stock/all/new?page=${currentPage}&idUser=${idUser}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const getSumStock = async (idUser) => {
  const URL = `${process.env.REACT_APP_BASEURL}/proveedores/ventas/stock/all/count?idUser=${idUser}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const searchStock = async (idUser, search) => {
  const encodedsearch = encodeURIComponent(search);
  const URL = `${process.env.REACT_APP_BASEURL}/proveedores/ventas/stock/search?page=All&idUser=${idUser}&search=${encodedsearch}`;
  try {
    const res = await axios(URL);
    return res.data?.success ? res.data : [];
  } catch (error) {
    console.error(error);
  }
};

const genExlStock = async (idUser, search) => {
  let URL = "";
  if (search) {
    const encodedsearch = encodeURIComponent(search);
    URL = `${process.env.REACT_APP_BASEURL}/proveedores/ventas/stock/search?page=All&idUser=${idUser}&search=${encodedsearch}`;
  } else {
    URL = `${process.env.REACT_APP_BASEURL}/proveedores/ventas/stock/search?page=All&idUser=${idUser}`;
  }

  function formatNumber(num) {
    return num.toLocaleString("en-US");
  }

  try {
    let res = await axios(URL);
    res = res.data?.success ? res.data.data : [];
    let aux = [];
    res.forEach((element) => {
      let aux2 = {
        SKU: element.SKU,
        Nombre: element.Nombre,
        Stock: element.Stock,
        Valor: element.Valor ? formatNumber(element.Valor) : element.Valor,
        Almacén: element.Almacen,
        Inventariable: element.Inventariable,
      };
      aux.push(aux2);
    });

    return aux;
  } catch (error) {
    console.error(error);
  }
};

export { getStock, getSumStock, searchStock, genExlStock };
