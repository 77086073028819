import { Box, LinearProgress, Typography, Table, TableCell, TableContainer, TableRow, Grid  } from "@mui/material";
import { useEffect, useState } from "react";
import { getTabInternalLogs } from "../../../../utils/cancelaciones/cancelaciones/cancelaciones";
import TableHead from '@mui/material/TableHead';
import local from "dayjs/plugin/localizedFormat";
import relative from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";

dayjs.locale("es-mx");
dayjs.extend(local);
dayjs.extend(relative);
dayjs.extend(utc);

const InternalLogsTab = ({ id }) => { 
    const [isLoading, setIsLoading] = useState(false);
    const [detalle, setDetalle] = useState(null);

    const getInternalLogs = async () => {
        try {
            setIsLoading(true);
            const res = await getTabInternalLogs (id);
            setDetalle(res);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const nombreCompleto = (nombre, apellidoP, apellidoM) => {
        const usuario = [];
        if (nombre) {
            usuario.push(nombre + " ");
        }
        if (apellidoP) {
            usuario.push(apellidoP + " ");
        }
        if (apellidoM) {
            usuario.push(apellidoM);
        }
        return (
            usuario
        );
    };

    useEffect(() => {
        getInternalLogs();
    }, [id]);

    return (<>
        <Box sx={{ mb: 2, ml: 2, mr: 2 }}>
            {isLoading && <LinearProgress />}
            <Grid container sx={{ mt: 2, mb: 2 }}>
                {detalle && (<>
                    <Grid item md={12}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="subtitle2">Fecha</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="subtitle2">Usuario</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="subtitle2">LOG</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {detalle.data.map(({ created_atl, nombre, apellidoP, apellidoM, log}) => (
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="p">{dayjs.utc(created_atl).format("DD MMM YYYY, hh:mm A")}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="p">{nombreCompleto(nombre, apellidoP, apellidoM)}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="p">{log}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    ))}
                            </Table>
                        </TableContainer>
                    </Grid>
                </>)}
            </Grid>
        </Box>
    </>);
};

export default InternalLogsTab;