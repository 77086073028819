import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import { LoadingButton } from "@mui/lab";

const AdvShearch = ({ advancedS, handleChangeAdvancedS, setIsAF }) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Busqueda avanzada</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              type={"date"}
              name="initDate"
              label="Fecha de alta"
              variant="outlined"
              value={advancedS.endDate}
              onChange={handleChangeAdvancedS}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              type={"date"}
              name="endDate"
              label="Fecha de alta"
              variant="outlined"
              value={advancedS.endDate}
              onChange={handleChangeAdvancedS}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              name="ordenCompra"
              label="Orden de compra"
              variant="outlined"
              value={advancedS.ordenCompra}
              onChange={handleChangeAdvancedS}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              name="numeroEntrada"
              label="Número de entrada"
              variant="filled"
              value={advancedS.numeroEntrada}
              onChange={handleChangeAdvancedS}
            />
          </Grid>
          <Grid item xs={8}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth variant="filled">
                  <InputLabel id="tov">Estatus requerimiento</InputLabel>
                  <Select
                    labelId="tov"
                    // value={}
                    // onChange={}
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  name="ordenVenta"
                  label="Orden de venta"
                  variant="filled"
                  value={advancedS.ordenVenta}
                  onChange={handleChangeAdvancedS}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={8}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  name="producto"
                  label="Producto"
                  variant="filled"
                  value={advancedS.producto}
                  onChange={handleChangeAdvancedS}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  name="sku"
                  label="SKU"
                  variant="filled"
                  value={advancedS.sku}
                  onChange={handleChangeAdvancedS}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <div style={{ textAlign: "right", marginTop: "2em" }}>
              <LoadingButton
                startIcon={<SearchIcon />}
                sx={{ mx: 2 }}
                // loading={isLoading}
                size="medium"
                variant="contained"
                color="success"
                onClick={() => setIsAF(true)}
                disableElevation
              >
                Consultar
              </LoadingButton>
            </div>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default AdvShearch;
