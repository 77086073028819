import React, { useEffect, useState } from "react";
import { getSalesFinal } from "../../../utils/proveedores/ventas/ventas";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

function DetailPanelContent({ row: rowProp }) {
  return (
    <Stack
      sx={{ py: 2, height: "100%", boxSizing: "border-box" }}
      direction="column"
    >
      <Paper sx={{ flex: 1, mx: "auto", width: "90%", p: 1 }}>
        <Stack direction="column" spacing={1} sx={{ height: 1 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ textAlign: "center" }}>
                  Access code 1
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  Access code 2
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  Group_of_colleges
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ border: 0 }}>
                <TableCell sx={{ width: "40%", textAlign: "center" }}>
                  {rowProp.access1}
                </TableCell>
                <TableCell sx={{ width: "30%", textAlign: "center" }}>
                  {rowProp.access2}
                </TableCell>
                <TableCell sx={{ width: "30%", textAlign: "center" }}>
                  {rowProp.GrupoColegios}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Stack>
      </Paper>
    </Stack>
  );
}

const SalesFinal = ({
  open,
  startDate,
  endDate,
  idNivel,
  idGrade,
  idColegio,
}) => {
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);

  const columns = [
    {
      field: "sale",
      headerName: "Pedido",
      flex: 0.8,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => `${params.row.Pedido}`,
    },
    {
      field: "sku",
      headerName: "Sku",
      flex: 0.9,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => `${params.row.Sku}`,
    },
    {
      field: "product",
      headerName: "Producto",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => `${params.row.Producto}`,
    },
    {
      field: "quantity",
      headerName: "Cantidad",
      flex: 0.6,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => `${params.row.Cantidad}`,
    },
    {
      field: "taxrate",
      headerName: "Taxrate",
      flex: 0.7,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => `${params.row.Impuestos}`,
    },
    {
      field: "costogea",
      headerName: "Costogea",
      flex: 0.8,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => `${params.row.ValorStock}`,
    },
    {
      field: "category",
      headerName: "Categoria",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => `${params.row.Categoria}`,
    },
    {
      field: "student",
      headerName: "Alumno",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => `${params.row.Alumno}`,
    },
  ];

  if (
    document.querySelector(
      ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
    )
  )
    document
      .querySelector(
        ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
      )
      .remove();

  useEffect(() => {
    if (open) {
      (async () => {
        setLoading(true);
        let sales = await getSalesFinal(
          page,
          23,
          startDate,
          endDate,
          idNivel,
          idGrade,
          idColegio
        );
        console.log(sales);
        setTotal(sales.total);
        setRows(sales.data);
        setLoading(false);
      })();
    }
  }, [open, page]);

  return (
    <Box style={{ height: 635, width: "100%" }}>
      <DataGridPro
        rows={rows}
        columns={columns}
        pagination
        pageSize={10}
        rowsPerPageOptions={[10]}
        getRowId={(row) => row.id_grid}
        rowCount={total}
        paginationMode="server"
        onPageChange={(newPage) => {
          setPage(newPage);
        }}
        selectionModel={selectionModel}
        loading={loading}
        disableColumnMenu
        disableSelectionOnClick
        getDetailPanelHeight={() => "auto"}
        getDetailPanelContent={({ row }) => <DetailPanelContent row={row} />}
      />
    </Box>
  );
};

export default SalesFinal;
