import React, { useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  createUserRH,
  validateMailRH,
} from "../../../utils/vales/configuracion/configuracion";

const ModalUserRHCreate = ({
  isOpen,
  onClose,
  updateUsr,
  setLoading,
  setRows,
  setEstado,
}) => {
  const [user, setUser] = useState({
    nombre: "",
    apellidoP: "",
    apellidoM: "",
    mail: "",
    pass: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return re.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setErrors({});

    let validationErrors = {};

    Object.keys(user).forEach((key) => {
      if (user[key].length <= 3) {
        validationErrors[key] = `${
          key.charAt(0).toUpperCase() + key.slice(1)
        } debe tener más de 3 caracteres`;
      }
    });

    if (!validateEmail(user.mail)) {
      validationErrors.mail = "Introduce un correo válido";
    }

    if (Object.keys(validationErrors).length === 0) {
      const dataToSend = {
        ...user,
        updateUsr: updateUsr,
      };
      setLoading(true);
      setRows([]);
      onClose();
      await createUserRH(dataToSend);
      setEstado((prevEstado) => (prevEstado === 0 ? 1 : 0));
      setUser({
        nombre: "",
        apellidoP: "",
        apellidoM: "",
        mail: "",
        pass: "",
      });
    } else {
      setErrors(validationErrors);
    }
  };

  const verifyMail = async () => {
    if (user.mail.length <= 3) {
      setErrors({
        ...errors,
        mail: "El correo electrónico debe tener más de 3 caracteres",
      });
      return;
    }

    if (!validateEmail(user.mail)) {
      setErrors({ ...errors, mail: "Introduce un correo válido" });
      return;
    }

    let validatefetch = await validateMailRH(user.mail);
    let isTrue = validatefetch.data;

    if (isTrue) {
      setErrors({ ...errors, mail: "El correo ya existe" });
      return;
    }

    if (!isTrue) {
      setErrors({ ...errors, mail: "" });
      return;
    }
  };

  const onCloseAndClear = () => {
    onClose();
    setUser({
      nombre: "",
      apellidoP: "",
      apellidoM: "",
      mail: "",
      pass: "",
    });
    setErrors({});
  };

  return (
    <Dialog open={isOpen} onClose={onCloseAndClear}>
      <DialogTitle>Agregar Usuario RH</DialogTitle>
      <DialogContent>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="nombre"
          label="Nombre"
          name="nombre"
          autoFocus
          value={user.nombre}
          onChange={handleChange}
          error={!!errors.nombre}
          helperText={errors.nombre}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="apellidoP"
          label="Apellido Paterno"
          name="apellidoP"
          value={user.apellidoP}
          onChange={handleChange}
          error={!!errors.apellidoP}
          helperText={errors.apellidoP}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="apellidoM"
          label="Apellido Materno"
          name="apellidoM"
          value={user.apellidoM}
          onChange={handleChange}
          error={!!errors.apellidoM}
          helperText={errors.apellidoM}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="mail"
          label="Correo Electrónico"
          name="mail"
          value={user.mail}
          onChange={handleChange}
          onBlur={verifyMail}
          error={!!errors.mail}
          helperText={errors.mail}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="pass"
          label="Contraseña"
          type="password"
          id="pass"
          value={user.pass}
          onChange={handleChange}
          error={!!errors.pass}
          helperText={errors.pass}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseAndClear} color="error" variant="outlined">
          Cancelar
        </Button>
        <Button onClick={handleSubmit} color="success" variant="outlined">
          Agregar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalUserRHCreate;
