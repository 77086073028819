import { Box, Grid, Button, Stack, TextField, Chip, Typography,  Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useEffect, useState } from "react";
import { useAuth } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getExcelRequest, getExcelRequestRemision, getOrdersIndex, searchOrders } from "../../../utils/trazabilidad/pedidos/pedidos";
import ExcelFile from "../../general/ExcelFile";

import local from "dayjs/plugin/localizedFormat";
import relative from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";

import VisibilityIcon from '@mui/icons-material/Visibility';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ListIcon from '@mui/icons-material/List';

dayjs.locale("es-mx");
dayjs.extend(local);
dayjs.extend(relative);
dayjs.extend(utc);

const chipColors = {
    "canceled": "#e53935",
    "complete": "#43a047",
    "completed": "#43a047",
    "entregado": "#43a047",
    "exception": "#ffa000",
    "paid": "#43a047",
    "pending": "#ffa000",
    "in_route": "#ffa000",
    "today": "#8e24aa",
    "closed": "#e53935",
    "stocking": "#3c8dbc",
};

const chipLabels = {
    "canceled": "Cancelado",
    "complete": "Completado",
    "completed": "Completado",
    "entregado": "Entregado",
    "exception": "Excepción",
    "paid": "Pagado",
    "pending": "Pendiente",
    "in_route": "En Ruta",
    "today": "Hoy",
    "closed": "Cerrado",
    "stocking": "Stocking",
};

const PedidosDataTable = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [keyWord, setKeytoSearch] = useState("");
    const [excelData, setExcelData] = useState(null);

    const [loading, setLoading] = useState(false); //Loading Index
    const [isELoading, setIsELoading] = useState(false); //Loading Excel

    const [auxInitDate, setAuxInitDate] = useState("");
    const [auxEndDate, setAuxEndDate] = useState("");

    const [openModalManifiesto, setOpenModalManifiesto] = useState(null);

    const handleOpenModalManifiesto = () => {
        setOpenModalManifiesto(true);
    };

    const handleCloseModalManifiesto = () => {
        setAuxInitDate("");
        setAuxEndDate("");
        setExcelData(null);
        setOpenModalManifiesto(false);
    };

    const [openModalRemision, setOpenModalRemision] = useState(null);

    const handleOpenModalRemision = () => {
        setOpenModalRemision(true);
    };

    const handleCloseModalRemision = () => {
        setAuxInitDate("");
        setAuxEndDate("");
        setExcelData(null);
        setOpenModalRemision(false);
    };

    const getAllExcelData = async () => {
        try {
            setIsELoading(true);
            const data = await getExcelRequest(auxInitDate, auxEndDate);
            setExcelData(data);
        } catch (error) {
            console.error(error);
        } finally {
            setIsELoading(false);
        }
    };

    const getAllExcelDataRemision = async () => {
        try {
            setIsELoading(true);
            const data = await getExcelRequestRemision (auxInitDate, auxEndDate);
            setExcelData(data);
        } catch (error) {
            console.error(error);
        } finally {
            setIsELoading(false);
        }
    };

    const columns = [
        {
            field: "increment_id", headerName: "No. Pedido", flex: 0.8, align: "center", headerAlign: "center",
        },
        {
            field: "noGuias", headerName: "No. de Guias", flex: 0.8, align: "center", headerAlign: "center",
            renderCell: (params) => (<>
                {params.row.no_guias === null ? <> 0 </> : <> {params.row.no_guias} </>}
            </>)
        },
        {
            field: "fechaPago", headerName: "Fecha de Pago", flex: 0.9, align: "center", headerAlign: "center",
            renderCell: (params) => (<>
                {dayjs.utc(params.row.payment_date).format("DD/MM/YYYY")}
            </>)
        },
        {
            field: "nombre", headerName: "Nombre del cliente", flex: 1.9, align: "center", headerAlign: "center",
            renderCell: (params) => (<>
                {params.row.customer_name + " " + params.row.customer_lastname + " " + params.row.customer_mothersname}
            </>)
        },
        {
            field: "method_name", headerName: "Metodo de pago", flex: 1.1, align: "center", headerAlign: "center",
        },
        {
            field: "costodeenvio", headerName: "Costo de envio", flex: 0.9, align: "center", headerAlign: "center",
            renderCell: (params) => (<>
                {"$ " + params.row.shipping_amount}
            </>)
        },
        {
            field: "estatus", headerName: "Status", flex: 0.9, align: "center", headerAlign: "center",
            renderCell: (params) => (<>
                <Chip label={chipLabels[params.row.status]} size="small" sx={{ backgroundColor: chipColors[params.row.status], color: "#fff", }} />
            </>)
        },
        {
            field: "accion", headerName: "Acción", flex: 0.7, align: "center", headerAlign: "center",
            renderCell: (params) => (<>
                <Button variant="contained" size="small" disableElevation onClick={() => navigate(`detail/${params.row.increment_id}`)}>Detalles</Button>
            </>)
        },
    ];

    if (document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"))
        document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']").remove();

    useEffect(() => {
        let active = true;
        (async () => {
            let newRows = null;
            if (typeof keyWord === 'undefined' || keyWord === undefined || keyWord.length === 0) {
                setLoading(true);
                newRows = await getOrdersIndex(page);
            }
            else if (keyWord.length >= 1) {
                setLoading(true);
                newRows = await searchOrders(page, keyWord);
            }
            else active = false;
            if (!active) {
                return;
            }
            setTotal(newRows.total);
            setRows(newRows.data);
            setLoading(false);
        })();
        return () => {
            active = false;
        };
    }, [page, keyWord, user]);

    return (<><Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
            <Grid container sx={{ my: 2 }}>
                <Grid item md={4} sx={{ px: 2 }}>
                    <Grid>
                        <Stack direction="row" spacing={1}>
                            <TextField size="small" variant="outlined" fullWidth label="Buscar pedido..." value={keyWord} onChange={(e) => setKeytoSearch(e.target.value)} />
                        </Stack>
                    </Grid>
                </Grid>
                <Grid item md sx={{ px: 2, textAlign: 'right' }}>
                    <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center">
                        <LoadingButton startIcon={<ListAltIcon/>} variant="contained" color="info" onClick={() => handleOpenModalManifiesto()}>Manifiesto</LoadingButton>
                        <LoadingButton startIcon={<ListIcon/>} variant="contained" color="info" onClick={() => handleOpenModalRemision()}>Remisión</LoadingButton>
                    </Stack>
                </Grid>
            </Grid>
        </Box>
        <Box style={{ height: 631, width: '100%' }}>
            <DataGridPro
                rows={rows}
                columns={columns}
                pagination
                pageSize={10}
                rowsPerPageOptions={[10]}
                getRowId={((row) => (row.increment_id))}
                rowCount={total}
                paginationMode="server"
                onPageChange={(newPage) => {
                    setPage(newPage);
                }}
                loading={loading}
                disableColumnMenu
                disableSelectionOnClick
            />
        </Box>
    </Box>
        <Dialog open={openModalManifiesto} onClose={handleCloseModalManifiesto}>
            <DialogTitle>
                <Typography><strong>Generar Manifiesto XLS</strong></Typography>
            </DialogTitle>
            <DialogContent>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                        components={['DatePicker']}
                    >
                        <DemoItem label="Fecha Inicio">
                            <DatePicker onChange={(newValue) => setAuxInitDate(dayjs.utc(newValue).format("YYYY-MM-DD"))} />
                        </DemoItem>
                        <DemoItem label="Fecha Fin:">
                            <DatePicker onChange={(newValue) => setAuxEndDate(dayjs.utc(newValue).format("YYYY-MM-DD"))} />
                        </DemoItem>
                    </DemoContainer>
                </LocalizationProvider>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={() => handleCloseModalManifiesto()}>Cerrar</Button>
                {!excelData && <LoadingButton loading={isELoading} variant="contained" color="info" onClick={() => getAllExcelData()}>Generar excel</LoadingButton>}
                {(excelData && <ExcelFile excelData={excelData} fileName={`Manifiesto ` + auxInitDate + ' - ' + auxEndDate} />)}
            </DialogActions>
        </Dialog>

        <Dialog open={openModalRemision} onClose={handleCloseModalRemision}>
            <DialogTitle>
                <Typography><strong>Generar Remision XLS</strong></Typography>
            </DialogTitle>
            <DialogContent>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                        components={['DatePicker']}
                    >
                        <DemoItem label="Fecha Inicio">
                            <DatePicker onChange={(newValue) => setAuxInitDate(dayjs.utc(newValue).format("YYYY-MM-DD"))} />
                        </DemoItem>
                        <DemoItem label="Fecha Fin:">
                            <DatePicker onChange={(newValue) => setAuxEndDate(dayjs.utc(newValue).format("YYYY-MM-DD"))} />
                        </DemoItem>
                    </DemoContainer>
                </LocalizationProvider>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={() => handleCloseModalRemision()}>Cerrar</Button>
                {!excelData && <LoadingButton loading={isELoading} variant="contained" color="info" onClick={() => getAllExcelDataRemision()}>Generar excel</LoadingButton>}
                {(excelData && <ExcelFile excelData={excelData} fileName={`Remision ` + auxInitDate + ' - ' + auxEndDate} />)}
            </DialogActions>
        </Dialog>
    </>);
}

export default PedidosDataTable;