import { Box, Grid, Button, Chip } from "@mui/material";
import { useEffect, useState, Fragment } from "react";
import { DataGridPro } from '@mui/x-data-grid-pro';
import Paper from '@mui/material/Paper';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import { getVerifications, } from "../../../utils/trazabilidad/pedidos/pedidos";
import { useNavigate } from "react-router-dom";
import local from "dayjs/plugin/localizedFormat";
import relative from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";

dayjs.locale("es-mx");
dayjs.extend(local);
dayjs.extend(relative);
dayjs.extend(utc);

const chipColors = {
    "1": "#e53935",
    "2": "#ffa000",
    "3": "#ffa000",
};

const chipLabels = {
    "1": "Nueva",
    "2": "En proceso",
    "3": "Cerrado",
};

const VerificacionesTab = ({ id }) => {
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [keyWord, setKeytoSearch] = useState("");
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const columns = [

        {
            field: "fecha", headerName: "Fecha", flex: 1.2, align: "center", headerAlign: "center",
            renderCell: (params) => (<>
                {dayjs.utc(params.row.creation_date).format("DD MMM YYYY, hh:mm:ss A")}
            </>)
        },
        {
            field: "tipificacion", headerName: "Tipificación", flex: 1, align: "center", headerAlign: "center",
        },
        {
            field: "Ustatus", headerName: "Último Status", flex: 0.8, align: "center", headerAlign: "center",
            renderCell: (params) => (<>
                {params.row.id_status ? ((params.row.id_status ===  "1" || "2" ||"3") ? <Chip label={chipLabels[params.row.id_status]} size="small" sx={{ backgroundColor: chipColors[params.row.id_status], color: "#fff", }} /> : "" ) : ""}
            </>)
        },
        {
            field: "accion", headerName: "Accion", flex: 0.8, align: "center", headerAlign: "center",
            renderCell: (params) => (<>
                <Button variant="contained" size="small" disableElevation onClick={() => navigate(`/trazabilidad/verificaciones/detail/${params.row.id_verificaciones}`)}>
                    <VisibilityIcon />
                </Button>
            </>)
        },
    ];

    if (document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"))
        document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']").remove();

    useEffect(() => {
        let active = true;
        (async () => {
            let newRows = null;
            if (typeof keyWord === 'undefined' || keyWord === undefined || keyWord.length === 0) {
                setLoading(true);
                newRows = await getVerifications (id, page);
                console.log(newRows);
            }
            else if (keyWord.length >= 1) {
                setLoading(true);
                //newRows = await searchOrders (page, keyWord);
            }
            else active = false;
            if (!active) {
                return;
            }
            setTotal(newRows.total);
            setRows(newRows.data);
            setLoading(false);
        })();
        return () => {
            active = false;
        };
    }, [id]);

    return (<>
        <Box sx={{ p:2 }} style={{ height: 300 }} component={Paper}>
            <DataGridPro
                rows={rows}
                columns={columns}
                pagination
                pageSize={5}
                rowsPerPageOptions={[5]}
                getRowId={((row) => (row.id_verificaciones))}
                rowCount={total}
                paginationMode="server"
                onPageChange={(newPage) => {
                    setPage(newPage);
                }}
                loading={loading}
                disableColumnMenu
                disableSelectionOnClick
            />
        </Box>
    </>);
};

export default VerificacionesTab;