import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { forwardRef, useState } from "react";
import {
  Autocomplete,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  MenuItem,
  TextField,
} from "@mui/material";
import { useDialogs } from "../../context/DialogsContext";
import { useResourses } from "../../context/ResourcesContext";
import { useAuth } from "../../context/AuthContext";
import { createAsesoria } from "../../utils/crm/asesoria";
import BackOfficeOnCrm from "./BackOfficeOnCrm";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function NuevoCasoOnCrmDialog() {
  const [isLoading, setIsLoading] = useState(false);
  const [college, setCollege] = useState(0);
  const [channel, setChannel] = useState("Redes sociales");
  const [typeCase, setTypeCase] = useState(1);
  const [solution, setSolution] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [theme, setTheme] = useState(0);

  const { colleges, channels, typeCases, topics } = useResourses();
  const { user } = useAuth();

  const { setOpenNuevoCasoOnCrmDialog, openNuevoCasoOnCrmDialog } = useDialogs();

  const closeDialog = () => setOpenNuevoCasoOnCrmDialog(false);

  const clearValues = () => {
    setCollege(0);
    setChannel("Redes sociales");
    setTypeCase(1);
    setSolution("");
    setEmail("");
    setPhone("");
  };

  const uploadAsesoria = async () => {
    try {
      await createAsesoria({
        solution,
        email,
        channel,
        idUser: user.data.awsid,
        college,
        theme,
      });
    } catch (error) {}
  };

  const uploadOtro = async () => {
    try {
      await createAsesoria({
        solution,
        email,
        channel,
        idUser: user.data.awsid,
        college,
        theme,
      });
    } catch (error) {}
  };

  const handleCrearTicket = async () => {
    setIsLoading(true);

    try {
      if (!solution || !email || !phone) {
        setIsLoading(false);
        return;
      }

      if (typeCase === 1) {
        await uploadAsesoria();
      }

      if (typeCase === 3) {
        await uploadOtro();
      }

      closeDialog();
      clearValues();
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  return (
    <div>
      <Dialog open={openNuevoCasoOnCrmDialog} onClose={closeDialog} TransitionComponent={Transition} fullScreen>
        {isLoading && <LinearProgress />}
        <DialogTitle>Crear nuevo caso</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                autoFocus
                margin="dense"
                label="Email"
                type="email"
                variant="filled"
                disabled={isLoading}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                margin="dense"
                label="Teléfono"
                variant="filled"
                disabled={isLoading}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Grid>
            <Grid item xs={8}>
              <Autocomplete
                disablePortal
                isOptionEqualToValue={(e) => true}
                options={colleges.map((c) => ({ id: c.id, label: c.name }))}
                onChange={(e, val) => {
                  setCollege(+val.id);
                }}
                renderInput={(params) => <TextField {...params} label="Colegio" variant="filled" />}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                select
                label="Canal"
                value={channel}
                onChange={(e) => setChannel(e.target.value)}
                variant="filled"
                disabled={isLoading}
              >
                {channels &&
                  channels.length &&
                  channels.map((chan) => (
                    <MenuItem key={chan.id_crm_channels} value={chan.channel_name}>
                      {chan.channel_name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Descripción"
                multiline
                variant="filled"
                rows={3}
                disabled={isLoading}
                value={solution}
                onChange={(e) => setSolution(e.target.value)}
              />
            </Grid>
            <Grid item xs={typeCase === 2 ? 12 : 6}>
              <TextField
                select
                label="Tipo de caso"
                value={typeCase}
                onChange={(e) => setTypeCase(e.target.value)}
                variant="filled"
                sx={{
                  width: "100%",
                }}
                disabled={isLoading}
              >
                {typeCases &&
                  typeCases.length &&
                  typeCases.map((tc) => (
                    <MenuItem key={tc.id_crm_typecases} value={tc.id_crm_typecases}>
                      {tc.typecase_name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            {typeCase === 1 && (
              <Grid item xs={6}>
                <Autocomplete
                  disablePortal
                  isOptionEqualToValue={(e) => true}
                  options={topics
                    .filter((t) => t.id_categoria === 3)
                    .map((t) => ({ id: t.id_tema, label: t.nombreTema }))}
                  sx={{
                    width: "100%",
                    mb: 2,
                  }}
                  onChange={(e, val) => {
                    setTheme(+val.id);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Tema" helperText="Selecciona el tema" variant="filled" />
                  )}
                />
              </Grid>
            )}
            {typeCase === 2 && (
              <Grid item xs={12}>
                <BackOfficeOnCrm solution={solution} channel={channel} college={college} email={email} />
              </Grid>
            )}
            {typeCase === 3 && (
              <Grid item xs={6}>
                <Autocomplete
                  disablePortal
                  isOptionEqualToValue={(e) => true}
                  options={topics
                    .filter((t) => [4, 5, 8, 25, 21, 24].includes(t.id_categoria))
                    .map((t) => ({ id: t.id_tema, label: t.nombreTema }))}
                  sx={{
                    width: "100%",
                    mb: 2,
                  }}
                  onChange={(e, val) => {
                    setTheme(+val.id);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Tema" helperText="Selecciona el tema" variant="filled" />
                  )}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        {typeCase !== 2 && (
          <DialogActions>
            <Button onClick={closeDialog}>Cancelar</Button>
            <Button variant="contained" disableElevation onClick={handleCrearTicket} disabled={isLoading}>
              Crear caso
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}
