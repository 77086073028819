import { Box, Grid, Button, Stack, Typography, TextField, Chip, } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import DateInTable from "../../general/DateInTable";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { getRmaList, searchRmaList, getExcelRma } from "../../../utils/trazabilidad/rma/rma";
import ExcelFile from "../../general/ExcelFile";

const chipColors = {
    "1": "#dd4b39",
    "2": "#f39c12",
    "3": "#007aa6",
    "4": "#000000",
    "5": "#000000",
    "6": "#00a65a",
    "7": "#000000",
    "8": "#b73384",
    "9": "#000000",
    "10": "#000000",
    "11": "#616161",
    "12": "#000000"
};
  
const chipLabels = {
    "1": "Nuevo",
    "2": "Pedido Recibido, en espera de resolución",
    "3": "En espera de datos de envio",
    "4": "En espera de datos de reembolso",
    "5": "En espera de datos",
    "6": "Liberado",
    "7": "En espera de Store Credit",
    "8": "En espera de reembolso",
    "9": "En espera de reembolsos",
    "10": "Cerrado",
    "11": "No aplica",
    "12": "En espera de envio"
};

const RmaDataTable = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [keyWord, setKeytoSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [isELoading, setIsELoading] = useState(false);
    const [excelData, setExcelData] = useState(null);

    const getAllExcelData = async () => {
        try {
            setIsELoading(true);
            const data = await getExcelRma(chipLabels);
            setExcelData(data);
        } catch (error) {
            console.error(error);
        } finally {
            setIsELoading(false);
        }
    };

    const columns = [
        { field: "fechaEntrega", headerName: "Fecha de entrega", flex: 1, renderCell: (params) => (
            <Typography><DateInTable dateString={params.row.fechaEntrega} /></Typography>
        )},
        { field: "diasTranscurridos", headerName: "Días Hábiles Transcurridos", flex: 0.3, align: "center", headerAlign: "center" },
        { field: "guia", headerName: "Número de Guía", flex: 1, align: "center", headerAlign: "center" },
        { field: "order_increment_id", headerName: "Orden", flex: 0.6, align: "center", headerAlign: "center" },
        { field: "increment_id", headerName: "RMA", flex: 0.6, align: "center", headerAlign: "center" },
        { field: "fecha_ult", headerName: "Fecha de última modificación", flex: 1, renderCell: (params) => (
            <Typography><DateInTable dateString={params.row.fecha_ult} /></Typography>
        )},
        { field: "id_status_storeCredit", headerName: "Store Credit", flex: 1, align: "center", headerAlign: "center", colSpan: ({ row }) => ((row.id_status_storeCredit === 2 && row.id_status_reembolso === 2 && row.id_status_cambio === 2) ? 3 : 1), renderCell: (params) => (
            <Chip label={chipLabels[params.row.id_status_storeCredit]} size="small" sx={{ backgroundColor: chipColors[params.row.id_status_storeCredit], color: "#fff", }} />
        )},
        { field: "id_status_reembolso", headerName: "Reembolso", flex: 1, align: "center", headerAlign: "center",  renderCell: (params) => (
            <Chip label={chipLabels[params.row.id_status_reembolso]} size="small" sx={{ backgroundColor: chipColors[params.row.id_status_reembolso], color: "#fff", }} />
        )},
        { field: "id_status_cambio", headerName: "Cambio", flex: 1, align: "center", headerAlign: "center",  renderCell: (params) => (
            <Chip label={chipLabels[params.row.id_status_cambio]} size="small" sx={{ backgroundColor: chipColors[params.row.id_status_cambio], color: "#fff", }} />
        )},
        { field: "accion", headerName: "Acción", flex: 1, align: "center", headerAlign: "center", sortable: false, renderCell: (params) => (
            <Button variant="contained" size="small" disableElevation startIcon={<VisibilityIcon />} onClick={() => navigate(`detail/${params.row.id}`)}>Detalle</Button>
        )},
    ];

    if(document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"))
        document.querySelector(".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']").remove();

    useEffect(() => {

        let active = true;

        (async () => {
            let newRows = null;

            if(typeof keyWord === 'undefined' || keyWord === undefined || keyWord.length === 0){
                setLoading(true);
                newRows = await getRmaList(page);
            }   
            else if(keyWord.length >= 5)
            {
                setLoading(true);
                newRows = await searchRmaList(page, keyWord);
            }
            else active = false;

            if (!active) {
                return;
            }
            setTotal(newRows.total);
            setRows(newRows.data);
            setLoading(false);
        })();

        return () => {
            active = false;
        };

    }, [page, keyWord]);

    return (
    <>
        <Box sx={{ display: "flex", alignItems: "center" }}>
            <Grid container sx={{ my:2 }}>
                <Grid item md={4} sx={{ px:2 }}>
                    <Stack direction="row" spacing={1}>
                        <TextField size="small" variant="outlined" fullWidth label="Buscar RMA..." value={keyWord} onChange={(e) => setKeytoSearch(e.target.value)} />
                    </Stack>
                </Grid>
                <Grid item md sx={{ px:2, textAlign: 'right' }}>
                    {!excelData && <LoadingButton loading={isELoading} variant="contained" color="info" onClick={getAllExcelData}>Generar excel</LoadingButton>}
                    {excelData && (<ExcelFile excelData={excelData} fileName={"RMAs"} />)}
                </Grid>
            </Grid>
        </Box>
        <Box style={{ height: 635, width: '100%' }}>
            <DataGridPro
                rows={rows}
                columns={columns}
                pagination
                pageSize={10}
                rowsPerPageOptions={[10]}
                getRowId={((row) => (row.id))}
                rowCount={total}
                paginationMode="server"
                onPageChange={(newPage) => {
                    setPage(newPage);
                }}
                loading={loading}
                disableColumnMenu
                disableSelectionOnClick
            />
        </Box>
    </>
    );
};

export default RmaDataTable;