import { Box, Typography, Grid, List, ListItem, ListItemText, LinearProgress, Chip } from "@mui/material";
import DateInTable from "../../general/DateInTable";

const ExcepcionesIDSDetail = ({ excepId, detalle, chipColors, chipLabels }) => {

    return (
        <>            
            <Grid container>                
                {detalle && (
                <Grid item md={4}>                                    
                    <List>
                        <ListItem>
                            <ListItemText primary="Número de pedido" secondary={detalle.id_order}/>                                        
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Número de guía" secondary={(detalle.guia_tx ? detalle.guia_tx : 'S/G')}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Número de reporte" secondary={(detalle.num_reporte ? detalle.num_reporte : 'S/N')}/>
                        </ListItem>
                    </List>
                </Grid>)}

                {detalle && (
                <Grid item md={4}>
                    <List>
                        <ListItem>
                            <ListItemText primary="Fecha de embarque" secondary={<DateInTable dateString={detalle.fecha_embarque} />}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Fecha de registro" secondary={<DateInTable dateString={detalle.fecha_reg} />}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Fecha de reporte" secondary={detalle.fecha_reporte ? (<DateInTable dateString={detalle.fecha_reporte} />) : 'S/F'}/>
                        </ListItem>
                    </List>
                </Grid>)}

                {detalle && (
                <Grid item md={4}>
                    <List>
                        <ListItem>
                            <ListItemText primary="Status excepción" secondary={<Chip label={chipLabels[detalle.estado]} size="small" sx={{backgroundColor: chipColors[detalle.estado], color: "#fff", }} />}/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Status guía" secondary={(detalle.guia_st ? detalle.guia_st : 'S/S')}/>
                        </ListItem>
                    </List>
                </Grid>)}
            </Grid>            
        </>
    );
};

export default ExcepcionesIDSDetail;