/*const profiles = {
  inicio: [1, 23, 29, 35, 37, 7],
  ordenes: [1, 23, 29, 35, 37, 7],
  altoRiesgo: [1, 23, 29, 35, 37, 46, 7],
  blacklist: [1, 23, 29, 35, 37, 7],
  notificaciones: [1, 23, 29, 35, 37, 7],
  contracargos: [1, 23, 29, 35, 37, 7],
  complementos: [1, 23, 29, 35, 37, 7],
  atencionC: [1, 7, 23, 29, 30, 34, 58],
  logistica: [1, 9, 20, 23, 29, 46, 7],
  blacklistNom: [1, 23, 35, 37, 7],
};*/

const profiles = {
  inicio: [1,],
  ordenes: [1,],
  altoRiesgo: [1,],
  blacklist: [1,],
  notificaciones: [1,],
  contracargos: [1,],
  complementos: [1,],
  atencionC: [1,],
  logistica: [1,],
  blacklistNom: [1,],
};

const belongsAltoRiesgo = (profile) => {
  let aux = profiles;
  return aux.inicio.includes(profile)
    ? true
    : aux.ordenes.includes(profile)
    ? true
    : aux.altoRiesgo.includes(profile)
    ? true
    : aux.blacklist.includes(profile)
    ? true
    : aux.notificaciones.includes(profile)
    ? true
    : aux.contracargos.includes(profile)
    ? true
    : aux.complementos.includes(profile)
    ? true
    : aux.atencionC.includes(profile)
    ? true
    : aux.logistica.includes(profile)
    ? true
    : aux.blacklistNom.includes(profile);
};

export { profiles, belongsAltoRiesgo };
