import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import {
  deleteGuideOV,
  getGuides,
  getNmberOfGuides,
  getPdfGuide,
  getProductListGuide,
  searchGuides,
} from "../../../../../utils/ventaMayoreo/ordenesVenta/ordenesVenta";
import PrintIcon from "@mui/icons-material/Print";
import DeleteIcon from "@mui/icons-material/Delete";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { SaveGuia } from "./SaveGuia";
import { getCarries } from "../../../../../utils/trazabilidad/pedidos/pedidos";
import { useAuth } from "../../../../../context/AuthContext";
import DateInTable from "../../../../general/DateInTable";

function DetailPanelContent({ row: rowProp }) {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);

  const columns = [
    {
      field: "nombre",
      headerName: "Producto",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "sku",
      headerName: "SKU",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "cantidad",
      headerName: "Cantidad",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];

  if (
    document.querySelector(
      ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
    )
  )
    document
      .querySelector(
        ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
      )
      .remove();

  useEffect(() => {
    let active = true;

    (async () => {
      let newRows = null;
      setLoading(true);
      newRows = await getProductListGuide(page, rowProp.id_tz_guia);
      setTotal(newRows.total);
      setRows(newRows.data);
      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [page]);

  return (
    <Stack
      sx={{ py: 2, height: "100%", boxSizing: "border-box" }}
      direction="column"
    >
      <Paper sx={{ flex: 1, mx: "auto", width: "90%", p: 1 }}>
        <Box style={{ height: 375, width: "100%" }}>
          <DataGridPro
            rows={rows}
            columns={columns}
            pagination
            pageSize={5}
            rowsPerPageOptions={[5]}
            getRowId={(row) => row.id_requerimiento_producto}
            rowCount={total}
            paginationMode="server"
            onPageChange={(newPage) => {
              setPage(newPage);
            }}
            loading={loading}
            disableColumnMenu
            disableSelectionOnClick
          />
        </Box>
      </Paper>
    </Stack>
  );
}

export const GuiasTap = ({
  idRequerimiento,
  numCotizaacion,
  id,
  toggleEstado,
}) => {
  const { user } = useAuth();
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [keyWord, setKeytoSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [openRowId, setOpenRowId] = useState(null);
  const [totalGuide, setTotalGuide] = useState(0);
  const [showSaveGuide, setShowSaveGuide] = useState(false);
  const [carriers, setCarriers] = useState([]);

  const columns = [
    {
      field: "numeroDeGuia",
      headerName: "Número de guía",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: ({ row }) => row.guia,
    },
    {
      field: "rastreo",
      headerName: "Rastreo",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: ({ row }) => row.rastreo,
    },
    {
      field: "fechaDeAlta",
      headerName: "Fecha de alta",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) =>
        row.created_at ? (
          <Typography>
            <DateInTable dateString={row.created_at} />
          </Typography>
        ) : (
          "S/Fecha"
        ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => row.estatus,
    },
    {
      field: "accion",
      headerName: "Acción",
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => (
        <>
          <Button
            className="imprimirGuia"
            title="Imprimir Guía"
            sx={{ minWidth: "20px", marginRight: "5px", span: { margin: 0 } }}
            variant="contained"
            color="success"
            startIcon={<PrintIcon />}
            disabled={row.id_carrier === 5}
            onClick={() => {
              if (row.id_tz_guia) {
                imprimirGuiaForGuia(row.id_tz_guia);
              } else if (row.id_tz_guias) {
                imprimirGuiaForGuias(row.id_tz_guias, row.pdf);
              }
            }}
            data-id={row.id_tz_guia || row.id_tz_guias}
          >
            <span className="glyphicon glyphicon-print"></span>
          </Button>

          <Button
            className="eliminarGuia"
            title="Eliminar Guía"
            sx={{ minWidth: "20px", span: { margin: 0 } }}
            variant="contained"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={() => {
              if (row.id_tz_guia) {
                eliminarGuiaForGuias(row.id_tz_guia);
              } else if (row.id_tz_guias) {
                eliminarGuiaForGuias(row.id_tz_guias);
              }
            }}
            data-id={row.id_tz_guia || row.id_tz_guias}
          >
            <span className="glyphicon glyphicon-trash"></span>
          </Button>
        </>
      ),
    },
  ];

  if (
    document.querySelector(
      ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
    )
  )
    document
      .querySelector(
        ".MuiDataGrid-main > div[style='position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;']"
      )
      .remove();

  useEffect(() => {
    (async () => {
      let carriersR = await getCarries();
      setCarriers(carriersR.data);
      let totalG = await getNmberOfGuides(idRequerimiento);
      setTotalGuide(totalG.data);
    })();
  }, []);

  useEffect(() => {
    let active = true;

    (async () => {
      let newRows = null;

      if (
        typeof keyWord === "undefined" ||
        keyWord === undefined ||
        keyWord.length === 0
      ) {
        setLoading(true);
        newRows = await getGuides(page, idRequerimiento, numCotizaacion);
      } else if (keyWord.length >= 3) {
        setLoading(true);
        newRows = await searchGuides(
          page,
          idRequerimiento,
          numCotizaacion,
          keyWord
        );
      } else active = false;

      if (!active) {
        return;
      }
      setTotal(newRows.total);
      setRows(newRows.data);
      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [page, keyWord]);

  function encodeUtf8(val) {
    try {
      return decodeURIComponent(escape(val));
    } catch (err) {
      return val;
    }
  }

  const imprimirGuiaForGuias = (id, base64) => {
    if (isDriveLink(base64)) {
      descargarDesdeDrive(base64);
    } else {
      const linkSource = `data:application/pdf;base64,${base64}`;
      const downloadLink = document.createElement("a");

      const fileNameWithExt = `${id}.pdf`;

      downloadLink.href = linkSource;
      downloadLink.download = fileNameWithExt;
      downloadLink.click();
    }
  };

  const imprimirGuiaForGuia = async (id) => {
    const dataGuide = await getPdfGuide(id);
    if (dataGuide.data.length !== 0) {
      const guideData = dataGuide.data[0].pdf;
      console.log("guideData", guideData);
      if (isDriveLink(guideData)) {
        descargarDesdeDrive(guideData);
      } else {
        const linkSource = `data:application/pdf;base64,${guideData}`;
        const downloadLink = document.createElement("a");
  
        const fileNameWithExt = `${id}.pdf`;
  
        downloadLink.href = linkSource;
        downloadLink.download = fileNameWithExt;
        downloadLink.click();
      }
    } else {
      console.log("No se encontró la guía");
    }
  };

  const isDriveLink = useCallback((link) => {
    return link.includes("drive.google.com");
  }, []);

  const descargarDesdeDrive = (url) => {
    console.log(url);
    window.open(url, "_blank");
  };

  const eliminarGuiaForGuias = async (id) => {
    let data = {
      idGuia: id,
      idUser: user.data.awsid,
    };
    await deleteGuideOV(data);
    toggleEstado();
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Grid container sx={{ mb: 2, mt: 2 }}>
          <Grid item md={4}>
            <Stack direction="row" spacing={1}>
              <TextField
                size="small"
                variant="outlined"
                fullWidth
                label={`Buscar`}
                value={keyWord}
                onChange={(e) => setKeytoSearch(e.target.value)}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box style={{ height: 635, width: "100%" }}>
        <DataGridPro
          rows={rows}
          columns={columns}
          pagination
          pageSize={10}
          rowsPerPageOptions={[10]}
          getRowId={(row) => row.id}
          rowCount={total}
          paginationMode="server"
          onPageChange={(newPage) => {
            setPage(newPage);
          }}
          selectionModel={selectionModel}
          loading={loading}
          disableColumnMenu
          disableSelectionOnClick
          getDetailPanelHeight={() => "auto"}
          getDetailPanelContent={({ row }) => <DetailPanelContent row={row} />}
        />
      </Box>
      <Box sx={{ marginY: "10px" }}>
        <Button
          color="info"
          variant="contained"
          onClick={() => setShowSaveGuide(true)}
          disabled={totalGuide < 1}
        >
          Generar mas guias
        </Button>
      </Box>
      {carriers.length !== 0 && showSaveGuide && idRequerimiento && (
        <SaveGuia
          carriers={carriers}
          idRequerimiento={idRequerimiento}
          id={id}
          toggleEstado={toggleEstado}
          numCotizaacion={numCotizaacion}
        />
      )}
    </>
  );
};
