import { Box, Grid, Typography, Alert, Card, CardContent } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const GuiasTabs = ({ boId, detalle }) => {
    const [isLoading, setIsLoading] = useState(false);

    return (
        <Box sx={{mb: 2, mx: 2}}>
            <Grid container sx={{mb:2}}>
                <Grid item md></Grid>
                <Grid item md={4}>
                    <Alert variant="filled" severity="info"><Typography variant="p"><strong>Detalle de la guía</strong></Typography></Alert>
                    <Card>
                        <CardContent>
                            <Typography variant="p" component="div">Número de guía:</Typography>
                            <Typography variant="h6" align="center" component="div">{detalle.guia}</Typography>
                            <Box sx={{ textAlign: 'center', my: 2 }}>
                                <LoadingButton loading={isLoading} variant="contained" color="primary" startIcon={<FileDownloadIcon />}>Descargar</LoadingButton>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md></Grid>
            </Grid>
        </Box>
    );
};

export default GuiasTabs;