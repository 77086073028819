import { Box, Card, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import { profiles } from "../../../utils/vales/profilesVales";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import BreadCrumb from "../../general/BreadCrumb";
import PedidosDataTable from "./PedidosDataTable";

const itemsBreadCrumbs = [
    {
        label: 'Trazabilidad',
        icon: <CreditCardIcon sx={{ mr: 0.5 }} fontSize="small" />,
        link: '/trazabilidad'
    },
    { label: 'Pedidos', }
];

const Pedidos = () => {
    const [nextRender, setNextRender] = useState(false);
    const { user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!profiles.coordinadores.includes(user.role.idProfile)) {
            navigate("/dashboard", { replace: true });
        } else setNextRender(true);
    }, []);

    return (
        <>
            {nextRender && (<>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", }} >
                    <Typography variant="h4" sx={{ my: 2 }}>Pedidos</Typography>
                    <Box><BreadCrumb items={itemsBreadCrumbs} /></Box>
                </Box>
                <motion.div initial={{ y: 20 }} animate={{ y: 0 }} transition={{ duration: 0.5 }}>
                    <Card sx={{ mb: 4 }}>
                        <Box>
                            <PedidosDataTable />
                        </Box>
                    </Card>
                </motion.div>
            </>)}
        </>
    );
};

export default Pedidos;