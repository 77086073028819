import { CircularProgress, Box, Button, Card, CardContent, Typography, Grid, IconButton, Stack, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, InputLabel, Select, FormControl, MenuItem, List, ListItem, ListItemText } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import IncidenciasTable from "./IncidenciasTable";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import { profiles } from "../../../utils/trazabilidad/profilesTrazabilidad";
import { getOrderData, getOrderGuides } from "../../../utils/trazabilidad/verificaciones/verificaciones";
import { addInci, changeAddress } from "../../../utils/trazabilidad/incidencias/indicencias";
import DateInTable from "../../general/DateInTable";
import IncidenciasDataTable from "./IncidenciasDataTable";
import BreadCrumb from "../../general/BreadCrumb";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";

const itemsBreadCrumbs = [
  {
    label: 'Trazabilidad',
    icon: <ContentPasteSearchIcon sx={{ mr: 0.5 }} fontSize="small"/>,
    link: '/trazabilidad/inicio'
  },
  { label: 'Incidencias a Logística', }
];

const Incidencias = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [nextRender, setNextRender] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [order, setOrder] = useState("");
  const [guia, setGuia] = useState("");
  const [type, setType] = useState("");
  const [data, setData] = useState(null);
  const [guias, setGuias] = useState([]);
  const [address, setAddress] = useState({
    nombre: '',
    calle: '',
    ne: '',
    ni: '',
    entre: '',
    colonia: '',
    dele: '',
    cp: '',
    pais: 'México',
    ciudad: '',
    estado: '',
    fachada: ''
  });

  const handleChangeAddress = (event) => {    
    let name = event.target.name;
    let value = event.target.value;
    setAddress((prevalue) => {
      return {
        ...prevalue,
        [name]: value
      }
    });
  };

  function isempt(){
    if(address.nombre && address.calle && address.ne && address.entre && address.colonia && address.dele && address.cp && address.ciudad && address.estado && address.fachada && address.pais)
      return false;
    else
      return true;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeOrder = (event) => {
    setOrder(event.target.value);
  };

  const handleChangeGuia = (event) => {
    setGuia(event.target.value);
  };

  const handleChangeType = (event) => {
    setType(event.target.value);
  };

  const handleSaveSC = async () => {
    try {
      setIsLoading(true);
      if(data && data.increment_id && guia && type)
      {
        if(type === 1){
          if(!isempt()){
            let idInc = await addInci(user.data.awsid, data.increment_id, type, guia);
            await changeAddress(user.data.awsid, idInc, address.nombre, address.pais, address.cp, address.estado, address.dele, address.calle, address.ne, address.ni, address.colonia, address.ciudad, address.entre, address.fachada);
            refreshPage();
          }
        }
        else{
          await addInci(user.data.awsid, data.increment_id, type, guia);
          refreshPage();
        }
      }      
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const searchData = async () => {
    try {
      setIsLoading(true);
      setData(null);
      setGuia("");
      setGuias([]);
      const resp = await getOrderData(order);
      setData(resp);
      const respo = await getOrderGuides(order);
      setGuia(respo[0].id_tz_guias);
      setGuias(respo);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const refreshPage = () => {
    const current = location.pathname;
    navigate(`/reload`, { replace: true });
    setTimeout(() => {
      navigate(current, { replace: true });
    }, 1000);
  };
  
  useEffect(() => {

    if(!profiles.incidenciasLog.includes(user.role.idProfile)){
      navigate("/dashboard", { replace: true });
    }else setNextRender(true);

  }, []);

  return (
    <>
    {nextRender && (<>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", }} >
        <Typography variant="h4" sx={{ my: 2 }}>Incidencias</Typography>
        <Box>
          <BreadCrumb items={itemsBreadCrumbs} />
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: 'end', mb: 2 }}>
        <Button variant="contained" color="success" startIcon={<AddIcon />} onClick={handleClickOpen}>Crear incidencia</Button>
      </Box>

      <motion.div initial={{ y: 20 }} animate={{ y: 0 }} transition={{ duration: 0.5 }}>        
        <Card>          
          <Box>
            <IncidenciasDataTable />
          </Box>
        </Card>
      </motion.div>

      <Dialog open={open} fullWidth={true} maxWidth="md" scroll="paper" onClose={handleClose}>
        <DialogTitle>Nueva incidencia</DialogTitle>
        <DialogContent dividers={true}>
          <Stack sx={{ mt:2, mb:2 }} direction="row" spacing={1}>
            <TextField fullWidth label="Número de pedido" variant="outlined" value={order} onChange={handleChangeOrder} />
            <Button variant="contained" color="info" startIcon={<SearchIcon />} disabled={(!order || order.length < 9)} onClick={searchData}>Buscar</Button>
          </Stack>
          {(isLoading && (!data || guias.length <= 0)) && (
            <><Typography variant="p"> Cargando ...</Typography> <CircularProgress /></>
          )}

          {data && (<Grid container>
            <Grid item md={6}>
              <List>
                <ListItem><ListItemText primary="Número de pedido" secondary={data.increment_id}/></ListItem>
                <ListItem><ListItemText primary="Fecha de creación" secondary={<DateInTable dateString={data.created_at} />}/></ListItem>
                <ListItem><ListItemText primary="Fecha de pago" secondary={<DateInTable dateString={data.payment_date} />}/></ListItem>
                <ListItem><ListItemText primary="Escuela(s)" secondary={data.school}/></ListItem>
              </List>
            </Grid>
            <Grid item md={6}>
              <List>
                <ListItem><ListItemText primary="Nombre del cliente" secondary={data.customer_name + " " + data.customer_lastname + " " + data.customer_mothersname}/></ListItem>
                <ListItem><ListItemText primary="Teléfono" secondary={data.telephone}/></ListItem>
                <ListItem><ListItemText primary="Email" secondary={data.email}/></ListItem>
              </List>
            </Grid>
          </Grid>
          )}

          {guias.length > 0 && (<FormControl fullWidth sx={{mb: 2}}>
            <InputLabel id="selecte" htmlFor="groupedselect">Guía</InputLabel>
            <Select id="groupedselect" name="groupedselect" label="Guía" value={guia} onChange={handleChangeGuia}>
              {guias.map((row) => (
              <MenuItem key={row.id_tz_guias} value={row.id_tz_guias}>{row.guia} - {row.name_carrier} - {row.name_servicio} - {row.nombre_tipo_guia}</MenuItem>
              ))}
            </Select>
          </FormControl>
          )}

          {data && (
          <FormControl fullWidth sx={{mb: 2}}>
            <InputLabel id="selecteT" htmlFor="groupedselectT">Tipificación</InputLabel>
            <Select id="groupedselectT" name="groupedselectT" label="Tipificación" value={type} onChange={handleChangeType}>
              <MenuItem value={1}>Cambio de domicilio</MenuItem>
              <MenuItem value={2}>Guía sin movimientos</MenuItem>
              <MenuItem value={3}>Solicitud de ocurre</MenuItem>
            </Select>
          </FormControl>
          )}

          {(type === 1) && (
          <Card>
            <CardContent>
              <Typography><strong>Nuevo Domicilio:</strong></Typography>
              <Stack sx={{m: 2}} direction="row" spacing={2}>
                <TextField fullWidth name="nombre" label="Nombre" variant="outlined" value={address.nombre} onChange={handleChangeAddress} autoComplete="off" />
              </Stack>
              <Stack sx={{m: 2}} direction="row" spacing={3}>
                <TextField fullWidth name="calle" label="Calle" variant="outlined" value={address.calle} onChange={handleChangeAddress} autoComplete="off" />
                <TextField fullWidth name="ne" label="Número exterior" variant="outlined" value={address.ne} onChange={handleChangeAddress} autoComplete="off" />
                <TextField fullWidth name="ni" label="Número interior" variant="outlined" value={address.ni} onChange={handleChangeAddress} autoComplete="off" />
              </Stack>
              <Stack sx={{m: 2}} direction="row" spacing={3}>
                <TextField fullWidth name="colonia" label="Colonia" variant="outlined" value={address.colonia} onChange={handleChangeAddress} autoComplete="off" />
                <TextField fullWidth name="fachada" label="Fachada" variant="outlined" value={address.fachada} onChange={handleChangeAddress} autoComplete="off" />
                <TextField fullWidth name="entre" label="Entre calles" variant="outlined" value={address.entre} onChange={handleChangeAddress} autoComplete="off" />
              </Stack>
              <Stack sx={{m: 2}} direction="row" spacing={3}>
                <TextField fullWidth name="ciudad" label="Ciudad" variant="outlined" value={address.ciudad} onChange={handleChangeAddress} autoComplete="off" />
                <TextField fullWidth name="estado" label="Estado" variant="outlined" value={address.estado} onChange={handleChangeAddress} autoComplete="off" />
                <TextField fullWidth name="dele" label="Delegación" variant="outlined" value={address.dele} onChange={handleChangeAddress} autoComplete="off" />
              </Stack>
              <Stack sx={{m: 2}} direction="row" spacing={3}>
                <TextField fullWidth name="cp" label="C.P." variant="outlined" value={address.cp} onChange={handleChangeAddress} autoComplete="off" />
                <TextField fullWidth name="pais" label="País" variant="outlined" value={address.pais} onChange={handleChangeAddress} autoComplete="off" />
              </Stack>
            </CardContent>
          </Card>
          )}

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <LoadingButton loading={isLoading} variant="contained" color="info" startIcon={<SaveIcon />} disabled={(!order || !guia || !type || (type === 1 && isempt()))} onClick={handleSaveSC}>Crear incidencia</LoadingButton>
        </DialogActions>
      </Dialog>
    </>)}
    </>
  )
}

export default Incidencias;