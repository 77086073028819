import { useState } from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { ContactTab } from "./ContactTab";
import {
  Forum as ForumIcon,
  LocalGroceryStore as LocalGroceryStoreIcon,
  ContactPage as ContactPageIcon,
} from "@mui/icons-material";
import { MessageTab } from "./MessageTab";
import { ProductsTab } from "./ProductsTab";
import { SizeProvider } from "../SizeProvider";
import CircularProgress from "@mui/material/CircularProgress";
import { Warning as WarningIcon } from "@mui/icons-material";

export const IndexTabs = ({
  contactDetail,
  cotizacionId,
  productsDetail,
  idRequerimiento,
  detalle,
}) => {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab
              icon={<ForumIcon />}
              iconPosition="start"
              label="Mensajes"
              value="1"
            />
            {detalle && detalle.productosMarcas ? (
              <Tab style={{ display: "flex", flexDirection: "row" }}
                icon={
                  <span>
                    <WarningIcon
                      color="warning"
                    />
                    <LocalGroceryStoreIcon />
                  </span>
                }
                label="Productos"
                value="2"
              />
            ) : (
              <Tab
                icon={<LocalGroceryStoreIcon />}
                iconPosition="start"
                label="Productos"
                value="2"
              />
            )}

            <Tab
              icon={<ContactPageIcon />}
              iconPosition="start"
              label="Contacto"
              value="3"
            />
          </TabList>
        </Box>
        <TabPanel value="1">
          {idRequerimiento ? (
            <MessageTab idRequerimiento={idRequerimiento} />
          ) : (
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          )}
        </TabPanel>
        <TabPanel value="2">
          {cotizacionId && productsDetail ? (
            <SizeProvider>
              <ProductsTab
                cotizacionId={cotizacionId}
                productsDetail={productsDetail}
              />
            </SizeProvider>
          ) : (
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          )}
        </TabPanel>
        <TabPanel value="3">
          {contactDetail ? (
            <ContactTab contactDetail={contactDetail} />
          ) : (
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          )}
        </TabPanel>
      </TabContext>
    </Box>
  );
};
